import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { SubSink } from 'subsink';
import { ReportService } from '../../_services/report.service';
import { GhgEmissionsReport } from '../../_models/_reports/ghg-emissions-report';
import { CoreService } from '../../_services/core.service';
import { RefineryFlowRatesReport } from '../../_models/_reports/refinery-flow-rates-report';
import { DiluentFlowRatesReport } from '../../_models/_reports/diluent-flow-rates-report';
import { CapexReport } from '../../_models/_reports/capex-report';
import { OpexReport } from '../../_models/_reports/opex-report';
import { RevenueReport } from '../../_models/_reports/revenue-report';
import { InsightsComponent } from './insights/insights.component';
import { MarginalValueEntry } from '../../_models/marginal-value-entry';
import { GhgSummaryReportComponent } from './ghg-summary-report/ghg-summary-report.component';
import { isTypeUndefined } from '../../_utils/utils';

declare let unitConverter: any;

@Component({
  selector: 'sob-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.css'],
})
export class SummaryReportComponent implements OnInit, OnDestroy {
  ghgEmissions: GhgEmissionsReport;
  refineryFlowRates: RefineryFlowRatesReport;
  diluentFlowRates: DiluentFlowRatesReport;
  capexReport: CapexReport;
  opexReport: OpexReport;
  revenueReport: RevenueReport;
  constraintRanking: MarginalValueEntry[] = [];
  objFunctionUnit = 'MMCAD$/kbpcd';

  @ViewChild(InsightsComponent, { static: true }) insights: InsightsComponent;
  @ViewChild(GhgSummaryReportComponent) ghgSummaryReport: GhgSummaryReportComponent;
  private subSink = new SubSink();

  constructor(private coreService: CoreService, private reportService: ReportService, private ref: ChangeDetectorRef) {
    // TODO Analyze if this should only be fired when case is solved
    // TODO consider subscribing to currentCaseReplacedRequest

    this.subSink.add(
      this.coreService.caseChangedRequest.subscribe((data: any) => {
        if (data && data.solveSuccess) {
          this.buildReports();
          if (!this.coreService.currentCase.multiPeriodEnabled) {
            this.showInsightsPage();
          }
        }
        this.constraintRanking = this.coreService.currentCase.marginalValueEntries;
      })
    );

    this.subSink.add(
      this.coreService.currentCaseReplacedRequest.subscribe(() => {
        if (this.coreService.currentCase.isSolved) {
          this.buildReports();
          this.constraintRanking = this.coreService.currentCase.marginalValueEntries;
        }
      })
    );
  }

  ngOnInit() {
    this.constraintRanking = this.coreService.currentCase.marginalValueEntries;
  }

  showInsightsPage() {
    $('#summaryReportModal').modal('show');
    $('#srInsights').tab('show');
  }

  showConstraintRank() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  buildReports() {
    this.ghgEmissions = this.reportService.buildGhgEmissionsReport();
    this.refineryFlowRates = this.reportService.buildRefineryFlowRatesReport();
    this.diluentFlowRates = this.reportService.buildDiluentFlowRatesReport();
    this.capexReport = this.reportService.buildCapexReport();
    this.opexReport = this.reportService.buildOpexReport();
    this.revenueReport = this.reportService.buildRevenueReport();

    this.ref.detectChanges();

    this.buildInsightsCharts();
    this.ghgSummaryReport.buildReport(this.ghgEmissions);

    this.buildDiluentFlowratesChart();
    this.buildDiluentFlowratesTable();

    this.buildRefineryFlowratesChart();
    this.buildRefineryFlowratesTable();

    this.buildCapexChart();
    this.buildCapexTable();

    this.buildOpexChart();
    this.buildOpexTable();

    this.buildRevenueChart();
    this.buildRevenueTable();
    if (this.constraintRanking.length !== 0) {
      if (this.objFunctionUnit === 'MMCAD$/kbpcd') {
        this.buildConstraintRankingChart();
      } else {
        this.buildConstraintRankingByPercentChart();
      }
    }
  }

  buildInsightsCharts() {
    this.insights.initGhgChart(this.ghgEmissions);
    this.insights.initRevenueChart(this.revenueReport);
    this.insights.initCapexChart(this.capexReport);
    this.insights.initOpexChart(this.opexReport);
    this.insights.initRefineryChart(this.refineryFlowRates);
    this.insights.initDiluentChart(this.diluentFlowRates);
  }

  getOwnerFlowsheetName(mve: MarginalValueEntry) {
    const ownerFlowsheet = this.coreService.currentCase.getUnitOperation(mve.ownerOperationId);
    if (isTypeUndefined(ownerFlowsheet)) {
      return 'Main Flowsheet';
    }
    return ownerFlowsheet.name;
  }

  // region diluent flowrates report
  buildDiluentFlowratesChart() {
    const series = [
      {
        name: 'Volumetric flowrate',
        colorByPoint: true,
        data: this.diluentFlowRates.data.map(cv => {
          return { y: cv.value, name: cv.name };
        }),
      },
    ];

    const chartOptions = this.getCommonChartOptions('Diluent flowrates', this.diluentFlowRates.unit, series);

    Highcharts.chart('srDiluentFlowRatesChart', <any>chartOptions);
  }

  buildDiluentFlowratesTable() {
    const tableData = [];

    for (const cu of this.diluentFlowRates.data) {
      tableData.push({ name: cu.name, value: cu.value });
    }

    const $table = $('#srDiluentFlowRatesTable');

    if ((<any>$.fn).dataTable.isDataTable('#srDiluentFlowRatesTable')) {
      (<any>$table).DataTable().destroy();
    }

    const opts = {
      data: tableData,
      paging: false,
      searching: false,
      info: false,
      order: [[1, 'desc']],
      columns: [
        {
          data: 'name',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              return `${unitConverter.formatNumber(data.value)} ${this.diluentFlowRates.unit}`;
            }
            return data.value;
          },
        },
      ],
    };

    (<any>$table).DataTable(<any>opts);
  }
  // endregion

  // region refinery flowrates report
  buildRefineryFlowratesChart() {
    const series = [
      {
        name: 'Volumetric flowrate',
        colorByPoint: true,
        data: this.refineryFlowRates.data.map(cv => {
          return { y: cv.value, name: cv.name };
        }),
      },
    ];

    const chartOptions = this.getCommonChartOptions('Refinery flowrates', this.refineryFlowRates.unit, series);

    Highcharts.chart('srRefineryFlowRatesChart', <any>chartOptions);
  }

  buildRefineryFlowratesTable() {
    const tableData = [];

    for (const cu of this.refineryFlowRates.data) {
      tableData.push({ name: cu.name, value: cu.value });
    }

    const $table = $('#srRefineryFlowRatesTable');

    if ((<any>$.fn).dataTable.isDataTable('#srRefineryFlowRatesTable')) {
      (<any>$table).DataTable().destroy();
    }

    const opts = {
      data: tableData,
      paging: false,
      searching: false,
      info: false,
      order: [[1, 'desc']],
      columns: [
        {
          data: 'name',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              return `${unitConverter.formatNumber(data.value)} ${this.refineryFlowRates.unit}`;
            }
            return data.value;
          },
        },
      ],
    };

    (<any>$table).DataTable(<any>opts);
  }
  // endregion

  // region revenue report
  buildRevenueChart() {
    const series = [
      {
        name: 'Total Revenue',
        colorByPoint: true,
        data: this.revenueReport.data.map(cv => {
          return { y: cv.value, name: cv.name };
        }),
      },
    ];

    const chartOptions = this.getCommonChartOptions('Revenue', this.revenueReport.unit, series);

    Highcharts.chart('srRevenueChart', <any>chartOptions);
  }

  buildRevenueTable() {
    const tableData = [];

    for (const cu of this.revenueReport.data) {
      tableData.push({ name: cu.name, value: cu.value });
    }

    const $table = $('#srRevenueTable');

    if ((<any>$.fn).dataTable.isDataTable('#srRevenueTable')) {
      (<any>$table).DataTable().destroy();
    }

    const opts = {
      data: tableData,
      paging: false,
      searching: false,
      info: false,
      order: [[1, 'desc']],
      columns: [
        {
          data: 'name',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              return `${unitConverter.formatNumber(data.value)} ${this.revenueReport.unit}`;
            }
            return data.value;
          },
        },
      ],
    };

    (<any>$table).DataTable(<any>opts);
  }
  // endregion

  // region capex report
  buildCapexChart() {
    const series = {
      name: 'CAPEX',
      colorByPoint: true,
      data: this.capexReport.contributionByCategory.map(cv => {
        return { y: cv.value, name: cv.name, drilldown: cv.childDataId };
      }),
    };

    const drilldownSeries = this.capexReport.contributionByUnit.map(cu => {
      return {
        name: `${cu.name} CAPEX`,
        id: cu.id,
        data: cu.data.map(cv => {
          return {
            name: cv.name,
            y: cv.value,
          };
        }),
      };
    });

    const chartOptions = this.getCommonDrilldownChartOptions('CAPEX', this.capexReport.unit, series, drilldownSeries);
    Highcharts.chart('srCapexChart', <any>chartOptions);
  }

  buildCapexTable() {
    const tableData = [];

    for (const cu of this.capexReport.contributionByUnit) {
      for (const cv of cu.data) {
        tableData.push(cv);
      }
    }

    const $table = $('#srCapexTable');

    if ((<any>$.fn).dataTable.isDataTable('#srCapexTable')) {
      (<any>$table).DataTable().destroy();
    }

    const opts = {
      data: tableData,
      paging: false,
      searching: false,
      info: false,
      order: [[1, 'desc']],
      columns: [
        {
          data: 'name',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              return `${unitConverter.formatNumber(data.value)} ${this.capexReport.unit}`;
            }
            return data.value;
          },
        },
      ],
    };

    (<any>$table).DataTable(<any>opts);
  }
  // endregion

  // region opex report
  buildOpexChart() {
    const series = {
      name: 'OPEX',
      colorByPoint: true,
      data: this.opexReport.contributionByCategory.map(cv => {
        return { y: cv.value, name: cv.name, drilldown: cv.childDataId };
      }),
    };

    const drilldownSeries = this.opexReport.contributionByUnit.map(cu => {
      return {
        name: `${cu.name} OPEX`,
        id: cu.id,
        data: cu.data.map(cv => {
          return {
            name: cv.name,
            y: cv.value,
          };
        }),
      };
    });

    const chartOptions = this.getCommonDrilldownChartOptions('OPEX', this.opexReport.unit, series, drilldownSeries);
    Highcharts.chart('srOpexChart', <any>chartOptions);
  }

  buildOpexTable() {
    const tableData = [];

    for (const cu of this.opexReport.contributionByUnit) {
      for (const cv of cu.data) {
        tableData.push(cv);
      }
    }

    const $table = $('#srOpexTable');

    if ((<any>$.fn).dataTable.isDataTable('#srOpexTable')) {
      (<any>$table).DataTable().destroy();
    }

    const opts = {
      data: tableData,
      paging: false,
      searching: false,
      info: false,
      order: [[1, 'desc']],
      columns: [
        {
          data: 'name',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              return `${unitConverter.formatNumber(data.value)} ${this.opexReport.unit}`;
            }
            return data.value;
          },
        },
      ],
    };

    (<any>$table).DataTable(<any>opts);
  }
  // endregion

  switchConstraintChartType() {
    this.objFunctionUnit = this.objFunctionUnit === '%' ? 'MMCAD$/kbpcd' : '%';
    this.objFunctionUnit === 'MMCAD$/kbpcd'
      ? this.buildConstraintRankingChart()
      : this.buildConstraintRankingByPercentChart();
  }

  buildConstraintRankingChart() {
    this.ref.detectChanges();
    const series = this.constraintRanking
      .map(mve => {
        return { name: `${mve.variableName} - ${mve.unitOperationName}`, y: mve.objFunctionChangeRate };
      })
      .filter(mve => !mve.name.startsWith('Minimum Flow'));

    const chartOptions = this.getBarChartOptions(series);
    Highcharts.chart('srConstraintChart', <any>chartOptions);
  }

  buildConstraintRankingByPercentChart() {
    const totalObjFunctionChangeRateValue = this.getTotalObjFunctionChangeRate();

    const series = this.constraintRanking
      .filter(mve => !mve.variableName.startsWith('Minimum Flow'))
      .map(mve => {
        const percent = (mve.objFunctionChangeRate * 100) / totalObjFunctionChangeRateValue;
        return { name: `${mve.variableName} - ${mve.unitOperationName}`, y: percent };
      });

    const chartOptions = this.getBarChartOptions(series);
    Highcharts.chart('srConstraintChart', <any>chartOptions);
  }

  getTotalObjFunctionChangeRate() {
    let total = 0;
    this.constraintRanking
      .filter(mve => !mve.variableName.startsWith('Minimum Flow'))
      .forEach(mve => {
        total += mve.objFunctionChangeRate;
      });

    return total;
  }

  getBarChartOptions(series) {
    return {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: `Obj. function change rate value (${this.objFunctionUnit})`,
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.3f}${this.objFunctionUnit}</b><br/>`,
        followPointer: true,
      },
      series: [
        {
          name: 'Constraint',
          data: series,
          colorByPoint: true,
        },
      ],
    };
  }

  getCommonChartOptions(title, unit, series) {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },

      credits: {
        enabled: false,
      },

      title: {
        text: title,
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: `{point.name}: {point.y:,.2f} ${unit}`,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.2f} ${unit}</b><br/>`,
      },
      series,
    };
  }

  getCommonDrilldownChartOptions(title, unit, series, drilldownSeries) {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },

      credits: {
        enabled: false,
      },

      title: {
        text: title,
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: `{point.name}: {point.y:.2f} ${unit}`,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f} ${unit}</b><br/>`,
      },
      series: [series],

      drilldown: {
        series: drilldownSeries,
      },
    };
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
