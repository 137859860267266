/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { Case, Project } from '../_models';
import { unitOperationsConfig } from '../_config/unit-operations.config';
import { Mixer } from '../_models/_unit-operations/mixer';
import { Splitter } from '../_models/_unit-operations/splitter';
import { isTypeUndefined } from '../_utils/utils';
import { RangeDistributionConstraint } from '../_models/_unit-operations/range-distribution-constraint';

@Injectable()
export class AfterDeserializationCompatibilityService {
  makeCompatible(project: Project) {
    for (const c of project.cases) {
      this.makeCaseCompatible(c);
    }
  }

  private makeCaseCompatible(c: Case) {
    this.addMixingRatioVariablesToAllNormalMixers(c);
    this.addMinimumAndMaximumRatioValuesToNormalOptimizableSplitters(c);
    this.setRangeConstraintsName(c);
  }

  private addMixingRatioVariablesToAllNormalMixers(c: Case) {
    const mixersList = c.findUnitOpsByCategory(unitOperationsConfig.mixer.key) as Mixer[];

    for (const mixer of mixersList) {
      const inletStreamsList = c.getStreamsByOutletUnitOperationId(mixer.id);

      const hasMissingMixingRatioVariables = !mixer.mixingRatioVariables.length && inletStreamsList.length;

      if (!hasMissingMixingRatioVariables) {
        return;
      }

      console.warn(`${mixer.name} has missing mixing ratio variables!!`);
      for (const inletStream of inletStreamsList) {
        const inletName = c.getUnitOperationName(inletStream.inletUnitOperationId);
        console.warn(`${inletName} is connected to ${mixer.name}`);
        mixer.addInletDistributionRatioVariables(inletStream.inletUnitOperationId);
      }

      console.warn('....');
      console.warn('....');
    }
  }

  private addMinimumAndMaximumRatioValuesToNormalOptimizableSplitters(c: Case) {
    const optimizableSplittersList = c.filterUnitOperations(
      uo => uo instanceof Splitter && uo.isOptimizable
    ) as Splitter[];

    for (const splitter of optimizableSplittersList) {
      for (const drv of splitter.distributionRatioVariables) {
        if (isTypeUndefined(drv.minimumValue)) {
          drv.minimumValue = 0;
          const outletName = c.getUnitOperationName(drv.unitOperationId);
          console.warn(
            `Setting Minimum value on Splitter :: ${splitter.name}` +
              `Distribution Ratio Variable corresponding to ${outletName}`
          );
        }

        if (isTypeUndefined(drv.maximumValue)) {
          drv.maximumValue = 1;
          const outletName = c.getUnitOperationName(drv.unitOperationId);
          console.warn(
            `Setting maximum value on Splitter :: ${splitter.name}` +
              `Distribution Ratio Variable corresponding to ${outletName}\n-----\n\n`
          );
        }
      }
    }
  }

  private setRangeConstraintsName(c: Case) {
    const mixersAndSplitters = c.filterUnitOperations(
      unitOperation => unitOperation instanceof Mixer || unitOperation instanceof Splitter
    );

    mixersAndSplitters.forEach((unitOperation: Mixer | Splitter) => {
      const constraintsWithMissingOwner = [] as RangeDistributionConstraint[];

      if (isTypeUndefined(unitOperation.constraints) || Object.keys(unitOperation.constraints).length === 0) return;

      Object.values(unitOperation.constraints.constraints).forEach(cstr => {
        const sourceUnitOperation = c.getUnitOperation(cstr.sourceUnitOperationId);

        if (!sourceUnitOperation) {
          constraintsWithMissingOwner.push(cstr);
        } else if (cstr.id.includes('minimumRange')) {
          cstr.constraintName = `Minimum ${sourceUnitOperation.name} Ratio`;
        } else if (cstr.id.includes('maximumRange')) {
          cstr.constraintName = `Maximum ${sourceUnitOperation.name} Ratio`;
        } else {
          cstr.constraintName = `Exact ${sourceUnitOperation.name} Ratio`;
        }
      });

      // TODO this is a temporary fix just to remove orphan distribution ratio variables
      // TODO this error was detected in Test on June 20, 2024
      // REMOVE WHEN NO LONGER NEEDED!!!
      for (const cstr of constraintsWithMissingOwner) {
        // eslint-disable-next-line no-console
        console.warn(
          `Removing all variables depending on missing unit operation ${cstr.sourceUnitOperationId}`,
          `from ${unitOperation.name} - doesn't have an owner!`
        );

        if (unitOperation instanceof Mixer) {
          unitOperation.removeInletDistributionRatioVariables(cstr.sourceUnitOperationId);
        } else if (unitOperation instanceof Splitter) {
          unitOperation.removeOutletDistributionRatioVariables(cstr.sourceUnitOperationId);
        }
      }
    });
  }
}
