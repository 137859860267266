import { Component, OnInit } from '@angular/core';
import { SendCaseModalService } from './send-case-modal.service';
import { CoreService } from '../../_services/core.service';
import { FlowsheetService } from '../../_services/flowsheet.service';

@Component({
  selector: 'sob-send-case-modal',
  templateUrl: './send-case-modal.component.html',
  styleUrls: ['./send-case-modal.component.css'],
})
export class SendCaseModalComponent implements OnInit {
  constructor(
    private sendCaseModalService: SendCaseModalService,
    private coreService: CoreService,
    private flowsheetService: FlowsheetService
  ) {
    this.sendCaseModalService.showRequest.subscribe(() => {
      this.show();
    });
  }

  ngOnInit() {
    $('#sendCaseModal').on('show.bs.modal', () => {
      this.hideError();
      this.hideLoading();
      this.showMessage();
    });
  }

  sendCase() {
    if (this.coreService.currentCase.getActiveFlowsheet().isSubflowsheet()) {
      this.flowsheetService.closeSubFlowsheet();
    }

    this.showLoading();

    setTimeout(() => {
      this.coreService.sendCaseToProcessEcology().subscribe(
        () => {
          this.hideLoading();
          this.hide();
        },
        () => {
          this.hideLoading();
          this.showError();
        }
      );
    }, 1000);
  }

  show() {
    $('#sendCaseModal').modal('show');
  }

  hide() {
    $('#sendCaseModal').modal('hide');
  }

  showMessage() {
    $('#sendCaseToPEMessage').show();
  }

  showLoading() {
    $('#sendCaseToPEMessage').hide();
    $('#sendCaseToPELoading').show();
  }

  hideLoading() {
    $('#sendCaseToPELoading').hide();
  }

  showError() {
    $('#sendCaseError').fadeIn();
  }

  hideError() {
    $('#sendCaseError').hide();
  }
}
