import { AfterViewInit, Component } from '@angular/core';
import { CommentsService } from '../../_services/helpers/comments.service';
import { BaseObjectComment } from '../../_models/base-object-comment';
import { CoreService } from '../../_services/core.service';
import { isTypeUndefined } from '../../_utils/utils';

@Component({
  selector: 'sob-case-comments',
  templateUrl: './case-comments.component.html',
  styleUrls: ['./case-comments.component.css'],
})
export class CaseCommentsComponent implements AfterViewInit {
  comments: BaseObjectComment[];
  selectedComment: BaseObjectComment = null;

  constructor(private commentsService: CommentsService, private coreService: CoreService) {}

  ngAfterViewInit() {
    this.updateDataTable();
  }

  updateDataTable() {
    const $modal = $('#caseCommentsModal');
    $modal.on('show.bs.modal', () => {
      this.getCaseComments();
      this.buildCommentsTable();
    });

    $('#tableCaseComments ').on('click', '.inspectButton', e => {
      const $target = $(e.currentTarget);
      const id = $target.data('id');
      this.selectedComment = this.commentsService.getCommentById(id);
    });
  }

  saveComment(com: BaseObjectComment) {
    this.selectedComment = null;
    const $editModal = $('#editComment');
    $editModal.modal('hide');
    const newText = $('#area').val().toString();
    this.commentsService.updateComment(com, newText);
    this.getCaseComments();
    this.buildCommentsTable();
  }

  cancel() {
    this.selectedComment = null;
    const $editModal = $('#editComment');
    $editModal.modal('hide');
  }

  getCaseComments() {
    this.comments = this.commentsService.getCommentsPool();
  }

  getUnitOpOwnerName(idOwner: string) {
    const unit = this.coreService.currentCase.getUnitOperation(idOwner);
    return unit.name;
  }

  getFlowsheetOwner(idOwner: string) {
    const unitOp = this.coreService.currentCase.getUnitOperation(idOwner);
    const flowsheet = this.coreService.currentCase.getUnitOperation(unitOp.flowsheetId);
    return isTypeUndefined(flowsheet) ? 'Main Flowsheet' : flowsheet.name;
  }

  buildCommentsTable() {
    const $table = $('#tableCaseComments');

    if ((<any>$.fn).dataTable.isDataTable('#tableCaseComments')) {
      (<any>$table).DataTable().destroy();
    }
    const opts = {
      data: this.comments,
      paging: true,
      searching: true,
      info: false,
      order: [[0, 'asc']],
      columns: [
        {
          data: data => {
            return this.getUnitOpOwnerName(data.ownerId);
          },
        },
        {
          data: data => {
            return this.getFlowsheetOwner(data.ownerId);
          },
        },
        {
          data: data => {
            if (this.validateUser(data.username)) {
              return (
                `<button class="btn btn-primary btn-md inspectButton" ` +
                `data-toggle="modal" data-target="#editComment"  ` +
                `data-backdrop="static" data-keyboard="false" ` +
                `" data-id="${data.id}">Edit</button>`
              );
            }
            return (
              `<button class="btn btn-primary btn-md inspectButton" disabled="true" ` +
              `data-toggle="modal" data-target="#editComment"  ` +
              `data-backdrop="static" data-keyboard="false" ` +
              `" data-id="${data.id}">Edit</button>`
            );
          },
        },
        {
          data: 'username',
        },
        {
          data: data => {
            return this.formatDate(data.commentDate);
          },
        },
        {
          data: 'text',
          visible: false,
          searchable: true,
        },
      ],
    };
    (<any>$table).DataTable(<any>opts);
  }

  openFlowsheetComments() {
    $('#flowsheetCommentsModal').modal('show');
  }

  formatDate(date: Date) {
    const padTo2Digits = num => num.toString().padStart(2, '0');
    return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
  }

  validateUser(tableUser: string) {
    const serializedUser = localStorage.getItem('user');
    const currentUser = JSON.parse(serializedUser || '{}').userName;

    return currentUser === tableUser;
  }
}
