import { ConstraintsReport } from '../../_models/_reports/_optimization-settings-table/constraints-report';
import { Case } from '../../_models';
import { ExcelReportUtils } from './excel-report.utils';
import { ConstraintReportVariable } from '../../_models/_reports/_optimization-settings-table/constraint-report-variable';

export class ConstraintsExcelReport {
  static generateDataArray(constraintsReport: ConstraintsReport, c: Case) {
    const dataArray = [['Active', 'Block name', 'Constraint', 'Constraint value', 'Current value', 'unit']];

    for (const constraint of constraintsReport.constraints) {
      const arrayConstraintElement = [
        constraint.unitOperationName,
        constraint.name,
        ExcelReportUtils.roundFormat(constraint.constraintValue, -2),
        ExcelReportUtils.roundFormat(constraint.currentValue, -2),
        constraint.constraintUnit,
      ];

      if (constraint instanceof ConstraintReportVariable) {
        const simvar = c.getSimulationVariable(constraint.simVarId);
        arrayConstraintElement.splice(0, 0, simvar.isActive ? 'true' : 'false');
      } else {
        arrayConstraintElement.splice(0, 0, constraint.isActive ? 'true' : 'false');
      }

      dataArray.push(arrayConstraintElement);
    }

    return dataArray;
  }
}
