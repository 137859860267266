import { SuncorMaterialStream } from '../_streams/suncor-material-stream';

declare let unitConverter: any;

export class SankeySuncorMaterialStream {
  id: string;
  from: number;
  to: number;
  name: string;
  volumetricFlowrate: number;
  volumetricFlowrateUnit: string;
  width: number;

  constructor(s: SuncorMaterialStream) {
    this.id = s.id;
    this.from = s.inletUnitOperation.key;
    this.to = s.outletUnitOperation.key;
    this.name = s.name || s.connectionName;
    this.volumetricFlowrateUnit = unitConverter.units.Volumetricflowrate.KBPCD;
    this.volumetricFlowrate = s.volumetricFlowrate.convertToAnotherUnit(this.volumetricFlowrateUnit, false);
    this.width = this.volumetricFlowrate > 700 ? 700 : this.volumetricFlowrate;
  }
}
