import { FlexSheetMapper } from './flex-sheet-mapper';
import { Upgrader } from '../../../_models/_unit-operations/upgrader';
import { UpgraderSheetRows } from '../cell-config/upgrader-cell-config';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';

export class UpgraderFlexSheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: Upgrader, row: number, value: string | number) {
    if (row === UpgraderSheetRows.NAME && typeof value === 'string') {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (row) {
        case UpgraderSheetRows.CCR_REMOVAL:
          this.simulationVariableMapper.map(value, unitOperation.ccrRemoval);
          break;

        case UpgraderSheetRows.NITROGEN_REMOVAL:
          this.simulationVariableMapper.map(value, unitOperation.nitrogenRemoval);
          break;

        case UpgraderSheetRows.SULFUR_REMOVAL:
          this.simulationVariableMapper.map(value, unitOperation.sulfurRemoval);
          break;

        case UpgraderSheetRows.SWEET_PRODUCT_RATIO:
          this.simulationVariableMapper.map(value, unitOperation.sweetProductRatio);
          break;

        case UpgraderSheetRows.SWEET_GHG_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.sweetGhgIntensity);
          break;

        case UpgraderSheetRows.SOUR_GHG_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.sourGhgIntensity);
          break;

        case UpgraderSheetRows.CAPACITY:
          this.simulationVariableMapper.map(value, unitOperation.capacity);
          break;

        case UpgraderSheetRows.COGEN_ELECTRICITY_GENERATION:
          this.simulationVariableMapper.map(value, unitOperation.cogenElectricityGeneration);
          break;

        case UpgraderSheetRows.NET_ELECTRICITY_EXPORT:
          this.simulationVariableMapper.map(value, unitOperation.netElectricityExport);
          break;

        case UpgraderSheetRows.COGEN_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.cogenIntensity);
          break;

        case UpgraderSheetRows.GRID_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.gridIntensity);
          break;

        case UpgraderSheetRows.SWEET_PRODUCT_PRICE:
          this.simulationVariableMapper.map(value, unitOperation.sweetProductPrice);
          break;

        case UpgraderSheetRows.SOUR_PRODUCT_PRICE:
          this.simulationVariableMapper.map(value, unitOperation.sourProductPrice);
          break;

        case UpgraderSheetRows.CAPEX_AMORTIZED:
          this.simulationVariableMapper.map(value, unitOperation.capexAmortized);
          break;

        case UpgraderSheetRows.OPEX_FIXED:
          this.simulationVariableMapper.map(value, unitOperation.opexFixed);
          break;

        case UpgraderSheetRows.OPEX_VAR:
          this.simulationVariableMapper.map(value, unitOperation.opexVar);
          break;

        case UpgraderSheetRows.OPEX_GAS_DIESEL:
          this.simulationVariableMapper.map(value, unitOperation.opexGasDiesel);
          break;

        case UpgraderSheetRows.OPEX_POWER:
          this.simulationVariableMapper.map(value, unitOperation.opexPower);
          break;
        case UpgraderSheetRows.DEA_USE_RATE:
          this.simulationVariableMapper.map(value, unitOperation.deaUseRate);
          break;
        case UpgraderSheetRows.AMINE_RATE_CAPACITY:
          this.simulationVariableMapper.map(value, unitOperation.amineRateCapacity);
          break;
      }
    }
  }
}
