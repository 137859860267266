import { AfterViewInit, Component, ElementRef } from '@angular/core';
import * as go from 'gojs';
import { Map } from 'gojs';
import { Case } from '../../_models/case';
import { FlowsheetDiagramTemplateProvider } from '../../_services/flowsheet-diagram/flowsheet-diagram-template-provider.service';
import { FlowsheetDiagramService } from '../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { UtilitiesFlowsheetTemplateProvider } from '../../_services/flowsheet-diagram/utilities-flowsheet-template-provider';
import { StreamCategory } from '../../_config/stream-category.enum';
import { OtherDiagramObject } from '../../_config/other-diagram.object';
import { OtherDiagramObjectsTemplateProvider } from '../../_services/flowsheet-diagram/other-diagram-objects-template-provider';
import { FuelGasFlowsheetTemplateProvider } from '../../_services/flowsheet-diagram/fuel-gas-flowsheet-template-provider';

@Component({
  selector: 'sob-flowsheet-diagram',
  templateUrl: './flowsheet-diagram.component.html',
  styleUrls: ['./flowsheet-diagram.component.css'],
})
export class FlowsheetDiagramComponent implements AfterViewInit {
  // $: any = go.GraphObject.make;

  // private disabledColor = '#999';

  constructor(
    el: ElementRef,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private templateProvider: FlowsheetDiagramTemplateProvider,
    private utilitiesFlowsheetProvider: UtilitiesFlowsheetTemplateProvider,
    private otherObjectsTemplateProvider: OtherDiagramObjectsTemplateProvider,
    private fuelGasFlowsheetProvider: FuelGasFlowsheetTemplateProvider
  ) {
    this.flowsheetDiagramService.updateModelRequest.subscribe(() => {
      this.flowsheetDiagramService.clearModel();
    });
  }

  ngAfterViewInit(): void {
    this.initFlowsheetDiagram();
  }

  initFlowsheetDiagram(): void {
    this.flowsheetDiagramService.gojsDiagram = this.templateProvider.getDiagramTemplate();
    this.flowsheetDiagramService.gojsDiagram.nodeTemplateMap = this.getNodeTemplateMap();
    this.flowsheetDiagramService.gojsDiagram.linkTemplateMap = this.getLinkTemplateMap();
    this.flowsheetDiagramService.gojsDiagram.model = this.templateProvider.getModelTemplate();
    this.flowsheetDiagramService.gojsDiagram.contextMenu = this.templateProvider.getContextMenuTemplate();

    this.flowsheetDiagramService.gojsDiagram.allowDrop = true;
    (this.flowsheetDiagramService.gojsDiagram.toolManager.rotatingTool as go.RotatingTool).snapAngleMultiple = 90;
    (this.flowsheetDiagramService.gojsDiagram.toolManager.rotatingTool as go.RotatingTool).snapAngleEpsilon = 45;
    this.flowsheetDiagramService.gojsDiagram.toolManager.dragSelectingTool.delay = 200;
    this.flowsheetDiagramService.gojsDiagram.animationManager.isEnabled = true;

    this.flowsheetDiagramService.gojsDiagram.grid.gridCellSize = new go.Size(20, 20);
    this.flowsheetDiagramService.gojsDiagram.toolManager.draggingTool.isGridSnapEnabled = true;
    this.flowsheetDiagramService.gojsDiagram.toolManager.resizingTool.isGridSnapEnabled = true;
    // this.flowsheetDiagramService.gojsDiagram.toolManager.draggingTool.gridSnapCellSpot = go.Spot.Center;

    this.flowsheetDiagramService.overview = this.templateProvider.getOverviewTemplate();
    this.flowsheetDiagramService.overview.observed = this.flowsheetDiagramService.gojsDiagram;

    $('#page-wrapper').bind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', () => {
      this.flowsheetDiagramService.gojsDiagram.requestUpdate();
    });

    $('#flowsheet-diagram-wrapper').on('resize', () => {
      this.flowsheetDiagramService.gojsDiagram.requestUpdate();
    });
  }

  private getLinkTemplateMap() {
    const linkTemplateMap = new Map<string, go.Link>();
    linkTemplateMap.add('', this.templateProvider.getLinkTemplate());
    linkTemplateMap.add(StreamCategory.MATERIAL_STREAM, this.templateProvider.getLinkTemplate());
    linkTemplateMap.add(StreamCategory.WATER_MATERIAL_STREAM, this.templateProvider.getWaterLinkTemplate());
    linkTemplateMap.add(StreamCategory.FUEL_GAS_MATERIAL_STREAM, this.templateProvider.getFuelGasLinkTemplate());
    return linkTemplateMap;
  }

  private getNodeTemplateMap() {
    const templateMap = new Map<string, go.Node>();
    templateMap.add(unitOperationsConfig.diluentSource.key, this.templateProvider.getDiluentSourceTemplate());
    templateMap.add(unitOperationsConfig.mine.key, this.templateProvider.getMineTemplate());
    templateMap.add(unitOperationsConfig.mixer.key, this.templateProvider.getMixerTemplate());
    templateMap.add(unitOperationsConfig.offshore.key, this.templateProvider.getOffshoreTemplate());
    templateMap.add(unitOperationsConfig.pipe.key, this.templateProvider.getPipeTemplate());
    templateMap.add(unitOperationsConfig.bitumenConversion.key, this.templateProvider.getBitumenConversionTemplate());
    templateMap.add(unitOperationsConfig.refinery.key, this.templateProvider.getRefineryTemplate());
    templateMap.add(
      unitOperationsConfig.multipleInletRefinery.key,
      this.templateProvider.getMultipleInletRefineryTemplate()
    );
    templateMap.add(unitOperationsConfig.suncorRenewableBlock.key, this.templateProvider.getRenewableTemplate());
    templateMap.add(unitOperationsConfig.sagd.key, this.templateProvider.getSagdTemplate());
    templateMap.add(unitOperationsConfig.splitter.key, this.templateProvider.getSplitterTemplate());
    templateMap.add(unitOperationsConfig.upgrader.key, this.templateProvider.getUpgraderTemplate());
    templateMap.add(unitOperationsConfig.upgraderInlet.key, this.templateProvider.getUpgraderInletTemplate());
    templateMap.add(
      unitOperationsConfig.multipleInletUpgrader.key,
      this.templateProvider.getMultipleInletUpgraderTemplate()
    );
    templateMap.add(unitOperationsConfig.flexibleUpgrader.key, this.templateProvider.getFlexibleUpgraderTemplate());
    templateMap.add(unitOperationsConfig.level2Upgrader.key, this.templateProvider.getLevel2UpgraderTemplate());
    templateMap.add(unitOperationsConfig.thirdPartyRefinery.key, this.templateProvider.getThirdPartyRefineryTemplate());
    templateMap.add(unitOperationsConfig.thirdPartySource.key, this.templateProvider.getThirdPartySourceTemplate());
    templateMap.add(unitOperationsConfig.dru.key, this.templateProvider.getDRUTemplate());
    templateMap.add(unitOperationsConfig.vac.key, this.templateProvider.getVACTemplate());
    templateMap.add(unitOperationsConfig.hydrotreater.key, this.templateProvider.getHydrotreaterTemplate());
    templateMap.add(unitOperationsConfig.hgoHydrotreater.key, this.templateProvider.getHgoHydrotreaterTemplate());
    templateMap.add(unitOperationsConfig.lgoHydrotreater.key, this.templateProvider.getLgoHydrotreaterTemplate());
    templateMap.add(unitOperationsConfig.dcu.key, this.templateProvider.getDCUTemplate());
    templateMap.add(unitOperationsConfig.hydrogenPlant.key, this.templateProvider.getHydrogenPlantTemplate());
    templateMap.add(unitOperationsConfig.sulfurPlant.key, this.templateProvider.getSulfurPlantTemplate());
    templateMap.add(unitOperationsConfig.fluidCoker.key, this.templateProvider.getFluidCokerTemplate());
    templateMap.add(unitOperationsConfig.genericSink.key, this.templateProvider.getGenericSinkTemplate());
    templateMap.add(
      unitOperationsConfig.separatedHydrotreater.key,
      this.templateProvider.getSeparatedHydrotreaterTemplate()
    );
    templateMap.add(unitOperationsConfig.productTank.key, this.templateProvider.getProductTankTemplate());
    templateMap.add(unitOperationsConfig.commodityTank.key, this.templateProvider.getCommodityTankTemplate());
    templateMap.add(unitOperationsConfig.hydrocracker.key, this.templateProvider.getHydrocrackerTemplate());
    templateMap.add(unitOperationsConfig.extraction.key, this.templateProvider.getExtractionTemplate());
    templateMap.add(unitOperationsConfig.flexibleExtraction.key, this.templateProvider.getFlexibleExtractionTemplate());
    templateMap.add(unitOperationsConfig.inletTransferUnit.key, this.templateProvider.getInletTransferUnitTemplate());
    templateMap.add(unitOperationsConfig.outletTransferUnit.key, this.templateProvider.getOutletTransferUnitTemplate());

    templateMap.add(unitOperationsConfig.waterBoiler.key, this.utilitiesFlowsheetProvider.getWaterBoilerTemplate());
    templateMap.add(
      unitOperationsConfig.waterUtilityUnitOperation.key,
      this.templateProvider.getWaterUtilityTemplate()
    );

    templateMap.add(unitOperationsConfig.waterSource.key, this.utilitiesFlowsheetProvider.getWaterSourceTemplate());
    templateMap.add(
      unitOperationsConfig.waterSourceImport.key,
      this.utilitiesFlowsheetProvider.getWaterSourceImportTemplate()
    );
    templateMap.add(
      unitOperationsConfig.waterSinkImport.key,
      this.utilitiesFlowsheetProvider.getWaterSinkImportTemplate()
    );
    templateMap.add(
      unitOperationsConfig.waterHeatExchanger.key,
      this.utilitiesFlowsheetProvider.getHeatExchangerTemplate()
    );
    templateMap.add(unitOperationsConfig.waterTurbine.key, this.utilitiesFlowsheetProvider.getTurbineTemplate());
    templateMap.add(unitOperationsConfig.waterHeater.key, this.utilitiesFlowsheetProvider.getHeaterTemplate());
    templateMap.add(unitOperationsConfig.waterCooler.key, this.utilitiesFlowsheetProvider.getCoolerTemplate());
    templateMap.add(
      unitOperationsConfig.waterTwoPhaseSeparator.key,
      this.utilitiesFlowsheetProvider.getTwoPhaseSeparatorTemplate()
    );
    templateMap.add(unitOperationsConfig.waterPump.key, this.utilitiesFlowsheetProvider.getPumpTemplate());
    templateMap.add(unitOperationsConfig.waterValve.key, this.utilitiesFlowsheetProvider.getValveTemplate());
    templateMap.add(unitOperationsConfig.waterSink.key, this.utilitiesFlowsheetProvider.getSinkTemplate());
    templateMap.add(unitOperationsConfig.waterMixer.key, this.utilitiesFlowsheetProvider.getWaterMixerTemplate());
    templateMap.add(unitOperationsConfig.waterSplitter.key, this.utilitiesFlowsheetProvider.getWaterSplitterTemplate());
    templateMap.add(unitOperationsConfig.waterHeader.key, this.utilitiesFlowsheetProvider.getWaterHeaderTemplate());
    templateMap.add(unitOperationsConfig.waterPipe.key, this.utilitiesFlowsheetProvider.getWaterPipeTemplate());
    templateMap.add(
      unitOperationsConfig.waterCogeneration.key,
      this.utilitiesFlowsheetProvider.getWaterCogenerationTemplate()
    );

    templateMap.add(unitOperationsConfig.fuelGasMixer.key, this.fuelGasFlowsheetProvider.getFuelGasMixerTemplate());
    templateMap.add(
      unitOperationsConfig.fuelGasUtilityUnitOperation.key,
      this.templateProvider.getFuelGasUtilityTemplate()
    );
    templateMap.add(unitOperationsConfig.fuelGasFlare.key, this.fuelGasFlowsheetProvider.getFuelGasFlareTemplate());
    templateMap.add(
      unitOperationsConfig.fuelGasSinkImport.key,
      this.fuelGasFlowsheetProvider.getFuelGasSinkImportTemplate()
    );
    templateMap.add(
      unitOperationsConfig.fuelGasSourceImport.key,
      this.fuelGasFlowsheetProvider.getFuelGasSourceImportTemplate()
    );
    templateMap.add(unitOperationsConfig.fuelGasSink.key, this.fuelGasFlowsheetProvider.getFuelGasSinkTemplate());
    templateMap.add(unitOperationsConfig.fuelGasSource.key, this.fuelGasFlowsheetProvider.getFuelGasSourceTemplate());
    templateMap.add(
      unitOperationsConfig.fuelGasSplitter.key,
      this.fuelGasFlowsheetProvider.getFuelGasSplitterTemplate()
    );
    templateMap.add(
      unitOperationsConfig.fuelGasAmineContactor.key,
      this.fuelGasFlowsheetProvider.getFuelGasAmineContactorTemplate()
    );

    templateMap.add(OtherDiagramObject.DIAGRAM_COMMENT, this.otherObjectsTemplateProvider.getDiagramCommentsTemplate());
    return templateMap;
  }
}
