import { Injectable } from '@angular/core';
import * as go from 'gojs';
import { FlowsheetDiagramService } from './flowsheet-diagram.service';
import { GojsInletPortOptions, GojsOutletPortOptions } from '../../_models/_gojs/gojs-port-options';
import { isTypeNumber } from '../../_utils/utils';
import { UnitOperationReadiness } from '../../_config/unit-operations/unit-operation-readiness';
import { AlignmentString } from '../../_utils/optea-utils';

@Injectable()
export class GoJsDiagramUtils {
  $: any = go.GraphObject.make;

  constructor(private flowsheetDiagramService: FlowsheetDiagramService) {}

  public createNodeSelectionTemplate() {
    return this.$(
      go.Adornment,
      'Auto',
      this.$(go.Shape, 'Rectangle', { fill: null, stroke: 'dodgerblue', strokeWidth: 2 }),
      this.$(go.Placeholder)
    );
  }

  /**
   * A background for the nodes that will prevent mistakenly dragging the diagram
   * when trying to drag a node and clicking on an empty space
   */
  public getNodeTransparentBackground() {
    return this.$(go.Shape, { margin: 0, figure: 'Rectangle', fill: 'Transparent', stroke: null });
  }

  getNameTextBlockTemplate(options: {
    textAlign: AlignmentString;
    marginTop?: number;
    marginRight?: number;
    marginLeft?: number;
  }) {
    return this.$(
      go.TextBlock,
      {
        textAlign: options.textAlign,
        margin: new go.Margin(options.marginTop || 0, options.marginRight || 0, 0, options.marginLeft || 0),
        maxSize: new go.Size(150, 400),
        wrap: go.TextBlock.WrapDesiredSize,
        stroke: '#0b84b6',
        font: '15px sans-serif',
      },
      new go.Binding('text', 'name'),
      new go.Binding('opacity', '', n => {
        return n.data.hiddenLabel ? 0 : 1;
      }).ofObject()
    );
  }

  getAllSidesBusyReadinessIndicator() {
    return this.getReadinessIndicatorTemplate({ x: 0.95, y: 0, offsetY: -3 });
  }

  getTopRightReadinessIndicator() {
    return this.getReadinessIndicatorTemplate({ x: 1, y: 0, offsetX: 2 });
  }

  getTopLeftReadinessIndicator() {
    return this.getReadinessIndicatorTemplate({ x: 0, y: 0, offsetX: -2 });
  }

  getTopCenterReadinessIndicator() {
    return this.getReadinessIndicatorTemplate({ x: 0.5, y: 0, offsetY: -10 });
  }

  getCenterRightReadinessIndicator() {
    return this.getReadinessIndicatorTemplate({ x: 1, y: 0.5, offsetX: 10 });
  }

  getBottomCenterReadinessIndicator(offsetY?: number) {
    return this.getReadinessIndicatorTemplate({ x: 0.5, y: 1, offsetY: isTypeNumber(offsetY) ? offsetY : 11 });
  }

  getReadinessIndicatorTemplate(options: { x: number; y: number; offsetX?: number; offsetY?: number }) {
    return this.$(
      go.Panel,
      'Auto',
      {
        alignment: new go.Spot(options.x, options.y, options.offsetX || 0, options.offsetY || 0),
      },
      this.$(
        go.TextBlock,
        {
          name: 'ICON',
          margin: 5,
          font: 'bold 14pt FontAwesome',
        },
        {
          toolTip: this.$(
            go.Adornment,
            'Auto',
            this.$(go.Shape, {
              stroke: 'transparent',
              fill: '#CEF6F5',
            }),

            this.$(
              go.TextBlock,
              { margin: 4 },
              new go.Binding('text', '', n => {
                return this.getReadinessTooltipText(n.data.readiness);
              }).ofObject()
            )
          ),
        },
        new go.Binding('stroke', '', n => {
          return this.getReadinessColor(n.data.readiness);
        }).ofObject(),
        new go.Binding('text', '', n => {
          return this.getReadinessIcon(n.data.readiness);
        }).ofObject()
      ),
      new go.Binding('visible', '', n => {
        const { readiness } = n.data;
        return readiness !== UnitOperationReadiness.READY;
      }).ofObject()
    );
  }

  private getReadinessColor(readiness: UnitOperationReadiness) {
    if (readiness === UnitOperationReadiness.NOT_FULLY_SPECIFIED) {
      return 'red';
    }

    if (readiness === UnitOperationReadiness.NOT_FULLY_CONNECTED) {
      return 'red';
    }

    return 'gray';
  }

  private getReadinessIcon(readiness: UnitOperationReadiness) {
    if (readiness === UnitOperationReadiness.NOT_FULLY_SPECIFIED) {
      return '\uf06a';
    }

    if (readiness === UnitOperationReadiness.NOT_FULLY_CONNECTED) {
      return '\uf06a';
    }

    return '\uf059';
  }

  private getReadinessTooltipText(readiness: UnitOperationReadiness) {
    if (readiness === UnitOperationReadiness.NOT_FULLY_SPECIFIED) {
      return 'Not fully specified';
    }

    if (readiness === UnitOperationReadiness.NOT_FULLY_CONNECTED) {
      return 'Not fully connected';
    }

    return 'Unknown status';
  }

  createOutletPort(initializers: GojsOutletPortOptions) {
    return this.$(
      go.Panel,
      { alignment: initializers.alignment, cursor: 'pointer' },
      this.$(
        go.Shape,
        {
          width: initializers.width || 6,
          height: initializers.height || 6,
          portId: initializers.portId,
          name: initializers.name || '',
          fill: '#4972b0',
          stroke: 'Transparent',
          fromMaxLinks: isTypeNumber(initializers.fromMaxLinks) ? initializers.fromMaxLinks : Infinity,
          fromLinkable: true,
          fromSpot: initializers.fromSpot || go.Spot.RightSide,
        },
        new go.Binding('fromMaxLinks', 'fromMaxLinks'),
        {
          toolTip: this.createPortTooltip(initializers.toolTip),
        }
      )
    );
  }

  createInletPort(initializers: GojsInletPortOptions) {
    return this.$(
      go.Panel,
      'Auto',
      { alignment: initializers.alignment, cursor: 'pointer' },
      this.$(
        go.Shape,
        {
          width: initializers.width || 6,
          height: initializers.height || 6,
          margin: initializers.margin || 0,
          portId: initializers.portId,
          name: initializers.name,
          fill: '#4972b0',
          stroke: 'Transparent',
          toLinkable: true,
          toMaxLinks: isTypeNumber(initializers.toMaxLinks) ? initializers.toMaxLinks : Infinity,
          toSpot: initializers.toSpot || go.Spot.LeftSide,
        },
        {
          toolTip: this.createPortTooltip(initializers.toolTip),
        },
        new go.Binding('height', 'height')
      )
    );
  }

  createNodeTooltip() {
    return this.$(
      go.Adornment,
      'Auto',
      this.$(
        go.Shape,
        {
          stroke: 'transparent',
        },
        new go.Binding('fill', '', n => {
          return n.hiddenLabel ? '#CEF6F5' : 'transparent';
        })
      ),
      this.$(
        go.TextBlock,
        {
          margin: 4,
        },
        new go.Binding('text', '', n => {
          return n.hiddenLabel ? n.name : '';
        })
      )
    );
  }

  createPortTooltip(text: string) {
    return this.$(
      go.Adornment,
      'Auto',
      this.$(go.Shape, {
        fill: '#CEF6F5',
        stroke: 'transparent',
      }),
      this.$(go.TextBlock, {
        margin: 4,
        text,
      })
    );
  }

  getClickEventHandlers() {
    return {
      doubleClick: (e, node) => {
        this.flowsheetDiagramService.doubleClickOnNode(node.data.id);
      },
      contextClick: (e, node) => {
        this.flowsheetDiagramService.contextClickOnNode(node.data.id);
      },
    };
  }

  toLocation(data: { x: number; y: number }) {
    return new go.Point(data.x, data.y);
  }

  getDeletableBinding() {
    return new go.Binding('deletable', 'deletable', deletable => (typeof deletable === 'undefined' ? true : deletable));
  }

  // region links
  createLinkToolTip() {
    return this.$(
      go.Adornment,
      'Auto',
      this.$(go.Shape, {
        fill: '#CEF6F5',
        stroke: 'DarkGray',
      }),
      this.$(
        go.Panel,
        'Vertical',
        { padding: new go.Margin(3, 5, 1, 5) },
        this.$(go.TextBlock, { alignment: go.Spot.Left }, new go.Binding('text', 'name')),
        this.$(go.TextBlock, { alignment: go.Spot.Left }, new go.Binding('text', 'connectionName')),
        this.$(
          go.Panel,
          'Table',
          { defaultStretch: go.GraphObject.Horizontal, alignment: go.Spot.Left, margin: new go.Margin(3, 0, 0, 0) },
          this.$(go.TextBlock, { row: 0, column: 0, margin: new go.Margin(3, 7, 0, 0), text: 'Volumetric flowrate' }),
          this.$(
            go.TextBlock,
            { row: 0, column: 1, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'volumetricFlowrate')
          ),
          this.$(
            go.TextBlock,
            { row: 0, column: 2, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'volumetricFlowrateUnit')
          ),

          this.$(go.TextBlock, { row: 1, column: 0, margin: new go.Margin(3, 7, 0, 0), text: 'GHG intensity' }),
          this.$(
            go.TextBlock,
            { row: 1, column: 1, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'ghgIntensity')
          ),
          this.$(
            go.TextBlock,
            { row: 1, column: 2, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'ghgIntensityUnit')
          )
        )
      )
    );
  }

  createWaterLinkToolTip() {
    return this.$(
      go.Adornment,
      'Auto',
      this.$(go.Shape, {
        fill: '#CEF6F5',
        stroke: 'DarkGray',
      }),
      this.$(
        go.Panel,
        'Vertical',
        { padding: new go.Margin(3, 5, 1, 5) },
        this.$(go.TextBlock, { alignment: go.Spot.Left }, new go.Binding('text', 'name')),
        this.$(go.TextBlock, { alignment: go.Spot.Left }, new go.Binding('text', 'connectionName')),
        this.$(
          go.Panel,
          'Table',
          { defaultStretch: go.GraphObject.Horizontal, alignment: go.Spot.Left, margin: new go.Margin(3, 0, 0, 0) },
          this.$(go.TextBlock, { row: 0, column: 0, margin: new go.Margin(3, 7, 0, 0), text: 'Mass Flowrate' }),
          this.$(
            go.TextBlock,
            { row: 0, column: 1, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'massFlowrate')
          ),
          this.$(
            go.TextBlock,
            { row: 0, column: 2, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'massFlowrateUnit')
          ),

          this.$(go.TextBlock, { row: 1, column: 0, margin: new go.Margin(3, 7, 0, 0), text: 'Temperature' }),
          this.$(
            go.TextBlock,
            { row: 1, column: 1, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'temperature')
          ),
          this.$(
            go.TextBlock,
            { row: 1, column: 2, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'temperatureUnit')
          ),

          this.$(go.TextBlock, { row: 2, column: 0, margin: new go.Margin(3, 7, 0, 0), text: 'Pressure' }),
          this.$(
            go.TextBlock,
            { row: 2, column: 1, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'pressure')
          ),
          this.$(
            go.TextBlock,
            { row: 2, column: 2, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'pressureUnit')
          )
        )
      )
    );
  }

  createFuelGasLinkToolTip() {
    return this.$(
      go.Adornment,
      'Auto',
      this.$(go.Shape, {
        fill: '#CEF6F5',
        stroke: 'DarkGray',
      }),
      this.$(
        go.Panel,
        'Vertical',
        { padding: new go.Margin(3, 5, 1, 5) },
        this.$(go.TextBlock, { alignment: go.Spot.Left }, new go.Binding('text', 'name')),
        this.$(go.TextBlock, { alignment: go.Spot.Left }, new go.Binding('text', 'connectionName')),
        this.$(
          go.Panel,
          'Table',
          { defaultStretch: go.GraphObject.Horizontal, alignment: go.Spot.Left, margin: new go.Margin(3, 0, 0, 0) },
          this.$(go.TextBlock, { row: 0, column: 0, margin: new go.Margin(3, 7, 0, 0), text: 'Flowrate' }),
          this.$(
            go.TextBlock,
            { row: 0, column: 1, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'flowrate')
          ),
          this.$(
            go.TextBlock,
            { row: 0, column: 2, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'flowrateUnit')
          ),

          this.$(go.TextBlock, {
            row: 1,
            column: 0,
            margin: new go.Margin(3, 7, 0, 0),
            text: 'Heat Flow (LHV * Flow)',
          }),
          this.$(
            go.TextBlock,
            { row: 1, column: 1, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'heatFlow')
          ),
          this.$(
            go.TextBlock,
            { row: 1, column: 2, margin: new go.Margin(3, 7, 0, 0) },
            new go.Binding('text', 'heatFlowUnit')
          ),

          this.$(go.TextBlock, { row: 1, column: 0, margin: new go.Margin(35, 7, 0, 0), text: 'LHV' }),
          this.$(
            go.TextBlock,
            { row: 1, column: 1, margin: new go.Margin(35, 7, 0, 0) },
            new go.Binding('text', 'lhv')
          ),
          this.$(
            go.TextBlock,
            { row: 1, column: 2, margin: new go.Margin(35, 7, 0, 0) },
            new go.Binding('text', 'lhvUnit')
          )
        )
      )
    );
  }
  // endregion
}
