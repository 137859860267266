import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GlobalErrorModalService {
  protected errorOccurredSubject = new Subject<unknown>();
  errorOccurred$ = this.errorOccurredSubject.asObservable();

  reportError(error: unknown) {
    this.errorOccurredSubject.next(error);
  }
}
