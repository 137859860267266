import { BaseObject } from '../../base-object';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';

export class WaterSourceInformationStream extends BaseObject {
  category = 'water-source-information-stream';

  temperature: SimulationVariable;
  pressure: SimulationVariable;
  massFlowrate: SimulationVariable;
  vaporFraction: SimulationVariable;
  simulationVariableId: string;
  providerUnitOperationId: string;

  constructor(waterSourceInformationStream: WaterSourceInformationStream | any, ownerCase: Case) {
    super(waterSourceInformationStream.id, ownerCase);
    this.initValues(waterSourceInformationStream);
    this.setQuantities();
  }

  private initValues(waterSourceInformationStream: WaterSourceInformationStream | any) {
    this.simulationVariableId = waterSourceInformationStream.simulationVariableId;
    this.providerUnitOperationId = waterSourceInformationStream.providerUnitOperationId;
    this.temperature = new SimulationVariable(waterSourceInformationStream.temperature, this.ownerCase, this);
    this.pressure = new SimulationVariable(waterSourceInformationStream.pressure, this.ownerCase, this);
    this.massFlowrate = new SimulationVariable(waterSourceInformationStream.massFlowrate, this.ownerCase, this);
    this.vaporFraction = new SimulationVariable(waterSourceInformationStream.vaporFraction, this.ownerCase, this);
  }

  setQuantities() {
    this.temperature.setQuantity(Quantity.TEMPERATURE);
    this.pressure.setQuantity(Quantity.PRESSURE);
    this.massFlowrate.setQuantity(Quantity.MASSFLOWRATE);
    this.vaporFraction.setQuantity(Quantity.FRACTION);
  }

  getFullName(): string {
    return this.getSimulationVariable().getFullName();
  }

  getSimulationVariable(): SimulationVariable {
    return this.ownerCase.getSimulationVariable(this.simulationVariableId);
  }

  override dePersist(persistedObj: any) {
    super.dePersist(persistedObj);
    this.temperature = this.getSimulationVariableSafe(persistedObj.temperature);
    this.pressure = this.getSimulationVariableSafe(persistedObj.pressure);
    this.massFlowrate = this.getSimulationVariableSafe(persistedObj.massFlowrate);
    this.vaporFraction = this.getSimulationVariableSafe(persistedObj.vaporFraction);
    this.setSimulationVariablesOwner();
    this.setQuantities();
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      simulationVariableId: this.simulationVariableId,
      providerUnitOperationId: this.providerUnitOperationId,
      temperature: this.temperature.id,
      pressure: this.pressure.id,
      massFlowrate: this.massFlowrate.id,
      vaporFraction: this.vaporFraction.id,
    };
  }
}
