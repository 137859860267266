import { IUnitOperation } from '../i-unit-operation';
import { SuncorUnitOperation } from './suncor-unit-operation';
import { Case } from '../case';
import { MixerBlendRecipeOption, MixerConstraintOption } from '../../_config/unit-operations/mixer-enums';
import { DefaultMappedType } from '../default-mapped-type';
import { isTypeUndefined } from '../../_utils/utils';
import { RangeDistributionRatioVariable } from './range-distribution-ratio-variable';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { InletDistributionRatioVariablesOwner } from './inlet-distribution-ratio-variables-owner';
import { MixingConstraints } from './mixing-constraints';
import { RangeDistributionConstraint } from './range-distribution-constraint';

export class Mixer extends SuncorUnitOperation implements IUnitOperation, InletDistributionRatioVariablesOwner {
  category = unitOperationsConfig.mixer.key;
  constraintOption: MixerConstraintOption;
  blendRecipeOption: MixerBlendRecipeOption;
  mixingRatioVariables: RangeDistributionRatioVariable[] = [];
  constraints: MixingConstraints;

  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    // TODO: ...
    this.initValues(unitOperation);
    this.setQuantities();
  }

  getAlternativeGhgIntensity() {
    return undefined;
  }

  addInletDistributionRatioVariables(unitOperationId: string) {
    this.addMixingRatioVariable(unitOperationId);
  }

  private addMixingRatioVariable(unitOperationId: string) {
    this.clearAllMixingRatioVariables();
    const rangeDistributionRatioVar = new RangeDistributionRatioVariable({ unitOperationId });
    this.mixingRatioVariables.push(rangeDistributionRatioVar);

    if (this.mixingRatioVariables.length === 1) {
      this.mixingRatioVariables[0].value = 1;
    }

    this.createConstraintsObjInstance();
    this.initConstraintsBasedInMixingRatios(rangeDistributionRatioVar);
  }

  findMixingRatioVariable(unitOperationId: string): RangeDistributionRatioVariable | undefined {
    return this.mixingRatioVariables.find(drv => {
      return drv.unitOperationId === unitOperationId;
    });
  }

  removeInletDistributionRatioVariables(unitOperationId: string) {
    const index = this.mixingRatioVariables.indexOf(this.findMixingRatioVariable(unitOperationId));

    if (index >= 0) {
      this.mixingRatioVariables.splice(index, 1);
      this.constraints.removeConstraint(unitOperationId);
    }
  }

  clearAllMixingRatioVariables() {
    for (const dv of this.mixingRatioVariables) {
      dv.value = null;
    }
  }

  override initValues(unitOperation: DefaultMappedType<Mixer>) {
    super.initValues(unitOperation);

    this.constraintOption = unitOperation.constraintOption || MixerConstraintOption.NONE;
    this.blendRecipeOption = isTypeUndefined(unitOperation.blendRecipeOption)
      ? MixerBlendRecipeOption.EXACT
      : unitOperation.blendRecipeOption;

    if (unitOperation.mixingRatioVariables instanceof Array) {
      this.createConstraintsObjInstance(unitOperation);
      for (const dv of unitOperation.mixingRatioVariables) {
        const rangeDistributionRatioVar = new RangeDistributionRatioVariable(dv);
        this.mixingRatioVariables.push(rangeDistributionRatioVar);
        this.initConstraintsBasedInMixingRatios(rangeDistributionRatioVar);
      }
    }
  }

  initConstraintsBasedInMixingRatios(rangeDistributionRatioVar: RangeDistributionRatioVariable) {
    if (this.constraintOption === MixerConstraintOption.BLEND_RATIO) {
      const unitOpId = rangeDistributionRatioVar.unitOperationId;
      const unitOperation = this.ownerCase.getUnitOperation(unitOpId);

      if (this.blendRecipeOption === MixerBlendRecipeOption.RANGE) {
        if (
          this.constraints.constraints[`minimumRange${unitOpId}`] &&
          this.constraints.constraints[`maximumRange${unitOpId}`]
        )
          return;

        this.constraints.addNewConstraint(
          `minimumRange${unitOpId}`,
          unitOperation ? `Minimum ${unitOperation.name} Ratio` : 'Minimum',
          rangeDistributionRatioVar.minimumValue,
          unitOpId
        );
        this.constraints.addNewConstraint(
          `maximumRange${unitOpId}`,
          unitOperation ? `Maximum ${unitOperation.name} Ratio` : 'Maximum',
          rangeDistributionRatioVar.maximumValue,
          unitOpId
        );
      }

      if (this.blendRecipeOption === MixerBlendRecipeOption.EXACT) {
        if (this.constraints.constraints[`exactValue${unitOpId}`]) return;
        this.constraints.addNewConstraint(
          `exactValue${unitOpId}`,
          unitOperation ? `Exact ${unitOperation.name} Ratio` : 'Exact',
          rangeDistributionRatioVar.value,
          unitOpId
        );
      }
    }
  }

  initConstraintsBasedInExistingConstraints(unitOperation: DefaultMappedType<Mixer>) {
    Object.entries(unitOperation.constraints.constraints).forEach((entry: [string, RangeDistributionConstraint]) => {
      this.constraints.addNewConstraint(
        entry[0],
        entry[1].constraintName,
        entry[1].constraintValue,
        entry[1].sourceUnitOperationId,
        entry[1].isActive
      );
    });
  }

  createConstraintsObjInstance(unitOperation?: DefaultMappedType<Mixer>) {
    if (isTypeUndefined(this.constraints)) {
      this.constraints = new MixingConstraints();
    }
    if (isTypeUndefined(unitOperation)) return;
    if (!isTypeUndefined(unitOperation.constraints)) {
      if (Object.keys(unitOperation.constraints.constraints).length > 0)
        this.initConstraintsBasedInExistingConstraints(unitOperation);
    }
  }
}
