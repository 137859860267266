export class MarginalValueEntry {
  unitOperationName: string;
  variableName: string;
  value: number;
  unit: string;
  objFunctionChangeRate: number;
  objFunctionChangeRateUnit: string;
  ownerOperationId: string;

  constructor(entry: any) {
    this.unitOperationName = entry.unitOperationName;
    this.variableName = entry.variableName;
    this.value = entry.value;
    this.unit = entry.unit;
    this.objFunctionChangeRate = entry.objFunctionChangeRate;
    this.objFunctionChangeRateUnit = entry.objFunctionChangeRateUnit;
    this.ownerOperationId = entry.ownerOperationId;
  }
}
