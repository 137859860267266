<div class="form-horizontal">
  <h4 class="subtitle">Inlet</h4>

  <ul *ngFor="let inletStream of inletStreams" class="inletStreamsList">
    <li>{{ inletStream.inletUnitOperation.name }}</li>
  </ul>

  <div *ngIf="!inletStreams.length" class="outletStreamName">No connections available</div>

  <h4 class="subtitle" style="margin-top: 20px">Outlet</h4>
  <div *ngIf="outletStream" class="outletStreamName">{{ outletStream.outletUnitOperation.name }}</div>
  <div *ngIf="!outletStream" class="outletStreamName">No connection available</div>
</div>

<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#fuelGasMixerComments" aria-controls="fuelGasMixerComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="fuelGasMixerComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
