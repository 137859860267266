import { UntypedFormGroup } from '@angular/forms';
import { isPrimitiveType } from '../_utils/utils';
import { isPlainBasicSimulationVariable } from '../_utils/optea-utils';

export class DefaultBaseObjectFormChangeDetector {
  constructor(private formGroup: UntypedFormGroup) {}

  detectChangesInValues(originalFormValue: any, currentFormValue: any): boolean {
    const changes = false;
    const currentValueKeys = Object.keys(currentFormValue);

    for (const k of currentValueKeys) {
      if (!this.ignoreForDefaultChangeDetection(k)) {
        const originalValue = originalFormValue[k];
        const currentValue = currentFormValue[k];

        if (isPrimitiveType(originalValue)) {
          if (currentValue !== originalValue) {
            return true;
          }
        } else if (isPlainBasicSimulationVariable(originalValue) && isPlainBasicSimulationVariable(currentValue)) {
          if (originalValue.value !== currentValue.value) {
            return true;
          }
        } else {
          console.warn(`Unimplemented equality comparison at ${k} for ${currentValue} and ${originalValue}`);
        }
      }
    }

    return changes;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ignoreForDefaultChangeDetection(k: string) {
    return false;
  }
}
