import { SimulationVariable } from '../simulation-variable';
import { UncertainParameter } from './uncertain-parameter';

export class ParameterSensitivity {
  kpi: SimulationVariable;
  parameter: UncertainParameter;
  // not sure if these properties will be used or not
  partialDifferential: number;
  oneAtATime: number;
  standardRegression: number;
  rankedRegression: number;
  tornadoMean: number;
  tornadoMin: number;
  tornadoMax: number;
  tornadoRank: number;

  constructor(sensitivity: any) {
    if (sensitivity.kpi instanceof SimulationVariable && sensitivity.parameter instanceof UncertainParameter) {
      this.kpi = sensitivity.kpi;
      this.parameter = sensitivity.parameter;
      this.partialDifferential = sensitivity.partialDifferential;
      this.oneAtATime = sensitivity.oneAtATime;
      this.standardRegression = sensitivity.standardRegression;
      this.rankedRegression = sensitivity.rankedRegression;
      this.tornadoMean = sensitivity.tornadoMean;
      this.tornadoMin = sensitivity.tornadoMin;
      this.tornadoMax = sensitivity.tornadoMax;
      this.tornadoRank = sensitivity.tornadoRank;
    } else {
      this.kpi = sensitivity.kpi;
      this.parameter = sensitivity.parameter;
      this.partialDifferential = sensitivity.partialDifferential;
      this.oneAtATime = sensitivity.oneAtATime;
      this.standardRegression = sensitivity.standardRegression;
      this.rankedRegression = sensitivity.rankedRegression;
      this.tornadoMean = sensitivity.tornadoMean;
      this.tornadoMin = sensitivity.tornadoMin;
      this.tornadoMax = sensitivity.tornadoMax;
      this.tornadoRank = sensitivity.tornadoRank;
    }
  }

  getTornadoMeanCapture(asNumber: boolean) {
    return new SimulationVariable(
      {
        value: this.tornadoMean,
        unit: this.kpi.unit,
        quantity: this.kpi.quantity,
      },
      null,
      null
    ).convertToCaptureUnit(asNumber);
  }

  getTornadoMinCapture(asNumber: boolean) {
    return new SimulationVariable(
      {
        value: this.tornadoMin,
        unit: this.kpi.unit,
        quantity: this.kpi.quantity,
      },
      null,
      null
    ).convertToCaptureUnit(asNumber);
  }

  getTornadoMaxCapture(asNumber: boolean) {
    return new SimulationVariable(
      {
        value: this.tornadoMax,
        unit: this.kpi.unit,
        quantity: this.kpi.quantity,
      },
      null,
      null
    ).convertToCaptureUnit(asNumber);
  }

  public toJSON() {
    return {
      kpi: this.kpi.id,
      parameter: this.parameter.id,
      partialDifferential: this.partialDifferential,
      oneAtATime: this.oneAtATime,
      standardRegression: this.standardRegression,
      rankedRegression: this.rankedRegression,
      tornadoMean: this.tornadoMean,
      tornadoMin: this.tornadoMin,
      tornadoMax: this.tornadoMax,
      tornadoRank: this.tornadoRank,
    };
  }
}
