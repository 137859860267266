import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

/**
 * Service for getting a date based on server's date
 * Created for avoiding possible wrong dates if user's computer time is not correctly set
 */
@Injectable()
export class ServerUtcDateService {
  // the date retrieved from the server
  private serverSeedDate: Date;

  // local user computer time
  private retrievedAt: Date;

  constructor(private http: HttpClient) {}

  /**
   * Sets the base date for calculating an accurate date according to the server (and not according to user's machine)
   */
  setServerSeedDate(serializedSeedDate: string) {
    this.serverSeedDate = new Date(serializedSeedDate);
    this.retrievedAt = new Date();
  }

  /**
   * Gets a date that is calculated based on the seed date from the server
   * Adds the elapsed time since the seed date was retrieved from the server
   *
   * May have an offset of -1 seconds due to response time of the server when the date is retrieved
   */
  getCurrentDate(): Date {
    const elapsedTime = new Date().getTime() - this.retrievedAt.getTime();
    return new Date(this.serverSeedDate.getTime() + elapsedTime);
  }

  getServerSeedDate(): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}/getCurrentUtcDate`);
  }
}
