import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { SuncorUnitOperation } from './suncor-unit-operation';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { IUnitOperation } from '../i-unit-operation';
import { BaseObject } from '../base-object';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { RangeDistributionRatioVariable } from './range-distribution-ratio-variable';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../_case-study/kpi-provider';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { CommodityTankConstraintOption } from '../../_config/unit-operations/commodity-tank-enums';

declare let unitConverter: any;

export class CommodityTank
  extends SuncorUnitOperation
  implements IUnitOperation, ConstrainableObject, MultiPeriodParameterProvider, KpiProvider, ParameterProvider
{
  category = unitOperationsConfig.commodityTank.key;
  distributionRatioVariables: Array<RangeDistributionRatioVariable> = [];
  floatTank: boolean;
  estimateProductValue: boolean;
  productPrice: SimulationVariable;
  constraints: UnitOperationConstraints;
  commodityVolume: SimulationVariable;
  maxSulfur: SimulationVariable;
  maxDensity: SimulationVariable;
  maxNitrogen: SimulationVariable;
  blendRecipeOption: string;
  constraintOption: string;

  commoditySulfur: SimulationVariable;
  commodityNitrogen: SimulationVariable;
  commodityDensity: SimulationVariable;

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis(): SimulationVariable[] {
    return [
      this.productPrice,
      this.commodityVolume,
      this.commoditySulfur,
      this.commodityNitrogen,
      this.commodityDensity,
    ];
  }

  getAvailableParametricStudyParameters(): Array<SimulationVariable> {
    return [this.productPrice];
  }

  getAvailableParameters(): Array<SimulationVariable> {
    return undefined;
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.productPrice = new SimulationVariable(unitOperation.productPrice, this.ownerCase, this);
    this.commodityVolume = new SimulationVariable(unitOperation.commodityVolume, this.ownerCase, this);
    this.maxSulfur = new SimulationVariable(unitOperation.maxSulfur, this.ownerCase, this);
    this.maxDensity = new SimulationVariable(unitOperation.maxDensity, this.ownerCase, this);
    this.maxNitrogen = new SimulationVariable(unitOperation.maxNitrogen, this.ownerCase, this);

    this.commoditySulfur = new SimulationVariable(unitOperation.commoditySulfur, this.ownerCase, this);
    this.commodityNitrogen = new SimulationVariable(unitOperation.commodityNitrogen, this.ownerCase, this);
    this.commodityDensity = new SimulationVariable(unitOperation.commodityDensity, this.ownerCase, this);

    this.constraintOption = unitOperation.constraintOption;
    if (typeof unitOperation.floatTank === 'undefined') {
      this.floatTank = false;
    } else {
      this.floatTank = !!unitOperation.floatTank;
    }
    if (typeof unitOperation.estimateProductValue === 'undefined') {
      this.estimateProductValue = true;
    } else {
      this.estimateProductValue = !!unitOperation.estimateProductValue;
    }
    if (unitOperation.distributionRatioVariables instanceof Array) {
      for (const dv of unitOperation.distributionRatioVariables) {
        this.distributionRatioVariables.push(new RangeDistributionRatioVariable(dv));
      }
    }
    if (typeof unitOperation.blendRecipeOption === 'undefined') {
      this.blendRecipeOption = 'exact';
    } else {
      this.blendRecipeOption = unitOperation.blendRecipeOption;
    }

    this.initConstraints();
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
    const s = another as CommodityTank;
    this.name = another.name;
    this.floatTank = s.floatTank;
    this.estimateProductValue = s.estimateProductValue;
    for (let i = 0; i < s.distributionRatioVariables.length; i++) {
      this.distributionRatioVariables[i].value = s.distributionRatioVariables[i].value;
      this.distributionRatioVariables[i].minimumValue = s.distributionRatioVariables[i].minimumValue;
      this.distributionRatioVariables[i].maximumValue = s.distributionRatioVariables[i].maximumValue;
    }
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('Maximum demand', this.capacity);
    this.constraints.addConstraint('minimumFlow', this.minimumFlow);
    this.constraints.addConstraint(SimulationVariableName.MAXIMUM_SULFUR, this.maxSulfur);
    this.constraints.addConstraint(SimulationVariableName.MAXIMUM_MASS_DENSITY, this.maxDensity);
    this.constraints.addConstraint(SimulationVariableName.MAXIMUM_NITROGEN, this.maxNitrogen);
  }

  override setDefaultValues(): void {
    super.setDefaultValues();
    if (this.maxDensity.isUndefined()) {
      this.maxDensity.setDefaultValue(null, unitConverter.units.LiquidDensity.KG_M3);
    }
    if (!this.constraintOption) {
      this.constraintOption = CommodityTankConstraintOption.BLEND_RATIO;
    }
  }

  override setQuantities() {
    super.setQuantities();
    this.productPrice.setQuantity('CurrencyPerVolume');
    this.commodityVolume.setQuantity('Volumetricflowrate');
    this.maxSulfur.setQuantity('MassFraction');
    this.maxNitrogen.setQuantity('MassFraction');
    this.maxDensity.setQuantity('LiquidDensity');
    this.commoditySulfur.setQuantity('MassFraction');
    this.commodityNitrogen.setQuantity('MassFraction');
    this.commodityDensity.setQuantity('LiquidDensity');
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.capacity.setName(SimulationVariableName.MAXIMUM_DEMAND);
    this.minimumFlow.setName(SimulationVariableName.MINIMUM_DEMAND);
    this.maxSulfur.setName(SimulationVariableName.MAXIMUM_SULFUR);
    this.maxDensity.setName('Minimum API');
    this.maxNitrogen.setName(SimulationVariableName.MAXIMUM_NITROGEN);
    this.productPrice.setName('Product Price');
    this.commodityVolume.setName('Commodity Volume');
    this.commoditySulfur.setName('Commodity Sulfur');
    this.commodityNitrogen.setName('Commodity Nitrogen');
    this.commodityDensity.setName('Commodity Density');
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.capacity];
  }

  isBlendRatioConstraintSelected() {
    return this.constraintOption === CommodityTankConstraintOption.BLEND_RATIO;
  }

  addDistributionVariable(unitOperationId: string) {
    this.emptyDistributionValues();
    this.distributionRatioVariables.push(new RangeDistributionRatioVariable({ unitOperationId }));

    if (this.distributionRatioVariables.length === 1) {
      this.distributionRatioVariables[0].value = 1;
    }
  }

  emptyDistributionValues() {
    for (const dv of this.distributionRatioVariables) {
      dv.value = null;
    }
  }

  findDistributionRatioVariable(unitOperationId: string) {
    return this.distributionRatioVariables.find(drv => {
      return drv.unitOperationId === unitOperationId;
    });
  }

  findDistributionRatioVariableName(i: number) {
    const dv = this.distributionRatioVariables[i];

    if (dv) {
      const uo = this.ownerCase.getUnitOperation(dv.unitOperationId);
      return uo ? uo.name : null;
    }

    return null;
  }

  removeDistributionRatioVariable(unitOperationId: string) {
    const index = this.distributionRatioVariables.indexOf(this.findDistributionRatioVariable(unitOperationId));

    if (index >= 0) {
      this.distributionRatioVariables.splice(index, 1);
    }
  }

  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return (
      inletStream && this.constraints.constraintValueViolated('Maximum demand', inletStream.volumetricFlowrate.value)
    );
  }

  constraintValueDefined(): boolean {
    return this.constraints.anyConstraintValueDefined();
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.productPrice = this.getSimulationVariableSafe(sup.productPrice);
    this.commodityVolume = this.getSimulationVariableSafe(sup.commodityVolume);
    this.maxSulfur = this.getSimulationVariableSafe(sup.maxSulfur);
    this.maxDensity = this.getSimulationVariableSafe(sup.maxDensity);
    this.maxNitrogen = this.getSimulationVariableSafe(sup.maxNitrogen);
    this.commodityNitrogen = this.getSimulationVariableSafe(sup.commodityNitrogen);
    this.commoditySulfur = this.getSimulationVariableSafe(sup.commoditySulfur);
    this.commodityDensity = this.getSimulationVariableSafe(sup.commodityDensity);

    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }
}
