import { SuncorMaterialStream } from '../_streams/suncor-material-stream';
import { GojsBaseStream } from './gojs-base-stream';
import { StreamCategory } from '../../_config/stream-category.enum';

declare let unitConverter: any;

export class GojsSuncorMaterialStream extends GojsBaseStream {
  override category = StreamCategory.MATERIAL_STREAM;
  volumetricFlowrate: string;
  volumetricFlowrateUnit: string;
  ghgIntensity: string;
  ghgIntensityUnit: string;
  price: string;
  priceUnit: string;

  constructor(s: SuncorMaterialStream) {
    super(s);

    this.volumetricFlowrateUnit = unitConverter.units.Volumetricflowrate.KBPCD;
    this.volumetricFlowrate = s.volumetricFlowrate.convertToAnotherUnit(this.volumetricFlowrateUnit, true);

    this.ghgIntensityUnit = unitConverter.units.MassPerVolume.KG_BBL;
    this.ghgIntensity = s.ghgIntensity.convertToAnotherUnit(this.ghgIntensityUnit, true);

    this.priceUnit = unitConverter.units.CurrencyPerVolume.CAD_BBL;
    this.price = s.price.convertToAnotherUnit(this.priceUnit, true);

    this.dashedLine = !s.hasFlowrate();
  }
}
