import { Upgrader } from '../../../_unit-operations/upgrader';
import { SimulationVariable } from '../../../simulation-variable';
import { SimulationVariableName } from '../../../../_config/simulation-variable-name.enum';
import { ConstraintReportVariable } from '../constraint-report-variable';

export class UpgraderConstraintReportVariableResolver {
  static create() {
    return new UpgraderConstraintReportVariableResolver();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  addCurrentValueAndUnitToConstraintReportVariable(
    crv: ConstraintReportVariable,
    params: { uo: Upgrader; simVar: SimulationVariable }
  ): void {
    const { uo, simVar } = params;
    if (simVar.name === SimulationVariableName.AMINE_RATE_CAPACITY) {
      crv.currentValue = uo.amineCirculationRate.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.amineCirculationRate.getDefaultUnit();
      return;
    }

    if (simVar.name === SimulationVariableName.SULFUR_CAPACITY) {
      crv.currentValue = uo.totalH2SProduction.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.totalH2SProduction.getDefaultUnit();
      return;
    }

    // TODO check if this is correct... getting the value from HYDROGEN USE??
    if (simVar.name === SimulationVariableName.MAXIMUM_HYDROGEN_PRODUCTION) {
      crv.currentValue = uo.hydrogenUse.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.hydrogenUse.getDefaultUnit();
      return;
    }

    // TODO check if this is correct... getting the value from HYDROGEN USE??
    if (simVar.name === SimulationVariableName.MAXIMUM_FEED_CAPACITY) {
      crv.currentValue = uo.hydrogenUse.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.hydrogenUse.getDefaultUnit();
      return;
    }
    // TODO check if this is correct...
    if (simVar.name === SimulationVariableName.CAPACITY) {
      crv.currentValue = uo.capacity.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.capacity.getDefaultUnit();
      return;
    }

    // TODO check if this is correct... getting the value from HYDROGEN USE??
    if (simVar.name === SimulationVariableName.MAXIMUM_SULFUR_CONTENT) {
      crv.currentValue = uo.hydrogenUse.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.hydrogenUse.getDefaultUnit();
      return;
    }

    // TODO check if this is correct... getting the value from HYDROGEN USE??
    if (simVar.name === SimulationVariableName.MAXIMUM_MASS_DENSITY) {
      crv.currentValue = uo.hydrogenUse.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.hydrogenUse.getDefaultUnit();
      return;
    }

    throw new Error(`Unknown constraint: ${simVar.name}`);
  }
}
