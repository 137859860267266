import { DefaultBaseObjectFormGroupMapper } from './default-base-object-form-group.mapper';
import { BaseObject } from '../_models/base-object';
import { WaterSinkImport } from '../_models/_unit-operations/utilties/water-sink-import';
import { ArrayDiff } from '../_utils/array-diff';
import { WaterSourceInformationStream } from '../_models/_unit-operations/utilties/water-source-information-stream';

export class WaterSinkImportFormGroupMapper extends DefaultBaseObjectFormGroupMapper {
  override map(source: BaseObject, destination: BaseObject) {
    super.map(source, destination);

    const sourcePlainObject = source as WaterSinkImport;
    const destinationInstance = destination as WaterSinkImport;

    const arrayDiff = new ArrayDiff(sourcePlainObject.inletSourceInformationStreams);

    // diff with the source information streams as its base array
    const diff = arrayDiff.diff(
      destinationInstance.inletSourceInformationStreams,
      (l: WaterSourceInformationStream, r: WaterSourceInformationStream) => {
        return l.simulationVariableId === r.simulationVariableId;
      }
    );

    // map matching information streams
    for (const sourceInformationStream of diff.intersection) {
      const destinationStream = destinationInstance.inletSourceInformationStreams.find(
        informationStream => informationStream.simulationVariableId === sourceInformationStream.simulationVariableId
      );

      super.map(sourceInformationStream, destinationStream);
    }

    // add new information streams
    const informationStreamsToAdd = diff.left;
    for (const sourceInformationStream of informationStreamsToAdd) {
      const destinationInformationStream = new WaterSourceInformationStream(
        sourceInformationStream,
        destinationInstance.ownerCase
      );
      destinationInstance.addInformationStream(destinationInformationStream);
    }

    // delete information streams
    const informationStreamsToDelete = diff.right;
    for (const informationStreamToDelete of informationStreamsToDelete) {
      destinationInstance.removeInformationStream(informationStreamToDelete.simulationVariableId);
    }
  }

  override ignoreForAutoMapping(key: keyof WaterSinkImport, source: BaseObject, destination: BaseObject): boolean {
    const result = super.ignoreForAutoMapping(key, source, destination);
    return key === 'inletSourceInformationStreams' || result;
  }
}
