<label class="subtitle">Steam</label>
<a class="btn btn-outline btn-success btn-xs m-l-sm" href="#steamDiv" (click)="switchOpened()" aria-expanded="false">
  <i *ngIf="!isOpened" class="fa fa-plus"></i>
  <i *ngIf="isOpened" class="fa fa-minus"></i>
</a>
<div class="collapse" id="steamDiv" aria-expanded="false" style="">
  <div class="form-group">
    <label for="customModelEnabled" class="control-label col-sm-4"> Use Custom Model for Steam </label>
    <div class="col-sm-4">
      <label class="control-label">
        <input type="checkbox" id="customModelEnabled" [(ngModel)]="unitOperation.useSteamCustomModel" />
      </label>
    </div>

    <div class="clearfix"></div>
  </div>
  <div *ngIf="unitOperation.useSteamCustomModel">
    <sob-open-code-editor-button
      [code]="unitOperation.steamModelScript"
      [defaultCodeProvider]="defaultCodeProvider"
      [instanceId]="'steamCodeEditor'"
      [disabled]="!unitOperation.useSteamCustomModel"
      (codeSaved)="saveSteamCustomModel($event)"></sob-open-code-editor-button>
  </div>
  <hr class="m-t-md m-b-md" />
  <div class="form-group">
    <label [attr.for]="'steamMake900'" class="pw-label control-label col-sm-4"> 900# Steam Make </label>
    <sim-var-input [inputId]="'steamMake900'" [readOnly]="true" [simVar]="unitOperation.steamMake900"></sim-var-input>
  </div>
  <div class="form-group">
    <label [attr.for]="'steamUse900'" class="pw-label control-label col-sm-4"> 900# Steam Use </label>
    <sim-var-input [inputId]="'steamUse900'" [readOnly]="true" [simVar]="unitOperation.steamUse900"></sim-var-input>
  </div>
  <hr style="padding-bottom: 10px" />
  <div class="form-group">
    <label [attr.for]="'steamMake600'" class="pw-label control-label col-sm-4"> 600# Steam Make </label>
    <sim-var-input [inputId]="'steamMake600'" [readOnly]="true" [simVar]="unitOperation.steamMake600"></sim-var-input>
  </div>
  <div class="form-group">
    <label [attr.for]="'steamUse600'" class="pw-label control-label col-sm-4"> 600# Steam Use </label>
    <sim-var-input [inputId]="'steamUse600'" [readOnly]="true" [simVar]="unitOperation.steamUse600"></sim-var-input>
  </div>
  <hr style="padding-bottom: 10px" />
  <div class="form-group">
    <label [attr.for]="'steamMake150'" class="pw-label control-label col-sm-4"> 150# Steam Make </label>
    <sim-var-input [inputId]="'steamMake150'" [readOnly]="true" [simVar]="unitOperation.steamMake150"></sim-var-input>
  </div>
  <div class="form-group">
    <label [attr.for]="'steamUse150'" class="pw-label control-label col-sm-4"> 150# Steam Use </label>
    <sim-var-input [inputId]="'steamUse150'" [readOnly]="true" [simVar]="unitOperation.steamUse150"></sim-var-input>
  </div>
  <hr style="padding-bottom: 10px" />
  <div class="form-group">
    <label [attr.for]="'steamMake50'" class="pw-label control-label col-sm-4"> 50# Steam Make </label>
    <sim-var-input [inputId]="'steamMake50'" [readOnly]="true" [simVar]="unitOperation.steamMake50"></sim-var-input>
  </div>
  <div class="form-group">
    <label [attr.for]="'steamUse50'" class="pw-label control-label col-sm-4"> 50# Steam Use </label>
    <sim-var-input [inputId]="'steamUse50'" [readOnly]="true" [simVar]="unitOperation.steamUse50"></sim-var-input>
  </div>
</div>
