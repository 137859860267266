<div id="propertyWindow">
  <div class="col-xs-10">
    <div id="pwNameWrapper">
      <input type="text" class="form-control" id="pwBlockName" name="pwBlockName" [(ngModel)]="unitOperation.name" />
    </div>
  </div>
  <div class="col-xs-2">
    <a id="closePropertyWindow" class="btn btn-danger" (click)="onClosePropertyWindowClicked()"> X </a>
  </div>

  <div class="col-xs-12" id="propertyWindowContent" [ngSwitch]="unitOperation.category">
    <sob-diluent-source [unitOperation]="unitOperation" *ngSwitchCase="'diluent-source'"></sob-diluent-source>
    <sob-extraction [unitOperation]="unitOperation" *ngSwitchCase="'extraction'"></sob-extraction>
    <!--    <sob-bitumen-conversion [unitOperation]="unitOperation" *ngSwitchCase="'bitumen-conversion'">-->
    <!--    </sob-bitumen-conversion>-->
    <sob-bitumen-conversion
      [unitOperation]="unitOperation"
      [formGroupWrapper]="formGroupWrapper"
      *ngSwitchCase="unitOperationsConfig.bitumenConversion.key">
    </sob-bitumen-conversion>
    <sob-mine-source [unitOperation]="unitOperation" *ngSwitchCase="'mine-source'"></sob-mine-source>
    <sob-mixer
      [unitOperation]="unitOperation"
      [formGroupWrapper]="formGroupWrapper"
      *ngSwitchCase="'mixer'"></sob-mixer>
    <sob-offshore-source [unitOperation]="unitOperation" *ngSwitchCase="'offshore-source'"></sob-offshore-source>
    <sob-pipe
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.pipe.key"></sob-pipe>
    <sob-refinery
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.refinery.key"></sob-refinery>
    <sob-suncor-renewable-block [unitOperation]="unitOperation" *ngSwitchCase="'suncor-renewable-block'">
    </sob-suncor-renewable-block>
    <sob-sagd-source [unitOperation]="unitOperation" *ngSwitchCase="'sagd-source'"></sob-sagd-source>
    <sob-splitter
      *ngSwitchCase="unitOperationsConfig.splitter.key"
      [unitOperation]="unitOperation"
      [formGroupWrapper]="formGroupWrapper"></sob-splitter>
    <sob-third-party-refinery [unitOperation]="unitOperation" *ngSwitchCase="'third-party-refinery'">
    </sob-third-party-refinery>
    <sob-third-party-source [unitOperation]="unitOperation" *ngSwitchCase="'third-party-source'">
    </sob-third-party-source>
    <sob-upgrader [unitOperation]="unitOperation" *ngSwitchCase="'upgrader'"></sob-upgrader>
    <sob-dru [unitOperation]="unitOperation" *ngSwitchCase="'dru'"></sob-dru>
    <sob-vac [unitOperation]="unitOperation" *ngSwitchCase="'vac'"></sob-vac>
    <sob-hydrotreater
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.hydrotreater.key"></sob-hydrotreater>
    <sob-hydrogen-plant [unitOperation]="unitOperation" *ngSwitchCase="'hydrogen-plant'"> </sob-hydrogen-plant>
    <sob-sulfur-plant
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.sulfurPlant.key"></sob-sulfur-plant>

    <sob-hgo-hydrotreater [unitOperation]="unitOperation" *ngSwitchCase="unitOperationsConfig.hgoHydrotreater.key">
    </sob-hgo-hydrotreater>
    <sob-lgo-hydrotreater
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.lgoHydrotreater.key"></sob-lgo-hydrotreater>
    <sob-dcu [unitOperation]="unitOperation" *ngSwitchCase="'dcu'"></sob-dcu>
    <sob-fluid-coker [unitOperation]="unitOperation" *ngSwitchCase="unitOperationsConfig.fluidCoker.key">
    </sob-fluid-coker>
    <sob-commodity-tank [unitOperation]="unitOperation" *ngSwitchCase="'commodity-tank'"></sob-commodity-tank>

    <sob-product-tank
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.productTank.key">
    </sob-product-tank>

    <sob-hydrocracker [unitOperation]="unitOperation" *ngSwitchCase="'hydrocracker'"></sob-hydrocracker>

    <!-- Utilities -->
    <sob-water-utility-unit-operation [unitOperation]="unitOperation" *ngSwitchCase="'water-utility-unit-operation'">
    </sob-water-utility-unit-operation>
    <sob-water-source
      [unitOperation]="unitOperation"
      [formGroupWrapper]="formGroupWrapper"
      *ngSwitchCase="unitOperationsConfig.waterSource.key">
    </sob-water-source>
    <sob-water-source-import
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.waterSourceImport.key">
    </sob-water-source-import>
    <sob-water-sink-import
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.waterSinkImport.key">
    </sob-water-sink-import>
    <sob-water-turbine
      [unitOperation]="unitOperation"
      [formGroupWrapper]="formGroupWrapper"
      *ngSwitchCase="unitOperationsConfig.waterTurbine.key"></sob-water-turbine>
    <sob-water-heat-exchanger [unitOperation]="unitOperation" *ngSwitchCase="'water-heat-exchanger'">
    </sob-water-heat-exchanger>
    <sob-water-heater [unitOperation]="unitOperation" *ngSwitchCase="'water-heater'"></sob-water-heater>
    <sob-water-cooler [unitOperation]="unitOperation" *ngSwitchCase="'water-cooler'"></sob-water-cooler>
    <sob-water-two-phase-separator [unitOperation]="unitOperation" *ngSwitchCase="'water-two-phase-separator'">
    </sob-water-two-phase-separator>
    <sob-water-pump [unitOperation]="unitOperation" *ngSwitchCase="'water-pump'"></sob-water-pump>
    <sob-water-valve [unitOperation]="unitOperation" *ngSwitchCase="'water-valve'"></sob-water-valve>
    <sob-water-mixer [unitOperation]="unitOperation" *ngSwitchCase="'water-mixer'"></sob-water-mixer>
    <sob-water-splitter
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.waterSplitter.key"></sob-water-splitter>
    <sob-water-sink [unitOperation]="unitOperation" *ngSwitchCase="'water-sink'"></sob-water-sink>
    <sob-water-header
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.waterHeader.key"></sob-water-header>
    <sob-water-pipe [unitOperation]="unitOperation" *ngSwitchCase="'water-pipe'"></sob-water-pipe>
    <sob-water-boiler
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.waterBoiler.key"></sob-water-boiler>
    <sob-water-cogeneration
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.waterCogeneration.key"></sob-water-cogeneration>

    <sob-fuel-gas-utility-unit-operation
      *ngSwitchCase="unitOperationsConfig.fuelGasUtilityUnitOperation.key"
      [unitOperation]="unitOperation"></sob-fuel-gas-utility-unit-operation>
    <sob-fuel-gas-flare
      *ngSwitchCase="unitOperationsConfig.fuelGasFlare.key"
      [unitOperation]="unitOperation"
      [formGroupWrapper]="formGroupWrapper">
    </sob-fuel-gas-flare>
    <sob-fuel-gas-sink
      [formGroupWrapper]="formGroupWrapper"
      *ngSwitchCase="unitOperationsConfig.fuelGasSink.key"
      [unitOperation]="unitOperation">
    </sob-fuel-gas-sink>
    <sob-fuel-gas-sink-import
      *ngSwitchCase="unitOperationsConfig.fuelGasSinkImport.key"
      [unitOperation]="unitOperation"
      [formGroupWrapper]="formGroupWrapper"></sob-fuel-gas-sink-import>
    <sob-fuel-gas-source-import
      *ngSwitchCase="unitOperationsConfig.fuelGasSourceImport.key"
      [unitOperation]="unitOperation"
      [formGroupWrapper]="formGroupWrapper">
    </sob-fuel-gas-source-import>
    <sob-fuel-gas-mixer
      *ngSwitchCase="unitOperationsConfig.fuelGasMixer.key"
      [unitOperation]="unitOperation"></sob-fuel-gas-mixer>
    <sob-fuel-gas-source
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.fuelGasSource.key"></sob-fuel-gas-source>
    <sob-fuel-gas-splitter
      [formGroupWrapper]="formGroupWrapper"
      [unitOperation]="unitOperation"
      *ngSwitchCase="unitOperationsConfig.fuelGasSplitter.key"></sob-fuel-gas-splitter>
    <sob-fuel-gas-amine-contactor
      *ngSwitchCase="unitOperationsConfig.fuelGasAmineContactor.key"
      [unitOperation]="unitOperation"
      [formGroupWrapper]="formGroupWrapper">
    </sob-fuel-gas-amine-contactor>
  </div>
</div>
