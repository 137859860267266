import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';

export class FuelGasSplitterFormChangeDetector extends DefaultBaseObjectFormChangeDetector {
  override detectChangesInValues(originalFormValue: any, currentValue: any): boolean {
    let changes = super.detectChangesInValues(originalFormValue, currentValue);
    for (let i = 0; i < currentValue.distributionRatioVariables.length; i++) {
      if (originalFormValue.distributionRatioVariables[i] === undefined) {
        return changes;
      }
      if (currentValue.distributionRatioVariables[i].value !== originalFormValue.distributionRatioVariables[i].value) {
        changes = changes || true;
      }
    }
    return changes;
  }
}
