<div class="modal fade" id="globalErrorModal" tabindex="-1" role="dialog" aria-labelledby="globalErrorModalLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">An application error occurred</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-1 px-0 text-center">
            <i class="fa fa-times-circle text-danger error-icon"></i>
          </div>
          <div class="col-xs-11">
            <h3 class="text-danger error-title">An application error occurred.</h3>
            <span *ngIf="isLoading()" class="text-info"
              ><i class="fa fa-spinner fa-spin"></i> Notifying Process Ecology</span
            >
            <span *ngIf="!isLoading() && processEcologyNotified" class="text-success">
              <i class="fa fa-check-circle"></i> Process Ecology has been notified</span
            >
            <span *ngIf="!isLoading() && !processEcologyNotified" class="text-danger"
              ><i class="fa fa-times-circle"></i> An error occurred while notifying Process Ecology</span
            >
            <br />
            If the error occurred while modifying the Case, please send it to Process Ecology to detect any possible
            data corruption.
            <br />
            Be sure to save all your work and reload this page.
            <div>
              <button
                type="button"
                class="btn btn-link px-0 underline"
                data-toggle="collapse"
                data-target="#errorDetailsCollapsible">
                <i class="fa fa-chevron-right expand-collapse" *ngIf="errorDetailsCollapsed"></i>
                <i class="fa fa-chevron-down expand-collapse" *ngIf="!errorDetailsCollapsed"></i>
                Error details
              </button>
            </div>
            <div class="collapse mt-2" id="errorDetailsCollapsible">
              <div class="well">
                <b>{{ currentError.message }}</b> <br />
                <div class="error-details">
                  {{ currentError.stackTrace }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
