import { enumValuesToArray } from '../../_utils/enum-values-to-array';
import { Case, CaseSettings } from '../../_models';
import { BaseObjectFactory } from '../../_models/base-object-factory';
import { ObjectiveFunction } from '../../_models/_optimization/objective-function';
import { CaseStudyManager } from '../../_models/_case-study/case-study-manager';
import { KpiManager } from '../../_models/_case-study/kpi-manager';
import { SuncorCalculator } from '../../_models/suncor-calculator';

enum BaseObjectHierarchy {
  TOP = 0,
  SECOND = 1,
  THIRD = 2,
  FOURTH = 3,
}

export class OtherBaseObjectPoolDeserializer {
  private readonly arrangedPool;
  private readonly serializedCase;

  constructor(serializedCase) {
    this.arrangedPool = {};
    this.arrangePool(serializedCase.otherBaseObjectPool);
    this.serializedCase = serializedCase;
  }

  private arrangePool(serializedPool) {
    let i;
    for (i = 0; i < enumValuesToArray(BaseObjectHierarchy).length; i++) {
      this.arrangedPool[i] = [];
    }

    for (const id in serializedPool) {
      if (!serializedPool.hasOwnProperty(id)) {
        continue;
      }

      const current = serializedPool[id];

      switch (current.category) {
        case 'simulationVariableArray':
          this.arrangedPool[BaseObjectHierarchy.TOP].push(current);
          break;

        case 'multiPeriodParameter':
          this.arrangedPool[BaseObjectHierarchy.SECOND].push(current);
          break;
        case 'waterPipeSegment':
          this.arrangedPool[BaseObjectHierarchy.SECOND].push(current);
          break;

        case 'uncertainParameter':
          this.arrangedPool[BaseObjectHierarchy.SECOND].push(current);
          break;
        case 'ParametricStudyKpiResult':
          this.arrangedPool[BaseObjectHierarchy.SECOND].push(current);
          break;
        case 'parametricStudyParameter':
          this.arrangedPool[BaseObjectHierarchy.SECOND].push(current);
          break;

        case 'kpiManager':
        case 'uncertaintyAnalysis':
        case 'multiPeriodSettings':
          this.arrangedPool[BaseObjectHierarchy.THIRD].push(current);
          break;
        case 'parametricStudy':
          this.arrangedPool[BaseObjectHierarchy.THIRD].push(current);
          break;
        case 'fluid-analysis':
          this.arrangedPool[BaseObjectHierarchy.THIRD].push(current);
          break;
        case 'gas-fluid-analysis':
          this.arrangedPool[BaseObjectHierarchy.THIRD].push(current);
          break;

        default:
          this.arrangedPool[BaseObjectHierarchy.FOURTH].push(current);
      }
    }
  }

  deserializeSimulationVariableArrays(c: Case) {
    for (const serializedArray of this.arrangedPool[BaseObjectHierarchy.TOP]) {
      const sva = BaseObjectFactory.create(serializedArray, c);
      sva.dePersist(serializedArray);
      c.addToPools(sva);
    }
  }

  deserializeLowerHierarchyObjects(c: Case) {
    for (let i = 1; i < enumValuesToArray(BaseObjectHierarchy).length; i++) {
      const objectsInHierarchy = this.arrangedPool[i];

      for (const serializedObject of objectsInHierarchy) {
        const bo = BaseObjectFactory.create(serializedObject, c);
        bo.dePersist(serializedObject);
        c.addToPools(bo);
      }
    }

    // this.postDeserialization(c);
  }

  postDeserialization(c: Case) {
    c.caseSettings = <CaseSettings>c.getOtherBaseObject(this.serializedCase.caseSettings);

    if (!c.caseSettings) {
      c.caseSettings = new CaseSettings({}, c);
      c.addToPools(c.caseSettings);
      c.caseSettings.addSimVarsToPool();
    }

    c.objectiveFunction = <ObjectiveFunction>c.getOtherBaseObject(this.serializedCase.objectiveFunction);

    if (!c.objectiveFunction) {
      c.objectiveFunction = new ObjectiveFunction({}, c);
      c.addToPools(c.objectiveFunction);
      c.objectiveFunction.addSimVarsToPool();
    }
    c.caseStudyManager = <CaseStudyManager>c.getOtherBaseObject(this.serializedCase.caseStudyManager);
    if (!c.caseStudyManager) {
      c.caseStudyManager = new CaseStudyManager({}, c);
      c.caseStudyManager.kpiManager = new KpiManager({}, c);
      c.addToPools(c.caseStudyManager);
    }

    c.calculator = <SuncorCalculator>c.getOtherBaseObject(this.serializedCase.calculator);

    if (!c.calculator) {
      c.calculator = new SuncorCalculator({}, c);
      c.addToPools(c.calculator);
    }
  }
}
