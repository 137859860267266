<div class="form-horizontal">
  <div class="form-group">
    <label for="psResultsKpi" class="control-label col-sm-1">KPI</label>
    <div class="col-sm-8">
      <select
        name="psResultsKpi"
        id="psResultsKpi"
        class="form-control"
        #psResultsKpi
        (change)="changeResults(psResultsKpi.value)">
        <option *ngFor="let kpi of selectedKpisWithResults()" value="{{ kpi.id }}">
          {{ kpi.getFullName() }}
        </option>
      </select>
    </div>
  </div>
</div>

<ul class="nav nav-pills" role="tablist">
  <li role="presentation" class="active">
    <a href="#psChartTab" role="tab" data-toggle="tab" aria-controls="psChartTab"> Chart </a>
  </li>

  <li role="presentation">
    <a href="#psTableTab" role="tab" data-toggle="tab" aria-controls="srGhgEmissionsReportTable"> Table </a>
  </li>

  <div style="text-align: right">
    <a href="javascript:void(0)" class="btn btn-primary btn-md" title="Download Excel Report" (click)="excelReport()">
      Excel Report&nbsp;
      <i class="main-icon fa fa-download"></i>
    </a>
  </div>
</ul>

<div class="tab-content tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="psChartTab">
    <div id="parametricStudyChart"></div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="psTableTab">
    <div class="table-responsive">
      <table class="table" id="parametricStudyResultsTable">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
</div>
