<div>
  <h3><b>Initial Setup</b></h3>
  <br />
</div>

<div class="form-horizontal" [formGroup]="formGroup" *ngIf="multiPeriodSettings">
  <div class="form-group">
    <label class="control-label col-sm-4" for="mps.numberOfSteps"> Number of Days </label>

    <div class="col-sm-8">
      <input formControlName="numberOfSteps" class="form-control m-b-xs" type="text" id="mps.numberOfSteps" />

      <sob-number-validation-messages
        *ngIf="shouldShowControlValidation(numberOfStepsControl)"
        [control]="numberOfStepsControl"></sob-number-validation-messages>
    </div>
  </div>

  <div class="form-group">
    <label for="mps.evaluateInventory" class="control-label col-sm-4"> Evaluate Inventory </label>
    <div class="col-sm-4">
      <label class="control-label">
        <input
          type="checkbox"
          id="mps.evaluateInventory"
          formControlName="evaluateInventory"
          (change)="onEvaluateInventoryChange($event)" />
      </label>
    </div>
  </div>

  <ng-container *ngFor="let tank of allTanks" [formGroupName]="tank.id">
    <hr />
    <h4>
      <a
        data-toggle="collapse"
        sfeCollapsePanelIcon
        href="#inventorySettings{{ tank.id }}"
        aria-expanded="false"
        [attr.aria-controls]="'inventorySettings' + tank.id">
        <i class="fa fa-plus" aria-hidden="true"></i>
        {{ tank.name }}
      </a>
    </h4>

    <div id="inventorySettings{{ tank.id }}" class="collapse">
      <div class="form-group" formGroupName="minVolume">
        <label class="control-label col-sm-4"> Minimum Volume </label>

        <div class="col-sm-6" sobReactiveSimVarInput>
          <div class="row">
            <div class="col-xs-6">
              <sob-reactive-unit-select formControlName="unit" [quantity]="tank.minVolume.quantity">
              </sob-reactive-unit-select>
            </div>
            <div class="col-xs-6">
              <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="tank.minVolume">
              </sob-reactive-variable-value-input>

              <div class="m-t-xs">
                <sob-number-validation-messages
                  *ngIf="shouldShowControlValidation(getTankValueFormControl(tank.id, 'minVolume'))"
                  [control]="getTankValueFormControl(tank.id, 'minVolume')">
                </sob-number-validation-messages>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" formGroupName="maxVolume">
        <label class="control-label col-sm-4"> Maximum Volume </label>

        <div class="col-sm-6" sobReactiveSimVarInput>
          <div class="row m-b-xs">
            <div class="col-xs-6">
              <sob-reactive-unit-select formControlName="unit" [quantity]="tank.maxVolume.quantity">
              </sob-reactive-unit-select>
            </div>
            <div class="col-xs-6">
              <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="tank.maxVolume">
              </sob-reactive-variable-value-input>

              <div class="m-t-xs">
                <sob-number-validation-messages
                  *ngIf="shouldShowControlValidation(getTankValueFormControl(tank.id, 'maxVolume'))"
                  [control]="getTankValueFormControl(tank.id, 'maxVolume')"></sob-number-validation-messages>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" formGroupName="initialVolume">
        <label class="control-label col-sm-4"> Initial Volume </label>

        <div class="col-sm-6" sobReactiveSimVarInput>
          <div class="row m-b-xs">
            <div class="col-xs-6">
              <sob-reactive-unit-select formControlName="unit" [quantity]="tank.initialVolume.quantity">
              </sob-reactive-unit-select>
            </div>
            <div class="col-xs-6">
              <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="tank.initialVolume">
              </sob-reactive-variable-value-input>

              <div class="m-t-xs">
                <sob-number-validation-messages
                  *ngIf="shouldShowControlValidation(getTankValueFormControl(tank.id, 'initialVolume'))"
                  [control]="getTankValueFormControl(tank.id, 'initialVolume')"></sob-number-validation-messages>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
