import { H2sSpreadsheetModel } from '../../../_models/utilities-spreadsheet/h2s-spreadsheet.model';
import { H2sSheetUtils } from './h2s-sheet-utils';

export class H2sSheetItemDataShifter {
  constructor(private items: H2sSpreadsheetModel[], private h2sUtils: H2sSheetUtils) {}

  /** *
   * Shifts upwards the data of items below, fills empty space left after deleting a unit operation
   * @param emptyItem The item that was left empty after a unit operation was deleted
   * @param belongsToFirstSection whether the data to be shifted belongs to the first section or not
   */

  shiftItemData(emptyItem: H2sSpreadsheetModel, belongsToFirstSection: boolean) {
    const startIndex = this.items.indexOf(emptyItem);
    const copyDataMethod: (s: H2sSpreadsheetModel, d: H2sSpreadsheetModel) => void = belongsToFirstSection
      ? this.h2sUtils.copyUnitOperationItemData
      : this.h2sUtils.copySulfurPlantItemData;

    const clearDataMethod: (item: H2sSpreadsheetModel) => void = belongsToFirstSection
      ? this.h2sUtils.clearUnitOperationData
      : this.h2sUtils.clearSulfurPlantData;

    let lastItem;

    for (let i = startIndex; i < this.items.length; i++) {
      const currentItem = this.items[i];
      if (i === this.items.length - 1) {
        clearDataMethod(currentItem);
        lastItem = currentItem;

        continue;
      }

      const nextItem = this.items[i + 1];
      copyDataMethod(nextItem, currentItem);
    }

    if (this.h2sUtils.isItemEmpty(lastItem)) {
      this.h2sUtils.removeItem(lastItem);
    }
  }
}
