import { BaseObject } from '../_models/base-object';
import { WaterBoiler } from '../_models/_unit-operations/utilties/water-boiler';
import { hasNumericValue } from '../_utils/utils';
import { VariableStatus } from '../_config/variable-status.enum';
import { DefaultBaseObjectFormGroupMapper } from './default-base-object-form-group.mapper';

export class WaterBoilerFormGroupMapper extends DefaultBaseObjectFormGroupMapper {
  override map(source: BaseObject, destination: BaseObject) {
    super.map(source, destination);

    const boiler = destination as WaterBoiler;

    const simVars = [boiler.outletTemperature, boiler.outletPressure, boiler.outletVaporFraction, boiler.power];

    const solverCalculatedVariables = simVars.filter(sv => !hasNumericValue(sv.value));
    const userSpecifiedVariables = simVars.filter(sv => hasNumericValue(sv.value));

    // if two variables don't have value and the other two do, then it is clear which ones will be solver calculated
    if (solverCalculatedVariables.length === userSpecifiedVariables.length) {
      solverCalculatedVariables.forEach(sv => {
        sv.variableStatus = VariableStatus.SOLVER_CALCULATED;
      });

      userSpecifiedVariables.forEach(sv => {
        sv.variableStatus = VariableStatus.USER_SPECIFIED;
      });
    }
  }
}
