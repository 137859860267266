import { DataType } from '@grapecity/wijmo';
import { AbstractConstraintsHandler } from './abstract-constraints-handler';
import { BaseConstraintsSpreadsheetModel } from '../../_models/constraints-spreadsheet/base-constraints-spreadsheet-model';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { FlexSheetColumn } from '../../_models/wijmo/flex-sheet-column';
import { UnitOperation } from '../../_models';
import { DCU } from '../../_models/_unit-operations/dcu';
import { DRU } from '../../_models/_unit-operations/dru';
import { VAC } from '../../_models/_unit-operations/vac';
import { Hydrotreater } from '../../_models/_unit-operations/hydrotreater';
import { CommodityTank } from '../../_models/_unit-operations/commodity-tank';
import { Pipe } from '../../_models/_unit-operations/pipe';
import { Hydrocracker } from '../../_models/_unit-operations/hydrocracker';
import { FluidCoker } from '../../_models/_unit-operations/fluid-coker';
import { HgoHydrotreater } from '../../_models/_unit-operations/hgo-hydrotreater';
import { LgoHydrotreater } from '../../_models/_unit-operations/lgo-hydrotreater';
import { WaterTurbine } from '../../_models/_unit-operations/utilties/water-turbine';
import { WaterSink } from '../../_models/_unit-operations/utilties/water-sink';
import { WaterBoiler } from '../../_models/_unit-operations/utilties/water-boiler';
import { WaterCogeneration } from '../../_models/_unit-operations/utilties/water-cogeneration';
import { WaterValve } from '../../_models/_unit-operations/utilties/water-valve';
import { WaterSinkImport } from '../../_models/_unit-operations/utilties/water-sink-import';
import { FuelGasSinkImport } from '../../_models/_unit-operations/fuel-gas/fuel-gas-sink-import';
import { CoreService } from '../core.service';
import { FlowsheetTreeService } from '../sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { NumberToUnitConverter } from '../number-to-unit-converter.service';
import { isEmptyValue } from '../../_utils/utils';
import { Mixer } from '../../_models/_unit-operations/mixer';
import { Splitter } from '../../_models/_unit-operations/splitter';

export class UpgraderConstraintsSheetHandler extends AbstractConstraintsHandler {
  protected readonly SHEET_INDEX: number;

  constructor(
    coreService: CoreService,
    flowsheetTreeService: FlowsheetTreeService,
    private nuc: NumberToUnitConverter
  ) {
    super(coreService, flowsheetTreeService);
  }

  isUnitOperationAllowed(
    unitOperation: UnitOperation
  ): unitOperation is
    | DCU
    | DRU
    | VAC
    | Hydrotreater
    | CommodityTank
    | Pipe
    | Hydrocracker
    | FluidCoker
    | HgoHydrotreater
    | LgoHydrotreater
    | WaterTurbine
    | WaterSink
    | WaterBoiler
    | WaterCogeneration
    | WaterValve
    | WaterSinkImport
    | FuelGasSinkImport
    | Mixer
    | Splitter {
    if (!isEmptyValue(unitOperation.flowsheetId)) {
      return (
        unitOperation instanceof DCU ||
        unitOperation instanceof DRU ||
        unitOperation instanceof VAC ||
        unitOperation instanceof Hydrotreater ||
        unitOperation instanceof CommodityTank ||
        unitOperation instanceof Pipe ||
        unitOperation instanceof Hydrocracker ||
        unitOperation instanceof FluidCoker ||
        unitOperation instanceof HgoHydrotreater ||
        unitOperation instanceof LgoHydrotreater ||
        unitOperation instanceof WaterTurbine ||
        unitOperation instanceof WaterSink ||
        unitOperation instanceof WaterBoiler ||
        unitOperation instanceof WaterCogeneration ||
        unitOperation instanceof WaterValve ||
        unitOperation instanceof WaterSinkImport ||
        unitOperation instanceof FuelGasSinkImport ||
        unitOperation instanceof Mixer ||
        unitOperation instanceof Splitter
      );
    }

    return false;
  }

  override clearResults(): void {
    for (const i of this.items) {
      const item = i as BaseConstraintsSpreadsheetModel;
    }

    this.grid.collectionView.refresh();
  }

  private getUnitOperation(item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation {
    const unitOperation = this.coreService.currentCase.getUnitOperation(item.unitOperationId) as SuncorUnitOperation;

    if (this.isUnitOperationAllowed(unitOperation)) {
      return unitOperation;
    }

    throw new Error(`A non-allowed unit operation was detected ${unitOperation.category} - ${unitOperation.name}`);
  }

  assignValueToUnitOperation(valueAsNumber: number, item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation {
    const uo = this.getUnitOperation(item);

    if (this.isMixingConstraintsUser(uo)) {
      this.assignNewValuesForRangeConstraints(uo, item, valueAsNumber);
    } else {
      const simVar = uo.getSimulationVariableSafe(item.id);
      simVar.value = this.nuc.convertDefaultToInternalUnit(valueAsNumber, simVar.quantity);
    }

    return uo;
  }

  deleteUnitOperationValue(item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation {
    const uo = this.getUnitOperation(item);

    if (this.isMixingConstraintsUser(uo)) {
      this.assignNewValuesForRangeConstraints(uo, item, null);
    } else {
      const simVar = uo.getSimulationVariableSafe(item.id);
      simVar.value = null;
    }

    return uo;
  }

  protected getColumnsConfiguration(): FlexSheetColumn[] {
    return [
      {
        header: 'Active',
        binding: 'isActive',
        dataType: DataType.Boolean,
        width: 120,
        isReadOnly: false,
      },
      {
        header: 'Unit Name',
        binding: 'unitOperationName',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: 'Constraint',
        binding: 'constraint',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: 'Constraint Value',
        binding: 'constraintValue',
        dataType: DataType.Number,
        width: 120,
      },
      {
        header: 'Current Value',
        binding: 'currentValue',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: 'Unit',
        binding: 'unit',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: 'Flowsheet Owner',
        binding: 'flowsheetOwner',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
    ];
  }
}
