import { Injectable } from '@angular/core';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { CoreService } from '../core.service';
import { LogManagerService } from '../../flowsheet/log-manager/log-manager.service';
import { UncertaintyAnalysisApiService } from './uncertainty-analysis-api.service';
import { UncertaintyAnalysis } from '../../_models/_case-study/uncertainty-analysis';

@Injectable({
  providedIn: 'root',
})
export class UncertaintyAnalysisService {
  private subSink = new SubSink();

  constructor(
    private caseService: CoreService,
    private logManager: LogManagerService,
    private uncertaintyApi: UncertaintyAnalysisApiService
  ) {}

  solveUncertaintyAnalysis(analysis: UncertaintyAnalysis): Observable<any> {
    this.logManager.info(`Solving ${analysis.name}`);
    const f = this.uncertaintyApi.solveUncertaintyAnalysis(analysis, this.caseService.project);

    this.subSink.add(
      f.subscribe(
        (solvedRequest: any) => {
          const { currentCase } = this.caseService;
          const serializedCaseStudy =
            solvedRequest.caseModel.otherBaseObjectPool[solvedRequest.caseModel.caseStudyManager];
          const serializedAnalysis =
            solvedRequest.caseModel.otherBaseObjectPool[serializedCaseStudy.uncertaintyAnalyses[0]];
          const currentAnalysis = <UncertaintyAnalysis>currentCase.getOtherBaseObject(serializedAnalysis.id);
          currentAnalysis.dePersistResults(serializedAnalysis);

          this.logManager.info(`${analysis.name} solved`);
          // here, the uncertainty analysis component should be notified I guess...
          // should reload its current analysis.
          currentCase.caseStudyManager.addUncertaintyAnalysisResults();
        },
        () => {},
        () => {}
      )
    );

    return f;
  }

  finishSubscriptions() {
    this.uncertaintyApi.finishSubscriptions();
    this.subSink.unsubscribe();
  }
}
