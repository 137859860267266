import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CaseStudyManager } from '../../../_models/_case-study/case-study-manager';
import { ParametricStudy } from '../../../_models/_case-study/parametric-study';
import { ProgressIndicatorService } from '../../progress-indicator/progress-indicator.service';
import { ParametricStudyService } from '../../../_services/case-study/parametric-study.service';
import { LogManagerService } from '../../log-manager/log-manager.service';
import { isEmptyValue } from '../../../_utils/utils';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { CoreService } from '../../../_services/core.service';
import { ParametricStudyComponent } from '../parametric-study/parametric-study.component';

enum ErrorMessage {
  KEY_NOT_FOUND = 'was not present',
}

@Component({
  selector: 'sob-parametric-study-window',
  templateUrl: './parametric-study-window.component.html',
  styleUrls: ['./parametric-study-window.component.css'],
})
export class ParametricStudyWindowComponent implements AfterViewInit {
  @Input()
  caseStudyManager: CaseStudyManager;

  @ViewChild(ParametricStudyComponent) parametricStudyComponent: ParametricStudyComponent;

  currentStudy: ParametricStudy;
  private openingKpiManager: boolean;
  currentlyFlowsheetOwnerId: string;
  invalidName: boolean;

  constructor(
    private ref: ChangeDetectorRef,
    private progressIndicator: ProgressIndicatorService,
    private parametricStudyService: ParametricStudyService,
    private logManager: LogManagerService,
    private toastr: ToastrService,
    private coreService: CoreService
  ) {}

  ngAfterViewInit() {
    const $modal = $('#caseStudyModal');
    $modal.on('show.bs.modal', () => {
      this.setCurrentStudyById(this.caseStudyManager.currentStudyId);
    });

    $modal.on('hidden.bs.modal', () => {
      this.invalidName = false;
      if (this.parametricStudyComponent) {
        this.parametricStudyComponent.cleanInputs();
        if (this.openingKpiManager) {
          this.openingKpiManager = false;
          // need to open the new modal when old modal hide animation is completed
          $('#kpiManagerModal').modal('show');
        }
      }
    });
  }

  addParametricStudy(name: HTMLInputElement) {
    this.validateParametricStudyName(name);
    if (!this.invalidName) {
      const ps = this.caseStudyManager.addParametricStudy(name.value);
      ps.subscribeToOwnerCaseEvents();
      this.caseStudyManager.currentStudyId = ps.id;
      this.setCurrentStudyById(ps.id);
      name.value = '';
    } else {
      this.invalidName = true;
    }
  }

  validateParametricStudyName(e: EventTarget) {
    this.invalidName = isEmptyValue((e as HTMLInputElement).value);
  }

  currentStudyChanged(id: string) {
    this.setCurrentStudyById(id);
  }

  removeParametricStudy() {
    if (this.currentStudy) {
      this.caseStudyManager.removeParametricStudy(this.currentStudy.id);

      if (this.caseStudyManager.parametricStudies.length) {
        this.setCurrentStudyById(this.caseStudyManager.parametricStudies[0].id);
      } else {
        this.currentStudy = undefined;
      }
    }
  }

  setCurrentStudyById(id: string) {
    if (!id) {
      this.currentStudy = undefined;
      return;
    }

    if (this.currentStudy) {
      delete this.currentStudy;
      this.ref.detectChanges();
    }

    this.currentStudy = this.caseStudyManager.findParametricStudy(id);
    this.ref.detectChanges();
  }

  solveParametricStudy() {
    this.progressIndicator.show({
      message: 'Solving case study...',
      showStopButton: true,
      solveCanceller: this.parametricStudyService.solveCanceller,
    });

    this.parametricStudyService.solveParametricStudy(this.currentStudy).subscribe(
      () => {
        this.setCurrentStudyById(this.currentStudy.id); // let's reload the UI
        this.progressIndicator.hide();

        this.toastr.success('Case study solved successfully');
      },
      error => {
        this.progressIndicator.hide();

        this.setCurrentStudyById(this.currentStudy.id);

        if (error.cancelled) {
          this.logManager.warning('Case study solve cancelled by user');
          this.toastr.warning('Case study solve cancelled by user');
        } else if (error.message.includes(ErrorMessage.KEY_NOT_FOUND)) {
          this.logManager.error('Invalid Independent Variable');
          this.toastr.error('Invalid Independent Variable');
        } else {
          this.logManager.error('An error occurred while solving the case study');
          this.toastr.error('An error occurred while solving the case study');
          console.error(error);
        }
      }
    );
  }

  solveParametricStudyAsync() {
    this.progressIndicator.show();

    this.parametricStudyService.solveParametricStudyAsync().subscribe({
      next: () => {
        this.progressIndicator.hide();
        this.toastr.success('We will send you an email when parametric study is solved');
      },
      error: () => {
        this.progressIndicator.hide();
        this.toastr.error('An error occurred while solving your parametric study');
      },
    });
  }

  openKpiManager() {
    this.openingKpiManager = true;
    $('#caseStudyModal').modal('hide');
  }

  getFlowsheetOwnerId(fOwnerId: string) {
    this.parametricStudyComponent.cleanInputs();
    this.currentlyFlowsheetOwnerId = fOwnerId;

    if (!isEmptyValue(fOwnerId)) {
      const flowsheetOwnerCategory = this.coreService.currentCase.getUnitOperation(fOwnerId).category;

      switch (flowsheetOwnerCategory) {
        case unitOperationsConfig.upgrader.key:
          this.coreService.currentCase.initUpgraderFlowsheetParametricStudyCategories();
          break;
        case unitOperationsConfig.waterUtilityUnitOperation.key:
          this.coreService.currentCase.initSteamFlowsheetParametricStudyCategories();
          break;
        case unitOperationsConfig.fuelGasUtilityUnitOperation.key:
          this.coreService.currentCase.initFuelGasFlowsheetParametricStudyCategories();
          break;
        case unitOperationsConfig.extraction.key:
          this.coreService.currentCase.initExtractionFlowsheetParametricStudyCategories();
          break;
        default:
          break;
      }
    } else if (fOwnerId === '') {
      this.coreService.currentCase.initMainFlowsheetParametricStudyCategories();
    }
  }
}
