import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { ParametricStudy } from '../../_models/_case-study/parametric-study';
import { Project } from '../../_models';
import { SolveCaseRequest } from '../../_models/solve-case-request';
import { environment } from '../../../environments/environment';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class ParametricStudyApiService {
  constructor(private http: HttpClient, private userService: UserService) {}

  private static getHeaders() {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return headers;
  }

  solveParametricStudy(ps: ParametricStudy, project: Project) {
    const dataToSend: SolveCaseRequest = {
      solveOption: 'waitForResults',
      caseModel: ps.ownerCase,
      version: project.version,
      username: '',
      userId: '',
      email: '',
    };

    return this.http.post(environment.parametricStudyUrl, dataToSend, {
      headers: ParametricStudyApiService.getHeaders(),
    });
  }

  solveParametricStudyAsync(options: {
    promise: Promise<FormData>;
    caseToBeSolvedId: string;
    fileName: string;
  }): Observable<any> {
    return new Observable(observer => {
      options.promise.then(data => {
        data.append('user', JSON.stringify(this.userService.getCurrentUser()));
        data.append('fileName', options.fileName);
        data.append('caseToBeSolvedId', options.caseToBeSolvedId);
        this.sendSolveAsyncRequest(data, observer);
      });
    });
  }

  private sendSolveAsyncRequest(data, observer: Subscriber<any>) {
    this.http.post(environment.parametricStudyAsyncUrl, data, { headers: this.getMultipartHeaders() }).subscribe(
      response => {
        observer.next(response);
      },
      () => {
        observer.error();
      }
    );
  }

  private getMultipartHeaders(): HttpHeaders {
    return new HttpHeaders().set('enctype', 'multipart/form-data');
  }
}
