import { SuncorUnitOperation } from './suncor-unit-operation';
import { SimulationVariable } from '../simulation-variable';
import { SteamType } from '../../_config/steam-type.enum';

export abstract class SteamUseUnitOperation extends SuncorUnitOperation {
  steamMake900: SimulationVariable;
  steamUse900: SimulationVariable;
  steamMake600: SimulationVariable;
  steamUse600: SimulationVariable;
  steamMake150: SimulationVariable;
  steamUse150: SimulationVariable;
  steamMake50: SimulationVariable;
  steamUse50: SimulationVariable;
  // custom model
  useSteamCustomModel: boolean;
  steamModelScript: string;

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.steamMake900 = new SimulationVariable(unitOperation.steamMake900 || {}, this.ownerCase, this);
    this.steamUse900 = new SimulationVariable(unitOperation.steamUse900 || {}, this.ownerCase, this);
    this.steamMake600 = new SimulationVariable(unitOperation.steamMake600 || {}, this.ownerCase, this);
    this.steamUse600 = new SimulationVariable(unitOperation.steamUse600 || {}, this.ownerCase, this);
    this.steamMake150 = new SimulationVariable(unitOperation.steamMake150 || {}, this.ownerCase, this);
    this.steamUse150 = new SimulationVariable(unitOperation.steamUse150 || {}, this.ownerCase, this);
    this.steamMake50 = new SimulationVariable(unitOperation.steamMake50 || {}, this.ownerCase, this);
    this.steamUse50 = new SimulationVariable(unitOperation.steamUse50 || {}, this.ownerCase, this);
    this.useSteamCustomModel = !!unitOperation.useSteamCustomModel;
    this.steamModelScript = unitOperation.steamModelScript || '';
  }

  override setQuantities() {
    super.setQuantities();
    this.steamMake900.setQuantity('Massflowrate');
    this.steamUse900.setQuantity('Massflowrate');
    this.steamMake600.setQuantity('Massflowrate');
    this.steamUse600.setQuantity('Massflowrate');
    this.steamMake150.setQuantity('Massflowrate');
    this.steamUse150.setQuantity('Massflowrate');
    this.steamMake50.setQuantity('Massflowrate');
    this.steamUse50.setQuantity('Massflowrate');
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();

    this.steamMake900.setName('#900 Steam Make');
    this.steamMake600.setName('#600 Steam Make');
    this.steamMake150.setName('#150 Steam Make');
    this.steamMake50.setName('#50 Steam Make');

    this.steamUse900.setName('#900 Steam Use');
    this.steamUse600.setName('#600 Steam Use');
    this.steamUse150.setName('#150 Steam Use');
    this.steamUse50.setName('#50 Steam Use');
  }

  override dePersist(uo: any) {
    super.dePersist(uo);
    this.steamMake900 = this.getSimulationVariableSafe(uo.steamMake900);
    this.steamUse900 = this.getSimulationVariableSafe(uo.steamUse900);
    this.steamMake600 = this.getSimulationVariableSafe(uo.steamMake600);
    this.steamUse600 = this.getSimulationVariableSafe(uo.steamUse600);
    this.steamMake150 = this.getSimulationVariableSafe(uo.steamMake150);
    this.steamUse150 = this.getSimulationVariableSafe(uo.steamUse150);
    this.steamMake50 = this.getSimulationVariableSafe(uo.steamMake50);
    this.steamUse50 = this.getSimulationVariableSafe(uo.steamUse50);
  }

  override clearResults() {
    super.clearResults();
    this.steamMake900.value = null;
    this.steamUse900.value = null;
    this.steamMake600.value = null;
    this.steamUse600.value = null;
    this.steamMake150.value = null;
    this.steamUse150.value = null;
    this.steamMake50.value = null;
    this.steamUse50.value = null;
  }

  getSteamMakeVariableByType(steamType: SteamType): SimulationVariable {
    switch (steamType) {
      case SteamType.Steam900:
        return this.steamMake900;
      case SteamType.Steam600:
        return this.steamMake600;
      case SteamType.Steam150:
        return this.steamMake150;
      case SteamType.Steam50:
        return this.steamMake50;
    }
  }

  getSteamUseVariableByType(steamType: SteamType): SimulationVariable {
    switch (steamType) {
      case SteamType.Steam900:
        return this.steamUse900;
      case SteamType.Steam600:
        return this.steamUse600;
      case SteamType.Steam150:
        return this.steamUse150;
      case SteamType.Steam50:
        return this.steamUse50;
    }
  }

  override detectChanges(another: any): boolean {
    const changes = super.detectChanges(another);
    return changes || another.steamModelScript !== this.steamModelScript;
  }
}
