/* eslint-disable max-len */
export const DEFAULT_STEAM_MODEL =
  '// Variables available to user: \n' +
  '// "InletVolumetricFlowRate_KBPCD" : InletVolumetricFlowRate in kbpcd\n' +
  '//Methods Available to use\n' +
  '// GetUnitOpInletVolumetricFlowRate(string UnitOperationName) \n' +
  '// Gets the UnitOperationName as string and returns the Inlet Volumetric Flowrate to any operation in the flowsheet\n' +
  '// GetStreamVolumetricFlowRate(string MaterialStreamName) \n' +
  '// Gets the MaterialStreamName as string and returns the volumetric Flowrate of any stream in the flowsheet\n' +
  '// Calculated Variables:\n' +
  '// Steam Use parameters (all calculated in kpph)\n' +
  '// "SteamUse900" , "SteamUse600" , "SteamUse150", "SteamUse50"\n' +
  '// Steam Make parameters (all calculated in kpph)\n' +
  '// "SteamMake900" , "SteamMake600" , "SteamMake150", "SteamMake50"\n' +
  'SteamUse900 = 0.0;\n' +
  'SteamUse600 = 0.0;\n' +
  'SteamUse150 = 0.0;\n' +
  'SteamUse50 = 0.0;\n' +
  'SteamMake900 = 0.0;\n' +
  'SteamMake600 = 0.0;\n' +
  'SteamMake150 = 0.0;\n' +
  'SteamMake50 = 0.0;';
