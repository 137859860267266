import { DistributionRatioVariable } from './distribution-ratio-variable';

export class RangeDistributionRatioVariable extends DistributionRatioVariable {
  minimumValue?: number;
  maximumValue?: number;

  constructor(dv: any) {
    super(dv.unitOperationId, dv.value);
    this.minimumValue = dv.minimumValue;
    this.maximumValue = dv.maximumValue;
  }
}
