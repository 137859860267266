<div id="wrapper">
  <sob-flowsheet-navbar></sob-flowsheet-navbar>

  <sob-flowsheet-side-menu></sob-flowsheet-side-menu>

  <div id="page-wrapper" class="gray-bg">
    <!--    it is important to have the colorpicker here!-->
    <sob-diagram-item-color-picker></sob-diagram-item-color-picker>
    <div class="tab-content">
      <div class="col-xs-12 tab-pane fade in active" id="flowsheet">
        <div class="col-xs-12 no-padding" id="flowsheet-workspace">
          <div class="tab-content">
            <div role="tabpanel" class="tab-pane active" id="flowsheet-diagram-container">
              <sob-sub-flowsheet-info
                *ngIf="activeFlowsheetOwner"
                [activeFlowsheetOwner]="activeFlowsheetOwner"></sob-sub-flowsheet-info>
              <sob-flowsheet-diagram></sob-flowsheet-diagram>
            </div>
            <div role="tabpanel" class="tab-pane" id="sankey-container">
              <sob-sankey-diagram></sob-sankey-diagram>
            </div>
            <div role="tabpanel" class="tab-pane" id="flowsheet-spreadsheet">
              <sob-flowsheet-spreadsheet></sob-flowsheet-spreadsheet>
            </div>

            <div role="tabpanel" class="tab-pane" id="multi-period-settings-container">
              <sob-multi-period-settings></sob-multi-period-settings>
            </div>
          </div>

          <sob-flowsheet-diagram-palette></sob-flowsheet-diagram-palette>
          <sob-bottom-bar></sob-bottom-bar>
        </div>
        <sob-property-window
          [unitOperation]="currentUnitOp"
          [formGroupWrapper]="propertyWindowFormGroupWrapper"
          *ngIf="currentUnitOp">
        </sob-property-window>
      </div>
    </div>

    <sob-save-case-file></sob-save-case-file>
    <sob-open-case-file></sob-open-case-file>
    <sob-case-settings></sob-case-settings>
    <sob-material-stream></sob-material-stream>
    <sob-water-material-stream></sob-water-material-stream>
    <sob-fuel-gas-material-stream></sob-fuel-gas-material-stream>
    <sob-kpi-manager-modal [kpiManager]="kpiManager"></sob-kpi-manager-modal>
    <sob-parametric-study-window [caseStudyManager]="caseStudyManager"></sob-parametric-study-window>
    <sob-summary-report></sob-summary-report>
    <sob-multi-period-results></sob-multi-period-results>
    <sob-upgrader-report></sob-upgrader-report>
    <sob-case-comments></sob-case-comments>
    <sob-excel-report></sob-excel-report>
    <sob-multiperiod-excel-report></sob-multiperiod-excel-report>
    <sob-case-study-excel-report></sob-case-study-excel-report>
    <sob-optimization-settings-table></sob-optimization-settings-table>
    <sob-progress-indicator></sob-progress-indicator>
    <sob-flowsheet-comments></sob-flowsheet-comments>
    <sob-about-window></sob-about-window>
    <sob-send-case-modal></sob-send-case-modal>
    <sob-case-list></sob-case-list>
    <sob-global-kpi></sob-global-kpi>
    <sob-code-editor></sob-code-editor>
    <sob-diagram-controls></sob-diagram-controls>
    <sob-sankey-diagram-controls></sob-sankey-diagram-controls>
    <sob-assay-manager [assayManager]="assayManager"></sob-assay-manager>
    <sob-constraint-violation-report></sob-constraint-violation-report>
    <sob-search-modal></sob-search-modal>
  </div>
</div>
