import { FlexSheetMapper } from './flex-sheet-mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';
import { AssayGasesSheetRows } from '../cell-config/assay-gases-cell-config';

export class AssayGasesFlexsheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(assay: GasFluidAnalysis, row: number, value: string | number) {
    if (row === AssayGasesSheetRows.NAME && typeof value === 'string') {
      assay.assayName = value;
    }

    if (typeof value === 'number') {
      switch (row) {
        case AssayGasesSheetRows.COMP_CO:
          this.simulationVariableMapper.map(value, assay.composition[0]);
          break;
        case AssayGasesSheetRows.COMP_CO2:
          this.simulationVariableMapper.map(value, assay.composition[1]);
          break;
        case AssayGasesSheetRows.COMP_H2:
          this.simulationVariableMapper.map(value, assay.composition[2]);
          break;
        case AssayGasesSheetRows.COMP_N2:
          this.simulationVariableMapper.map(value, assay.composition[3]);
          break;
        case AssayGasesSheetRows.COMP_H2S:
          this.simulationVariableMapper.map(value, assay.composition[4]);
          break;
        case AssayGasesSheetRows.COMP_SO2:
          this.simulationVariableMapper.map(value, assay.composition[5]);
          break;
        case AssayGasesSheetRows.COMP_METHANE:
          this.simulationVariableMapper.map(value, assay.composition[6]);
          break;
        case AssayGasesSheetRows.COMP_ETHANE:
          this.simulationVariableMapper.map(value, assay.composition[7]);
          break;
        case AssayGasesSheetRows.COMP_PROPANE:
          this.simulationVariableMapper.map(value, assay.composition[8]);
          break;
        case AssayGasesSheetRows.COMP_IC4:
          this.simulationVariableMapper.map(value, assay.composition[9]);
          break;
        case AssayGasesSheetRows.COMP_NC4:
          this.simulationVariableMapper.map(value, assay.composition[10]);
          break;
        case AssayGasesSheetRows.COMP_IC5:
          this.simulationVariableMapper.map(value, assay.composition[11]);
          break;
        case AssayGasesSheetRows.COMP_NC5:
          this.simulationVariableMapper.map(value, assay.composition[12]);
          break;
        case AssayGasesSheetRows.COMP_C6:
          this.simulationVariableMapper.map(value, assay.composition[13]);
          break;
      }
    }
  }
}
