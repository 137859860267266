import * as wjcSheet from '@grapecity/wijmo.grid.sheet';

import { UnitOperationFlexSheetEventHandler } from './unit-operation-flex-sheet-event-handler';
import { Upgrader } from '../../../_models/_unit-operations/upgrader';
import { FlexSheetValueConverter } from '../flex-sheet-value-converter';
import { enumValuesToArray } from '../../../_utils/enum-values-to-array';
import { NumberToSimulationVariableMapper } from '../mappers/number-to-simulation-variable-mapper';
import { UpgraderCellConfig, UpgraderSheetRows } from '../cell-config/upgrader-cell-config';
import { UpgraderFlexSheetMapper } from '../mappers/upgrader-flex-sheet-mapper';

export class UpgraderFlexSheetEventHandler implements UnitOperationFlexSheetEventHandler {
  constructor(private valueConverter: FlexSheetValueConverter) {}

  handle(unitOperation: Upgrader, spreadsheet: wjcSheet.FlexSheet, flexSheetEvent, rawValue) {
    const { row } = flexSheetEvent;

    if (
      enumValuesToArray(UpgraderSheetRows).indexOf(row) === -1 ||
      row === UpgraderSheetRows.TOTAL_H2S_PRODUCTION ||
      row === UpgraderSheetRows.AMINE_CIRCULATION_RATE
    ) {
      flexSheetEvent.cancel = true;
      return;
    }

    const cellConfig = UpgraderCellConfig.find(rowConfig => rowConfig.row === row);
    const convertedValue = this.valueConverter.convert(spreadsheet, rawValue, cellConfig);

    const simulationVariableMapper = new NumberToSimulationVariableMapper();
    const mapper = new UpgraderFlexSheetMapper(simulationVariableMapper);

    mapper.map(unitOperation, row, convertedValue);
  }
}
