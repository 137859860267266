import { AfterViewInit, Component } from '@angular/core';
import { CoreService } from '../../_services/core.service';
import { UnitOperation } from '../../_models';
import { FlowsheetDiagramService } from '../../_services/flowsheet-diagram/flowsheet-diagram.service';

@Component({
  selector: 'sob-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.css'],
})
export class SearchModalComponent implements AfterViewInit {
  searchTerm = '';
  selectedUnitId = '';

  constructor(private coreService: CoreService, private flowsheetDiagramService: FlowsheetDiagramService) {}

  zoomToUnitOperation() {
    this.searchTerm = '';
    $('#searchModal').modal('hide');
    this.flowsheetDiagramService.zoomToUnitOperation(this.selectedUnitId);
    this.selectedUnitId = '';
  }

  get unitOperations() {
    let activeFlowsheet;
    if (this.coreService.currentCase.getActiveFlowsheet()) {
      const uoId = this.coreService.currentCase.getActiveFlowsheet().unitOperationId;
      activeFlowsheet = this.coreService.currentCase.getUnitOperation(uoId);
    }
    if (activeFlowsheet) {
      return this.coreService.currentCase.filterUnitOperations(
        uo =>
          uo instanceof UnitOperation &&
          uo.flowsheetId === this.coreService.currentCase.getActiveFlowsheet().unitOperationId
      );
    }
    return this.coreService.currentCase.filterUnitOperations(
      uo => uo instanceof UnitOperation && uo.flowsheetId === ''
    );
  }

  assignSearchTerm(searchTerm) {
    this.searchTerm = searchTerm;
    this.selectedUnitId = searchTerm;
  }

  ngAfterViewInit(): void {
    jQuery('#search-bar').focusin(() => {
      jQuery('#facilities').css('display', 'block');
    });
    jQuery('#facilities').on('click', $e => {
      this.searchTerm = $e.target.innerText;
      jQuery('#facilities').css('display', 'none');
    });
    jQuery('#search-bar').focusout(() => {
      setTimeout(() => {
        jQuery('#facilities').css('display', 'none');
      }, 100);
    });
  }
}
