import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';
import { LgoHydrotreater } from '../_models/_unit-operations/lgo-hydrotreater';

export class LgoHydrotreaterFormChangeDetector extends DefaultBaseObjectFormChangeDetector {
  override detectChangesInValues(originalFormValue: any, currentValue: any): boolean {
    let changes = super.detectChangesInValues(originalFormValue, currentValue);

    const originalLgoValues = originalFormValue as LgoHydrotreater;
    const currentLgoValues = currentValue as LgoHydrotreater;

    if (
      originalLgoValues.customModelScript !== currentLgoValues.customModelScript ||
      originalLgoValues.steamModelScript !== currentLgoValues.steamModelScript ||
      originalLgoValues.gasModelScript !== currentLgoValues.gasModelScript
    ) {
      changes = changes || true;
    }

    return changes;
  }
}
