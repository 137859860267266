import { Completion } from '@codemirror/autocomplete';
import { DefaultCodeProvider } from './default-code-provider';
import { defaultHgoHydrotreaterModel } from '../../_config/unit-operations/default-models/default-hgo-hydrotreater-model';
import { hgoHydrotreaterCodeCompletions } from '../../_config/unit-operations/custom-code-completions/hgo-hydrotreater-code-completions';

export class HgoHydrotreaterCodeProvider extends DefaultCodeProvider {
  constructor() {
    super(defaultHgoHydrotreaterModel);
  }

  override getCodeCompletions(): Completion[] {
    return super.getCodeCompletions().concat(hgoHydrotreaterCodeCompletions);
  }
}
