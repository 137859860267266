import { FlexSheetMapper } from './flex-sheet-mapper';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { AssaySheetRows } from '../cell-config/assay-cell-config';

export class AssayFlexsheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(assay: SuncorFluidAnalysis, row: number, value: string | number) {
    if (row === AssaySheetRows.NAME && typeof value === 'string') {
      assay.assayName = value;
    }
    if (typeof value === 'number') {
      switch (row) {
        case AssaySheetRows.COMP_LIGHT_ENDS:
          this.simulationVariableMapper.map(value, assay.composition[0]);
          break;

        case AssaySheetRows.COMP_LIGHT_NAPHTHA:
          this.simulationVariableMapper.map(value, assay.composition[1]);
          break;

        case AssaySheetRows.COMP_HEAVY_NAPHTHA:
          this.simulationVariableMapper.map(value, assay.composition[2]);
          break;

        case AssaySheetRows.COMP_DISTILLATE:
          this.simulationVariableMapper.map(value, assay.composition[3]);
          break;

        case AssaySheetRows.COMP_LGO:
          this.simulationVariableMapper.map(value, assay.composition[4]);
          break;

        case AssaySheetRows.COMP_HGO:
          this.simulationVariableMapper.map(value, assay.composition[5]);
          break;

        case AssaySheetRows.COMP_VGO:
          this.simulationVariableMapper.map(value, assay.composition[6]);
          break;

        case AssaySheetRows.COMP_HVGO:
          this.simulationVariableMapper.map(value, assay.composition[7]);
          break;

        case AssaySheetRows.COMP_RESID:
          this.simulationVariableMapper.map(value, assay.composition[8]);
          break;
        // Light ends
        case AssaySheetRows.LE_SULFUR:
          this.simulationVariableMapper.map(value, assay.lightEndsCut.sulfur);
          break;

        case AssaySheetRows.LE_ANILINE_POINT:
          this.simulationVariableMapper.map(value, assay.lightEndsCut.anilinePoint);
          break;

        case AssaySheetRows.LE_MASS_DENSITY:
          this.simulationVariableMapper.map(value, assay.lightEndsCut.massDensity);
          break;

        case AssaySheetRows.LE_NITROGEN:
          this.simulationVariableMapper.map(value, assay.lightEndsCut.nitrogen);
          break;

        case AssaySheetRows.LE_CCR:
          this.simulationVariableMapper.map(value, assay.lightEndsCut.ccr);
          break;

        // Light naphtha
        case AssaySheetRows.LN_SULFUR:
          this.simulationVariableMapper.map(value, assay.lightNaphthaCut.sulfur);
          break;

        case AssaySheetRows.LN_ANILINE_POINT:
          this.simulationVariableMapper.map(value, assay.lightNaphthaCut.anilinePoint);
          break;

        case AssaySheetRows.LN_MASS_DENSITY:
          this.simulationVariableMapper.map(value, assay.lightNaphthaCut.massDensity);
          break;

        case AssaySheetRows.LN_NITROGEN:
          this.simulationVariableMapper.map(value, assay.lightNaphthaCut.nitrogen);
          break;

        case AssaySheetRows.LN_CCR:
          this.simulationVariableMapper.map(value, assay.lightNaphthaCut.ccr);
          break;

        // Heavy Naphtha
        case AssaySheetRows.HN_SULFUR:
          this.simulationVariableMapper.map(value, assay.heavyNaphthaCut.sulfur);
          break;

        case AssaySheetRows.HN_ANILINE_POINT:
          this.simulationVariableMapper.map(value, assay.heavyNaphthaCut.anilinePoint);
          break;

        case AssaySheetRows.HN_MASS_DENSITY:
          this.simulationVariableMapper.map(value, assay.heavyNaphthaCut.massDensity);
          break;

        case AssaySheetRows.HN_NITROGEN:
          this.simulationVariableMapper.map(value, assay.heavyNaphthaCut.nitrogen);
          break;

        case AssaySheetRows.HN_CCR:
          this.simulationVariableMapper.map(value, assay.heavyNaphthaCut.ccr);
          break;

        // Distillate
        case AssaySheetRows.DIS_SULFUR:
          this.simulationVariableMapper.map(value, assay.distillateCut.sulfur);
          break;

        case AssaySheetRows.DIS_ANILINE_POINT:
          this.simulationVariableMapper.map(value, assay.distillateCut.anilinePoint);
          break;

        case AssaySheetRows.DIS_MASS_DENSITY:
          this.simulationVariableMapper.map(value, assay.distillateCut.massDensity);
          break;

        case AssaySheetRows.DIS_NITROGEN:
          this.simulationVariableMapper.map(value, assay.distillateCut.nitrogen);
          break;

        case AssaySheetRows.DIS_CCR:
          this.simulationVariableMapper.map(value, assay.distillateCut.ccr);
          break;

        // LGO
        case AssaySheetRows.LGO_SULFUR:
          this.simulationVariableMapper.map(value, assay.LGOCut.sulfur);
          break;

        case AssaySheetRows.LGO_ANILINE_POINT:
          this.simulationVariableMapper.map(value, assay.LGOCut.anilinePoint);
          break;

        case AssaySheetRows.LGO_MASS_DENSITY:
          this.simulationVariableMapper.map(value, assay.LGOCut.massDensity);
          break;

        case AssaySheetRows.LGO_NITROGEN:
          this.simulationVariableMapper.map(value, assay.LGOCut.nitrogen);
          break;

        case AssaySheetRows.LGO_CCR:
          this.simulationVariableMapper.map(value, assay.LGOCut.ccr);
          break;

        // HGO
        case AssaySheetRows.HGO_SULFUR:
          this.simulationVariableMapper.map(value, assay.HGOCut.sulfur);
          break;

        case AssaySheetRows.HGO_ANILINE_POINT:
          this.simulationVariableMapper.map(value, assay.HGOCut.anilinePoint);
          break;

        case AssaySheetRows.HGO_MASS_DENSITY:
          this.simulationVariableMapper.map(value, assay.HGOCut.massDensity);
          break;

        case AssaySheetRows.HGO_NITROGEN:
          this.simulationVariableMapper.map(value, assay.HGOCut.nitrogen);
          break;

        case AssaySheetRows.HGO_CCR:
          this.simulationVariableMapper.map(value, assay.HGOCut.ccr);
          break;

        // VGO
        case AssaySheetRows.VGO_SULFUR:
          this.simulationVariableMapper.map(value, assay.VGOCut.sulfur);
          break;

        case AssaySheetRows.VGO_ANILINE_POINT:
          this.simulationVariableMapper.map(value, assay.VGOCut.anilinePoint);
          break;

        case AssaySheetRows.VGO_MASS_DENSITY:
          this.simulationVariableMapper.map(value, assay.VGOCut.massDensity);
          break;

        case AssaySheetRows.VGO_NITROGEN:
          this.simulationVariableMapper.map(value, assay.VGOCut.nitrogen);
          break;

        case AssaySheetRows.VGO_CCR:
          this.simulationVariableMapper.map(value, assay.VGOCut.ccr);
          break;

        // HVGO
        case AssaySheetRows.HVGO_SULFUR:
          this.simulationVariableMapper.map(value, assay.HVGOCut.sulfur);
          break;

        case AssaySheetRows.HVGO_ANILINE_POINT:
          this.simulationVariableMapper.map(value, assay.HVGOCut.anilinePoint);
          break;

        case AssaySheetRows.HVGO_MASS_DENSITY:
          this.simulationVariableMapper.map(value, assay.HVGOCut.massDensity);
          break;

        case AssaySheetRows.HVGO_NITROGEN:
          this.simulationVariableMapper.map(value, assay.HVGOCut.nitrogen);
          break;

        case AssaySheetRows.HVGO_CCR:
          this.simulationVariableMapper.map(value, assay.HVGOCut.ccr);
          break;

        // Resid
        case AssaySheetRows.RES_SULFUR:
          this.simulationVariableMapper.map(value, assay.residCut.sulfur);
          break;

        case AssaySheetRows.RES_ANILINE_POINT:
          this.simulationVariableMapper.map(value, assay.residCut.anilinePoint);
          break;

        case AssaySheetRows.RES_MASS_DENSITY:
          this.simulationVariableMapper.map(value, assay.residCut.massDensity);
          break;

        case AssaySheetRows.RES_NITROGEN:
          this.simulationVariableMapper.map(value, assay.residCut.nitrogen);
          break;

        case AssaySheetRows.RES_CCR:
          this.simulationVariableMapper.map(value, assay.residCut.ccr);
          break;
      }
    }
  }
}
