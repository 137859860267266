import { Injectable } from '@angular/core';
import {
  AbstractControlOptions,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { SimulationVariable } from '../_models';

export type SimulationVariableForm = FormGroup<{ value: FormControl<number | undefined>; unit: FormControl<string> }>;

export type SimulationVariableConstraintForm = FormGroup<{
  value: FormControl<number | undefined>;
  unit: FormControl<string>;
  isActive: FormControl<boolean>;
}>;

@Injectable({ providedIn: 'root' })
export class SimVarFormBuilder {
  control(
    simVar: SimulationVariable,
    valueInputDisabled: boolean = false,
    unitSelectDisabled: boolean = false,
    isConstraint?: boolean,
    valueValidatorOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    unitValidatorOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null
  ): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      value: new UntypedFormControl({ value: simVar.value, disabled: valueInputDisabled }, valueValidatorOpts),

      unit: new UntypedFormControl({ value: simVar.unit, disabled: unitSelectDisabled }, unitValidatorOpts),
    });

    if (isConstraint) {
      formGroup.addControl('isActive', new UntypedFormControl(simVar.isActive));
    }

    return formGroup;
  }

  populatedControl(
    sv: SimulationVariable,
    valueInputDisabled: boolean = false,
    unitSelectDisabled: boolean = false,
    valueValidatorOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    unitValidatorOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null
  ): SimulationVariableForm {
    return new FormGroup({
      value: new FormControl<number | undefined>({ value: sv.value, disabled: valueInputDisabled }, valueValidatorOpts),
      unit: new FormControl<string>({ value: sv.unit, disabled: unitSelectDisabled }, unitValidatorOpts),
    });
  }

  emptyControl(
    valueInputDisabled: boolean = false,
    unitSelectDisabled: boolean = false,
    valueValidatorOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    unitValidatorOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null
  ): SimulationVariableForm {
    return new FormGroup({
      value: new FormControl<number | undefined>(
        { value: undefined, disabled: valueInputDisabled },
        valueValidatorOpts
      ),
      unit: new FormControl<string>({ value: '', disabled: unitSelectDisabled }, unitValidatorOpts),
    });
  }

  emptyConstraintControl(
    valueInputDisabled: boolean = false,
    unitSelectDisabled: boolean = false,
    valueValidatorOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    unitValidatorOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null
  ): SimulationVariableConstraintForm {
    return new FormGroup({
      value: new FormControl<number | undefined>(
        { value: undefined, disabled: valueInputDisabled },
        valueValidatorOpts
      ),
      unit: new FormControl<string>({ value: '', disabled: unitSelectDisabled }, unitValidatorOpts),
      isActive: new FormControl<boolean>(false),
    });
  }

  patchValue(sv: SimulationVariable, svFormGroup: SimulationVariableForm) {
    svFormGroup.patchValue({ value: sv.value, unit: sv.unit });
  }

  patchConstraintValue(sv: SimulationVariable, svFormGroup: SimulationVariableConstraintForm) {
    svFormGroup.patchValue({ value: sv.value, unit: sv.unit, isActive: sv.isActive });
  }
}
