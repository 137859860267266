<div class="multi-step" id="multi-period-settings-wrapper">
  <div class="multi-period-step col-xs-12">
    <div class="row">
      <div
        class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4 p-m white-bg">
        <sob-multi-period-inventory-settings [formGroup]="formGroup"> </sob-multi-period-inventory-settings>

        <div class="row">
          <div class="col-xs-12 text-right">
            <button type="button" class="btn btn-link" (click)="onFirstStepNextClicked()">
              Define Time-Dependent Variables <i class="fa fa-arrow-right font-lighter"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="multi-period-step col-xs-12">
    <div class="row">
      <div class="col-xs-12 col-lg-10 col-lg-offset-1 col-xl-8 col-xl-offset-2 m-b-md p-md white-bg">
        <sob-multi-period-parameter-selector
          [multiPeriodSettings]="multiPeriodSettings"
          *ngIf="multiPeriodSettings"></sob-multi-period-parameter-selector>

        <div class="row m-t-md">
          <div class="col-xs-6 text-left">
            <button type="button" class="btn btn-link" (click)="showPreviousStep()">
              <i class="fa fa-arrow-left font-lighter"></i> Initial Setup
            </button>
          </div>

          <div class="col-xs-6 text-right">
            <button type="button" class="btn btn-link" (click)="onSecondStepNextClicked()">
              <i class="fa fa-arrow-right font-lighter"></i> Time-Dependent Variables
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="multi-period-step col-xs-12 spreadsheet-step">
    <div class="spreadsheet-wrapper spreadsheet-padding white-bg">
      <sob-multi-period-parameter-spreadsheet></sob-multi-period-parameter-spreadsheet>
    </div>

    <div class="row">
      <div class="col-xs-6 p-r-none">
        <div class="p-xs white-bg text-left">
          <button type="button" class="btn btn-link p-h-0-important" (click)="showPreviousStep()">
            <i class="fa fa-arrow-left font-lighter"></i> Define Time-Dependent Variables
          </button>
        </div>
      </div>

      <div class="col-xs-6 p-l-none">
        <div class="p-xs white-bg text-right">
          <button type="button" class="btn btn-link p-h-0-important" (click)="onThirdStepNextClicked()">
            <i class="fa fa-arrow-right font-lighter"></i> Define KPIs
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="multi-period-step col-xs-12">
    <div class="row">
      <div class="col-xs-12 col-lg-10 col-lg-offset-1 col-xl-8 col-xl-offset-2 m-b-md p-md white-bg">
        <div>
          <h3><b>Define KPIs</b></h3>
          <br />
        </div>
        <sob-kpi-manager [kpiManager]="kpiManager"></sob-kpi-manager>

        <div class="row m-t-md">
          <div class="col-xs-6 text-left">
            <button type="button" class="btn btn-link" (click)="onFourthStepPreviousClicked()">
              <i class="fa fa-arrow-left font-lighter"></i> Time-Dependent Values
            </button>
          </div>

          <div class="col-xs-6 text-right">
            <button type="button" class="btn btn-link" (click)="onMultiPeriodSettingsDone()">
              <i class="fa fa-check font-lighter"></i> Return to Flowsheet
            </button>

            <button type="button" class="btn btn-link" (click)="solveCase()">
              <i class="fa fa-play font-lighter"></i> Run Case
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
