/* eslint-disable max-len */
export const DEFAULT_HYDROCRACKER_MODEL =
  '// Variables available to user: \n' +
  '// "Inletccrpercent" : CCR of the feed in wt%\n' +
  '// "InletSpecificGravity" : Specific gravity of the feed\n' +
  '// "WatsonK" : UOP Watson K factor of the feed\n' +
  '// "H2MassPercent" : Hydrogen use as wt% of the feed\n' +
  '// GetStreamFlowRate (string MaterialStreamName, FlowrateBasis basis)\n' +
  '// Gets the MaterialStreamName and "FlowrateBasis" and return the flowrate\n' +
  '// Valid Options for FlowrateBasis: \n' +
  '// FlowrateBasis.Mass (returns in TPD)| FlowrateBasis.Volume (returns in kbpcd)\n' +
  '// GetStreamVolumetricFlowRate(string MaterialStreamName) \n' +
  '// Gets the MaterialStreamName as string and returns the volumetric Flowrate of any stream in the flowsheet\n' +
  '// GetStreamCutFlowRate(string streamName, SuncorCuts suncorCut, FlowrateBasis basis)\n' +
  '// Gets streamName, SuncorCut and Flowrate basis and returns the cut flowrate for the given stream\n ' +
  '// Valid options for SuncorCut:\n ' +
  '// SuncorCuts.LightEnd, SuncorCuts.LightNaphta, SuncorCuts.HeavyNaphta, \n' +
  '// SuncorCuts.Distillate, SuncorCuts.LightGasOil, SuncorCuts.HeavyGasOil, \n' +
  '// SuncorCuts.VacGasOil, SuncorCuts.HeavyVacGasOil, SuncorCuts.VacuumResidue \n' +
  '// Calculated Variables:\n' +
  '// Yield parameters (all calculated in wt%)\n' +
  '// "DiluentYield" , "NaphtaYield" , "LGOYield", "HGOYield", "ResidueYield"\n' +
  '// Properties:\n' +
  '// "NaphtaAPI, "LGOAPI", "HGOAPI", "ResidueAPI"\n' +
  '// "NaphtaSulfur", "LGOSulfur", "HGOSulfur", "ResidueOilSulfur" (in wt%)\n' +
  '// "NaphtaNitrogen", "LGONitrogen", "HGONitrogen", "ResidueNitrogen" (in ppmw)\n' +
  'double vh = 0.6621 * H2MassPercent * InletSpecificGravity;\n' +
  'double inletAPI = 141.5 / InletSpecificGravity - 131.5;\n' +
  'double gasolineYieldLVPercent = -0.03734 * (inletAPI * inletAPI) + 1.57575 * inletAPI + 0.014923 * WatsonK - 1.36473 * vh - 0.16324 * (vh / WatsonK);\n' +
  'DiluentYield = 0.8672 * gasolineYieldLVPercent - 0.9969; // in wt%\n' +
  'double c4YieldLVPercent = 0.020359 * (gasolineYieldLVPercent * gasolineYieldLVPercent) + 0.04888 * gasolineYieldLVPercent+ 0.108964 * inletAPI;\n' +
  'double c4YieldWtPercent = 0.8672 * c4YieldLVPercent - 0.5;\n' +
  'double naphthaYieldLVPercent = -0.10322 * (gasolineYieldLVPercent * gasolineYieldLVPercent) + 2.981215 * gasolineYieldLVPercent - 0.07898 * inletAPI;\n' +
  'NaphtaYield = 0.8672 * naphthaYieldLVPercent - 0.9969; // in wt%\n' +
  'LGOYield = (100.0 + H2MassPercent - NaphtaYield - c4YieldWtPercent - DiluentYield) * 0.41; // in wt%\n' +
  'HGOYield = (100.0 + H2MassPercent - NaphtaYield - c4YieldWtPercent - DiluentYield) * 0.41; // in wt%\n' +
  'ResidueYield = (100.0 + H2MassPercent - NaphtaYield - c4YieldWtPercent - DiluentYield) * (1.0 - 0.41); // in wt%\n' +
  'NaphtaAPI = 0.0217 * Inletccrpercent + 55.018;\n' +
  '//All gas oil components (LGO, HGO, VGO, HVGO) are assumed to have the same Density\n' +
  'LGOAPI = -0.295 * Inletccrpercent + 14.438;\n' +
  'HGOAPI = -0.295 * Inletccrpercent + 14.438;\n' +
  'ResidueAPI = -0.295 * Inletccrpercent + 14.438;\n' +
  'NaphtaSulfur = 0.0026 * Inletccrpercent + 1.796;\n' +
  '//All gas oil components (LGO,. HGO, VGO, HVGO) are assumed to have the same sulfur content\n' +
  'LGOSulfur = 0.1319 * Inletccrpercent + 2.1964;\n' +
  'HGOSulfur = 0.1319 * Inletccrpercent + 2.1964;\n' +
  'ResidueSulfur = 0.1319 * Inletccrpercent + 2.1964;\n' +
  'NaphtaNitrogen = 3.8236 * Inletccrpercent + 82.464;\n' +
  '//All gas oil components (LGO, HGO, VGO, HVGO) are assumed to have the same Nitrogen content\n' +
  'LGONitrogen = 44.724 * Inletccrpercent + 2931.7;\n' +
  'HGONitrogen = 44.724 * Inletccrpercent + 2931.7;\n' +
  'ResidueNitrogen = 44.724 * Inletccrpercent + 2931.7;';
