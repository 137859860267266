import { ReportValue } from './report-value';
import { Case } from '../case';
import { SuncorUnitOperation } from '../_unit-operations/suncor-unit-operation';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { UnitOperation } from '../unit-operation';
import { SimulationVariable } from '../simulation-variable';
import { ReportDataSet } from './report-data-set';
import { isNullOrUndefined } from '../../_utils/utils';

declare let unitConverter: any;

export class OpexReport {
  total: number;
  unit: string;
  contributionByCategory: Array<ReportValue> = [];
  contributionByUnit: Array<ReportDataSet> = [];

  constructor(c: Case) {
    this.initReport(c);
  }

  initReport(c: Case) {
    this.total = 0;

    const units = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.category !== unitOperationsConfig.splitter.key && uo.category !== unitOperationsConfig.mixer.key;
    }) as Array<SuncorUnitOperation>;

    const valuesHolder = {};

    // with this, all unit operation types should be added automatically... except splitter and mixer
    // eslint-disable-next-line guard-for-in
    for (const cat in unitOperationsConfig) {
      const currentKey = unitOperationsConfig[cat].key;
      if (currentKey === unitOperationsConfig.splitter.key || currentKey === unitOperationsConfig.mixer.key) {
        continue;
      }

      valuesHolder[currentKey] = {};
      valuesHolder[currentKey].categoryValue = new ReportValue(0, unitOperationsConfig[cat].displayName, currentKey);
      valuesHolder[currentKey].categorySet = new ReportDataSet(unitOperationsConfig[cat].displayName, currentKey);
    }

    for (const uo of units) {
      const s = uo.opexTotal;
      const unitType = uo.category;
      let isInvalid;
      if (s) {
        isInvalid = isNaN(s.value) || isNullOrUndefined(s.value);
      } else {
        isInvalid = true;
      }
      valuesHolder[unitType].categoryValue.value += isInvalid ? 0 : s.value;
      valuesHolder[unitType].categorySet.addToData(
        new ReportValue(unitConverter.parseFloatString(isInvalid ? 0 : s.convertToDefaultUnit(true, false)), uo.name)
      );
      this.total += isInvalid ? 0 : s.value;
    }

    const sv = units.length ? units[0].opexTotal : null;

    // eslint-disable-next-line guard-for-in
    for (const cat in valuesHolder) {
      this.contributionByCategory.push(valuesHolder[cat].categoryValue);
      this.contributionByUnit.push(valuesHolder[cat].categorySet);
    }

    if (sv) {
      const internalUnit = sv.getInternalUnit();
      this.unit = sv.getDefaultUnit();

      for (const contrib of this.contributionByCategory) {
        contrib.value = unitConverter.parseFloatString(
          unitConverter(sv.quantity).convert(contrib.value, internalUnit, this.unit).round().getValue()
        );
      }

      const totalSv = new SimulationVariable(sv, sv.ownerCase, sv.ownerBaseObject);
      totalSv.value = this.total;
      this.total = unitConverter.parseFloatString(totalSv.convertToDefaultUnit(true, false));
    }
  }
}
