import { Injectable } from '@angular/core';
import { Flowsheet } from '../../_models/flowsheet-manager/flowsheet';
import { SimulationVariable } from '../../_models';
import { CoreService } from '../core.service';
import { ProjectDeserializerService } from './project-deserializer.service';
import { FlowsheetDiagramService } from '../flowsheet-diagram/flowsheet-diagram.service';
import { SankeyDiagramService } from '../../flowsheet/sankey-diagram/sankey-diagram.service';
import { MultiPeriodService } from '../multi-period/multi-period.service';

@Injectable()
export class ProjectFileLoaderService {
  constructor(
    private core: CoreService,
    private projectDeserializer: ProjectDeserializerService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private sankeyDiagramService: SankeyDiagramService,
    private multiPeriodService: MultiPeriodService
  ) {}

  load(serializedProject: string, fileName?: string, skipDiagramReload?) {
    this.core.project = this.projectDeserializer.deserialize(serializedProject);
    const currentCase = this.core.project.cases[0];
    this.core.currentCase = currentCase;

    if (!currentCase.getActiveFlowsheet()) {
      // this should happen only for cases that were created before multi flowsheet support
      currentCase.setActiveFlowsheet(new Flowsheet({}, currentCase));
      currentCase.setMainFlowsheetId(currentCase.getActiveFlowsheet().id);
      currentCase.addToPools(currentCase.getActiveFlowsheet());
    }

    if (fileName && !currentCase.name) {
      currentCase.name = fileName.replace(/\.[^/.]+$/, '').trim();
    }

    // TODO should this be fired or only notifyCurrentCaseReplaced
    this.core.notifyCaseChanged();
    this.core.notifyCurrentCaseReplaced({ wholeProjectReplaced: true });
    this.sankeyDiagramService.caseToSankeyDiagram(currentCase);

    currentCase.caseStudyManager.kpiManager.addDefaultKpis(currentCase.calculator);

    if (!skipDiagramReload) {
      this.core.caseToGoJSModel(currentCase);
    }

    this.flowsheetDiagramService.zoomToFit();

    this.cleanAllCases();
    this.core.project.fileName = fileName;
  }

  private cleanAllCases() {
    for (const currentCase of this.core.project.cases) {
      const simVarsWithoutOwner = currentCase.filterSimvars(s => {
        return !s.ownerBaseObject;
      });

      // eslint-disable-next-line no-console
      console.info(
        simVarsWithoutOwner.length,
        'simulation Variables without owner Will be removed from',
        currentCase.name
      );

      // eslint-disable-next-line guard-for-in
      for (let i = 0; i < simVarsWithoutOwner.length; i++) {
        currentCase.removeFromPools(simVarsWithoutOwner[i]);
      }

      const flowsheetsWithoutOwner = currentCase.filterFlowsheets(f => {
        return f.unitOperationId === undefined && f.id !== currentCase.getMainFlowsheetId();
      });

      // eslint-disable-next-line no-console
      console.info(flowsheetsWithoutOwner.length, 'Flowsheets without owner will be removed from', currentCase.name);

      for (let i = 0; i < flowsheetsWithoutOwner.length; i++) {
        currentCase.removeFromPools(flowsheetsWithoutOwner[i]);
      }
    }
  }
}
