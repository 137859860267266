import { Component, Input, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { VAC } from '../../../_models/_unit-operations/vac';
import { CoreService } from '../../../_services/core.service';
import { roundNumber } from '../../../_utils/utils';

declare let unitConverter: any;
@Component({
  selector: 'sob-vac',
  templateUrl: './vac.component.html',
  styleUrls: ['./vac.component.css'],
})
export class VACComponent implements OnDestroy {
  @Input()
  unitOperation: VAC;

  private subSink = new SubSink();

  // TODO this might be duplicated
  components = ['light Ends', 'light Naphtha', 'heavy Naphtha', 'distillate', 'LGO', 'HGO', 'VGO', 'HVGO', 'resid'];

  constructor(private coreService: CoreService) {
    this.subSink.add(
      this.coreService.streamAddedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.updateDistributionRatioVariables();
        }
      })
    );

    this.subSink.add(
      this.coreService.streamRemovedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.updateDistributionRatioVariables();
        }
      })
    );
  }

  findDistributionRatioName(index: number): string {
    if (this.unitOperation) {
      return this.unitOperation.findDistributionRatioVariableName(index);
    }

    return '';
  }

  onDistributionRatioVariableValueChange(params: {
    ratioVariableIndex: number;
    componentName: string;
    componentIndex: number;
  }) {
    const dvParsed = unitConverter.parseFloatString(
      this.unitOperation.distributionRatioVariables[params.ratioVariableIndex][params.componentName]
    );

    if (Number.isNaN(dvParsed)) {
      this.unitOperation.distributionRatioVariables[params.ratioVariableIndex][params.componentName] = null;
    } else {
      this.unitOperation.distributionRatioVariables[params.ratioVariableIndex][params.componentName] = roundNumber(
        dvParsed,
        5
      );
    }

    this.calculateLastDistributionVariable(params.componentIndex);
  }

  calculateLastDistributionVariable(componentIndex: number) {
    const component = this.components[componentIndex].replace(' ', '');
    let total = 0;
    for (let i = 0; i < this.unitOperation.distributionRatioVariables.length - 1; i++) {
      const dv = this.unitOperation.distributionRatioVariables[i];
      const dvParsed = unitConverter.parseFloatString(dv[component]);
      if (!Number.isNaN(dvParsed)) {
        total += dvParsed;
      } else if (dvParsed > 1) {
        this.unitOperation.clearComponent(component);
        return;
      } else {
        return;
      }
    }
    if (1 - total < 0) {
      this.unitOperation.clearComponent(component);
      return;
    }
    this.unitOperation.distributionRatioVariables[this.unitOperation.distributionRatioVariables.length - 1][component] =
      parseFloat((1 - total).toFixed(5));
  }

  private updateDistributionRatioVariables() {
    const { ownerCase } = this.unitOperation;
    const unit = ownerCase.getUnitOperation(this.unitOperation.id) as VAC;
    this.unitOperation.distributionRatioVariables = unit.distributionRatioVariables;
    this.unitOperation.boilingPointCuts = unit.boilingPointCuts;
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
