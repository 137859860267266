<div class="form-horizontal" [formGroup]="parametersGroup">
  <div class="row">
    <div class="col-sm-12">
      <strong> Parameter Values </strong>

      <!--States-->
      <button
        class="btn btn-md btn-success"
        data-toggle="tooltip"
        title="Add state"
        style="padding: 0 3px; margin: 0 10px"
        (click)="addState()">
        <i class="fa fa-plus-circle"></i>
      </button>

      <button
        class="btn btn-md btn-danger"
        data-toggle="tooltip"
        title="Remove state"
        style="padding: 0 3px"
        (click)="removeState()">
        <i class="fa fa-minus-circle"></i>
      </button>
    </div>
  </div>

  <ng-container sobReactiveMultipleVariableInput>
    <div class="row" style="padding-top: 10px">
      <div class="col-sm-6" style="margin-top: 5px; margin-bottom: 5px">
        {{ parameter.simulationVariable.getFullName() }}
      </div>

      <div class="col-sm-6">
        <sob-reactive-unit-select
          (unitChange)="updateUnit($event)"
          formControlName="unit"
          [quantity]="parameter.variableValues.quantity"></sob-reactive-unit-select>
      </div>
    </div>

    <div id="parameterStatesDiv">
      <ng-container formArrayName="values">
        <table *ngIf="parametricStudy.parameter">
          <thead>
            <th *ngFor="let value of parameter.variableValues.values; let i = index">State {{ i + 1 }}</th>
          </thead>

          <tbody>
            <tr>
              <td *ngFor="let value of valuesGroupArray.controls; let i = index">
                <div formGroupName="{{ i }}">
                  <sob-reactive-variable-value-input
                    formControlName="val"
                    (change)="updateVariableValueInput(i)"
                    [valueMetaData]="parameter.variableValues">
                  </sob-reactive-variable-value-input>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </ng-container>
</div>
