<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-lightEnds"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-lightEnds'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Light Ends
  </a>
</h4>

<div id="{{ idPrefix }}uo-lightEnds" class="collapse">
  <div class="form-horizontal">
    <sob-reactive-cut
      [readOnly]="readonly"
      [cut]="fluidAnalysis.lightEndsCut"
      [idPrefix]="idPrefix + 'lightEndsCut'"
      [formGroup]="formGroup">
    </sob-reactive-cut>
  </div>
</div>

<hr />

<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-lightNaphtha"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-lightNaphtha'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Light Naphtha
  </a>
</h4>
<div id="{{ idPrefix }}uo-lightNaphtha" class="collapse">
  <div class="form-horizontal">
    <sob-reactive-cut
      [readOnly]="readonly"
      [cut]="fluidAnalysis.lightNaphthaCut"
      [idPrefix]="idPrefix + 'lightNaphthaCut'"
      [formGroup]="formGroup">
    </sob-reactive-cut>
  </div>
</div>

<hr />

<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-heavyNaphtha"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-heavyNaphtha'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Heavy Naphtha
  </a>
</h4>
<div id="{{ idPrefix }}uo-heavyNaphtha" class="collapse">
  <div class="form-horizontal">
    <sob-reactive-cut
      [readOnly]="readonly"
      [cut]="fluidAnalysis.heavyNaphthaCut"
      [idPrefix]="idPrefix + 'heavyNaphthaCut'"
      [formGroup]="formGroup">
    </sob-reactive-cut>
  </div>
</div>

<hr />

<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-distillate"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-distillate'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Distillate
  </a>
</h4>
<div id="{{ idPrefix }}uo-distillate" class="collapse">
  <div class="form-horizontal">
    <sob-reactive-cut
      [readOnly]="readonly"
      [cut]="fluidAnalysis.distillateCut"
      [idPrefix]="idPrefix + 'distillateCut'"
      [formGroup]="formGroup">
    </sob-reactive-cut>
  </div>
</div>

<hr />

<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-lgo"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-lgo'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    LGO
  </a>
</h4>
<div id="{{ idPrefix }}uo-lgo" class="collapse">
  <div class="form-horizontal">
    <sob-reactive-cut
      [readOnly]="readonly"
      [cut]="fluidAnalysis.LGOCut"
      [idPrefix]="idPrefix + 'LGOCut'"
      [formGroup]="formGroup">
    </sob-reactive-cut>
  </div>
</div>

<hr />

<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-hgo"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-hgo'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    HGO
  </a>
</h4>
<div id="{{ idPrefix }}uo-hgo" class="collapse">
  <div class="form-horizontal">
    <sob-reactive-cut
      [readOnly]="readonly"
      [cut]="fluidAnalysis.HGOCut"
      [idPrefix]="idPrefix + 'HGOCut'"
      [formGroup]="formGroup">
    </sob-reactive-cut>
  </div>
</div>

<hr />

<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-vgo"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-vgo'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    VGO
  </a>
</h4>
<div id="{{ idPrefix }}uo-vgo" class="collapse">
  <div class="form-horizontal">
    <sob-reactive-cut
      [readOnly]="readonly"
      [cut]="fluidAnalysis.VGOCut"
      [idPrefix]="idPrefix + 'VGOCut'"
      [formGroup]="formGroup">
    </sob-reactive-cut>
  </div>
</div>

<hr />

<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-hvgo"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-hvgo'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    HVGO
  </a>
</h4>
<div id="{{ idPrefix }}uo-hvgo" class="collapse">
  <div class="form-horizontal">
    <sob-reactive-cut
      [readOnly]="readonly"
      [cut]="fluidAnalysis.HVGOCut"
      [idPrefix]="idPrefix + 'HVGOCut'"
      [formGroup]="formGroup">
    </sob-reactive-cut>
  </div>
</div>

<hr />

<h4>
  <a
    data-toggle="collapse"
    sfeCollapsePanelIcon
    href="#{{ idPrefix }}uo-resid"
    aria-expanded="false"
    [attr.aria-controls]="'#uo-resid'">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Resid
  </a>
</h4>
<div id="{{ idPrefix }}uo-resid" class="collapse">
  <div class="form-horizontal">
    <sob-reactive-cut
      [readOnly]="readonly"
      [cut]="fluidAnalysis.residCut"
      [idPrefix]="idPrefix + 'residCut'"
      [formGroup]="formGroup">
    </sob-reactive-cut>
  </div>
</div>

<hr />
