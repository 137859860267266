import { Completion } from '@codemirror/autocomplete';
import { CodemirrorCompletionType } from '../../codemirror-completion.type';

const bitumenConversionVariables: Completion[] = [
  {
    type: CodemirrorCompletionType.VARIABLE,
    label: 'ProductYields',
    info:
      'Dictionary of stream Name and Corresponding Yield Percent for the stream \n' +
      ' Example: ProductYields["Outlet1"] = 80.0 ',
  },
];

const bitumenConversionEnums: Completion[] = [
  {
    type: CodemirrorCompletionType.METHOD,
    label: 'YieldBasis',
    info: 'Valid options: YieldBasis.Mass, YieldBasis.Volume',
  },
];

export const bitumenConversionCompletions = bitumenConversionVariables.concat(bitumenConversionEnums);
