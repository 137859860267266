import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { WaterUnitOperation } from './water-unit-operation';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

export class WaterTwoPhaseSeparator extends WaterUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.waterTwoPhaseSeparator.key;

  gasLiquidCarryover: SimulationVariable;
  liquidGasCarryover: SimulationVariable;

  constructor(unitOperation: any | WaterTwoPhaseSeparator, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.gasLiquidCarryover = new SimulationVariable(unitOperation.gasLiquidCarryover, this.ownerCase, this);
    this.liquidGasCarryover = new SimulationVariable(unitOperation.liquidGasCarryover, this.ownerCase, this);
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.gasLiquidCarryover.setName(SimulationVariableName.GAS_LIQUID_CARRY_OVER);
    this.liquidGasCarryover.setName(SimulationVariableName.LIQUID_GAS_CARRY_OVER);
  }

  override setQuantities() {
    super.setQuantities();
    this.gasLiquidCarryover.setQuantity(Quantity.MOLE_FRACTION);
    this.liquidGasCarryover.setQuantity(Quantity.MOLE_FRACTION);
  }

  getAvailableKpis(): SimulationVariable[] {
    if (!this.ownerCase.multiPeriodEnabled) {
      return [this.gasLiquidCarryover, this.liquidGasCarryover];
    }
    return [];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.gasLiquidCarryover, this.liquidGasCarryover];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(unitOperation: any) {
    super.dePersist(unitOperation);
    this.gasLiquidCarryover = this.getSimulationVariableSafe(unitOperation.gasLiquidCarryover);
    this.liquidGasCarryover = this.getSimulationVariableSafe(unitOperation.liquidGasCarryover);
    this.setQuantities();
    this.setDefaultValues();
  }
}
