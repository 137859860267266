<div id="paletteButton" (click)="togglePalette()">
  <i class="fa fa-forward"></i>
</div>
<div id="paletteWrapper">
  <div id="normalPalettes">
    <button id="sourcesBtn" data-toggle="collapse" data-target="#sources" class="palette-category btn btn-link">
      Sources
    </button>
    <div id="sources" class="palette-container palette collapse in sources"></div>
    <hr />

    <button id="processingBtn" data-toggle="collapse" data-target="#processing" class="palette-category btn btn-link">
      Processing
    </button>
    <div id="processing" class="palette-container palette collapse in processing"></div>
    <hr />

    <button id="transportBtn" data-toggle="collapse" data-target="#transport" class="palette-category btn btn-link">
      Infrastructure
    </button>
    <div id="transport" class="palette-container palette collapse in transport"></div>
    <hr />
    <button id="connectorsBtn" data-toggle="collapse" data-target="#connectors" class="palette-category btn btn-link">
      Connectors
    </button>
    <div id="connectors" class="palette-container palette collapse in connectors"></div>
  </div>
  <div id="upgraderPaletteContainer" style="display: none">
    <button
      id="generalBtn"
      data-toggle="collapse"
      data-target="#generalUpgraderPalette"
      class="palette-category btn btn-link">
      General
    </button>
    <div id="generalUpgraderPalette" class="palette-container palette collapse in general"></div>
    <hr />

    <button
      id="upgrderProcessingBtn"
      data-toggle="collapse"
      data-target="#processingUpgraderPalette"
      class="palette-category btn btn-link">
      Processing
    </button>
    <div id="processingUpgraderPalette" class="palette-container palette collapse in upgraderProcessing"></div>
    <hr />

    <button
      id="reactorsBtn"
      data-toggle="collapse"
      data-target="#reactorsUpgraderPalette"
      class="palette-category btn btn-link">
      Reactors
    </button>
    <div id="reactorsUpgraderPalette" class="palette-container palette collapse in reactors"></div>
    <hr />

    <button
      id="utilitiesAndEnvironmentalBtn"
      data-toggle="collapse"
      data-target="#utilitiesAndEnvironmentalUpgraderPalette"
      class="palette-category btn btn-link">
      Utilities & Environment
    </button>
    <div
      id="utilitiesAndEnvironmentalUpgraderPalette"
      class="palette-container palette collapse in utilitiesAndEnvironmental"></div>
  </div>

  <div id="utilitiesPaletteContainer" style="display: none">
    <button
      id="utilitiesPaletteBtn"
      data-toggle="collapse"
      data-target="#utilitiesPalette"
      class="palette-category btn btn-link">
      Unit Operations
    </button>
    <div id="utilitiesPalette" class="palette-container palette collapse in level2UtilitiesPalette"></div>
  </div>

  <div id="fuelGasPaletteContainer" style="display: none">
    <button
      id="fuelGasPaletteBtn"
      data-toggle="collapse"
      data-target="#utilitiesPalette"
      class="palette-category btn btn-link">
      Unit Operations
    </button>
    <div id="fuelGasPalette" class="palette-container palette collapse in level2"></div>
  </div>

  <div id="extractionPaletteContainer" style="display: none">
    <button data-toggle="collapse" data-target="#extractionPalette" class="palette-category btn btn-link">
      Unit Operations
    </button>
    <div id="extractionPalette" class="palette-container palette collapse in level2"></div>
  </div>
</div>
