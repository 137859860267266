import { Component, Input } from '@angular/core';
import { FuelGasUtilityUnitOperation } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-utility-unit-operation';
import { FlowsheetService } from '../../../_services/flowsheet.service';

@Component({
  selector: 'sob-fuel-gas-utility-unit-operation',
  templateUrl: './fuel-gas-utility-unit-operation.component.html',
  styleUrls: ['./fuel-gas-utility-unit-operation.component.css'],
})
export class FuelGasUtilityUnitOperationComponent {
  @Input() unitOperation: FuelGasUtilityUnitOperation;

  constructor(private flowsheetService: FlowsheetService) {}

  openSubFlowsheet(): void {
    if ($('#closePropertyWindow').attr('disabled')) {
      return;
    }
    this.flowsheetService.prepareUnitOperationForSave();
    this.flowsheetService.closePropertyWindow();

    this.flowsheetService.loadUnitOperationFlowsheet(this.unitOperation.id);
  }
}
