<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#waterSourceImportComments" aria-controls="waterSourceImportComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="pressureCalculationMode" class="pw-label control-label col-sm-4">
            Pressure Calculation Mode
          </label>
          <div class="col-sm-6">
            <select class="form-control" formControlName="pressureCalculationMode" id="pressureCalculationMode">
              <option value="{{ PressureCalculationMode.LOWEST }}">Lowest</option>
              <option value="{{ PressureCalculationMode.OUTLET_PRESSURE }}">Outlet Pressure</option>
            </select>
          </div>
        </div>

        <ng-container *ngIf="isOutletPressureCalculationMode">
          <div class="form-group" formGroupName="outletPressure">
            <label class="control-label col-sm-4"> Outlet Pressure </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.outletPressure.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.outletPressure">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <hr />

        <h4>Information Streams</h4>

        <div class="form-group">
          <label for="steamType" class="control-label col-sm-4"> Steam Type </label>

          <div class="col-sm-8">
            <div class="row">
              <div class="col-xs-12">
                <select
                  formControlName="steamType"
                  name="steamType"
                  id="steamType"
                  class="form-control"
                  (change)="onSteamTypeChanged()">
                  <option value="{{ SteamType.Steam900 }}">{{ SteamType.Steam900 }}</option>
                  <option value="{{ SteamType.Steam600 }}">{{ SteamType.Steam600 }}</option>
                  <option value="{{ SteamType.Steam150 }}">{{ SteamType.Steam150 }}</option>
                  <option value="{{ SteamType.Steam50 }}">{{ SteamType.Steam50 }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-xs-12">
            <select
              name="selectedInformationStreamVariableId"
              id="selectedInformationStreamVariableId"
              formControlName="selectedInformationStreamVariableId"
              size="2"
              style="height: 200px"
              class="form-control">
              <option
                (click)="filterStreams()"
                *ngFor="let sv of availableInformationStreamVariables"
                value="{{ sv.id }}"
                (dblclick)="addInformationStream()">
                {{ sv.getFullName() }}
              </option>
            </select>

            <button class="btn btn-xs btn-success m-t-xs" (click)="addInformationStream()">
              <i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>

        <ng-container formArrayName="inletSourceInformationStreams">
          <ng-container *ngFor="let informationStreamGroup of getInformationStreamsFormGroupsArray(); let i = index">
            <div class="row">
              <h4>
                <a
                  data-toggle="collapse"
                  href="#informationStreamCollapse{{ i }}"
                  aria-expanded="false"
                  sfeCollapsePanelIcon>
                  <i class="fa fa-plus" aria-hidden="true"> </i>
                  {{ activeInformationStreams[i].getFullName() }}
                </a>
              </h4>
              <div>
                <span class="inline pull-right p-t-5-x" style="margin-top: -30px">
                  <button
                    class="btnR btn btn-xs btn-danger"
                    (click)="removeInformationStream(i)"
                    data-toggle="tooltip"
                    title="Remove Information Stream">
                    <i class="fa fa-minus-circle"></i>
                  </button>
                </span>
              </div>
              <div id="informationStreamCollapse{{ i }}" class="collapse">
                <sob-water-source-information-stream-form
                  [informationStream]="activeInformationStreams[i]"
                  [informationStreamFormGroup]="informationStreamGroup"
                  (removeClicked)="removeInformationStream(i)">
                </sob-water-source-information-stream-form>
              </div>
            </div>
            <hr />
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterSourceImportComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
