import { Mapper } from '../../../_models/_interfaces/mapper';
import { Pipe } from '../../../_models/_unit-operations/pipe';
import { SimulationVariable } from '../../../_models';
import { StringToSimulationVariableMapper } from './string-to-simulation-variable-mapper';

export enum PipeExcelIndexes {
  PIPE_LENGTH = 5,
  PRESSURE_DROP = 6,
  GHG_INTENSITY_PER_MASS_DISTANCE = 7,
  PIPE_DENSITY_SPEC = 8,
  CAPACITY = 9,
  OPEX_FIXED = 12,
  OPEX_VAR = 13,
}

export class ArrayToPipeMapper implements Mapper<string[], Pipe> {
  private readonly simulationVariableMapper: Mapper<string, SimulationVariable>;

  constructor() {
    this.simulationVariableMapper = new StringToSimulationVariableMapper();
  }

  map(source: string[], destination: Pipe) {
    this.simulationVariableMapper.map(source[PipeExcelIndexes.PIPE_LENGTH], destination.pipeLength);
    this.simulationVariableMapper.map(source[PipeExcelIndexes.PRESSURE_DROP], destination.pressureDrop);

    this.simulationVariableMapper.map(
      source[PipeExcelIndexes.GHG_INTENSITY_PER_MASS_DISTANCE],
      destination.ghgIntensityPerMassDistance
    );

    this.simulationVariableMapper.map(source[PipeExcelIndexes.PIPE_DENSITY_SPEC], destination.pipeDensitySpec);
    this.simulationVariableMapper.map(source[PipeExcelIndexes.CAPACITY], destination.capacity);
    this.simulationVariableMapper.map(source[PipeExcelIndexes.OPEX_FIXED], destination.opexFixed);
    this.simulationVariableMapper.map(source[PipeExcelIndexes.OPEX_VAR], destination.opexVar);
  }
}
