import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { FlowsheetComponent } from './flowsheet/flowsheet.component';

import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { ChangelogComponent } from './changelog/changelog.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LatestAccessComponent } from './latest-access/latest-access.component';
import { RoleAdminGuard } from './_guards/roleAdmin.guard';

const appRoutes: Routes = [
  // This will redirect to login.
  { path: '', component: FlowsheetComponent, canActivate: [AuthGuard] },
  { path: 'versionControl', component: ChangelogComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'login/:code', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'resetPassword', component: ForgotPasswordComponent },
  { path: 'latest-access', component: LatestAccessComponent, canActivate: [RoleAdminGuard] },
  // otherwise redirect to home, which will redirect to login here :/
  { path: '**', redirectTo: '' },
];

export const routing = RouterModule.forRoot(appRoutes, {});
