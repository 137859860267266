import { WaterMaterialStream } from '../_streams/water-material-stream';
import { GojsBaseStream } from './gojs-base-stream';
import { StreamCategory } from '../../_config/stream-category.enum';

declare let unitConverter: any;

export class GojsWaterMaterialStream extends GojsBaseStream {
  override category = StreamCategory.WATER_MATERIAL_STREAM;
  massFlowrate: string;
  massFlowrateUnit: string;

  temperature: string;
  temperatureUnit: string;

  pressure: string;
  pressureUnit: string;

  constructor(s: WaterMaterialStream) {
    super(s);

    this.massFlowrateUnit = unitConverter.units.Massflowrate.KPPH;
    this.massFlowrate = s.massFlowrate.convertToAnotherUnit(this.massFlowrateUnit, true);

    this.temperatureUnit = s.temperature.getDefaultUnit();
    this.temperature = s.temperature.convertToDefaultUnit(true, true);

    this.pressureUnit = s.pressure.getDefaultUnit();
    this.pressure = s.pressure.convertToDefaultUnit(true, true);

    this.dashedLine = !s.hasFlowrate();
  }
}
