import { BaseObject } from '../../base-object';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { WaterPipe } from './water-pipe';
import { KpiProvider } from '../../_case-study/kpi-provider';

declare let unitConverter: any;
export class WaterPipeSegment extends BaseObject implements KpiProvider {
  category = 'waterPipeSegment';
  segmentLength: SimulationVariable;
  insideDiameter: SimulationVariable;
  outsideDiameter: SimulationVariable;
  segmentAngle: SimulationVariable;
  relativeRoughness: SimulationVariable;
  overallU: SimulationVariable;
  inletAmbientTemperature: SimulationVariable;
  outletAmbientTemperature: SimulationVariable;

  constructor(waterPipeSegment: WaterPipeSegment | any, ownerCase: Case) {
    super(waterPipeSegment.id, ownerCase);
    this.initValues(waterPipeSegment);
    this.setSimulationVariableNames();
  }

  initValues(waterPipeSegment: WaterPipeSegment | any) {
    this.name = waterPipeSegment.name;
    this.segmentLength = new SimulationVariable(waterPipeSegment.segmentLength, this.ownerCase, this);
    this.insideDiameter = new SimulationVariable(waterPipeSegment.insideDiameter, this.ownerCase, this);
    this.outsideDiameter = new SimulationVariable(waterPipeSegment.outsideDiameter, this.ownerCase, this);
    this.segmentAngle = new SimulationVariable(waterPipeSegment.segmentAngle, this.ownerCase, this);
    this.relativeRoughness = new SimulationVariable(waterPipeSegment.relativeRoughness, this.ownerCase, this);
    this.overallU = new SimulationVariable(waterPipeSegment.overallU, this.ownerCase, this);
    this.inletAmbientTemperature = new SimulationVariable(
      waterPipeSegment.inletAmbientTemperature,
      this.ownerCase,
      this
    );
    this.outletAmbientTemperature = new SimulationVariable(
      waterPipeSegment.outletAmbientTemperature,
      this.ownerCase,
      this
    );

    this.setQuantities();
    this.setDefaultValues();
  }

  setSimulationVariableNames() {
    this.segmentLength.setName(SimulationVariableName.LENGTH);
    this.insideDiameter.setName(SimulationVariableName.INSIDE_DIAMETER);
    this.outsideDiameter.setName(SimulationVariableName.OUTSIDE_DIAMETER);
    this.segmentAngle.setName(SimulationVariableName.ANGLE);
    this.relativeRoughness.setName(SimulationVariableName.RELATIVE_ROUGHNESS);
  }

  setQuantities() {
    this.segmentLength.setQuantity(Quantity.LENGTH);
    this.insideDiameter.setQuantity(Quantity.LENGTH);
    this.outsideDiameter.setQuantity(Quantity.LENGTH);
    this.segmentAngle.setQuantity(Quantity.ANGLE);
    this.relativeRoughness.setQuantity(Quantity.DIMENSIONLESS);
    this.overallU.setQuantity(Quantity.U);
    this.inletAmbientTemperature.setQuantity(Quantity.TEMPERATURE);
    this.outletAmbientTemperature.setQuantity(Quantity.TEMPERATURE);
  }

  setDefaultValues() {
    if (this.segmentLength.isUndefined()) {
      this.segmentLength.setDefaultValue(null, unitConverter.units.Length.M);
    }
    if (this.insideDiameter.isUndefined()) {
      this.insideDiameter.setDefaultValue(null, unitConverter.units.Length.CM);
    }
    if (this.outsideDiameter.isUndefined()) {
      this.outsideDiameter.setDefaultValue(null, unitConverter.units.Length.CM);
    }
  }

  getAvailableKpis(): SimulationVariable[] {
    if (!this.ownerCase.multiPeriodEnabled) {
      return [this.segmentLength, this.insideDiameter, this.outsideDiameter, this.segmentAngle, this.relativeRoughness];
    }
    return [];
  }

  getWaterPipeSegmentOwner(wPipeSegmentId: string) {
    const waterPipes = this.ownerCase.filterUnitOperations(uo => uo instanceof WaterPipe) as WaterPipe[];
    let waterPipeOwner;
    waterPipes.forEach(wP => {
      wP.segments.forEach(wPSegments => {
        if (wPSegments.id === wPipeSegmentId) {
          waterPipeOwner = wP;
        }
      });
    });

    return waterPipeOwner;
  }

  override dePersist(persistedObj: any) {
    super.dePersist(persistedObj);
    this.segmentLength = this.getSimulationVariableSafe(persistedObj.segmentLength);
    this.insideDiameter = this.getSimulationVariableSafe(persistedObj.insideDiameter);
    this.outsideDiameter = this.getSimulationVariableSafe(persistedObj.outsideDiameter);
    this.segmentAngle = this.getSimulationVariableSafe(persistedObj.segmentAngle);
    this.relativeRoughness = this.getSimulationVariableSafe(persistedObj.relativeRoughness);
    this.overallU = this.getSimulationVariableSafe(persistedObj.overallU);
    this.inletAmbientTemperature = this.getSimulationVariableSafe(persistedObj.inletAmbientTemperature);
    this.outletAmbientTemperature = this.getSimulationVariableSafe(persistedObj.outletAmbientTemperature);

    this.setQuantities();
    this.setDefaultValues();
    this.setSimulationVariablesOwner();
    this.setSimulationVariableNames();
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      segmentLength: this.segmentLength.id,
      insideDiameter: this.insideDiameter.id,
      outsideDiameter: this.outsideDiameter.id,
      segmentAngle: this.segmentAngle.id,
      relativeRoughness: this.relativeRoughness.id,
      overallU: this.overallU.id,
      inletAmbientTemperature: this.inletAmbientTemperature.id,
      outletAmbientTemperature: this.outletAmbientTemperature.id,
    };
  }
}
