import { AfterViewInit, Component } from '@angular/core';
import { SankeyDiagramService } from './sankey-diagram.service';
import { SankeyDiagramTemplateProviderService } from './sankey-diagram-template-provider.service';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

@Component({
  selector: 'sob-sankey-diagram',
  templateUrl: './sankey-diagram.component.html',
  styleUrls: ['./sankey-diagram.component.css'],
})
export class SankeyDiagramComponent implements AfterViewInit {
  constructor(
    private sankeyDiagramService: SankeyDiagramService,
    private templateProvider: SankeyDiagramTemplateProviderService,
    private flowsheetService: FlowsheetService
  ) {}

  ngAfterViewInit(): void {
    this.initDiagram();
    this.flowsheetService.subFlowsheetOpenedRequest.subscribe(n => {
      this.changeLinkTemplate(n);
    });

    this.flowsheetService.subFlowsheetClosedRequest.subscribe(() => {
      this.changeLinkTemplate();
    });
  }

  initDiagram() {
    this.sankeyDiagramService.sankeyDiagram = this.templateProvider.getDiagramTemplate();
    this.sankeyDiagramService.sankeyDiagram.nodeTemplate = this.templateProvider.getNodeTemplate();
    this.sankeyDiagramService.sankeyDiagram.linkTemplate = this.templateProvider.getLinkTemplate();
    this.sankeyDiagramService.sankeyDiagram.layout = this.templateProvider.getSankeyLayout();
  }

  changeLinkTemplate(uoName?: string) {
    if (uoName) {
      if (uoName === unitOperationsConfig.waterUtilityUnitOperation.key) {
        this.sankeyDiagramService.sankeyDiagram.linkTemplate = this.templateProvider.getWaterLinkTemplate();
      } else if (uoName === unitOperationsConfig.fuelGasUtilityUnitOperation.key) {
        this.sankeyDiagramService.sankeyDiagram.linkTemplate = this.templateProvider.getFuelGasLinkTemplate();
      }
    } else {
      this.sankeyDiagramService.sankeyDiagram.linkTemplate = this.templateProvider.getLinkTemplate();
    }
  }
}
