import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { BaseObject } from '../../base-object';
import { BaseWaterSplitter } from './base-water-splitter';

export class WaterSplitter extends BaseWaterSplitter {
  category = unitOperationsConfig.waterSplitter.key;

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.setQuantities();
  }

  override clearResults() {
    super.clearResults();
  }

  override overwriteValues(another: BaseObject) {
    const ws = another as WaterSplitter;
    this.isOptimizable = ws.isOptimizable;

    this.name = another.name;
    for (let i = 0; i < ws.distributionRatioVariables.length; i++) {
      this.distributionRatioVariables[i].value = ws.distributionRatioVariables[i].value;
    }
  }

  setOptimizable(isOptimizable: boolean) {
    this.isOptimizable = isOptimizable;
  }
}
