import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../core.service';
import { FlowsheetDiagramService } from '../flowsheet-diagram/flowsheet-diagram.service';
import { LogManagerService } from '../../flowsheet/log-manager/log-manager.service';
import { MultiPeriodEnabledOptions } from '../../_models/_multi-period/multi-period-enabled-options';

@Injectable({
  providedIn: 'root',
})
export class MultiPeriodService {
  private multiPeriodModeEnabledSubject = new Subject<MultiPeriodEnabledOptions>();
  multiPeriodModeEnabledRequest = this.multiPeriodModeEnabledSubject.asObservable();

  private multiPeriodSettingsCompletedSubject = new Subject();
  multiPeriodSettingsCompletedRequest = this.multiPeriodSettingsCompletedSubject.asObservable();

  constructor(
    private core: CoreService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private toastr: ToastrService,
    private logManager: LogManagerService
  ) {}

  enableMultiPeriod(options: MultiPeriodEnabledOptions) {
    if (options.showNotification) {
      this.toastr.success('Multi Period mode enabled!', '', { timeOut: 1500 });
      this.logManager.info('Multi Period mode enabled!');
    }

    options.multiPeriodEnabled = true;
    this.currentCase.multiPeriodEnabled = true;
    this.flowsheetDiagramService.setMultiPeriodStyle();
    this.flowsheetDiagramService.setDiagramAsReadOnly();
    this.notifyMultiPeriodEnabled(options);
  }

  disableMultiPeriod(options: MultiPeriodEnabledOptions) {
    if (options.showNotification) {
      this.toastr.warning('Multi Period mode disabled!');
      this.logManager.info('Multi Period mode disabled!');
    }

    this.currentCase.multiPeriodEnabled = false;
    options.multiPeriodEnabled = false;
    this.flowsheetDiagramService.setNormalStyle();
    this.notifyMultiPeriodEnabled(options);
    this.flowsheetDiagramService.setDiagramAsEditable();
  }

  private notifyMultiPeriodEnabled(options: MultiPeriodEnabledOptions) {
    this.multiPeriodModeEnabledSubject.next(options);
  }

  notifyMultiPeriodSettingsCompleted() {
    this.multiPeriodSettingsCompletedSubject.next();
  }

  private get currentCase() {
    return this.core.currentCase;
  }
}
