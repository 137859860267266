import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjcSheet from '@grapecity/wijmo.grid.sheet';

import { CollectionView } from '@grapecity/wijmo';
import { CoreService } from '../core.service';
import { FlexSheetColumn } from '../../_models/wijmo/flex-sheet-column';
import { Case, UnitOperation } from '../../_models';
import { BaseConstraintsSpreadsheetModel } from '../../_models/constraints-spreadsheet/base-constraints-spreadsheet-model';
import { UnitOperationConstraints } from '../../_models/_unit-operations/unit-operation-constraints';
import { ConstraintReportVariable } from '../../_models/_reports/_optimization-settings-table/constraint-report-variable';
import { FlowsheetTreeService } from '../sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { MixingConstraints } from '../../_models/_unit-operations/mixing-constraints';
import { Mixer } from '../../_models/_unit-operations/mixer';
import { SuncorConstraint } from '../../_models/_unit-operations/suncor-constraint';
import { RangeDistributionConstraint } from '../../_models/_unit-operations/range-distribution-constraint';
import { MixingConstraintReportVariable } from '../../_models/_reports/_optimization-settings-table/mixing-constraint-report-variable';
import { MixerBlendRecipeOption } from '../../_config/unit-operations/mixer-enums';
import { Splitter } from '../../_models/_unit-operations/splitter';
import { RangeDistributionRatioVariable } from '../../_models/_unit-operations/range-distribution-ratio-variable';
import { BaseConstraintReportVariable } from '../../_models/_reports/_optimization-settings-table/base-constraint-report-variable';

export abstract class AbstractConstraintsHandler {
  protected items: BaseConstraintsSpreadsheetModel[];
  protected grid: wjGrid.FlexGrid;
  protected readonly DEFAULT_NUMBER_OF_ROWS = 90;
  protected sheetName: string;

  protected abstract readonly SHEET_INDEX: number;

  // constraints: Array<ConstraintReportVariable> = [];
  flowsheetTree = this.flowsheetTreeService.getFlowsheetTree(this.coreService.currentCase);

  protected constructor(protected coreService: CoreService, private flowsheetTreeService: FlowsheetTreeService) {}

  protected abstract isUnitOperationAllowed(unitOperation: UnitOperation): boolean;

  abstract clearResults(): void;

  protected abstract getColumnsConfiguration(): FlexSheetColumn[];

  protected toSpreadsheetItem(
    constraint: BaseConstraintReportVariable,
    flowsheetOwner?: string
  ): BaseConstraintsSpreadsheetModel {
    const constraintSpreadsheetModel = {
      isActive: constraint.isActive,
      unitOperationId: constraint.unitOperationId,
      unitOperationName: constraint.unitOperationName,
      constraint: constraint.name,
      constraintValue: constraint.constraintValue,
      currentValue: constraint.currentValue,
      flowsheetOwner,
    } as BaseConstraintsSpreadsheetModel;

    if (constraint instanceof ConstraintReportVariable) {
      constraintSpreadsheetModel.id = constraint.simVarId;
      constraintSpreadsheetModel.unit = constraint.currentUnit;
    } else {
      const mixingConstraintReportVar = constraint as MixingConstraintReportVariable;
      constraintSpreadsheetModel.id = mixingConstraintReportVar.id;
      constraintSpreadsheetModel.unit = mixingConstraintReportVar.constraintUnit;
    }
    return constraintSpreadsheetModel;
  }

  init(spreadsheet: wjcSheet.FlexSheet, sheetName: string) {
    this.grid = new wjGrid.FlexGrid(document.createElement('div'), {
      autoGenerateColumns: false,
      columns: this.getColumnsConfiguration(),
      frozenRows: 1,
      frozenColumns: 1,
    });

    this.items = [];
    this.clearSpreadsheetItems();

    const data = this.createCollectionView();

    spreadsheet.addBoundSheet(sheetName, data, this.SHEET_INDEX, this.grid);
    this.sheetName = sheetName;
  }

  protected createCollectionView() {
    return new CollectionView(this.items);
  }

  caseToSpreadsheet(c: Case): void {
    const definedConstraints: Array<BaseConstraintReportVariable> = [];
    this.clearSpreadsheetItems();

    let flowsheetOwner = '';

    for (const id in c.unitOperationPool) {
      const uo = c.unitOperationPool[id] as UnitOperation;
      if (this.isUnitOperationAllowed(uo)) {
        if ((uo as any).constraints) {
          const { constraints } = (uo as any).constraints as UnitOperationConstraints | MixingConstraints;
          for (const id in constraints) {
            const constraint = constraints[id];
            if (constraint.constraintValueDefined()) {
              this.addConstraintReportVariableToArray(definedConstraints, constraint, uo);
            }
          }
        }
      }
    }

    for (const cKey in definedConstraints) {
      flowsheetOwner = this.assignFlowsheetOwner(definedConstraints[cKey].unitOperationId);
      this.addConstraintWithoutRefreshing(definedConstraints[cKey], flowsheetOwner);
      flowsheetOwner = '';
    }

    this.grid.collectionView.refresh();
  }

  handleEditEvent(spreadsheet: wjcSheet.FlexSheet, flexSheetEvent: any) {
    if (spreadsheet.selectedSheet.name !== this.sheetName) {
      return;
    }

    const item = this.items[flexSheetEvent.row - 1];
    if (!item.id) {
      flexSheetEvent.cancel = true;
      return;
    }

    if (spreadsheet.activeEditor) {
      if (spreadsheet.activeEditor.value === 'on') {
        this.handleEventActiveChange(item, spreadsheet.activeEditor.checked);
      }
      this.handleEvent(spreadsheet.activeEditor.value, item, flexSheetEvent);
    }
  }

  handleDeleteEvent(spreadsheet: wjcSheet.FlexSheet, flexSheetEvent: any) {
    if (spreadsheet.selectedSheet.name !== this.sheetName) {
      return;
    }

    const item = this.items[flexSheetEvent.row - 1];
    if (!item.id) {
      flexSheetEvent.cancel = true;
      return;
    }

    this.handleEvent('', item, flexSheetEvent);
  }

  handleEventActiveChange(item: BaseConstraintsSpreadsheetModel, checked: boolean) {
    const simVar = this.coreService.currentCase.getSimulationVariable(item.id);
    item.isActive = checked;

    if (simVar) {
      simVar.setIsActive(checked);
    } else {
      const unitOperationOwner = this.coreService.currentCase.getUnitOperation(item.unitOperationId);
      if (unitOperationOwner) {
        if (this.isMixingConstraintsUser(unitOperationOwner)) {
          unitOperationOwner.constraints.constraints[item.id].isActive = checked;
        }
      }
    }

    this.grid.collectionView.refresh();
  }

  handlePasteEvent(spreadsheet: wjcSheet.FlexSheet, flexSheetEvent: any): void {
    if (spreadsheet.selectedSheet.name !== this.sheetName) {
      return;
    }

    const item = this.items[flexSheetEvent.row - 1];
    if (!item.id) {
      flexSheetEvent.cancel = true;
      return;
    }

    if (!flexSheetEvent.data) {
      flexSheetEvent.cancel = true;
      return;
    }

    this.handleEvent(flexSheetEvent.data, item, flexSheetEvent);
  }

  protected handleEvent(rawValue, item: BaseConstraintsSpreadsheetModel, flexSheetEvent?: any) {
    let uo;
    if (rawValue !== '') {
      const valueAsNumber = +rawValue;

      if (Number.isNaN(valueAsNumber)) {
        flexSheetEvent.cancel = true;
        return;
      }

      uo = this.assignValueToUnitOperation(valueAsNumber, item);
    } else {
      uo = this.deleteUnitOperationValue(item);
      flexSheetEvent.cancel = true;
    }

    this.coreService.notifyUnitOperationUpdated(uo, true);
  }

  abstract assignValueToUnitOperation(
    valueAsNumber: number,
    item: BaseConstraintsSpreadsheetModel
  ): SuncorUnitOperation;

  abstract deleteUnitOperationValue(item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation;

  protected clearSpreadsheetItems() {
    this.items.splice(this.DEFAULT_NUMBER_OF_ROWS, this.items.length - this.DEFAULT_NUMBER_OF_ROWS);
    for (let i = 0; i < this.DEFAULT_NUMBER_OF_ROWS; i++) {
      this.items[i] = {};
    }
  }

  addUnitOperation(unitOperation: UnitOperation): void {
    if (this.isUnitOperationAllowed(unitOperation)) {
      this.addConstraintWithoutRefreshing(unitOperation);
      this.grid.collectionView.refresh();
    }
  }

  protected addConstraintWithoutRefreshing(constraint: ConstraintReportVariable | any, flowsheetOwner?: string) {
    const index = this.items.findIndex(u => !u.unitOperationName);

    const newItem = this.toSpreadsheetItem(constraint, flowsheetOwner);

    if (index > -1 && index <= this.DEFAULT_NUMBER_OF_ROWS - 1) {
      this.items[index] = newItem;
    } else {
      this.items.push(newItem);
    }
  }

  updateConstraintValue(unitOperation: UnitOperation): void {
    const noDefinedConstraints: Array<BaseConstraintReportVariable> = [];
    const definedConstraints: Array<BaseConstraintReportVariable> = [];
    let flowsheetOwner = '';

    if (this.isUnitOperationAllowed(unitOperation)) {
      if ((unitOperation as any).constraints) {
        const { constraints } = (unitOperation as any).constraints as UnitOperationConstraints | MixingConstraints;
        for (const id in constraints) {
          const constraint = constraints[id];

          if (constraint.constraintValueDefined()) {
            this.addConstraintReportVariableToArray(definedConstraints, constraint, unitOperation);
          }

          if (!constraint.constraintValueDefined()) {
            this.addConstraintReportVariableToArray(noDefinedConstraints, constraint, unitOperation);
          }
        }
      }
    }

    if (definedConstraints.length === 0 && noDefinedConstraints.length === 0) {
      this.removeAllConstraintItemsByUnitOperationId(unitOperation.id);
    }

    for (const cKey in definedConstraints) {
      let constraintId: string;
      const definedConstraint = definedConstraints[cKey];
      if (definedConstraint instanceof ConstraintReportVariable) {
        constraintId = definedConstraint.simVarId;
      } else {
        constraintId = (definedConstraint as MixingConstraintReportVariable).id;
      }

      flowsheetOwner = this.assignFlowsheetOwner(definedConstraint.unitOperationId);
      const index = this.items.findIndex(i => i.id === constraintId);
      if (index < 0) {
        if (unitOperation instanceof Mixer) {
          if (unitOperation.blendRecipeOption === MixerBlendRecipeOption.EXACT) {
            this.removeRangeConstraints(unitOperation.id);
          } else {
            this.removeExactConstraints(unitOperation.id);
          }
        } else if (unitOperation instanceof Splitter) {
          if (unitOperation.isOptimizable) {
            this.removeExactConstraints(unitOperation.id);
          } else {
            this.removeRangeConstraints(unitOperation.id);
          }
        }
        this.addConstraintWithoutRefreshing(definedConstraint, flowsheetOwner);
      }
      this.items[index] = this.toSpreadsheetItem(definedConstraint, flowsheetOwner);
      flowsheetOwner = '';
    }

    for (const ck in noDefinedConstraints) {
      let constraintId: string;
      const noDefinedConstraint = noDefinedConstraints[ck];
      if (noDefinedConstraint instanceof ConstraintReportVariable) {
        constraintId = (noDefinedConstraint as ConstraintReportVariable).simVarId;
      } else {
        constraintId = (noDefinedConstraint as MixingConstraintReportVariable).id;
      }

      const index = this.items.findIndex(i => i.id === constraintId);
      this.items.splice(index, 1);
    }

    if (this.isMixingConstraintsUser(unitOperation) && definedConstraints.length > 0) {
      this.removeMixingConstraintsWhenStreamDeleted(definedConstraints as MixingConstraintReportVariable[]);
    }

    this.grid.collectionView.refresh();
  }

  removeUnitOperation(unitOperation: UnitOperation): void {
    const noDefinedConstraints: Array<BaseConstraintReportVariable> = [];
    if (this.isUnitOperationAllowed(unitOperation)) {
      if ((unitOperation as any).constraints) {
        const { constraints } = (unitOperation as any).constraints as UnitOperationConstraints | MixingConstraints;
        for (const id in constraints) {
          const constraint = constraints[id];
          if (constraint.constraintValueDefined()) {
            this.addConstraintReportVariableToArray(noDefinedConstraints, constraint, unitOperation);
          }
        }
      }

      for (const cKey in noDefinedConstraints) {
        let constraintId: string;
        const noDefinedConstraint = noDefinedConstraints[cKey];
        if (noDefinedConstraint instanceof ConstraintReportVariable) {
          constraintId = (noDefinedConstraint as ConstraintReportVariable).simVarId;
        } else {
          constraintId = (noDefinedConstraint as MixingConstraintReportVariable).id;
        }

        const index = this.items.findIndex(i => i.id === constraintId);
        this.items.splice(index, 1);
      }

      this.removeMixingConstraintItemsWhenUnitOperationDeleted(unitOperation.id);

      // keep at least 40 rows/items
      if (this.items.length < this.DEFAULT_NUMBER_OF_ROWS) {
        this.items.push({});
      }

      this.grid.collectionView.refresh();
    }
  }

  getUnitOperationFlowsheetId(id: string) {
    let flowshetId = '';
    flowshetId = this.coreService.currentCase.getUnitOperation(id).flowsheetId;
    return flowshetId;
  }

  assignFlowsheetOwner(id: string) {
    const flowsheetOwners = this.flowsheetTreeService.getAllFlowsheetOwners(this.coreService.currentCase);
    let flowsheetOwner = '';
    flowsheetOwners.forEach(uo => {
      if (this.getUnitOperationFlowsheetId(id) === '') {
        flowsheetOwner = this.flowsheetTree.root.name;
      } else if (this.getUnitOperationFlowsheetId(id) === uo.id) {
        flowsheetOwner = uo.name;
      }
    });
    return flowsheetOwner;
  }

  isMixingConstraintsUser(unitOperation: UnitOperation): unitOperation is Mixer | Splitter {
    return unitOperation instanceof Mixer || unitOperation instanceof Splitter;
  }

  removeExactConstraints(unitOperationId: string) {
    const constraintModels = this.items.filter(cstr => cstr.unitOperationId === unitOperationId);
    const exactConstraintModels = constraintModels.filter(exactConstraint =>
      exactConstraint.constraint.includes('Exact')
    );

    for (let i = 0; i < exactConstraintModels.length; i++) {
      const constraintIdx = this.items.findIndex(x => x.id === exactConstraintModels[i].id);
      this.items.splice(constraintIdx, 1);
    }
  }

  removeRangeConstraints(unitOperationId: string) {
    const constraintModels = this.items.filter(cstr => cstr.unitOperationId === unitOperationId);
    const rangeConstraintModels = constraintModels.filter(
      rangeConstraint =>
        rangeConstraint.constraint.includes('Minimum') || rangeConstraint.constraint.includes('Maximum')
    );

    for (let i = 0; i < rangeConstraintModels.length; i++) {
      const constraintIdx = this.items.findIndex(x => x.id === rangeConstraintModels[i].id);
      this.items.splice(constraintIdx, 1);
    }
  }

  removeAllConstraintItemsByUnitOperationId(unitOperationId: string) {
    const constraintModels = this.items.filter(cstr => cstr.unitOperationId === unitOperationId);

    for (let i = 0; i < constraintModels.length; i++) {
      const constraintIdx = this.items.findIndex(x => x.id === constraintModels[i].id);
      this.items.splice(constraintIdx, 1);
    }
  }

  assignNewValuesForRangeConstraints(
    unitOperation: Mixer | Splitter,
    item: BaseConstraintsSpreadsheetModel,
    value: number
  ) {
    const { constraints } = unitOperation.constraints;
    const unitOpConstraint = constraints[item.id];
    let ratioVariable: RangeDistributionRatioVariable;

    if (unitOperation instanceof Mixer) {
      ratioVariable = unitOperation.findMixingRatioVariable(unitOpConstraint.sourceUnitOperationId);
    } else if (unitOperation instanceof Splitter) {
      ratioVariable = unitOperation.findDistributionRatioVariable(unitOpConstraint.sourceUnitOperationId);
    }
    unitOpConstraint.constraintValue = value;

    if (item.constraint.includes('Minimum')) {
      ratioVariable.minimumValue = value;
    } else if (item.constraint.includes('Maximum')) {
      ratioVariable.maximumValue = value;
    } else {
      ratioVariable.value = value;
    }
  }

  addConstraintReportVariableToArray(
    constraintArray: Array<BaseConstraintReportVariable>,
    constraint: SuncorConstraint | RangeDistributionConstraint,
    unitOperation: UnitOperation
  ) {
    if (constraint instanceof SuncorConstraint) {
      constraintArray.push(ConstraintReportVariable.create(unitOperation, constraint.constraint));
    } else {
      constraintArray.push(MixingConstraintReportVariable.create(unitOperation, constraint));
    }
  }

  removeMixingConstraintItemsWhenUnitOperationDeleted(uoId: string) {
    const streamsByInletUnitOperation = this.coreService.currentCase.getStreamsByInletUnitOperationId(uoId);
    const streamsByOutletUnitOperation = this.coreService.currentCase.getStreamsByOutletUnitOperationId(uoId);

    streamsByOutletUnitOperation.forEach(s => {
      if (s.inletUnitOperation instanceof Splitter) {
        this.removeListOfMixingConstraintsFromItemsList(uoId, s.inletUnitOperation.constraints.constraints);
      }
    });

    streamsByInletUnitOperation.forEach(s => {
      if (s.outletUnitOperation instanceof Mixer) {
        this.removeListOfMixingConstraintsFromItemsList(uoId, s.outletUnitOperation.constraints.constraints);
      }
    });
  }

  removeMixingConstraintsWhenStreamDeleted(definedConstraints: Array<MixingConstraintReportVariable>) {
    const constraintsToDelete: BaseConstraintsSpreadsheetModel[] = [];
    this.items.forEach(item => {
      if (Object.keys(item).length > 0 && item.unitOperationId === definedConstraints[0].unitOperationId) {
        const constraintReportVar = definedConstraints.find(
          cstr => cstr.name === item.constraint && cstr.unitOperationId === item.unitOperationId
        );

        if (!constraintReportVar) {
          constraintsToDelete.push(item);
        }
      }
    });

    constraintsToDelete.forEach(cstr => {
      const constraintItemIdx = this.items.findIndex(i => i.id === cstr.id);
      this.items.splice(constraintItemIdx, 1);
    });
  }

  removeListOfMixingConstraintsFromItemsList(
    unitOperationId: string,
    constraintList: { [p: string]: RangeDistributionConstraint }
  ) {
    const constraintsToDelete = Object.values(constraintList).filter(c => c.sourceUnitOperationId === unitOperationId);

    constraintsToDelete.forEach(constraint => {
      const constraintItemIdx = this.items.findIndex(i => i.id === constraint.id);
      this.items.splice(constraintItemIdx, 1);
    });
  }
}
