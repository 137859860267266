import { CaseResultsBackup } from './case-results-backup';
import { Case } from './case';
import { UncertaintyAnalysis } from './_case-study/uncertainty-analysis';
import { ParametricStudy } from './_case-study/parametric-study';

export class ApiObjectsBackupManager {
  /**
   * Cleans the results of a case and saves them into a backup object
   * a backup object containing the results and other stuff that is not needed when solving a case.
   * @returns
   * @param c
   */
  static cleanAndCreateBackupObject(c: Case): CaseResultsBackup {
    // remove the calculator
    const { calculator } = c;
    c.removeFromPools(calculator);

    return {
      ownerCase: c,
      calculator,
    };
  }

  static restoreBackupData(backupObject: CaseResultsBackup) {
    const c = backupObject.ownerCase;

    c.calculator = backupObject.calculator;
    c.addToPools(c.calculator);
    c.calculator.addSimVarsToPool();
  }

  static restoreRemovedAnalyses(removedAnalyses: Array<UncertaintyAnalysis>) {
    // TODO: Should the case be passed as parameter?
    for (const ua of removedAnalyses) {
      ua.ownerCase.caseStudyManager.uncertaintyAnalyses.push(ua);
      ua.ownerCase.addToPools(ua);
      ua.addParametersToPool();
    }
  }

  static removeOtherUncertaintyAnalyses(analysisToKeep: UncertaintyAnalysis): Array<UncertaintyAnalysis> {
    const analysesToRemove = analysisToKeep.ownerCase.caseStudyManager.uncertaintyAnalyses.filter(
      (ua: UncertaintyAnalysis) => {
        return ua.id !== analysisToKeep.id;
      }
    );

    for (const ua of analysesToRemove) {
      analysisToKeep.ownerCase.caseStudyManager.uncertaintyAnalyses.splice(
        analysisToKeep.ownerCase.caseStudyManager.uncertaintyAnalyses.indexOf(ua),
        1
      );

      analysisToKeep.ownerCase.removeFromPools(ua);

      for (const p of ua.uncertainParameters) {
        analysisToKeep.ownerCase.removeFromPools(p);
      }
    }

    return analysesToRemove;
  }

  static removeParametricStudies(c: Case, studyToKeep?: ParametricStudy) {
    const csm = c.caseStudyManager;

    const studiesToRemove = csm.parametricStudies.filter(ps => {
      return studyToKeep ? studyToKeep.id !== ps.id : true;
    });

    for (const ps of studiesToRemove) {
      csm.parametricStudies.splice(csm.parametricStudies.indexOf(ps), 1);
      const p = ps.parameter;
      c.removeFromPools(ps);
      c.removeFromPools(p);
      ps.parameter = p; // wat, seems removal from pools deletes the parameter property from owner study...
    }

    return studiesToRemove;
  }

  static restoreRemovedStudies(c: Case, removedStudies: ParametricStudy[]) {
    for (const ps of removedStudies) {
      ps.ownerCase = c; // is this needed? maybe... after de serializing a case coming from the API
      c.addToPools(ps);
      c.caseStudyManager.parametricStudies.push(ps);

      // and the parameter
      if (ps.parameter) {
        c.addToPools(ps.parameter);
      }
    }
  }
}
