import { ReportValue } from './report-value';

export class ReportDataSet {
  id: string;
  name: string;
  data: ReportValue[] = [];
  total: number;

  constructor(name: string, id: string) {
    this.id = id;
    this.name = name;
    this.total = 0;
  }

  addToData(rv: ReportValue) {
    this.data.push(rv);
    this.total += rv.value;
  }
}
