import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { FuelGasUnitOperation } from './fuel-gas-unit-operation';
import { SimulationVariable } from '../../simulation-variable';
import { Quantity } from '../../../_config/quantity.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

export class FuelGasAmineContactor extends FuelGasUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.fuelGasAmineContactor.key;
  h2SRemovalFraction: SimulationVariable;

  constructor(unitOperation: any | FuelGasAmineContactor, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.h2SRemovalFraction = new SimulationVariable(unitOperation.h2SRemovalFraction || {}, this.ownerCase, this);
    this.setQuantities();
  }

  override setSimulationVariableNames() {
    this.h2SRemovalFraction.setName(SimulationVariableName.H2S_REMOVAL);
  }

  override setQuantities() {
    super.setQuantities();
    this.h2SRemovalFraction.setQuantity(Quantity.PERCENT);
  }

  getAvailableKpis(): SimulationVariable[] {
    return [this.h2SRemovalFraction];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.h2SRemovalFraction];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.h2SRemovalFraction = this.getSimulationVariableSafe(sup.h2SRemovalFraction);
    this.setQuantities();
  }
}
