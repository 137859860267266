import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SubSink } from 'subsink';
import { AbstractFormGroupPropertyWindow } from '../abstract-form-group-property-window';
import { CoreService } from '../../../_services/core.service';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { Splitter } from '../../../_models/_unit-operations/splitter';
import {
  RangeDistributionRatioVariableForm,
  RangeDistributionRatioVariableFormBuilder,
} from '../../../_form-utils/range-distribution-ratio-variable-form-builder';

@Component({
  selector: 'sob-splitter',
  templateUrl: './splitter.component.html',
  styleUrls: ['./splitter.component.css'],
})
export class SplitterComponent extends AbstractFormGroupPropertyWindow implements OnInit, OnDestroy {
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;
  @Input() unitOperation: Splitter;

  protected formGroup = new FormGroup({
    name: new FormControl(''),
    isOptimizable: new FormControl(true),
    distributionRatioVariables: new FormArray<RangeDistributionRatioVariableForm>([]),
  });

  protected rangeDistributionVariablesFormGroup = new FormGroup({
    ratioVariables: new FormArray<RangeDistributionRatioVariableForm>([]),
  });

  private subSink = new SubSink();

  constructor(
    private coreService: CoreService,
    private rangeRatioVariablesFormBuilder: RangeDistributionRatioVariableFormBuilder
  ) {
    super();

    this.subSink.add(
      this.coreService.unitOperationRemovedRequest.subscribe(uo => {
        this.removeDistributionRatioVariable(uo.id);
      })
    );

    this.subSink.add(
      this.coreService.streamRemovedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.removeDistributionRatioVariable(s.outletUnitOperationId);
        }
      })
    );

    this.subSink.add(
      this.coreService.streamAddedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.addDistributionRatioVariable(s.outletUnitOperationId);
        }
      })
    );
  }

  ngOnInit(): void {
    this.init();
  }

  addControls() {
    this.formGroup.controls.name.patchValue(this.unitOperation.name);
    this.formGroup.controls.isOptimizable.patchValue(this.unitOperation.isOptimizable);

    const rangeDistributionVariablesFormArray = this.rangeRatioVariablesFormBuilder.formArray(
      this.unitOperation.distributionRatioVariables
    );

    this.formGroup.controls.distributionRatioVariables = rangeDistributionVariablesFormArray;
    this.rangeDistributionVariablesFormGroup.controls.ratioVariables = rangeDistributionVariablesFormArray;

    // copy everything to the main form... how good is this?
    for (const key of Object.keys(this.formGroup.controls)) {
      this.propertyWindowFormGroup.addControl(key, this.formGroup.controls[key]);
    }
  }

  // region adding/removing distribution ratio variables when property window is open
  private addDistributionRatioVariable(outletUnitOperationId: string) {
    this.unitOperation.addOutletDistributionRatioVariables(outletUnitOperationId);

    const ratioVariable = this.unitOperation.findDistributionRatioVariable(outletUnitOperationId);

    const ratioVariableControl = this.rangeRatioVariablesFormBuilder.control(ratioVariable);
    this.rangeDistributionVariablesFormGroup.controls.ratioVariables.push(ratioVariableControl);
  }

  /**
   * Removes a distribution ratio variable
   * Makes sense when a stream is deleted
   * @param unitOperationId
   * @private
   */
  private removeDistributionRatioVariable(unitOperationId: string) {
    const formGroupToRemove = this.rangeDistributionVariablesFormGroup.controls.ratioVariables.controls.find(value => {
      return value.getFormGroupId() === unitOperationId;
    });

    if (!formGroupToRemove) {
      return;
    }

    const formGroupIndex =
      this.rangeDistributionVariablesFormGroup.controls.ratioVariables.controls.indexOf(formGroupToRemove);
    this.rangeDistributionVariablesFormGroup.controls.ratioVariables.removeAt(formGroupIndex);

    // TODO remove this when unit operation copies are no longer needed
    this.unitOperation.removeOutletDistributionRatioVariables(unitOperationId);
  }
  // endregion

  ratioVariableValueControlsReadonly() {
    return this.formGroup.controls.isOptimizable.value;
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
