import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LogManagerService {
  private logMessageSubject = new Subject();
  logMessageRequest = this.logMessageSubject.asObservable();

  private logSerializedMessagesSubject = new Subject();
  logSerializedMessagesRequest = this.logSerializedMessagesSubject.asObservable();

  private clearLogCounterSubject = new Subject();
  clearLogCounterRequest = this.clearLogCounterSubject.asObservable();

  private clearLogManagerSubject = new Subject();
  clearLogManagerRequest = this.clearLogManagerSubject.asObservable();

  info(message: string): void {
    this.logMessageSubject.next({ message, severity: 'INFO', timestamp: new Date() });
  }

  warning(message: string): void {
    this.logMessageSubject.next({ message, severity: 'WARNING', timestamp: new Date() });
  }

  error(message: string): void {
    this.logMessageSubject.next({ message, severity: 'ERROR', timestamp: new Date() });
  }

  logSerializedMessages(serializedMessages: Array<any>) {
    this.logSerializedMessagesSubject.next(serializedMessages);
  }

  clearLogCounter() {
    this.clearLogCounterSubject.next();
  }

  clearLogManager() {
    this.clearLogManagerSubject.next();
  }
}
