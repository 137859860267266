import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CoreService } from '../../../_services/core.service';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { FuelGasSinkImport } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-sink-import';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { GasContributorUnitOperation } from '../../../_models/_unit-operations/gas-contributor-unit-operation';
import { EnergyStream } from '../../../_models/_unit-operations/fuel-gas/energy-stream';
import { Case, UnitOperation } from '../../../_models';
import { WaterBoiler } from '../../../_models/_unit-operations/utilties/water-boiler';
import { WaterUtilityUnitOperation } from '../../../_models/_unit-operations/utilties/water-utility-unit-operation';
import { WaterCogeneration } from '../../../_models/_unit-operations/utilties/water-cogeneration';

@Component({
  selector: 'sob-fuel-gas-sink-import',
  templateUrl: './fuel-gas-sink-import.component.html',
  styleUrls: ['./fuel-gas-sink-import.component.css'],
})
export class FuelGasSinkImportComponent implements OnInit {
  @Input() unitOperation: FuelGasSinkImport;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;
  availableProviderUnitOperations: UnitOperation[];

  // the energy streams active in the current property window
  activeEnergyStreams: EnergyStream[] = [];

  constructor(private coreService: CoreService, private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit(): void {
    this.activeEnergyStreams.push(...this.unitOperation.energyStreams);
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();

    // hmm
    this.filterStreams();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('heatFlow', this.svfb.control(this.unitOperation.heatFlow, true));

    // TODO nice hack to allow change detection in name... should not be used REMOVE when not needed
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('selectedProviderUnitOperationId', this.fb.control(''));
    this.propertyWindowFormGroup.addControl('energyStreams', this.fb.array([]));

    for (const energyStream of this.activeEnergyStreams) {
      this.addEnergyStreamControls(energyStream);
    }
  }

  private getOtherEnergyStreamUsers(): FuelGasSinkImport[] {
    return this.currentCase.filterUnitOperations(
      uo => uo.category === unitOperationsConfig.fuelGasSinkImport.key && uo.id !== this.unitOperation.id
    ) as FuelGasSinkImport[];
  }

  private usesEnergyStream(energyStreamUser: FuelGasSinkImport, providerUnitOperationId: string): boolean {
    return !!energyStreamUser.energyStreams.find(es => es.providerUnitOperationId === providerUnitOperationId);
  }

  getAvailableEnergyStreamProviders(): UnitOperation[] {
    const allowedUnitOperations = this.getParentFlowsheetAllowedUnitOperations();
    const result = [];
    for (const gasContributor of allowedUnitOperations) {
      // don't even show it if it is already in use
      if (this.isEnergyStreamInUseByOtherUnitOperation(gasContributor.id)) {
        continue;
      }

      result.push(gasContributor);
    }

    return result;
  }

  private getParentFlowsheetAllowedUnitOperations(): UnitOperation[] {
    const parentUnit = this.currentCase.getUnitOperation(this.unitOperation.flowsheetId);
    const parentFlowsheetId = parentUnit.flowsheetId;

    const waterUtilityUnitOperationList = this.coreService.currentCase.filterUnitOperations(
      uo => uo.flowsheetId === parentFlowsheetId && uo instanceof WaterUtilityUnitOperation
    );

    const waterUtilityUnitOperation = waterUtilityUnitOperationList[0];

    return this.coreService.currentCase.filterUnitOperations(uo => {
      return (
        (uo.flowsheetId === parentFlowsheetId && uo instanceof GasContributorUnitOperation) ||
        (waterUtilityUnitOperation &&
          uo.flowsheetId === waterUtilityUnitOperation.id &&
          (uo instanceof WaterBoiler || uo instanceof WaterCogeneration))
      );
    });
  }

  addEnergyStreamControls(energyStream: EnergyStream) {
    const energyStreamStreamGroup = this.fb.group({
      id: this.fb.control(energyStream.id), // not visible at all
      providerUnitOperationId: this.fb.control(energyStream.providerUnitOperationId), // not visible
      energyFlow: this.svfb.control(energyStream.energyFlow, true),
    });

    this.energyStreamsFormArray.push(energyStreamStreamGroup);
  }

  private isEnergyStreamInUseByOtherUnitOperation(providerUnitOperationId: string) {
    const otherEnergyStreamUsers = this.getOtherEnergyStreamUsers();

    for (const energyStreamUser of otherEnergyStreamUsers) {
      if (this.usesEnergyStream(energyStreamUser, providerUnitOperationId)) {
        return true;
      }
    }

    return false;
  }

  addEnergyStream(): void {
    if (this.isSelectedEnergyStreamActive()) {
      return;
    }

    const unitOp = this.availableProviderUnitOperations.find(uo => uo.id === this.selectedProviderUnitOperationId);
    const unitOpIndex = this.availableProviderUnitOperations.indexOf(unitOp);
    const energyStream = new EnergyStream({}, this.unitOperation.ownerCase);
    energyStream.providerUnitOperationId = unitOp.id;
    this.addEnergyStreamControls(energyStream);
    this.activeEnergyStreams.push(energyStream);
    this.availableProviderUnitOperations.splice(unitOpIndex, 1);
  }

  private isSelectedEnergyStreamActive(): boolean {
    // instead of just a boolean this method could return a more elaborated object, maybe it could include the names
    // of the owner unit operation
    return !!this.activeEnergyStreams.find(is => is.providerUnitOperationId === this.selectedProviderUnitOperationId);
  }

  // removes the information streams from the available list if they are already in use
  // TODO check if this works ... and if it can be improved
  filterStreams() {
    if (this.activeEnergyStreams.length === 0) {
      this.availableProviderUnitOperations = this.getAvailableEnergyStreamProviders();
    } else {
      this.availableProviderUnitOperations = this.getAvailableEnergyStreamProviders();
      for (let i = 0; i < this.activeEnergyStreams.length; i++) {
        for (let j = 0; j < this.availableProviderUnitOperations.length; j++) {
          if (this.availableProviderUnitOperations[j].id === this.activeEnergyStreams[i].providerUnitOperationId) {
            this.availableProviderUnitOperations.splice(j, 1);
          }
        }
      }
    }
  }

  removeEnergyStream(i: number) {
    this.activeEnergyStreams.splice(i, 1);
    this.energyStreamsFormArray.removeAt(i);
    this.filterStreams();
  }

  // region form group getters
  getEnergyStreamsFormGroupsArray(): UntypedFormGroup[] {
    return this.energyStreamsFormArray.controls as UntypedFormGroup[];
  }

  get selectedProviderUnitOperationId(): string {
    return this.propertyWindowFormGroup.get('selectedProviderUnitOperationId').value;
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  get energyStreamsFormArray(): UntypedFormArray {
    return this.propertyWindowFormGroup.get('energyStreams') as UntypedFormArray;
  }

  // endregion

  get currentCase(): Case {
    return this.coreService.currentCase;
  }
}
