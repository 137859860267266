import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';
import { Case } from '../../_models';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { ArrayToUnitOperationMapper } from './mappers/array-to-unit-operation-mapper';

@Injectable()
export class ExcelUploadService {
  private readonly ID_INDEX = 2;

  private readonly SOURCES_SHEET = 0;
  private readonly UPGRADERS_SHEET = 1;
  private readonly PIPES_SHEET = 2;
  private readonly REFINERIES_SHEET = 3;

  private readonly arrayToUnitOperationMapper: ArrayToUnitOperationMapper;

  constructor() {
    this.arrayToUnitOperationMapper = new ArrayToUnitOperationMapper();
  }

  importExcelFile(c: Case, excelFile) {
    const wb = XLSX.read(excelFile, { type: 'binary', sheetStubs: true });

    const sources = this.readSheet(wb, this.SOURCES_SHEET);
    this.arrayOfArraysToUnitOperations(sources, c);

    const upgraders = this.readSheet(wb, this.UPGRADERS_SHEET);
    this.arrayOfArraysToUnitOperations(upgraders, c);

    const pipes = this.readSheet(wb, this.PIPES_SHEET);
    this.arrayOfArraysToUnitOperations(pipes, c);

    const refineries = this.readSheet(wb, this.REFINERIES_SHEET);
    this.arrayOfArraysToUnitOperations(refineries, c);
  }

  readSheet(wb: XLSX.WorkBook, sheetIndex: number): any[][] {
    const sheet = wb.Sheets[wb.SheetNames[sheetIndex]];
    const sheetAsJson = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    return this.transposeArrayOfArrays(sheetAsJson as any[][]);
  }

  transposeArrayOfArrays(source: any[][]): any[][] {
    const transposedArray = [];

    // for some reason xlsx library ignores empty cells of the last column
    // which creates a jagged array of arrays that causes errors while mapping
    // assumes the first row has the maximum number of columns
    const numberOfColumns = source.length ? source[0].length : 0;

    for (let rowIndex = 0; rowIndex < source.length; rowIndex++) {
      const row = source[rowIndex];

      // un-jag the array
      if (source[rowIndex].length < numberOfColumns) {
        this.fillRow(source[rowIndex], numberOfColumns - source[rowIndex].length);
      }

      for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
        if (typeof transposedArray[cellIndex] === 'undefined') {
          transposedArray[cellIndex] = [];
        }

        const cellValue = row[cellIndex];
        transposedArray[cellIndex].push(cellValue);
      }
    }

    return transposedArray;
  }

  fillRow(array: string[], itemsToAdd: number) {
    for (let i = 0; i < itemsToAdd; i++) {
      array.push('');
    }
  }

  arrayOfArraysToUnitOperations(source: any[][], c: Case) {
    for (const sourceValues of source) {
      const unitOperation = c.getUnitOperation(sourceValues[this.ID_INDEX]) as SuncorUnitOperation;

      if (unitOperation) {
        this.arrayToUnitOperationMapper.map(sourceValues, unitOperation);
      }
    }
  }
}
