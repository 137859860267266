import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { SimulationVariable } from '../../simulation-variable';
import { Quantity } from '../../../_config/quantity.enum';
import { EnergyStream } from './energy-stream';
import { isTypeUndefined } from '../../../_utils/utils';
import { FuelGasUnitOperation } from './fuel-gas-unit-operation';
import { BaseObject } from '../../base-object';
import { UnitOperationConstraints } from '../unit-operation-constraints';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';

export class FuelGasSinkImport extends FuelGasUnitOperation implements KpiProvider {
  category = unitOperationsConfig.fuelGasSinkImport.key;
  heatFlow: SimulationVariable;
  energyStreams: EnergyStream[];
  constraints: UnitOperationConstraints;

  constructor(unitOperation: any | FuelGasSinkImport, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any | FuelGasSinkImport) {
    super.initValues(unitOperation);
    this.energyStreams = [];

    if (!isTypeUndefined(unitOperation.energyStreams) && unitOperation.energyStreams instanceof Array) {
      // could be an array of strings
      const energyStream = unitOperation.energyStreams.length ? unitOperation.energyStreams[0] : undefined;

      // the copies and the original will share the reference to EnergyStreams array
      // as the copies only make sense for property windows that use template-driven forms
      if (energyStream instanceof EnergyStream) {
        this.energyStreams = unitOperation.energyStreams;
      }
    }

    this.heatFlow = new SimulationVariable(unitOperation.heatFlow, this.ownerCase, this);
    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('energyDemand', this.heatFlow);
  }

  override setSimulationVariableNames() {
    this.heatFlow.setName(SimulationVariableName.ENERGY_DEMAND);
  }

  override setQuantities() {
    this.heatFlow.setQuantity(Quantity.ENERGYRATE);
  }

  addEnergyStream(energyStream: EnergyStream) {
    const index = this.energyStreams.findIndex(
      es => es.providerUnitOperationId === energyStream.providerUnitOperationId
    );
    if (index > -1) {
      return;
    }

    if (!energyStream.providerUnitOperationId) {
      throw new Error('Unable to set providerUnitOperationId for energy Stream');
    }

    this.energyStreams.push(energyStream);
    this.ownerCase.addToPools(energyStream);
    energyStream.addSimVarsToPool();
  }

  removeEnergyStream(providerUnitOperationId: string) {
    const index = this.energyStreams.findIndex(i => i.providerUnitOperationId === providerUnitOperationId);
    if (index === -1) {
      return;
    }

    const energyStream = this.energyStreams.splice(index, 1)[0];
    this.ownerCase.removeFromPools(energyStream);
  }

  override getChildrenObjectsForRemoval(): BaseObject[] {
    const objs = super.getChildrenObjectsForRemoval();
    objs.push(...this.energyStreams);
    return objs;
  }

  protected override isPropertyBlackListed(property: any): boolean {
    return super.isPropertyBlackListed(property) || property === this.energyStreams;
  }

  getAvailableKpis(): SimulationVariable[] {
    if (!this.ownerCase.multiPeriodEnabled) {
      return [this.heatFlow];
    }
    return [];
  }

  dePersistEnergyStreams(persistedObj: any) {
    // TODO this is temporary...
    if (!persistedObj.energyStreams) {
      return;
    }

    persistedObj.energyStreams.forEach(isId => {
      this.energyStreams.push(this.ownerCase.getOtherBaseObject(isId) as EnergyStream);
    });
  }

  override dePersist(sup: any) {
    super.dePersist(sup);

    this.heatFlow = this.getSimulationVariableSafe(sup.heatFlow);
    this.setQuantities();
    this.initConstraints();
  }

  override toJSON(): any {
    const plainObject = super.toJSON();
    plainObject.energyStreams = this.energyStreams.map(i => i.id);

    return plainObject;
  }
}
