import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';
import { FuelGasSourceImport } from '../_models/_unit-operations/fuel-gas/fuel-gas-source-import';

export class FuelGasSourceImportFormChangeDetector extends DefaultBaseObjectFormChangeDetector {
  override detectChangesInValues(originalFormValue: any, currentValue: any): boolean {
    let changes = super.detectChangesInValues(originalFormValue, currentValue);

    const originalSinkValues = originalFormValue as FuelGasSourceImport;
    const currentSinkValues = currentValue as FuelGasSourceImport;

    if (
      originalSinkValues.inletSourceInformationStreams.length != currentSinkValues.inletSourceInformationStreams.length
    ) {
      changes = changes || true;
    }

    return changes;
  }
}
