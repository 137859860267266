import { Component, Input, OnInit } from '@angular/core';
import { WaterHeatExchanger } from '../../../_models/_unit-operations/utilties/water-heat-exchanger';
import { WaterHeatExchangerConfiguration } from '../../../_config/water-heat-exchanger-configuration.enum';
import { WaterHeatExchangerTemperatureCalculationMode } from '../../../_config/water-heat-exchanger-temperature-calculation-mode.enum';

@Component({
  selector: 'sob-water-heat-exchanger',
  templateUrl: './water-heat-exchanger.component.html',
  styleUrls: ['./water-heat-exchanger.component.css'],
})
export class WaterHeatExchangerComponent implements OnInit {
  @Input() unitOperation: WaterHeatExchanger;
  configuration = WaterHeatExchangerConfiguration;
  temperatureCalculationMode = WaterHeatExchangerTemperatureCalculationMode;

  ngOnInit() {
    if (this.unitOperation.temperatureCalculationMode === WaterHeatExchangerTemperatureCalculationMode.UA) {
      $('#selectMode').val(WaterHeatExchangerTemperatureCalculationMode.UA);
      $('#outlet-temperature').hide();
      $('#ua-temperature').show();
    } else if (
      this.unitOperation.temperatureCalculationMode ===
      WaterHeatExchangerTemperatureCalculationMode.OUTLET_TEMPERATURE_SHELL
    ) {
      $('#selectMode').val(WaterHeatExchangerTemperatureCalculationMode.OUTLET_TEMPERATURE_SHELL);
      $('#outlet-temperature').show();
      $('#ua-temperature').hide();
    } else if (
      this.unitOperation.temperatureCalculationMode ===
      WaterHeatExchangerTemperatureCalculationMode.OUTLET_TEMPERATURE_TUBE
    ) {
      $('#selectMode').val(WaterHeatExchangerTemperatureCalculationMode.OUTLET_TEMPERATURE_TUBE);
      $('#outlet-temperature').show();
      $('#ua-temperature').hide();
    }

    if (this.unitOperation.configuration === WaterHeatExchangerConfiguration.COUNTER_CURRENT) {
      $('#configurationMode').val(WaterHeatExchangerConfiguration.COUNTER_CURRENT);
    } else if (this.unitOperation.configuration === WaterHeatExchangerConfiguration.CO_CURRENT) {
      $('#configurationMode').val(WaterHeatExchangerConfiguration.CO_CURRENT);
    }
  }

  temperatureCalculationModeChanged(calculationValue) {
    if (calculationValue === WaterHeatExchangerTemperatureCalculationMode.UA) {
      this.unitOperation.temperatureCalculationMode = WaterHeatExchangerTemperatureCalculationMode.UA;
      $('#outlet-temperature').hide();
      $('#ua-temperature').show();
    } else if (calculationValue === WaterHeatExchangerTemperatureCalculationMode.OUTLET_TEMPERATURE_SHELL) {
      this.unitOperation.temperatureCalculationMode =
        WaterHeatExchangerTemperatureCalculationMode.OUTLET_TEMPERATURE_SHELL;
      $('#outlet-temperature').show();
      $('#ua-temperature').hide();
    } else if (calculationValue === WaterHeatExchangerTemperatureCalculationMode.OUTLET_TEMPERATURE_TUBE) {
      this.unitOperation.temperatureCalculationMode =
        WaterHeatExchangerTemperatureCalculationMode.OUTLET_TEMPERATURE_TUBE;
      $('#outlet-temperature').show();
      $('#ua-temperature').hide();
    }
  }

  configurationModeChanged(value) {
    if (value === WaterHeatExchangerConfiguration.COUNTER_CURRENT) {
      this.unitOperation.configuration = WaterHeatExchangerConfiguration.COUNTER_CURRENT;
    } else if (value === WaterHeatExchangerConfiguration.CO_CURRENT) {
      this.unitOperation.configuration = WaterHeatExchangerConfiguration.CO_CURRENT;
    }
  }
}
