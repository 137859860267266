import { Component, Input } from '@angular/core';
import { SuncorRenewableBlock } from '../../../_models/_unit-operations/suncor-renewable-block';
import { SuncorRenewable } from '../../../_models/_unit-operations/suncor-renewable';

@Component({
  selector: 'sob-suncor-renewable-block',
  templateUrl: './suncor-renewable-block.component.html',
  styleUrls: ['./suncor-renewable-block.component.css'],
})
export class SuncorRenewableBlockComponent {
  @Input()
  unitOperation: SuncorRenewableBlock;

  addRenewable(renewableType) {
    this.unitOperation.renewablesList.push(
      new SuncorRenewable(
        {
          name: this.findRenewableNameByType(renewableType),
          renewableType,
        },
        this.unitOperation,
        this.unitOperation.ownerCase
      )
    );
  }

  removeRenewable(id) {
    const idx = this.unitOperation.renewablesList.findIndex(sr => sr.id === id);
    this.unitOperation.renewablesList.splice(idx, 1);
  }

  private findRenewableNameByType(type) {
    const howMany = this.unitOperation.renewablesList.filter(sr => sr.renewableType === type).length;
    return `${type} ${howMany + 1}`;
  }
}
