import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { attachSimVarEvent } from '../unit-converter.helper';
import { SimulationVariable } from '../../../_models';
import { ReadyStatus } from '../../../_config/ready-status.enum';

declare let unitConverter: any;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sfe-value-input',
  template: `<input
    type="text"
    name="{{ inputId }}"
    id="{{ inputId }}"
    [(ngModel)]="displayValue"
    (change)="valueChanged($event.target.value)"
    class="{{ elClass }} {{ simVar.variableStatus }} {{ simVar.readyStatus }}"
    [disabled]="disabled" />`,
})
export class ValueInputComponent implements AfterContentInit, AfterViewInit, OnInit {
  @Input() inputId: string;
  @Input() convertedBy: string;
  @Input() simVar: SimulationVariable;
  @Input() elClass: string;
  @Input() multiple: boolean;
  @Input() readOnlyValue: boolean;
  @Input() readOnlyUnit: boolean;

  // when this is true the input element will be disabled
  @Input() disabled: boolean;

  originalValue: number;
  displayValue: string;

  ref: ChangeDetectorRef;

  constructor(ref: ChangeDetectorRef) {
    this.ref = ref;
  }

  ngOnInit() {
    this.originalValue = this.simVar.value;
  }

  ngAfterViewInit(): void {
    if (!this.multiple && this.convertedBy) {
      attachSimVarEvent($(`#${this.inputId}`), $(`#${this.convertedBy}`), this.simVar.quantity, this);
    }
  }

  ngAfterContentInit(): void {
    if (!this.simVar.quantity) {
      this.setValue(this.simVar.value, unitConverter.formatNumber(this.simVar.value));
      return;
    }

    const conversion = unitConverter(this.simVar.quantity).convert(
      this.simVar.value,
      unitConverter(this.simVar.quantity).getInternalUnit(),
      this.simVar.unit
    );

    this.setValue(conversion.getValue(), conversion.round().format());
  }

  setValue(rawValue, displayValue) {
    if (!this.readOnlyValue) {
      this.simVar.value = rawValue;
    }

    this.displayValue = displayValue;
  }

  setUnit(unit): void {
    if (!this.readOnlyUnit) {
      this.simVar.unit = unit;
    }
  }

  getConversionToInternalUnit(value) {
    return unitConverter(this.simVar.quantity).convert(
      value,
      this.simVar.unit,
      unitConverter(this.simVar.quantity).getInternalUnit()
    );
  }

  valueChanged(value) {
    const valueInternal = this.getConversionToInternalUnit(value).getValue();

    if (valueInternal < this.simVar.lowerBound || valueInternal > this.simVar.upperBound) {
      this.simVar.readyStatus = ReadyStatus.NOT_VALID;
      this.displayValue = '';
    } else {
      this.simVar.readyStatus = ReadyStatus.READY;
    }
  }
}
