import { Component, Input, OnInit } from '@angular/core';
import { MineSource } from '../../../_models/_unit-operations/mine-source';
import { CoreService } from '../../../_services/core.service';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';

@Component({
  selector: 'sob-mine-source',
  templateUrl: './mine-source.component.html',
  styleUrls: ['./mine-source.component.css'],
})
export class MineSourceComponent implements OnInit {
  @Input() unitOperation: MineSource;
  currentAnalysis: SuncorFluidAnalysis;

  constructor(private coreService: CoreService) {}

  ngOnInit() {
    this.updateCurrentAnalysis();
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Crude oil - Bitumen');
  }

  updateCurrentAnalysis(id?): void {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }

    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
      this.assayChanged();
    }
  }

  assayChanged(): void {
    const cuts = [
      'lightEndsCut',
      'lightNaphthaCut',
      'heavyNaphthaCut',
      'distillateCut',
      'LGOCut',
      'HGOCut',
      'VGOCut',
      'HVGOCut',
      'residCut',
    ];

    const simVarNames = ['sulfur', 'anilinePoint', 'massDensity', 'nitrogen', 'ccr'];

    for (const cutId of cuts) {
      for (const simVarName of simVarNames) {
        const simVar = this.currentAnalysis[cutId][simVarName];
        const $field = `#${cutId}${simVarName}`;
        $($field).val(simVar.convertToCaptureUnit(true));
        $(`${$field}Unit`).val(simVar.unit);
        $($field).change();
      }
    }

    $(`#compositionUnit${this.unitOperation.id}`).val(this.currentAnalysis.composition[0].unit);
    let total = 0;
    for (let i = 0; i < this.currentAnalysis.composition.length; i++) {
      const simVar = this.currentAnalysis.composition[i];
      total += simVar.convertToCaptureUnit(true);
      $(`#composition${i}`).val(simVar.convertToCaptureUnit(true));
    }

    $('#compositionTotalUO').val(total);

    $('#fluidAnalysisMassDensity').val(this.currentAnalysis.massDensity.convertToCaptureUnit(true));
    $('#fluidAnalysisMassDensityUnit').val(this.currentAnalysis.massDensity.unit);
    $('#fluidAnalysisMassDensity').change();

    $('#fluidAnalysisCcr').val(this.currentAnalysis.ccr.convertToCaptureUnit(true));
    $('#fluidAnalysisCcrUnit').val(this.currentAnalysis.ccr.unit);
    $('#fluidAnalysisCcr').change();

    $('#fluidAnalysisSulfur').val(this.currentAnalysis.sulfur.convertToCaptureUnit(true));
    $('#fluidAnalysisSulfurUnit').val(this.currentAnalysis.sulfur.unit);
    $('#fluidAnalysisSulfur').change();

    $('#fluidAnalysisNitrogen').val(this.currentAnalysis.nitrogen.convertToCaptureUnit(true));
    $('#fluidAnalysisNitrogenUnit').val(this.currentAnalysis.nitrogen.unit);
    $('#fluidAnalysisNitrogen').change();
  }
}
