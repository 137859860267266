import { UnitOperation } from '../../unit-operation';
import { Case } from '../../case';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';

export class FuelGasUtilityUnitOperation extends UnitOperation {
  category = unitOperationsConfig.fuelGasUtilityUnitOperation.key;
  isSolved: boolean;

  constructor(unitOperation: FuelGasUtilityUnitOperation | any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
  }

  override initValues(unitOperation: any) {
    this.isSolved = unitOperation.isSolved;
  }

  override clearResults() {
    super.clearResults();
    this.isSolved = false;
  }

  override setSimulationVariableNames() {}

  override get isFlowsheetOwner() {
    return true;
  }
}
