import { isTypeFunction } from '../../_utils/utils';

export interface OutletDistributionRatioVariablesOwner {
  addOutletDistributionRatioVariables(unitOperationId: string): void;
  removeOutletDistributionRatioVariables(unitOperationId: string): void;
}

export function isOutletDistributionRatioVariablesOwner(val: any): val is OutletDistributionRatioVariablesOwner {
  return (
    isTypeFunction(val.addOutletDistributionRatioVariables) &&
    isTypeFunction(val.removeOutletDistributionRatioVariables)
  );
}
