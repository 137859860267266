import { ReportValue } from './report-value';
import { Case } from '../case';
import { UnitOperation } from '../unit-operation';
import { ThirdPartyRefinery } from '../_unit-operations/third-party-refinery';
import { Refinery } from '../_unit-operations/refinery';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { SimulationVariable } from '../simulation-variable';

declare let unitConverter: any;

export class RefineryFlowRatesReport {
  total: number;
  unit: string;
  data: Array<ReportValue> = [];

  constructor(c: Case) {
    this.initReport(c);
  }

  initReport(c: Case) {
    this.total = 0;

    const refineries = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.category === unitOperationsConfig.refinery.key;
    }) as Array<Refinery>;

    const thirpartyrefineries = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.category === unitOperationsConfig.thirdPartyRefinery.key;
    }) as Array<ThirdPartyRefinery>;

    for (const uo of refineries) {
      const s = uo.volumetricFlowrate;
      this.total += s.value;
      this.data.push(new ReportValue(unitConverter.parseFloatString(s.convertToDefaultUnit(true, false)), uo.name));
    }
    for (const uo of thirpartyrefineries) {
      const s = uo.volumetricFlowrate;
      this.total += s.value;
      this.data.push(new ReportValue(unitConverter.parseFloatString(s.convertToDefaultUnit(true, false)), uo.name));
    }

    const sv = refineries.length
      ? refineries[0].volumetricFlowrate
      : thirpartyrefineries.length
      ? thirpartyrefineries[0].volumetricFlowrate
      : null;

    if (sv) {
      this.unit = sv.unit;
      const totalSv = new SimulationVariable(sv, sv.ownerCase, sv.ownerBaseObject);
      totalSv.value = this.total;

      this.total = unitConverter.parseFloatString(totalSv.convertToDefaultUnit(true, false));
    }
  }
}
