import { SimulationVariable } from '../simulation-variable';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { Case } from '../case';
import { Quantity } from '../../_config/quantity.enum';
import { GasContributorUnitOperation } from './gas-contributor-unit-operation';
import { KpiProvider } from '../_case-study/kpi-provider';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { ParameterProvider } from '../_case-study/parameter-provider';

declare let unitConverter: any;

export class SulfurPlant extends GasContributorUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.sulfurPlant.key;

  constraints: UnitOperationConstraints;

  sulfurGHGIntensity: SimulationVariable;
  sulfurFlowrate: SimulationVariable;
  sulfurCapacity: SimulationVariable;
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.sulfurGHGIntensity = new SimulationVariable(unitOperation.sulfurGHGIntensity, this.ownerCase, this);
    this.sulfurFlowrate = new SimulationVariable(unitOperation.sulfurFlowrate, this.ownerCase, this);
    this.sulfurCapacity = new SimulationVariable(unitOperation.sulfurCapacity, this.ownerCase, this);
    this.setSimulationVariableNames();
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [
        this.sulfurCapacity,
        this.sulfurFlowrate,
        this.sulfurGHGIntensity,
        this.ghgEmissions,
        this.opexTotal,
        this.capexTotal,
      ];
    }
    return [
      this.sulfurCapacity,
      this.sulfurFlowrate,
      this.sulfurGHGIntensity,
      this.ghgEmissions,
      this.steamUse900,
      this.steamMake900,
      this.steamMake600,
      this.steamUse600,
      this.steamMake150,
      this.steamUse150,
      this.steamMake50,
      this.steamUse50,
      this.fuelGasHeatingValue,
      this.fuelGasEmissionFactor,
      this.energyFlow,
      this.flowrate,
      this.opexTotal,
      this.capexTotal,
    ];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.ghgIntensity, this.opexVar, this.opexGasDiesel, this.opexPower, this.opexFixed, this.capexAmortized];
  }

  getAvailableParameters() {
    return undefined;
  }

  override clearResults() {
    super.clearResults();
    this.sulfurFlowrate.value = undefined;
  }

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  override initValues(unitOperation: any | SulfurPlant): void {
    super.initValues(unitOperation);
  }

  override setDefaultValues(): void {
    if (this.sulfurGHGIntensity.isUndefined()) {
      this.sulfurGHGIntensity.setDefaultValue(0.0);
    }
    if (this.energyFlow.isUndefined()) {
      this.energyFlow.setDefaultValue(0.0, unitConverter.units.Energyrate.GJ_H);
    }

    super.setDefaultValues();
  }

  override setQuantities() {
    super.setQuantities();
    this.sulfurGHGIntensity.setQuantity(Quantity.MASS_PER_STD_H2_VOLUME);
    this.sulfurFlowrate.setQuantity(Quantity.MOLEFLOWRATE);
    this.sulfurCapacity.setQuantity(Quantity.MOLEFLOWRATE);
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.sulfurGHGIntensity = this.getSimulationVariableSafe(sup.sulfurGHGIntensity);
    this.sulfurFlowrate = this.getSimulationVariableSafe(sup.sulfurFlowrate);
    this.sulfurCapacity = this.getSimulationVariableSafe(sup.sulfurCapacity);
    this.setQuantities();
    this.setDefaultValues();
  }

  constraintValueDefined() {
    return this.constraints.anyConstraintValueDefined();
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.sulfurCapacity.setName(SimulationVariableName.SULFUR_CAPACITY);
    this.sulfurFlowrate.setName(SimulationVariableName.SULFUR_FLOWRATE);
    this.sulfurGHGIntensity.setName(SimulationVariableName.PROCESS_GHG_INTENSITY);
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.energyFlow.setName(SimulationVariableName.ENERGY_FLOW);
    this.flowrate.setName(SimulationVariableName.FUEL_GAS_FLOWRATE);
  }
}
