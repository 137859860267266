import { Mapper } from '../../../../_models/_interfaces/mapper';
import { DiluentSource } from '../../../../_models/_unit-operations/diluent-source';
import { StringToSimulationVariableMapper } from '../string-to-simulation-variable-mapper';
import { SimulationVariable } from '../../../../_models';

export enum DiluentSourceExcelIndexes {
  VOLUMETRIC_FLOW_RATE = 4,
  GHG_INTENSITY = 5,
  COGEN_ELECTRICITY_GENERATION = 7,
  NET_ELECTRICITY_EXPORT = 8,
  COGEN_INTENSITY = 9,
  GRID_INTENSITY = 10,
  MASS_DENSITY = 14,
  CCR = 15,
  SULFUR = 16,
  NITROGEN = 17,
  LIGHT_ENDS = 20,
  NAPHTA = 21,
  DISTILLATE = 22,
  GAS_OIL = 23,
  VACUUM_RESIDUE = 24,
}

export class ArrayToDiluentSourceMapper implements Mapper<string[], DiluentSource> {
  private readonly simulationVariableMapper: Mapper<string, SimulationVariable>;

  constructor() {
    this.simulationVariableMapper = new StringToSimulationVariableMapper();
  }

  map(source: string[], destination: DiluentSource) {
    this.simulationVariableMapper.map(
      source[DiluentSourceExcelIndexes.VOLUMETRIC_FLOW_RATE],
      destination.volumetricFlowrate
    );
    this.simulationVariableMapper.map(source[DiluentSourceExcelIndexes.GHG_INTENSITY], destination.ghgIntensity);
  }
}
