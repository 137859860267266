import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { WaterBoiler } from '../../../_models/_unit-operations/utilties/water-boiler';
import { SimulationVariable } from '../../../_models/simulation-variable';
import { isEmptyValue } from '../../../_utils/utils';
import { VariableStatus } from '../../../_config/variable-status.enum';

@Component({
  selector: 'sob-water-boiler',
  templateUrl: './water-boiler.component.html',
  styleUrls: ['./water-boiler.component.css'],
})
export class WaterBoilerComponent implements OnInit {
  @Input() unitOperation: WaterBoiler;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  constructor(private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit() {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
    this.grayOutFields();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('outletPressure', this.svfb.control(this.unitOperation.outletPressure));
    this.propertyWindowFormGroup.addControl(
      'outletVaporFraction',
      this.svfb.control(this.unitOperation.outletVaporFraction)
    );
    this.propertyWindowFormGroup.addControl(
      'outletTemperature',
      this.svfb.control(this.unitOperation.outletTemperature)
    );
    this.propertyWindowFormGroup.addControl('power', this.svfb.control(this.unitOperation.power));
    this.propertyWindowFormGroup.addControl(
      'fuelGasEmissionFactor',
      this.svfb.control(this.unitOperation.fuelGasEmissionFactor)
    );
    this.propertyWindowFormGroup.addControl(
      'fuelGasHeatingValue',
      this.svfb.control(this.unitOperation.fuelGasHeatingValue)
    );
    this.propertyWindowFormGroup.addControl('ghgEmissions', this.svfb.control(this.unitOperation.ghgEmissions, true));

    this.propertyWindowFormGroup.addControl(
      'massCapacity',
      this.svfb.control(this.unitOperation.massCapacity, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'minimumMassFlowrate',
      this.svfb.control(this.unitOperation.minimumMassFlowrate, false, false, true)
    );
  }

  grayOutFields(): void {
    const temperature = this.propertyWindowFormGroup.get('outletTemperature').get('value');
    const pressure = this.propertyWindowFormGroup.get('outletPressure').get('value');
    const vaporFraction = this.propertyWindowFormGroup.get('outletVaporFraction').get('value');
    const power = this.propertyWindowFormGroup.get('power').get('value');

    // an array of tuples
    const controlsWithSimVar: [SimulationVariable, AbstractControl][] = [
      [this.unitOperation.outletTemperature, temperature],
      [this.unitOperation.outletPressure, pressure],
      [this.unitOperation.outletVaporFraction, vaporFraction],
      [this.unitOperation.power, power],
    ];

    const controlsWithUndefinedValue = controlsWithSimVar.filter(item => {
      // the solver calculated status must be considered as well
      return isEmptyValue(item[1].value) || item[0].variableStatus === VariableStatus.SOLVER_CALCULATED;
    });

    const controlsWithValue = controlsWithSimVar.filter(item => {
      return !isEmptyValue(item[1].value) && item[0].variableStatus !== VariableStatus.SOLVER_CALCULATED;
    });

    if (controlsWithValue.length >= controlsWithUndefinedValue.length) {
      for (let i = 0; i < controlsWithUndefinedValue.length; i++) {
        controlsWithUndefinedValue[i][0].variableStatus = VariableStatus.SOLVER_CALCULATED;
        controlsWithUndefinedValue[i][1].disable();
      }
    } else {
      controlsWithSimVar.forEach(item => {
        item[0].variableStatus = VariableStatus.USER_SPECIFIED;
        item[1].enable();
      });
    }
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  // endregion
}
