import { BaseObject } from './base-object';
import { CaseSettings } from './case-settings';
import { Case } from './case';
import { CaseStudyManager } from './_case-study/case-study-manager';
import { KpiManager } from './_case-study/kpi-manager';
import { ObjectiveFunction } from './_optimization/objective-function';
import { SuncorCalculator } from './suncor-calculator';
import { ParametricStudy } from './_case-study/parametric-study';
import { Parameter } from './_case-study/parameter';
import { ParametricStudyParameter } from './_case-study/parametric-study-parameter';
import { UncertaintyAnalysis } from './_case-study/uncertainty-analysis';
import { UncertainParameter } from './_case-study/uncertain-parameter';
import { SimulationVariableArray } from './simulation-variable-array';
import { AssayManager } from './_fluid/assay-manager';
import { SuncorFluidAnalysis } from './_fluid/suncor-fluid-analysis';
import { WaterSourceInformationStream } from './_unit-operations/utilties/water-source-information-stream';
import { WaterPipeSegment } from './_unit-operations/utilties/water-pipe-segment';
import { GasFluidAnalysis } from './_fluid/gas-fluid-analysis';
import { EnergyStream } from './_unit-operations/fuel-gas/energy-stream';
import { FuelGasSourceInformationStream } from './_unit-operations/fuel-gas/fuel-gas-source-information-stream';
import { MultiPeriodSettings } from './_multi-period/multi-period-settings';
import { MultiPeriodParameter } from './_multi-period/multi-period-parameter';

export class BaseObjectFactory {
  static create(sourceObj: any, ownerCase: Case): BaseObject {
    let bo: BaseObject;

    switch (sourceObj.category) {
      case 'caseSettings':
        bo = new CaseSettings(sourceObj, ownerCase);
        break;

      case 'caseStudyManager':
        bo = new CaseStudyManager(sourceObj, ownerCase);
        break;

      case 'assayManager':
        bo = new AssayManager(sourceObj, ownerCase);
        break;

      case 'fluid-analysis':
        bo = new SuncorFluidAnalysis(sourceObj, ownerCase);
        break;

      case 'gas-fluid-analysis':
        bo = new GasFluidAnalysis(sourceObj, ownerCase);
        break;

      case 'kpiManager':
        bo = new KpiManager(sourceObj, ownerCase);
        break;

      case 'objectiveFunction':
        bo = new ObjectiveFunction(sourceObj, ownerCase);
        break;

      case 'suncorCalculator':
        bo = new SuncorCalculator(sourceObj, ownerCase);
        break;
      case 'parametricStudy':
        bo = new ParametricStudy(sourceObj, ownerCase);
        break;
      case 'parameter':
        bo = new Parameter(sourceObj, ownerCase);
        break;
      case 'parametricStudyParameter':
        bo = new ParametricStudyParameter(sourceObj, ownerCase);
        break;
      case 'uncertaintyAnalysis':
        bo = new UncertaintyAnalysis(sourceObj, ownerCase);
        break;
      case 'uncertainParameter':
        bo = new UncertainParameter(sourceObj, ownerCase);
        break;
      case 'simulationVariableArray':
        bo = new SimulationVariableArray(sourceObj, ownerCase, null);
        break;

      case 'water-source-information-stream':
        bo = new WaterSourceInformationStream(sourceObj, ownerCase);
        break;
      case 'waterPipeSegment':
        bo = new WaterPipeSegment(sourceObj, ownerCase);
        break;

      case 'multiPeriodSettings':
        bo = new MultiPeriodSettings(sourceObj, ownerCase);
        break;

      case 'multiPeriodParameter':
        bo = new MultiPeriodParameter(sourceObj, ownerCase);
        break;

      case 'energyStream':
        bo = new EnergyStream(sourceObj, ownerCase);
        break;
      case 'fuelGasSourceInformationStream':
        bo = new FuelGasSourceInformationStream(sourceObj, ownerCase);
        break;
    }

    return bo;
  }
}
