import { FlexSheetMapper } from './flex-sheet-mapper';
import { Pipe } from '../../../_models/_unit-operations/pipe';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';
import { SagdSourceSheetRows } from '../cell-config/sagd-source-cell-config';
import { PipeSheetRows } from '../cell-config/pipe-cell-config';

export class PipeFlexSheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: Pipe, row: number, value: string | number) {
    if (row === SagdSourceSheetRows.NAME && typeof value === 'string') {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (row) {
        case PipeSheetRows.PIPE_LENGTH:
          this.simulationVariableMapper.map(value, unitOperation.pipeLength);
          break;

        case PipeSheetRows.PRESSURE_DROP:
          this.simulationVariableMapper.map(value, unitOperation.pressureDrop);
          break;

        case PipeSheetRows.GHG_INTENSITY_PER_MASS_DISTANCE:
          this.simulationVariableMapper.map(value, unitOperation.ghgIntensityPerMassDistance);
          break;

        case PipeSheetRows.PIPE_DENSITY_SPEC:
          this.simulationVariableMapper.map(value, unitOperation.pipeDensitySpec);
          break;

        case PipeSheetRows.CAPACITY:
          this.simulationVariableMapper.map(value, unitOperation.capacity);
          break;

        case PipeSheetRows.OPEX_FIXED:
          this.simulationVariableMapper.map(value, unitOperation.opexFixed);
          break;

        case PipeSheetRows.OPEX_VAR:
          this.simulationVariableMapper.map(value, unitOperation.opexVar);
          break;
      }
    }
  }
}
