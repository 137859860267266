import { Component } from '@angular/core';
import { AppInfo } from '../../_config/app-info';

@Component({
  selector: 'sob-about-window',
  templateUrl: './about-window.component.html',
  styleUrls: ['./about-window.component.css'],
})
export class AboutWindowComponent {
  get appVersion() {
    return AppInfo.APP_VERSION;
  }
}
