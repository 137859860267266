import { Component, Input } from '@angular/core';
import { Upgrader } from '../../../_models/_unit-operations/upgrader';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
import { defaultUpgraderModel } from '../../../_config/unit-operations/default-models/default-upgrader-model';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { defaultSteamModelForHydrogenPlants } from '../../../_config/unit-operations/default-models/default-steam-model-for-hydrogen-plants';
import { UpgraderCodeProvider } from '../../../_services/custom-code-providers/upgrader-code-provider';

@Component({
  selector: 'sob-upgrader',
  templateUrl: './upgrader.component.html',
  styleUrls: ['./upgrader.component.css'],
})
export class UpgraderComponent {
  @Input()
  unitOperation: Upgrader;

  isOpened = false;
  defaultCodeProvider: UpgraderCodeProvider;
  steamDefaultCodeProvider: DefaultCodeProvider;

  constructor(private flowsheetService: FlowsheetService, private flowsheetDiagramService: FlowsheetDiagramService) {
    this.defaultCodeProvider = new UpgraderCodeProvider();
    this.steamDefaultCodeProvider = new DefaultCodeProvider(defaultSteamModelForHydrogenPlants);
  }

  saveCustomModel(res: any) {
    if (res.instanceId === 'upgraderCodeEditor') {
      this.unitOperation.upgraderModelScript = res.code;
    }
  }

  saveSteamCustomModel(res: any) {
    if (res.instanceId === 'upgraderSteamCodeEditor') {
      this.unitOperation.hydrogenPlantsCustomModel = res.code;
    }
  }

  openSubFlowsheet() {
    if ($('#closePropertyWindow').attr('disabled')) {
      return;
    }
    this.flowsheetService.prepareUnitOperationForSave();
    this.flowsheetService.closePropertyWindow();

    // TODO what is this?
    setTimeout(() => {}, 500);
    this.flowsheetService.loadUnitOperationFlowsheet(this.unitOperation.id);
  }

  clearOpex() {
    if (!this.unitOperation.useSubFlowsheet) {
      this.unitOperation.opexTotal.value = null;
      $('#opexTotal').val('');

      this.unitOperation.opexVar.value = null;
      $('#opexVar').val('');

      this.unitOperation.opexGasDiesel.value = null;
      $('#opexGasDiesel').val('');

      this.unitOperation.opexPower.value = null;
      $('#opexPower').val('');

      this.unitOperation.opexCarbonDioxide.value = null;
      $('#opexCarbonDioxide').val('');

      this.unitOperation.opexFixed.value = null;
      $('#opexFixed').val('');
    }
  }

  changeNodeTemplate(subFlowsheetEnabled) {
    if (subFlowsheetEnabled) {
      this.flowsheetDiagramService.changeTemplate(this.unitOperation.id, unitOperationsConfig.flexibleUpgrader.key);
    } else {
      this.flowsheetDiagramService.changeTemplate(this.unitOperation.id, unitOperationsConfig.upgrader.key);
    }
    this.flowsheetDiagramService.deleteInletStreams(this.unitOperation.id);
    this.flowsheetDiagramService.deleteOutletStreams(this.unitOperation.id);
  }

  // TODO unused - remove
  changeInletPorts(has2Inlets) {
    if (has2Inlets) {
      this.flowsheetDiagramService.changeTemplate(
        this.unitOperation.id,
        unitOperationsConfig.multipleInletUpgrader.key
      );
    } else {
      this.flowsheetDiagramService.changeTemplate(this.unitOperation.id, unitOperationsConfig.level2Upgrader.key);
    }
    this.flowsheetDiagramService.deleteInletStreams(this.unitOperation.id);
  }

  switchOpened() {
    $('#steamDiv').collapse('toggle');
    this.isOpened = !this.isOpened;
  }
}
