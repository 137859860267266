import { WaterUnitOperation } from './water-unit-operation';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';
import { ThermalCalculationMode } from '../../../_config/thermal-calculation-mode.enum';
import { WaterPipePressureModel } from '../../../_config/water-pipe-pressure-model.enum';
import { WaterPipeSegment } from './water-pipe-segment';
import { BaseObject } from '../../base-object';
import { ArrayDiff } from '../../../_utils/array-diff';
import { hasNumericValue } from '../../../_utils/utils';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

declare let unitConverter: any;
export class WaterPipe extends WaterUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.waterPipe.key;
  pipePressureModel: WaterPipePressureModel;
  thermalCalculationMode: ThermalCalculationMode;
  stepLength: SimulationVariable;
  segments: WaterPipeSegment[];

  constructor(unitOperation: WaterPipe | any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: WaterPipe | any) {
    super.initValues(unitOperation);
    this.pipePressureModel = unitOperation.pipePressureModel || WaterPipePressureModel.HOMOGENEOUS;
    this.thermalCalculationMode = unitOperation.thermalCalculationMode || ThermalCalculationMode.ADIABATIC;
    this.stepLength = new SimulationVariable(unitOperation.stepLength, this.ownerCase, this);
    this.segments = [];

    if (unitOperation.segments instanceof Array) {
      const waterPipeSegment = unitOperation.segments.length ? unitOperation.segments[0] : undefined;
      if (waterPipeSegment instanceof WaterPipeSegment) {
        this.segments = unitOperation.segments;
      }
    }
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.stepLength.setName(SimulationVariableName.STEP_LENGTH);
  }

  override overwriteValues(another: BaseObject) {
    const sourceWaterPipe = another as WaterPipe;
    const destinationWaterPipe = this;

    destinationWaterPipe.pipePressureModel = sourceWaterPipe.pipePressureModel;
    destinationWaterPipe.thermalCalculationMode = sourceWaterPipe.thermalCalculationMode;
    this.mapStepLengthProperty(sourceWaterPipe, destinationWaterPipe);
    destinationWaterPipe.comments = sourceWaterPipe.comments;

    const arrayDiff = new ArrayDiff(sourceWaterPipe.segments);

    const diff = arrayDiff.diff(destinationWaterPipe.segments, (l: WaterPipeSegment, r: WaterPipeSegment) => {
      return l.id === r.id;
    });

    for (const sourceWaterPipeSegment of diff.intersection) {
      const destinationWaterPipeSegment = destinationWaterPipe.segments.find(
        informationStream => informationStream.id === sourceWaterPipeSegment.id
      );

      this.mapSimulationVariables(sourceWaterPipeSegment, destinationWaterPipeSegment);
    }

    const waterPipeSegmentsToAdd = diff.left;
    for (const sourceWaterPipeSegment of waterPipeSegmentsToAdd) {
      const destinationWaterPipeSegment = new WaterPipeSegment(sourceWaterPipeSegment, destinationWaterPipe.ownerCase);
      destinationWaterPipe.addWaterPipeSegments(destinationWaterPipeSegment);
    }

    const waterPipeSegmentsToDelete = diff.right;
    for (const waterPipeSegmentToDelete of waterPipeSegmentsToDelete) {
      destinationWaterPipe.removeWaterPipeSegment(waterPipeSegmentToDelete);
    }
  }

  mapStepLengthProperty(source: WaterPipe, destination: WaterPipe) {
    destination.stepLength.quantity = source.stepLength.quantity;
    destination.stepLength.value = source.stepLength.value;
    destination.stepLength.unit = source.stepLength.unit;
    destination.stepLength.variableStatus = source.stepLength.variableStatus;
  }

  mapSimulationVariables(source: BaseObject, destination: BaseObject) {
    const keys = Object.keys(source);

    for (const key of keys) {
      const currentValue = destination[key];

      if (currentValue instanceof SimulationVariable) {
        if (source[key].id) {
          destination[key].id = source[key].id;
        }

        if (source[key].quantity) {
          destination[key].quantity = source[key].quantity;
        }

        if (hasNumericValue(source[key].value)) {
          destination[key].value = +source[key].value;
        } else {
          destination[key].value = undefined;
        }

        if (source[key].unit) {
          destination[key].unit = source[key].unit;
        }

        if (source[key].status) {
          destination[key].variableStatus = source[key].variableStatus;
        }
      } else {
        destination[key] = source[key];
      }
    }
  }

  addWaterPipeSegments(waterPipeSegment: WaterPipeSegment) {
    const index = this.segments.findIndex(wps => wps.id === waterPipeSegment.id);
    if (index > -1) {
      return;
    }

    this.segments.push(waterPipeSegment);
    this.setWaterPipeSegmentsName();
    this.ownerCase.addToPools(waterPipeSegment);
    waterPipeSegment.addSimVarsToPool();
  }

  removeWaterPipeSegment(wps: WaterPipeSegment) {
    const index = this.segments.findIndex(s => s.id === wps.id);
    if (index === -1) {
      return;
    }

    this.segments.splice(index, 1);
    this.ownerCase.removeFromPools(wps);
    this.ownerCase.removeFromPools(wps.segmentLength);
    this.ownerCase.removeFromPools(wps.insideDiameter);
    this.ownerCase.removeFromPools(wps.outsideDiameter);
    this.ownerCase.removeFromPools(wps.segmentAngle);
    this.ownerCase.removeFromPools(wps.relativeRoughness);
    this.ownerCase.removeFromPools(wps.overallU);
    this.ownerCase.removeFromPools(wps.inletAmbientTemperature);
    this.ownerCase.removeFromPools(wps.outletAmbientTemperature);
  }

  override setQuantities() {
    super.setQuantities();
    this.stepLength.setQuantity(Quantity.LENGTH);
  }

  override setDefaultValues() {
    super.setDefaultValues();
    if (this.stepLength.isUndefined()) {
      this.stepLength.setDefaultValue(1.0, unitConverter.units.Length.M);
    }
  }

  setWaterPipeSegmentsName() {
    for (let i = 0; i < this.segments.length; i++) {
      this.segments[i].name = `${this.name} - Segment ${i + 1}`;
    }
  }

  override getChildrenObjectsForRemoval(): BaseObject[] {
    const objs = super.getChildrenObjectsForRemoval();
    objs.push(...this.segments);
    return objs;
  }

  getAvailableKpis(): SimulationVariable[] {
    if (!this.ownerCase.multiPeriodEnabled) {
      return [this.stepLength];
    }
    return [];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.stepLength];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(unitOperation: any) {
    super.dePersist(unitOperation);
    this.stepLength = this.getSimulationVariableSafe(unitOperation.stepLength);

    this.setQuantities();
    this.setDefaultValues();
    this.setSimulationVariableNames();
  }

  dePersistPipeSegments(persistedObj: any) {
    if (!persistedObj.segments) {
      return;
    }

    persistedObj.segments.forEach(isId => {
      this.segments.push(this.ownerCase.getOtherBaseObject(isId) as WaterPipeSegment);
    });
  }

  public override toJSON(): any {
    return {
      id: this.id,
      location: this.location,
      key: this.key,
      name: this.name,
      flowsheetId: this.flowsheetId,
      readiness: this.readiness,
      comments: this.comments,
      category: this.category,
      segments: this.segments.map(wps => wps.id),
      pipePressureModel: this.pipePressureModel,
      thermalCalculationMode: this.thermalCalculationMode,
      stepLength: this.stepLength.id,
    };
  }
}
