import { HgoHydrotreater } from '../../../_unit-operations/hgo-hydrotreater';
import { SimulationVariable } from '../../../simulation-variable';
import { SimulationVariableName } from '../../../../_config/simulation-variable-name.enum';
import { ConstraintReportVariable } from '../constraint-report-variable';

declare const unitConverter: any;

export class HgoHydrotreaterConstraintReportVariableResolver {
  static create() {
    return new HgoHydrotreaterConstraintReportVariableResolver();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  addCurrentValueAndUnitToConstraintReportVariable(
    crv: ConstraintReportVariable,
    params: { uo: HgoHydrotreater; simVar: SimulationVariable }
  ): void {
    const { uo, simVar } = params;

    if (simVar.name === SimulationVariableName.MAXIMUM_FEED_CAPACITY) {
      crv.currentValue = uo.totalInletVolume.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.totalInletVolume.getDefaultUnit();
      return;
    }

    if (simVar.name === SimulationVariableName.MINIMUM_FLOW) {
      crv.currentValue = uo.totalInletVolume.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.totalInletVolume.getDefaultUnit();
      return;
    }

    // variables depending on inletStream
    const inletStreamList = uo.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === uo.id;
    });

    if (!inletStreamList.length) {
      return;
    }

    if (simVar.name === SimulationVariableName.MAXIMUM_NITROGEN) {
      crv.currentValue = inletStreamList[0].nitrogen.convertToAnotherUnit(unitConverter.units.MassFraction.PPMW, false);
      crv.currentUnit = unitConverter.units.MassFraction.PPMW;
      return;
    }

    if (simVar.name === SimulationVariableName.MAXIMUM_SULFUR_CONTENT) {
      crv.currentValue = inletStreamList[0].sulfur.convertToAnotherUnit(
        unitConverter.units.MassFraction.WATER_PERCENT,
        false
      );
      crv.currentUnit = unitConverter.units.MassFraction.WATER_PERCENT;
      return;
    }

    throw new Error(`Unknown constraint: ${simVar.name}`);
  }
}
