import { Injectable } from '@angular/core';
import * as go from 'gojs';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { GoJsDiagramUtils } from './go-js-diagram-utils';

@Injectable()
export class FuelGasFlowsheetTemplateProvider {
  $: any = go.GraphObject.make;

  constructor(private gojsDiagramUtils: GoJsDiagramUtils) {}

  getFuelGasFlareTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.fuelGasFlare.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              toMaxLinks: 1,
              name: 'IN',
              width: 5,
              height: 5,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          ),
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'left', marginTop: 6 })
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getFuelGasSinkImportTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          { width: 100 },
          this.$(
            go.Panel,
            'Spot',
            this.$(
              go.Picture,
              // eslint-disable-next-line max-len
              {
                width: 50,
                name: 'ICON',
                desiredSize: new go.Size(40, 40),
                margin: 5,
                source: unitOperationsConfig.fuelGasSinkImport.diagramIcon,
              }
            ),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0.5, 1),
              toSpot: go.Spot.BottomSide,
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.getCenterRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getFuelGasSourceImportTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.fuelGasSourceImport.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getFuelGasSinkTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.fuelGasSink.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              toMaxLinks: 1,
              name: 'IN',
              width: 5,
              height: 5,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          ),
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'left', marginTop: 6 })
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getFuelGasMixerTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap,
        linkConnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(22, 28),
              source: unitOperationsConfig.mixer.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              name: 'IN',
              width: 4,
              height: 39,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.88, 0.5),
              portId: 'out',
              name: 'OUT',
              fromMaxLinks: 1,
              width: 6,
              height: 4,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getFuelGasSplitterTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        linkConnected: (node, link, port) => {
          if (port.portId === 'out' && node.findLinksOutOf().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'out' && node.findLinksOutOf().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.$(go.Panel, 'Horizontal', this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(22, 28),
              source: unitOperationsConfig.fuelGasSplitter.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0.1, 0.5),
              portId: 'in',
              name: 'IN',
              height: 4,
              width: 4,
              toolTip: 'In',
              toMaxLinks: 1,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              portId: 'out',
              name: 'OUT',
              width: 4,
              height: 39,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getFuelGasSourceTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'right', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.fuelGasSource.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getFuelGasAmineContactorTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.fuelGasAmineContactor.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }
}
