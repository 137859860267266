import { UnitOperation } from '../../unit-operation';

export abstract class FuelGasUnitOperation extends UnitOperation {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override initValues(unitOperation: any) {}

  override setDefaultValues() {}

  override setQuantities() {}
}
