import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { WaterPipe } from '../../../_models/_unit-operations/utilties/water-pipe';
import { WaterPipePressureModel } from '../../../_config/water-pipe-pressure-model.enum';
import { ThermalCalculationMode } from '../../../_config/thermal-calculation-mode.enum';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { WaterPipeSegment } from '../../../_models/_unit-operations/utilties/water-pipe-segment';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { SimulationVariableMapper } from '../../../_mappers/simulation-variable-mapper';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: 'sob-water-pipe',
  templateUrl: './water-pipe.component.html',
  styleUrls: ['./water-pipe.component.css'],
})
export class WaterPipeComponent implements OnInit {
  @Input()
  unitOperation: WaterPipe;

  waterPipeSegmentForm: UntypedFormGroup;
  saveSubscription: Subscription;
  pipeSegment: WaterPipeSegment;

  constructor(
    private coreService: CoreService,
    private fb: UntypedFormBuilder,
    private svfb: SimVarFormBuilder,
    private flowsheetService: FlowsheetService,
    private simVarMapper: SimulationVariableMapper
  ) {
    this.saveSubscription = this.flowsheetService.prepareUnitOperationForSaveRequest.subscribe(() => {
      this.save();
      this.saveSubscription.unsubscribe();
    });
  }

  ngOnInit() {
    if (this.unitOperation.thermalCalculationMode === ThermalCalculationMode.ISOTHERMAL) {
      $('#selectThermalMode').val(ThermalCalculationMode.ISOTHERMAL);
    } else if (this.unitOperation.thermalCalculationMode === ThermalCalculationMode.THERMAL) {
      $('#selectThermalMode').val(ThermalCalculationMode.THERMAL);
    }

    this.waterPipeSegmentForm = this.fb.group({});
    this.initWaterPipeSegments(this.unitOperation.thermalCalculationMode);
  }

  addSegmentControlWhenOtherMode() {
    for (const pipeSegment of this.unitOperation.segments) {
      this.waterPipeSegmentForm.addControl(
        pipeSegment.id,
        this.fb.group({
          segmentLength: this.svfb.control(pipeSegment.segmentLength, false, false, false, [Validators.required]),
          insideDiameter: this.svfb.control(pipeSegment.insideDiameter, false, false, false, [Validators.required]),
          outsideDiameter: this.svfb.control(pipeSegment.outsideDiameter, false, false, false, [Validators.required]),
          segmentAngle: this.svfb.control(pipeSegment.segmentAngle, false, false, false, [Validators.required]),
          relativeRoughness: this.svfb.control(pipeSegment.relativeRoughness, false, false, false, [
            Validators.required,
          ]),
        })
      );
    }
  }

  private initWaterPipeSegments(calculationModeSelected: ThermalCalculationMode) {
    if (calculationModeSelected === ThermalCalculationMode.THERMAL) {
      for (const pipeSegment of this.unitOperation.segments) {
        this.waterPipeSegmentForm.addControl(
          pipeSegment.id,
          this.fb.group({
            segmentLength: this.svfb.control(pipeSegment.segmentLength, false, false, false, [Validators.required]),
            insideDiameter: this.svfb.control(pipeSegment.insideDiameter, false, false, false, [Validators.required]),
            outsideDiameter: this.svfb.control(pipeSegment.outsideDiameter, false, false, false, [Validators.required]),
            segmentAngle: this.svfb.control(pipeSegment.segmentAngle, false, false, false, [Validators.required]),
            relativeRoughness: this.svfb.control(pipeSegment.relativeRoughness, false, false, false, [
              Validators.required,
            ]),
            overallU: this.svfb.control(pipeSegment.overallU, false, false, false, [Validators.required]),
            inletAmbientTemperature: this.svfb.control(pipeSegment.inletAmbientTemperature, false, false, false, [
              Validators.required,
            ]),
            outletAmbientTemperature: this.svfb.control(pipeSegment.outletAmbientTemperature, false, false, false, [
              Validators.required,
            ]),
          })
        );
      }
    } else {
      this.addSegmentControlWhenOtherMode();
    }
  }

  public addWaterPipeSegmentFormGroup() {
    this.pipeSegment = new WaterPipeSegment({}, this.unitOperation.ownerCase);
    this.unitOperation.addWaterPipeSegments(this.pipeSegment);
    this.initWaterPipeSegments(this.unitOperation.thermalCalculationMode);
  }

  public removeWaterPipeSegment(segment: WaterPipeSegment) {
    this.unitOperation.removeWaterPipeSegment(segment);
    this.waterPipeSegmentForm.removeControl(segment.id);
    this.initWaterPipeSegments(this.unitOperation.thermalCalculationMode);
  }

  pipePressureModelChanged(pressureValue) {
    if (pressureValue === WaterPipePressureModel.HOMOGENEOUS) {
      this.unitOperation.pipePressureModel = WaterPipePressureModel.HOMOGENEOUS;
    }
  }

  thermalCalculationModeChanged(calculationValue) {
    if (calculationValue === ThermalCalculationMode.ISOTHERMAL) {
      this.unitOperation.thermalCalculationMode = ThermalCalculationMode.ISOTHERMAL;
      this.changeWaterPipeFormGroup(this.unitOperation.thermalCalculationMode);
    } else if (calculationValue === ThermalCalculationMode.THERMAL) {
      this.unitOperation.thermalCalculationMode = ThermalCalculationMode.THERMAL;
      this.changeWaterPipeFormGroup(this.unitOperation.thermalCalculationMode);
    } else {
      this.unitOperation.thermalCalculationMode = ThermalCalculationMode.ADIABATIC;
      this.changeWaterPipeFormGroup(this.unitOperation.thermalCalculationMode);
    }
  }

  changeWaterPipeFormGroup(thermalMode: ThermalCalculationMode) {
    for (const wps of this.unitOperation.segments) {
      this.waterPipeSegmentForm.removeControl(wps.id);
      if (thermalMode !== ThermalCalculationMode.THERMAL) {
        wps.inletAmbientTemperature.value = undefined;
        wps.outletAmbientTemperature.value = undefined;
        wps.overallU.value = undefined;
      }
      this.initWaterPipeSegments(thermalMode);
    }
  }

  getValueFieldsErrorValidations(id: string, formGroupName: string) {
    return (
      this.waterPipeSegmentForm.get(id).get(formGroupName).get('value').errors &&
      this.waterPipeSegmentForm.get(id).get(formGroupName).get('value').touched
    );
  }

  mapSegmentValuesWhenOtherMode() {
    for (const segment of this.unitOperation.segments) {
      let sv = this.waterPipeSegmentForm.get(segment.id).get('segmentLength').value;
      this.simVarMapper.map(sv, segment.segmentLength);

      sv = this.waterPipeSegmentForm.get(segment.id).get('insideDiameter').value;
      this.simVarMapper.map(sv, segment.insideDiameter);

      sv = this.waterPipeSegmentForm.get(segment.id).get('outsideDiameter').value;
      this.simVarMapper.map(sv, segment.outsideDiameter);

      sv = this.waterPipeSegmentForm.get(segment.id).get('segmentAngle').value;
      this.simVarMapper.map(sv, segment.segmentAngle);

      sv = this.waterPipeSegmentForm.get(segment.id).get('relativeRoughness').value;
      this.simVarMapper.map(sv, segment.relativeRoughness);
    }
  }

  mapSegmentValuesWhenThermalMode() {
    this.mapSegmentValuesWhenOtherMode();
    for (const segment of this.unitOperation.segments) {
      let sv = this.waterPipeSegmentForm.get(segment.id).get('overallU').value;
      this.simVarMapper.map(sv, segment.overallU);

      sv = this.waterPipeSegmentForm.get(segment.id).get('inletAmbientTemperature').value;
      this.simVarMapper.map(sv, segment.inletAmbientTemperature);

      sv = this.waterPipeSegmentForm.get(segment.id).get('outletAmbientTemperature').value;
      this.simVarMapper.map(sv, segment.outletAmbientTemperature);
    }
  }

  save() {
    if (this.unitOperation.thermalCalculationMode === ThermalCalculationMode.THERMAL) {
      this.mapSegmentValuesWhenThermalMode();
    } else {
      this.mapSegmentValuesWhenOtherMode();
    }
  }
}
