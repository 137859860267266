import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { environment } from '../../environments/environment';

@Injectable()
export class DownloadManualService {
  constructor(private http: HttpClient) {}

  download() {
    this.http.get(environment.manualUrl, { responseType: 'blob', observe: 'response' }).subscribe(
      data => {
        const fileName = /filename="(.*?)"/g.exec(data.headers.get('content-disposition'))[1];

        FileSaver.saveAs(new Blob([data.body], { type: 'application/pdf' }), fileName);
      },
      () => {}
    );
  }
}
