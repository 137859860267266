import { AfterViewInit, Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { LatestUserAccessService } from './_services/latest-user-access.service';
import { AuthService } from './_services/auth.service';

Highcharts.setOptions({ lang: { thousandsSep: ',' } });

@Component({
  selector: 'sob-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit {
  constructor(private latestUserAccessService: LatestUserAccessService, private authService: AuthService) {}

  ngAfterViewInit(): void {
    this.latestUserAccessService.beginRegisteringAccess();
  }
}
