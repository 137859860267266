import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { DistributionRatioVariable } from '../distribution-ratio-variable';
import { Case } from '../../case';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { SimulationVariable } from '../../simulation-variable';
import { Quantity } from '../../../_config/quantity.enum';
import { BaseWaterSplitter } from './base-water-splitter';

export class WaterHeader extends BaseWaterSplitter {
  category = unitOperationsConfig.waterHeader.key;

  pressureCalculationMode: PressureCalculationMode | string;
  outletPressure: SimulationVariable;

  override isOptimizable: boolean;
  override distributionRatioVariables: DistributionRatioVariable[] = [];

  constructor(unitOperation: WaterHeader | any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
  }

  override initValues(unitOperation: WaterHeader | any) {
    super.initValues(unitOperation);

    this.pressureCalculationMode = unitOperation.pressureCalculationMode || PressureCalculationMode.LOWEST;
    this.outletPressure = new SimulationVariable(unitOperation.outletPressure, this.ownerCase, this);
    this.setQuantities();
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.outletPressure.setName('Outlet Pressure');
  }

  override setQuantities() {
    super.setQuantities();
    this.outletPressure.setQuantity(Quantity.PRESSURE);
  }

  override dePersist(unitOperation: any) {
    super.dePersist(unitOperation);
    this.outletPressure = this.getSimulationVariableSafe(unitOperation.outletPressure);
    this.setQuantities();
    this.setDefaultValues();
  }
}
