<div
  class="modal fade"
  id="constraintViolationReportModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="summaryReportModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Constraint Violation Report</h4>
      </div>

      <div class="modal-body">
        <table class="table" id="constraintEntryTable" style="width: 100%">
          <thead>
            <tr>
              <th>Owner Operation Name</th>
              <th>Variable Name</th>
              <th>Constraint Violation Percent</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let entry of constraintViolationEntries">
              <td>{{ entry.ownerOperationName }}</td>
              <td>{{ entry.variableName }}</td>
              <td>{{ entry.constraintViolationPercent | number : '1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
