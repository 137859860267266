import { FuelGasSinkImport } from '../../../_unit-operations/fuel-gas/fuel-gas-sink-import';
import { SimulationVariable } from '../../../simulation-variable';
import { SimulationVariableName } from '../../../../_config/simulation-variable-name.enum';
import { ConstraintReportVariable } from '../constraint-report-variable';

export class FuelGasSinkImportConstraintReportVariableResolver {
  static create() {
    return new FuelGasSinkImportConstraintReportVariableResolver();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  addCurrentValueAndUnitToConstraintReportVariable(
    crv: ConstraintReportVariable,
    params: { uo: FuelGasSinkImport; simVar: SimulationVariable }
  ): void {
    const { uo, simVar } = params;

    const inletStreamList = uo.ownerCase.filterFuelGasMaterialStreams(s => {
      return s.outletUnitOperationId === uo.id;
    });

    if (!inletStreamList.length) {
      return;
    }

    if (simVar.name === SimulationVariableName.ENERGY_DEMAND) {
      crv.currentValue = inletStreamList[0].heatFlow.convertToDefaultUnit(true, false);
      crv.currentUnit = inletStreamList[0].heatFlow.getDefaultUnit();
      return;
    }

    throw new Error(`Unknown constraint: ${simVar.name}`);
  }
}
