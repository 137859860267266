import { SolveCanceller } from '../solve-canceller';
import { ParametricStudyService } from '../../_services/case-study/parametric-study.service';

export class SolveCaseStudyCanceller implements SolveCanceller {
  service: ParametricStudyService;

  constructor(service: ParametricStudyService) {
    this.service = service;
  }

  cancelSolve() {
    this.service.cancelSolve();
  }
}
