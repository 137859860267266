import { BaseStream } from './base-stream';
import { SuncorMaterialStream } from './suncor-material-stream';
import { Case } from '../case';
import { WaterMaterialStream } from './water-material-stream';
import { StreamCategory } from '../../_config/stream-category.enum';
import { FuelGasMaterialStream } from './fuel-gas-material-stream';

export class StreamFactory {
  public static create(streamData, ownerCase: Case): BaseStream {
    switch (streamData.category) {
      case StreamCategory.MATERIAL_STREAM:
      default:
        return new SuncorMaterialStream(streamData, ownerCase);

      case StreamCategory.WATER_MATERIAL_STREAM:
        return new WaterMaterialStream(streamData, ownerCase);

      case StreamCategory.FUEL_GAS_MATERIAL_STREAM:
        return new FuelGasMaterialStream(streamData, ownerCase);
    }
  }
}
