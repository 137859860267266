import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';

export class NumberToSimulationVariableMapper implements Mapper<number, SimulationVariable> {
  map(source: number, destination: SimulationVariable) {
    if (isNaN(source)) {
      destination.value = undefined;
      return;
    }

    const originalUnit = destination.unit;

    destination.value = source;
    destination.unit = destination.getDefaultUnit();

    destination.value = destination.convertToInternalUnit();
    destination.unit = originalUnit;
  }
}
