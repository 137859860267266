import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SendCaseModalService {
  private showSubject = new Subject();
  showRequest = this.showSubject.asObservable();

  show() {
    this.showSubject.next();
  }
}
