import { FlexSheetMapper } from './flex-sheet-mapper';
import { OffshoreSource } from '../../../_models/_unit-operations/offshore-source';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';
import { OffshoreSourceSheetRows } from '../cell-config/offshore-source-cell-config';

export class OffshoreSourceFlexSheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: OffshoreSource, row: number, value: string | number) {
    if (row === OffshoreSourceSheetRows.NAME && typeof value === 'string') {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (row) {
        case OffshoreSourceSheetRows.VOLUMETRIC_FLOW_RATE:
          this.simulationVariableMapper.map(value, unitOperation.volumetricFlowrate);
          break;

        case OffshoreSourceSheetRows.GHG_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.ghgIntensity);
          break;

        case OffshoreSourceSheetRows.COGEN_ELECTRICITY_GENERATION:
          this.simulationVariableMapper.map(value, unitOperation.cogenElectricityGeneration);
          break;

        case OffshoreSourceSheetRows.NET_ELECTRICITY_EXPORT:
          this.simulationVariableMapper.map(value, unitOperation.netElectricityExport);
          break;

        case OffshoreSourceSheetRows.COGEN_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.cogenIntensity);
          break;

        case OffshoreSourceSheetRows.GRID_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.gridIntensity);
          break;

        case OffshoreSourceSheetRows.PRICE:
          this.simulationVariableMapper.map(value, unitOperation.price);
          break;

        case OffshoreSourceSheetRows.CAPEX_AMORTIZED:
          this.simulationVariableMapper.map(value, unitOperation.capexAmortized);
          break;

        case OffshoreSourceSheetRows.OPEX_FIXED:
          this.simulationVariableMapper.map(value, unitOperation.opexFixed);
          break;

        case OffshoreSourceSheetRows.OPEX_VAR:
          this.simulationVariableMapper.map(value, unitOperation.opexVar);
          break;

        case OffshoreSourceSheetRows.OPEX_GAS_DIESEL:
          this.simulationVariableMapper.map(value, unitOperation.opexGasDiesel);
          break;

        case OffshoreSourceSheetRows.OPEX_POWER:
          this.simulationVariableMapper.map(value, unitOperation.opexPower);
          break;
      }
    }
  }
}
