export class ArrayDiff<T> {
  constructor(private baseArray: T[]) {}

  /**
   * Gets the difference between two arrays - something like right/left join in sql but without the intersection
   * @param against The array that baseArray will be compared against
   * @param compareFunction anonymous function to compare two items of each array - should return true if both items
   * are equal
   */
  diff<U>(against: U[], compareFunction: (l: T, r: U) => boolean): { left: T[]; right: U[]; intersection: T[] } {
    const leftResult = this.left(against, compareFunction);
    const rightResult = this.right(against, compareFunction);
    const intersection = this.intersection(against, compareFunction);
    return { left: leftResult, right: rightResult, intersection };
  }

  /**
   * Gets the left difference against the array passed by parameter
   * @param against The array that baseArray will be compared against
   * @param compareFunction anonymous function to compare two items of each array -
   * should return true if both items are equal
   */
  left<U>(against: U[], compareFunction: (l: T, r: U) => boolean): T[] {
    return this.baseArray.filter(l => {
      return against.findIndex(r => compareFunction(l, r)) === -1;
    });
  }

  /**
   * Gets the right difference against the array passed by parameter
   * @param against The array that baseArray will be compared against
   * @param compareFunction anonymous function to compare two items of each array - should return true if both items
   * are equal
   */
  right<U>(against: U[], compareFunction: (l: T, r: U) => boolean): U[] {
    return against.filter(r => {
      return this.baseArray.findIndex(l => compareFunction(l, r)) === -1;
    });
  }

  /**
   * Gets the intersection with the array passed by parameter
   * @param against The array that baseArray will be compared against
   * @param compareFunction anonymous function to compare two items of each array - should return true if both items
   * are equal
   */
  intersection<U>(against: U[], compareFunction: (l: T, r: U) => boolean): T[] {
    return this.baseArray.filter(l => against.findIndex(r => compareFunction(l, r)) >= 0);
  }
}
