<div class="modal fade" id="streamModal" tabindex="-1" role="dialog" aria-labelledby="streamModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Stream Information</h4>
      </div>
      <div class="modal-body" *ngIf="stream">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#streamGeneralTab" role="tab" data-toggle="tab" aria-controls="streamGeneralTab"> General </a>
          </li>

          <li role="presentation">
            <a href="#streamPropertiesTab" role="tab" data-toggle="tab" aria-controls="streamPropertiesTab">
              Properties
            </a>
          </li>
          <li role="presentation">
            <a href="#streamCompositionTab" role="tab" data-toggle="tab" aria-controls="streamCompositionTab">
              Composition
            </a>
          </li>
          <li role="presentation">
            <a href="#streamColorTab" role="tab" data-toggle="tab" aria-controls="streamColorTab"> Color </a>
          </li>
        </ul>

        <div class="tab-content pw-margin tab-content-padding">
          <div role="tabpanel" class="tab-pane fade in active" id="streamGeneralTab">
            <div class="form-horizontal">
              <div class="form-group" [formGroup]="streamGroup">
                <label for="streamName" class="control-label col-sm-4"> Name </label>

                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-6">
                      <input type="text" class="form-control" id="streamName" formControlName="name" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <span
                        class="text-danger"
                        *ngIf="nameControl.invalid && (nameControl.dirty || nameControl.touched)">
                        <span *ngIf="nameControl.errors.required">Please enter a name for the stream</span>
                        <span *ngIf="nameControl.errors.unique">Entered name is already in use</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="" class="pw-label control-label col-sm-4">From</label>
                <span style="padding-top: 7px; display: inline-block" class="col-sm-4">
                  {{ stream.inletUnitOperation.name }}
                </span>
              </div>

              <div class="form-group">
                <label for="" class="pw-label control-label col-sm-4">To</label>
                <span style="padding-top: 7px; display: inline-block" class="col-sm-4">
                  {{ stream.outletUnitOperation.name }}
                </span>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-volumetricFlowrate'" class="pw-label control-label col-sm-4">
                  Volumetric Flowrate
                </label>
                <sim-var-input
                  [inputId]="'stream-volumetricFlowrate'"
                  [simVar]="stream.volumetricFlowrate"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-massFlowrate'" class="pw-label control-label col-sm-4">
                  Mass Flowrate
                </label>
                <sim-var-input
                  [inputId]="'stream-massFlowrate'"
                  [simVar]="stream.massFlowrate"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-ghgEmissions'" class="pw-label control-label col-sm-4">
                  GHG Emissions
                </label>
                <sim-var-input
                  [inputId]="'stream-ghgEmissions'"
                  [simVar]="stream.ghgEmissions"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-ghgIntensity'" class="pw-label control-label col-sm-4">
                  GHG Intensity
                </label>
                <sim-var-input
                  [inputId]="'stream-ghgIntensity'"
                  [simVar]="stream.ghgIntensity"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-price'" class="pw-label control-label col-sm-4"> Price </label>
                <sim-var-input
                  [inputId]="'stream-price'"
                  [simVar]="stream.price"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="streamPropertiesTab">
            <ul class="nav nav-pills" role="tablist">
              <li role="presentation" class="active">
                <a href="#streamBulkPropertiesTab" role="tab" data-toggle="tab" aria-controls="streamBulkPropertiesTab">
                  Bulk Properties
                </a>
              </li>
            </ul>

            <div class="tab-content pw-margin tab-content-padding">
              <div role="tabpanel" class="tab-pane fade in active" id="streamBulkPropertiesTab">
                <div class="form-horizontal">
                  <div class="form-group">
                    <label [attr.for]="'stream-massDensity'" class="pw-label control-label col-sm-4">
                      Mass Density
                    </label>
                    <sim-var-input
                      [inputId]="'stream-massDensity'"
                      [simVar]="stream.massDensity"
                      [readOnly]="true"
                      [transientValue]="true"></sim-var-input>
                  </div>

                  <div class="form-group">
                    <label [attr.for]="'stream-ccr'" class="pw-label control-label col-sm-4"> CCR </label>
                    <sim-var-input
                      [inputId]="'stream-ccr'"
                      [simVar]="stream.ccr"
                      [readOnly]="true"
                      [transientValue]="true"></sim-var-input>
                  </div>

                  <div class="form-group">
                    <label [attr.for]="'stream-sulfur'" class="pw-label control-label col-sm-4"> Sulfur </label>
                    <sim-var-input
                      [inputId]="'stream-sulfur'"
                      [simVar]="stream.sulfur"
                      [readOnly]="true"
                      [transientValue]="true"></sim-var-input>
                  </div>

                  <div class="form-group">
                    <label [attr.for]="'stream-nitrogen'" class="pw-label control-label col-sm-4"> Nitrogen </label>
                    <sim-var-input
                      [inputId]="'stream-nitrogen'"
                      [simVar]="stream.nitrogen"
                      [readOnly]="true"
                      [transientValue]="true"></sim-var-input>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="streamCompositionTab">
            <div class="form-horizontal">
              <div class="form-group">
                <div class="btn-group" data-toggle="buttons">
                  <button
                    class="btn btn-primary {{ compositionTypeSelected === StreamCompositionType.MASS ? 'active' : '' }}"
                    (click)="compositionTypeChanged($event.target)"
                    name="{{ StreamCompositionType.MASS }}">
                    {{ StreamCompositionType.MASS }}
                  </button>
                  <button
                    class="btn btn-primary {{
                      compositionTypeSelected === StreamCompositionType.VOLUME ? 'active' : ''
                    }}"
                    (click)="compositionTypeChanged($event.target)"
                    name="{{ StreamCompositionType.VOLUME }}">
                    {{ StreamCompositionType.VOLUME }}
                  </button>
                  <button
                    class="btn btn-primary {{
                      compositionTypeSelected === StreamCompositionType.DISTILLATION ? 'active' : ''
                    }}"
                    (click)="compositionTypeChanged($event.target)"
                    name="{{ StreamCompositionType.DISTILLATION }}">
                    {{ StreamCompositionType.DISTILLATION }}
                  </button>
                </div>
              </div>

              <ng-container *ngIf="compositionTypeSelected === StreamCompositionType.MASS">
                <div class="form-horizontal" sfeMultipleSimVarInput totalOutputId="streamWeightCompositionTotal">
                  <div class="form-group">
                    <label [attr.for]="'stream-compositionUnit'" class="pw-label control-label col-sm-4"> Unit </label>

                    <div class="col-sm-4">
                      <sfe-unit-select
                        [selectId]="'stream-compositionUnit'"
                        [simVar]="stream.composition[0]"
                        [elClass]="'form-control'"></sfe-unit-select>
                    </div>
                  </div>

                  <ng-container *ngFor="let idx of stream.composition | keys">
                    <div class="form-group">
                      <label [attr.for]="'stream-composition' + idx" class="pw-label control-label col-sm-4">
                        {{ stream.composition[idx].name | titleCaseComposition }}
                      </label>
                      <div class="col-sm-4">
                        <sfe-value-input
                          [simVar]="stream.composition[idx]"
                          [elClass]="'form-control'"
                          [inputId]="'stream-composition' + idx"
                          [multiple]="true"
                          [convertedBy]="'stream-compositionUnit'"
                          [readOnlyValue]="true"
                          [disabled]="true">
                        </sfe-value-input>
                      </div>
                    </div>
                  </ng-container>

                  <div class="form-group">
                    <label class="pw-label control-label col-sm-4">Total</label>
                    <div class="col-sm-4">
                      <input type="text" class="form-control" id="streamWeightCompositionTotal" readonly />
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="compositionTypeSelected === StreamCompositionType.VOLUME">
                <div class="form-horizontal" sfeMultipleSimVarInput totalOutputId="streamVolumeCompositionTotal">
                  <div class="form-group">
                    <label [attr.for]="'stream-volumeCompositionUnit'" class="pw-label control-label col-sm-4">
                      Unit
                    </label>

                    <div class="col-sm-4">
                      <sfe-unit-select
                        [selectId]="'stream-volumeCompositionUnit'"
                        [simVar]="stream.volumeComposition[0]"
                        [elClass]="'form-control'"></sfe-unit-select>
                    </div>
                  </div>

                  <ng-container *ngFor="let idx of stream.volumeComposition | keys">
                    <div class="form-group">
                      <label [attr.for]="'stream-volumeComposition' + idx" class="pw-label control-label col-sm-4">
                        {{ stream.volumeComposition[idx].name | titleCaseComposition }}
                      </label>
                      <div class="col-sm-4">
                        <sfe-value-input
                          [simVar]="stream.volumeComposition[idx]"
                          [elClass]="'form-control'"
                          [inputId]="'stream-volumeComposition' + idx"
                          [multiple]="true"
                          [convertedBy]="'stream-volumeCompositionUnit'"
                          [readOnlyValue]="true"
                          [disabled]="true">
                        </sfe-value-input>
                      </div>
                    </div>
                  </ng-container>

                  <div class="form-group">
                    <label class="pw-label control-label col-sm-4">Total</label>
                    <div class="col-sm-4">
                      <input type="text" class="form-control" id="streamVolumeCompositionTotal" readonly />
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="compositionTypeSelected === StreamCompositionType.DISTILLATION">
                <div class="form-horizontal" sfeMultipleSimVarInput>
                  <div class="form-group">
                    <label [attr.for]="'stream-distillationCompositionUnit'" class="pw-label control-label col-sm-4">
                      Unit
                    </label>

                    <div class="col-sm-4">
                      <sfe-unit-select
                        [selectId]="'stream-distillationCompositionUnit'"
                        [simVar]="stream.distillationComposition[0]"
                        [elClass]="'form-control'"
                        [unitSubset]="UnitSubset.VOLUME_FRACTION_NO_PPM"></sfe-unit-select>
                    </div>
                  </div>

                  <ng-container *ngFor="let idx of stream.distillationComposition | keys">
                    <div class="form-group">
                      <label
                        [attr.for]="'stream-distillationComposition' + idx"
                        class="pw-label control-label col-sm-4">
                        {{ stream.distillationComposition[idx].name }}
                      </label>
                      <div class="col-sm-4">
                        <sfe-value-input
                          [simVar]="stream.distillationComposition[idx]"
                          [elClass]="'form-control'"
                          [inputId]="'stream-distillationComposition' + idx"
                          [multiple]="true"
                          [convertedBy]="'stream-distillationCompositionUnit'"
                          [readOnlyValue]="true"
                          [disabled]="true">
                        </sfe-value-input>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade" id="streamColorTab">
            <div class="form-horizontal">
              <div class="form-group">
                <label for="streamColor" class="pw-label control-label col-sm-4"> Color </label>
                <div class="col-sm-4">
                  <input
                    [(colorPicker)]="stream.streamColor"
                    [(ngModel)]="stream.streamColor"
                    class="form-control"
                    id="streamColor"
                    readonly="readonly" />
                </div>
              </div>

              <div class="form-group">
                <label class="control-label pw-label col-sm-4"> Preview </label>
                <div class="col-sm-4">
                  <span class="form-control" [style.backgroundColor]="stream.streamColor"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
