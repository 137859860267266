import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { WaterUnitOperation } from './water-unit-operation';
import { WaterHeatExchangerConfiguration } from '../../../_config/water-heat-exchanger-configuration.enum';
import { WaterHeatExchangerTemperatureCalculationMode } from '../../../_config/water-heat-exchanger-temperature-calculation-mode.enum';
import { SimulationVariable } from '../../simulation-variable';
import { Quantity } from '../../../_config/quantity.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

declare let unitConverter: any;

export class WaterHeatExchanger extends WaterUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.waterHeatExchanger.key;

  configuration: WaterHeatExchangerConfiguration | string;
  temperatureCalculationMode: WaterHeatExchangerTemperatureCalculationMode | string;
  outletTemperature: SimulationVariable;
  pressureDropTube: SimulationVariable;
  pressureDropShell: SimulationVariable;
  geometryFactor: SimulationVariable;

  duty: SimulationVariable;
  ua: SimulationVariable;

  uaResult: SimulationVariable;

  lmtd: SimulationVariable;

  constructor(unitOperation: any | WaterHeatExchanger, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.configuration = unitOperation.configuration || '';
    this.temperatureCalculationMode = unitOperation.temperatureCalculationMode || '';
    this.outletTemperature = new SimulationVariable(unitOperation.outletTemperature, this.ownerCase, this);
    this.pressureDropTube = new SimulationVariable(unitOperation.pressureDropTube, this.ownerCase, this);
    this.pressureDropShell = new SimulationVariable(unitOperation.pressureDropShell, this.ownerCase, this);
    this.geometryFactor = new SimulationVariable(unitOperation.geometryFactor, this.ownerCase, this);

    this.duty = new SimulationVariable(unitOperation.duty, this.ownerCase, this);
    this.ua = new SimulationVariable(unitOperation.ua, this.ownerCase, this);

    this.uaResult = new SimulationVariable(unitOperation.uaResult, this.ownerCase, this);

    this.lmtd = new SimulationVariable(unitOperation.lmtd, this.ownerCase, this);
  }

  override clearResults() {
    super.clearResults();
    this.duty.value = null;
    this.uaResult.value = null;
    this.lmtd.value = null;
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.outletTemperature.setName(SimulationVariableName.OUTLET_TEMPERATURE);
    this.pressureDropTube.setName(SimulationVariableName.PRESSURE_DROP_TUBE);
    this.pressureDropShell.setName(SimulationVariableName.PRESSURE_DROP_SHELL);
    this.geometryFactor.setName(SimulationVariableName.GEOMETRY_FACTOR);

    this.duty.setName(SimulationVariableName.DUTY);
    this.ua.setName(SimulationVariableName.UA);

    this.uaResult.setName(SimulationVariableName.UA_RESULT);

    this.lmtd.setName(SimulationVariableName.LMTD);
  }

  override setQuantities() {
    super.setQuantities();
    this.outletTemperature.setQuantity(Quantity.TEMPERATURE);
    this.pressureDropTube.setQuantity(Quantity.DELTA_PRESSURE);
    this.pressureDropShell.setQuantity(Quantity.DELTA_PRESSURE);
    this.geometryFactor.setQuantity(Quantity.DIMENSIONLESS);

    this.duty.setQuantity(Quantity.POWER);
    this.ua.setQuantity(Quantity.UA);

    this.uaResult.setQuantity(Quantity.UA);

    this.lmtd.setQuantity(Quantity.DELTA_TEMPERATURE);
  }

  override setDefaultValues() {
    super.setDefaultValues();
    if (this.configuration === '') {
      this.configuration = WaterHeatExchangerConfiguration.COUNTER_CURRENT;
    }
    if (this.temperatureCalculationMode === '') {
      this.temperatureCalculationMode = WaterHeatExchangerTemperatureCalculationMode.OUTLET_TEMPERATURE_TUBE;
    }
    if (this.outletTemperature.isUndefined()) {
      this.outletTemperature.setDefaultValue(null, unitConverter.units.Temperature.CELSIUS);
    }
    if (this.pressureDropTube.isUndefined()) {
      this.pressureDropTube.setDefaultValue(0.0);
    }
    if (this.pressureDropShell.isUndefined()) {
      this.pressureDropShell.setDefaultValue(0.0);
    }
    if (this.geometryFactor.isUndefined()) {
      this.geometryFactor.setDefaultValue(1.0);
    }
    if (this.ua.isUndefined()) {
      this.ua.setDefaultValue(null);
    }

    if (this.uaResult.isUndefined()) {
      this.uaResult.setDefaultValue(null);
    }
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [this.outletTemperature, this.duty, this.ua];
    }
    return [
      this.outletTemperature,
      this.pressureDropTube,
      this.pressureDropShell,
      this.geometryFactor,
      this.duty,
      this.ua,
      this.lmtd,
    ];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.pressureDropTube, this.pressureDropShell, this.geometryFactor];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);

    this.outletTemperature = this.getSimulationVariableSafe(sup.outletTemperature);
    this.pressureDropTube = this.getSimulationVariableSafe(sup.pressureDropTube);
    this.pressureDropShell = this.getSimulationVariableSafe(sup.pressureDropShell);
    this.geometryFactor = this.getSimulationVariableSafe(sup.geometryFactor);
    this.duty = this.getSimulationVariableSafe(sup.duty);
    this.ua = this.getSimulationVariableSafe(sup.ua);

    this.uaResult = this.getSimulationVariableSafe(sup.uaResult);

    this.lmtd = this.getSimulationVariableSafe(sup.lmtd);

    this.setQuantities();
    this.setDefaultValues();
  }
}
