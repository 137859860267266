import { Case } from '../../_models';
import { GhgEmissionsReport } from '../../_models/_reports/ghg-emissions-report';
import { ExcelReportUtils } from './excel-report.utils';

export class GhgExcelReport {
  static generateDataArray(ghgEmissionsReport: GhgEmissionsReport, c: Case, flowsheetOwnerId?: string) {
    const dataArray = [['Source', 'GHG Value (MMtpa)', 'Flowsheet Owner']];

    if (flowsheetOwnerId) {
      for (const upgraderSubFlowsheet of ghgEmissionsReport.contributionByUpgraderSubFlowsheet) {
        if (upgraderSubFlowsheet.id === flowsheetOwnerId) {
          upgraderSubFlowsheet.data.map(unitData => {
            dataArray.push([
              unitData.name,
              ExcelReportUtils.roundFormat(unitData.value, -2),
              upgraderSubFlowsheet.name,
            ]);
          });
        }
      }
    } else {
      for (const unit of ghgEmissionsReport.contributionByUnit) {
        unit.data.map(unitData => {
          dataArray.push([unitData.name, ExcelReportUtils.roundFormat(unitData.value, -2), '']);
        });
      }
    }

    return dataArray;
  }
}
