import { FormArray, FormControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { RangeDistributionRatioVariable } from '../_models/_unit-operations/range-distribution-ratio-variable';
import { IdentifiableFormGroup } from '../shared/identifiable-form-group';
import { isNullOrUndefined, roundNumber } from '../_utils/utils';
import { CustomValidators } from '../shared/custom-validators';

export type RangeDistributionRatioVariableForm = IdentifiableFormGroup<{
  minimumValue: FormControl<number>;
  maximumValue: FormControl<number>;
  value: FormControl<number>;
}>;

@Injectable({ providedIn: 'root' })
export class RangeDistributionRatioVariableFormBuilder {
  formArray(variablesArray: RangeDistributionRatioVariable[]): FormArray<RangeDistributionRatioVariableForm> {
    return new FormArray(variablesArray.map(this.control));
  }

  control(dv: RangeDistributionRatioVariable): RangeDistributionRatioVariableForm {
    const dvRoundedValue = isNullOrUndefined(dv.value) ? dv.value : roundNumber(dv.value, 4);

    return new IdentifiableFormGroup(dv.unitOperationId, {
      minimumValue: new FormControl(dv.minimumValue, [Validators.min(0), Validators.max(1)]),
      maximumValue: new FormControl(dv.maximumValue, [Validators.min(0), Validators.max(1)]),
      value: new FormControl(dvRoundedValue, [Validators.min(0), Validators.max(1)]),
    });
  }
}
