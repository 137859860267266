import { BaseObject } from '../base-object';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';

export class MultiPeriodParameter extends BaseObject {
  category = 'multiPeriodParameter';

  simulationVariable: SimulationVariable;

  multiPeriodValues: number[];

  constructor(parameter: MultiPeriodParameter | any, ownerCase: Case) {
    super(parameter.id, ownerCase);
    this.multiPeriodValues = parameter.multiPeriodValues || [];

    if (parameter.simulationVariable instanceof SimulationVariable) {
      this.simulationVariable = parameter.simulationVariable;
    }
  }

  getFullName(): string {
    return this.simulationVariable.getFullName();
  }

  getDefaultUnit(): string {
    return this.simulationVariable.getDefaultUnit();
  }

  override dePersist(persistedObj: any) {
    this.simulationVariable = this.ownerCase.getSimulationVariable(persistedObj.simulationVariable);
  }

  public toJSON() {
    return {
      id: this.id,
      category: this.category,
      simulationVariable: this.simulationVariable.id,
      multiPeriodValues: this.multiPeriodValues,
    };
  }
}

export function isMultiPeriodParameter(value: any): value is MultiPeriodParameter {
  return typeof value.getAvailableMultiPeriodParameters === 'function';
}
