import { Injectable } from '@angular/core';
import { AbstractSubFlowsheetPaletteHandler } from './abstract-sub-flowsheet-palette-handler';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { GoJsPaletteUtils } from './gojs-palette-utils';

@Injectable()
export class ExtractionPaletteHandler extends AbstractSubFlowsheetPaletteHandler {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(gojsPaletteUtils: GoJsPaletteUtils) {
    super(gojsPaletteUtils);
  }

  getPaletteModel(): any[] {
    return [
      {
        name: unitOperationsConfig.inletTransferUnit.displayName,
        icon: unitOperationsConfig.inletTransferUnit.paletteIcon,
        diagramIcon: unitOperationsConfig.inletTransferUnit.diagramIcon,
        category: unitOperationsConfig.inletTransferUnit.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.mixer.displayName,
        icon: unitOperationsConfig.mixer.paletteIcon,
        diagramIcon: unitOperationsConfig.mixer.diagramIcon,
        category: unitOperationsConfig.mixer.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.splitter.displayName,
        icon: unitOperationsConfig.splitter.paletteIcon,
        diagramIcon: unitOperationsConfig.splitter.diagramIcon,
        category: unitOperationsConfig.splitter.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.genericSink.displayName,
        icon: unitOperationsConfig.genericSink.paletteIcon,
        diagramIcon: unitOperationsConfig.genericSink.diagramIcon,
        category: unitOperationsConfig.genericSink.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.bitumenConversion.displayName,
        icon: unitOperationsConfig.bitumenConversion.paletteIcon,
        diagramIcon: unitOperationsConfig.bitumenConversion.diagramIcon,
        category: unitOperationsConfig.bitumenConversion.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.outletTransferUnit.displayName,
        icon: unitOperationsConfig.outletTransferUnit.paletteIcon,
        diagramIcon: unitOperationsConfig.outletTransferUnit.diagramIcon,
        category: unitOperationsConfig.outletTransferUnit.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }
}
