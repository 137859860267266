<div class="modal fade" id="caseStudyModal" tabindex="-1" role="dialog" aria-labelledby="reportsModalLabel">
  <div class="modal-lg modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Case Study</h4>
      </div>
      <div class="modal-body" *ngIf="caseStudyManager">
        <div class="row">
          <div class="col-md-6">
            <div class="form-inline">
              <div class="form-group">
                <label for="newParametricStudyName">New Case Study</label>
                <input
                  type="text"
                  class="form-control"
                  (change)="validateParametricStudyName($event.target)"
                  #newParametricStudyName
                  id="newParametricStudyName"
                  style="margin-left: 10px" />
                <button
                  (click)="addParametricStudy(newParametricStudyName)"
                  class="btn btn-primary"
                  title="Add selected parameter">
                  Add
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="invalidName">
            <div class="alert alert-warning name">Please add a name for the new Case Study.</div>
          </div>
        </div>

        <hr [style.display]="currentStudy ? 'block' : 'none'" class="mt-20 mb-20" />

        <div class="row" [style.display]="currentStudy ? 'block' : 'none'">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form">
                  <div class="form-group">
                    <select
                      name=""
                      id="currentStudyId"
                      [(ngModel)]="caseStudyManager.currentStudyId"
                      #studySelect
                      (change)="currentStudyChanged(studySelect.value)"
                      class="form-control">
                      <option *ngFor="let study of caseStudyManager.parametricStudies" value="{{ study.id }}">
                        {{ study.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <button class="btn btn-danger" (click)="removeParametricStudy()">Remove Case Study</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" [style.display]="currentStudy ? 'block' : 'none'">
          <div class="col-md-2" style="text-align: right; margin-top: 7px">
            <span><strong>Select Flowsheet: </strong></span>
          </div>
          <div class="col-md-4">
            <sob-flowsheet-tree-selector
              [modalName]="'caseStudyModal'"
              (emitFlowsheetOwnerId)="getFlowsheetOwnerId($event)"></sob-flowsheet-tree-selector>
          </div>
        </div>
        <br />

        <ul class="nav nav-tabs" *ngIf="currentStudy">
          <li class="active">
            <a href="#caseStudyTab" data-toggle="tab">Case Study</a>
          </li>

          <li>
            <a href="#caseStudyResultsTab" data-toggle="tab">Results</a>
          </li>
        </ul>

        <div class="tab-content" *ngIf="currentStudy">
          <div class="tab-pane fade in active" id="caseStudyTab">
            <div class="row" *ngIf="!caseStudyManager.kpiManager.selectedKpis.length">
              <div class="col-sm-12">
                <div class="alert alert-warning">
                  Warning! no KPIs selected. <a (click)="openKpiManager()">Go to KPI manager</a>
                </div>
              </div>
            </div>
            <sob-parametric-study
              [caseStudyManager]="caseStudyManager"
              [parametricStudy]="currentStudy"
              [currentlyFlowsheetOwnerId]="currentlyFlowsheetOwnerId">
            </sob-parametric-study>

            <div
              class="row"
              *ngIf="
                currentStudy.parameter &&
                currentStudy.parameter.variableValues.length &&
                caseStudyManager.kpiManager.selectedKpis.length
              ">
              <div class="col-sm-12 text-center">
                <button (click)="solveParametricStudyAsync()" class="btn btn-success">Run</button>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="caseStudyResultsTab">
            <sob-parametric-study-results
              *ngIf="currentStudy.kpiResults.length"
              [parametricStudy]="currentStudy"
              [kpiManager]="caseStudyManager.kpiManager">
            </sob-parametric-study-results>

            <div *ngIf="!currentStudy.kpiResults.length">No results available, please run the case study.</div>
          </div>
        </div>

        <div class="row" *ngIf="!currentStudy">
          <div class="col-md-12">
            <h4>Please add a case study</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
