import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';
import { WaterSinkImport } from '../_models/_unit-operations/utilties/water-sink-import';

export class WaterSinkImportFormChangeDetector extends DefaultBaseObjectFormChangeDetector {
  override detectChangesInValues(originalFormValue: any, currentValue: any): boolean {
    let changes = super.detectChangesInValues(originalFormValue, currentValue);
    const sourceStreamOriginal = originalFormValue as WaterSinkImport;
    const sourceStreamCurrent = currentValue as WaterSinkImport;

    if (
      sourceStreamCurrent.inletSourceInformationStreams.length !=
      sourceStreamOriginal.inletSourceInformationStreams.length
    ) {
      changes = changes || true;
    }

    return changes;
  }
}
