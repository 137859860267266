import * as wjcSheet from '@grapecity/wijmo.grid.sheet';

import { UnitOperationFlexSheetEventHandler } from './unit-operation-flex-sheet-event-handler';
import { ThirdPartySource } from '../../../_models/_unit-operations/third-party-source';
import { enumValuesToArray } from '../../../_utils/enum-values-to-array';
import { NumberToSimulationVariableMapper } from '../mappers/number-to-simulation-variable-mapper';
import { ThirdPartySourceCellConfig, ThirdPartySourceSheetRows } from '../cell-config/third-party-source-cell-config';
import { FlexSheetValueConverter } from '../flex-sheet-value-converter';
import { ThirdPartySourceFlexSheetMapper } from '../mappers/third-party-source-flex-sheet-mapper';

export class ThirdPartySourceFlexSheetEventHandler implements UnitOperationFlexSheetEventHandler {
  constructor(private valueConverter: FlexSheetValueConverter) {}

  handle(unitOperation: ThirdPartySource, spreadsheet: wjcSheet.FlexSheet, flexSheetEvent, rawValue) {
    const { row } = flexSheetEvent;

    if (enumValuesToArray(ThirdPartySourceSheetRows).indexOf(row) === -1) {
      flexSheetEvent.cancel = true;
      return;
    }

    const cellConfig = ThirdPartySourceCellConfig.find(rowConfig => rowConfig.row === row);
    const convertedValue = this.valueConverter.convert(spreadsheet, rawValue, cellConfig);

    const simulationVariableMapper = new NumberToSimulationVariableMapper();
    const mapper = new ThirdPartySourceFlexSheetMapper(simulationVariableMapper);

    mapper.map(unitOperation, row, convertedValue);
  }
}
