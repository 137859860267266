<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">
      Distribution Ratio
    </a>
  </li>

  <li role="presentation">
    <a href="#productTankComments" aria-controls="productTankComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="productType" class="pw-label control-label col-sm-4"> Product Type </label>
          <div class="col-sm-4" id="productType">
            <select class="form-control" formControlName="productType">
              <option value="" disabled>Select</option>
              <option value="productButane">Product Butane</option>
              <option value="productNaphtha">Product Naphtha</option>
              <option value="productKero">Product Kero</option>
              <option value="productGasOil">Product Gas Oil</option>
              <option value="virginKero">Virgin Kero</option>
              <option value="virginGasOil">Virgin Gas Oil</option>
              <option value="cokerNaphtha">Coker Naphtha</option>
              <option value="cokerKero">Coker Kero</option>
              <option value="cokerGasOil">Coker Gas Oil</option>
            </select>
          </div>
        </div>

        <ng-container>
          <div class="form-group" formGroupName="productVolume">
            <label class="control-label col-sm-4"> Producte Volume </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.productVolume.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.productVolume">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="inventory">
            <label class="control-label col-sm-4"> Inventory </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.inventory.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.inventory">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="form-group">
          <label for="isOptimizable" class="control-label col-sm-4"> Optimize </label>

          <div class="col-sm-4">
            <label for="" class="control-label">
              <input
                type="checkbox"
                id="isOptimizable"
                formControlName="isOptimizable"
                (change)="isOptimizableChanged($event.target.checked)" />
            </label>
          </div>
        </div>

        <div
          class="form-group"
          formArrayName="distributionRatioVariables"
          *ngFor="let dv of distributionRatioVariablesFormArray.controls; let i = index">
          <label [attr.for]="'distributionRatio' + i" class="pw-label control-label col-sm-4">
            {{ findDistributionRatioName(i) }}
          </label>

          <div class="col-sm-8" formGroupName="{{ i }}">
            <input
              type="text"
              class="form-control"
              formControlName="value"
              (change)="roundDistributionVariable(i)"
              [readonly]="i === distributionRatioVariablesFormArray.controls.length - 1" />
            <span
              class="text-danger"
              *ngIf="
                distributionRatioVariablesFormArray.invalid &&
                (distributionRatioVariablesFormArray.controls[i].dirty ||
                  distributionRatioVariablesFormArray.controls[i].touched)
              ">
              <span *ngIf="distributionRatioVariablesFormArray.controls[i].errors?.required">Please enter a value</span>
              <span *ngIf="distributionRatioVariablesFormArray.controls[i].errors?.max"
                >Distribution ratio cannot be greater than 1</span
              >
              <span *ngIf="distributionRatioVariablesFormArray.controls[i].errors?.min"
                >Distribution ratio cannot be negative</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="productTankComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
