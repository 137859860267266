export const environment = {
  production: true,
  apiBaseUrl: 'https://test.suncor-optea.com/api',
  apiUrl: 'https://test.suncor-optea.com/api/simulation',
  simulationAsync: 'https://test.suncor-optea.com/api/solveAsync',
  authUrl: 'https://test.suncor-optea.com/api/auth',
  usersUrl: 'https://test.suncor-optea.com/api/users',
  accountUrl: 'https://test.suncor-optea.com/api/account',
  sendCaseUrl: 'https://test.suncor-optea.com/api/sendCaseToPe',
  sendFrontendErrorDetailsToPe: 'https://test.suncor-optea.com/api/send-frontend-error-details-to-pe',
  uncertaintyUrl: 'https://test.suncor-optea.com/api/UncertaintyAnalysis',
  parametricStudyUrl: 'https://test.suncor-optea.com/api/ParametricStudy',
  parametricStudyAsyncUrl: 'https://test.suncor-optea.com/api/solveParametricStudyAsync',
  manualUrl: 'https://test.suncor-optea.com/api/downloadManual',
};
