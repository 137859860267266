import { IUnitOperation } from './i-unit-operation';
import { SimulationVariable } from './simulation-variable';
import { Case } from './case';
import { BaseObject } from './base-object';
import { SimulationVariableResourceData } from './_unit-operations/simulation-variable-resource-data';
import { findUnitOperationDisplayNameByCategory } from '../_config/unit-operations.config';
import { UnitOperationReadiness } from '../_config/unit-operations/unit-operation-readiness';

export abstract class UnitOperation extends BaseObject implements IUnitOperation {
  key: number; // damn GoJS
  flowsheetId: string; // the flowsheet where the unit op belongs
  readiness: UnitOperationReadiness;
  location: any = {};
  comments: string;

  protected constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation.id, ownerCase);
    this.key = unitOperation.key || '';
    this.name = unitOperation.name || '';
    this.flowsheetId = unitOperation.flowsheetId || '';

    // TODO undefined might not be needed when implemented frontend-based validation
    this.readiness = unitOperation.readiness || UnitOperationReadiness.UNDEFINED;

    if (unitOperation.location) {
      this.location.x = unitOperation.location.x;
      this.location.y = unitOperation.location.y;
    }

    this.comments = unitOperation.comments || '';
  }

  abstract setSimulationVariableNames();
  abstract initValues(unitOperation: any);

  // TODO should be abstract
  setQuantities() {}

  // TODO should be abstract
  setDefaultValues() {}

  clearResults() {
    const simVarsArray = this.getSimVars();
    for (const simVar of simVarsArray) {
      if (simVar.variableStatus && simVar.isSolverCalculated()) {
        simVar.value = undefined;
      }
    }
  }

  saveToGoJS(model: any): void {
    const propertiesToSave = this.toNonCircularObject();
    const nodeData = model.findNodeDataForKey(this.key);
    model.startTransaction('save');

    let property: any;
    // eslint-disable-next-line guard-for-in
    for (property in propertiesToSave) {
      model.setDataProperty(nodeData, property, propertiesToSave[property]);
    }

    model.commitTransaction('save');
  }

  protected override isPropertyBlackListed(property: any): boolean {
    return property instanceof Case || typeof property === 'function' || property instanceof UnitOperation;
  }

  ignoreForMakeReady(simvar: SimulationVariable): boolean {
    return false;
  }

  loadResourceData(resourceData) {
    if (resourceData) {
      Object.keys(resourceData).forEach(prop => {
        const simvar = this[prop] as SimulationVariable;
        const data = resourceData[prop] as SimulationVariableResourceData;
        if (simvar && data) {
          simvar.lowerBound = data.lowerBound;
          simvar.upperBound = data.upperBound;
        }
      });
    }
  }

  getCategoryDisplayName(): string {
    return findUnitOperationDisplayNameByCategory(this.category);
  }

  resetReadiness() {
    this.readiness = UnitOperationReadiness.UNDEFINED;
  }

  get isFlowsheetOwner() {
    return false;
  }

  // TODO: this should be at BaseObject class check if the isPropertyBlackListed affects.
  public toJSON(): any {
    const objectToSerialize: any = {};
    let val: any;
    for (val in this) {
      if (this.isPropertyBlackListed(this[val])) {
        continue;
      }

      // only simulation variable id will be serialized
      // maybe detect all base objects?
      if (this[val] instanceof SimulationVariable) {
        objectToSerialize[val] = (<SimulationVariable>this[val]).id;
      } else if (this.isPropertyBlackListed(this[val])) {
        // welp, nothing
      } else {
        objectToSerialize[val] = this[val];
      }
    }

    return objectToSerialize;
  }
}
