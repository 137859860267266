<div class="container-fluid" style="background: #f0f0f0; height: 100%">
  <span class="font-bold" style="font-size: 30px">
    Latest Access by User
    <span class="text-muted" style="font-size: 13px">This data is wiped out when the application stops</span>
  </span>

  <table class="table table-hover full-width">
    <thead>
      <tr>
        <th>Username</th>
        <th>Latest Access</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let latestAccess of latestAccessList">
        <td><i class="fa fa-user"></i> {{ latestAccess.username }}</td>
        <td>
          <span class="badge badge-primary">
            <i class="fa fa-clock"></i>
            {{ latestAccess.ago.toUpperCase() }}
          </span>
          <span class="badge badge-success m-l-xs">
            <i class="fa fa-calendar-alt"></i>
            {{ latestAccess.lastAccessDate }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
