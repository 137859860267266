export const DEFAULT_GAS_CONTRIBUTOR_MODEL =
  '// Variables available to user: \n' +
  '// "InletVolumetricFlowRate_KBPCD" : InletVolumetricFlowRate in kbpcd\n' +
  '//Methods Available to use\n' +
  '// GetUnitOpInletVolumetricFlowRate(string UnitOperationName) \n' +
  // eslint-disable-next-line max-len
  '// Gets the UnitOperationName as string and returns the Inlet Volumetric Flowrate to any operation in the flowsheet\n' +
  '// GetStreamVolumetricFlowRate(string MaterialStreamName) \n' +
  '// Gets the MaterialStreamName as string and returns the volumetric Flowrate of any stream in the flowsheet\n' +
  '// Calculated Variables:\n' +
  '// Fuel gas use ("GasUse_Energy") in BBtu/d \n' +
  '// Gas make ("GasMake_Flow") in MSCFD\n' +
  'GasUse_Energy = 0.0;\n' +
  'GasMake_Flow = 0.0;';
