import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as go from 'gojs';

export interface DiagramItemColorPickerInput {
  diagramItemKey: string | number;
  documentX: number;
  documentY: number;
  diagram: go.Diagram;
  fill: string;
}

@Injectable()
export class DiagramItemColorPickerService {
  private openSubject = new Subject<DiagramItemColorPickerInput>();
  open$ = this.openSubject.asObservable();

  open(params: DiagramItemColorPickerInput) {
    this.openSubject.next(params);
  }
}
