import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { AbstractSheetHandler } from './abstract-sheet-handler';
import { FlexSheetColumnTracker } from '../flex-sheet-column-tracker';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';

export class AssayGasesSheetHandler extends AbstractSheetHandler {
  readonly SHEET_INDEX = 1;

  constructor(spreadsheet: wjcSheet.FlexSheet, columnTracker: FlexSheetColumnTracker) {
    super();
    this.spreadsheet = spreadsheet;
    this.columnTracker = columnTracker;
  }

  protected getLabels(): string[] {
    return [
      '',
      'Name',
      'Type',
      '',
      'Composition (mol%)',
      'CO',
      'CO2',
      'H2',
      'N2',
      'H2S',
      'SO2',
      'Methane',
      'Ethane',
      'Propane',
      'IC4',
      'NC4',
      'IC5',
      'NC5',
      'C6+',
      '',
    ];
  }

  protected setData(assay: GasFluidAnalysis) {
    const dataArray = [
      '',
      assay.assayName,
      assay.assayType,
      '',
      '',
      this.formatSimulationVariable(assay.composition[0]),
      this.formatSimulationVariable(assay.composition[1]),
      this.formatSimulationVariable(assay.composition[2]),
      this.formatSimulationVariable(assay.composition[3]),
      this.formatSimulationVariable(assay.composition[4]),
      this.formatSimulationVariable(assay.composition[5]),
      this.formatSimulationVariable(assay.composition[6]),
      this.formatSimulationVariable(assay.composition[7]),
      this.formatSimulationVariable(assay.composition[8]),
      this.formatSimulationVariable(assay.composition[9]),
      this.formatSimulationVariable(assay.composition[10]),
      this.formatSimulationVariable(assay.composition[11]),
      this.formatSimulationVariable(assay.composition[12]),
      this.formatSimulationVariable(assay.composition[13]),
      '',
    ];

    const column = this.columnTracker.findAssayColumnIndex(assay);
    this.arrayToSpreadsheetColumn(dataArray, column);
  }

  isUnitOperationAllowed(assay: GasFluidAnalysis): boolean {
    return assay instanceof GasFluidAnalysis;
  }
}
