<ng-container [formGroup]="informationStreamFormGroup">
  <div class="form-group" formGroupName="massFlowrate">
    <label class="control-label col-sm-4"> Mass Flowrate </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="informationStream.massFlowrate.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="informationStream.massFlowrate">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="temperature">
    <label class="control-label col-sm-4"> Temperature </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="informationStream.temperature.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="informationStream.temperature">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="pressure">
    <label class="control-label col-sm-4"> Pressure </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="informationStream.pressure.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="informationStream.pressure">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="vaporFraction">
    <label class="control-label col-sm-4"> Vapor Fraction </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="informationStream.vaporFraction.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="informationStream.vaporFraction">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>
</ng-container>
