export const defaultDCUModel =
  '// GetStreamFlowRate (string MaterialStreamName, FlowrateBasis basis)\n' +
  '// Gets the MaterialStreamName and "FlowrateBasis" and return the flowrate\n' +
  '// Valid Options for FlowrateBasis: \n' +
  '// FlowrateBasis.Mass (returns in TPD)| FlowrateBasis.Volume (returns in kbpcd)\n' +
  '// GetStreamVolumetricFlowRate(string MaterialStreamName) \n' +
  '// Gets the MaterialStreamName as string and returns the volumetric Flowrate of any stream in the flowsheet\n' +
  '// GetStreamCutFlowRate(string streamName, SuncorCuts suncorCut, FlowrateBasis basis)\n' +
  '// Gets streamName, SuncorCut and Flowrate basis and returns the cut flowrate for the given stream\n ' +
  '// Valid options for SuncorCut:\n ' +
  '// SuncorCuts.LightEnd, SuncorCuts.LightNaphta, SuncorCuts.HeavyNaphta, \n' +
  '// SuncorCuts.Distillate, SuncorCuts.LightGasOil, SuncorCuts.HeavyGasOil, \n' +
  '// SuncorCuts.VacGasOil, SuncorCuts.HeavyVacGasOil, SuncorCuts.VacuumResidue \n' +
  'GasYield = 0.52 * (0.0591 * Inletccrpercent + 2.1372);\n' +
  'double naphtayieldwtpercent = (-0.0519 * Inletccrpercent * Inletccrpercent + 2.1168 * Inletccrpercent - 2.6873);\n' +
  'LightNaphtaYield = naphtayieldwtpercent * 0.891;\n' +
  'HeavyNaphtaYield = naphtayieldwtpercent * (1.0 - 0.891);\n' +
  'DistillateYield = 0.616 * (0.0346 * Inletccrpercent * Inletccrpercent - 2.3061 * Inletccrpercent + 53.62);\n' +
  '//Gas oil yield split equally between LGO, HGO, VGO, HVGO\n' +
  'GasOilYield = 0.906 * (0.0663 * Inletccrpercent * Inletccrpercent - 3.0023 * Inletccrpercent + 52.654);\n' +
  'CokeYield = (-0.0043 * Inletccrpercent * Inletccrpercent + 1.4772 * Inletccrpercent + 2.116);\n' +
  '\n' +
  ' \n' +
  '\n' +
  'double naphthaAPI = 0.0217 * Inletccrpercent + 55.018;\n' +
  'DistillateAPI = 0.1716 * Inletccrpercent + 25.121;\n' +
  '//All gas oil components (LGO, HGO, VGO, HVGO) are assumed to have the same Density\n' +
  'GasOilAPI = -0.295 * Inletccrpercent + 14.438;\n' +
  '                \n' +
  'LightNaphtaAPI = naphthaAPI;\n' +
  'HeavyNaphtaAPI = 0.93 * naphthaAPI;\n' +
  '                \n' +
  'double naphthaSulfur = 0.0026 * Inletccrpercent + 1.796;\n' +
  'DistillateSulfur = 0.0888 * Inletccrpercent + 1.7721;\n' +
  '//All gas oil components (LGO,. HGO, VGO, HVGO) are assumed to have the same sulfur content\n' +
  'GasOilSulfur = 0.1319 * Inletccrpercent + 2.1964;\n' +
  '                \n' +
  'LightNaphtaSulfur = naphthaSulfur * 0.91;\n' +
  'HeavyNaphtaSulfur = naphthaSulfur * 1.78;\n' +
  '\n' +
  ' \n' +
  '\n' +
  'double naphthaNitrogen = 3.8236 * Inletccrpercent + 82.464;\n' +
  'DistillateNitrogen = 28.481 * Inletccrpercent + 272.6;\n' +
  '//All gas oil components (LGO, HGO, VGO, HVGO) are assumed to have the same Nitrogen content\n' +
  'GasOilNitrogen = 44.724 * Inletccrpercent + 2931.7; \n' +
  '                \n' +
  'LightNaphtaNitrogen = naphthaNitrogen;\n' +
  'HeavyNaphtaNitrogen = 1.13 * naphthaNitrogen;';
