<label class="subtitle">Steam</label>
<a class="btn btn-outline btn-success btn-xs m-l-sm" href="#steamDiv" (click)="switchOpened()" aria-expanded="false">
  <i *ngIf="!isOpened" class="fa fa-plus"></i>
  <i *ngIf="isOpened" class="fa fa-minus"></i>
</a>

<div class="collapse" id="steamDiv" aria-expanded="false" [formGroup]="propertyWindowFormGroup">
  <div class="form-group">
    <label for="customModelEnabled" class="control-label col-sm-4"> Use Custom Model for Steam </label>
    <div class="col-sm-4">
      <label class="control-label">
        <input type="checkbox" formControlName="useSteamCustomModel" />
      </label>
    </div>

    <div class="clearfix"></div>
  </div>

  <div *ngIf="useSteam">
    <sob-open-reactive-code-editor-button
      [code]="unitOperation.steamModelScript"
      [defaultCodeProvider]="defaultCodeProvider"
      [instanceId]="'reactiveSteamCodeEditor'"
      [disabled]="!unitOperation.useSteamCustomModel"
      (codeSaved)="saveSteamCustomModel($event)">
    </sob-open-reactive-code-editor-button>
  </div>

  <hr class="m-t-md m-b-md" />

  <div class="form-group" formGroupName="steamMake900">
    <label class="control-label col-sm-4"> 900# Steam Make </label>
    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.steamMake900.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.steamMake900">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="steamUse900">
    <label class="control-label col-sm-4"> 900# Steam Use </label>
    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.steamUse900.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.steamUse900">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <hr style="padding-bottom: 10px" />

  <div class="form-group" formGroupName="steamMake600">
    <label class="control-label col-sm-4"> 600# Steam Make </label>
    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.steamMake600.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.steamMake600">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="steamUse600">
    <label class="control-label col-sm-4"> 600# Steam Use </label>
    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.steamUse600.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.steamUse600">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <hr style="padding-bottom: 10px" />

  <div class="form-group" formGroupName="steamMake150">
    <label class="control-label col-sm-4"> 150# Steam Make </label>
    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.steamMake150.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.steamMake150">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="steamUse150">
    <label class="control-label col-sm-4"> 150# Steam Use </label>
    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.steamUse150.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.steamUse150">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <hr style="padding-bottom: 10px" />

  <div class="form-group" formGroupName="steamMake50">
    <label class="control-label col-sm-4"> 50# Steam Make </label>
    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.steamMake50.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.steamMake50">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="steamUse50">
    <label class="control-label col-sm-4"> 50# Steam Use </label>
    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.steamUse50.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.steamUse50">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>
</div>
