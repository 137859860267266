import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { FlexSheetValueConverter } from '../flex-sheet-value-converter';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';
import { enumValuesToArray } from '../../../_utils/enum-values-to-array';
import { AssayGasesCellConfig, AssayGasesSheetRows } from '../cell-config/assay-gases-cell-config';
import { NumberToSimulationVariableMapper } from '../mappers/number-to-simulation-variable-mapper';
import { AssayGasesFlexsheetMapper } from '../mappers/assay-gases-flexsheet-mapper';

export class AssayGasesFlexsheetEventHandler {
  constructor(private valueConverter: FlexSheetValueConverter) {}

  handle(assay: GasFluidAnalysis, spreadsheet: wjcSheet.FlexSheet, flexsheetEvent, rawValue) {
    const { row } = flexsheetEvent;

    if (enumValuesToArray(AssayGasesSheetRows).indexOf(row) === -1) {
      flexsheetEvent.cancel = true;
      return;
    }

    const cellConfig = AssayGasesCellConfig.find(rowConfig => rowConfig.row === row);
    const convertedValue = this.valueConverter.convert(spreadsheet, rawValue, cellConfig);

    const simulationVariableMapper = new NumberToSimulationVariableMapper();
    const mapper = new AssayGasesFlexsheetMapper(simulationVariableMapper);

    mapper.map(assay, row, convertedValue);
  }
}
