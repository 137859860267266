/* eslint-disable no-console */
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'sob-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  model: any = {}; // <- dont to this crap
  loading = false;
  error: string;
  success: string;
  returnUrl: string;
  private sub: any;
  message;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService,
    // WAT
    private zone: NgZone,
    private _route: ActivatedRoute
  ) {
    this.sub = this.route.params.subscribe((params: Params) => {
      this.message = +params['code'];
      if (this.message === 1) {
        this.success =
          'Your account is activated successfully. You can now login to Suncor OPTEA using your credentials.';
      } else if (this.message === 0) {
        this.error = 'An error occurred in your last request';
      }
    });
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // reset login status
    // should I even reset it?
    if (this.authenticationService.isAuthenticated()) {
      this.router.navigate(['']);
    }
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.model.username, this.model.password).subscribe(
      () => {
        this.loading = false;
        // WHY YOU FUCKING PIECE OF SHIT
        this.zone.run(() => {
          this.router.navigate(['']);
        });
      },
      error => {
        this.loading = false;
        if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          this.error = `An error occurred: ${error.error.message}`;
          console.error('An error occurred:', error.error.message);
        } else if (error.status === 400) {
          console.error(error);
          this.error = 'Invalid user name and/or password.';
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error('An error occurred:');
          console.error(error);
          console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
        }
      }
    );
  }

  closeModal() {
    if (this.error) {
      this.error = undefined; // <- WHY
    }
  }
}
