import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { BaseObject } from '../base-object';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { DistributionRatioVariable } from './distribution-ratio-variable';
import { RangeDistributionRatioVariable } from './range-distribution-ratio-variable';
import { Quantity } from '../../_config/quantity.enum';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../_case-study/kpi-provider';
import { GasContributorUnitOperation } from './gas-contributor-unit-operation';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { BitumenModelTypes } from '../../_config/unit-operations/bitumen-conversion-enums';

declare let unitConverter: any;

export class Hydrotreater
  extends GasContributorUnitOperation
  implements ConstrainableObject, MultiPeriodParameterProvider, KpiProvider, ParameterProvider
{
  category = 'hydrotreater';

  separateLightEndsProduct: boolean;
  totalYield: SimulationVariable;
  powerUseRate: SimulationVariable;
  naturalGasUseRate: SimulationVariable;
  hydrogenUseRate: SimulationVariable;
  fluidAnalysisId: string;

  powerEmissionFactor: SimulationVariable;
  powerPrice: SimulationVariable;
  hydrogenEmissionFactor: SimulationVariable;
  hydrogenPrice: SimulationVariable;
  naturalGasEmissionFactor: SimulationVariable;
  maximumSulfurContent: SimulationVariable;
  maximumNitrogen: SimulationVariable;
  naturalGasPrice: SimulationVariable;
  hydrogenUse: SimulationVariable;
  modelType: BitumenModelTypes;
  constraints: UnitOperationConstraints;
  butaneRecovery: SimulationVariable;
  distributionRatioVariables: Array<RangeDistributionRatioVariable> = [];
  hydrogenUseRateVariables: Array<DistributionRatioVariable> = [];
  blendRecipeOption: string;
  feedSlateDisabled: boolean;
  h2sProductionRate: SimulationVariable;
  h2sProduction: SimulationVariable;

  // values for constraint results
  totalInletVolume: SimulationVariable;
  totalInletSulfur: SimulationVariable;
  totalInletNitrogen: SimulationVariable;

  constraintOption: string;
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [
        this.ghgEmissions,
        this.ghgIntensity,
        this.totalYield,
        this.opexTotal,
        this.capexTotal,
        this.hydrogenUseRate,
        this.h2sProductionRate,
        this.h2sProduction,
        this.powerUseRate,
        this.naturalGasUseRate,
      ];
    }
    return [
      this.ghgEmissions,
      this.ghgIntensity,
      this.totalYield,
      this.opexTotal,
      this.capexTotal,
      this.hydrogenUseRate,
      this.h2sProductionRate,
      this.h2sProduction,
      this.powerUseRate,
      this.naturalGasUseRate,
      this.steamMake600,
      this.steamUse600,
      this.steamUse900,
      this.steamMake900,
      this.steamMake150,
      this.steamUse150,
      this.steamMake50,
      this.steamUse50,
      this.fuelGasHeatingValue,
      this.fuelGasEmissionFactor,
      this.energyFlow,
      this.flowrate,
    ];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [
      this.totalYield,
      this.opexVar,
      this.opexFixed,
      this.capexAmortized,
      this.h2sProductionRate,
      this.powerUseRate,
      this.naturalGasUseRate,
    ];
  }

  override clearResults() {
    super.clearResults();
    this.hydrogenUse.value = null;
    this.h2sProduction.value = null;
  }

  protected override ignoreForOverWrite(propertyName: string): boolean {
    return super.ignoreForOverWrite(propertyName) || propertyName === 'totalInletSulfur';
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.totalYield = new SimulationVariable(unitOperation.totalYield, this.ownerCase, this);
    this.powerUseRate = new SimulationVariable(unitOperation.powerUseRate, this.ownerCase, this);
    this.naturalGasUseRate = new SimulationVariable(unitOperation.naturalGasUseRate, this.ownerCase, this);
    this.fluidAnalysisId = unitOperation.fluidAnalysisId || '';
    this.powerEmissionFactor = new SimulationVariable(unitOperation.powerEmissionFactor || {}, this.ownerCase, this);
    this.powerPrice = new SimulationVariable(unitOperation.powerPrice || {}, this.ownerCase, this);
    this.hydrogenEmissionFactor = new SimulationVariable(
      unitOperation.hydrogenEmissionFactor || {},
      this.ownerCase,
      this
    );
    this.hydrogenPrice = new SimulationVariable(unitOperation.hydrogenPrice || {}, this.ownerCase, this);
    this.naturalGasEmissionFactor = new SimulationVariable(
      unitOperation.naturalGasEmissionFactor || {},
      this.ownerCase,
      this
    );
    this.naturalGasPrice = new SimulationVariable(unitOperation.naturalGasPrice || {}, this.ownerCase, this);
    this.hydrogenUse = new SimulationVariable(unitOperation.hydrogenUse || {}, this.ownerCase, this);
    this.hydrogenUseRate = new SimulationVariable(unitOperation.hydrogenUseRate || {}, this.ownerCase, this);
    this.maximumSulfurContent = new SimulationVariable(unitOperation.maximumSulfurContent, this.ownerCase, this);
    this.maximumNitrogen = new SimulationVariable(unitOperation.maximumNitrogen, this.ownerCase, this);
    this.butaneRecovery = new SimulationVariable(unitOperation.butaneRecovery, this.ownerCase, this);
    this.modelType = unitOperation.modelType || BitumenModelTypes.UTILITY;
    this.separateLightEndsProduct = unitOperation.separateLightEndsProduct;
    this.totalInletVolume = new SimulationVariable(unitOperation.totalInletVolume, this.ownerCase, this);
    this.totalInletSulfur = new SimulationVariable(unitOperation.totalInletSulfur, this.ownerCase, this);
    this.totalInletNitrogen = new SimulationVariable(unitOperation.totalInletNitrogen, this.ownerCase, this);
    this.h2sProductionRate = new SimulationVariable(unitOperation.h2sProductionRate || {}, this.ownerCase, this);
    this.h2sProduction = new SimulationVariable(unitOperation.h2sProduction || {}, this.ownerCase, this);
    this.constraintOption = unitOperation.constraintOption;
    if (unitOperation.distributionRatioVariables instanceof Array) {
      for (const dv of unitOperation.distributionRatioVariables) {
        this.distributionRatioVariables.push(new RangeDistributionRatioVariable(dv));
      }
    }
    if (unitOperation.hydrogenUseRateVariables instanceof Array) {
      for (const dv of unitOperation.hydrogenUseRateVariables) {
        this.hydrogenUseRateVariables.push(new DistributionRatioVariable(dv.unitOperationId, dv.value));
      }
    }
    if (typeof unitOperation.blendRecipeOption === 'undefined') {
      this.blendRecipeOption = 'exact';
    } else {
      this.blendRecipeOption = unitOperation.blendRecipeOption;
    }
    if (typeof unitOperation.feedSlateDisabled === 'undefined') {
      this.feedSlateDisabled = false;
    } else {
      this.feedSlateDisabled = !!unitOperation.feedSlateDisabled;
    }
    this.initConstraints();
  }

  override addSimVarsToPool() {
    super.addSimVarsToPool();
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
    const ds = another as Hydrotreater;
    for (let i = 0; i < ds.distributionRatioVariables.length; i++) {
      this.distributionRatioVariables[i].value = ds.distributionRatioVariables[i].value;
      this.distributionRatioVariables[i].minimumValue = ds.distributionRatioVariables[i].minimumValue;
      this.distributionRatioVariables[i].maximumValue = ds.distributionRatioVariables[i].maximumValue;
    }
    for (let i = 0; i < ds.hydrogenUseRateVariables.length; i++) {
      this.hydrogenUseRateVariables[i].value = ds.hydrogenUseRateVariables[i].value;
    }
  }

  override setSimulationVariablesOwner() {
    super.setSimulationVariablesOwner();
  }

  override setSimulationVariableNames(): void {
    super.setSimulationVariableNames();
    this.totalYield.setName('Volumetric Yield');
    this.powerUseRate.setName('Power Use Rate');
    this.opexTotal.setName('Total OPEX');
    this.capexTotal.setName('Total CAPEX');
    this.hydrogenUseRate.setName('Hydrogen Use Rate');
    this.h2sProductionRate.setName('H2S Production Rate');
    this.h2sProduction.setName('H2S Production');
    this.naturalGasUseRate.setName('Natural Gas Use Rate');
    this.steamMake900.setName('#900 Steam Make');
    this.steamUse900.setName('#900 Steam Use');
    this.steamMake600.setName('#600 Steam Make');
    this.steamUse600.setName('#600 Steam Use');
    this.steamMake150.setName('#150 Steam Make');
    this.steamUse150.setName('#150 Steam Use');
    this.steamMake50.setName('#50 Steam Make');
    this.steamUse50.setName('#50 Steam Use');
    this.maximumNitrogen.setName(SimulationVariableName.MAXIMUM_NITROGEN);
    this.maximumSulfurContent.setName(SimulationVariableName.MAXIMUM_SULFUR_CONTENT);
    this.totalInletVolume.setName('Total inlet volume');
    this.totalInletSulfur.setName('Total inlet sulfur');
    this.totalInletNitrogen.setName('Total inlet nitrogen');
    this.capacity.setName(SimulationVariableName.CAPACITY);
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.energyFlow.setName(SimulationVariableName.ENERGY_FLOW);
    this.flowrate.setName(SimulationVariableName.FUEL_GAS_FLOWRATE);
  }

  addDistributionVariable(unitOperationId: string) {
    this.emptyDistributionValues();
    this.distributionRatioVariables.push(new RangeDistributionRatioVariable({ unitOperationId }));
    this.hydrogenUseRateVariables.push(new DistributionRatioVariable(unitOperationId, null));

    if (this.distributionRatioVariables.length === 1) {
      this.distributionRatioVariables[0].value = 1;
    }
  }

  emptyDistributionValues() {
    for (const dv of this.distributionRatioVariables) {
      dv.value = null;
    }
  }

  findDistributionRatioVariable(unitOperationId: string) {
    return this.distributionRatioVariables.find(drv => {
      return drv.unitOperationId === unitOperationId;
    });
  }

  findHydrogenUseRateVariable(unitOperationId: string) {
    return this.hydrogenUseRateVariables.find(drv => {
      return drv.unitOperationId === unitOperationId;
    });
  }

  findDistributionRatioVariableName(i: number) {
    const dv = this.distributionRatioVariables[i];

    if (dv) {
      const uo = this.ownerCase.getUnitOperation(dv.unitOperationId);
      return uo ? uo.name : null;
    }

    return null;
  }

  removeDistributionRatioVariable(unitOperationId: string) {
    const index = this.distributionRatioVariables.indexOf(this.findDistributionRatioVariable(unitOperationId));

    if (index >= 0) {
      this.distributionRatioVariables.splice(index, 1);
      this.hydrogenUseRateVariables.splice(index, 1);
    }
  }

  override setDefaultValues(): void {
    if (this.totalYield.isUndefined()) {
      this.totalYield.setDefaultValue(90.0);
    }

    if (this.naturalGasUseRate.isUndefined()) {
      this.naturalGasUseRate.setDefaultValue(undefined, unitConverter.units.EnergyPerVolume.MJ_M3);
    }

    if (this.hydrogenEmissionFactor.isUndefined()) {
      this.hydrogenEmissionFactor.setDefaultValue(8500, unitConverter.units.MassPerStdH2Volume.KGCO2E_tonne);
    }

    if (this.hydrogenPrice.isUndefined()) {
      this.hydrogenPrice.setDefaultValue(1600, unitConverter.units.CurrencyPerStdH2Volume.CAD_tonne);
    }

    if (this.powerEmissionFactor.isUndefined()) {
      this.powerEmissionFactor.setDefaultValue(0.5, unitConverter.units.MassPerEnergy.KG_KWH);
    }

    if (this.powerPrice.isUndefined()) {
      this.powerPrice.setDefaultValue(40, unitConverter.units.CurrencyPerElectricity.CAD_MEGAWH);
    }

    if (this.naturalGasEmissionFactor.isUndefined()) {
      this.naturalGasEmissionFactor.setDefaultValue(55.32);
    }

    if (this.naturalGasPrice.isUndefined()) {
      this.naturalGasPrice.setDefaultValue(4.28);
    }

    if (this.butaneRecovery.isUndefined()) {
      this.butaneRecovery.setDefaultValue(18);
    }

    if (!this.constraintOption) {
      this.constraintOption = 'blendRatio';
    }

    if (this.energyFlow.isUndefined()) {
      this.energyFlow.setDefaultValue(0.0, unitConverter.units.Energyrate.GJ_H);
    }

    super.setDefaultValues();
  }

  override setQuantities() {
    super.setQuantities();
    this.totalYield.setQuantity('Percent');
    this.naturalGasUseRate.setQuantity('EnergyPerVolume');
    this.powerUseRate.setQuantity('PowerPerVolumetricFlow');
    this.totalInletVolume.setQuantity('Volumetricflowrate');
    this.totalInletSulfur.setQuantity(Quantity.MASS_FRACTION);
    this.totalInletNitrogen.setQuantity(Quantity.MASS_FRACTION);
    this.powerEmissionFactor.setQuantity('MassPerEnergy');
    this.powerPrice.setQuantity('CurrencyPerElectricity');
    this.hydrogenEmissionFactor.setQuantity('MassPerStdH2Volume');
    this.hydrogenPrice.setQuantity('CurrencyPerStdH2Volume');
    this.naturalGasPrice.setQuantity('CurrencyPerEnergy');
    this.hydrogenUse.setQuantity('Moleflowrate');
    this.hydrogenUseRate.setQuantity(Quantity.GAS_STD_VOLUME_PER_VOLUME);
    this.naturalGasEmissionFactor.setQuantity('MassPerEnergy');
    this.maximumSulfurContent.setQuantity(Quantity.MASS_FRACTION);
    this.maximumNitrogen.setQuantity(Quantity.MASS_FRACTION);
    this.butaneRecovery.setQuantity('Percent');
    this.h2sProduction.setQuantity('Moleflowrate');
    this.h2sProductionRate.setQuantity('GasStdVolumePerVolume');
  }

  override convertToInternalUnit() {
    super.convertToInternalUnit();
  }

  override detectChanges(another: Hydrotreater) {
    return super.detectChanges(another);
  }

  override getChildrenObjectsForRemoval() {
    return super.getChildrenObjectsForRemoval();
  }

  override updateStatus(another: Hydrotreater) {
    this.updateSimulationVariablesStatus(another);
  }

  getAlternativeGhgIntensity() {
    return new SimulationVariable(this.ghgIntensity, this.ownerCase, this);
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.totalYield = this.getSimulationVariableSafe(sup.totalYield);
    this.naturalGasUseRate = this.getSimulationVariableSafe(sup.naturalGasUseRate);
    this.hydrogenUseRate = this.getSimulationVariableSafe(sup.hydrogenUseRate);
    this.powerUseRate = this.getSimulationVariableSafe(sup.powerUseRate);

    this.powerPrice = this.getSimulationVariableSafe(sup.powerPrice);
    this.powerEmissionFactor = this.getSimulationVariableSafe(sup.powerEmissionFactor);
    this.naturalGasPrice = this.getSimulationVariableSafe(sup.naturalGasPrice);
    this.hydrogenUse = this.getSimulationVariableSafe(sup.hydrogenUse);
    this.naturalGasEmissionFactor = this.getSimulationVariableSafe(sup.naturalGasEmissionFactor);
    this.hydrogenPrice = this.getSimulationVariableSafe(sup.hydrogenPrice);
    this.hydrogenEmissionFactor = this.getSimulationVariableSafe(sup.hydrogenEmissionFactor);
    this.maximumNitrogen = this.getSimulationVariableSafe(sup.maximumNitrogen);
    this.maximumSulfurContent = this.getSimulationVariableSafe(sup.maximumSulfurContent);
    this.butaneRecovery = this.getSimulationVariableSafe(sup.butaneRecovery);
    this.totalInletVolume = this.getSimulationVariableSafe(sup.totalInletVolume);
    this.totalInletSulfur = this.getSimulationVariableSafe(sup.totalInletSulfur);
    this.totalInletNitrogen = this.getSimulationVariableSafe(sup.totalInletNitrogen);
    this.h2sProduction = this.getSimulationVariableSafe(sup.h2sProduction);
    this.h2sProductionRate = this.getSimulationVariableSafe(sup.h2sProductionRate);
    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
    this.constraints.addConstraint('minimumFlow', this.minimumFlow);
    this.constraints.addConstraint('maximumSulfurContent', this.maximumSulfurContent);
    this.constraints.addConstraint('maximumNitrogen', this.maximumNitrogen);
  }

  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];
    return (
      inletStream &&
      (this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value) ||
        this.constraints.constraintValueViolated('maximumSulfurContent', inletStream.sulfur.value) ||
        this.constraints.constraintValueViolated('maximumNitrogen', inletStream.nitrogen.value))
    );
  }

  getAvailableParameters() {
    const params = [this.totalYield, this.ghgIntensity, this.opexFixed, this.opexVar];
    if (this.modelType === BitumenModelTypes.UTILITY) {
      params.push(this.powerUseRate, this.naturalGasUseRate);
    }
    return params;
  }

  constraintValueDefined() {
    return this.constraints.anyConstraintValueDefined();
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.capacity];
  }
}
