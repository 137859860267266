import { Injectable } from '@angular/core';
import { Case } from '../../../_models/case';
import { UnitOperation } from '../../../_models/unit-operation';
import { FlowsheetTreeNode } from '../../../_models/flowsheet-manager/flowsheet-tree-node';
import { FlowsheetTree } from '../../../_models/flowsheet-manager/flowsheet-tree';

// WHY having this import causes errors when running a single unit test...
import { Upgrader } from '../../../_models/_unit-operations/upgrader';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';

@Injectable()
export class FlowsheetTreeService {
  getFlowsheetTree(c: Case) {
    const tree = new FlowsheetTree();
    const allFlowsheetOwners = this.getAllFlowsheetOwners(c);
    this.addChildren(tree.root, allFlowsheetOwners);

    return tree;
  }

  getFlowsheetTreeIncludeNormalUpgraders(c: Case) {
    const tree = new FlowsheetTree();
    const allFlowsheetOwners = this.getAllFlowsheetOwnersIncludeNormalUpgraders(c);
    this.addChildren(tree.root, allFlowsheetOwners);

    return tree;
  }

  private addChildren(node: FlowsheetTreeNode, allFlowsheetOwners: UnitOperation[]) {
    const children = allFlowsheetOwners.filter(uo => uo.flowsheetId === node.id);

    for (const child of children) {
      const childNode = new FlowsheetTreeNode(child);
      node.addChild(childNode);

      this.addChildren(childNode, allFlowsheetOwners);
    }
  }

  getAllFlowsheetOwners(c: Case) {
    return c.filterUnitOperations(uo => uo.isFlowsheetOwner);
  }

  private getAllFlowsheetOwnersIncludeNormalUpgraders(c: Case) {
    return c.filterUnitOperations(uo => uo.isFlowsheetOwner || uo instanceof Upgrader);
  }

  getFlowsheetOwnerIdByName(flowsheetName: string, c: Case) {
    let flowsheetOwnerId;
    const flowsheetTree = this.getFlowsheetTree(c).root;

    flowsheetTree.children.forEach(parentNode => {
      if (parentNode.name === flowsheetName) {
        flowsheetOwnerId = parentNode.id;
      } else {
        parentNode.children.forEach(childNode => {
          if (childNode.name === flowsheetName) {
            flowsheetOwnerId = childNode.id;
          }
        });
      }
    });

    return flowsheetOwnerId;
  }

  getFlowsheetOwnersCount(c: Case): number {
    let flowsheetOwnersCount = 0;
    const flowsheetTree = this.getFlowsheetTree(c).root;
    flowsheetTree.children.forEach(pNode => {
      if (
        pNode.unitOperation.category === unitOperationsConfig.upgrader.key ||
        pNode.unitOperation.category === unitOperationsConfig.extraction.key
      ) {
        flowsheetOwnersCount++;
      }
    });
    return flowsheetOwnersCount;
  }

  /**
   * Gets all the upgraders with sub flowsheet
   * @param c The owner case
   * @param flowsheetId A unit operation flowhseetId to find the upgrader parent
   */
  getUpgradersWithSubFlowsheet(c: Case, flowsheetId?: string): Upgrader[] {
    const upgraders = this.getAllFlowsheetOwners(c).filter(uo => {
      return uo instanceof Upgrader;
    }) as Upgrader[];

    if (flowsheetId) {
      return upgraders.filter(upg => {
        return upg.id === flowsheetId;
      });
    }
    return upgraders;
  }
}
