import { FuelGasUnitOperation } from './fuel-gas-unit-operation';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { isTypeUndefined } from '../../../_utils/utils';
import { FuelGasSourceInformationStream } from './fuel-gas-source-information-stream';
import { BaseObject } from '../../base-object';
import { SimulationVariable } from '../../simulation-variable';
import { FuelGasMaterialStream } from '../../_streams/fuel-gas-material-stream';
import { KpiProvider } from '../../_case-study/kpi-provider';

export class FuelGasSourceImport extends FuelGasUnitOperation implements KpiProvider {
  category = unitOperationsConfig.fuelGasSourceImport.key;
  inletSourceInformationStreams: FuelGasSourceInformationStream[];

  constructor(unitOperation: any | FuelGasSourceImport, ownerCase: Case) {
    super(unitOperation, ownerCase);

    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any | FuelGasSourceImport) {
    super.initValues(unitOperation);

    this.inletSourceInformationStreams = [];

    if (
      !isTypeUndefined(unitOperation.inletSourceInformationStreams) &&
      unitOperation.inletSourceInformationStreams instanceof Array
    ) {
      // could be an array of strings
      const informationStream = unitOperation.inletSourceInformationStreams.length
        ? unitOperation.inletSourceInformationStreams[0]
        : undefined;

      // the copies and the original will share the reference to inletSourceInformationStreams array
      // as the copies only make sense for property windows that use template-driven forms
      if (informationStream instanceof FuelGasSourceInformationStream) {
        this.inletSourceInformationStreams = unitOperation.inletSourceInformationStreams;
      }
    }
  }

  override setQuantities() {}

  override setSimulationVariableNames() {}

  addInformationStream(informationStream: FuelGasSourceInformationStream) {
    const index = this.inletSourceInformationStreams.findIndex(
      is => is.providerUnitOperationId === informationStream.providerUnitOperationId
    );

    if (index > -1) {
      return;
    }

    if (!informationStream.providerUnitOperationId) {
      throw new Error('Unable to set providerUnitOperationId for information Stream');
    }

    this.inletSourceInformationStreams.push(informationStream);
    this.ownerCase.addToPools(informationStream);
    informationStream.addSimVarsToPool();
  }

  removeInformationStream(providerUnitOperationId: string) {
    const index = this.inletSourceInformationStreams.findIndex(
      i => i.providerUnitOperationId === providerUnitOperationId
    );
    if (index === -1) {
      return;
    }

    const informationStream = this.inletSourceInformationStreams.splice(index, 1)[0];
    this.ownerCase.removeFromPools(informationStream);
  }

  override getChildrenObjectsForRemoval(): BaseObject[] {
    const objs = super.getChildrenObjectsForRemoval();
    objs.push(...this.inletSourceInformationStreams);
    return objs;
  }

  protected override isPropertyBlackListed(property: any): boolean {
    return super.isPropertyBlackListed(property) || property === this.inletSourceInformationStreams;
  }

  getStreamComingOutOfTheUnitOp() {
    return this.ownerCase.getStreamsByInletUnitOperationId(this.id) as FuelGasMaterialStream[];
  }

  getAvailableKpis(): SimulationVariable[] {
    if (!this.ownerCase.multiPeriodEnabled) {
      return [this.getStreamComingOutOfTheUnitOp()[0].flowrate];
    }
    return [];
  }

  override dePersist(persistedObj: any) {
    super.dePersist(persistedObj);
    this.setQuantities();
  }

  dePersistInformationStreams(persistedObj: any) {
    persistedObj.inletSourceInformationStreams.forEach(isId => {
      this.inletSourceInformationStreams.push(
        this.ownerCase.getOtherBaseObject(isId) as FuelGasSourceInformationStream
      );
    });
  }

  override toJSON(): any {
    const plainObject = super.toJSON();
    plainObject.inletSourceInformationStreams = this.inletSourceInformationStreams.map(i => i.id);

    return plainObject;
  }
}
