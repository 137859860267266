import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { WaterTurbine } from '../../../_models/_unit-operations/utilties/water-turbine';
import { SimulationVariable } from '../../../_models/simulation-variable';
import { isEmptyValue } from '../../../_utils/utils';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';

@Component({
  selector: 'sob-water-turbine',
  templateUrl: './water-turbine.component.html',
  styleUrls: ['./water-turbine.component.css'],
})
export class WaterTurbineComponent implements OnInit {
  @Input() unitOperation: WaterTurbine;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  PressureCalculationMode = PressureCalculationMode;
  capacityStatus = false;
  flowrateStatus = false;
  minDutyStatus = false;
  maxDutyStatus = false;

  constructor(private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit(): void {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
    $(document).ready(() => {
      this.grayOutFields();
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    $(document).ready(() => {
      this.grayOutFields();
    });
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl(
      'pressureCalculationMode',
      this.fb.control(this.unitOperation.pressureCalculationMode)
    );

    this.propertyWindowFormGroup.addControl('pressureDrop', this.svfb.control(this.unitOperation.pressureDrop));
    this.propertyWindowFormGroup.addControl('outletPressure', this.svfb.control(this.unitOperation.outletPressure));
    this.propertyWindowFormGroup.addControl('duty', this.svfb.control(this.unitOperation.duty));
    this.propertyWindowFormGroup.addControl(
      'isentropicEfficiency',
      this.svfb.control(this.unitOperation.isentropicEfficiency)
    );

    this.propertyWindowFormGroup.addControl('dutyResult', this.svfb.control(this.unitOperation.dutyResult));
    this.propertyWindowFormGroup.addControl('pressureRatio', this.svfb.control(this.unitOperation.pressureRatio));
    this.propertyWindowFormGroup.addControl(
      'polytropicEfficiency',
      this.svfb.control(this.unitOperation.polytropicEfficiency)
    );

    this.propertyWindowFormGroup.addControl(
      'massCapacity',
      this.svfb.control(this.unitOperation.massCapacity, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'minimumMassFlowrate',
      this.svfb.control(this.unitOperation.minimumMassFlowrate, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'minimumDuty',
      this.svfb.control(this.unitOperation.minimumDuty, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'maximumDuty',
      this.svfb.control(this.unitOperation.maximumDuty, false, false, true)
    );
  }

  pressureMode(value) {
    if (value === PressureCalculationMode.PRESSURE_DROP) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.PRESSURE_DROP;
      this.propertyWindowFormGroup.get('pressureCalculationMode').setValue(this.unitOperation.pressureCalculationMode);
    }
    if (value === PressureCalculationMode.OUTLET_PRESSURE) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.OUTLET_PRESSURE;
      this.propertyWindowFormGroup.get('pressureCalculationMode').setValue(this.unitOperation.pressureCalculationMode);
    }
    if (value === PressureCalculationMode.DUTY) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.DUTY;
      this.propertyWindowFormGroup.get('pressureCalculationMode').setValue(this.unitOperation.pressureCalculationMode);
    }
  }

  grayOutFields() {
    const massCapacity = this.propertyWindowFormGroup.get('massCapacity').get('value');
    const maximumDuty = this.propertyWindowFormGroup.get('maximumDuty').get('value');
    const minimumDuty = this.propertyWindowFormGroup.get('minimumDuty').get('value');
    const minimumMassFlowrate = this.propertyWindowFormGroup.get('minimumMassFlowrate').get('value');

    const controlsWithSimVar: [SimulationVariable, AbstractControl][] = [
      [this.unitOperation.massCapacity, massCapacity],
      [this.unitOperation.maximumDuty, maximumDuty],
      [this.unitOperation.minimumDuty, minimumDuty],
      [this.unitOperation.minimumMassFlowrate, minimumMassFlowrate],
    ];
    const controlsWithValue = controlsWithSimVar.filter(item => {
      return !isEmptyValue(item[1].value);
    });

    const controlsDuty = controlsWithValue.filter(item => {
      return (
        item[0].name === SimulationVariableName.MAXIMUM_DUTY || item[0].name === SimulationVariableName.MINIMUM_DUTY
      );
    });
    const controlsFlowrate = controlsWithValue.filter(item => {
      return (
        item[0].name === SimulationVariableName.MAXIMUM_FEED_CAPACITY ||
        item[0].name === SimulationVariableName.MINIMUM_MASS_FLOWRATE
      );
    });

    if (controlsDuty.length > controlsFlowrate.length) {
      massCapacity.disable();
      minimumMassFlowrate.disable();
      this.capacityStatus = true;
      this.flowrateStatus = true;
    } else if (controlsDuty.length < controlsFlowrate.length) {
      maximumDuty.disable();
      minimumDuty.disable();
      this.minDutyStatus = true;
      this.maxDutyStatus = true;
    } else if (controlsDuty.length === controlsFlowrate.length) {
      this.propertyWindowFormGroup.enable();
      this.capacityStatus = false;
      this.flowrateStatus = false;
      this.minDutyStatus = false;
      this.maxDutyStatus = false;
    }
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }
}
