import { Injectable } from '@angular/core';
import { Project, SimulationVariable } from '../_models';

@Injectable()
export class GlobalKpiService {
  getGlobalKpiNames(project: Project): { id: string; name: string }[] {
    const globalKpis: { id: string; name: string }[] = [];

    const { cases } = project;

    for (const c of cases) {
      const kpis = c.caseStudyManager.kpiManager.selectedKpis.map(kpi => kpi.id);

      for (const kpiId of kpis) {
        if (globalKpis.find(gKpi => gKpi.id === kpiId)) {
          continue;
        }

        let existsInAllCases = true;
        for (const c2 of cases) {
          if (c2 === c) {
            continue;
          }

          if (!c2.simulationVariablePool[kpiId]) {
            existsInAllCases = false;
            break;
          }
        }

        if (existsInAllCases) {
          globalKpis.push({
            id: kpiId,
            name: c.getSimulationVariable(kpiId).getFullName(),
          });
        }
      }
    }

    return globalKpis;
  }

  getGlobalKpi(kpiId: string, project: Project): SimulationVariable[] {
    const kpis = [];

    for (const c of project.cases) {
      kpis.push(c.getSimulationVariable(kpiId));
    }

    return kpis;
  }
}
