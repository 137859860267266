import { OffshoreSource } from '../../../../_models/_unit-operations/offshore-source';
import { Mapper } from '../../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../../_models';
import { StringToSimulationVariableMapper } from '../string-to-simulation-variable-mapper';

export enum OffshoreSourceExcelIndexes {
  VOLUMETRIC_FLOW_RATE = 4,
  GHG_INTENSITY = 5,
  COGEN_ELECTRICITY_GENERATION = 7,
  NET_ELECTRICITY_EXPORT = 8,
  COGEN_INTENSITY = 9,
  GRID_INTENSITY = 10,
  MASS_DENSITY = 14,
  CCR = 15,
  SULFUR = 16,
  NITROGEN = 17,
  LIGHT_ENDS = 20,
  NAPHTA = 21,
  DISTILLATE = 22,
  GAS_OIL = 23,
  VACUUM_RESIDUE = 24,
  PRICE = 27,
  CAPEX_AMORTIZED = 28,
  OPEX_FIXED = 29,
  OPEX_VAR = 30,
  OPEX_GAS_DIESEL = 31,
  OPEX_POWER = 32,
}

export class ArrayToOffshoreSourceMapper implements Mapper<string[], OffshoreSource> {
  private readonly simulationVariableMapper: Mapper<string, SimulationVariable>;

  constructor() {
    this.simulationVariableMapper = new StringToSimulationVariableMapper();
  }

  map(source: string[], destination: OffshoreSource) {
    this.simulationVariableMapper.map(
      source[OffshoreSourceExcelIndexes.VOLUMETRIC_FLOW_RATE],
      destination.volumetricFlowrate
    );
    this.simulationVariableMapper.map(source[OffshoreSourceExcelIndexes.GHG_INTENSITY], destination.ghgIntensity);
    this.simulationVariableMapper.map(
      source[OffshoreSourceExcelIndexes.COGEN_ELECTRICITY_GENERATION],
      destination.cogenElectricityGeneration
    );

    this.simulationVariableMapper.map(
      source[OffshoreSourceExcelIndexes.NET_ELECTRICITY_EXPORT],
      destination.netElectricityExport
    );
    this.simulationVariableMapper.map(source[OffshoreSourceExcelIndexes.COGEN_INTENSITY], destination.cogenIntensity);
    this.simulationVariableMapper.map(source[OffshoreSourceExcelIndexes.GRID_INTENSITY], destination.gridIntensity);

    this.simulationVariableMapper.map(source[OffshoreSourceExcelIndexes.PRICE], destination.price);
    this.simulationVariableMapper.map(source[OffshoreSourceExcelIndexes.CAPEX_AMORTIZED], destination.capexAmortized);
    this.simulationVariableMapper.map(source[OffshoreSourceExcelIndexes.OPEX_FIXED], destination.opexFixed);
    this.simulationVariableMapper.map(source[OffshoreSourceExcelIndexes.OPEX_VAR], destination.opexVar);
    this.simulationVariableMapper.map(source[OffshoreSourceExcelIndexes.OPEX_GAS_DIESEL], destination.opexGasDiesel);
    this.simulationVariableMapper.map(source[OffshoreSourceExcelIndexes.OPEX_POWER], destination.opexPower);
  }
}
