import { Injectable } from '@angular/core';
import { CaseCloner } from './case-cloner';
import { Case } from '../../_models';
import { CaseDeserializerService } from '../case-deserializer/case-deserializer.service';
import { Flowsheet } from '../../_models/flowsheet-manager/flowsheet';

@Injectable()
export class CaseClonerService implements CaseCloner {
  constructor(private caseDeserializer: CaseDeserializerService) {}

  clone(source: Case, newCaseName: string): Case {
    const serializedCase = JSON.stringify(source);
    const clonedCase = this.caseDeserializer.deserialize(serializedCase);

    clonedCase.name = newCaseName;
    clonedCase.id = (<any>window).uuid();

    // not dangerous call at all
    clonedCase.setDiagramString(source.getDiagramString());
    this.cloneFlowsheetPool(source, clonedCase);

    return clonedCase;
  }

  private cloneFlowsheetPool(source: Case, clonedCase: Case) {
    // clone the flowsheet pool
    clonedCase.flowsheetPool = {};

    for (const fId of Object.keys(source.flowsheetPool)) {
      const sourceFlowsheet = source.getFlowsheet(fId);
      const newFlowsheet = new Flowsheet(sourceFlowsheet, clonedCase);

      newFlowsheet.setDiagramJson(sourceFlowsheet.getDiagramJson());
      newFlowsheet.unitOperationId = sourceFlowsheet.unitOperationId;
      newFlowsheet.id = sourceFlowsheet.id;

      clonedCase.addToPools(newFlowsheet);
    }

    clonedCase.setActiveFlowsheet(clonedCase.getFlowsheet(clonedCase.getMainFlowsheetId()));

    // prevent showing the second level by default
    // TODO probably not a dangerous call
    clonedCase.setDiagramString(clonedCase.getActiveFlowsheet().getDiagramJson());
  }
}
