import { Component, Input, OnInit } from '@angular/core';
import { SagdSource } from '../../../_models/_unit-operations/sagd-source';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: 'sob-sagd-source',
  templateUrl: './sagd-source.component.html',
  styleUrls: ['./sagd-source.component.css'],
})
export class SagdSourceComponent implements OnInit {
  @Input()
  unitOperation: SagdSource;

  currentAnalysis: SuncorFluidAnalysis;

  constructor(private coreService: CoreService) {}

  ngOnInit() {
    this.updateCurrentAnalysis();
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Crude oil - Bitumen');
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
      this.assayChanged();
    }
  }

  assayChanged() {
    const cuts = [
      'lightEndsCut',
      'lightNaphthaCut',
      'heavyNaphthaCut',
      'distillateCut',
      'LGOCut',
      'HGOCut',
      'VGOCut',
      'HVGOCut',
      'residCut',
    ];
    const simvarNames = ['sulfur', 'anilinePoint', 'massDensity', 'nitrogen', 'ccr'];
    for (const cutId of cuts) {
      for (const simvarName of simvarNames) {
        const simVar = this.currentAnalysis[cutId][simvarName];
        const $field = `#${cutId}${simvarName}`;
        $($field).val(simVar.convertToCaptureUnit(true));
        $(`${$field}Unit`).val(simVar.unit);
        $($field).change();
      }
    }
    $(`#compositionUnit${this.unitOperation.id}`).val(this.currentAnalysis.composition[0].unit);
    let total = 0;
    for (let i = 0; i < this.currentAnalysis.composition.length; i++) {
      const simVar = this.currentAnalysis.composition[i];
      total += simVar.convertToCaptureUnit(true);
      $(`#composition${i}`).val(simVar.convertToCaptureUnit(true));
    }
    $('#compositionTotalUO').val(total);

    $('#fluidAnalysisMassDensity').val(this.currentAnalysis.massDensity.convertToCaptureUnit(true));
    $('#fluidAnalysisMassDensityUnit').val(this.currentAnalysis.massDensity.unit);
    $('#fluidAnalysisMassDensity').change();

    $('#fluidAnalysisCcr').val(this.currentAnalysis.ccr.convertToCaptureUnit(true));
    $('#fluidAnalysisCcrUnit').val(this.currentAnalysis.ccr.unit);
    $('#fluidAnalysisCcr').change();

    $('#fluidAnalysisSulfur').val(this.currentAnalysis.sulfur.convertToCaptureUnit(true));
    $('#fluidAnalysisSulfurUnit').val(this.currentAnalysis.sulfur.unit);
    $('#fluidAnalysisSulfur').change();

    $('#fluidAnalysisNitrogen').val(this.currentAnalysis.nitrogen.convertToCaptureUnit(true));
    $('#fluidAnalysisNitrogenUnit').val(this.currentAnalysis.nitrogen.unit);
    $('#fluidAnalysisNitrogen').change();
  }
}
