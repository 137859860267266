import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

declare let unitConverter: any;

@Component({
  selector: 'sob-reactive-unit-select',
  template: `<select
    class="form-control"
    style="{{ style }}"
    id="{{ selectId }}"
    [value]="value"
    [disabled]="isDisabled"
    (change)="unitChanged($event.target.value)"></select>`,
  styleUrls: ['./reactive-unit-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveUnitSelectComponent),
      multi: true,
    },
  ],
})
export class ReactiveUnitSelectComponent implements AfterViewInit {
  @Input() quantity: string;

  // TODO can we avoid passing an id? :v
  @Input() selectId: string;

  @Input() class: string;
  @Input() style: string;

  value: string;
  isDisabled: boolean;

  @Output() unitChange = new EventEmitter<string>();

  onChange = (_: any) => {};
  onTouch = () => {};

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.selectId) {
      unitConverter(this.quantity).createUnitSelect(`#${this.selectId}`, this.value);
    } else {
      unitConverter(this.quantity).createUnitSelect(this.el.nativeElement.firstChild, this.value);
    }
  }

  unitChanged(value) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);

    this.unitChange.emit(this.value);
  }

  writeValue(value: any): void {
    this.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
