<div [formGroup]="propertyWindowFormGroup">
  <div class="form-horizontal">
    <div class="form-group">
      <label class="pw-label control-label col-sm-4"> Assay Name </label>
      <div class="col-sm-6">
        <sob-assay-selector
          [fluidAnalyses]="fluidAnalyses"
          [currentAnalysis]="currentAnalysis"
          (updateCurrentAssay)="updateCurrentAnalysis($event)"
          formControlName="fluidAnalysisId">
        </sob-assay-selector>
      </div>
    </div>
  </div>
  <hr />
  <br />

  <ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
    <li role="presentation" class="active">
      <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
    </li>

    <li *ngIf="currentAnalysis" role="presentation">
      <a href="#compositionTab" aria-controls="compositionTab" role="tab" data-toggle="tab">Composition</a>
    </li>

    <li role="presentation">
      <a href="#fuelGasSourceComments" aria-controls="fuelGasSourceComments" role="tab" data-toggle="tab">
        <i
          class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}"
          aria-hidden="true">
        </i>
        Comments
      </a>
    </li>
  </ul>

  <div class="tab-content pw-margin tab-content-padding">
    <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <h4 class="subtitle">Conditions</h4>
          <hr />
          <br />

          <div class="form-group m-b-md">
            <label for="isOptimizable" class="control-label col-sm-4"> Optimize </label>

            <div class="col-sm-4">
              <label for="isOptimizable" class="control-label">
                <input
                  type="checkbox"
                  id="isOptimizable"
                  formControlName="isOptimizable"
                  (change)="isOptimizableChanged($event.target.checked)" />
              </label>
            </div>
          </div>

          <div class="form-group" formGroupName="flowrate">
            <label class="control-label col-sm-4"> Flowrate </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.flowrate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.flowrate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentAnalysis" role="tabpanel" class="tab-pane fade in" id="compositionTab">
      <ul class="nav nav-pills" role="tablist" style="margin-right: 15px">
        <li role="presentation" class="active">
          <a (click)="openAssayManager(currentAnalysis.id)">Edit</a>
        </li>
      </ul>

      <div class="tab-content pw-margin tab-content-padding">
        <div *ngIf="currentAnalysis" role="tabpanel" class="tab-pane fade in active" id="compositionUO">
          <div class="form-horizontal" sfeMultipleSimVarInput totalOutputId="compositionTotalUO">
            <div class="form-group">
              <label [attr.for]="'compositionUnit'" class="pw-label control-label col-sm-4"> Unit </label>

              <div class="col-sm-4">
                <sfe-unit-select
                  [selectId]="'compositionUnit' + unitOperation.id"
                  [simVar]="currentAnalysis.composition[0]"
                  [disabled]="false"
                  [elClass]="'form-control'"></sfe-unit-select>
              </div>
            </div>

            <ng-container *ngFor="let idx of currentAnalysis.composition | keys">
              <div class="form-group">
                <label [attr.for]="'composition' + idx" class="pw-label control-label col-sm-4">
                  {{ currentAnalysis.composition[idx].name | titleCaseComposition }}
                </label>
                <div class="col-sm-4">
                  <sfe-value-input
                    [simVar]="currentAnalysis.composition[idx]"
                    [elClass]="'form-control'"
                    [inputId]="'composition' + idx"
                    [multiple]="true"
                    [readOnlyValue]="true"
                    [disabled]="true"
                    [convertedBy]="'compositionUnit'">
                  </sfe-value-input>
                </div>
              </div>
            </ng-container>

            <div class="form-group">
              <label class="pw-label control-label col-sm-4">Total</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="compositionTotalUO" readonly />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="fuelGasSourceComments">
      <div class="tab-content no-padding no-border">
        <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
      </div>
    </div>
  </div>
</div>
