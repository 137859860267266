/* eslint-disable no-console */
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { GlobalErrorModalService } from '../shared/global-error-modal/global-error-modal.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone, private globalErrorModalService: GlobalErrorModalService) {}

  handleError(error: unknown): void {
    this.zone.run(() => {
      console.error('Caught by the global error handler', error);
      this.globalErrorModalService.reportError(error);
    });
  }
}
