<div class="sankey-control-wrapper" style="display: none">
  <ul class="unstyled no-padding no-margins controls">
    <li *ngIf="!genericSinksVisible" (click)="showGenericSinks()" title="Show Generic Sinks">
      <a> <i class="fa fa-eye"></i></a>
    </li>
    <li *ngIf="genericSinksVisible" (click)="hideGenericSinks()" title="Show Generic Sinks">
      <a> <i class="fa fa-eye-slash"></i></a>
    </li>
  </ul>
</div>
