<div id="multiperiodExcelReportModal" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Save Multiperiod Excel Report as...</h4>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="excelReportFileName">Name:</label>
          <input id="excelFilename" [(ngModel)]="filename" id="excelReportFileName" class="form-control" type="text" />
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button id="downloadExcelBtn" type="button" class="btn btn-primary" (click)="generateExcel()">Save</button>
      </div>
    </div>
  </div>
</div>
