import { Component } from '@angular/core';
import { SankeyDiagramService } from '../sankey-diagram/sankey-diagram.service';

@Component({
  selector: 'sob-sankey-diagram-controls',
  templateUrl: './sankey-diagram-controls.component.html',
  styleUrls: ['./sankey-diagram-controls.component.css'],
})
export class SankeyDiagramControlsComponent {
  genericSinksVisible = true;
  constructor(private sankeyDiagramService: SankeyDiagramService) {}

  hideGenericSinks() {
    this.genericSinksVisible = false;
    this.sankeyDiagramService.hideGenericSinks();
  }

  showGenericSinks() {
    this.genericSinksVisible = true;
    this.sankeyDiagramService.showGenericSinks();
  }
}
