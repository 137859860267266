import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartObject } from 'highcharts';
import { UtilitiesSummaryReportData } from '../../../_models/_reports/utilities-summary-report-data';
import { NumberToUnitConverter } from '../../../_services/number-to-unit-converter.service';

@Component({
  selector: 'sob-fuel-gas-summary-report-chart',
  templateUrl: './fuel-gas-summary-report-chart.component.html',
  styleUrls: ['./fuel-gas-summary-report-chart.component.css'],
})
export class FuelGasSummaryReportChartComponent implements AfterViewInit, OnChanges {
  @Input() data: UtilitiesSummaryReportData;
  @Input() unit: string;
  @Input() idPrefix: string;
  @Input() upgraderName: string;

  constructor(private nuc: NumberToUnitConverter) {}

  ngAfterViewInit() {
    this.build();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['data'].firstChange) {
      this.build();
    }
  }

  build() {
    this.buildChart();
    this.buildTable();
  }

  private buildChart() {
    const series = [
      {
        name: this.data.displayTitle,
        colorByPoint: true,
        data: this.data.reportItems.map(ri => {
          return { y: ri.value, unitOperationName: ri.unitOperationName };
        }),
      },
    ];

    const chartOptions = this.getChartOptions(this.data.displayTitle, this.unit, series);
    const initialisedChart = Highcharts.chart(`${this.idPrefix}ufgr${this.data.type}`, <any>chartOptions);
    this.showEmptyChartMessage(initialisedChart);
  }

  showEmptyChartMessage(initialisedChart: ChartObject) {
    if (this.data.reportItems.length === 0) {
      if (this.data.displayTitle.includes('Make')) {
        initialisedChart.showLoading(`Upgrader "${this.upgraderName}" does not report Fuel Gas Make`);
      } else {
        initialisedChart.showLoading(`Upgrader "${this.upgraderName}" does not report Fuel Gas Use`);
      }
    }
  }

  private getChartOptions(title, unit, series) {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },

      credits: {
        enabled: false,
      },

      title: {
        text: title,
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: `{point.unitOperationName}: {point.y:,.1f} ${unit}`,
            style: {
              fontSize: '10px',
            },
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        // eslint-disable-next-line max-len
        pointFormat: `<span style="color:{point.color}">{point.unitOperationName}</span>: <b>{point.y:,.1f} ${unit} - {point.percentage:.1f}%</b><br/>`,
      },
      series,
    };
  }

  private buildTable() {
    const $table = $(`#${this.idPrefix}ufgr${this.data.type}Table`);

    if ((<any>$.fn).dataTable.isDataTable(`#${this.idPrefix}ufgr${this.data.type}Table`)) {
      (<any>$table).DataTable().destroy();
    }

    const opts = {
      data: this.data.reportItems,
      paging: false,
      searching: false,
      info: false,
      order: [[1, 'desc']],
      columns: [
        {
          data: 'unitOperationName',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              return `${this.nuc.formatNumber(data.value)} ${this.unit}`;
            }
            return data.value;
          },
        },
      ],
    };

    (<any>$table).DataTable(<any>opts);
  }

  changeUnitColumnName() {
    return this.data.type === 'fuelGasMake' ? 'Flowrate' : 'Energy Use';
  }
}
