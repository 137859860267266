import * as wjcCore from '@grapecity/wijmo';

import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum MineSourceSheetRows {
  NAME = 0,
  VOLUMETRIC_FLOW_RATE = 2,
  GHG_INTENSITY = 3,
  COGEN_ELECTRICITY_GENERATION = 5,
  NET_ELECTRICITY_EXPORT = 6,
  COGEN_INTENSITY = 7,
  GRID_INTENSITY = 8,
  LIGHT_ENDS = 11,
  LIGHT_NAPHTHA = 12,
  HEAVY_NAPHTHA = 13,
  DISTILLATE = 14,
  LGO = 15,
  HGO = 16,
  VGO = 17,
  HVGO = 18,
  RESID = 19,
  PRICE = 22,
  CAPEX_AMORTIZED = 23,
  OPEX_FIXED = 24,
  OPEX_VAR = 25,
  OPEX_GAS_DIESEL = 26,
  OPEX_POWER = 27,
}

export const MineSourceCellConfig: FlexSheetCellConfig[] = [
  {
    row: MineSourceSheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: MineSourceSheetRows.VOLUMETRIC_FLOW_RATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.GHG_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.COGEN_ELECTRICITY_GENERATION,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.NET_ELECTRICITY_EXPORT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.COGEN_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.GRID_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.LIGHT_ENDS,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.LIGHT_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.HEAVY_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.DISTILLATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.LGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.HGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.VGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.HVGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.RESID,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.PRICE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.CAPEX_AMORTIZED,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.OPEX_FIXED,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.OPEX_VAR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.OPEX_GAS_DIESEL,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: MineSourceSheetRows.OPEX_POWER,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
