import { UntypedFormGroup } from '@angular/forms';
import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';
import { DefaultMappedType } from '../_models/default-mapped-type';
import { Splitter } from '../_models/_unit-operations/splitter';

export class SplitterFormChangeDetector extends DefaultBaseObjectFormChangeDetector {
  constructor(formGroup: UntypedFormGroup) {
    super(formGroup);
  }

  override detectChangesInValues(
    originalFormValue: DefaultMappedType<Splitter>,
    currentValue: DefaultMappedType<Splitter>
  ): boolean {
    let changes = super.detectChangesInValues(originalFormValue, currentValue);

    if (originalFormValue.distributionRatioVariables.length !== currentValue.distributionRatioVariables.length) {
      return true;
    }

    for (let i = 0; i < currentValue.distributionRatioVariables.length; i++) {
      const currentDistributionRatioVariable = currentValue.distributionRatioVariables[i];
      const originalDistributionRatioVariable = originalFormValue.distributionRatioVariables[i];

      if (originalDistributionRatioVariable === undefined) {
        // TODO WTF
        return changes;
      }
      if (
        currentDistributionRatioVariable.value !== originalDistributionRatioVariable.value ||
        currentDistributionRatioVariable.minimumValue !== originalDistributionRatioVariable.minimumValue ||
        currentDistributionRatioVariable.maximumValue !== originalDistributionRatioVariable.maximumValue
      ) {
        changes = changes || true;
      }
    }

    return changes;
  }

  override ignoreForDefaultChangeDetection(k: string): boolean {
    return super.ignoreForDefaultChangeDetection(k) || (k as keyof Splitter) === 'distributionRatioVariables';
  }
}
