import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ParametricStudyApiService } from './parametric-study-api.service';
import { CoreService } from '../core.service';
import { SolveCaseStudyCanceller } from '../../_models/_case-study/solve-case-study-canceller';
import { ApiObjectsBackupManager } from '../../_models/api-objects-backup-manager';
import { ParametricStudy } from '../../_models/_case-study/parametric-study';
import { FileService } from '../file.service';

@Injectable({
  providedIn: 'root',
})
export class ParametricStudyService {
  subscription: Subscription;
  solveSubject: Subject<any>;
  solveObservable: Observable<any>;
  solveCanceller: SolveCaseStudyCanceller;

  constructor(
    private parametricStudyApi: ParametricStudyApiService,
    private coreService: CoreService,
    private fileService: FileService
  ) {
    this.solveCanceller = new SolveCaseStudyCanceller(this);
  }

  solveParametricStudy(ps: ParametricStudy): Observable<any> {
    this.solveSubject = new Subject();
    this.solveObservable = this.solveSubject.asObservable();

    ps.clearResults();
    const removedStudies = ApiObjectsBackupManager.removeParametricStudies(ps.ownerCase, ps);

    this.subscription = this.parametricStudyApi.solveParametricStudy(ps, this.coreService.project).subscribe(
      solvedResponse => {
        if ((<any>solvedResponse).caseModel) {
          this.handleSuccess(solvedResponse, ps, removedStudies);
          this.solveSubject.next();
        } else {
          this.handleError(solvedResponse, ps, removedStudies);
          this.solveSubject.error(solvedResponse);
        }
      },
      error => {
        this.handleError(error, ps, removedStudies);
        this.solveSubject.error(error);
      }
    );

    return this.solveObservable;
  }

  cancelSolve() {
    this.subscription.unsubscribe();
    this.solveSubject.error({ cancelled: true });
  }

  private handleSuccess(solvedResponse: any, ps: ParametricStudy, removedAnalyses: ParametricStudy[]) {
    this.restoreBackup(removedAnalyses);
    const serializedCase = solvedResponse.caseModel;
    let serializedStudy = serializedCase.otherBaseObjectPool[serializedCase.caseStudyManager].parametricStudies[0];
    serializedStudy = serializedCase.otherBaseObjectPool[serializedStudy];
    ps.dePersistResults(serializedStudy);
  }

  private handleError(error, ps: ParametricStudy, removedAnalyses: ParametricStudy[]) {
    // ps.convertToCaptureUnit();
    this.restoreBackup(removedAnalyses);
  }

  private restoreBackup(removedAnalyses: ParametricStudy[]) {
    ApiObjectsBackupManager.restoreRemovedStudies(
      removedAnalyses.length ? removedAnalyses[0].ownerCase : undefined,
      removedAnalyses
    );
  }

  solveParametricStudyAsync(): Observable<any> {
    const formData = this.coreService.getProjectFormData();
    return this.parametricStudyApi.solveParametricStudyAsync({
      promise: formData,
      caseToBeSolvedId: this.coreService.currentCase.id,
      fileName: this.coreService.project.fileName,
    });
  }
}
