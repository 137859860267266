import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../../_services/core.service';
import { CaseClonerService } from '../../_services/case-cloner/case-cloner.service';
import { LogManagerService } from '../log-manager/log-manager.service';
import { FlowsheetDiagramService } from '../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { ProgressIndicatorService } from '../progress-indicator/progress-indicator.service';
import { SankeyDiagramService } from '../sankey-diagram/sankey-diagram.service';

@Component({
  selector: 'sob-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.css'],
})
export class CaseListComponent {
  selectedCaseId: string;
  selectedCaseName: string;
  newCaseName: string;

  constructor(
    private coreService: CoreService,
    private caseCloner: CaseClonerService,
    private logManager: LogManagerService,
    private flowsheetService: FlowsheetService,
    private progressIndicator: ProgressIndicatorService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private sankeyService: SankeyDiagramService,
    private toastr: ToastrService
  ) {}

  openCloneCaseModal(id) {
    $('#cloneCaseModal').modal('show');
    this.newCaseName = '';
    this.selectedCaseId = id;
  }

  cloneCase() {
    if (!this.newCaseName) {
      this.toastr.warning('Case name is required', '', { positionClass: 'toast-top-right' });
      return;
    }
    const caseToClone = this.project.findCaseById(this.selectedCaseId);

    if (caseToClone === this.currentCase) {
      if (!this.currentCase.getActiveFlowsheet().isSubflowsheet()) {
        // TODO probably not a dangerous assignment
        caseToClone.setDiagramString(this.flowsheetDiagramService.getDiagramJson());
        caseToClone.getActiveFlowsheet().setDiagramJson(caseToClone.getDiagramString());
      } else {
        // I guess this should be done before exiting to the main flowsheet...
        this.flowsheetService.exitToMainFlowsheet();
        // TODO probably not a dangerous assignment
        caseToClone.setDiagramString(this.flowsheetDiagramService.getDiagramJson());
        caseToClone.getActiveFlowsheet().setDiagramJson(caseToClone.getDiagramString());
      }
    }

    const clonedCase = this.caseCloner.clone(caseToClone, this.newCaseName);

    this.project.addCase(clonedCase);

    if (this.currentCase.getActiveFlowsheet().isSubflowsheet()) {
      this.flowsheetService.exitToMainFlowsheet();
    }

    this.hideCloneCaseModal();
    this.hideModal();

    const message = `Case ${clonedCase.name} cloned`;
    this.toastr.info(message, '', { positionClass: 'toast-top-right' });
    this.logManager.info(message);

    // leave this at the end
    this.coreService.setCurrentCase(clonedCase);
  }

  hideCloneCaseModal() {
    $('#cloneCaseModal').modal('hide');
  }

  openDeleteCaseModal(id, name) {
    this.selectedCaseId = id;
    this.selectedCaseName = name;
    $('#deleteCaseModal').modal('show');
  }

  deleteCase() {
    if (this.selectedCaseId === this.currentCase.id) {
      this.toastr.error('Current case cannot be deleted', '', { positionClass: 'toast-top-right' });

      return;
    }

    const caseIndex = this.project.cases.findIndex(c => c.id === this.selectedCaseId);
    const caseName = this.project.cases[caseIndex].name;

    this.project.cases.splice(caseIndex, 1);

    const message = `${caseName} deleted`;

    this.toastr.info(message, '', { positionClass: 'toast-top-right' });
    this.logManager.info(message);

    this.hideDeleteCaseModal();
  }

  hideDeleteCaseModal() {
    $('#deleteCaseModal').modal('hide');
  }

  setCurrentCase(id) {
    if (this.currentCase.getActiveFlowsheet().isSubflowsheet()) {
      this.flowsheetService.exitToMainFlowsheet();
    }
    this.coreService.setCurrentCaseById(id);
    this.sankeyService.caseToSankeyDiagram(this.coreService.currentCase);
    this.hideModal();
  }

  hideModal() {
    $('#caseListModal').modal('hide');
  }

  get project() {
    return this.coreService.project;
  }

  get currentCase() {
    return this.coreService.currentCase;
  }
}
