import { ThirdPartyRefinery } from '../../../_models/_unit-operations/third-party-refinery';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { StringToSimulationVariableMapper } from './string-to-simulation-variable-mapper';

export enum ThirdPartyRefineryExcelIndexes {
  LIGHT_ENDS_MARGIN = 5,
  NAPHTHA_MARGIN = 6,
  DISTILLATE_MARGIN = 7,
  GAS_OIL_MARGIN = 8,
  VACUUM_RESIDUE_MARGIN = 9,
  CAPACITY = 10,
}

export class ArrayToThirdPartyRefineryMapper implements Mapper<string[], ThirdPartyRefinery> {
  private readonly simulationVariableMapper: Mapper<string, SimulationVariable>;

  constructor() {
    this.simulationVariableMapper = new StringToSimulationVariableMapper();
  }

  map(source: string[], destination: ThirdPartyRefinery) {
    this.simulationVariableMapper.map(
      source[ThirdPartyRefineryExcelIndexes.LIGHT_ENDS_MARGIN],
      destination.lightEndsMargin
    );
    this.simulationVariableMapper.map(source[ThirdPartyRefineryExcelIndexes.NAPHTHA_MARGIN], destination.naphthaMargin);
    this.simulationVariableMapper.map(
      source[ThirdPartyRefineryExcelIndexes.DISTILLATE_MARGIN],
      destination.distillateMargin
    );
    this.simulationVariableMapper.map(source[ThirdPartyRefineryExcelIndexes.GAS_OIL_MARGIN], destination.gasOilMargin);
    this.simulationVariableMapper.map(
      source[ThirdPartyRefineryExcelIndexes.VACUUM_RESIDUE_MARGIN],
      destination.vacuumResidueMargin
    );
    this.simulationVariableMapper.map(source[ThirdPartyRefineryExcelIndexes.CAPACITY], destination.capacity);
  }
}
