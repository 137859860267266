import { BaseObject } from '../base-object';
import { SimulationVariableArray } from '../simulation-variable-array';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';

export class ParametricStudyParameter extends BaseObject {
  category = 'parametricStudyParameter';
  variableValues: SimulationVariableArray;
  simulationVariable: SimulationVariable;

  constructor(parameter: any, ownerCase: Case) {
    super(parameter.id, ownerCase);
    this.initValues(parameter);
  }

  initValues(parameter: any) {
    if (parameter.simulationVariable instanceof SimulationVariable) {
      this.simulationVariable = parameter.simulationVariable;
    }

    this.variableValues = new SimulationVariableArray(parameter.variableValues, null, null);

    if (!this.variableValues.quantity) {
      this.variableValues.setQuantity(this.simulationVariable.quantity);
      this.variableValues.unit = this.simulationVariable.unit;
    }

    if (parameter.variableValues instanceof SimulationVariableArray) {
      for (const n of parameter.variableValues) {
        this.variableValues.addValue(n);
      }
    }
  }

  override convertToInternalUnit() {
    const convertedValues = this.variableValues.convertToInternalUnit();

    for (let i = 0; i < convertedValues.length; i++) {
      this.variableValues.values[i] = convertedValues[i];
    }
  }

  convertToCaptureUnit() {
    const convertedValues = this.variableValues.convertToCaptureUnitArray(true, false);

    for (let i = 0; i < convertedValues.length; i++) {
      this.variableValues.values[i] = convertedValues[i];
    }
  }

  addState() {
    this.variableValues.addValue(this.simulationVariable.value);
  }

  removeLastValue() {
    this.variableValues.removeValue(this.variableValues.values.length - 1);
  }

  // region serialization
  override dePersist(persistedObj: any): void {
    this.simulationVariable = this.ownerCase.getSimulationVariable(persistedObj.simulationVariable);
    this.variableValues = new SimulationVariableArray(persistedObj.variableValues, null, null);
  }

  public toJSON() {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      simulationVariable: this.simulationVariable.id,
      variableValues: this.variableValues,
    };
  }
}
