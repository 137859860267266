import { UnitOperation } from '../unit-operation';
import { Case } from '../case';
import { SimulationVariable } from '../simulation-variable';

export class UpgraderInlet extends UnitOperation {
  category = 'upgrader-inlet';

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override initValues(unitOperation: any) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override dePersist(serializedUnitOperation: any) {}

  override setSimulationVariableNames() {}

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  override setQuantities() {}

  override clearResults() {}

  override setDefaultValues() {}
}
