import { Component, Input } from '@angular/core';
import { CoreService } from '../../../_services/core.service';
import { WaterUtilityUnitOperation } from '../../../_models/_unit-operations/utilties/water-utility-unit-operation';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { SteamType } from '../../../_config/steam-type.enum';
import { WaterSourceImport } from '../../../_models/_unit-operations/utilties/water-source-import';
import { WaterSinkImport } from '../../../_models/_unit-operations/utilties/water-sink-import';
import { WaterSourceInformationStream } from '../../../_models/_unit-operations/utilties/water-source-information-stream';
import { BaseWaterImportUnitOperation } from '../../../_models/_unit-operations/utilties/base-water-import-unit-operation';
import { NumberToUnitConverter } from '../../../_services/number-to-unit-converter.service';
import { Quantity } from '../../../_config/quantity.enum';
import { WaterBoiler } from '../../../_models/_unit-operations/utilties/water-boiler';
import { WaterTurbine } from '../../../_models/_unit-operations/utilties/water-turbine';
import {
  UtilitiesSummaryReportData,
  UtilitiesSummaryReportItem,
} from '../../../_models/_reports/utilities-summary-report-data';
import { WaterCogeneration } from '../../../_models/_unit-operations/utilties/water-cogeneration';
import { WaterUnitOperation } from '../../../_models/_unit-operations/utilties/water-unit-operation';

declare let unitConverter: any;

@Component({
  selector: 'sob-utilities-summary-report',
  templateUrl: './utilities-summary-report.component.html',
  styleUrls: ['./utilities-summary-report.component.css'],
})
export class UtilitiesSummaryReportComponent {
  @Input() upgraderName: string;

  steamUse50ReportData: UtilitiesSummaryReportData;
  steamMake50ReportData: UtilitiesSummaryReportData;

  steamUse150ReportData: UtilitiesSummaryReportData;
  steamMake150ReportData: UtilitiesSummaryReportData;

  steamUse600ReportData: UtilitiesSummaryReportData;
  steamMake600ReportData: UtilitiesSummaryReportData;

  steamUse900ReportData: UtilitiesSummaryReportData;
  steamMake900ReportData: UtilitiesSummaryReportData;

  upgraderId = '';

  constructor(private coreService: CoreService, private nuc: NumberToUnitConverter) {}

  buildReport(id?: string) {
    let utilitiesUnitOperation;
    if (id) {
      utilitiesUnitOperation = this.getUtilitiesUnitOperation(id);
      this.buildData(utilitiesUnitOperation ? utilitiesUnitOperation.id : '');
      this.upgraderId = id;
    } else {
      utilitiesUnitOperation = this.getUtilitiesUnitOperation();
      this.buildData(utilitiesUnitOperation ? utilitiesUnitOperation.id : '');
    }
  }

  private buildData(utilitiesUnitOperationId: string) {
    // 50
    const steamUse50Units = this.getWaterSinkImportUnits(utilitiesUnitOperationId, SteamType.Steam50);
    this.steamUse50ReportData = this.createSingleReportData(steamUse50Units, 'steam50Use', '50 psig Steam Use');

    const steamMake50Units = this.getWaterSourceImportUnits(utilitiesUnitOperationId, SteamType.Steam50);
    this.steamMake50ReportData = this.createSingleReportData(steamMake50Units, 'steam50Make', '50 psig Steam Make');

    // 150
    const steamUse150Units = this.getWaterSinkImportUnits(utilitiesUnitOperationId, SteamType.Steam150);
    this.steamUse150ReportData = this.createSingleReportData(steamUse150Units, 'steam150Use', '150 psig Steam Use');

    const steamMake150Units = this.getWaterSourceImportUnits(utilitiesUnitOperationId, SteamType.Steam150);
    this.steamMake150ReportData = this.createSingleReportData(steamMake150Units, 'steam150Make', '150 psig Steam Make');

    // 600
    const steamUse600Units = this.getWaterSinkImportUnits(utilitiesUnitOperationId, SteamType.Steam600);
    this.steamUse600ReportData = this.createSingleReportData(steamUse600Units, 'steam600Use', '600 psig Steam Use');

    const steamMake600Units = this.getWaterSourceImportUnits(utilitiesUnitOperationId, SteamType.Steam600);
    this.steamMake600ReportData = this.createSingleReportData(steamMake600Units, 'steam600Make', '600 psig Steam Make');

    this.buildSteam900Data(utilitiesUnitOperationId);
  }

  private buildSteam900Data(utilitiesUnitOperationId: string) {
    const steamUse900Units = this.getWaterSinkImportUnits(utilitiesUnitOperationId, SteamType.Steam900);
    this.steamUse900ReportData = this.createSingleReportData(
      steamUse900Units,
      'steam900Use',
      '900 psig Steam Use',
      true
    );
    const turbinesReportData = this.getTurbinesInletFlowrateReportData(utilitiesUnitOperationId);
    this.steamUse900ReportData.reportItems.push(...turbinesReportData.reportItems);
    this.steamUse900ReportData.total = this.nuc.convert(
      this.steamUse900ReportData.total + turbinesReportData.total,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      true
    );

    const steamMake900Units = this.getWaterSourceImportUnits(utilitiesUnitOperationId, SteamType.Steam900);
    this.steamMake900ReportData = this.createSingleReportData(
      steamMake900Units,
      'steam900Make',
      '900 psig Steam Make',
      true
    );

    const boilersReportData = this.getBoilersOutletFlowrateReportData(utilitiesUnitOperationId);
    this.steamMake900ReportData.reportItems.push(...boilersReportData.reportItems);
    this.steamMake900ReportData.total = this.nuc.convert(
      this.steamMake900ReportData.total + boilersReportData.total,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      true
    );
  }

  private createSingleReportData(
    waterImportUnitsArray: BaseWaterImportUnitOperation[],
    type: string,
    displayTitle: string,
    totalInInternalUnit: boolean = false
  ): UtilitiesSummaryReportData {
    let allReportItems: UtilitiesSummaryReportItem[] = [];

    let total = 0;
    for (const wiu of waterImportUnitsArray) {
      const currentReportItems = [];
      for (const infStream of wiu.inletSourceInformationStreams) {
        currentReportItems.push(this.informationStreamToUtilitiesSummaryReportItem(infStream));
        total += infStream.massFlowrate.value;
      }

      allReportItems = allReportItems.concat(currentReportItems);
    }

    if (!totalInInternalUnit) {
      total = this.nuc.convert(total, Quantity.MASSFLOWRATE, 'kpph', true);
    }

    return {
      type,
      reportItems: allReportItems,
      total,
      displayTitle,
    };
  }

  private informationStreamToUtilitiesSummaryReportItem(informationStream: WaterSourceInformationStream) {
    const unitOperationName = this.coreService.currentCase.getUnitOperation(
      informationStream.providerUnitOperationId
    ).name;
    const massFlowrateUnit = unitConverter.units.Massflowrate.KPPH;
    return {
      value: informationStream.massFlowrate.convertToAnotherUnit(massFlowrateUnit),
      unitOperationName,
    } as UtilitiesSummaryReportItem;
  }

  private getUtilitiesUnitOperation(id?: string): WaterUtilityUnitOperation {
    let result;
    if (id) {
      result = this.coreService.currentCase.filterUnitOperations(
        uo => uo.category === unitOperationsConfig.waterUtilityUnitOperation.key && uo.flowsheetId === id
      );
    } else {
      result = this.coreService.currentCase.filterUnitOperations(
        uo => uo.category === unitOperationsConfig.waterUtilityUnitOperation.key
      );
    }
    // TODO what if it has more than 1 element?
    if (result.length) {
      return result[0] as WaterUtilityUnitOperation;
    }

    return undefined;
  }

  private getWaterSourceImportUnits(ownerFlowsheetId: string, steamType: SteamType): WaterSourceImport[] {
    return this.coreService.currentCase.filterUnitOperations(
      uo => uo.flowsheetId === ownerFlowsheetId && uo instanceof WaterSourceImport && uo.steamType === steamType
    ) as WaterSourceImport[];
  }

  private getWaterSinkImportUnits(ownerFlowsheetId: string, steamType: SteamType): WaterSinkImport[] {
    return this.coreService.currentCase.filterUnitOperations(
      uo => uo.flowsheetId === ownerFlowsheetId && uo instanceof WaterSinkImport && uo.steamType === steamType
    ) as WaterSinkImport[];
  }

  private getBoilersOutletFlowrateReportData(ownerFlowsheetId: string): UtilitiesSummaryReportData {
    const unitOperations = this.coreService.currentCase.filterUnitOperations(
      uo => uo.flowsheetId === ownerFlowsheetId && (uo instanceof WaterBoiler || uo instanceof WaterCogeneration)
    ) as WaterUnitOperation[];

    let total = 0;
    const reportItems: UtilitiesSummaryReportItem[] = [];

    const massFlowrateUnit = unitConverter.units.Massflowrate.KPPH;
    unitOperations.forEach(uo => {
      const outletStream = this.coreService.currentCase.filterWaterMaterialStreams(
        s => s.inletUnitOperationId === uo.id
      )[0];

      if (!outletStream) {
        return;
      }

      total += outletStream.massFlowrate.hasNumericValue() ? outletStream.massFlowrate.value : 0;

      reportItems.push({
        value: outletStream.massFlowrate.convertToAnotherUnit(massFlowrateUnit),
        unitOperationName: uo.name,
      });
    });

    return {
      reportItems,
      total,
      type: '',
      displayTitle: '',
    };
  }

  private getTurbinesInletFlowrateReportData(ownerFlowsheetId: string): UtilitiesSummaryReportData {
    const turbines = this.coreService.currentCase.filterUnitOperations(
      uo => uo.flowsheetId === ownerFlowsheetId && uo instanceof WaterTurbine
    ) as WaterTurbine[];

    let total = 0;
    const reportItems: UtilitiesSummaryReportItem[] = [];

    const massFlowrateUnit = unitConverter.units.Massflowrate.KPPH;
    turbines.forEach(turbine => {
      const inletStream = this.coreService.currentCase.filterWaterMaterialStreams(
        s => s.outletUnitOperationId === turbine.id
      )[0];

      if (!inletStream) {
        return;
      }

      total += inletStream.massFlowrate.hasNumericValue() ? inletStream.massFlowrate.value : 0;

      reportItems.push({
        value: inletStream.massFlowrate.convertToAnotherUnit(massFlowrateUnit),
        unitOperationName: turbine.name,
      });
    });

    return {
      reportItems,
      total,
      displayTitle: '',
      type: '',
    };
  }
}
