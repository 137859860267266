<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#waterSplitterComments" aria-controls="waterSplitterComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group m-b-md">
          <label for="isOptimizable" class="control-label col-sm-4"> Optimize </label>

          <div class="col-sm-4">
            <label for="isOptimizable" class="control-label">
              <input type="checkbox" id="isOptimizable" formControlName="isOptimizable" />
            </label>
          </div>
        </div>

        <label class="control-label col-sm-4"> Stream Name </label>

        <label class="control-label col-sm-6"> Distribution Ratio </label>

        <br />
        <br />
        <br />
        <sob-reactive-range-distribution-ratio-variables
          [valueControlsReadonly]="ratioVariableValueControlsReadonly()"
          [ratioVariables]="unitOperation.distributionRatioVariables"
          [ratioVariablesFormGroup]="
            rangeDistributionVariablesFormGroup
          "></sob-reactive-range-distribution-ratio-variables>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterSplitterComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
