import { Completion } from '@codemirror/autocomplete';

const keywords = 'as break case catch continue default do else finally for foreach if in is new switch try while'
  .split(' ')
  .map(value => {
    return { label: value, type: 'keyword' } as Completion;
  });

const types = ' bool byte char decimal double short int long string var'.split(' ').map(value => {
  return { label: value, type: 'type' } as Completion;
});

export const baseCodeEditorAutoCompletions: Completion[] = keywords.concat(...types);
