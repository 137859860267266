import * as wjcCore from '@grapecity/wijmo';

import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum ThirdPartySourceSheetRows {
  NAME = 0,
  VOLUMETRIC_FLOW_RATE = 2,
  GHG_INTENSITY = 3,
  LIGHT_ENDS = 11,
  LIGHT_NAPHTHA = 12,
  HEAVY_NAPHTHA = 13,
  DISTILLATE = 14,
  LGO = 15,
  HGO = 16,
  VGO = 17,
  HVGO = 18,
  RESID = 19,
  PRICE = 22,
}

export const ThirdPartySourceCellConfig: FlexSheetCellConfig[] = [
  {
    row: ThirdPartySourceSheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.VOLUMETRIC_FLOW_RATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.GHG_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.LIGHT_ENDS,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.LIGHT_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.HEAVY_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.DISTILLATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.LGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.HGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.VGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.HVGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.RESID,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartySourceSheetRows.PRICE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
