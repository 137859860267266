import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CaseSettings } from '../../_models';
import { CoreService } from '../../_services/core.service';
import { FlowsheetService } from '../../_services/flowsheet.service';

@Component({
  selector: 'sob-case-settings',
  templateUrl: './case-settings.component.html',
  styleUrls: ['./case-settings.component.css'],
})
export class CaseSettingsComponent implements OnInit {
  caseSettings: CaseSettings;

  constructor(
    private coreService: CoreService,
    private flowsheetService: FlowsheetService,
    private ref: ChangeDetectorRef
  ) {
    this.flowsheetService.openCaseSettingsRequest.subscribe(() => {
      this.openCaseSettings();
    });
  }

  ngOnInit() {
    $('#caseSettingsModal').on('hide.bs.modal', () => {
      this.coreService.saveCaseSettings(this.caseSettings);
    });
  }

  openCaseSettings(): void {
    this.caseSettings = null;
    this.ref.detectChanges();
    this.caseSettings = new CaseSettings(this.coreService.currentCase.caseSettings, this.coreService.currentCase);
    $('#caseSettingsModal').modal('show');
  }
}
