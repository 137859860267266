<ul class="nav nav-pills" role="tablist">
  <li role="presentation" class="active custom-pill">
    <a
      href="#{{ idPrefix }}ufgr{{ data.type }}ChartTab"
      style="font-size: 11px"
      role="tab"
      data-toggle="tab"
      [attr.aria-controls]="'#{{idPrefix}}ufgr{{data.type}}ChartTab'">
      Pie Chart
    </a>
  </li>

  <li role="presentation" class="custom-pill">
    <a
      href="#{{ idPrefix }}ufgr{{ data.type }}TableTab"
      style="font-size: 11px"
      role="tab"
      data-toggle="tab"
      [attr.aria-controls]="'#{{idPrefix}}ufgr{{data.type}}TableTab'">
      Table
    </a>
  </li>
</ul>

<div class="tab-content">
  <div role="tabpanel" class="tab-pane fade in active" id="{{ idPrefix }}ufgr{{ data.type }}ChartTab">
    <div id="{{ idPrefix }}ufgr{{ data.type }}"></div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="{{ idPrefix }}ufgr{{ data.type }}TableTab">
    <div class="row">
      <div class="col-xs-12">
        <h3 class="m-t-sm text-center">{{ data.displayTitle }}</h3>
        <table class="table" id="{{ idPrefix }}ufgr{{ data.type }}Table" style="width: 100%">
          <thead>
            <tr>
              <th>Unit Operation</th>
              <th>{{ changeUnitColumnName() }}</th>
            </tr>
          </thead>

          <tfoot>
            <tr>
              <th class="text-right">Total</th>
              <th>{{ data.total | thousandSeparator }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
