import { BaseObject } from '../base-object';
import { Case } from '../case';
import { CaseStudyCategory } from '../_case-study/case-study-category';
import { CaseStudyManager } from '../_case-study/case-study-manager';
import { MultiPeriodParameter } from './multi-period-parameter';
import { KeyValueObject } from '../key-value-object';

export class MultiPeriodSettings extends BaseObject {
  category = 'multiPeriodSettings';

  numberOfSteps: number;
  evaluateInventory: boolean;
  multiPeriodParameters: MultiPeriodParameter[] = [];
  multiPeriodResults: KeyValueObject<number>[] = [];

  categories: Array<CaseStudyCategory> = [];

  constructor(multiPeriodSettings: MultiPeriodSettings | any, ownerCase: Case) {
    super(multiPeriodSettings.id, ownerCase);
    this.numberOfSteps = multiPeriodSettings.numberOfSteps;
    this.evaluateInventory = !!multiPeriodSettings.evaluateInventory;
  }

  addToCategories(category: CaseStudyCategory): void {
    this.categories.push(category);
  }

  getMultiPeriodProvidersByCategoryName(categoryName: string): Array<BaseObject> {
    let selectedCategory: CaseStudyCategory = null;

    for (const category of this.categories) {
      if (category.categoryName === categoryName) {
        selectedCategory = category;
      }
    }

    return this.caseStudyManager.getMultiPeriodProvidersForCategory(selectedCategory);
  }

  addMultiPeriodParameter(simulationVariableId: string) {
    const sv = this.ownerCase.getSimulationVariable(simulationVariableId);

    if (this.multiPeriodParameters.find(p => p.simulationVariable === sv)) {
      return;
    }

    const mpp = new MultiPeriodParameter({ simulationVariable: sv }, this.ownerCase);

    this.multiPeriodParameters.push(mpp);
    this.ownerCase.addToPools(mpp);
  }

  removeMultiPeriodParameter(id: string) {
    const index = this.multiPeriodParameters.findIndex(p => p.id === id);

    if (index > -1) {
      this.multiPeriodParameters.splice(index, 1);
    }
  }

  removeMultiPeriodParameterBySimulationVariableId(simulationVariableId: string) {
    const index = this.multiPeriodParameters.findIndex(p => p.simulationVariable.id === simulationVariableId);

    if (index > -1) {
      this.multiPeriodParameters.splice(index, 1);
    }
  }

  get caseStudyManager(): CaseStudyManager {
    return this.ownerCase.caseStudyManager;
  }

  override dePersist(persistedObj: any) {
    for (const pId of persistedObj.multiPeriodParameters) {
      const mpp = this.ownerCase.getOtherBaseObject(pId) as MultiPeriodParameter;
      if (mpp) {
        this.multiPeriodParameters.push(mpp);
      }
    }

    this.multiPeriodResults = persistedObj.multiPeriodResults;
  }

  public toJSON(): any {
    return {
      category: this.category,
      id: this.id,
      numberOfSteps: this.numberOfSteps,
      evaluateInventory: this.evaluateInventory,
      multiPeriodParameters: this.multiPeriodParameters.map((mpp: MultiPeriodParameter) => mpp.id),
      multiPeriodResults: this.multiPeriodResults,
    };
  }
}
