import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { KpiManager } from '../../../_models/_case-study/kpi-manager';
import { KpiManagerComponent } from '../kpi-manager/kpi-manager.component';

@Component({
  selector: 'sob-kpi-manager-modal',
  templateUrl: './kpi-manager-modal.component.html',
  styleUrls: ['./kpi-manager-modal.component.css'],
})
export class KpiManagerModalComponent implements AfterViewInit {
  @Input() kpiManager: KpiManager;
  @ViewChild(KpiManagerComponent) kpiManagerComponent: KpiManagerComponent;

  ngAfterViewInit(): void {
    const $modal = $('#kpiManagerModal');

    $modal.on('hidden.bs.modal', () => {
      this.kpiManagerComponent.cleanInputs();
    });
  }
}
