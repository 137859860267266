import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';
import { FuelGasSinkImport } from '../_models/_unit-operations/fuel-gas/fuel-gas-sink-import';

export class FuelGasSinkImportFormChangeDetector extends DefaultBaseObjectFormChangeDetector {
  override detectChangesInValues(originalFormValue: any, currentValue: any): boolean {
    let changes = super.detectChangesInValues(originalFormValue, currentValue);

    const originalSinkValues = originalFormValue as FuelGasSinkImport;
    const currentSinkValues = currentValue as FuelGasSinkImport;

    if (originalSinkValues.energyStreams.length !== currentSinkValues.energyStreams.length) {
      changes = changes || true;
    }

    return changes;
  }
}
