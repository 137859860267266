import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { BaseWaterImportUnitOperation } from './base-water-import-unit-operation';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { UnitOperationConstraints } from '../unit-operation-constraints';
import { Quantity } from '../../../_config/quantity.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';

declare let unitConverter: any;

export class WaterSinkImport extends BaseWaterImportUnitOperation implements KpiProvider {
  category = unitOperationsConfig.waterSinkImport.key;

  massFlowrate: SimulationVariable;
  pressure: SimulationVariable;
  temperature: SimulationVariable;
  constraints: UnitOperationConstraints;

  constructor(unitOperation: any | WaterSinkImport, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.massFlowrate = new SimulationVariable(unitOperation.massFlowrate, this.ownerCase, this);
    this.pressure = new SimulationVariable(unitOperation.pressure, this.ownerCase, this);
    this.temperature = new SimulationVariable(unitOperation.temperature, this.ownerCase, this);
    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('flowrate', this.massFlowrate);
    this.constraints.addConstraint('temperature', this.temperature);
    this.constraints.addConstraint('pressure', this.pressure);
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.massFlowrate.setName(SimulationVariableName.FLOWRATE);
    this.pressure.setName(SimulationVariableName.PRESSURE);
    this.temperature.setName(SimulationVariableName.TEMPERATURE);
  }

  override setDefaultValues() {
    super.setDefaultValues();
    if (this.massFlowrate.isUndefined()) {
      this.massFlowrate.setDefaultValue(null, unitConverter.units.Massflowrate.KPPH);
      this.massFlowrate.setIsActive(true);
    }
    if (this.pressure.isUndefined()) {
      this.pressure.setDefaultValue(null, unitConverter.units.Pressure.KPAA);
    }

    if (this.temperature.isUndefined()) {
      this.temperature.setDefaultValue(null, unitConverter.units.Temperature.CELSIUS);
    }
  }

  override setQuantities() {
    super.setQuantities();
    this.massFlowrate.setQuantity(Quantity.MASSFLOWRATE);
    this.temperature.setQuantity(Quantity.TEMPERATURE);
    this.pressure.setQuantity(Quantity.PRESSURE);
  }

  override getAvailableKpis(): SimulationVariable[] {
    if (!this.ownerCase.multiPeriodEnabled) {
      return [
        this.getStreamComingInToTheUnitOp()[0].massFlowrate,
        this.getStreamComingInToTheUnitOp()[0].temperature,
        this.getStreamComingInToTheUnitOp()[0].pressure,
      ];
    }
    return [];
  }

  override dePersist(sup: any) {
    super.dePersist(sup);

    this.massFlowrate = this.getSimulationVariableSafe(sup.massFlowrate);
    this.pressure = this.getSimulationVariableSafe(sup.pressure);
    this.temperature = this.getSimulationVariableSafe(sup.temperature);

    this.setDefaultValues();
    this.setQuantities();
    this.initConstraints();
  }
}
