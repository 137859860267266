import { Injectable } from '@angular/core';
import { SuncorFluidAnalysis } from '../../_models/_fluid/suncor-fluid-analysis';
import { UnitOperation } from '../../_models';

@Injectable()
export class AssaySelectorHelper {
  private currentAnalysis: SuncorFluidAnalysis;
  private unitOperation: UnitOperation;

  setUnitOperation(unitOperation: UnitOperation) {
    this.unitOperation = unitOperation;
  }

  setCurrentAnalysis(currentAnalysis: SuncorFluidAnalysis) {
    this.currentAnalysis = currentAnalysis;
  }

  assayChanged() {
    const cuts = [
      'lightEndsCut',
      'lightNaphthaCut',
      'heavyNaphthaCut',
      'distillateCut',
      'LGOCut',
      'HGOCut',
      'VGOCut',
      'HVGOCut',
      'residCut',
    ];
    const simVarNames = ['sulfur', 'anilinePoint', 'massDensity', 'nitrogen', 'ccr'];
    for (const cutId of cuts) {
      for (const simvarName of simVarNames) {
        const simVar = this.currentAnalysis[cutId][simvarName];
        const $field = `#${cutId}${simvarName}`;
        $($field).val(simVar.convertToCaptureUnit(true));
        $(`${$field}Unit`).val(simVar.unit);
        $($field).change();
      }
    }

    $(`#compositionUnit${this.unitOperation.id}`).val(this.currentAnalysis.composition[0].unit);
    let total = 0;
    for (let i = 0; i < this.currentAnalysis.composition.length; i++) {
      const simVar = this.currentAnalysis.composition[i];
      total += simVar.convertToCaptureUnit(true);
      $(`#composition${i}`).val(simVar.convertToCaptureUnit(true));
    }

    $('#compositionTotalUO').val(total);

    $('#fluidAnalysisMassDensity').val(this.currentAnalysis.massDensity.convertToCaptureUnit(true));
    $('#fluidAnalysisMassDensityUnit').val(this.currentAnalysis.massDensity.unit);
    $('#fluidAnalysisMassDensity').change();

    $('#fluidAnalysisCcr').val(this.currentAnalysis.ccr.convertToCaptureUnit(true));
    $('#fluidAnalysisCcrUnit').val(this.currentAnalysis.ccr.unit);
    $('#fluidAnalysisCcr').change();

    $('#fluidAnalysisSulfur').val(this.currentAnalysis.sulfur.convertToCaptureUnit(true));
    $('#fluidAnalysisSulfurUnit').val(this.currentAnalysis.sulfur.unit);
    $('#fluidAnalysisSulfur').change();

    $('#fluidAnalysisNitrogen').val(this.currentAnalysis.nitrogen.convertToCaptureUnit(true));
    $('#fluidAnalysisNitrogenUnit').val(this.currentAnalysis.nitrogen.unit);
    $('#fluidAnalysisNitrogen').change();
  }
}
