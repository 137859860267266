import { Pipe, PipeTransform } from '@angular/core';
import { BaseObject } from '../_models/base-object';

@Pipe({
  name: 'alphanumericSort',
})
export class AlphanumericSortPipe implements PipeTransform {
  transform(kpiProvider: Array<BaseObject>): Array<BaseObject> {
    const numericArray: string[] = [];
    const numericWhitHyphenArray: string[] = [];

    for (const kpiObject of kpiProvider) {
      const nameArray = kpiObject.name.split(' ');
      for (let i = 0; i < nameArray.length; i++) {
        if (nameArray[i].match(/^[0-9]+$/)) {
          numericArray.push(nameArray[i]);
        }
        if (nameArray[i].match(/^[0-9]+-[0-9]+$/)) {
          numericWhitHyphenArray.push(nameArray[i]);
        }
      }
    }
    const maxNumericLength = this.findMaxStringLength(numericArray);
    const maxNumericHyphenLength = this.findMaxStringLength(numericWhitHyphenArray);

    kpiProvider = kpiProvider.sort((objectA, objectB) => {
      /* For numeric ends or beginnings */
      const arrayObjectA = objectA.name.split(' ');
      const arrayObjectB = objectB.name.split(' ');

      for (let i = 0; i < arrayObjectA.length; i++) {
        arrayObjectA[i] = this.addZerosIfNumeric(arrayObjectA[i], maxNumericLength, maxNumericHyphenLength);
      }

      for (let i = 0; i < arrayObjectB.length; i++) {
        arrayObjectB[i] = this.addZerosIfNumeric(arrayObjectB[i], maxNumericLength, maxNumericHyphenLength);
      }

      return arrayObjectA > arrayObjectB ? 1 : -1;
    });
    return kpiProvider;
  }

  private findMaxStringLength(stringArray: string[]): number {
    if (stringArray.length !== 0) {
      let stringToCompare = stringArray[0];
      for (let i = 1; i < stringArray.length; i++) {
        if (stringArray[i].length > stringToCompare.length) {
          stringToCompare = stringArray[i];
        }
      }
      if (stringToCompare.match(/^[0-9]+$/)) {
        return stringToCompare.length + 1;
      }
      return stringToCompare.length + 3;
    }
    return 0;
  }

  private addZerosIfNumeric(s: string, maxNumericLength: number, maxNumericHyphenLength: number): string {
    const numericRegEx = /^[0-9]+$/;
    const numericWhitHyphenRegEx = /^[0-9]+-[0-9]+$/;

    let result = s;
    if (s.match(numericRegEx)) {
      result = this.addZeros(s, maxNumericLength);
    }

    if (s.match(numericWhitHyphenRegEx)) {
      result = this.addZeros(s, maxNumericHyphenLength);
    }

    return result;
  }

  private addZeros(numericString: string, maxStringLength: number): string {
    const numberOfZeros = maxStringLength - numericString.length;

    for (let iterator = 0; iterator < numberOfZeros; iterator++) {
      numericString = `0${numericString}`;
    }

    return numericString;
  }
}
