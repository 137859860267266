import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FlowsheetDiagramService } from './flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from './core.service';
import { UnitOperation } from '../_models/unit-operation';
import { SankeyDiagramService } from '../flowsheet/sankey-diagram/sankey-diagram.service';
import { SubFlowsheetService } from './sub-flowsheet/sub-flowsheet.service';
import { BaseObjectFormGroupWrapper } from '../_form-utils/base-object-form-group-wrapper';
import { flowsheetHeight } from '../app.helpers';

@Injectable()
export class FlowsheetService {
  private openCaseSettingsSubject = new Subject();
  openCaseSettingsRequest = this.openCaseSettingsSubject.asObservable();

  private openPropertyWindowSubject = new Subject();
  openPropertyWindowRequest = this.openPropertyWindowSubject.asObservable();

  private openStreamPropertyWindowSubject = new Subject();
  openStreamPropertyWindowRequest = this.openStreamPropertyWindowSubject.asObservable();

  private openWaterStreamPropertyWindowSubject = new Subject();
  openWaterStreamPropertyWindowRequest = this.openWaterStreamPropertyWindowSubject.asObservable();

  private openFuelGasStreamPropertyWindowSubject = new Subject();
  openFuelGasStreamPropertyWindowRequest = this.openFuelGasStreamPropertyWindowSubject.asObservable();

  private closePropertyWindowSubject = new Subject();
  closePropertyWindowRequest = this.closePropertyWindowSubject.asObservable();

  private saveCustomCodeSubject = new Subject();
  saveCustomCodeRequest = this.saveCustomCodeSubject.asObservable();

  // at the moment of writing this - it used for mapping values from a reactive form
  private prepareUnitOperationForSaveSubject = new Subject();
  prepareUnitOperationForSaveRequest = this.prepareUnitOperationForSaveSubject.asObservable();

  private subFlowsheetOpenedSubject = new Subject<string>();
  subFlowsheetOpenedRequest = this.subFlowsheetOpenedSubject.asObservable();

  private subFlowsheetClosedSubject = new Subject<string>();
  subFlowsheetClosedRequest = this.subFlowsheetClosedSubject.asObservable();

  // TODO this should be temporary.. .when all property windows use reactive forms, this should be moved to the
  //  property window component
  propertyWindowFormGroupWrapper: BaseObjectFormGroupWrapper;

  // not sure if this service should go in the flowsheet folder...
  constructor(
    private coreService: CoreService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private sankeyService: SankeyDiagramService,
    private subFlowsheetService: SubFlowsheetService,
    private toastr: ToastrService
  ) {
    this.flowsheetDiagramService.doubleClickOnNodeRequest.subscribe((nodeId: string) => {
      this.openPropertyWindow(nodeId);
    });

    this.flowsheetDiagramService.contextClickOnNodeRequest.subscribe((nodeId: string) => {
      this.openPropertyWindow(nodeId);
    });

    this.flowsheetDiagramService.doubleClickOnLinkRequest.subscribe(linkId => {
      if (!this.coreService.currentCase.multiPeriodEnabled) {
        this.openStreamPropertyWindow(linkId);
      }
      if (this.coreService.currentCase.multiPeriodEnabled) {
        this.toastr.warning('When Multi Period is active, the property window cannot be opened', '', {
          positionClass: 'toast-top-right',
        });
      }
    });

    this.flowsheetDiagramService.doubleClickOnWaterLinkRequest.subscribe(linkId => {
      this.openWaterStreamPropertyWindow(linkId);
    });

    this.flowsheetDiagramService.doubleClickOnFuelGasLinkRequest.subscribe(linkId => {
      this.openFuelGasStreamPropertyWindow(linkId);
    });

    this.propertyWindowFormGroupWrapper = new BaseObjectFormGroupWrapper(new UntypedFormGroup({}));
  }

  openCaseSettings(): void {
    this.openCaseSettingsSubject.next();
  }

  openPropertyWindow(uoId: string): void {
    this.openPropertyWindowSubject.next(this.coreService.copyUnitOperationById(uoId));
  }

  openStreamPropertyWindow(streamId): void {
    this.openStreamPropertyWindowSubject.next(streamId);
  }

  openWaterStreamPropertyWindow(streamId): void {
    this.openWaterStreamPropertyWindowSubject.next(streamId);
  }

  openFuelGasStreamPropertyWindow(streamId): void {
    this.openFuelGasStreamPropertyWindowSubject.next(streamId);
  }

  closePropertyWindow(): void {
    this.closePropertyWindowSubject.next();
  }

  saveCustomCodeChanges(): void {
    this.saveCustomCodeSubject.next();
  }

  prepareUnitOperationForSave() {
    this.prepareUnitOperationForSaveSubject.next();
  }

  // Make unit operation non deletable when its property window is closed
  preventUnitOperationDeletion(uo: UnitOperation) {
    this.flowsheetDiagramService.preventUnitOperationDeletion(uo);
  }

  makeAllUnitOperationsDeletable() {
    this.flowsheetDiagramService.makeAllUnitOperationsDeletable();
  }

  loadUnitOperationFlowsheet(uoId: string): void {
    // this.subFlowsheetService.loadUnitOperationFlowsheet(uoId);
    this.subFlowsheetService.open(uoId);
    const unitOperationCategory = this.coreService.currentCase.getUnitOperation(uoId).category;
    this.subFlowsheetOpenedSubject.next(unitOperationCategory);
    flowsheetHeight();
  }

  updateMaxLinksForAllSubFlowsheets(): void {
    this.subFlowsheetService.updateMaxLinksForAllSubFlowsheets();
  }

  closeSubFlowsheet(): void {
    this.subFlowsheetService.close();
    this.subFlowsheetClosedSubject.next();
    this.closePropertyWindow();
  }

  exitToMainFlowsheet(): void {
    this.subFlowsheetService.exitToMainFlowsheet();
    this.subFlowsheetClosedSubject.next();
  }
}
