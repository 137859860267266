<div class="text-center" style="margin-top: 20px; margin-bottom: 20px" *ngIf="unitOperation.useSubFlowsheet">
  <a (click)="openSubFlowsheet()" class="btn btn-primary" style="margin-bottom: 15px"
    ><i class="fa fa-window-maximize"></i> Use Subflowsheet</a
  >
  <hr />
</div>
<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>

  <li role="presentation">
    <a href="#amine" aria-controls="amine" role="tab" data-toggle="tab">Utilities</a>
  </li>

  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>

  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation" *ngIf="!unitOperation.useSubFlowsheet">
    <a href="#upgCustomModels" aria-controls="upgCustomModels" role="tab" data-toggle="tab"> Custom Models </a>
  </li>

  <li role="presentation">
    <a href="#upgraderComments" aria-controls="upgraderComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="level2Enabled" class="control-label col-sm-4"> Enable Level 2 Model </label>
          <div class="col-sm-4">
            <label class="control-label">
              <input
                type="checkbox"
                id="level2Enabled"
                (ngModelChange)="changeNodeTemplate($event)"
                (click)="clearOpex()"
                [(ngModel)]="unitOperation.useSubFlowsheet" />
            </label>
          </div>

          <div class="clearfix"></div>
        </div>
        <ng-container *ngIf="!unitOperation.useSubFlowsheet">
          <div class="form-group">
            <label [attr.for]="'ccrRemoval'" class="pw-label control-label col-sm-4"> CCR Removal </label>
            <sim-var-input [inputId]="'ccrRemoval'" [simVar]="unitOperation.ccrRemoval"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'nitrogenRemoval'" class="pw-label control-label col-sm-4"> Nitrogen Removal </label>
            <sim-var-input [inputId]="'nitrogenRemoval'" [simVar]="unitOperation.nitrogenRemoval"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'sulfurRemoval'" class="pw-label control-label col-sm-4"> Sulfur Removal </label>
            <sim-var-input [inputId]="'sulfurRemoval'" [simVar]="unitOperation.sulfurRemoval"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'sweetProductRatio'" class="pw-label control-label col-sm-4">
              Sweet Product Ratio
            </label>
            <sim-var-input [inputId]="'sweetProductRatio'" [simVar]="unitOperation.sweetProductRatio"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'swellFactor'" class="pw-label control-label col-sm-4"> Swell Factor </label>
            <div class="col-sm-4">
              <select class="form-control" disabled="disabled">
                <option value="">N/A</option>
              </select>
            </div>
            <sim-var-input
              [inputId]="'swellFactor'"
              [hiddenUnit]="true"
              [simVar]="unitOperation.swellFactor"></sim-var-input>
          </div>
        </ng-container>

        <div class="form-group">
          <label [attr.for]="'sweetProductRatio'" class="pw-label control-label col-sm-4"> Volumetric Yield </label>
          <sim-var-input
            [inputId]="'volumetricYield'"
            [simVar]="unitOperation.volumetricYield"
            [readOnly]="true"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">GHG</h4>
        <ng-container *ngIf="!unitOperation.useSubFlowsheet">
          <div class="form-group">
            <label [attr.for]="'sweetGhgIntensity'" class="pw-label control-label col-sm-4">
              Sweet Product GHG Intensity
            </label>
            <sim-var-input [inputId]="'sweetGhgIntensity'" [simVar]="unitOperation.sweetGhgIntensity"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'sourGhgIntensity'" class="pw-label control-label col-sm-4">
              Sour Product GHG Intensity
            </label>
            <sim-var-input [inputId]="'sourGhgIntensity'" [simVar]="unitOperation.sourGhgIntensity"></sim-var-input>
          </div>
        </ng-container>

        <div class="form-group">
          <label [attr.for]="'absoluteGhgEmissions'" class="pw-label control-label col-sm-4">
            Absolute GHG Emissions
          </label>
          <sim-var-input
            [inputId]="'absoluteGhgEmissions'"
            [readOnly]="true"
            [simVar]="unitOperation.absoluteGhgEmissions"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'ghgEmissions'" class="pw-label control-label col-sm-4"> GHG Emissions </label>
          <sim-var-input
            [inputId]="'ghgEmissions'"
            [readOnly]="true"
            [simVar]="unitOperation.ghgEmissions"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">Cogen</h4>

        <div class="form-group">
          <label [attr.for]="'cogenElectricityGeneration'" class="pw-label control-label col-sm-4">
            Cogen Electricity Generation
          </label>
          <sim-var-input
            [inputId]="'cogenElectricityGeneration'"
            [simVar]="unitOperation.cogenElectricityGeneration"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'netElectricityExport'" class="pw-label control-label col-sm-4">
            Net Electricity Export
          </label>
          <sim-var-input
            [inputId]="'netElectricityExport'"
            [simVar]="unitOperation.netElectricityExport"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'gridIntensity'" class="pw-label control-label col-sm-4"> Grid Intensity </label>
          <sim-var-input [inputId]="'gridIntensity'" [simVar]="unitOperation.gridIntensity"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'cogenIntensity'" class="pw-label control-label col-sm-4"> Cogen Intensity </label>
          <sim-var-input [inputId]="'cogenIntensity'" [simVar]="unitOperation.cogenIntensity"></sim-var-input>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="amine">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group" *ngIf="unitOperation.useSubFlowsheet">
          <h4 class="subtitle">Hydrogen</h4>
          <label [attr.for]="'hydrogenUse'" class="pw-label control-label col-sm-4"> Hydrogen Use </label>
          <sim-var-input [inputId]="'hydrogenUse'" [readOnly]="true" [simVar]="unitOperation.hydrogenUse">
          </sim-var-input>
        </div>
        <h4 class="subtitle">Amine</h4>
        <div class="form-group">
          <label [attr.for]="'totalH2SProduction'" class="pw-label control-label col-sm-4">
            Total H2S Production
          </label>
          <sim-var-input
            [inputId]="'totalH2SProduction'"
            [readOnly]="true"
            [simVar]="unitOperation.totalH2SProduction"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'deaUseRate'" class="pw-label control-label col-sm-4"> DEA Use Rate </label>
          <sim-var-input [inputId]="'deaUseRate'" [simVar]="unitOperation.deaUseRate"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'amineCirculationRate'" class="pw-label control-label col-sm-4">
            Amine Circulation Rate
          </label>
          <sim-var-input
            [inputId]="'amineCirculationRate'"
            [readOnly]="true"
            [simVar]="unitOperation.amineCirculationRate"></sim-var-input>
        </div>
        <label class="subtitle">Steam</label>
        <a
          class="btn btn-outline btn-success btn-xs m-l-sm"
          href="#steamDiv"
          (click)="switchOpened()"
          aria-expanded="false">
          <i *ngIf="!isOpened" class="fa fa-plus"></i>
          <i *ngIf="isOpened" class="fa fa-minus"></i>
        </a>
        <div class="collapse" id="steamDiv" aria-expanded="false" style="">
          <div class="form-group">
            <label for="useCustomModelForHydrogenPlants" class="control-label col-sm-4">
              Custom Steam Model for Hydrogen Plants
            </label>
            <div class="col-sm-4">
              <label class="control-label">
                <input
                  type="checkbox"
                  id="useCustomModelForHydrogenPlants"
                  [(ngModel)]="unitOperation.useCustomModelForHydrogenPlants" />
              </label>
            </div>

            <div class="clearfix"></div>
          </div>

          <div style="margin-bottom: 20px">
            <sob-open-code-editor-button
              [code]="unitOperation.hydrogenPlantsCustomModel"
              [defaultCodeProvider]="steamDefaultCodeProvider"
              [disabled]="!unitOperation.useCustomModelForHydrogenPlants"
              [instanceId]="'upgraderSteamCodeEditor'"
              (codeSaved)="saveSteamCustomModel($event)"></sob-open-code-editor-button>
          </div>
          <div class="form-group">
            <label [attr.for]="'steamMake900'" class="pw-label control-label col-sm-4"> 900# Steam Make </label>
            <sim-var-input
              [inputId]="'steamMake900'"
              [readOnly]="true"
              [simVar]="unitOperation.steamMake900"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'steamUse900'" class="pw-label control-label col-sm-4"> 900# Steam Use </label>
            <sim-var-input
              [inputId]="'steamUse900'"
              [readOnly]="true"
              [simVar]="unitOperation.steamUse900"></sim-var-input>
          </div>
          <hr style="padding-bottom: 10px" />
          <div class="form-group">
            <label [attr.for]="'steamMake600'" class="pw-label control-label col-sm-4"> 600# Steam Make </label>
            <sim-var-input
              [inputId]="'steamMake600'"
              [readOnly]="true"
              [simVar]="unitOperation.steamMake600"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'steamUse600'" class="pw-label control-label col-sm-4"> 600# Steam Use </label>
            <sim-var-input
              [inputId]="'steamUse600'"
              [readOnly]="true"
              [simVar]="unitOperation.steamUse600"></sim-var-input>
          </div>
          <hr style="padding-bottom: 10px" />
          <div class="form-group">
            <label [attr.for]="'steamMake150'" class="pw-label control-label col-sm-4"> 150# Steam Make </label>
            <sim-var-input
              [inputId]="'steamMake150'"
              [readOnly]="true"
              [simVar]="unitOperation.steamMake150"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'steamUse150'" class="pw-label control-label col-sm-4"> 150# Steam Use </label>
            <sim-var-input
              [inputId]="'steamUse150'"
              [readOnly]="true"
              [simVar]="unitOperation.steamUse150"></sim-var-input>
          </div>
          <hr style="padding-bottom: 10px" />
          <div class="form-group">
            <label [attr.for]="'steamMake50'" class="pw-label control-label col-sm-4"> 50# Steam Make </label>
            <sim-var-input
              [inputId]="'steamMake50'"
              [readOnly]="true"
              [simVar]="unitOperation.steamMake50"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'steamUse50'" class="pw-label control-label col-sm-4"> 50# Steam Use </label>
            <sim-var-input
              [inputId]="'steamUse50'"
              [readOnly]="true"
              [simVar]="unitOperation.steamUse50"></sim-var-input>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="economics">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <ng-container *ngIf="!unitOperation.useSubFlowsheet">
          <div class="form-group">
            <label [attr.for]="'sweetProductPrice'" class="pw-label control-label col-sm-4">
              Sweet Product Price
            </label>
            <sim-var-input [inputId]="'sweetProductPrice'" [simVar]="unitOperation.sweetProductPrice"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'sourProductPrice'" class="pw-label control-label col-sm-4"> Sour Product Price </label>
            <sim-var-input [inputId]="'sourProductPrice'" [simVar]="unitOperation.sourProductPrice"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'electricityPrice'" class="pw-label control-label col-sm-4"> Electricity Price </label>
            <sim-var-input [inputId]="'electricityPrice'" [simVar]="unitOperation.electricityPrice"></sim-var-input>
          </div>
        </ng-container>

        <div class="form-group">
          <label [attr.for]="'revenue'" class="pw-label control-label col-sm-4"> Revenue </label>
          <sim-var-input [inputId]="'revenue'" [simVar]="unitOperation.revenue" [readOnly]="true"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'unitValue'" class="pw-label control-label col-sm-4"> Before Tax Cash Flow </label>
          <sim-var-input [inputId]="'unitValue'" [simVar]="unitOperation.unitValue" [readOnly]="true"></sim-var-input>
        </div>
        <hr />

        <h4 class="subtitle">OPEX</h4>

        <div class="form-group">
          <label [attr.for]="'opexVar'" class="pw-label control-label col-sm-4"> Variable </label>
          <sim-var-input [inputId]="'opexVar'" [simVar]="unitOperation.opexVar"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexGasDiesel'" class="pw-label control-label col-sm-4"> Gas Diesel </label>
          <sim-var-input [inputId]="'opexGasDiesel'" [simVar]="unitOperation.opexGasDiesel"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexPower'" class="pw-label control-label col-sm-4"> Power </label>
          <sim-var-input [inputId]="'opexPower'" [simVar]="unitOperation.opexPower"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexCarbonDioxide'" class="pw-label control-label col-sm-4"> CO2 </label>
          <sim-var-input
            [inputId]="'opexCarbonDioxide'"
            [simVar]="unitOperation.opexCarbonDioxide"
            [readOnly]="true"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexFixed'" class="pw-label control-label col-sm-4"> Fixed </label>
          <sim-var-input [inputId]="'opexFixed'" [simVar]="unitOperation.opexFixed"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'opexTotal'" [simVar]="unitOperation.opexTotal" [readOnly]="true"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">CAPEX</h4>

        <div class="form-group">
          <label [attr.for]="'capexAmortized'" class="pw-label control-label col-sm-4"> Amortized CAPEX </label>
          <sim-var-input [inputId]="'capexAmortized'" [simVar]="unitOperation.capexAmortized"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'capexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'capexTotal'" [simVar]="unitOperation.capexTotal" [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <p style="text-align: right; position: relative">
      <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
    </p>
    <div class="form-horizontal">
      <div class="form-group">
        <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Feed Capacity </label>
        <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.capacity"> </sim-var-input>
      </div>

      <div class="form-group">
        <label [attr.for]="'maximumSulfurContent'" class="pw-label control-label col-sm-4">
          Maximum Sulfur Content
        </label>
        <sim-var-input
          [inputId]="'maximumSulfurContent'"
          [isConstraint]="true"
          [simVar]="unitOperation.maximumSulfurContent">
        </sim-var-input>
      </div>

      <div class="form-group">
        <label [attr.for]="'maximumMassDensity'" class="pw-label control-label col-sm-4"> Maximum Mass Density </label>
        <sim-var-input
          [inputId]="'maximumMassDensity'"
          [isConstraint]="true"
          [simVar]="unitOperation.maximumMassDensity">
        </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'maximumHydrogenProduction'" class="pw-label control-label col-sm-4">
          Maximum Hydrogen Production
        </label>
        <sim-var-input
          [inputId]="'maximumHydrogenProduction'"
          [isConstraint]="true"
          [simVar]="unitOperation.maximumHydrogenProduction">
        </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'amineRateCapacity'" class="pw-label control-label col-sm-4"> Amine Rate Capacity </label>
        <sim-var-input [inputId]="'amineRateCapacity'" [isConstraint]="true" [simVar]="unitOperation.amineRateCapacity">
        </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'sulfurCapacity'" class="pw-label control-label col-sm-4"> Sulfur Capacity </label>
        <sim-var-input [inputId]="'sulfurCapacity'" [isConstraint]="true" [simVar]="unitOperation.sulfurCapacity">
        </sim-var-input>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="upgCustomModels">
    <div class="form-group">
      <label for="customModelEnabled" class="control-label col-sm-4"> Enable Custom Models </label>
      <div class="col-sm-4">
        <label class="control-label">
          <input type="checkbox" id="customModelEnabled" [(ngModel)]="unitOperation.useCustomModel" />
        </label>
      </div>

      <div class="clearfix"></div>
    </div>

    <div>
      <sob-open-code-editor-button
        [code]="unitOperation.upgraderModelScript"
        [defaultCodeProvider]="defaultCodeProvider"
        [disabled]="!unitOperation.useCustomModel"
        [instanceId]="'upgraderCodeEditor'"
        (codeSaved)="saveCustomModel($event)"></sob-open-code-editor-button>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="upgraderComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
