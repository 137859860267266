import * as XLSX from 'xlsx';

/*
 * Xlsx library utils
 */

export class XlsxUtils {
  static sheetFromArrayOfArrays(data: any): any {
    const ws = {};

    const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };

    for (let R = 0; R !== data.length; ++R) {
      for (let C = 0; C !== data[R].length; ++C) {
        if (range.s.r > R) {
          range.s.r = R;
        }
        if (range.s.c > C) {
          range.s.c = C;
        }
        if (range.e.r < R) {
          range.e.r = R;
        }
        if (range.e.c < C) {
          range.e.c = C;
        }

        const cell: any = { v: data[R][C] };

        if (cell.v == null) {
          continue;
        }

        const cellRef = XLSX.utils.encode_cell({ c: C, r: R });

        if (typeof cell.v === 'number') {
          cell.t = 'n';
        } else if (typeof cell.v === 'boolean') {
          cell.t = 'b';
        } else if (cell.v instanceof Date) {
          cell.t = 'n';
          cell.z = XLSX.SSF._table[14];
          cell.v = this.datenum(cell.v, null);
        } else {
          cell.t = 's';
        }

        ws[cellRef] = cell;
      }
    }

    if (range.s.c < 10000000) {
      ws['!ref'] = XLSX.utils.encode_range(range);
    }

    return ws;
  }

  static s2ab(s: any): any {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  static datenum(v: any, date1904: any): any {
    if (date1904) {
      v += 1462;
    }

    const epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30)).getTime()) / (24 * 60 * 60 * 1000);
  }
}
