<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>
  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#commodityTankComments" aria-controls="commodityTankComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="estimateProductValue" class="control-label col-sm-4"> Estimate Product Value </label>

          <div class="col-sm-4">
            <label for="" class="control-label">
              <input type="checkbox" id="estimateProductValue" [(ngModel)]="unitOperation.estimateProductValue" />
            </label>
          </div>
        </div>
        <div class="form-group">
          <label [attr.for]="'productPrice'" class="pw-label control-label col-sm-4"> Product Price </label>
          <sim-var-input
            [inputId]="'productPrice'"
            [readOnly]="unitOperation.estimateProductValue"
            [simVar]="unitOperation.productPrice">
          </sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'commodityVolume'" class="pw-label control-label col-sm-4"> Commodity Volume </label>
          <sim-var-input [inputId]="'commodityVolume'" [readOnly]="true" [simVar]="unitOperation.commodityVolume">
          </sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'commoditySulfur'" class="pw-label control-label col-sm-4"> Commodity Sulfur </label>
          <sim-var-input [inputId]="'commoditySulfur'" [readOnly]="true" [simVar]="unitOperation.commoditySulfur">
          </sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'commodityNitrogen'" class="pw-label control-label col-sm-4"> Commodity Nitrogen </label>
          <sim-var-input [inputId]="'commodityNitrogen'" [readOnly]="true" [simVar]="unitOperation.commodityNitrogen">
          </sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'commodityDensity'" class="pw-label control-label col-sm-4"> Commodity Density </label>
          <sim-var-input [inputId]="'commodityDensity'" [readOnly]="true" [simVar]="unitOperation.commodityDensity">
          </sim-var-input>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <div class="form-horizontal">
      <div class="form-group">
        <label for="constraintOption" class="pw-label control-label col-sm-4"> Constraint Option </label>
        <div class="col-sm-6">
          <select
            id="constraintOption"
            (change)="disableBlendProperties()"
            class="form-control"
            [(ngModel)]="unitOperation.constraintOption">
            <option value="{{ CommodityTankConstraintOption.BLEND_RATIO }}">Blend Ratio</option>
            <option value="{{ CommodityTankConstraintOption.PROPERTIES }}">Properties</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label [attr.for]="'Feedslate'" class="pw-label control-label col-sm-4 subtitle-v2"> Feedslate </label>
      </div>
      <div class="form-group">
        <label for="isOptimizable" class="control-label col-sm-4"> Float Tank </label>

        <div class="col-sm-4">
          <label for="" class="control-label">
            <input
              type="checkbox"
              id="isOptimizable"
              [disabled]="unitOperation.constraintOption === CommodityTankConstraintOption.PROPERTIES"
              [(ngModel)]="unitOperation.floatTank" />
          </label>
        </div>
      </div>
      <div class="form-group">
        <label for="typeDD" class="pw-label control-label col-sm-4"> Blend Recipe Option </label>
        <div class="col-sm-6">
          <select
            [disabled]="
              unitOperation.floatTank || unitOperation.constraintOption === CommodityTankConstraintOption.PROPERTIES
            "
            id="typeDD"
            class="form-control"
            [(ngModel)]="unitOperation.blendRecipeOption">
            <option value="exact">Exact</option>
            <option value="range">Range</option>
          </select>
        </div>
      </div>
      <sob-range-distribution-ratio-variable
        [blendRecipeOption]="unitOperation.blendRecipeOption"
        [readonly]="
          unitOperation.floatTank || unitOperation.constraintOption === CommodityTankConstraintOption.PROPERTIES
        "
        [ratioVariables]="unitOperation.distributionRatioVariables">
      </sob-range-distribution-ratio-variable>
      <div class="form-group">
        <hr style="padding-top: 20px" />
        <label class="pw-label control-label col-sm-4 subtitle-v2"> Constraints </label>
      </div>
    </div>
    <p style="text-align: right; position: relative">
      <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
    </p>
    <div class="form-horizontal">
      <div class="form-group">
        <label [attr.for]="'minimumFlow'" class="pw-label control-label col-sm-4"> Minimum Demand </label>
        <sim-var-input [inputId]="'minimumFlow'" [isConstraint]="true" [simVar]="unitOperation.minimumFlow">
        </sim-var-input>
      </div>

      <div class="form-group">
        <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Demand </label>
        <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.capacity"> </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'maxSulfur'" class="pw-label control-label col-sm-4"> Minimum API </label>
        <sim-var-input
          [inputId]="'maxDensity'"
          [isConstraint]="true"
          [disabledConstraint]="unitOperation.constraintOption === CommodityTankConstraintOption.BLEND_RATIO"
          [simVar]="unitOperation.maxDensity">
        </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'maxSulfur'" class="pw-label control-label col-sm-4"> Maximum Sulfur </label>
        <sim-var-input
          [inputId]="'maxSulfur'"
          [isConstraint]="true"
          [disabledConstraint]="unitOperation.constraintOption === CommodityTankConstraintOption.BLEND_RATIO"
          [simVar]="unitOperation.maxSulfur">
        </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'maxNitrogen'" class="pw-label control-label col-sm-4"> Maximum Nitrogen </label>
        <sim-var-input
          [inputId]="'maxNitrogen'"
          [isConstraint]="true"
          [disabledConstraint]="unitOperation.constraintOption === CommodityTankConstraintOption.BLEND_RATIO"
          [simVar]="unitOperation.maxNitrogen">
        </sim-var-input>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="commodityTankComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
