import { AfterViewInit, Component } from '@angular/core';
import * as go from 'gojs';

@Component({
  selector: 'sob-diagram-comment-button',
  templateUrl: './diagram-comment-button.component.html',
  styleUrls: ['./diagram-comment-button.component.css'],
})
export class DiagramCommentButtonComponent implements AfterViewInit {
  $ = go.GraphObject.make;
  private palette: go.Palette;

  ngAfterViewInit() {
    this.init();
  }

  private init(): void {
    this.palette = this.initPaletteElement();
    this.palette.nodeTemplate = this.getPaletteNodeTemplate();

    this.palette.model.nodeDataArray = [
      {
        name: 'Comments',
        category: 'diagram-comment',
      },
    ];
  }

  private initPaletteElement() {
    return this.$(go.Palette, 'diagramCommentsButton', {
      autoScale: go.Diagram.None,
      contentAlignment: go.Spot.TopLeft,
      'toolManager.hoverDelay': 60,
      allowZoom: false,
      padding: 0,
    });
  }

  private getPaletteNodeTemplate(): any {
    return this.$(
      go.Node,
      'Auto',
      {
        width: 28,
        height: 28,
        selectionAdorned: false,
      },
      this.$(go.Shape, {
        margin: 0,
        figure: 'Rectangle',
        fill: 'Transparent',
        stroke: '#5f5f5f',
        strokeWidth: 0,
        name: 'BACKGROUND',
      }),
      this.$(
        go.Panel,
        'Vertical',
        this.$(go.TextBlock, { text: '\uf0e6', font: '12pt FontAwesome' })
        // this.$(go.Picture,
        //   {desiredSize: new go.Size(25, 25), margin: 5, name: 'ICON'},
        //   // Picture.source is data bound to the "source" attribute of the model data
        //   new go.Binding('source', 'icon'))
      )
    );
  }
}
