import { Injectable } from '@angular/core';
import { Case, CaseSettings, SimulationVariable } from '../../_models';
import { UnitOperationFactory } from '../../_models/_unit-operations/unit-operation-factory';
import { CaseStudyManager } from '../../_models/_case-study/case-study-manager';
import { KpiManager } from '../../_models/_case-study/kpi-manager';
import { ObjectiveFunction } from '../../_models/_optimization/objective-function';
import { SuncorCalculator } from '../../_models/suncor-calculator';
import { OtherBaseObjectPoolDeserializer } from './other-base-object-pool-deserializer';
import { Flowsheet } from '../../_models/flowsheet-manager/flowsheet';
import { AssayManager } from '../../_models/_fluid/assay-manager';
import { ResourceDataService } from '../resource-data.service';
import { ConstraintViolationEntry } from '../../_models/constraint-violation-entry';
import { MarginalValueEntry } from '../../_models/marginal-value-entry';
import { StreamFactory } from '../../_models/_streams/stream-factory';
import { WaterSourceImport } from '../../_models/_unit-operations/utilties/water-source-import';
import { WaterSinkImport } from '../../_models/_unit-operations/utilties/water-sink-import';
import { FuelGasSourceImport } from '../../_models/_unit-operations/fuel-gas/fuel-gas-source-import';
import { FuelGasSinkImport } from '../../_models/_unit-operations/fuel-gas/fuel-gas-sink-import';
import { WaterPipe } from '../../_models/_unit-operations/utilties/water-pipe';
import { MultiPeriodSettings } from '../../_models/_multi-period/multi-period-settings';
import { BaseObjectComment } from '../../_models/base-object-comment';

@Injectable()
export class CaseDeserializerService {
  constructor(private resourceDataService: ResourceDataService) {}

  deserialize(serializedCase: string): Case {
    const plainCase = JSON.parse(serializedCase);
    return this.plainObjectToCase(plainCase);
  }

  plainObjectToCase(plainCase): Case {
    const c = new Case(plainCase);

    this.loadSimulationVariables(plainCase, c);

    const otherBaseObjectDeserializer = new OtherBaseObjectPoolDeserializer(plainCase);
    otherBaseObjectDeserializer.deserializeSimulationVariableArrays(c);

    this.loadUnitOperations(plainCase, c);
    this.loadMaterialStreams(plainCase, c);
    this.loadFlowsheets(plainCase, c);
    this.loadComments(plainCase, c);
    otherBaseObjectDeserializer.deserializeLowerHierarchyObjects(c);
    this.loadOtherBaseObjects(plainCase, c);
    return c;
  }

  private loadSimulationVariables(plainCase, c: Case) {
    // Simulation variables are (so far) the most basic object,
    // so it's good to load them first
    // eslint-disable-next-line guard-for-in
    for (const svId in plainCase.simulationVariablePool) {
      // owner to be set later, while de
      if (plainCase.simulationVariablePool[svId].quantity === 'Tempreature') {
        plainCase.simulationVariablePool[svId].quantity = 'Temperature';
      }

      const sv: SimulationVariable = new SimulationVariable(plainCase.simulationVariablePool[svId], c, null);
      c.addToPools(sv);
    }
  }

  private loadUnitOperations(plainCase, c: Case) {
    // load unit operation pool
    // eslint-disable-next-line guard-for-in
    for (const uoId in plainCase.unitOperationPool) {
      const serializedUnitOperation = plainCase.unitOperationPool[uoId];
      const rd = this.resourceDataService.getUOResourceData(serializedUnitOperation.category);
      const uo = UnitOperationFactory.createUnitOperationAndSetDefaultValues(serializedUnitOperation, c);

      uo.dePersist(serializedUnitOperation);
      uo.setSimulationVariablesOwner();
      uo.setSimulationVariableNames();
      uo.loadResourceData(rd);
      c.addToPools(uo);
    }
  }

  private loadComments(plainCase, c: Case) {
    if (!plainCase.commentsPool) {
      return;
    }

    for (const comment of plainCase.commentsPool) {
      const newComment = BaseObjectComment.createCommentAndSetValues(comment);
      newComment.dePersist(comment);
      c.commentsPool.push(newComment);
    }
  }

  private loadMaterialStreams(plainCase, c: Case) {
    // eslint-disable-next-line guard-for-in
    for (const streamId in plainCase.materialStreamPool) {
      const serializedStream = plainCase.materialStreamPool[streamId];
      const stream = StreamFactory.create(serializedStream, c);
      stream.dePersist(serializedStream);
      stream.setQuantities(); // may be added to dePersist
      stream.setSimulationVariablesOwner();
      stream.setSimulationVariableNames();
      c.addToPools(stream);
    }
  }

  private loadFlowsheets(plainCase, c: Case) {
    // eslint-disable-next-line guard-for-in
    for (const fId in plainCase.flowsheetPool) {
      const serializedFlowsheet = plainCase.flowsheetPool[fId];
      const flowsheet = new Flowsheet(serializedFlowsheet, c);
      flowsheet.dePersist(serializedFlowsheet);
      c.addToPools(flowsheet);
    }
  }

  private loadOtherBaseObjects(plainCase, c: Case) {
    // eslint-disable-next-line guard-for-in
    this.loadCaseSettings(plainCase, c);
    this.loadMultiPeriodSettings(plainCase, c);
    this.loadCaseStudyManager(plainCase, c);
    this.loadAssayManager(plainCase, c);
    this.loadObjectiveFunction(plainCase, c);
    this.loadCalculator(plainCase, c);
    this.loadConstraintViolationEntries(plainCase, c);
    this.loadWaterPipeSegments(plainCase, c);
    this.loadWaterSourceImportInformationStreams(plainCase, c);
    this.loadWaterSinkImportInformationStreams(plainCase, c);
    this.loadFuelGasSinkImportEnergyStreams(plainCase, c);
    this.loadFuelGasSourceImportInformationStreams(plainCase, c);
  }

  private loadCaseSettings(plainCase: any, c: Case) {
    c.caseSettings = <CaseSettings>c.getOtherBaseObject(plainCase.caseSettings);

    if (!c.caseSettings) {
      c.caseSettings = new CaseSettings({}, c);
      c.addToPools(c.caseSettings);
      c.caseSettings.addSimVarsToPool();
    }
  }

  private loadConstraintViolationEntries(plainCase: any, c: Case) {
    if (plainCase.hasOwnProperty('constraintViolationEntries')) {
      for (let i = 0; i < plainCase.constraintViolationEntries.length; i++) {
        c.constraintViolationEntries.push(new ConstraintViolationEntry(plainCase.constraintViolationEntries[i]));
      }
    }
    if (plainCase.hasOwnProperty('marginalValueEntries')) {
      for (let i = 0; i < plainCase.marginalValueEntries.length; i++) {
        c.marginalValueEntries.push(new MarginalValueEntry(plainCase.marginalValueEntries[i]));
      }
    }
  }

  private loadCaseStudyManager(plainCase: any, c: Case) {
    c.caseStudyManager = <CaseStudyManager>c.getOtherBaseObject(plainCase.caseStudyManager);
    if (!c.caseStudyManager) {
      c.caseStudyManager = new CaseStudyManager({}, c);
      c.addToPools(c.caseStudyManager);

      c.caseStudyManager.kpiManager = new KpiManager({}, c);
      c.addToPools(c.caseStudyManager.kpiManager);
    } else {
      const serializedCaseStudy = plainCase.otherBaseObjectPool[plainCase.caseStudyManager];
      c.caseStudyManager.kpiManager = <KpiManager>c.getOtherBaseObject(serializedCaseStudy.kpiManager);
    }
    c.initCaseStudyManagerCategories(); // wat
  }

  private loadAssayManager(plainCase: any, c: Case) {
    c.assayManager = <AssayManager>c.getOtherBaseObject(plainCase.assayManager);
    if (!c.assayManager) {
      c.assayManager = new AssayManager({}, c);
      c.addToPools(c.assayManager);
    }
    c.assayManager.setSimulationVariablesOwner();
  }

  private loadObjectiveFunction(plainCase: any, c: Case) {
    c.objectiveFunction = <ObjectiveFunction>c.getOtherBaseObject(plainCase.objectiveFunction);

    if (!c.objectiveFunction) {
      c.objectiveFunction = new ObjectiveFunction({}, c);
      c.addToPools(c.objectiveFunction);
      // c.objectiveFunction.addSimVarsToPool();
    }
  }

  private loadCalculator(plainCase: any, c: Case) {
    c.calculator = <SuncorCalculator>c.getOtherBaseObject(plainCase.calculator);
    if (!c.calculator) {
      c.calculator = new SuncorCalculator({}, c);
      c.addToPools(c.calculator);
      c.calculator.addSimVarsToPool();
    }
  }

  private loadWaterPipeSegments(plainCase, c: Case) {
    const waterPipeList = c.filterUnitOperations(uo => uo instanceof WaterPipe) as WaterPipe[];

    for (const waterPipe of waterPipeList) {
      const plainWaterPipe = plainCase.unitOperationPool[waterPipe.id];
      waterPipe.dePersistPipeSegments(plainWaterPipe);
    }
  }

  private loadWaterSourceImportInformationStreams(plainCase: any, c: Case) {
    const waterSourceImportList = c.filterUnitOperations(uo => uo instanceof WaterSourceImport) as WaterSourceImport[];

    for (const waterSourceImport of waterSourceImportList) {
      const plainWaterSourceImport = plainCase.unitOperationPool[waterSourceImport.id];
      waterSourceImport.dePersistInformationStreams(plainWaterSourceImport);
    }
  }

  private loadWaterSinkImportInformationStreams(plainCase: any, c: Case) {
    const waterSinkImportList = c.filterUnitOperations(uo => uo instanceof WaterSinkImport) as WaterSinkImport[];

    for (const waterSinkImport of waterSinkImportList) {
      const plainWaterSourceImport = plainCase.unitOperationPool[waterSinkImport.id];
      waterSinkImport.dePersistInformationStreams(plainWaterSourceImport);
    }
  }

  private loadMultiPeriodSettings(plainCase: any, c: Case) {
    c.multiPeriodSettings = c.getOtherBaseObject(plainCase.multiPeriodSettings) as MultiPeriodSettings;

    if (!c.multiPeriodSettings) {
      c.multiPeriodSettings = new MultiPeriodSettings({}, c);
      c.addToPools(c.multiPeriodSettings);
      c.multiPeriodSettings.addSimVarsToPool();
    }
    c.initMultiPeriodCategories();
  }

  private loadFuelGasSinkImportEnergyStreams(plainCase: any, c: Case) {
    const fuelGasImportList = c.filterUnitOperations(uo => uo instanceof FuelGasSinkImport) as FuelGasSinkImport[];
    for (const fuelGasImport of fuelGasImportList) {
      const plainFuelGasImport = plainCase.unitOperationPool[fuelGasImport.id];
      fuelGasImport.dePersistEnergyStreams(plainFuelGasImport);
    }
  }

  private loadFuelGasSourceImportInformationStreams(plainCase: any, c: Case) {
    const fuelGasSourceList = c.filterUnitOperations(uo => uo instanceof FuelGasSourceImport) as FuelGasSourceImport[];

    for (const fuelGasSourceImport of fuelGasSourceList) {
      const plainFuelGasSourceImport = plainCase.unitOperationPool[fuelGasSourceImport.id];
      fuelGasSourceImport.dePersistInformationStreams(plainFuelGasSourceImport);
    }
  }
}
