import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { BaseObjectFormGroupWrapper } from '../../_form-utils/base-object-form-group-wrapper';

export abstract class AbstractFormGroupPropertyWindow {
  abstract formGroupWrapper: BaseObjectFormGroupWrapper;

  abstract addControls();

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  init() {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
  }

  isTouchedOrDirty(control: AbstractControl) {
    return control.touched || control.dirty;
  }

  shouldShowValidationError(formControl: AbstractControl) {
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }

  shouldShowFormArrayValidationError(formArray: UntypedFormArray, controlIndex: number) {
    return formArray.invalid && (formArray.controls[controlIndex].dirty || formArray.controls[controlIndex].touched);
  }
}
