import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { SimulationVariable } from '../../simulation-variable';
import { FuelGasUnitOperation } from './fuel-gas-unit-operation';
import { Quantity } from '../../../_config/quantity.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';

export class FuelGasSink extends FuelGasUnitOperation implements KpiProvider {
  category = unitOperationsConfig.fuelGasSink.key;
  flowrate: SimulationVariable;

  constructor(unitOperation: any | FuelGasSink, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.flowrate = new SimulationVariable(unitOperation.flowrate || {}, this.ownerCase, this);
    this.setQuantities();
  }

  override setQuantities() {
    super.setQuantities();
    this.flowrate.setQuantity(Quantity.MOLARFLOWRATE);
  }

  override setSimulationVariableNames() {
    this.flowrate.setName(SimulationVariableName.FLOWRATE);
  }

  getAvailableKpis(): SimulationVariable[] {
    if (!this.ownerCase.multiPeriodEnabled) {
      return [this.flowrate];
    }
    return [];
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.flowrate = this.getSimulationVariableSafe(sup.flowrate);
    this.setQuantities();
  }
}
