import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommodityTank } from '../../../_models/_unit-operations/commodity-tank';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { RangeDistributionRatioVariableComponent } from '../../shared/range-distriburion-ratio-variable/range-distribution-ratio-variable.component';
import { CommodityTankConstraintOption } from '../../../_config/unit-operations/commodity-tank-enums';

@Component({
  selector: 'sob-commodity-tank',
  templateUrl: './commodity-tank.component.html',
  styleUrls: ['./commodity-tank.component.css'],
})
export class CommodityTankComponent {
  protected CommodityTankConstraintOption = CommodityTankConstraintOption;

  @Input() unitOperation: CommodityTank;

  @ViewChild(RangeDistributionRatioVariableComponent) distribution: RangeDistributionRatioVariableComponent;
  saveSubscription: Subscription;

  constructor(private fb: UntypedFormBuilder, private flowsheetService: FlowsheetService) {
    this.saveSubscription = this.flowsheetService.prepareUnitOperationForSaveRequest.subscribe(() => {
      this.save();
      this.saveSubscription.unsubscribe();
    });
  }

  save() {
    this.distribution.rows.controls.forEach((control, i) => {
      this.unitOperation.distributionRatioVariables[i].value = control.get('value').value;
      this.unitOperation.distributionRatioVariables[i].minimumValue = control.get('minimumValue').value;
      this.unitOperation.distributionRatioVariables[i].maximumValue = control.get('maximumValue').value;
    });
  }

  disableBlendProperties() {
    if (this.unitOperation.isBlendRatioConstraintSelected()) {
      this.unitOperation.maxSulfur.isActive = false;
      this.unitOperation.maxNitrogen.isActive = false;
      this.unitOperation.maxDensity.isActive = false;
    }
  }
}
