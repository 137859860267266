import { Injectable } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { BitumenConversionOutlet } from '../_models/_unit-operations/bitumen-conversion-outlet';
import { IdentifiableFormGroup } from '../shared/identifiable-form-group';
import { SimulationVariableForm, SimVarFormBuilder } from './sim-var-form-builder';

export type BitumenConversionOutletForm = IdentifiableFormGroup<{
  fluidAnalysisId: FormControl<string>;
  yield: SimulationVariableForm;
  price: SimulationVariableForm;
}>;

@Injectable({ providedIn: 'root' })
export class BitumenConversionOutletFormBuilder {
  constructor(private svfb: SimVarFormBuilder) {}

  formArray(outletsArray: BitumenConversionOutlet[]): FormArray<BitumenConversionOutletForm> {
    return new FormArray<BitumenConversionOutletForm>(
      outletsArray.map(outlet => {
        return this.control(outlet);
      })
    );
  }

  control(outlet: BitumenConversionOutlet): BitumenConversionOutletForm {
    return new IdentifiableFormGroup(outlet.unitOperationId, {
      fluidAnalysisId: new FormControl(outlet.fluidAnalysisId, [Validators.required]),
      yield: this.svfb.populatedControl(outlet.yield, false, false, [Validators.required]),
      price: this.svfb.populatedControl(outlet.price, false, false, [Validators.required]),
    });
  }
}
