<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#waterSinkComments" aria-controls="waterSinkComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Conditions</h4>
        <hr />
        <br />

        <div class="form-group">
          <label [attr.for]="'massFlowrate'" class="pw-label control-label col-sm-4"> Mass Flowrate </label>
          <sim-var-input [inputId]="'massFlowrate'" [readOnly]="true" [simVar]="unitOperation.massFlowrate">
          </sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'temperature'" class="pw-label control-label col-sm-4"> Temperature </label>
          <sim-var-input [inputId]="'temperature'" [readOnly]="true" [simVar]="unitOperation.temperature">
          </sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'pressure'" class="pw-label control-label col-sm-4"> Pressure </label>
          <sim-var-input [inputId]="'pressure'" [readOnly]="true" [simVar]="unitOperation.pressure"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <p style="text-align: right; position: relative">
      <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
    </p>
    <div class="form-horizontal">
      <div class="form-group">
        <label [attr.for]="'minimumMassFlowrate'" class="pw-label control-label col-sm-4">
          Minimum Mass Flowrate
        </label>
        <sim-var-input
          [inputId]="'minimumMassFlowrate'"
          [isConstraint]="true"
          [simVar]="unitOperation.minimumMassFlowrate">
        </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Mass Flowrate </label>
        <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.maximumMassFlowrate">
        </sim-var-input>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterSinkComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
