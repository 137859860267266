import { Injectable } from '@angular/core';
import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { AbstractConstraintsHandler } from './abstract-constraints-handler';
import { CoreService } from '../core.service';
import { ConstraintsSheetHandler } from './constraints-sheet-handler';
import { Case, UnitOperation } from '../../_models';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { SubFlowsheetService } from '../sub-flowsheet/sub-flowsheet.service';
import { FlowsheetTreeService } from '../sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { NumberToUnitConverter } from '../number-to-unit-converter.service';
import { UpgraderConstraintsSheetHandler } from './upgrader-constraints-sheet-handler';
import { ExtractionConstraintsSheetHandler } from './extraction-constraints-sheet-handler';

@Injectable({
  providedIn: 'root',
})
export class ConstraintsSpreadsheetService {
  private spreadsheet: wjcSheet.FlexSheet;

  constraintsSheetHandler: ConstraintsSheetHandler;
  upgraderConstraintsSheetHandler: UpgraderConstraintsSheetHandler;
  extractionConstraintsSheetHandler: ExtractionConstraintsSheetHandler;

  sheetHandlers: AbstractConstraintsHandler[];

  constructor(
    private coreService: CoreService,
    private subFlowsheetService: SubFlowsheetService,
    private flowsheetTreeService: FlowsheetTreeService,
    private nuc: NumberToUnitConverter
  ) {}

  init(spreadsheet: wjcSheet.FlexSheet) {
    this.spreadsheet = spreadsheet;
    this.constraintsSheetHandler = new ConstraintsSheetHandler(this.coreService, this.flowsheetTreeService, this.nuc);
    this.constraintsSheetHandler.init(this.spreadsheet, 'Constraints - Level 1');

    this.upgraderConstraintsSheetHandler = new UpgraderConstraintsSheetHandler(
      this.coreService,
      this.flowsheetTreeService,
      this.nuc
    );
    this.upgraderConstraintsSheetHandler.init(this.spreadsheet, 'Upgrader Constraints');

    this.extractionConstraintsSheetHandler = new ExtractionConstraintsSheetHandler(
      this.coreService,
      this.flowsheetTreeService,
      this.nuc
    );
    this.extractionConstraintsSheetHandler.init(this.spreadsheet, 'Extraction Constraints');

    this.sheetHandlers = [
      this.constraintsSheetHandler,
      this.upgraderConstraintsSheetHandler,
      this.extractionConstraintsSheetHandler,
    ];

    spreadsheet.beginningEdit.addHandler((ss: wjcSheet.FlexSheet, flexSheetEvent: any) => {
      if (flexSheetEvent.data.code === 'Backspace' || flexSheetEvent.data.code === 'Delete') {
        this.sheetHandlers.forEach(sh => sh.handleDeleteEvent(ss, flexSheetEvent));
      }
    });

    spreadsheet.cellEditEnding.addHandler((ss: wjcSheet.FlexSheet, flexSheetEvent: any) => {
      this.sheetHandlers.forEach(sh => sh.handleEditEvent(ss, flexSheetEvent));
    });

    spreadsheet.pastingCell.addHandler((ss: wjcSheet.FlexSheet, flexSheetEvent: any) => {
      this.sheetHandlers.forEach(sh => sh.handlePasteEvent(ss, flexSheetEvent));
    });
  }

  caseToSpreadsheet(c: Case): void {
    this.sheetHandlers.forEach(sh => sh.caseToSpreadsheet(c));
  }

  addUnitOperation(unitOperation: SuncorUnitOperation): void {
    this.sheetHandlers.forEach(sh => sh.addUnitOperation(unitOperation));
  }

  clearResults(): void {
    this.sheetHandlers.forEach(sh => sh.clearResults());
  }

  updateConstraintValue(uo: UnitOperation): void {
    this.sheetHandlers.forEach(sh => sh.updateConstraintValue(uo));
  }

  removeUnitOperation(unitOperation: SuncorUnitOperation): void {
    this.sheetHandlers.forEach(sh => sh.removeUnitOperation(unitOperation));
  }
}
