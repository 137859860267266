import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver/FileSaver';
import { Case } from '../_models';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  downloadFile(cases: Case[], serializedProject: string, filename?: string) {
    this.generateFile(cases, serializedProject).then(function (content) {
      saveAs(content, filename);
    });
  }

  generateFile(cases: Case[], serializedProject: string) {
    const jszip = new JSZip();

    cases.forEach(function (c: Case) {
      const diagram = c.getDiagramString();

      // TODO WTF! so this property is not serialized in the case itself but in the zip file!?
      // This generates a file with the same name as the case
      jszip.file(c.name || 'unnamed', diagram, { binary: true });

      // eslint-disable-next-line guard-for-in
      for (const flowsheet in c.flowsheetPool) {
        const f = c.flowsheetPool[flowsheet];

        // and this only writes a file for each of the subflowsheet diagrams diagrams - but actually,
        // the main flowsheet does have an entry in the flowsheet pool...
        if (f.unitOperationId) {
          jszip.file(`${f.unitOperationId}@${c.id}` || 'unnamed', f.getDiagramJson(), { binary: true });
        }
      }
    });

    jszip.file('pools', serializedProject, { binary: true });
    return jszip.generateAsync({ type: 'blob' });
  }

  getPools(file) {
    return this.getFile(file, 'pools');
  }

  getFile(file, filename) {
    return JSZip.loadAsync(file).then(function (f) {
      return f.file(filename).async('text');
    });
  }
}
