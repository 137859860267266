import { AfterViewInit, ContentChild, Directive } from '@angular/core';
import { ReactiveVariableValueInputComponent } from './reactive-variable-value-input/reactive-variable-value-input.component';
import { ReactiveUnitSelectComponent } from './reactive-unit-select/reactive-unit-select.component';
import { ReactiveConstraintCheckBoxComponent } from './reactive-constraint-check-box/reactive-constraint-check-box.component';

@Directive({
  selector: '[sobReactiveSimVarInput]',
})
export class ReactiveSimVarInputDirective implements AfterViewInit {
  // query available on ngAfterViewInit
  @ContentChild(ReactiveVariableValueInputComponent, { static: false }) valueInput: ReactiveVariableValueInputComponent;
  @ContentChild(ReactiveUnitSelectComponent, { static: false }) unitSelect: ReactiveUnitSelectComponent;
  @ContentChild(ReactiveConstraintCheckBoxComponent, { static: false })
  isConstraint: ReactiveConstraintCheckBoxComponent;

  constructor() {}

  ngAfterViewInit() {
    this.unitSelect.unitChange.subscribe(newUnit => {
      this.valueInput.updateUnit(newUnit);
    });
  }
}
