import { unitOperationsConfig } from '../_config/unit-operations.config';
import { DistributionRatioVariable } from '../_models/_unit-operations/distribution-ratio-variable';

export class V201 {
  static updateHydrotreatersRatioVariables(serializedCase: any) {
    const hydrotreaters = Object.values(serializedCase.unitOperationPool).filter((uo: any) => {
      return uo.category === unitOperationsConfig.hydrotreater.key && !uo.hasOwnProperty('distributionRatioVariables');
    }) as any[];

    for (const uo of hydrotreaters) {
      const streams = Object.values(serializedCase.materialStreamPool).filter(
        (s: any) => s.outletUnitOperationId === uo.id
      );
      if (streams) {
        uo.distributionRatioVariables = [];
        uo.hydrogenUseRateVariables = [];
        streams.forEach((stream: any) => {
          const ratioVariable = new DistributionRatioVariable(stream.inletUnitOperationId, 1 / streams.length);
          uo.distributionRatioVariables.push(ratioVariable);
          const simVar = Object.values(serializedCase.simulationVariablePool).filter((sv: any) => {
            return sv.id === uo.hydrogenUseRate;
          })[0] as any;
          if (simVar) {
            uo.hydrogenUseRateVariables.push(new DistributionRatioVariable(stream.inletUnitOperationId, simVar.value));
          } else {
            uo.hydrogenUseRateVariables.push(new DistributionRatioVariable(stream.inletUnitOperationId, null));
          }
        });
      }
    }
  }
}
