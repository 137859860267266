import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UncertaintyAnalysisComponentService {
  private solveErrorSubject = new Subject();
  solveErrorRequest = this.solveErrorSubject.asObservable();

  notifySolveError(error: any) {
    this.solveErrorSubject.next(error);
  }
}
