import * as wjcCore from '@grapecity/wijmo';
import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { FlexSheetCellConfig } from './cell-config/flex-sheet-cell-config';

export class FlexSheetValueConverter {
  convert(spreadsheet: wjcSheet.FlexSheet, value, cellConfig: FlexSheetCellConfig): any {
    if (cellConfig.dataType === wjcCore.DataType.String) {
      return value;
    }

    if (typeof value === 'string' && value.startsWith('=')) {
      value = spreadsheet.evaluate(value);
    }

    return wjcCore.changeType(value, cellConfig.dataType);
  }
}
