import { NumberToUnitConverter } from '../number-to-unit-converter.service';
import { SteamSheetItemType } from '../../_config/spreadsheet/steam-sheet-item-type.enum';
import { FlowsheetType } from '../../_config/flowsheet-type.enum';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { UtilitySteamSpreadsheetModel } from '../../_models/utilities-spreadsheet/utility-steam-spreadsheet-model';
import { UnitOperation } from '../../_models';
import { BaseUtilitySpreadsheetModel } from '../../_models/utilities-spreadsheet/base-utility-spreadsheet-model';
import { SteamUseUnitOperation } from '../../_models/_unit-operations/steam-use-unit-operation';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { WaterBoiler } from '../../_models/_unit-operations/utilties/water-boiler';
import { Quantity } from '../../_config/quantity.enum';
import { DRU } from '../../_models/_unit-operations/dru';
import { VAC } from '../../_models/_unit-operations/vac';
import { DCU } from '../../_models/_unit-operations/dcu';
import { Hydrocracker } from '../../_models/_unit-operations/hydrocracker';
import { Hydrotreater } from '../../_models/_unit-operations/hydrotreater';
import { HydrogenPlant } from '../../_models/_unit-operations/hydrogen-plant';
import { SulfurPlant } from '../../_models/_unit-operations/sulfur-plant';
import { WaterTurbine } from '../../_models/_unit-operations/utilties/water-turbine';
import { WaterUnitOperation } from '../../_models/_unit-operations/utilties/water-unit-operation';
import { WaterSourceImport } from '../../_models/_unit-operations/utilties/water-source-import';
import { hasNumericValue } from '../../_utils/utils';
import { SteamType } from '../../_config/steam-type.enum';
import { WaterSinkImport } from '../../_models/_unit-operations/utilties/water-sink-import';
import { WaterSource } from '../../_models/_unit-operations/utilties/water-source';
import { WaterHeader } from '../../_models/_unit-operations/utilties/water-header';

declare let unitConverter: any;

export class SteamSheetHelper {
  constructor(private nuc: NumberToUnitConverter) {}

  // this reflects the order that the items will have in the spreadsheet
  itemOrderGuide: { type: string; flowsheet: string }[] = [
    { type: SteamSheetItemType.HYDROCARBON, flowsheet: FlowsheetType.HYDROCARBON },
    { type: SteamSheetItemType.UTILITIES_TITLE, flowsheet: FlowsheetType.UTILITIES },
    { type: unitOperationsConfig.waterBoiler.key, flowsheet: FlowsheetType.UTILITIES },
    { type: unitOperationsConfig.waterTurbine.key, flowsheet: FlowsheetType.UTILITIES },
    { type: unitOperationsConfig.waterSourceImport.key, flowsheet: FlowsheetType.UTILITIES },
    { type: unitOperationsConfig.waterSinkImport.key, flowsheet: FlowsheetType.UTILITIES },
    { type: unitOperationsConfig.waterSource.key, flowsheet: FlowsheetType.UTILITIES },
    { type: unitOperationsConfig.waterHeader.key, flowsheet: FlowsheetType.UTILITIES },
  ];

  calculateNewItemIndex(
    items: UtilitySteamSpreadsheetModel[],
    newItem: UtilitySteamSpreadsheetModel
  ): { index: number; firstItemOfItsType: boolean; firstUtilitiesItem } {
    const itemOfSameTypeIndex = this.findLastIndexOfTypeInItems(items, newItem.type);

    if (itemOfSameTypeIndex > -1) {
      return {
        index: itemOfSameTypeIndex + 1,
        firstItemOfItsType: false,
        firstUtilitiesItem: false,
      };
    }

    // if items of same type aren't already in the sheet
    const newItemOrder = this.itemOrderGuide.findIndex(io => io.type === newItem.type);
    let resultIndex = 0;

    // check if there are items that go before the new item
    for (let i = newItemOrder - 1; i >= 0; i--) {
      const currentType = this.itemOrderGuide[i].type;
      const newItemIndex = this.findLastIndexOfTypeInItems(items, currentType);

      if (newItemIndex > -1) {
        resultIndex = newItemIndex + 1;
        break;
      }
    }

    for (let i = newItemOrder + 1; i < this.itemOrderGuide.length; i++) {
      const currentType = this.itemOrderGuide[i];

      const newItemIndex = this.findFirstIndexOfTypeInItems(items, currentType.type);

      if (newItemIndex > -1) {
        resultIndex = newItemIndex;
        break;
      }
    }

    const isFromUtilitiesFlowsheet = this.isItemFromUtilitiesFlowsheet(newItem);
    const utilitiesTypes = this.itemOrderGuide
      .filter(io => io.flowsheet === FlowsheetType.UTILITIES)
      .map(io => io.type);

    const firstUtilitiesItem =
      isFromUtilitiesFlowsheet && items.filter(i => utilitiesTypes.includes(i.type)).length === 0;

    return {
      index: resultIndex,
      firstUtilitiesItem,
      firstItemOfItsType: true,
    };
  }

  findLastIndexOfTypeInItems(items: UtilitySteamSpreadsheetModel[], typeToSearch: string) {
    const itemsOfCurrentType = items.filter(item => item.type === typeToSearch);
    if (itemsOfCurrentType.length) {
      const lastItem = itemsOfCurrentType[itemsOfCurrentType.length - 1];

      return items.indexOf(lastItem);
    }

    return -1;
  }

  findFirstIndexOfTypeInItems(items: UtilitySteamSpreadsheetModel[], typeToSearch: string) {
    return items.findIndex(item => item.type === typeToSearch);
  }

  /**
   * Gets other the items that should be removed when a unit operation is removed, for example
   * when the last boiler is removed, its spacer should be removed as well
   * @param items The list of all the items in the spreadsheet
   * @param uo The unit operation being removed
   */
  getExtraItemsToRemove(items: UtilitySteamSpreadsheetModel[], uo: UnitOperation): UtilitySteamSpreadsheetModel[] {
    const result = [];
    const uoItem = items.find(i => i.id === uo.id);

    const itemsOfSameType = items.filter(i => i.type === uoItem.type && i.id !== uo.id);
    const itemsWithEmptyId = itemsOfSameType.filter(i => !i.id);

    if (itemsOfSameType.length === itemsWithEmptyId.length) {
      result.push(...itemsWithEmptyId);
    }

    // check if this is the last item from utilities flowsheet...
    if (this.isItemFromUtilitiesFlowsheet(uoItem)) {
      const utilitiesTypes = this.itemOrderGuide
        .filter(io => io.flowsheet === FlowsheetType.UTILITIES)
        .map(io => io.type);
      const allItemsFromUtilitiesFlowsheet = items.filter(i => utilitiesTypes.includes(i.type) && i.id !== uo.id);

      const utilitiesItemsWithoutId = allItemsFromUtilitiesFlowsheet.filter(i => !i.id);

      if (allItemsFromUtilitiesFlowsheet.length === utilitiesItemsWithoutId.length) {
        // this should only include the title.. and probably the spacers?
        result.push(...utilitiesItemsWithoutId);
      }
    }

    return result;
  }

  private isItemFromUtilitiesFlowsheet(item: UtilitySteamSpreadsheetModel) {
    const utilitiesTypes = this.itemOrderGuide
      .filter(io => io.flowsheet === FlowsheetType.UTILITIES)
      .map(io => io.type);
    return utilitiesTypes.includes(item.type);
  }

  toSpreadsheetItem(uo: UnitOperation, alternateName?: string): BaseUtilitySpreadsheetModel {
    let result: UtilitySteamSpreadsheetModel;

    if (uo instanceof SteamUseUnitOperation || uo instanceof Upgrader) {
      result = this.steamUseUnitOperationToSpreadsheetItem(uo);
    } else if (this.isBoiler(uo)) {
      result = this.boilerToSpreadsheetItem(uo, false);
    } else if (this.isWaterTurbine(uo)) {
      result = this.turbineToSpreadsheetItem(uo, false);
    } else if (this.isWaterSourceImport(uo)) {
      result = this.waterSourceImportToSpreadsheetItem(uo, false);
    } else if (this.isWaterSinkImport(uo)) {
      result = this.waterSinkImportToSpreadsheetItem(uo, false);
    } else if (this.isWaterSource(uo)) {
      result = this.waterSourceToSpreadsheetItem(uo, false);
    } else if (this.isWaterHeader(uo)) {
      result = this.waterHeaderToSpreadsheetItem(uo, false);
    }

    if (alternateName) {
      result.unitOperationName = alternateName;
    }

    return result;
  }

  steamUseUnitOperationToSpreadsheetItem(
    unitOperation: SteamUseUnitOperation | Upgrader
  ): UtilitySteamSpreadsheetModel {
    const result: UtilitySteamSpreadsheetModel = {
      id: unitOperation.id,
      unitOperationName: unitOperation.name,
      type: SteamSheetItemType.HYDROCARBON,
    };

    if (unitOperation.ownerCase.isSolved) {
      result.steamMake900 = unitOperation.steamMake900.convertToAnotherUnit(unitConverter.units.Massflowrate.KPPH);
      result.steamUse900 = unitOperation.steamUse900.convertToAnotherUnit(unitConverter.units.Massflowrate.KPPH);
      result.steamMake600 = unitOperation.steamMake600.convertToAnotherUnit(unitConverter.units.Massflowrate.KPPH);
      result.steamUse600 = unitOperation.steamUse600.convertToAnotherUnit(unitConverter.units.Massflowrate.KPPH);
      result.steamMake150 = unitOperation.steamMake150.convertToAnotherUnit(unitConverter.units.Massflowrate.KPPH);
      result.steamUse150 = unitOperation.steamUse150.convertToAnotherUnit(unitConverter.units.Massflowrate.KPPH);
      result.steamMake50 = unitOperation.steamMake50.convertToAnotherUnit(unitConverter.units.Massflowrate.KPPH);
      result.steamUse50 = unitOperation.steamUse50.convertToAnotherUnit(unitConverter.units.Massflowrate.KPPH);
    }

    return result;
  }

  boilerToSpreadsheetItem(uo: WaterBoiler, rawValue: boolean): UtilitySteamSpreadsheetModel {
    const result = this.getEmptySpreadsheetItem(uo.name, uo.category, undefined);
    result.id = uo.id;

    if (uo.ownerCase.isSolved) {
      const inletStream = uo.ownerCase.filterWaterMaterialStreams(s => s.outletUnitOperationId === uo.id)[0];

      if (inletStream) {
        result.steamMake900 = inletStream.massFlowrate.value;
      }
    }

    if (!rawValue) {
      this.convertItemValuesToDisplayUnit(result);
    }

    return result;
  }

  turbineToSpreadsheetItem(uo: WaterTurbine, rawValue: boolean): UtilitySteamSpreadsheetModel {
    const result = this.getEmptySpreadsheetItem(uo.name, uo.category, undefined);
    result.id = uo.id;

    if (uo.ownerCase.isSolved) {
      const inletStream = uo.ownerCase.filterWaterMaterialStreams(s => s.outletUnitOperationId === uo.id)[0];

      if (inletStream) {
        result.steamUse900 = inletStream.massFlowrate.value;
      }
    }

    if (!rawValue) {
      this.convertItemValuesToDisplayUnit(result);
    }

    return result;
  }

  waterSourceImportToSpreadsheetItem(uo: WaterSourceImport, rawValue: boolean): UtilitySteamSpreadsheetModel {
    let totalFlowrate = 0;
    for (const informationStream of uo.inletSourceInformationStreams) {
      const massFlowrate = informationStream.massFlowrate.value;
      totalFlowrate += hasNumericValue(massFlowrate) ? massFlowrate : 0;
    }

    const result = this.getEmptySpreadsheetItem(uo.name, uo.category, undefined);
    result.id = uo.id;

    switch (uo.steamType) {
      case SteamType.Steam50:
        result.steamMake50 = totalFlowrate;
        break;

      case SteamType.Steam150:
        result.steamMake150 = totalFlowrate;
        break;

      case SteamType.Steam600:
        result.steamMake600 = totalFlowrate;
        break;

      case SteamType.Steam900:
        result.steamMake900 = totalFlowrate;
        break;
    }

    if (!rawValue) {
      this.convertItemValuesToDisplayUnit(result);
    }

    return result;
  }

  waterSinkImportToSpreadsheetItem(uo: WaterSinkImport, rawValue: boolean): UtilitySteamSpreadsheetModel {
    let totalFlowrate = 0;
    for (const informationStream of uo.inletSourceInformationStreams) {
      const massFlowrate = informationStream.massFlowrate.value;
      totalFlowrate += hasNumericValue(massFlowrate) ? massFlowrate : 0;
    }

    const result = this.getEmptySpreadsheetItem(uo.name, uo.category, undefined);
    result.id = uo.id;

    switch (uo.steamType) {
      case SteamType.Steam50:
        result.steamUse50 = totalFlowrate;
        break;

      case SteamType.Steam150:
        result.steamUse150 = totalFlowrate;
        break;

      case SteamType.Steam600:
        result.steamUse600 = totalFlowrate;
        break;

      case SteamType.Steam900:
        result.steamUse900 = totalFlowrate;
        break;
    }

    if (!rawValue) {
      this.convertItemValuesToDisplayUnit(result);
    }

    return result;
  }

  waterSourceToSpreadsheetItem(uo: WaterSource, rawValue: boolean): UtilitySteamSpreadsheetModel {
    const result = this.getEmptySpreadsheetItem(uo.name, uo.category, undefined);
    result.id = uo.id;

    if (uo.ownerCase.isSolved) {
      const outletStream = uo.ownerCase.filterWaterMaterialStreams(s => s.inletUnitOperationId === uo.id)[0];

      if (outletStream) {
        result.steamMake900 = outletStream.massFlowrate.value;
      }
    }

    if (!rawValue) {
      this.convertItemValuesToDisplayUnit(result);
    }

    return result;
  }

  waterHeaderToSpreadsheetItem(uo: WaterHeader, rawValue: boolean): UtilitySteamSpreadsheetModel {
    const result = this.getEmptySpreadsheetItem(uo.name, uo.category, undefined);
    result.id = uo.id;

    if (uo.ownerCase.isSolved) {
      const inletStreams = uo.ownerCase.filterWaterMaterialStreams(s => s.outletUnitOperationId === uo.id);
      let totalInletFlowrate = 0;
      for (const stream of inletStreams) {
        totalInletFlowrate += stream.massFlowrate.value;
      }

      const outletStreams = uo.ownerCase.filterWaterMaterialStreams(s => s.inletUnitOperationId === uo.id);
      let totalOutletFlowrate = 0;
      for (const stream of inletStreams) {
        totalOutletFlowrate += stream.massFlowrate.value;
      }

      result.steamMake900 = totalInletFlowrate;
      result.steamUse900 = totalOutletFlowrate;
    }

    if (!rawValue) {
      this.convertItemValuesToDisplayUnit(result);
    }

    return result;
  }

  getEmptySpreadsheetItem(unitOperationName: string, type: string, subType: string): UtilitySteamSpreadsheetModel {
    return {
      type,
      subType,
      unitOperationName,
      steamMake900: 0,
      steamUse900: 0,
      steamMake600: 0,
      steamUse600: 0,
      steamMake150: 0,
      steamUse150: 0,
      steamMake50: 0,
      steamUse50: 0,
    };
  }

  convertItemValuesToDisplayUnit(item: UtilitySteamSpreadsheetModel): void {
    item.steamUse50 = this.nuc.convert(
      item.steamUse50,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      false
    );
    item.steamMake50 = this.nuc.convert(
      item.steamMake50,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      false
    );
    item.steamMake150 = this.nuc.convert(
      item.steamMake150,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      false
    );
    item.steamUse150 = this.nuc.convert(
      item.steamUse150,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      false
    );
    item.steamMake600 = this.nuc.convert(
      item.steamMake600,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      false
    );
    item.steamUse600 = this.nuc.convert(
      item.steamUse600,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      false
    );
    item.steamMake900 = this.nuc.convert(
      item.steamMake900,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      false
    );
    item.steamUse900 = this.nuc.convert(
      item.steamUse900,
      Quantity.MASSFLOWRATE,
      unitConverter.units.Massflowrate.KPPH,
      false
    );
  }

  sumValuesToItem(source: UtilitySteamSpreadsheetModel, destination: UtilitySteamSpreadsheetModel): void {
    destination.steamMake50 += source.steamMake50;
    destination.steamUse50 += source.steamUse50;
    destination.steamMake150 += source.steamMake150;
    destination.steamUse150 += source.steamUse150;
    destination.steamMake600 += source.steamMake600;
    destination.steamUse600 += source.steamUse600;
    destination.steamMake900 += source.steamMake900;
    destination.steamUse900 += source.steamUse900;
  }

  isUnitOperationAllowed(uo: UnitOperation): boolean {
    return this.isFromHydroCarbonFlowsheet(uo) || this.isBoiler(uo);
  }

  isFromHydroCarbonFlowsheet(uo: UnitOperation): uo is SteamUseUnitOperation {
    return (
      uo instanceof DRU ||
      uo instanceof VAC ||
      uo instanceof DCU ||
      uo instanceof Hydrocracker ||
      uo instanceof Hydrotreater ||
      uo instanceof HydrogenPlant ||
      uo instanceof SulfurPlant
    );
  }

  isFromUtilitiesFlowsheet(uo: UnitOperation): uo is WaterUnitOperation {
    return (
      this.isBoiler(uo) ||
      this.isWaterTurbine(uo) ||
      this.isWaterSourceImport(uo) ||
      this.isWaterSinkImport(uo) ||
      this.isWaterHeader(uo) ||
      this.isWaterSource(uo)
    );
  }

  isBoiler(uo: UnitOperation): uo is WaterBoiler {
    return uo instanceof WaterBoiler;
  }

  isWaterTurbine(uo: UnitOperation): uo is WaterTurbine {
    return uo instanceof WaterTurbine;
  }

  isWaterSourceImport(uo: UnitOperation): uo is WaterSourceImport {
    return uo instanceof WaterSourceImport;
  }

  isWaterSinkImport(uo: UnitOperation): uo is WaterSinkImport {
    return uo instanceof WaterSinkImport;
  }

  isWaterSource(uo: UnitOperation): uo is WaterSource {
    return uo instanceof WaterSource;
  }

  isWaterHeader(uo: UnitOperation): uo is WaterHeader {
    return uo instanceof WaterHeader;
  }
}
