import { FlexSheetMapper } from './flex-sheet-mapper';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';
import { Hydrotreater } from '../../../_models/_unit-operations/hydrotreater';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { VAC } from '../../../_models/_unit-operations/vac';
import { DCU } from '../../../_models/_unit-operations/dcu';
import { H2sSheetColumns } from '../cell-config/h2s-production-cell-config';

export class H2sFlexSheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: Hydrotreater | Hydrocracker | VAC | DCU, col: number, value: string | number) {
    if (col === H2sSheetColumns.NAME && typeof value === 'string') {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (col) {
        case H2sSheetColumns.H2S_PRODUCTION:
          break;
        case H2sSheetColumns.H2S_PRODUCTION_RATE:
          this.simulationVariableMapper.map(value, unitOperation.h2sProductionRate);
          break;
      }
    }
  }
}
