<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="toggle-navigation">
    <a class="btn btn-primary" (click)="toggleNavigation()"><i class="fa fa-bars"></i> </a>
  </div>
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="dropdown profile-element">
          <a data-toggle="dropdown" class="dropdown-toggle" href="#">
            <span class="block m-t-xs"> <strong class="font-bold"></strong> </span>
            <span class="text-muted text-xs block"> <b class="caret"></b> </span>
          </a>
          <ul class="dropdown-menu animated fadeInRight m-t-xs">
            <li><a [routerLink]="['/login']">Log out</a></li>
          </ul>
        </div>
        <div class="logo-element"></div>
      </li>

      <li class="active">
        <a href="#">
          <i class="menu-icon fa fa-file"></i>
          <span class="nav-label">File</span>
          <span class="fa arrow"></span>
          <ul class="nav nav-second-level"></ul>
        </a>

        <ul class="nav nav-second-level">
          <li>
            <a href="#" data-toggle="modal" data-target="#newCaseWarningModal">
              <i class="fa fa-files-o"></i>
              New Project
            </a>
          </li>
          <li>
            <a href="#" (click)="openSaveFileDialog()">
              <i class="fa fa-save"></i>
              Download Project
            </a>
          </li>

          <li>
            <a href="#" (click)="openLoadFileDialog()">
              <i class="fa fa-folder-open-o"></i>
              Open Project
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#" title="Run case" (click)="solveCase()">
          <i class="fa fa-play"></i> <span class="nav-label">Run Case</span>
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <i class="main-icon fa fa-wrench"></i>
          <span class="nav-label">Settings</span>
          <span class="fa arrow"></span>
        </a>

        <ul class="nav nav-second-level submenu-lg">
          <li>
            <a href="javascript:void(0)" (click)="openOptimizationSettingsTable()" title="Optimization settings table">
              <i class="fa fa-table"></i>
              Optimization Settings Table
            </a>
          </li>

          <li>
            <a (click)="openCaseSettings()" href="javascript:void(0)" title="Case Settings">
              <i class="fa fa-wrench"></i>
              Case Settings
            </a>
          </li>

          <li *ngIf="this.currentCase.constraintViolationEntries.length > 0">
            <a (click)="openConstraintViolationReport()" href="javascript:void(0)" title="Case Settings">
              <i class="fa fa-table"></i>
              Constraint Violation Report
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a href="javascript:void(0)" title="KPI manager" (click)="openKpiManagerModal()">
          <i class="fa fa-tachometer"></i>
          <span class="nav-label">KPI Manager</span>
        </a>
      </li>
      <li li *ngIf="!isMultiPeriodEnabled">
        <a href="#" (click)="openCaseStudyModal()" title="Case study manager">
          <i class="fa fa-area-chart"></i>
          <span class="nav-label">Case study manager</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" title="Open Cases List" (click)="openCaseList()">
          <i class="main-icon fa fa-list-alt"></i>
          <span class="nav-label"> Open Cases List </span>
        </a>
      </li>

      <li *ngIf="!isMultiPeriodEnabled">
        <a href="javascript:void(0);" title="Global KPI" (click)="openGlobalKpiModal()">
          <i class="main-icon fa fa-globe"></i>
          <span class="nav-label"> Global KPI </span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" title="Assay manager" (click)="openAssayManagerModal()">
          <i class="main-icon fa fa-flask"></i>
          <span class="nav-label"> Assay Manager </span>
        </a>
      </li>

      <li *ngIf="this.currentCase.isSolved">
        <a href="javascript:void(0)" (click)="openSummaryReport()" title="Summary report">
          <i class="fa fa-bar-chart"></i>
          <span class="nav-label">Summary Report</span>
        </a>
      </li>

      <li
        *ngIf="
          this.currentCase.multiPeriodEnabled &&
          this.currentCase.isSolved &&
          this.currentCase.multiPeriodSettings.multiPeriodResults
        ">
        <a href="javascript:void(0)" (click)="openMultiPeriodResults()" title="Multi Period Results">
          <i class="fa fa-calendar"></i>
          <span class="nav-label">Multi Period Results</span>
        </a>
      </li>

      <li *ngIf="this.currentCase.isSolved">
        <a href="javascript:void(0)" (click)="openUpgraderReport()" title="Upgrader report">
          <i class="fa fa-arrow-circle-o-up fa-lg"></i>
          <span class="nav-label">Upgrader Report</span>
        </a>
      </li>

      <li *ngIf="this.currentCase.isSolved && !isMultiPeriodEnabled">
        <a href="javascript:void(0)" title="Excel Report" (click)="excelReport()">
          <i class="main-icon fa fa-download"></i>
          <span class="nav-label">Excel Report</span>
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <i class="main-icon fa fa-info-circle"></i>
          <span class="nav-label">Info</span>
          <span class="fa arrow"></span>
        </a>

        <ul class="nav nav-second-level submenu-lg">
          <li>
            <a href="javascript:void(0)" title="Case comments" (click)="openCaseCommentsModal()">
              <i class="main-icon fa fa-file-text"></i>
              Case Comments
            </a>
          </li>

          <li>
            <a title="About" (click)="openAboutWindow()">
              <i class="main-icon fa fa-info-circle"></i>
              About
            </a>
          </li>

          <li>
            <a title="Download product documentation" (click)="downloadManual()">
              <i class="main-icon fa fa-question-circle" style="display: inline-block"></i>
              Download Product Documentation
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>

<div id="newCaseWarningModal" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Warning</h4>
      </div>

      <div class="modal-body">
        A new case
        <!--project?-->
        will be created, all unsaved changes will be lost.
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="createNewProject()">Accept</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
