import { SimulationVariable } from '../../simulation-variable';
import { BaseObject } from '../../base-object';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';

export class EnergyStream extends BaseObject {
  category = 'energyStream';
  energyFlow: SimulationVariable;
  providerUnitOperationId: string;

  constructor(energyStream: EnergyStream | any, ownerCase: Case) {
    super(energyStream.id, ownerCase);
    this.initValues(energyStream);
    this.setQuantities();
  }

  private initValues(energyStream: EnergyStream | any) {
    this.providerUnitOperationId = energyStream.providerUnitOperationId;
    this.energyFlow = new SimulationVariable(energyStream.energyFlow, this.ownerCase, this);
  }

  setQuantities() {
    this.energyFlow.setQuantity(Quantity.POWER);
  }

  getFullName() {
    return `${this.ownerCase.getUnitOperation(this.providerUnitOperationId).name} - Thermal Energy Use`;
  }

  override dePersist(persistedObj: any) {
    super.dePersist(persistedObj);
    this.energyFlow = this.getSimulationVariableSafe(persistedObj.energyFlow);
    this.setSimulationVariablesOwner();
    this.setQuantities();
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      providerUnitOperationId: this.providerUnitOperationId,
      energyFlow: this.energyFlow.id,
    };
  }
}
