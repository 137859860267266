import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { BitumenConversion } from '../../../_models/_unit-operations/bitumen-conversion';
import { AbstractFormGroupPropertyWindow } from '../abstract-form-group-property-window';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import {
  BitumenConversionYieldBasis,
  BitumenModelTypes,
} from '../../../_config/unit-operations/bitumen-conversion-enums';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import {
  BitumenConversionOutletForm,
  BitumenConversionOutletFormBuilder,
} from '../../../_form-utils/bitumen-conversion-outlet-form-builder';
import { CoreService } from '../../../_services/core.service';
import { AssayType } from '../../../_config/assay-type.enum';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
import { defaultBitumenConversionModel } from '../../../_config/unit-operations/default-models/default-bitumen-conversion-model';
import { BitumenConversionCodeProvider } from '../../../_services/custom-code-providers/bitumen-conversion-code-provider';

@Component({
  selector: 'sob-bitumen-conversion',
  templateUrl: './bitumen-conversion.component.html',
  styleUrls: ['./bitumen-conversion.component.css'],
})
export class BitumenConversionComponent extends AbstractFormGroupPropertyWindow implements OnInit, OnDestroy {
  @Input() unitOperation: BitumenConversion;

  @Input() formGroupWrapper!: BaseObjectFormGroupWrapper;

  defaultCodeProvider: BitumenConversionCodeProvider;

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    yieldBasis: new FormControl('', [Validators.required]),
    hydrogenUseRate: this.svfb.emptyControl(),
    hydrogenUse: this.svfb.emptyControl(true),
    hydrogenImbalance: this.svfb.emptyControl(true),
    ghgIntensity: this.svfb.emptyControl(),
    absoluteGhgEmissions: this.svfb.emptyControl(true),
    outlets: new FormArray<BitumenConversionOutletForm>([]),

    revenue: this.svfb.emptyControl(true),
    unitValue: this.svfb.emptyControl(true),
    opexFixed: this.svfb.emptyControl(),
    opexVar: this.svfb.emptyControl(),
    opexCarbonDioxide: this.svfb.emptyControl(true),
    opexTotal: this.svfb.emptyControl(true),
    capexAmortized: this.svfb.emptyControl(),
    capexTotal: this.svfb.emptyControl(true),
    capacity: this.svfb.emptyConstraintControl(),

    modelType: new FormControl('', [Validators.required]),
    powerUseRate: this.svfb.emptyControl(),
    powerPrice: this.svfb.emptyControl(),
    powerEmissionFactor: this.svfb.emptyControl(),
    hydrogenPrice: this.svfb.emptyControl(),
    hydrogenEmissionFactor: this.svfb.emptyControl(),
    naturalGasUseRate: this.svfb.emptyControl(),
    naturalGasPrice: this.svfb.emptyControl(),
    naturalGasEmissionFactor: this.svfb.emptyControl(),

    useCustomModel: new FormControl<boolean>(false),
    customModelScript: new FormControl<string>(''),
  });

  private subSink = new SubSink();

  protected readonly BitumenConversionYieldBasis = BitumenConversionYieldBasis;
  protected readonly BitumenModelTypes = BitumenModelTypes;

  constructor(
    private svfb: SimVarFormBuilder,
    private bofb: BitumenConversionOutletFormBuilder,
    private core: CoreService
  ) {
    super();

    this.subSink.add(
      this.core.streamAddedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.addBitumenConversionOutlet(s.outletUnitOperationId);
        }
      })
    );

    this.subSink.add(
      this.core.streamRemovedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.removeBitumenConversionOutlet(s.outletUnitOperationId);
        }
      })
    );

    this.defaultCodeProvider = new BitumenConversionCodeProvider();
  }

  ngOnInit(): void {
    this.init();
  }

  addControls() {
    this.formGroup.controls.name.patchValue(this.unitOperation.name);
    this.formGroup.controls.yieldBasis.patchValue(this.unitOperation.yieldBasis);
    this.svfb.patchValue(this.unitOperation.hydrogenUseRate, this.formGroup.controls.hydrogenUseRate);
    this.svfb.patchValue(this.unitOperation.hydrogenUse, this.formGroup.controls.hydrogenUse);
    this.svfb.patchValue(this.unitOperation.hydrogenImbalance, this.formGroup.controls.hydrogenImbalance);
    this.svfb.patchValue(this.unitOperation.ghgIntensity, this.formGroup.controls.ghgIntensity);
    this.svfb.patchValue(this.unitOperation.absoluteGhgEmissions, this.formGroup.controls.absoluteGhgEmissions);

    this.svfb.patchValue(this.unitOperation.revenue, this.formGroup.controls.revenue);
    this.svfb.patchValue(this.unitOperation.unitValue, this.formGroup.controls.unitValue);
    this.svfb.patchValue(this.unitOperation.opexFixed, this.formGroup.controls.opexFixed);
    this.svfb.patchValue(this.unitOperation.opexVar, this.formGroup.controls.opexVar);
    this.svfb.patchValue(this.unitOperation.opexCarbonDioxide, this.formGroup.controls.opexCarbonDioxide);
    this.svfb.patchValue(this.unitOperation.opexTotal, this.formGroup.controls.opexTotal);
    this.svfb.patchValue(this.unitOperation.capexAmortized, this.formGroup.controls.capexAmortized);
    this.svfb.patchValue(this.unitOperation.capexTotal, this.formGroup.controls.capexTotal);
    this.svfb.patchConstraintValue(this.unitOperation.capacity, this.formGroup.controls.capacity);

    this.formGroup.controls.outlets = this.bofb.formArray(this.unitOperation.outlets);

    this.formGroup.controls.modelType.patchValue(this.unitOperation.modelType);

    this.svfb.patchValue(this.unitOperation.powerUseRate, this.formGroup.controls.powerUseRate);
    this.svfb.patchValue(this.unitOperation.powerPrice, this.formGroup.controls.powerPrice);
    this.svfb.patchValue(this.unitOperation.powerEmissionFactor, this.formGroup.controls.powerEmissionFactor);
    this.svfb.patchValue(this.unitOperation.hydrogenPrice, this.formGroup.controls.hydrogenPrice);
    this.svfb.patchValue(this.unitOperation.hydrogenEmissionFactor, this.formGroup.controls.hydrogenEmissionFactor);
    this.svfb.patchValue(this.unitOperation.naturalGasUseRate, this.formGroup.controls.naturalGasUseRate);
    this.svfb.patchValue(this.unitOperation.naturalGasPrice, this.formGroup.controls.naturalGasPrice);
    this.svfb.patchValue(this.unitOperation.naturalGasEmissionFactor, this.formGroup.controls.naturalGasEmissionFactor);

    for (const key of Object.keys(this.formGroup.controls)) {
      this.propertyWindowFormGroup.addControl(key, this.formGroup.controls[key]);
    }

    this.formGroup.controls.useCustomModel.patchValue(this.unitOperation.useCustomModel);
    this.formGroup.controls.customModelScript.patchValue(this.unitOperation.customModelScript);

    this.onModelTypeChanged();
    this.onCustomModelChanged();
  }

  // region outlets
  addBitumenConversionOutlet(outletUnitOperationId: string) {
    this.unitOperation.addOutlet(outletUnitOperationId);
    const outlet = this.unitOperation.findOutlet(outletUnitOperationId);
    const outletControl = this.bofb.control(outlet);

    if (this.isCustomModelEnabled) {
      outletControl.controls.yield.controls.value.disable();
    }

    this.outletsFormArray.controls.push(outletControl);
  }

  removeBitumenConversionOutlet(outletUnitOperationId: string) {
    const formGroupToRemove = this.outletsFormArray.controls.find(fg => {
      return fg.getFormGroupId() === outletUnitOperationId;
    });

    // This is important... if we remove a negative index an item will be removed...
    if (!formGroupToRemove) {
      return;
    }

    // TODO remove this when unit operation copies are no longer needed
    this.unitOperation.removeOutlet(outletUnitOperationId);
    const formGroupIndex = this.outletsFormArray.controls.indexOf(formGroupToRemove);

    this.outletsFormArray.removeAt(formGroupIndex);
  }

  findOutletName(i: number) {
    const id = this.unitOperation.outlets[i].unitOperationId;
    return this.core.currentCase.getUnitOperationName(id);
  }

  get outletsFormArray() {
    return this.formGroup.controls.outlets;
  }
  // endregion

  // region assay
  get fluidAnalyses() {
    return this.core.currentCase.assayManager.getAssaysByCategory(AssayType.PRODUCT);
  }

  getCurrentAnalysisByOutletIndex(index: number) {
    const fluidAnalysisId = this.outletsFormArray.controls[index].controls.fluidAnalysisId.value;
    return this.core.currentCase.assayManager.findFluidAnalysisById(fluidAnalysisId);
  }

  updateCurrentAnalysis(index: number, selectedAssayId: string) {
    this.outletsFormArray.controls[index].controls.fluidAnalysisId.patchValue(selectedAssayId);
  }
  // endregion

  // region modelType
  protected updateModelType(modelType: string) {
    this.formGroup.controls.modelType.patchValue(modelType);

    this.onModelTypeChanged();
  }

  private onModelTypeChanged() {
    if (this.isSimpleModelType()) {
      this.disableAndClearUtilityFields();
    } else {
      this.enableUtilityFields();
    }
  }

  private disableAndClearUtilityFields() {
    this.formGroup.controls.powerUseRate.controls.value.patchValue(undefined);
    this.formGroup.controls.powerUseRate.controls.value.disable();

    this.formGroup.controls.powerPrice.controls.value.patchValue(undefined);
    this.formGroup.controls.powerPrice.controls.value.disable();

    this.formGroup.controls.naturalGasUseRate.controls.value.patchValue(undefined);
    this.formGroup.controls.naturalGasUseRate.controls.value.disable();

    this.formGroup.controls.hydrogenUseRate.controls.value.patchValue(undefined);
    this.formGroup.controls.hydrogenUseRate.controls.value.disable();

    this.formGroup.controls.powerEmissionFactor.controls.value.patchValue(undefined);
    this.formGroup.controls.powerEmissionFactor.controls.value.disable();

    this.formGroup.controls.hydrogenPrice.controls.value.patchValue(undefined);
    this.formGroup.controls.hydrogenPrice.controls.value.disable();

    this.formGroup.controls.hydrogenEmissionFactor.controls.value.patchValue(undefined);
    this.formGroup.controls.hydrogenEmissionFactor.controls.value.disable();

    this.formGroup.controls.naturalGasPrice.controls.value.patchValue(undefined);
    this.formGroup.controls.naturalGasPrice.controls.value.disable();

    this.formGroup.controls.naturalGasEmissionFactor.controls.value.patchValue(undefined);
    this.formGroup.controls.naturalGasEmissionFactor.controls.value.disable();
  }

  private enableUtilityFields() {
    this.formGroup.controls.powerUseRate.controls.value.enable();
    this.formGroup.controls.powerPrice.controls.value.enable();
    this.formGroup.controls.naturalGasUseRate.controls.value.enable();
    this.formGroup.controls.hydrogenUseRate.controls.value.enable();
    this.formGroup.controls.powerEmissionFactor.controls.value.enable();
    this.formGroup.controls.hydrogenPrice.controls.value.enable();
    this.formGroup.controls.hydrogenEmissionFactor.controls.value.enable();
    this.formGroup.controls.naturalGasPrice.controls.value.enable();
    this.formGroup.controls.naturalGasEmissionFactor.controls.value.enable();
  }

  private isSimpleModelType() {
    return this.formGroup.controls.modelType.value === BitumenModelTypes.SIMPLE;
  }
  // endregion modelType

  // region customModelScript
  protected get isCustomModelEnabled() {
    return this.formGroup.controls.useCustomModel.value;
  }

  protected onCustomModelChanged() {
    if (this.isCustomModelEnabled) {
      this.disableAllOutletsYields();
    } else {
      this.enableAllOutletsYields();
    }
  }

  private disableAllOutletsYields() {
    for (const outletControl of this.outletsFormArray.controls) {
      outletControl.controls.yield.controls.value.disable();
    }
  }

  private enableAllOutletsYields() {
    for (const outletControl of this.outletsFormArray.controls) {
      outletControl.controls.yield.controls.value.enable();
    }
  }
  // endregion

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  saveCustomModel(target: { code: string; instanceId: string }) {
    this.formGroup.controls.customModelScript.patchValue(target.code);
  }
}
