import { Completion } from '@codemirror/autocomplete';
import { DefaultCodeProvider } from './default-code-provider';
import { defaultUpgraderModel } from '../../_config/unit-operations/default-models/default-upgrader-model';

export class UpgraderCodeProvider extends DefaultCodeProvider {
  constructor() {
    super(defaultUpgraderModel);
  }

  override getCodeCompletions(): Completion[] {
    const upgraderCompletions: Completion[] = [
      { label: 'GasYield', type: 'variable', info: '' },
      { label: 'NaphtaYield', type: 'variable', info: '' },
      { label: 'DistillateYield', type: 'variable', info: '' },
      { label: 'GasOilYield', type: 'variable', info: '' },
      { label: 'CokeYield', type: 'variable', info: '' },
      { label: 'NaphtaAPI', type: 'variable', info: '' },
      { label: 'DistillateAPI', type: 'variable', info: '' },
      { label: 'GasOilAPI', type: 'variable', info: '' },
      { label: 'NaphtaSulfur', type: 'variable', info: '' },
      { label: 'GasOilSulfur', type: 'variable', info: '' },
      { label: 'NaphtaNitrogen', type: 'variable', info: '' },
      { label: 'DistillateNitrogen', type: 'variable', info: '' },
      { label: 'GasOilNitrogen', type: 'variable', info: '' },
    ];

    return super.getCodeCompletions().concat(...upgraderCompletions);
  }
}
