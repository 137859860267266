import { HttpClient, HttpHeaders } from '@angular/common/http';
import { defer, of } from 'rxjs';
import { catchError, delay, repeatWhen, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LatestUserAccessService {
  // the time between the observable checks if the request can be sent
  private readonly OBSERVABLE_DELAY = 30000;

  // the actual delay between requests
  private readonly POLL_DELAY = 60000;

  constructor(private http: HttpClient) {}

  beginRegisteringAccess() {
    defer(() => {
      return this.registerAccess();
    })
      .pipe(
        repeatWhen(notifications => notifications.pipe(delay(this.OBSERVABLE_DELAY))),
        retry(),
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe();
  }

  registerAccess() {
    const serializedUser = localStorage.getItem('user');
    const username = JSON.parse(serializedUser || '{}').userName;

    if (!username) {
      // dummy observable, do nothing
      return of(undefined);
    }

    // use local storage so it will prevent multiple tabs from sending the request
    const currentTime = new Date().getTime();
    const latestAccessTime = this.getLatestAccessTime();

    // wait until some time passes between requests
    if (currentTime - latestAccessTime < this.POLL_DELAY) {
      // dummy observable, do nothing
      return of(undefined);
    }

    this.storeLatestAccessTime();
    return this.http.post(`${environment.apiBaseUrl}/latest-access`, undefined, { headers: this.getHeaders(username) });
  }

  getLatestAccesses() {
    return this.http.get(`${environment.apiBaseUrl}/latest-access`);
  }

  private storeLatestAccessTime() {
    localStorage.setItem('latestAccessTime', new Date().getTime().toString());
  }

  private getLatestAccessTime(): number {
    const latestAccessTime = localStorage.getItem('latestAccessTime');

    if (!latestAccessTime) {
      return 0;
    }

    return +latestAccessTime;
  }

  private getHeaders(username) {
    return new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', Username: username });
  }
}
