import { UnitOperationConstraints } from '../_unit-operations/unit-operation-constraints';

export interface ConstrainableObject {
  constraints: UnitOperationConstraints;
  constraintViolated(): boolean;
  constraintValueDefined(): boolean;
  initConstraints();
}

export function isConstrainableObject(co: any): co is ConstrainableObject {
  return typeof (co as ConstrainableObject).constraintViolated === 'function';
}
