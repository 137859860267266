import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { Case } from '../case';
import { SuncorUnitOperation } from './suncor-unit-operation';
import { SuncorRenewable } from './suncor-renewable';
import { BaseObject } from '../base-object';
import { SimulationVariable } from '../simulation-variable';

export class SuncorRenewableBlock extends SuncorUnitOperation {
  category = unitOperationsConfig.suncorRenewableBlock.key;

  renewablesList: SuncorRenewable[] = [];

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation) {
    super.initValues(unitOperation);

    if (unitOperation.renewablesList instanceof Array) {
      for (const sr of unitOperation.renewablesList) {
        if (sr instanceof SuncorRenewable) {
          this.renewablesList.push(new SuncorRenewable(sr, this, this.ownerCase));
        }
      }
    }
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
    const anotherSrb = another as SuncorRenewableBlock;

    const renewablesToRemove = [];
    for (const sr of this.renewablesList) {
      if (!anotherSrb.findRenewableById(sr.id)) {
        renewablesToRemove.push(sr);
      }
    }

    for (const renewableToRemove of renewablesToRemove) {
      const idx = this.renewablesList.findIndex(sr => sr.id === renewableToRemove.id);
      this.renewablesList.splice(idx, 1);
      this.ownerCase.removeFromPools(renewableToRemove);
    }

    // renewables to be added / overwritten
    for (const anotherSr of anotherSrb.renewablesList) {
      let sr = this.findRenewableById(anotherSr.id);

      if (sr) {
        sr.overwriteValues(anotherSr);
      } else {
        sr = new SuncorRenewable(anotherSr, this, this.ownerCase);
        this.renewablesList.push(sr);
        sr.addSimVarsToPool();
      }
    }
  }

  override ignoreForOverWrite(propertyName: string) {
    return super.ignoreForOverWrite(propertyName) || this[propertyName] === this.renewablesList;
  }

  findRenewableById(id): SuncorRenewable {
    return this.renewablesList.find(sr => sr.id === id);
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
  }

  override convertToInternalUnit() {
    super.convertToInternalUnit();
    for (const sr of this.renewablesList) {
      sr.convertToInternalUnit();
    }
  }

  override getSimVars() {
    const simVars = super.getSimVars();
    for (const sr of this.renewablesList) {
      simVars.push.apply(sr.getSimVars(), this.renewablesList);
    }

    return simVars;
  }

  override setQuantities() {
    super.setQuantities();
    for (const sr of this.renewablesList) {
      sr.setQuantities();
    }
  }

  getAlternativeGhgIntensity() {
    return undefined;
  }

  override ignoreForMakeReady(simvar: SimulationVariable): boolean {
    return super.ignoreForMakeReady(simvar) || simvar === this.capacity || simvar === this.minimumFlow;
  }

  override dePersist(serializedUnit: any) {
    super.dePersist(serializedUnit);

    if (serializedUnit.renewablesList instanceof Array) {
      for (const sr of serializedUnit.renewablesList) {
        const newSr = new SuncorRenewable(sr, this, this.ownerCase);
        newSr.dePersist(sr);
        this.renewablesList.push(newSr);
      }
    }

    this.setQuantities();
    this.setDefaultValues();
  }
}
