import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CoreService } from '../../../_services/core.service';
import { MultiPeriodInventorySettingsComponent } from '../multi-period-inventory-settings/multi-period-inventory-settings.component';
import { MultiPeriodSettings } from '../../../_models/_multi-period/multi-period-settings';
import { MultiPeriodParameterSpreadsheetComponent } from '../multi-period-parameter-spreadsheet/multi-period-parameter-spreadsheet.component';
import { MultiPeriodService } from '../../../_services/multi-period/multi-period.service';
import { KpiManager } from '../../../_models/_case-study/kpi-manager';
import { KpiManagerComponent } from '../../case-study/kpi-manager/kpi-manager.component';

@Component({
  selector: 'sob-multi-period-settings',
  templateUrl: './multi-period-settings.component.html',
  styleUrls: ['./multi-period-settings.component.css'],
})
export class MultiPeriodSettingsComponent implements OnInit {
  @ViewChild(MultiPeriodInventorySettingsComponent, { static: true })
  multiPeriodInventorySettingsComponent: MultiPeriodInventorySettingsComponent;

  @ViewChild(MultiPeriodParameterSpreadsheetComponent, { static: true })
  multiPeriodParameterSpreadsheetComponent: MultiPeriodParameterSpreadsheetComponent;

  @ViewChild(KpiManagerComponent, { static: false }) kpiManagerComponent: KpiManagerComponent;

  formGroup: UntypedFormGroup;

  constructor(
    private core: CoreService,
    private fb: UntypedFormBuilder,
    private multiPeriodService: MultiPeriodService
  ) {
    this.core.currentCaseReplacedRequest.subscribe(() => {
      this.init();
    });

    this.multiPeriodService.multiPeriodModeEnabledRequest.subscribe(opts => {
      if (opts.multiPeriodEnabled) {
        this.showFirstStep();
      }
    });
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({});
  }

  init() {
    this.multiPeriodInventorySettingsComponent.init();
  }

  onFirstStepNextClicked() {
    this.multiPeriodInventorySettingsComponent.isSubmitted = true;
    if (this.formGroup.valid) {
      this.multiPeriodInventorySettingsComponent.map();
      this.showNextStep();
    } else {
      this.cursorFocusInFieldsWithValidationError();
    }
  }

  cursorFocusInFieldsWithValidationError() {
    for (const control in this.formGroup.controls) {
      if (this.formGroup.controls[control].invalid) {
        if (control === 'numberOfSteps') {
          $('input[formControlName=numberOfSteps]').focus();
          break;
        } else {
          const $formLabel = $(`a[aria-controls=inventorySettings${control}]`);
          $formLabel.attr('class', '');
          $formLabel.attr('aria-expanded', 'true');

          const $formDiv = $(`#inventorySettings${control}`);
          $formDiv.attr('class', 'ng-star-inserted collapse in');
          $formDiv.attr('aria-expanded', 'true');
          $formDiv.attr('style', '');

          const $labelIcon = $(`a[aria-controls=inventorySettings${control}] > i`);
          $labelIcon.attr('class', 'fa fa-minus');

          const $inputs = $('sob-reactive-variable-value-input.ng-invalid > input');
          $inputs[0].focus();
        }
      }
    }
  }

  onSecondStepNextClicked() {
    for (const multiPeriodParameter of this.multiPeriodSettings.multiPeriodParameters) {
      let difference = this.multiPeriodSettings.numberOfSteps - multiPeriodParameter.multiPeriodValues.length;

      if (difference > 0) {
        for (let i = 0; i < difference; i++) {
          multiPeriodParameter.multiPeriodValues.push(undefined);
        }
      } else {
        difference = Math.abs(difference);
        for (let i = 0; i < difference; i++) {
          multiPeriodParameter.multiPeriodValues.splice(-1, difference);
        }
      }
    }

    this.multiPeriodParameterSpreadsheetComponent.initializeSheet();
    this.showNextStep();
  }

  onThirdStepNextClicked() {
    this.kpiManagerComponent.cleanInputs();
    this.kpiManagerComponent.flowsheetSelector.initializeTree();
    this.showNextStep();
    // TODO remove the categories from the KPI manager - add the new category
    this.kpiManager.removeDefaultKpis(this.core.currentCase.calculator);
  }

  onFourthStepPreviousClicked() {
    this.showPreviousStep();
    this.kpiManagerComponent.cleanInputs();
  }

  showNextStep(): void {
    const visibleStep = $('.multi-period-step:visible');
    const hiddenStep = visibleStep.next('.multi-period-step');

    visibleStep.hide();
    hiddenStep.fadeIn();
  }

  showPreviousStep(): void {
    const visibleStep = $('.multi-period-step:visible');
    const hiddenStep = visibleStep.prev('.multi-period-step');

    visibleStep.hide();
    hiddenStep.fadeIn();
  }

  showFirstStep() {
    const $step = $('.multi-period-step');
    $step.hide();
    $step.first().show();
  }

  get multiPeriodSettings(): MultiPeriodSettings {
    return this.core.currentCase.multiPeriodSettings;
  }

  get kpiManager(): KpiManager {
    return this.core.currentCase.caseStudyManager.kpiManager;
  }

  onMultiPeriodSettingsDone() {
    this.kpiManagerComponent.cleanInputs();
    this.multiPeriodService.notifyMultiPeriodSettingsCompleted();
    this.showFirstStep();
  }

  solveCase() {
    this.core.solveCase();
  }
}
