import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, Subscriber, SubscriptionLike as ISubscription } from 'rxjs';
import { Project } from '../_models/project';
import { ProgressIndicatorService } from '../flowsheet/progress-indicator/progress-indicator.service';
import { environment } from '../../environments/environment';
import { LogManagerService } from '../flowsheet/log-manager/log-manager.service';
import { UserService } from './user.service';
import { SolveCaseRequest } from '../_models/solve-case-request';
import { SolveCaseCanceller } from '../_models/solve-case-canceller';
import { ApiObjectsBackupManager } from '../_models/api-objects-backup-manager';
import { CaseResultsBackup } from '../_models/case-results-backup';

@Injectable()
export class ApiService {
  request: ISubscription;
  solveCanceller: SolveCaseCanceller;
  private currentError;

  constructor(
    private http: HttpClient,
    private logManager: LogManagerService,
    private progressIndicator: ProgressIndicatorService,
    private userService: UserService
  ) {
    this.solveCanceller = new SolveCaseCanceller(this);
  }

  readResourceFile() {
    return this.http.get('/assets/resources.json');
  }

  solveCase(project: Project, caseId): Observable<any> {
    const currentCase = project.cases.find(c => c.id === caseId);
    const flowsheets = currentCase.flowsheetPool;
    const activeFlowsheet = currentCase.getActiveFlowsheet();
    const dataToSend: SolveCaseRequest = {
      solveOption: currentCase.solveOption,
      version: project.version,
      caseModel: currentCase,
      username: this.userService.getCurrentUserFullName(),
      email: this.userService.currentUsername,
      userId: this.userService.getCurrentUserId(),
    };

    // TODO remove this heresy
    const solveSubject = new Subject<any>();
    const solveObservable = solveSubject.asObservable();
    let timeToGetResponse = performance.now();

    this.request = this.http.post(environment.apiUrl, dataToSend, { headers: this.getHeaders() }).subscribe(
      response => {
        if ((<any>response).stacktrace) {
          solveSubject.next({ success: false, error: response });
          this.currentError = response;
        } else {
          timeToGetResponse = performance.now() - timeToGetResponse;
          solveSubject.next({
            success: true,
            solvedResponse: response,
            backupFlowsheets: flowsheets,
            activeFlowsheet: activeFlowsheet.id,
            timeToGetResponse,
          });
        }
      },
      error => {
        solveSubject.next({ success: false, error });

        if (error.error) {
          this.currentError = error.error;
        } else {
          this.currentError = error;
        }

        // return this.handleSolveCaseError(backupObject);
      }
    );

    return solveObservable;
  }

  /**
   *
   * @param {Project} project A project containing the case that failed.
   * @returns {Observable<any>}
   */
  sendCaseToProcessEcology(formData): Observable<any> {
    const obs = new Observable(observer => {
      formData.then(data => {
        data.append('error', this.currentError);
        data.append('user', JSON.stringify(this.userService.getCurrentUser()));
        data.append('errorMessage', this.currentError.stackTrace ? '' : JSON.stringify(this.currentError));
        this.http.post(environment.sendCaseUrl, data, { headers: this.getMultipartHeaders() }).subscribe(
          () => {
            observer.next();
          },
          () => {
            observer.error();
          }
        );
      });
    });
    return obs;
  }

  solveCaseAsync(formData, caseToBeSolvedId: string): Observable<any> {
    return new Observable(observer => {
      formData.then(data => {
        data.append('user', JSON.stringify(this.userService.getCurrentUser()));
        data.append('caseToBeSolvedId', caseToBeSolvedId);
        this.sendSolveAsyncRequest(data, observer);
      });
    });
  }

  private sendSolveAsyncRequest(data, observer: Subscriber<any>) {
    this.http.post(environment.simulationAsync, data, { headers: this.getMultipartHeaders() }).subscribe(
      response => {
        observer.next({ success: true, solvedResponse: response });
      },
      error => {
        observer.error();
      }
    );
  }

  cancelSolveCase(): void {
    this.request.unsubscribe();

    // Insert api call to stop the engine here

    this.logManager.warning('Solving case canceled by user.');
  }

  private getHeaders() {
    return new HttpHeaders().set('Accept', 'application/json');
  }

  private getMultipartHeaders() {
    return new HttpHeaders().set('enctype', 'multipart/form-data');
  }

  handleSolveCaseError(backupObject: CaseResultsBackup): void {
    ApiObjectsBackupManager.restoreBackupData(backupObject);
  }
}
