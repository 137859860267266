<div class="row">
  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-3 form-horizontal insights-info" *ngIf="calculator">
    <div class="insights-info-title">Totals</div>

    <hr class="insights-info-title-separator" />

    <div class="form-group">
      <label [attr.for]="'insightsIntegration'" class="pw-label control-label col-sm-4"> Integration </label>
      <sim-var-input
        [inputId]="'insightsIntegration'"
        [simVar]="calculator.integration"
        [readOnly]="true"
        [transientValue]="true"></sim-var-input>
    </div>

    <div class="form-group">
      <label [attr.for]="'insightsTotalGhg'" class="pw-label control-label col-sm-4"> Total GHG </label>
      <sim-var-input
        [inputId]="'insightsTotalGhg'"
        [simVar]="calculator.totalGhg"
        [readOnly]="true"
        [transientValue]="true"></sim-var-input>
    </div>

    <div class="form-group">
      <label [attr.for]="'insightsTotalSuncorGhg'" class="pw-label control-label col-sm-4"> Total Suncor GHG </label>
      <sim-var-input
        [inputId]="'insightsTotalSuncorGhg'"
        [simVar]="calculator.totalSuncorGhg"
        [readOnly]="true"
        [transientValue]="true"></sim-var-input>
    </div>

    <div class="form-group">
      <label [attr.for]="'insightsTotalRevenue'" class="pw-label control-label col-sm-4"> Total Revenue </label>
      <sim-var-input
        [inputId]="'insightsTotalRevenue'"
        [simVar]="calculator.totalRevenue"
        [readOnly]="true"
        [transientValue]="true"></sim-var-input>
    </div>

    <div class="form-group">
      <label [attr.for]="'insightsTotalOpex'" class="pw-label control-label col-sm-4"> Total OPEX </label>
      <sim-var-input
        [inputId]="'insightsTotalOpex'"
        [simVar]="calculator.totalOpex"
        [readOnly]="true"
        [transientValue]="true"></sim-var-input>
    </div>

    <div class="form-group">
      <label [attr.for]="'insightsTotalCapex'" class="pw-label control-label col-sm-4"> Total CAPEX </label>
      <sim-var-input
        [inputId]="'insightsTotalCapex'"
        [simVar]="calculator.totalCapex"
        [readOnly]="true"
        [transientValue]="true"></sim-var-input>
    </div>

    <div class="form-group">
      <label [attr.for]="'insightsTotalValue'" class="pw-label control-label col-sm-4"> Total Value </label>
      <sim-var-input
        [inputId]="'insightsTotalValue'"
        [simVar]="calculator.totalValue"
        [readOnly]="true"
        [transientValue]="true"></sim-var-input>
    </div>

    <div class="form-group">
      <label [attr.for]="'insightsTotalGhgIntensity'" class="pw-label control-label col-sm-4">
        Total GHG Intensity
      </label>
      <sim-var-input
        [inputId]="'insightsTotalGhgIntensity'"
        [simVar]="calculator.totalGhgIntensity"
        [readOnly]="true"
        [transientValue]="true"></sim-var-input>
    </div>

    <div class="form-group">
      <label [attr.for]="'insightsTotalSuncorGhgIntensity'" class="pw-label control-label col-sm-4">
        Total Suncor GHG Intensity
      </label>
      <sim-var-input
        [inputId]="'insights'"
        [simVar]="calculator.totalSuncorGhgIntensity"
        [readOnly]="true"
        [transientValue]="true"></sim-var-input>
    </div>
  </div>

  <div class="col-xs-12 col-sm-8 col-md-8 col-lg-9 insights-charts-container">
    <div class="row">
      <div class="col-md-4 col-sm-6 col-xs-12 insights-chart-outer">
        <div class="insights-chart-wrapper">
          <div class="insights-chart-info">
            <span class="insights-chart-title">GHG Emissions</span>
            <a (click)="switchTab('#srGhgEmissionsReport')" class="pull-right">More </a>
          </div>
          <div id="insightsGhgChart" class="insights-chart"></div>

          <div class="text-center insights-chart-subtitle">
            Suncor <span class="chart-subtitle-value" id="insightsTotalSuncorGhgValue"></span>
            {{ calculator ? calculator.totalSuncorGhg.unit : '' }}
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 col-xs-12 insights-chart-outer">
        <div class="insights-chart-wrapper">
          <div class="insights-chart-info">
            <span class="insights-chart-title">Revenue</span>
            <a (click)="switchTab('#srRevenueReport')" class="pull-right">More</a>
          </div>
          <div id="insightsRevenueChart" class="insights-chart"></div>

          <div class="text-center insights-chart-subtitle">
            Total value <span class="chart-subtitle-value" id="insightsTotalValueValue"></span>
            {{ calculator ? calculator.totalValue.getDefaultUnit() : '' }}
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 col-xs-12 insights-chart-outer">
        <div class="insights-chart-wrapper">
          <div class="insights-chart-info">
            <span class="insights-chart-title">CAPEX</span>
            <a (click)="switchTab('#srCapexReport')" class="pull-right">More</a>
          </div>
          <div id="insightsCapexChart" class="insights-chart"></div>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 col-xs-12 insights-chart-outer">
        <div class="insights-chart-wrapper">
          <div class="insights-chart-info">
            <span class="insights-chart-title">OPEX</span>
            <a (click)="switchTab('#srOpexReport')" class="pull-right">More</a>
          </div>
          <div id="insightsOpexChart" class="insights-chart"></div>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 col-xs-12 insights-chart-outer">
        <div class="insights-chart-wrapper">
          <div class="insights-chart-info">
            <span class="insights-chart-title">Refinery Flowrates</span>
            <a (click)="switchTab('#srRefineryFlowRates')" class="pull-right">More</a>
          </div>
          <div id="insightsRefineryChart" class="insights-chart"></div>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 col-xs-12 insights-chart-outer">
        <div class="insights-chart-wrapper">
          <div class="insights-chart-info">
            <span class="insights-chart-title">Diluent Flowrates</span>
            <a (click)="switchTab('#srDiluentFlowrates')" class="pull-right">More</a>
          </div>

          <div id="insightsDiluentChart" class="insights-chart"></div>
        </div>
      </div>
    </div>
  </div>
</div>
