import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { MultiPeriodSettings } from '../../_models/_multi-period/multi-period-settings';
import { NumberToUnitConverter } from '../number-to-unit-converter.service';

declare let unitConverter: any;

export abstract class AbstractMultiPeriodSpreadsheetEventHandler {
  protected constructor(protected spreadsheet: wjcSheet.FlexSheet, private nuc: NumberToUnitConverter) {}

  handle(multiPeriodSettings: MultiPeriodSettings, flexSheetEvent: any): void {
    const { col } = flexSheetEvent;
    const { row } = flexSheetEvent;

    const multiPeriodParameter = multiPeriodSettings.multiPeriodParameters[col - 1];
    if (!multiPeriodSettings.multiPeriodParameters[col - 1] || row - 1 < 0 || row > multiPeriodSettings.numberOfSteps) {
      flexSheetEvent.cancel = true;
      return;
    }

    const rawValue = this.getEventData(flexSheetEvent);

    let convertedValue = unitConverter.parseFloatString(rawValue);

    if (isNaN(convertedValue)) {
      flexSheetEvent.cancel = true;
      return;
    }

    const simVar = multiPeriodParameter.simulationVariable;
    convertedValue = this.nuc.convertDefaultToInternalUnit(convertedValue, simVar.quantity);

    const periodIndex = row - 1;

    multiPeriodParameter.multiPeriodValues[periodIndex] = convertedValue;
  }

  abstract getEventData(flexSheetEvent: any): string;
}

export class MultiPeriodSpreadsheetEditEventHandler extends AbstractMultiPeriodSpreadsheetEventHandler {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(spreadsheet: wjcSheet.FlexSheet, nuc: NumberToUnitConverter) {
    super(spreadsheet, nuc);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getEventData(flexSheetEvent: any): string {
    return this.spreadsheet.activeEditor.value;
  }
}

export class MultiPeriodSpreadsheetPasteEventHandler extends AbstractMultiPeriodSpreadsheetEventHandler {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(spreadsheet: wjcSheet.FlexSheet, nuc: NumberToUnitConverter) {
    super(spreadsheet, nuc);
  }

  getEventData(flexSheetEvent: any): string {
    return flexSheetEvent.data;
  }
}
