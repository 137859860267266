import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { Refinery } from '../../../_models/_unit-operations/refinery';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';

@Component({
  selector: 'sob-refinery',
  templateUrl: './refinery.component.html',
  styleUrls: ['./refinery.component.css'],
})
export class RefineryComponent implements OnInit {
  @Input() unitOperation: Refinery;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  constructor(
    private fb: UntypedFormBuilder,
    private svfb: SimVarFormBuilder,
    private flowsheetDiagramService: FlowsheetDiagramService
  ) {}

  ngOnInit(): void {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('hasTwoInlets', this.fb.control(this.unitOperation.hasTwoInlets));
    this.propertyWindowFormGroup.addControl('lightEndsMargin', this.svfb.control(this.unitOperation.lightEndsMargin));
    this.propertyWindowFormGroup.addControl('naphthaMargin', this.svfb.control(this.unitOperation.naphthaMargin));
    this.propertyWindowFormGroup.addControl('distillateMargin', this.svfb.control(this.unitOperation.distillateMargin));
    this.propertyWindowFormGroup.addControl('gasOilMargin', this.svfb.control(this.unitOperation.gasOilMargin));
    this.propertyWindowFormGroup.addControl('vacuumResidueMargin', this.svfb.control(this.unitOperation.naphthaMargin));
    this.propertyWindowFormGroup.addControl(
      'volumetricFlowrate',
      this.svfb.control(this.unitOperation.volumetricFlowrate, true)
    );
    this.propertyWindowFormGroup.addControl(
      'feedstockValue',
      this.svfb.control(this.unitOperation.feedstockValue, true)
    );
    this.propertyWindowFormGroup.addControl(
      'train2FeedstockValue',
      this.svfb.control(this.unitOperation.train2FeedstockValue, true)
    );

    this.propertyWindowFormGroup.addControl('ghgIntensity', this.svfb.control(this.unitOperation.ghgIntensity, true));
    this.propertyWindowFormGroup.addControl('ghgEmissions', this.svfb.control(this.unitOperation.ghgEmissions, true));
    this.propertyWindowFormGroup.addControl(
      'cumulativeGhgEmissions',
      this.svfb.control(this.unitOperation.cumulativeGhgEmissions, true)
    );

    this.propertyWindowFormGroup.addControl('electricityPrice', this.svfb.control(this.unitOperation.electricityPrice));
    this.propertyWindowFormGroup.addControl('revenue', this.svfb.control(this.unitOperation.revenue, true));
    this.propertyWindowFormGroup.addControl('unitValue', this.svfb.control(this.unitOperation.unitValue, true));
    this.propertyWindowFormGroup.addControl('opexVar', this.svfb.control(this.unitOperation.opexVar));
    this.propertyWindowFormGroup.addControl('opexGasDiesel', this.svfb.control(this.unitOperation.opexGasDiesel));
    this.propertyWindowFormGroup.addControl('opexPower', this.svfb.control(this.unitOperation.opexPower));
    this.propertyWindowFormGroup.addControl(
      'opexCarbonDioxide',
      this.svfb.control(this.unitOperation.opexCarbonDioxide, true)
    );
    this.propertyWindowFormGroup.addControl('opexFixed', this.svfb.control(this.unitOperation.opexFixed));
    this.propertyWindowFormGroup.addControl('opexTotal', this.svfb.control(this.unitOperation.opexTotal, true));
    this.propertyWindowFormGroup.addControl('capexAmortized', this.svfb.control(this.unitOperation.capexAmortized));
    this.propertyWindowFormGroup.addControl('capexTotal', this.svfb.control(this.unitOperation.capexTotal, true));

    this.propertyWindowFormGroup.addControl(
      'capacity',
      this.svfb.control(this.unitOperation.capacity, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'minimumFlow',
      this.svfb.control(this.unitOperation.minimumFlow, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'maximumSulfurContent',
      this.svfb.control(this.unitOperation.maximumSulfurContent, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'maximumMassDensity',
      this.svfb.control(this.unitOperation.maximumMassDensity, false, false, true)
    );

    this.propertyWindowFormGroup.addControl(
      'train2Capacity',
      this.svfb.control(this.unitOperation.train2Capacity, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'train2MinimumFlow',
      this.svfb.control(this.unitOperation.train2MinimumFlow, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'train2MaximumSulfurContent',
      this.svfb.control(this.unitOperation.train2MaximumSulfurContent, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'train2MaximumMassDensity',
      this.svfb.control(this.unitOperation.train2MaximumMassDensity, false, false, true)
    );
  }

  changeInletPorts() {
    if (this.twoInletsControl) {
      this.flowsheetDiagramService.changeTemplate(
        this.unitOperation.id,
        unitOperationsConfig.multipleInletRefinery.key
      );
    } else {
      this.flowsheetDiagramService.changeTemplate(this.unitOperation.id, unitOperationsConfig.refinery.key);
    }
    this.flowsheetDiagramService.deleteInletStreams(this.unitOperation.id);
  }

  get twoInletsControl() {
    return this.formGroupWrapper.getFormGroup().get('hasTwoInlets').value;
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }
}
