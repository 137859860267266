import { unitOperationsConfig } from '../_config/unit-operations.config';
import { BitumenConversionOutlet } from '../_models/_unit-operations/bitumen-conversion-outlet';
import { SuncorMaterialStream } from '../_models';

export class V2100 {
  static transformBitumenConversionBlocks(serializedCase: any) {
    const blocks = Object.values(serializedCase.unitOperationPool).filter((uo: any) => {
      return uo.category === unitOperationsConfig.bitumenConversion.key && !uo.hasOwnProperty('outlets');
    }) as any[];

    for (const uo of blocks) {
      const stream = Object.values(serializedCase.materialStreamPool).filter(
        (s: any) => s.inletUnitOperationId === uo.id
      )[0] as SuncorMaterialStream;
      if (stream) {
        uo.outlets = [];
        const outlet = new BitumenConversionOutlet(
          {
            unitOperationId: stream.outletUnitOperationId,
          },
          null,
          null
        );
        outlet.fluidAnalysisId = uo.fluidAnalysisId;
        outlet.yield = uo.totalYield;
        outlet.price = uo.productPrice;
        uo.outlets.push(outlet);
      }
    }
  }
}
