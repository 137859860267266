import { SimulationVariable } from '../simulation-variable';

export class ParametricStudyKpiResult {
  kpi: SimulationVariable;
  variableValues: number[] = [];

  constructor(parametricStudyResult: any) {
    if (parametricStudyResult.kpi instanceof SimulationVariable) {
      this.kpi = parametricStudyResult.kpi;
      this.variableValues = Array.from(parametricStudyResult.variableValues);
    }
  }

  get length() {
    return this.variableValues.length;
  }

  public toJSON() {
    return {
      kpi: this.kpi.id.toString(),
      variableValues: this.variableValues,
    };
  }
}
