import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseObjectFormGroupWrapper } from '../../_form-utils/base-object-form-group-wrapper';
import { WaterSourceInformationStream } from '../../_models/_unit-operations/utilties/water-source-information-stream';
import { SimVarFormBuilder } from '../../_form-utils/sim-var-form-builder';
import { SteamUseUnitOperation } from '../../_models/_unit-operations/steam-use-unit-operation';
import { Case, SimulationVariable, UnitOperation } from '../../_models';
import { CoreService } from '../../_services/core.service';
import { PressureCalculationMode } from '../../_config/pressure-calculation-mode.enum';
import { SteamType } from '../../_config/steam-type.enum';
import { BaseWaterImportUnitOperation } from '../../_models/_unit-operations/utilties/base-water-import-unit-operation';

/**
 * Base class for property windows that work with information streams
 */
export abstract class AbstractWaterInformationStreamPropertyWindow {
  formGroupWrapper: BaseObjectFormGroupWrapper;
  unitOperation: BaseWaterImportUnitOperation;
  availableInformationStreamVariables: SimulationVariable[];
  abstract informationStreamsFormArray: UntypedFormArray;

  // the information streams active in the current property window
  activeInformationStreams: WaterSourceInformationStream[] = [];

  protected constructor(
    protected coreService: CoreService,
    protected fb: UntypedFormBuilder,
    protected svfb: SimVarFormBuilder
  ) {}

  /**
   * Get unit operations that may use an information stream from this property window
   * @protected
   */
  protected abstract getOtherInformationStreamUsers(): UnitOperation[];

  protected abstract usesInformationStream(informationStreamUser: UnitOperation, simulationVariableId: string): boolean;

  /**
   * Get a steam SimulationVariable that will be used for creating the list of available stream variables for
   * this property window
   * @param uo
   * @param steamType
   */
  protected abstract getAllowedAvailableSteamVariableByType(
    uo: SteamUseUnitOperation,
    steamType: SteamType
  ): SimulationVariable;

  addControls() {
    // TODO nice hack to allow change detection in name... should not be used REMOVE when not needed
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl(
      'pressureCalculationMode',
      this.fb.control(this.unitOperation.pressureCalculationMode)
    );
    this.propertyWindowFormGroup.addControl('outletPressure', this.svfb.control(this.unitOperation.outletPressure));
    this.propertyWindowFormGroup.addControl('steamType', this.fb.control(this.unitOperation.steamType));
    this.propertyWindowFormGroup.addControl('selectedInformationStreamVariableId', this.fb.control(''));
    this.propertyWindowFormGroup.addControl('inletSourceInformationStreams', this.fb.array([]));

    for (const informationStream of this.activeInformationStreams) {
      this.addInformationStreamControls(informationStream);
    }
  }

  getAvailableInformationStreamVariables(): SimulationVariable[] {
    const steamUnitOperations = this.getParentFlowsheetSteamUnitOperations();
    const simulationVariables: SimulationVariable[] = [];

    for (const steamUnitOperation of steamUnitOperations) {
      const sv = this.getAllowedAvailableSteamVariableByType(steamUnitOperation, this.selectedSteamType as SteamType);

      // don't even show it if it is already in use
      if (this.isInformationStreamInUseByOtherUnitOperation(sv.id)) {
        continue;
      }
      simulationVariables.push(
        this.getAllowedAvailableSteamVariableByType(steamUnitOperation, this.selectedSteamType as SteamType)
      );
    }
    return simulationVariables;
  }

  protected getParentFlowsheetSteamUnitOperations() {
    const parentUnit = this.currentCase.getUnitOperation(this.unitOperation.flowsheetId);
    const parentFlowsheetId = parentUnit.flowsheetId;

    return this.coreService.currentCase.filterUnitOperations(uo => {
      return uo.flowsheetId === parentFlowsheetId && uo instanceof SteamUseUnitOperation;
    }) as SteamUseUnitOperation[];
  }

  // region adding/removing information streams
  onSteamTypeChanged() {
    this.availableInformationStreamVariables = this.getAvailableInformationStreamVariables();
  }

  addInformationStreamControls(informationStream: WaterSourceInformationStream) {
    const informationStreamGroup = this.fb.group({
      id: this.fb.control(informationStream.id), // not visible at all
      simulationVariableId: this.fb.control(informationStream.simulationVariableId), // not visible
      temperature: this.svfb.control(informationStream.temperature, true),
      pressure: this.svfb.control(informationStream.pressure, true),
      massFlowrate: this.svfb.control(informationStream.massFlowrate, true),
      vaporFraction: this.svfb.control(informationStream.vaporFraction, true),
    });

    this.informationStreamsFormArray.push(informationStreamGroup);
  }

  protected isInformationStreamInUseByOtherUnitOperation(simulationVariableId: string) {
    const otherInformationStreamUsers = this.getOtherInformationStreamUsers();

    for (const informationStreamUser of otherInformationStreamUsers) {
      if (this.usesInformationStream(informationStreamUser, simulationVariableId)) {
        return true;
      }
    }

    return false;
  }

  addInformationStream() {
    if (this.isSelectedInformationStreamActive()) {
      return;
    }

    const simVar = this.availableInformationStreamVariables.find(
      sv => sv.id === this.selectedInformationStreamVariableId
    );
    const simVarIndex = this.availableInformationStreamVariables.findIndex(
      sv => sv.id === this.selectedInformationStreamVariableId
    );
    const informationStream = new WaterSourceInformationStream({}, this.unitOperation.ownerCase);
    informationStream.simulationVariableId = simVar.id;
    this.addInformationStreamControls(informationStream);
    this.activeInformationStreams.push(informationStream);
    this.availableInformationStreamVariables.splice(simVarIndex, 1);
  }

  protected isSelectedInformationStreamActive(): boolean {
    // instead of just a boolean this method could return a more elaborated object, maybe it could include the names
    // of the owner unit operation
    return !!this.activeInformationStreams.find(
      is => is.simulationVariableId === this.selectedInformationStreamVariableId
    );
  }

  filterStreams() {
    if (this.activeInformationStreams.length === 0) {
      this.availableInformationStreamVariables = this.getAvailableInformationStreamVariables();
    } else {
      this.availableInformationStreamVariables = this.getAvailableInformationStreamVariables();
      for (let i = 0; i < this.activeInformationStreams.length; i++) {
        for (let j = 0; j < this.availableInformationStreamVariables.length; j++) {
          if (
            this.availableInformationStreamVariables[j].id === this.activeInformationStreams[i].simulationVariableId
          ) {
            this.availableInformationStreamVariables.splice(j, 1);
          }
        }
      }
    }
  }

  removeInformationStream(i: number) {
    this.activeInformationStreams.splice(i, 1);
    this.informationStreamsFormArray.removeAt(i);
    this.filterStreams();
  }
  // endregion

  // region form group getters
  getInformationStreamsFormGroupsArray(): UntypedFormGroup[] {
    return this.informationStreamsFormArray.controls as UntypedFormGroup[];
  }

  get selectedInformationStreamVariableId(): string {
    return this.propertyWindowFormGroup.get('selectedInformationStreamVariableId').value;
  }

  get isOutletPressureCalculationMode(): boolean {
    return (
      this.propertyWindowFormGroup.controls['pressureCalculationMode'].value === PressureCalculationMode.OUTLET_PRESSURE
    );
  }

  get selectedSteamType(): string {
    return this.propertyWindowFormGroup.get('steamType').value;
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  // endregion

  get currentCase(): Case {
    return this.coreService.currentCase;
  }
}
