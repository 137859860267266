<div class="row">
  <div class="col-md-4" style="text-align: right; margin-top: 7px">
    <span><strong>Select Flowsheet: </strong></span>
  </div>
  <div class="col-md-4">
    <sob-flowsheet-tree-selector
      [modalName]="'kpiManagerModal'"
      (emitFlowsheetOwnerId)="getFlowsheetOwnerId($event)"></sob-flowsheet-tree-selector>
  </div>
</div>
<br />

<div class="row kpiManagerControls">
  <div class="col-md-4">
    <div class="form-group">
      <span><strong>Category</strong></span>
      <select class="form-control" size="2" (change)="categoryChanged($event)">
        <option *ngFor="let category of kpiManager.categories" value="{{ category.categoryName }}">
          {{ category.categoryName }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <span><strong>KPI Provider</strong></span>
      <select class="form-control" size="2" (change)="kpiProviderChanged($event)">
        <option *ngFor="let kpiProvider of kpiProvidersForCategory | alphanumericSort" value="{{ kpiProvider.id }}">
          {{ kpiProvider.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group" style="margin-bottom: 2px">
      <span><strong>Available KPIs</strong></span>
      <select class="form-control" [(ngModel)]="availableKpiId" size="2">
        <option *ngFor="let kpi of availableKpis" [value]="kpi.id">
          {{ kpi.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <button class="btn btn-xs btn-success" (click)="addKpi()" data-toggle="tooltip" title="Add selected KPI">
        <i class="fa fa-plus-circle"></i>
      </button>
    </div>
  </div>
</div>

<hr />

<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-xs-12">
        <strong>Selected KPIs</strong>
      </div>
    </div>

    <div class="selectedKpisWrapper">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Variable Name</th>
            <th>Unit</th>
            <th>Current Value</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let kpi of kpiManager.selectedKpis">
            <td><input type="checkbox" [attr.data-kpi]="kpi.id" /></td>
            <td>{{ kpi.getFullName() }}</td>
            <td>{{ kpi.unit }}</td>
            <td>{{ kpi.convertToCaptureUnit() }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr style="margin-top: 5px; margin-bottom: 5px" />

    <button
      (click)="copyToClipboard()"
      class="btn btn-xs btn-default"
      data-toggle="tooltip"
      title="Copy table content to clipboard">
      <i class="fa fa-copy"></i>
      Copy to clipboard
    </button>

    <button class="btn btn-xs btn-danger" (click)="removeKpis()" data-toggle="tooltip" title="Remove selected KPIs">
      <i class="fa fa-minus-circle"></i>
    </button>
  </div>
</div>

<textarea id="kpiClipboardText" cols="1" rows="1" style="position: absolute; left: -9999px"></textarea>
