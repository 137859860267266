import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { KpiManager } from '../../../_models/_case-study/kpi-manager';
import { SimulationVariable } from '../../../_models/simulation-variable';
import { BaseObject } from '../../../_models/base-object';
import { KpiProvider } from '../../../_models/_case-study/kpi-provider';
import { UnitOperation } from '../../../_models/unit-operation';
import { FlowsheetTreeService } from '../../../_services/sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { CoreService } from '../../../_services/core.service';
import { isEmptyValue } from '../../../_utils/utils';
import { BaseStream } from '../../../_models/_streams/base-stream';
import { WaterPipeSegment } from '../../../_models/_unit-operations/utilties/water-pipe-segment';
import { FlowsheetTreeSelectorComponent } from '../../flowsheet-tree-selector/flowsheet-tree-selector.component';

@Component({
  selector: 'sob-kpi-manager',
  templateUrl: './kpi-manager.component.html',
  styleUrls: ['./kpi-manager.component.css'],
})
export class KpiManagerComponent implements OnInit {
  @Input()
  kpiManager: KpiManager;

  kpiProvidersForCategory: BaseObject[] = [];
  availableKpis: SimulationVariable[] = [];

  // references the selected element value in the available kpis list
  availableKpiId: string;
  currentlyFlowsheetOwnerId: string;

  @ViewChild(FlowsheetTreeSelectorComponent) flowsheetSelector: FlowsheetTreeSelectorComponent;

  constructor(private flowsheetTree: FlowsheetTreeService, private coreService: CoreService) {}

  ngOnInit(): void {
    $('#pillContainer').on('shown.bs.tab', () => {
      if ($('#multi-period-settings-container').hasClass('active')) {
        this.cleanInputs();
      }
    });
  }

  cleanInputs(): void {
    $('.kpiManagerControls').find('select').val('');
    this.kpiManager.categories = [];
    this.currentlyFlowsheetOwnerId = '';
    this.availableKpiId = '';
    this.kpiProvidersForCategory = [];
    this.availableKpis = [];
    $('.selectedKpisWrapper').find('input[type=checkbox]:checked').prop('checked', false);
  }

  categoryChanged(event: any): void {
    this.kpiProvidersForCategory = this.kpiManager
      .getKpiProvidersByCategoryName(event.target.value)
      .filter(kpiProvider => {
        if (kpiProvider instanceof UnitOperation) {
          return kpiProvider.flowsheetId === this.currentlyFlowsheetOwnerId;
        }
        if (kpiProvider instanceof BaseStream) {
          const inletUnitOperation = this.coreService.currentCase.getUnitOperation(kpiProvider.inletUnitOperationId);
          return inletUnitOperation.flowsheetId === this.currentlyFlowsheetOwnerId;
        }
        if (kpiProvider instanceof WaterPipeSegment) {
          return kpiProvider.getWaterPipeSegmentOwner(kpiProvider.id).flowsheetId === this.currentlyFlowsheetOwnerId;
        }
        return kpiProvider;
      });
    this.availableKpis = [];
    this.availableKpiId = '';
  }

  kpiProviderChanged(event: any) {
    const selectedBaseObject = <any>(
      this.kpiProvidersForCategory.find(kpiProvider => kpiProvider.id === event.target.value)
    );

    this.availableKpis = (<KpiProvider>selectedBaseObject).getAvailableKpis();
    this.availableKpiId = '';
  }

  addKpi(): void {
    if (this.availableKpiId) {
      this.kpiManager.addKpiById(this.availableKpiId);
    }
  }

  removeKpis(): void {
    $('.selectedKpisWrapper')
      .find('input[type=checkbox]:checked')
      .each((index, elem) => {
        this.kpiManager.removeKpiById($(elem).data('kpi'));
      });
  }

  copyToClipboard() {
    let text = 'Variable name\tUnit\tCurrent value\n';
    for (const kpi of this.kpiManager.selectedKpis) {
      text += `${kpi.getFullName()}\t${kpi.unit}\t${kpi.convertToCaptureUnit(true)}\n`;
    }

    (document.getElementById('kpiClipboardText') as HTMLTextAreaElement).value = text;
    (document.getElementById('kpiClipboardText') as HTMLTextAreaElement).select();
    document.execCommand('copy');
  }

  getFlowsheetOwnerId(fOwnerId: string) {
    this.cleanInputs();
    this.currentlyFlowsheetOwnerId = fOwnerId;
    if (!isEmptyValue(fOwnerId)) {
      const flowsheetOwnerCategory = this.coreService.currentCase.getUnitOperation(fOwnerId).category;
      switch (flowsheetOwnerCategory) {
        case unitOperationsConfig.upgrader.key:
          this.coreService.currentCase.initUpgraderFlowsheetCategories();
          break;
        case unitOperationsConfig.waterUtilityUnitOperation.key:
          this.coreService.currentCase.initSteamFlowsheetCategories();
          break;
        case unitOperationsConfig.fuelGasUtilityUnitOperation.key:
          this.coreService.currentCase.initFuelGasFlowsheetCategories();
          break;
        default:
          break;
      }
    } else if (fOwnerId === '') {
      this.coreService.currentCase.initMainFlowsheetCategories();
    }
  }
}
