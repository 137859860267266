<div class="modal fade" id="globalKpiModal" tabindex="-1" role="dialog" aria-labelledby="globalKpiLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Global KPI</h4>
      </div>

      <div class="modal-body">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#clGlobalKpi" aria-controls="clGlobalKpi" role="tab" data-toggle="tab"> Global KPI </a>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane fade in active" id="clGlobalKpi">
            <div class="alert alert-warning" *ngIf="!globalKpis.length">
              Warning! no KPIs selected. <a (click)="openKpiManager()">Go to KPI Manager</a>
            </div>

            <div class="row" *ngIf="globalKpis.length">
              <div class="col-sm-6">
                <div class="form-group">
                  <span><strong>KPI</strong></span>
                  <select class="form-control" (change)="kpiChanged($event)">
                    <option *ngFor="let kpi of globalKpis" value="{{ kpi.id }}">{{ kpi.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6 text-right">
                <a
                  title="Switch chart type"
                  class="btn btn-outline btn-white"
                  style="margin-top: 20px"
                  (click)="switchChartType()"
                  *ngIf="globalKpis.length">
                  <i *ngIf="chartType === 'line'" class="fa fa-bar-chart"></i>
                  <i *ngIf="chartType === 'column'" class="fa fa-line-chart"></i>
                </a>
              </div>
            </div>

            <div id="globalKpiChart"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
