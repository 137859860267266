<div class="dropdown">
  <button
    class="btn btn-default dropdown-toggle form-control"
    style="text-align: left; padding-left: 15px"
    type="button"
    id="dropdownMenu1"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="true">
    {{ currentAnalysis ? currentAnalysis.assayName : ('Select' | titlecase) }}
    <span class="caret" style="float: right; margin-top: 8px"></span>
  </button>
  <ul class="dropdown-menu full-width" aria-labelledby="dropdownMenu1">
    <!--          <li class="disabled"><a>Select</a></li>-->
    <ng-container *ngFor="let fa of fluidAnalyses">
      <li>
        <a (click)="updateCurrentAnalysis(fa.id)">{{ fa.assayName | titlecase }}</a>
      </li>
    </ng-container>
    <li role="separator" class="divider"></li>
    <li>
      <a (click)="openAssayManager()">Create new analysis <i class="fa fa-plus-circle" style="color: #337ab7"></i></a>
    </li>
  </ul>
</div>
