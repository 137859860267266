import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CodeEditorService } from '../code-editor.service';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';

@Component({
  selector: 'sob-open-code-editor-button',
  templateUrl: './open-code-editor-button.component.html',
  styleUrls: ['./open-code-editor-button.component.css'],
})
export class OpenCodeEditorButtonComponent implements OnDestroy {
  private ngUnsubscribe = new Subject();

  @Input() code: string;
  @Input() disabled: boolean;
  @Input() defaultCodeProvider: DefaultCodeProvider;
  @Input() instanceId: string;
  @Output() codeSaved = new EventEmitter();

  constructor(private codeEditorService: CodeEditorService) {
    this.codeEditorService.closeAndSaveCodeRequest.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.codeSaved.emit({ code: res.code, instanceId: res.instanceId });
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openCodeEditor() {
    $('#codeEditorModal').modal('show');
    $('#codeEditorInstanceId').val(this.instanceId);
    this.codeEditorService.setEditorCode({ code: this.code, defaultCodeProvider: this.defaultCodeProvider });
  }
}
