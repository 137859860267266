import { FuelGasSummaryReportComponent } from '../../flowsheet/summary-report/fuel-gas-summary-report/fuel-gas-summary-report.component';
import { Case, UnitOperation } from '../../_models';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { ArrayDiff } from '../../_utils/array-diff';
import { UtilitiesSummaryReportItem } from '../../_models/_reports/utilities-summary-report-data';

export class FuelGasExcelReport {
  static generateDataArray(fuelGasReport: FuelGasSummaryReportComponent, c: Case, flowsheetOwnerId: string) {
    const dataArray = [['Source', 'Fuel Gas Make (MMSCFD)', 'Fuel Gas Use (GJ/h)']];

    this.getFuelGasValuesByUnitOperation(fuelGasReport, dataArray, flowsheetOwnerId, c);
    return dataArray;
  }

  static getUnitOperationNamesBySubFlowsheet(
    flowsheetOwnerId: string,
    c: Case,
    fuelGasReport: FuelGasSummaryReportComponent
  ) {
    const upgraderSubFlowsheetUos = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.flowsheetId === flowsheetOwnerId;
    });

    const steamSubFlowsheet = upgraderSubFlowsheetUos.filter(u => {
      return u.category === unitOperationsConfig.waterUtilityUnitOperation.key;
    });

    const fuelGasSubFlowsheet = upgraderSubFlowsheetUos.filter(u => {
      return u.category === unitOperationsConfig.fuelGasUtilityUnitOperation.key;
    });

    const fuelGasSubFlowsheetUos = c.filterUnitOperations((uo: UnitOperation) => {
      if (fuelGasSubFlowsheet.length !== 0) {
        return uo.flowsheetId === fuelGasSubFlowsheet[0].id;
      }

      return false;
    });

    const steamSubFlowsheetUos = c.filterUnitOperations((uo: UnitOperation) => {
      if (steamSubFlowsheet.length !== 0) {
        return uo.flowsheetId === steamSubFlowsheet[0].id;
      }

      return false;
    });

    const unitOperationNamesBySubFlowsheet = upgraderSubFlowsheetUos
      .concat(fuelGasSubFlowsheetUos.concat(steamSubFlowsheetUos))
      .map(u => {
        return u.name;
      });

    const unitOperationNames = this.getReportUnitOperationNames(fuelGasReport);

    const arrayDiff = new ArrayDiff(unitOperationNamesBySubFlowsheet);
    const diff = arrayDiff.diff(unitOperationNames, (l: string, r: string) => {
      return l === r;
    });

    return diff.intersection.length !== 0 ? diff.intersection : [];
  }

  static getReportUnitOperationNames(fuelGasReport: FuelGasSummaryReportComponent) {
    const unitOperationNames = [];

    for (const property in fuelGasReport) {
      if (property.includes('ReportData')) {
        fuelGasReport[property].reportItems.forEach(uo => {
          unitOperationNames.push(uo.unitOperationName);
        });
      }
    }

    return [...new Set(unitOperationNames)];
  }

  static getFuelGasValuesByUnitOperation(
    fuelGasReport: FuelGasSummaryReportComponent,
    dataArray: any[],
    flowsheetOwnerId: string,
    c: Case
  ) {
    const uOs = this.getUnitOperationNamesBySubFlowsheet(flowsheetOwnerId, c, fuelGasReport);

    if (uOs.length !== 0) {
      uOs.forEach(u => {
        const fuelGasMake = this.getFuelGasValue(fuelGasReport.fuelGasMakeReportData.reportItems, u);
        const fuelGasUse = this.getFuelGasValue(fuelGasReport.fuelGasUseReportData.reportItems, u);

        dataArray.push([u, fuelGasMake, fuelGasUse]);
      });
    }
  }

  static getFuelGasValue(fuelGasReportData: UtilitiesSummaryReportItem[], unitOperationName: string) {
    let fuelGasValue = 0;
    fuelGasReportData.filter(uo => {
      if (uo.unitOperationName === unitOperationName) {
        fuelGasValue = uo.value;
      }
    });
    return fuelGasValue;
  }
}
