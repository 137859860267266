import * as wjcCore from '@grapecity/wijmo';

import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum SagdSourceSheetRows {
  NAME = 0,
  VOLUMETRIC_FLOW_RATE = 2,
  GHG_INTENSITY = 3,
  COGEN_ELECTRICITY_GENERATION = 5,
  NET_ELECTRICITY_EXPORT = 6,
  COGEN_INTENSITY = 7,
  GRID_INTENSITY = 8,
  LIGHT_ENDS = 11,
  LIGHT_NAPHTHA = 12,
  HEAVY_NAPHTHA = 13,
  DISTILLATE = 14,
  LGO = 15,
  HGO = 16,
  VGO = 17,
  HVGO = 18,
  RESID = 19,
  PRICE = 22,
  CAPEX_AMORTIZED = 23,
  OPEX_FIXED = 24,
  OPEX_VAR = 25,
  OPEX_GAS_DIESEL = 26,
  OPEX_POWER = 27,
}

export const SagdSourceCellConfig: FlexSheetCellConfig[] = [
  {
    row: SagdSourceSheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: SagdSourceSheetRows.VOLUMETRIC_FLOW_RATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.GHG_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.COGEN_ELECTRICITY_GENERATION,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.NET_ELECTRICITY_EXPORT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.COGEN_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.GRID_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.LIGHT_ENDS,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.LIGHT_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.HEAVY_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.DISTILLATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.LGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.HGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.VGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.HVGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.RESID,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.PRICE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.CAPEX_AMORTIZED,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.OPEX_FIXED,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.OPEX_VAR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.OPEX_GAS_DIESEL,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: SagdSourceSheetRows.OPEX_POWER,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
