<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>
  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>

  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#refineryComments" aria-controls="refineryComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="has2inlets" class="control-label col-sm-4"> Has Two Inlets </label>
          <div class="col-sm-4">
            <label class="control-label">
              <input formControlName="hasTwoInlets" type="checkbox" id="has2inlets" (change)="changeInletPorts()" />
            </label>
          </div>
        </div>

        <hr />
        <h4 class="subtitle">Cut Margin</h4>
        <ng-container>
          <div class="form-group" formGroupName="lightEndsMargin">
            <label class="control-label col-sm-4"> Lights Ends Margin </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.lightEndsMargin.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.lightEndsMargin">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="naphthaMargin">
            <label class="control-label col-sm-4"> Naphtha Margin </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.naphthaMargin.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.naphthaMargin">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="distillateMargin">
            <label class="control-label col-sm-4"> Distillate Margin </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.distillateMargin.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.distillateMargin">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="gasOilMargin">
            <label class="control-label col-sm-4"> Gas Oil Margin </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.gasOilMargin.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.gasOilMargin">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="vacuumResidueMargin">
            <label class="control-label col-sm-4"> Vacuum Residue Margin </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.vacuumResidueMargin.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.vacuumResidueMargin">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <br />

          <div class="form-group" formGroupName="volumetricFlowrate">
            <label class="control-label col-sm-4"> Volumetric Flowrate </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.volumetricFlowrate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.volumetricFlowrate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="feedstockValue">
            <label class="control-label col-sm-4"> Train 1 Feedstock Value </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.feedstockValue.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.feedstockValue">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="twoInletsControl" formGroupName="train2FeedstockValue">
            <label class="control-label col-sm-4"> Train 2 Feedstock Value </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.train2FeedstockValue.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.train2FeedstockValue">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h4 class="subtitle">GHG</h4>

          <div class="form-group" formGroupName="ghgIntensity">
            <label class="control-label col-sm-4"> GHG Intensity </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgIntensity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgIntensity">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="ghgEmissions">
            <label class="control-label col-sm-4"> Refinery GHG Emissions </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgEmissions.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgEmissions">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="cumulativeGhgEmissions">
            <label class="control-label col-sm-4"> Cumulative GHG Emissions </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.cumulativeGhgEmissions.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.cumulativeGhgEmissions">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="economics">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <ng-container>
          <div class="form-group" formGroupName="electricityPrice">
            <label class="control-label col-sm-4"> Electricity Price </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.electricityPrice.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.electricityPrice">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="revenue">
            <label class="control-label col-sm-4"> Revenue </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.revenue.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.revenue">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="unitValue">
            <label class="control-label col-sm-4"> Before Tax Cash Flow </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.unitValue.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.unitValue">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h4 class="subtitle">OPEX</h4>

          <div class="form-group" formGroupName="opexVar">
            <label class="control-label col-sm-4"> Variable </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexVar.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexVar">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexGasDiesel">
            <label class="control-label col-sm-4"> Gas Diesel </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexGasDiesel.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexGasDiesel">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexPower">
            <label class="control-label col-sm-4"> Power </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexPower.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexPower">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexCarbonDioxide">
            <label class="control-label col-sm-4"> CO2 </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.opexCarbonDioxide.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexCarbonDioxide">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexFixed">
            <label class="control-label col-sm-4"> Fixed </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexFixed.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexFixed">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexTotal">
            <label class="control-label col-sm-4"> Total </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexTotal.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexTotal">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h4 class="subtitle">CAPEX</h4>

          <div class="form-group" formGroupName="capexAmortized">
            <label class="control-label col-sm-4"> Amortized </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexAmortized.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.capexAmortized">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="capexTotal">
            <label class="control-label col-sm-4"> Total </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexTotal.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.capexTotal">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Train 1</h4>
        <p style="text-align: right; position: relative">
          <span style="position: absolute; right: -15px; top: -20px; font-weight: bold">Active</span>
        </p>
        <ng-container>
          <div class="form-group" formGroupName="capacity">
            <label class="control-label col-sm-4"> Maximum Feed Capacity </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capacity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.capacity">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.capacity"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="minimumFlow">
            <label class="control-label col-sm-4"> Minimum Flow </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.minimumFlow.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.minimumFlow">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.minimumFlow"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="maximumSulfurContent">
            <label class="control-label col-sm-4"> Maximum Sulfur Content</label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.maximumSulfurContent.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.maximumSulfurContent">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.maximumSulfurContent"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="maximumMassDensity">
            <label class="control-label col-sm-4"> Maximum Mass Density</label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.maximumMassDensity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.maximumMassDensity">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.maximumMassDensity"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="form-horizontal" *ngIf="twoInletsControl">
        <hr />
        <h4 class="subtitle">Train 2</h4>
        <p style="text-align: right; position: relative">
          <span style="position: absolute; right: -15px; top: -20px; font-weight: bold">Active</span>
        </p>
        <ng-container>
          <div class="form-group" formGroupName="train2Capacity">
            <label class="control-label col-sm-4"> Maximum Feed Capacity </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.train2Capacity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.train2Capacity">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.train2Capacity"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="train2MinimumFlow">
            <label class="control-label col-sm-4"> Minimum Flow </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.train2MinimumFlow.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.train2MinimumFlow">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.train2MinimumFlow"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="train2MaximumSulfurContent">
            <label class="control-label col-sm-4"> Maximum Sulfur Content</label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.train2MaximumSulfurContent.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.train2MaximumSulfurContent">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.train2MaximumSulfurContent"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="train2MaximumMassDensity">
            <label class="control-label col-sm-4"> Maximum Mass Density</label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.train2MaximumMassDensity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.train2MaximumMassDensity">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.train2MaximumMassDensity"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="refineryComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
