import { ReportValue } from './report-value';
import { Case } from '../case';
import { UnitOperation } from '../unit-operation';
import { ThirdPartyRefinery } from '../_unit-operations/third-party-refinery';
import { Refinery } from '../_unit-operations/refinery';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { SimulationVariable } from '../simulation-variable';
import { SuncorUnitOperation } from '../_unit-operations/suncor-unit-operation';
import { isNullOrUndefined } from '../../_utils/utils';

declare let unitConverter: any;

export class RevenueReport {
  total: number;
  totalValue: number;
  unit: string;
  data: Array<ReportValue> = [];

  constructor(c: Case) {
    this.initReport(c);
  }

  initReport(c: Case) {
    this.total = 0;
    this.totalValue = 0;
    const refineries = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.category === unitOperationsConfig.refinery.key;
    }) as Array<Refinery>;

    const thirpartyrefineries = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.category === unitOperationsConfig.thirdPartyRefinery.key;
    }) as Array<ThirdPartyRefinery>;

    for (const uo of refineries) {
      const s = uo.revenue;
      this.total += s.value;
      this.data.push(new ReportValue(unitConverter.parseFloatString(s.convertToDefaultUnit(true, false)), uo.name));
    }
    for (const uo of thirpartyrefineries) {
      const s = uo.revenue;
      // s.value = uo.feedstockValue.value * uo.volumetricFlowrate.value;
      this.total += s.value;
      this.data.push(new ReportValue(unitConverter.parseFloatString(s.convertToDefaultUnit(true, false)), uo.name));
    }

    const units = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.category !== unitOperationsConfig.splitter.key && uo.category !== unitOperationsConfig.mixer.key;
    }) as Array<SuncorUnitOperation>;

    this.totalValue = this.total;
    for (const uo of units) {
      let s;
      if (uo.capexTotal) {
        s = uo.capexTotal.value + uo.opexTotal.value;
      }
      const isInvalid = isNaN(s) || isNullOrUndefined(s);

      this.totalValue -= isInvalid ? 0 : s;
    }

    const sv = refineries.length
      ? refineries[0].revenue
      : thirpartyrefineries.length
      ? thirpartyrefineries[0].revenue
      : null;

    if (sv) {
      this.unit = sv.unit;
      const totalSv = new SimulationVariable(sv, sv.ownerCase, sv.ownerBaseObject);
      totalSv.value = this.total;

      this.total = unitConverter.parseFloatString(totalSv.convertToDefaultUnit(true, false));
      this.totalValue = unitConverter.parseFloatString(
        unitConverter(sv.quantity).convert(this.totalValue, sv.getInternalUnit(), this.unit).round().getValue()
      );
    }
  }
}
