import { Injectable } from '@angular/core';
import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { Case, UnitOperation } from '../../../_models';
import { CoreService } from '../../core.service';
import { NumberToUnitConverter } from '../../number-to-unit-converter.service';
import { SpreadsheetTitle } from '../../../_config/spreadsheet/spreadsheet-title.enum';
import { Upgrader } from '../../../_models/_unit-operations/upgrader';
import { UpgraderH2sSheetHandler } from './upgrader-h2s-sheet-handler';

@Injectable({ providedIn: 'root' })
export class H2sSheetGroupHandler {
  // TODO probably remove this
  private spreadsheet: wjcSheet.FlexSheet;
  private sheetHandlers: UpgraderH2sSheetHandler[];

  constructor(private coreService: CoreService, private nuc: NumberToUnitConverter) {}

  // region init - base code
  init(spreadsheet: wjcSheet.FlexSheet, upgsOwners: Upgrader[]) {
    this.spreadsheet = spreadsheet;
    this.sheetHandlers = [];
    this.removeAllH2sSheets();

    upgsOwners.forEach((upg, index) => {
      const upgraderH2sSheetHandler = new UpgraderH2sSheetHandler(upg, this.nuc);
      upgraderH2sSheetHandler.init(this.spreadsheet, this.coreService.currentCase, index);
      this.sheetHandlers.push(upgraderH2sSheetHandler);
      this.caseToSpreadsheet(this.coreService.currentCase, upg);
    });
  }

  caseToSpreadsheet(c: Case, upgOwner: Upgrader) {
    this.sheetHandlers.forEach(sh => {
      if (sh.belongsToUnitOperation(upgOwner.id)) {
        sh.caseToSpreadsheet(c);
      }
    });
  }
  // endregion

  // region adding
  addUnitOperation(uo: UnitOperation): void {
    this.sheetHandlers.forEach(sh => {
      if (sh.belongsToUnitOperation(uo.flowsheetId)) {
        sh.addUnitOperation(uo);
      }
    });
  }
  // endregion

  // region updating
  updateUnitOperation(uo: UnitOperation): void {
    this.sheetHandlers.forEach(sh => {
      if (sh.belongsToUnitOperation(uo.flowsheetId)) {
        sh.updateUnitOperation(uo);
      }
    });
  }
  // endregion

  // region removing
  removeUnitOperation(uo: UnitOperation): void {
    this.sheetHandlers.forEach(sh => {
      if (sh.belongsToUnitOperation(uo.flowsheetId)) {
        sh.removeUnitOperation(uo);
      }
    });
  }
  // endregion

  clearResults(): void {
    this.sheetHandlers.forEach(sh => {
      sh.clearResults();
    });
  }

  removeAllH2sSheets() {
    const sheetNames = [];
    this.spreadsheet.sheets.forEach(sheet => {
      if (sheet.name.includes(` - ${SpreadsheetTitle.ACID_GAS_SULFUR}`)) {
        sheetNames.push(sheet.name);
      }
    });

    sheetNames.forEach(name => {
      this.spreadsheet.sheets.forEach((sheet, idx) => {
        if (sheet.name === name) {
          this.spreadsheet.sheets.removeAt(idx);
        }
      });
    });
  }

  removeH2sSheetsByUpgOwner(upgOwner: Upgrader) {
    this.spreadsheet.sheets.forEach((sheet, idx) => {
      if (sheet.name === `${upgOwner.name} - ${SpreadsheetTitle.ACID_GAS_SULFUR}`) {
        this.spreadsheet.sheets.removeAt(idx);
      }
    });
    this.sheetHandlers.forEach((sh, idx) => {
      if (sh.belongsToUnitOperation(upgOwner.id)) {
        this.sheetHandlers.splice(idx, 1);
      }
    });
  }
}
