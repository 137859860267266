import { QueryList } from '@angular/core';
import { ValueInputComponent } from './value-input/value-input.component';
import { UnitSelectComponent } from './unit-select/unit-select.component';
import { SimulationVariableArrayComponent } from './simulation-variable-array/simulation-variable-array.component';

declare let unitConverter: any;

export function attachSimVarEvent(valueField, unitField, quantity, _self): void {
  // at this point, it is assumed that valueField has the 'internal' value of the sim var
  unitField.data('fromUnit', unitConverter(quantity).getInternalUnit());
  valueField.data('value', _self.originalValue);

  // eslint-disable-next-line func-names
  valueField.on('change', function () {
    const $valueField = $(this);
    _self.setValue($valueField.val(), $valueField.val());
    $(this).data('value', $(this).val());
    unitField.data('fromUnit', unitField.val());
  });

  // eslint-disable-next-line func-names
  unitField.change(function () {
    const fromUnit = $(this).data('fromUnit');
    const toUnit = $(this).val();

    if (toUnit) {
      const valToConvert = valueField.data('value');
      if (fromUnit && valToConvert) {
        const conversion = unitConverter(quantity).convert(valToConvert, fromUnit, toUnit);
        _self.setValue(conversion.getValue(), conversion.round().format());
        _self.ref.detectChanges();
      }
    }
  });
}

export function attachMultipleSimVarEvent(
  valueInputs: QueryList<ValueInputComponent>,
  unitSelect: UnitSelectComponent
) {
  const internalUnit = unitConverter(unitSelect.simVar.quantity).getInternalUnit();

  // eslint-disable-next-line func-names
  valueInputs.forEach(function (item) {
    const $valueInput = $(`#${item.inputId}`);

    $valueInput.data('value', item.originalValue).data('fromUnit', internalUnit);

    // eslint-disable-next-line func-names
    $valueInput.on('change', function () {
      $(this).data('value', $(this).val());
      $(this).data('fromUnit', $(`#${unitSelect.selectId}`).val());
      item.setValue($valueInput.val(), $valueInput.val());
    });
  });

  // eslint-disable-next-line func-names
  $(`#${unitSelect.selectId}`).change(function () {
    const toUnit = $(this).val(); // maybe accessing sim var's unit?

    if (toUnit) {
      // eslint-disable-next-line func-names
      valueInputs.forEach(function (item) {
        const $valueInput = $(`#${item.inputId}`);
        const valToConvert = $valueInput.data('value');
        const fromUnit = $valueInput.data('fromUnit');

        item.setUnit(toUnit); // update the unit for the current simVar

        if (fromUnit && valToConvert) {
          const conversion = unitConverter(unitSelect.simVar.quantity).convert(valToConvert, fromUnit, toUnit);
          item.setValue(conversion.getValue(), conversion.round().format());
          item.ref.detectChanges();
        } // end fromUnit && valToConvert
      }); // end forEach
    } // end if toUnit
  });
}

export function attachSimulationVariableArrayEvent(component: SimulationVariableArrayComponent) {
  const sva = component.simVarArray;
  const us = component.unitSelect;
  const prefix = component.idPrefix;
  const internalUnit = unitConverter(sva.quantity).getInternalUnit();

  // eslint-disable-next-line guard-for-in
  for (const i in sva.values) {
    $(`#${prefix}Value${i}`).data('value', sva.values[i]).data('fromUnit', internalUnit);
  }

  // eslint-disable-next-line func-names
  $(`#${us.selectId}`).change(function () {
    const toUnit = $(this).val();
    if (toUnit) {
      // eslint-disable-next-line guard-for-in
      for (const i in sva.values) {
        const $field = $(`#${prefix}Value${i}`);
        const valToConvert = $field.data('value');
        const fromUnit = $field.data('fromUnit');

        if (fromUnit && valToConvert) {
          const conversion = unitConverter(sva.quantity).convert(valToConvert, fromUnit, toUnit);
          component.setValue(i, conversion.getValue(), conversion.round().format());
          component.ref.detectChanges();
        }
      }
    }
  });

  for (const i of sva.values) {
    const $input = $(`#${prefix}Value${i}`);
    $input.on('change', function () {
      $input.data('value', jQuery(this).val());
      $input.data('fromUnit', $(us.selectId).val());
      component.setValue(i, $input.val(), $input.val());
    });
  }
}
