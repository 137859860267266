import { Injectable } from '@angular/core';
import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { SteamSheetHandler } from './steam-sheet-handler';
import { CoreService } from '../core.service';
import { Case } from '../../_models';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { NumberToUnitConverter } from '../number-to-unit-converter.service';
import { HydrogenSheetHandler } from './hydrogen-sheet-handler';
import { AbstractUtilitiesHandler } from './abstract-utilities-handler';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { SpreadsheetTitle } from '../../_config/spreadsheet/spreadsheet-title.enum';
import { WaterUtilityUnitOperation } from '../../_models/_unit-operations/utilties/water-utility-unit-operation';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesSpreadsheetService {
  private spreadsheet: wjcSheet.FlexSheet;
  sheetHandlers: AbstractUtilitiesHandler[] = [];

  constructor(private coreService: CoreService, private nuc: NumberToUnitConverter) {}

  init(spreadsheet: wjcSheet.FlexSheet, upgsOwners: Upgrader[], sheetType: SpreadsheetTitle) {
    this.spreadsheet = spreadsheet;
    this.removeSheetHandlersByType(sheetType);
    this.removeAllUtilitiesSheetsByType(sheetType);

    let cont = 0;
    upgsOwners.forEach((upg, idx) => {
      if (sheetType === SpreadsheetTitle.STEAM_REPORT) {
        const findUpgraderSteamChild = this.coreService.currentCase.filterUnitOperations(
          uo => uo instanceof WaterUtilityUnitOperation && uo.flowsheetId === upg.id
        );
        if (findUpgraderSteamChild.length !== 0) {
          cont++;
          const steamSheetHandlerV2 = new SteamSheetHandler(this.coreService, upg, this.nuc);
          steamSheetHandlerV2.sheetIndex = this.getSheetIndex() + cont;
          steamSheetHandlerV2.init(
            this.spreadsheet,
            `${upg.name} - ${SpreadsheetTitle.STEAM_REPORT}`,
            steamSheetHandlerV2.sheetIndex
          );
          this.sheetHandlers.push(steamSheetHandlerV2);
        }
      } else {
        const hydrogenSheetHandlerV2 = new HydrogenSheetHandler(this.coreService, upg, this.nuc);
        hydrogenSheetHandlerV2.sheetIndex = 2 + idx;
        hydrogenSheetHandlerV2.init(
          this.spreadsheet,
          `${upg.name} - ${SpreadsheetTitle.HYDROGEN}`,
          hydrogenSheetHandlerV2.sheetIndex
        );
        this.sheetHandlers.push(hydrogenSheetHandlerV2);
      }
      this.caseToSpreadsheet(this.coreService.currentCase, upg, sheetType);
      this.addEventsHandlers(spreadsheet, upg, sheetType);
    });

    // TODO add more initialization code tht only exists in water-utility-unit-operation-spreadsheet-service
  }

  addEventsHandlers(spreadsheet: wjcSheet.FlexSheet, upgOwner: Upgrader, sheetType: SpreadsheetTitle) {
    spreadsheet.cellEditEnding.addHandler((ss: wjcSheet.FlexSheet, flexSheetEvent: any) => {
      this.sheetHandlers.forEach(sh => {
        if (sh.sheetName === `${upgOwner.name} - ${sheetType}`) {
          sh.handleEditEvent(ss, flexSheetEvent);
        }
      });
    });

    spreadsheet.pastingCell.addHandler((ss: wjcSheet.FlexSheet, flexSheetEvent: any) => {
      this.sheetHandlers.forEach(sh => {
        if (sh.sheetName === `${upgOwner.name} - ${sheetType}`) {
          sh.handlePasteEvent(ss, flexSheetEvent);
        }
      });
    });
  }

  caseToSpreadsheet(c: Case, upgOwner: Upgrader, sheetType: SpreadsheetTitle): void {
    this.sheetHandlers.forEach(sh => {
      if (sh.belongsToUnitOperation(upgOwner.id, sheetType)) {
        sh.caseToSpreadsheet(c, upgOwner);
      }
    });
  }

  addUnitOperation(unitOperation: SuncorUnitOperation, upgOwner: Upgrader, sheetType?: SpreadsheetTitle): void {
    this.sheetHandlers.forEach(sh => {
      if (sh.belongsToUnitOperation(upgOwner.id, sheetType)) {
        sh.addUnitOperation(unitOperation);
      }
    });
  }

  clearResults(): void {
    this.sheetHandlers.forEach(sh => {
      sh.clearResults();
    });
  }

  updateUnitOperation(unitOperation: SuncorUnitOperation, upgOwner: Upgrader, sheetType?: SpreadsheetTitle): void {
    this.sheetHandlers.forEach(sh => {
      if (sh.belongsToUnitOperation(upgOwner.id, sheetType)) {
        sh.updateUnitOperation(unitOperation);
      }
    });
  }

  removeUnitOperation(unitOperation: SuncorUnitOperation, upgOwner: Upgrader, sheetType?: SpreadsheetTitle): void {
    this.sheetHandlers.forEach(sh => {
      if (sh.belongsToUnitOperation(upgOwner.id, sheetType)) {
        sh.removeUnitOperation(unitOperation);
      }
    });
  }

  removeAllUtilitiesSheetsByType(sheetType: SpreadsheetTitle) {
    const sheetNames = [];
    this.spreadsheet.sheets.forEach(sheet => {
      if (sheet.name.includes(` - ${sheetType}`)) {
        sheetNames.push(sheet.name);
      }
    });

    sheetNames.forEach(name => {
      this.spreadsheet.sheets.forEach((sheet, idx) => {
        if (sheet.name === name) {
          this.spreadsheet.sheets.removeAt(idx);
        }
      });
    });
  }

  removeUtilitiesSheetsByUpgOwner(upgOwner: Upgrader, allSheets: boolean) {
    this.spreadsheet.sheets.forEach((sheet, idx) => {
      if (allSheets) {
        if (sheet.name === `${upgOwner.name} - ${SpreadsheetTitle.HYDROGEN}`) {
          this.spreadsheet.sheets.removeAt(idx);
        }
      }
      if (sheet.name === `${upgOwner.name} - ${SpreadsheetTitle.STEAM_REPORT}`) {
        this.spreadsheet.sheets.removeAt(idx);
      }
    });
  }

  removeSheetHandlersByType(sheetType: string) {
    const sheetNames = [];
    this.sheetHandlers.forEach(sheetHandler => {
      if (sheetHandler.sheetName.includes(` - ${sheetType}`)) {
        sheetNames.push(sheetHandler.sheetName);
      }
    });

    sheetNames.forEach(name => {
      this.sheetHandlers.forEach((sheet, idx) => {
        if (sheet.sheetName === name) {
          this.sheetHandlers.splice(idx, 1);
        }
      });
    });
  }

  getSheetIndex() {
    let lastH2sSheetIdx = null;
    this.spreadsheet.sheets.forEach((sheet, idx) => {
      if (sheet.name.includes(SpreadsheetTitle.ACID_GAS_SULFUR)) {
        lastH2sSheetIdx = idx;
      }
    });
    return lastH2sSheetIdx;
  }
}
