import { FuelGasUnitOperation } from './fuel-gas-unit-operation';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';
import { BaseObject } from '../../base-object';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

export class FuelGasSource extends FuelGasUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.fuelGasSource.key;
  flowrate: SimulationVariable;
  fluidAnalysisId: string;
  isOptimizable: boolean;

  constructor(unitOperation: any | FuelGasSource, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    this.flowrate = new SimulationVariable(unitOperation.flowrate, this.ownerCase, this);
    this.fluidAnalysisId = unitOperation.fluidAnalysisId || '';

    if (typeof unitOperation.isOptimizable === 'undefined') {
      this.isOptimizable = false;
    } else {
      this.isOptimizable = !!unitOperation.isOptimizable;
    }
    this.setQuantities();
  }

  override clearResults() {
    super.clearResults();

    if (this.isOptimizable) {
      this.flowrate.value = null;
    }
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
    const fgs = another as FuelGasSource;
    this.isOptimizable = fgs.isOptimizable;
    this.fluidAnalysisId = fgs.fluidAnalysisId;
  }

  override setSimulationVariableNames() {
    this.flowrate.setName(SimulationVariableName.FLOWRATE);
  }

  override setQuantities() {
    this.flowrate.setQuantity(Quantity.MOLARFLOWRATE);
  }

  getAvailableKpis(): SimulationVariable[] {
    return [this.flowrate];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.flowrate];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(persistedObj: any) {
    super.dePersist(persistedObj);
    this.flowrate = this.getSimulationVariableSafe(persistedObj.flowrate);
    this.isOptimizable = persistedObj.isOptimizable;

    this.setQuantities();
    this.setDefaultValues();
  }
}
