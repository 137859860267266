import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SimulationVariable, UnitOperation } from '../../../_models';
import { SteamType } from '../../../_config/steam-type.enum';
import { CoreService } from '../../../_services/core.service';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { AbstractWaterInformationStreamPropertyWindow } from '../abstract-water-information-stream-property-window';
import { WaterSinkImport } from '../../../_models/_unit-operations/utilties/water-sink-import';
import { SteamUseUnitOperation } from '../../../_models/_unit-operations/steam-use-unit-operation';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';

@Component({
  selector: 'sob-water-sink-import',
  templateUrl: './water-sink-import.component.html',
  styleUrls: ['./water-sink-import.component.css'],
})
export class WaterSinkImportComponent extends AbstractWaterInformationStreamPropertyWindow implements OnInit {
  @Input() override unitOperation: WaterSinkImport;
  @Input() override formGroupWrapper: BaseObjectFormGroupWrapper;
  PressureCalculationMode = PressureCalculationMode;
  SteamType = SteamType;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(coreService: CoreService, fb: UntypedFormBuilder, svfb: SimVarFormBuilder) {
    super(coreService, fb, svfb);
  }

  ngOnInit(): void {
    this.activeInformationStreams.push(...this.unitOperation.inletSourceInformationStreams);
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
    this.filterStreams();
  }

  override addControls() {
    super.addControls();

    this.propertyWindowFormGroup.addControl(
      'massFlowrate',
      this.svfb.control(this.unitOperation.massFlowrate, true, false, true)
    );

    this.propertyWindowFormGroup.addControl(
      'pressure',
      this.svfb.control(this.unitOperation.pressure, true, false, true)
    );

    this.propertyWindowFormGroup.addControl(
      'temperature',
      this.svfb.control(this.unitOperation.temperature, true, false, true)
    );
  }

  protected getOtherInformationStreamUsers(): UnitOperation[] {
    return this.currentCase.filterUnitOperations(
      uo => uo.category === unitOperationsConfig.waterSinkImport.key && uo.id !== this.unitOperation.id
    );
  }

  protected usesInformationStream(informationStreamUser: UnitOperation, simulationVariableId: string): boolean {
    return !!(informationStreamUser as WaterSinkImport).inletSourceInformationStreams.find(
      i => i.simulationVariableId === simulationVariableId
    );
  }

  protected getAllowedAvailableSteamVariableByType(
    uo: SteamUseUnitOperation,
    steamType: SteamType
  ): SimulationVariable {
    return uo.getSteamUseVariableByType(steamType);
  }

  get informationStreamsFormArray() {
    return this.propertyWindowFormGroup.get('inletSourceInformationStreams') as UntypedFormArray;
  }

  override get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }
}
