import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MultiPeriodSettings } from '../../../_models/_multi-period/multi-period-settings';
import { Case, UnitOperation } from '../../../_models';
import { isMultiPeriodTank, MultiPeriodTank } from '../../../_models/_multi-period/multi-period-tank';
import { CustomValidators } from '../../../shared/custom-validators';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { SimulationVariableMapper } from '../../../_mappers/simulation-variable-mapper';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: 'sob-multi-period-inventory-settings',
  templateUrl: './multi-period-inventory-settings.component.html',
  styleUrls: ['./multi-period-inventory-settings.component.css'],
})
export class MultiPeriodInventorySettingsComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  isSubmitted: boolean;
  allTanks: MultiPeriodTank[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private svfb: SimVarFormBuilder,
    private simVarMapper: SimulationVariableMapper,
    private core: CoreService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.removeFormControls();
    this.allTanks = [];
    this.addFormControls();

    if (this.currentCase.multiPeriodSettings.evaluateInventory) {
      this.findAllTanks();
      this.addTanksFormControls();
    }
  }

  addFormControls() {
    this.formGroup.addControl(
      'numberOfSteps',
      this.fb.control(this.multiPeriodSettings.numberOfSteps, [
        CustomValidators.validNumber(true),
        CustomValidators.positive,
      ])
    );

    this.formGroup.addControl('evaluateInventory', this.fb.control(this.multiPeriodSettings.evaluateInventory));
  }

  removeFormControls() {
    this.formGroup.removeControl('numberOfSteps');
    this.formGroup.removeControl('evaluateInventory');
    this.removeTanksFormControls();
  }

  onEvaluateInventoryChange($event: Event) {
    const { checked } = $event.target as HTMLInputElement;

    if (checked) {
      this.onEvaluateInventoryChecked();
    } else {
      this.onEvaluateInventoryUnchecked();
    }
  }

  get numberOfStepsControl(): AbstractControl {
    return this.formGroup.get('numberOfSteps');
  }

  touchedOrDirty(control: AbstractControl): boolean {
    return control.touched || control.dirty;
  }

  private onEvaluateInventoryChecked() {
    this.findAllTanks();
    this.addTanksFormControls();
  }

  private onEvaluateInventoryUnchecked() {
    this.removeTanksFormControls();
    this.allTanks = [];
  }

  private findAllTanks() {
    this.allTanks = this.currentCase
      .filterUnitOperations((uo: UnitOperation) => {
        return isMultiPeriodTank(uo);
      })
      .map<MultiPeriodTank>(t => (isMultiPeriodTank(t) ? t : undefined));
  }

  private addTanksFormControls() {
    for (const tank of this.allTanks) {
      this.formGroup.addControl(
        tank.id,
        this.fb.group({
          minVolume: this.svfb.control(tank.minVolume, false, false, false, [
            CustomValidators.validNumber(true),
            CustomValidators.positive,
          ]),

          maxVolume: this.svfb.control(tank.maxVolume, false, false, false, [
            CustomValidators.validNumber(true),
            CustomValidators.positive,
          ]),

          initialVolume: this.svfb.control(tank.initialVolume, false, false, false, [
            CustomValidators.validNumber(true),
            CustomValidators.positive,
          ]),
        })
      );
    }
  }

  private removeTanksFormControls() {
    for (const tank of this.allTanks) {
      this.formGroup.removeControl(tank.id);
    }
  }

  getTankValueFormControl(tankId: string, simVarName: string) {
    return this.formGroup.get(tankId).get(simVarName).get('value');
  }

  shouldShowControlValidation(control: AbstractControl) {
    return control.invalid && (this.isSubmitted || this.touchedOrDirty(control));
  }

  map(): void {
    this.isSubmitted = true;

    if (this.formGroup.valid) {
      this.multiPeriodSettings.numberOfSteps = +this.formGroup.value.numberOfSteps;
      this.multiPeriodSettings.evaluateInventory = !!this.formGroup.value.evaluateInventory;
      this.mapTankValues();
    }
  }

  private mapTankValues(): void {
    for (const tank of this.allTanks) {
      let ev = this.formGroup.get(tank.id).get('minVolume').value;
      this.simVarMapper.map(ev, tank.minVolume);

      ev = this.formGroup.get(tank.id).get('maxVolume').value;
      this.simVarMapper.map(ev, tank.maxVolume);

      ev = this.formGroup.get(tank.id).get('initialVolume').value;
      this.simVarMapper.map(ev, tank.initialVolume);
    }
  }

  private get currentCase(): Case {
    return this.core.currentCase;
  }

  get multiPeriodSettings(): MultiPeriodSettings {
    return this.currentCase.multiPeriodSettings;
  }
}
