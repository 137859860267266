// not sure about the file name of this (tempted to name color.enum)
// const colors = {
//   BLUE: '#0000FF',
//   BROWN: '#A52A2A',
//   CADET_BLUE: '#5F9EA0',
//   CRIMSON: '#DC143C',
//   DARK_BLUE: '#00008B',
//   GREEN: '#008000',
//   INDIAN_RED: '#CD5C5C',
//   LIGHT_SLATE_GRAY: '#778899',
//   RED: '#FF0000',
//   SEA_GREEN: '#2E8B57',
//   SLATE_GRAY: '#708090',
//   YELLOW: '#FFFF00',
//   YELLOW_GREEN: '#9ACD32'
// };

const obsoleteColorPalette = [
  '#58A6A2',
  '#92D2CA',
  '#317B96',
  '#7BA4BD',
  '#616C9F',
  '#9299B3',
  '#C7936B',
  '#DBB983',
  '#E6A04D',
  '#F3CB92',
  '#A8A151',
  '#D3CB87',
  '#6A9177',
  '#83BC89',
  '#1332c2',
  '#244662',
  '#9B55A6',
  '#D07B53',
  '#5D7D93',
  '#8BA98A',
];

const colors = [
  '#31A6A0',
  '#52ABBB',
  '#317B96',
  '#3F87CE',
  '#616C9F',
  '#6F11CB',
  '#C77F48',
  '#B3584C',
  '#E57A0E',
  '#9E7132',
  '#A8A151',
  '#B7A717',
  '#38754C',
  '#319A3C',
  '#1332c2',
  '#244662',
  '#9B55A6',
  '#BF0863',
  '#4B6A80',
  '#7D997D',
  '#008C69',
  '#E34D1B',
  '#80234D',
  '#547252',
  '#755117',
];

export function getRandomColor() {
  return colors[Math.floor(Math.random() * colors.length)];
}

/**
 * Use this function for getting the color for a stream
 * if an obsolete color with low contrast is used, then a new valid random one will be returned.
 * If the color is user defined, then the same color will be returned
 * If the color string evaluates to boolean false, then a new valid random color will be returned
 * @param color
 */
export function getColor(color: string) {
  if (obsoleteColorPalette.includes(color)) {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  return color || colors[Math.floor(Math.random() * colors.length)];
}
