<ul class="nav nav-pills" role="tablist" id="pillContainer">
  <li role="presentation" class="active">
    <a
      href="#flowsheet-diagram-container"
      aria-controls="flowsheet-diagram-container"
      role="tab"
      data-toggle="tab"
      (click)="showControls()">
      Flowsheet
    </a>
  </li>

  <li role="presentation">
    <a
      href="#flowsheet-spreadsheet"
      aria-controls="flowsheet-spreadsheet"
      role="tab"
      data-toggle="tab"
      (click)="hideControls()">
      Spreadsheet
    </a>
  </li>

  <li *ngIf="isSolved" (click)="refreshSankey()" role="presentation">
    <a href="#sankey-container" aria-controls="sankey-container" role="tab" data-toggle="tab">Sankey diagram</a>
  </li>

  <li *ngIf="multiPeriodEnabled" role="presentation">
    <a
      href="#multi-period-settings-container"
      aria-controls="multi-period-settings-container"
      role="tab"
      data-toggle="tab"
      (click)="hideControls()"
      >Multiperiod Settings</a
    >
  </li>
</ul>
