import { WaterUnitOperation } from './water-unit-operation';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { Quantity } from '../../../_config/quantity.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

declare let unitConverter: any;

export class WaterPump extends WaterUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.waterPump.key;
  pressureCalculationMode: PressureCalculationMode | string;
  pressureGain: SimulationVariable;
  outletPressure: SimulationVariable;
  duty: SimulationVariable;
  efficiency: SimulationVariable;
  dutyResult: SimulationVariable;

  constructor(unitOperation: any | WaterPump, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.pressureCalculationMode = unitOperation.pressureCalculationMode || '';
    this.pressureGain = new SimulationVariable(unitOperation.pressureGain, this.ownerCase, this);
    this.outletPressure = new SimulationVariable(unitOperation.outletPressure, this.ownerCase, this);
    this.duty = new SimulationVariable(unitOperation.duty, this.ownerCase, this);
    this.efficiency = new SimulationVariable(unitOperation.efficiency, this.ownerCase, this);
    this.dutyResult = new SimulationVariable(unitOperation.dutyResult, this.ownerCase, this);
  }

  override clearResults() {
    super.clearResults();
    this.dutyResult.value = null;
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.pressureGain.setName(SimulationVariableName.PRESSURE_GAIN);
    this.outletPressure.setName(SimulationVariableName.OUTLET_PRESSURE);
    this.duty.setName(SimulationVariableName.DUTY);
    this.efficiency.setName(SimulationVariableName.EFFICIENCY);
    this.dutyResult.setName(SimulationVariableName.DUTY);
  }

  override setQuantities() {
    super.setQuantities();
    this.pressureGain.setQuantity(Quantity.PRESSURE);
    this.outletPressure.setQuantity(Quantity.PRESSURE);
    this.duty.setQuantity(Quantity.POWER);
    this.efficiency.setQuantity(Quantity.DIMENSIONLESS);
    this.dutyResult.setQuantity(Quantity.POWER);
  }

  override setDefaultValues() {
    super.setDefaultValues();
    if (this.pressureCalculationMode === '') {
      this.pressureCalculationMode = PressureCalculationMode.PRESSURE_GAIN;
    }
    if (this.efficiency.isUndefined()) {
      this.efficiency.setDefaultValue(75, unitConverter.units.Dimensionless.PERCENT);
    }
    if (this.duty.isUndefined()) {
      this.duty.setDefaultValue(null, unitConverter.units.Power.KW);
    }
    if (this.dutyResult.isUndefined()) {
      this.dutyResult.setDefaultValue(null, unitConverter.units.Power.KW);
    }
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [this.outletPressure, this.dutyResult];
    }
    return [this.outletPressure, this.efficiency, this.dutyResult];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.efficiency];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(unitOperation: any) {
    super.dePersist(unitOperation);
    this.pressureGain = this.getSimulationVariableSafe(unitOperation.pressureGain);
    this.outletPressure = this.getSimulationVariableSafe(unitOperation.outletPressure);
    this.duty = this.getSimulationVariableSafe(unitOperation.duty);
    this.efficiency = this.getSimulationVariableSafe(unitOperation.efficiency);
    this.dutyResult = this.getSimulationVariableSafe(unitOperation.dutyResult);
    this.setQuantities();
    this.setDefaultValues();
  }
}
