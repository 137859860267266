import { Case } from '../case';
import { SimulationVariable } from '../simulation-variable';
import { UnitOperation } from '../unit-operation';
import { KpiProvider } from '../_case-study/kpi-provider';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

export class GenericSink extends UnitOperation implements KpiProvider {
  category = unitOperationsConfig.genericSink.key;

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis(): SimulationVariable[] {
    return [this.getInletStream().volumetricFlowrate];
  }

  getInletStream() {
    return this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override initValues(unitOperation: any) {}

  override setSimulationVariableNames() {}

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  override setQuantities() {}

  override setDefaultValues() {}
  override clearResults() {}
}
