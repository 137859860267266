import { FlexSheetMapper } from './flex-sheet-mapper';
import { SimulationVariable } from '../../../_models';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';
import { Hydrotreater } from '../../../_models/_unit-operations/hydrotreater';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { UtilitiesSheetColumns } from '../cell-config/utilities-cell-config.enum';

export class UtilitiesFlexSheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: Hydrotreater | Hydrocracker, col: number, value: string | number) {
    if (col === UtilitiesSheetColumns.NAME && typeof value === 'string') {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (col) {
        case UtilitiesSheetColumns.HYDROGEN_USE:
          break;
        case UtilitiesSheetColumns.HYDROGEN_USE_RATE:
          this.updateHydrogenUseRateVariables(unitOperation, value);
          break;
      }
    }
  }

  private updateHydrogenUseRateVariables(unitOperation: Hydrotreater | Hydrocracker, value) {
    const simvar = new SimulationVariable({ value, quantity: 'GasStdVolumePerVolume', unit: 'scf/bbl' }, null, null);
    if (unitOperation instanceof Hydrotreater) {
      unitOperation.hydrogenUseRateVariables.forEach(rv => {
        rv.value = simvar.convertToInternalUnit();
      });
    } else {
      unitOperation.hydrogenUseRate.value = simvar.convertToInternalUnit();
    }
  }
}
