import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { BaseObjectFormGroupWrapper } from '../../_form-utils/base-object-form-group-wrapper';

@Component({
  selector: 'sob-property-window',
  templateUrl: './property-window.component.html',
  styleUrls: ['./property-window.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PropertyWindowComponent {
  @Input() unitOperation: any;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  unitOperationsConfig = unitOperationsConfig;

  constructor(private flowsheetService: FlowsheetService) {}

  onClosePropertyWindowClicked(): void {
    if ($('#closePropertyWindow').attr('disabled')) {
      return;
    }

    // would need feedback, if unit op is valid then save, if not... well do not save :v
    this.flowsheetService.prepareUnitOperationForSave();

    // the property window hiding/showing will be handled by the parent flowsheet component
    this.flowsheetService.closePropertyWindow();
  }
}
