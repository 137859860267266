import { AfterViewInit, Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { SubSink } from 'subsink';
import * as go from 'gojs';
import { PopupTrigger } from '@grapecity/wijmo.input';
import { WjColorPicker, WjPopup } from '@grapecity/wijmo.angular2.input';
import { interval, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { PopupPosition } from '@grapecity/wijmo';
import { DiagramItemColorPickerInput, DiagramItemColorPickerService } from './diagram-item-color-picker.service';

interface DiagramItemColorPickerComponentState {
  diagram: go.Diagram;
  diagramItemKey: number | string;
}

@Component({
  selector: 'sob-diagram-item-color-picker',
  templateUrl: './diagram-item-color-picker.component.html',
  styleUrls: ['./diagram-item-color-picker.component.css'],
})
export class DiagramItemColorPickerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('popup') popup: WjPopup;
  @ViewChild('colorPicker') colorPicker: WjColorPicker;

  private subSink = new SubSink();
  protected PopupTrigger = PopupTrigger;

  state?: DiagramItemColorPickerComponentState;
  private animationInProgress = false;

  // seems like the fadeIn, fadeOut animations last this number of ms
  private guessedAnimationTimeMs = 400;

  private colorChangedSubject = new Subject<string>();
  private colorChanged$ = this.colorChangedSubject.asObservable();

  // initial value, will be overridden on open
  protected selectedColor = 'rgba(255, 255, 255, 1)';

  constructor(private colorPickerService: DiagramItemColorPickerService, private ngZone: NgZone) {
    this.subSink.add(
      this.colorPickerService.open$.subscribe({
        next: params => {
          if (this.isAnimating() || this.popup.isVisible) {
            return;
          }
          this.open(params);
        },
      })
    );

    this.subSink.add(
      this.colorChanged$
        .pipe(
          debounce(() => {
            return interval(200);
          })
        )
        .subscribe({
          next: value => {
            // This fill change will actually be tracked by undoManager
            this.updateFill(value);
          },
        })
    );
  }

  ngAfterViewInit() {
    // at the moment of creating this component, it is not possible to set the position
    // as a wj-popup input
    this.popup.position = PopupPosition.Above;
  }

  private open(params: DiagramItemColorPickerInput) {
    this.moveContainer(params.documentX, params.documentY);
    this.state = { diagram: params.diagram, diagramItemKey: params.diagramItemKey };
    this.selectedColor = params.fill;

    // eternal damnation will fall on us for this ;_;
    // prevent a visual glitch the first time the popup is shown
    // give chance to move the container correctly
    setTimeout(() => {
      this.ngZone.run(() => {
        this.popup.show();
      });
    }, 1);
  }

  private moveContainer(x: number, y: number) {
    const container = document.getElementById('diagram-item-color-picker-popup-container');
    container.style.left = `${x}px`;
    container.style.top = `${y}px`;
  }

  protected onColorPickerInputChanged(colorString: string) {
    if (!this.state) {
      return;
    }

    // changing the color "in real time"
    // not tracked by the undo manager... just in case
    const { diagram } = this.state;
    const oldSkipsUndoManagerValue = diagram.skipsUndoManager;
    diagram.skipsUndoManager = true;
    this.updateFill(colorString);
    diagram.skipsUndoManager = oldSkipsUndoManagerValue;

    // This observable is debounced
    // the last emission of this will be actually tracked by the undoManager
    this.colorChangedSubject.next(colorString);
  }

  private updateFill(fill: string) {
    if (!this.state) {
      return;
    }

    const { diagram } = this.state;
    const commentsNode = diagram.findNodeForKey(this.state.diagramItemKey);

    if (!commentsNode) {
      return;
    }

    diagram.model.startTransaction('updateFill');
    diagram.model.setDataProperty(commentsNode.data, 'fill', fill);
    diagram.model.commitTransaction('updateFill');
  }

  // region animation guess flags
  onPopupShowing() {
    this.animationStarting();
  }

  onPopupShown() {
    // mark the animation as finished after some time...
    setTimeout(() => {
      this.ngZone.run(() => {
        this.animationFinished();
      });
    }, this.guessedAnimationTimeMs);
  }

  onPopupHiding() {
    this.animationStarting();
  }

  onPopupHidden() {
    // mark the animation as finished after some time...
    setTimeout(() => {
      this.ngZone.run(() => {
        this.state = undefined;
        this.animationFinished();
      });
    }, this.guessedAnimationTimeMs);
  }

  // flags that should be in sync with animation... :/ to debounce the hide/show of the popup
  protected animationStarting() {
    this.animationInProgress = true;
  }

  protected animationFinished() {
    this.animationInProgress = false;
  }

  private isAnimating() {
    return this.animationInProgress;
  }
  // endregion

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
