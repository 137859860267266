<form [formGroup]="table" style="padding-right: 15px">
  <table class="table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Min</th>
        <th>Max</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      <ng-container formArrayName="tableRows" *ngFor="let group of rows.controls; let i = index">
        <tr [formGroupName]="i">
          <td>{{ findDistributionRatioName(i) }}</td>
          <td>
            <input
              [readOnly]="blendRecipeOption === 'exact' || readonly"
              (change)="validateRange(i, 'minimumValue')"
              type="number"
              class="form-control"
              formControlName="minimumValue" />
          </td>
          <td>
            <input
              [readOnly]="blendRecipeOption === 'exact' || readonly"
              (change)="validateRange(i, 'maximumValue')"
              type="number"
              class="form-control"
              formControlName="maximumValue" />
          </td>
          <td>
            <input
              (change)="roundValues(i)"
              type="number"
              class="form-control"
              formControlName="value"
              [readOnly]="i === rows.controls.length - 1 || blendRecipeOption === 'range' || readonly" />
          </td>
        </tr>
        <tr
          *ngIf="
            rows.controls[i].get('value').invalid &&
            (rows.controls[i].get('value').dirty || rows.controls[i].get('value').touched)
          ">
          <td colspan="3">
            <span class="text-danger">
              <span *ngIf="rows.controls[i].get('value').errors?.required">Please enter a value</span>
              <span *ngIf="rows.controls[i].get('value').errors?.max">Distribution ratio cannot be greater than 1</span>
              <span *ngIf="rows.controls[i].get('value').errors?.min">Distribution ratio cannot be negative</span>
            </span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</form>
