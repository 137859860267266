import { Injectable } from '@angular/core';
import * as go from 'gojs';
import { DiagramItemColorPickerService } from '../../flowsheet/diagram-item-color-picker/diagram-item-color-picker.service';
import { isNullOrUndefined, isTypeString } from '../../_utils/utils';

@Injectable()
export class OtherDiagramObjectsTemplateProvider {
  constructor(private diagramItemColorPickerService: DiagramItemColorPickerService) {}

  $: any = go.GraphObject.make;

  // region other diagram objects
  // TODO this could be in a separate file
  getDiagramCommentsTemplate() {
    // the shape of the comments block
    go.Shape.defineFigureGenerator('File', (shape, w, h) => {
      const geo = new go.Geometry();
      const fig = new go.PathFigure(0, 0, true); // starting point
      geo.add(fig);
      fig.add(new go.PathSegment(go.PathSegment.Line, w - 10, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 10));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      const fig2 = new go.PathFigure(w - 10, 0, false);
      geo.add(fig2);
      // The Fold
      fig2.add(new go.PathSegment(go.PathSegment.Line, w - 10, 10));
      fig2.add(new go.PathSegment(go.PathSegment.Line, w, 10));
      geo.spot1 = new go.Spot(0, 0.25);
      geo.spot2 = go.Spot.BottomRight;
      return geo;
    });

    return this.$(
      go.Node,
      'Auto',
      {
        resizable: true,
        minSize: new go.Size(150, 80),
        selectionAdornmentTemplate: this.getCommentsSelectionAdornmentTemplate(),
      },
      this.$(
        go.Shape,
        'File',
        {
          name: 'SHAPE',
          strokeWidth: 1,
          spot1: new go.Spot(0, 0),
          spot2: new go.Spot(1, 1),
        },
        new go.Binding('fill', '', data => {
          if (!data.fill) {
            return 'rgb(254, 254, 184)';
          }

          return data.fill;
        }).makeTwoWay(),
        new go.Binding('opacity', '', data => {
          if (isNullOrUndefined(data.opacity)) {
            return 1;
          }

          return data.opacity;
        }).makeTwoWay()
      ),
      this.$(
        go.TextBlock,
        {
          margin: 10,
          maxSize: new go.Size(NaN, NaN),
          wrap: go.TextBlock.WrapFit,
          editable: true,
          stretch: go.GraphObject.Fill,
          minSize: new go.Size(130, 60),
          textEditor: this.getCommentsTextEditor(),
          textEdited: (textBlock: go.TextBlock) => {
            const { part } = textBlock;

            const actualBounds = { width: part.actualBounds.width, height: part.actualBounds.height };

            textBlock.part.height = NaN;
            textBlock.part.width = NaN;
            textBlock.part.ensureBounds();
            const autoCalculatedTextBounds = { width: part.actualBounds.width, height: part.actualBounds.height };

            part.width =
              actualBounds.width > autoCalculatedTextBounds.width ? actualBounds.width : autoCalculatedTextBounds.width;

            part.height =
              actualBounds.height > autoCalculatedTextBounds.height
                ? actualBounds.height
                : autoCalculatedTextBounds.height;
          },
          name: 'TEXT',
        },
        new go.Binding('text').makeTwoWay()
      ),
      new go.Binding('location', 'location', go.Point.parse).makeTwoWay(go.Point.stringify),
      new go.Binding('height', 'height').makeTwoWay(),
      new go.Binding('width', 'width').makeTwoWay()
    );
  }

  private getCommentsTextEditor() {
    const customEditor = new go.HTMLInfo();
    const customTextArea = document.createElement('textarea');

    customEditor.show = (textBlock, diagram, tool) => {
      if (!(textBlock instanceof go.TextBlock)) {
        return;
      }

      const textEditingTool = tool as go.TextEditingTool;

      customTextArea.addEventListener('keydown', e => {
        const { key } = e;
        if (key === 'Tab') {
          e.preventDefault();
          return false;
        }
        if (key === 'Escape') {
          // Cancel on Esc
          tool.doCancel();
          if (tool.diagram) {
            tool.diagram.focus();
          }
        }

        return true;
      });

      // taken from https://gojs.net/latest/extensions/TextEditor.js - very important fix!
      // without this, if user clicks outside the diagram, the current textBlock being edited
      // will remain in edit mode
      // Disallow blur.
      // If the textEditingTool blurs and the text is not valid,
      // we do not want focus taken off the element just because a user clicked elsewhere.
      customTextArea.addEventListener(
        'blur',
        () => {
          if (
            !textEditingTool ||
            textEditingTool.currentTextEditor === null ||
            textEditingTool.state === go.TextEditingTool.StateNone
          ) {
            return;
          }

          customTextArea.focus();

          if (textEditingTool.selectsTextOnActivate) {
            customTextArea.select();
            customTextArea.setSelectionRange(0, 9999);
          }
        },
        false
      );

      customTextArea.value = textBlock.text;
      customTextArea.style.resize = 'none';

      const { scale } = textBlock.diagram;
      const scaledWidth = Math.floor(scale * textBlock.measuredBounds.width);
      const scaledHeight = Math.floor(scale * textBlock.measuredBounds.height);
      customTextArea.style.width = `${scaledWidth + 1}px`;
      customTextArea.style.height = `${scaledHeight + 1}px`;

      const scaledFontSize = Math.floor(scale * 12);
      customTextArea.style.fontSize = `${scaledFontSize}px`;

      customTextArea.style.backgroundColor = 'white';

      const loc = textBlock.getDocumentPoint(go.Spot.TopLeft);
      const pos = diagram.transformDocToView(loc);
      customTextArea.style.left = `${pos.x - 1}px`;
      customTextArea.style.top = `${pos.y - 1}px`;
      customTextArea.style.position = 'absolute';
      customTextArea.style.zIndex = '100'; // place it in front of the Diagram

      if (diagram.div !== null) {
        diagram.div.appendChild(customTextArea);
      }

      customTextArea.focus();
    };

    customEditor.hide = diagram => {
      if (diagram.div.contains(customTextArea)) {
        diagram.div.removeChild(customTextArea);
      }
    };

    customEditor.valueFunction = () => {
      return customTextArea.value;
    };

    return customEditor;
  }

  private getCommentsSelectionAdornmentTemplate() {
    return this.$(
      go.Adornment,
      'Spot',
      this.$(
        go.Panel,
        'Auto',
        this.$(go.Shape, { stroke: 'dodgerblue', strokeWidth: 2, fill: null }),
        this.$(go.Placeholder)
      ),
      this.$(
        go.Panel,
        'Horizontal',
        { alignment: go.Spot.Top, alignmentFocus: go.Spot.Bottom },
        this.$(
          'Button',
          this.$(go.TextBlock, {
            text: '\uf1fb', // eye dropper
            font: '11pt FontAwesome',
          }),
          {
            click: (e: go.InputEvent, button: any) => {
              const clickedNode = button.part.adornedPart as go.Node;

              const shape = clickedNode.findObject('SHAPE') as go.Shape;

              if (!shape || !isTypeString(shape.fill)) {
                return;
              }

              this.diagramItemColorPickerService.open({
                diagram: clickedNode.diagram as go.Diagram,
                diagramItemKey: clickedNode.key,
                documentX: e.viewPoint.x,
                documentY: e.viewPoint.y,
                fill: shape.fill,
              });
            },
            _buttonFillOver: 'transparent',
          },
          this.$(go.Shape, { fill: null, stroke: null, desiredSize: new go.Size(14, 14), margin: 5 })
        )
      )
    );
  }
  // endregion
}
