import { Component, Input } from '@angular/core';
import {
  UtilitiesSummaryReportData,
  UtilitiesSummaryReportItem,
} from '../../../_models/_reports/utilities-summary-report-data';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { CoreService } from '../../../_services/core.service';
import { FuelGasUtilityUnitOperation } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-utility-unit-operation';
import { FuelGasSourceImport } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-source-import';
import { FuelGasSource } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-source';
import { FuelGasSinkImport } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-sink-import';
import { FuelGasFlare } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-flare';
import { FuelGasSourceInformationStream } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-source-information-stream';
import { GasContributorUnitOperation } from '../../../_models/_unit-operations/gas-contributor-unit-operation';
import { SimulationVariable, UnitOperation } from '../../../_models';
import { NumberToUnitConverter } from '../../../_services/number-to-unit-converter.service';
import { Quantity } from '../../../_config/quantity.enum';
import { WaterBoiler } from '../../../_models/_unit-operations/utilties/water-boiler';
import { WaterCogeneration } from '../../../_models/_unit-operations/utilties/water-cogeneration';

declare let unitConverter: any;

@Component({
  selector: 'sob-fuel-gas-summary-report',
  templateUrl: './fuel-gas-summary-report.component.html',
  styleUrls: ['./fuel-gas-summary-report.component.css'],
})
export class FuelGasSummaryReportComponent {
  @Input() upgraderName: string;
  fuelGasMakeReportData: UtilitiesSummaryReportData;
  fuelGasMakeUnit = unitConverter.units.Molarflowrate.MMSCFD;
  fuelGasUseReportData: UtilitiesSummaryReportData;
  fuelGasUseUnit = unitConverter.units.Energyrate.GJ_H;
  upgraderId = '';

  constructor(private coreService: CoreService, private nuc: NumberToUnitConverter) {}

  buildReport(id?: string) {
    let fuelGasUtilityUnitOperation;
    if (id) {
      fuelGasUtilityUnitOperation = this.getFuelGasUtilityUnitOperation(id);
      this.buildData(fuelGasUtilityUnitOperation ? fuelGasUtilityUnitOperation.id : '');
      this.upgraderId = id;
    } else {
      fuelGasUtilityUnitOperation = this.getFuelGasUtilityUnitOperation();
      this.buildData(fuelGasUtilityUnitOperation ? fuelGasUtilityUnitOperation.id : '');
    }
  }

  private getFuelGasUtilityUnitOperation(id?: string): FuelGasUtilityUnitOperation {
    let result = null;
    if (id) {
      result = this.coreService.currentCase.filterUnitOperations(
        uo => uo.category === unitOperationsConfig.fuelGasUtilityUnitOperation.key && uo.flowsheetId === id
      );
    } else {
      result = this.coreService.currentCase.filterUnitOperations(
        uo => uo.category === unitOperationsConfig.fuelGasUtilityUnitOperation.key
      );
    }

    // TODO what if it has more than 1 element?
    if (result.length) {
      return result[0] as FuelGasUtilityUnitOperation;
    }

    return undefined;
  }

  private buildData(ownerFlowsheetId: string) {
    this.fuelGasMakeReportData = this.buildFuelGasMakeReportData(ownerFlowsheetId);
    this.fuelGasUseReportData = this.buildFuelGasUseReportData(ownerFlowsheetId);
  }

  private buildFuelGasMakeReportData(ownerFlowsheetId: string): UtilitiesSummaryReportData {
    const fuelGasSourceUnits = this.getFuelGasSourceUnits(ownerFlowsheetId);
    const fuelGasMakeContributorsList = this.getFuelGasMakeContributors(ownerFlowsheetId);

    const allReportItems: UtilitiesSummaryReportItem[] = [];
    let total = 0;

    for (const fuelGasSource of fuelGasSourceUnits) {
      total += fuelGasSource.flowrate.value;
      allReportItems.push(this.flowrateToUtilitiesSummaryReportItem(fuelGasSource.flowrate, fuelGasSource.name));
    }

    for (const fuelGasMakeContributor of fuelGasMakeContributorsList) {
      total += fuelGasMakeContributor.flowrate.value;
      allReportItems.push(
        this.flowrateToUtilitiesSummaryReportItem(fuelGasMakeContributor.flowrate, fuelGasMakeContributor.name)
      );
    }

    total = this.nuc.convert(total, Quantity.MOLARFLOWRATE, this.fuelGasMakeUnit, true);

    return {
      type: 'fuelGasMake',
      reportItems: allReportItems,
      total,
      displayTitle: 'Fuel Gas Make',
    };
  }

  private buildFuelGasUseReportData(ownerFlowsheetId: string): UtilitiesSummaryReportData {
    const fuelGasFlareUnits = this.getFuelGasFlareUnits(ownerFlowsheetId);
    const fuelGasUseContributorsList = this.getFuelGasUseContributors(ownerFlowsheetId);

    const allReportItems: UtilitiesSummaryReportItem[] = [];
    let total = 0;

    for (const uo of fuelGasUseContributorsList) {
      let simVar;

      if (uo instanceof GasContributorUnitOperation) {
        simVar = uo.energyFlow;
      } else if (uo instanceof WaterBoiler) {
        simVar = uo.power;
      } else if (uo instanceof WaterCogeneration) {
        simVar = uo.totalEnergyDemand;
      }

      total += simVar.value;

      allReportItems.push(this.energyFlowToUtilitiesSummaryReportItem(simVar, uo.name));
    }

    total = this.nuc.convert(total, Quantity.ENERGYRATE, this.fuelGasUseUnit, true);

    return {
      type: 'fuelGasUse',
      reportItems: allReportItems,
      total,
      displayTitle: 'Fuel Gas Use',
    };
  }

  private flowrateToUtilitiesSummaryReportItem(flowrate: SimulationVariable, unitOperationName: string) {
    const flowrateUnit = unitConverter.units.Molarflowrate.MMSCFD;
    return {
      value: flowrate.convertToAnotherUnit(flowrateUnit),
      unitOperationName,
    } as UtilitiesSummaryReportItem;
  }

  private energyFlowToUtilitiesSummaryReportItem(energyFlow: SimulationVariable, unitOperationName: string) {
    const energyFlowUnit = unitConverter.units.Energyrate.GJ_H;

    return {
      value: energyFlow.convertToAnotherUnit(energyFlowUnit),
      unitOperationName,
    } as UtilitiesSummaryReportItem;
  }

  private getFuelGasMakeContributors(ownerFlowsheetId: string): GasContributorUnitOperation[] {
    const fuelGasSourceImportUnits = this.getFuelGasSourceImportUnits(ownerFlowsheetId);

    const informationStreams = fuelGasSourceImportUnits.map(fgs => fgs.inletSourceInformationStreams);

    // flat the array, from [][] to []
    const flatInformationStreams = [].concat.apply([], informationStreams) as FuelGasSourceInformationStream[];

    const gasContributorIds = flatInformationStreams.map(is => is.providerUnitOperationId);

    const gasContributors = [];

    for (const id of gasContributorIds) {
      gasContributors.push(this.coreService.currentCase.getUnitOperation(id));
    }

    return gasContributors;
  }

  private getFuelGasUseContributors(ownerFlowsheetId: string): UnitOperation[] {
    const fuelGasSinkImportUnits = this.getFuelGasSinkImportUnits(ownerFlowsheetId);

    const energyStreams = fuelGasSinkImportUnits.map(fgs => fgs.energyStreams);

    // flatten the array, from [][] to []
    const flatEnergyStreams = [].concat.apply([], energyStreams) as FuelGasSourceInformationStream[];

    const gasContributorIds = flatEnergyStreams.map(is => is.providerUnitOperationId);

    const gasContributors = [];

    for (const id of gasContributorIds) {
      gasContributors.push(this.coreService.currentCase.getUnitOperation(id));
    }

    return gasContributors;
  }

  private getFuelGasSourceImportUnits(ownerFlowsheetId: string): FuelGasSourceImport[] {
    return this.coreService.currentCase.filterUnitOperations(
      uo => uo instanceof FuelGasSourceImport && uo.flowsheetId === ownerFlowsheetId
    ) as FuelGasSourceImport[];
  }

  private getFuelGasSourceUnits(ownerFlowsheetId: string): FuelGasSource[] {
    return this.coreService.currentCase.filterUnitOperations(
      uo => uo instanceof FuelGasSource && uo.flowsheetId === ownerFlowsheetId
    ) as FuelGasSource[];
  }

  private getFuelGasSinkImportUnits(ownerFlowsheetId: string): FuelGasSinkImport[] {
    return this.coreService.currentCase.filterUnitOperations(
      uo => uo instanceof FuelGasSinkImport && uo.flowsheetId === ownerFlowsheetId
    ) as FuelGasSinkImport[];
  }

  private getFuelGasFlareUnits(ownerFlowsheetId: string): FuelGasFlare[] {
    return this.coreService.currentCase.filterUnitOperations(
      uo => uo instanceof FuelGasFlare && uo.flowsheetId === ownerFlowsheetId
    ) as FuelGasFlare[];
  }
}
