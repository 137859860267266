import { Case } from '../../_models';
import { SuncorFluidAnalysis } from '../../_models/_fluid/suncor-fluid-analysis';
import { Cut } from '../../_models/_fluid/cut';
import { GasFluidAnalysis } from '../../_models/_fluid/gas-fluid-analysis';

export class AssayManagerExcelReport {
  static generateDataArray(c: Case): any[] {
    const dataArray = [];
    const assays = c.assayManager.fluidAnalyses;
    this.addFirstColumn(dataArray);
    for (const assay of assays) {
      this.addAssay(dataArray, assay);
    }
    return dataArray;
  }

  private static addFirstColumn(dataArray: any[]) {
    dataArray.push([]);
    dataArray.push(['Assays']);
    dataArray.push([]);
    dataArray.push(['General']);
    dataArray.push(['Name']);
    dataArray.push(['Type']);
    dataArray.push([]);
    dataArray.push(['Composition (wt%)']);
    dataArray.push(['Light ends']);
    dataArray.push(['Light naphtha']);
    dataArray.push(['Heavy naphtha']);
    dataArray.push(['Distillate']);
    dataArray.push(['LGO']);
    dataArray.push(['HGO']);
    dataArray.push(['VGO']);
    dataArray.push(['HVGO']);
    dataArray.push(['Resid']);
    dataArray.push([]);
    dataArray.push(['Light ends']);
    dataArray.push(['Sulfur (wt%)']);
    dataArray.push(['Aniline point (C)']);
    dataArray.push(['Mass density (API)']);
    dataArray.push(['Nitrogen (wt%)']);
    dataArray.push(['CCR (wt%)']);
    dataArray.push([]);
    dataArray.push(['Light naphtha']);
    dataArray.push(['Sulfur (wt%)']);
    dataArray.push(['Aniline point (C)']);
    dataArray.push(['Mass density (API)']);
    dataArray.push(['Nitrogen (wt%)']);
    dataArray.push(['CCR (wt%)']);
    dataArray.push([]);
    dataArray.push(['Heavy naphtha']);
    dataArray.push(['Sulfur (wt%)']);
    dataArray.push(['Aniline point (C)']);
    dataArray.push(['Mass density (API)']);
    dataArray.push(['Nitrogen (wt%)']);
    dataArray.push(['CCR (wt%)']);
    dataArray.push([]);
    dataArray.push(['Distillate']);
    dataArray.push(['Sulfur (wt%)']);
    dataArray.push(['Aniline point (C)']);
    dataArray.push(['Mass density (API)']);
    dataArray.push(['Nitrogen (wt%)']);
    dataArray.push(['CCR (wt%)']);
    dataArray.push([]);
    dataArray.push(['LGO']);
    dataArray.push(['Sulfur (wt%)']);
    dataArray.push(['Aniline point (C)']);
    dataArray.push(['Mass density (API)']);
    dataArray.push(['Nitrogen (wt%)']);
    dataArray.push(['CCR (wt%)']);
    dataArray.push([]);
    dataArray.push(['HGO']);
    dataArray.push(['Sulfur (wt%)']);
    dataArray.push(['Aniline point (C)']);
    dataArray.push(['Mass density (API)']);
    dataArray.push(['Nitrogen (wt%)']);
    dataArray.push(['CCR (wt%)']);
    dataArray.push([]);
    dataArray.push(['VGO']);
    dataArray.push(['Sulfur (wt%)']);
    dataArray.push(['Aniline point (C)']);
    dataArray.push(['Mass density (API)']);
    dataArray.push(['Nitrogen (wt%)']);
    dataArray.push(['CCR (wt%)']);
    dataArray.push([]);
    dataArray.push(['HVGO']);
    dataArray.push(['Sulfur (wt%)']);
    dataArray.push(['Aniline point (C)']);
    dataArray.push(['Mass density (API)']);
    dataArray.push(['Nitrogen (wt%)']);
    dataArray.push(['CCR (wt%)']);
    dataArray.push([]);
    dataArray.push(['Resid']);
    dataArray.push(['Sulfur (wt%)']);
    dataArray.push(['Aniline point (C)']);
    dataArray.push(['Mass density (API)']);
    dataArray.push(['Nitrogen (wt%)']);
    dataArray.push(['CCR (wt%)']);

    return dataArray;
  }

  private static addAssay(dataArray: any[], assay: SuncorFluidAnalysis | GasFluidAnalysis) {
    let rowNum = 4;
    dataArray[rowNum++].push(assay.assayName);
    dataArray[rowNum++].push(assay.assayType);
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push('');

    for (let i = 0; i < assay.composition.length; i++) {
      dataArray[rowNum++].push(assay.composition[i].convertToDefaultUnit());
    }
    const cuts = [
      'lightEndsCut',
      'lightNaphthaCut',
      'heavyNaphthaCut',
      'distillateCut',
      'LGOCut',
      'HGOCut',
      'VGOCut',
      'HVGOCut',
      'residCut',
    ];
    cuts.forEach(cut => {
      const fCut = assay[cut] as Cut;
      dataArray[rowNum++].push('');
      dataArray[rowNum++].push('');
      dataArray[rowNum++].push(fCut.sulfur.isUndefined() ? '' : fCut.sulfur.convertToDefaultUnit());
      dataArray[rowNum++].push(fCut.anilinePoint.isUndefined() ? '' : fCut.anilinePoint.convertToDefaultUnit());
      dataArray[rowNum++].push(fCut.massDensity.isUndefined() ? '' : fCut.massDensity.convertToDefaultUnit());
      dataArray[rowNum++].push(fCut.nitrogen.isUndefined() ? '' : fCut.nitrogen.convertToDefaultUnit());
      dataArray[rowNum++].push(fCut.ccr.isUndefined() ? '' : fCut.ccr.convertToDefaultUnit());
    });
  }
}
