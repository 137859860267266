import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Upgrader } from '../../../_models/_unit-operations/upgrader';
import { ReportDataSet } from '../../../_models/_reports/report-data-set';
import { MixedGhgReportValue } from '../../../_models/_reports/report-value';
import { ReportService } from '../../../_services/report.service';
import { NumberToUnitConverter } from '../../../_services/number-to-unit-converter.service';
import { Quantity } from '../../../_config/quantity.enum';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { isEmptyValue } from '../../../_utils/utils';

declare let unitConverter: any;

@Component({
  selector: 'sob-ghg-upgrader-report',
  templateUrl: './ghg-upgrader-report.component.html',
  styleUrls: ['./ghg-upgrader-report.component.css'],
})
export class GhgUpgraderReportComponent {
  upgraderData: ReportDataSet;
  upgrader: Upgrader;
  readonly unit = unitConverter.units.Massflowrate.MMTPA;
  upgTotalGhgEmissions: number;

  constructor(private reportService: ReportService, private nuc: NumberToUnitConverter) {}

  buildReport(upgrader: Upgrader): void {
    this.upgraderData = this.reportService.buildUpgraderDataSet(upgrader.id);
    this.upgraderData.total = this.convertToDisplayUnit(this.upgraderData.total);

    if (this.upgraderData.data.length === 0) {
      this.upgTotalGhgEmissions = this.getUpgraderTotalGhgEmissions(upgrader);
    } else {
      this.buildPieChart();
      this.buildWaterfallChart();
      this.buildTable();
    }
  }

  getUpgraderTotalGhgEmissions(upgrader: Upgrader) {
    let upgTotalGhgEmissions;
    this.reportService.buildGhgEmissionsReport().contributionByUnit.forEach(r => {
      if (r.id === unitOperationsConfig.upgrader.key) {
        r.data.forEach(uO => {
          if (uO.name === upgrader.name) {
            upgTotalGhgEmissions = uO.value;
          }
        });
      }
    });

    return upgTotalGhgEmissions;
  }

  buildWaterfallChart() {
    const series = [
      {
        color: Highcharts.getOptions().colors[8],
        name: 'GHG Emissions',
        data: this.upgraderData.data
          .filter(cv => cv.value !== 0 && !isEmptyValue(cv.value))
          .sort((a, b) => (a.value > b.value ? -1 : 1))
          .map(cv => {
            return {
              name: cv.name,
              y: this.convertToDisplayUnit(cv.value),
            };
          }),
      },
    ];

    const totalBarSeries = {
      color: Highcharts.getOptions().colors[0],
      name: 'GHG Emissions',
      data: [
        {
          name: 'Total Emissions',
          y: this.upgraderData.total,
          color: Highcharts.getOptions().colors[0],
        },
      ],
    };

    series.push(totalBarSeries);

    Highcharts.chart('urGhgEmissionsWaterfallChart', this.getWaterfallChartOptions(series));
  }

  buildPieChart(): void {
    const series = [
      {
        name: 'GHG Emissions',
        data: this.upgraderData.data
          .filter(cv => !isEmptyValue(cv.value))
          .map(cv => {
            return {
              name: cv.name,
              y: this.convertToDisplayUnit(cv.value),
            };
          }),
      },
    ];

    const chartOptions = this.getPieChartOptions('GHG Emissions', series);

    Highcharts.chart('urGhgEmissionsChart', <any>chartOptions);
  }

  getPieChartOptions(title: string, series) {
    const { unit } = this;
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },

      credits: {
        enabled: false,
      },

      title: {
        text: title,
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: `{point.name}: {point.y:.2f} ${unit}`,
          },
        },
      },
      tooltip: {
        formatter() {
          return (
            `<span style="font-size:11px">${this.series.name}</span><br>` +
            `<span style="color:${this.color}">${this.point.name}</span>: <b>${this.y} ${unit}</b><br/>`
          );
        },
      },
      series,
    };
  }

  buildTable(): void {
    const tableData = [];
    for (const cv of this.upgraderData.data) {
      const reportValue = cv as MixedGhgReportValue;
      tableData.push({
        name: reportValue.name,
        category: reportValue.unitOperationCategory,
        value: this.convertToDisplayUnit(reportValue.value),
      });
    }

    const $table = $('#urGhgEmissionsTable');

    this.destroyDataTable();

    const opts = {
      data: tableData,
      paging: false,
      searching: false,
      info: false,
      order: [[2, 'desc']],
      columns: [
        {
          data: 'name',
        },
        {
          data: 'category',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              return `${unitConverter.formatNumber(data.value)} ${this.unit}`;
            }
            return data.value;
          },
        },
      ],
    };

    (<any>$table).DataTable(<any>opts);
  }

  destroyDataTable(): void {
    const $table = $('#urGhgEmissionsTable');

    if ((<any>$.fn).dataTable.isDataTable('#urGhgEmissionsTable')) {
      (<any>$table).DataTable().destroy();
    }
  }

  getWaterfallChartOptions(series: any) {
    const { unit } = this;
    return {
      chart: {
        type: 'waterfall',
      },
      title: {
        text: 'GHG Emissions',
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: unit,
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        waterfall: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000',
          },
          pointPadding: 0,
          borderWidth: 0,
        },
      },
      tooltip: {
        formatter() {
          return `<span style="font-size:11px">${this.series.name}</span><br>
            <span style="color: ${this.color}">${this.point.name}</span>: <b>${this.y} ${unit}</b><br/>`;
        },
      },

      series,
    };
  }

  private convertToDisplayUnit(value: number) {
    return this.nuc.convert(value, Quantity.MASSFLOWRATE, this.unit, true);
  }
}
