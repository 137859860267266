// should the directory be named 'migrations'?
export class V102 {
  static moveFluidAnalysisVarsToPool(serializedCase: any) {
    // find the unit operations with the fluidAnalysis key
    // TODO use correct types...
    const unitsWithFluidAnalysis = Object.values(serializedCase.unitOperationPool).filter(uo =>
      uo.hasOwnProperty('fluidAnalysis')
    ) as any[];

    for (const uo of unitsWithFluidAnalysis) {
      const oldComposition = uo.fluidAnalysis.composition;
      uo.fluidAnalysis.composition = [];

      for (let i = 0; i < oldComposition.length; i++) {
        uo.fluidAnalysis.composition.push(oldComposition[i].id);
        serializedCase.simulationVariablePool[oldComposition[i].id] = oldComposition[i];
      }
    }
  }
}
