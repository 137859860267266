import { WaterSink } from '../../../_unit-operations/utilties/water-sink';
import { SimulationVariable } from '../../../simulation-variable';
import { SimulationVariableName } from '../../../../_config/simulation-variable-name.enum';
import { ConstraintReportVariable } from '../constraint-report-variable';

export class WaterSinkConstraintReportVariableResolver {
  static create() {
    return new WaterSinkConstraintReportVariableResolver();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  addCurrentValueAndUnitToConstraintReportVariable(
    crv: ConstraintReportVariable,
    params: { uo: WaterSink; simVar: SimulationVariable }
  ): void {
    const { uo, simVar } = params;

    if (simVar.name === SimulationVariableName.MAXIMUM_MASS_FLOWRATE) {
      crv.currentValue = uo.maximumMassFlowrate.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.maximumMassFlowrate.getDefaultUnit();
      return;
    }

    if (simVar.name === SimulationVariableName.MINIMUM_MASS_FLOWRATE) {
      crv.currentValue = uo.minimumMassFlowrate.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.minimumMassFlowrate.getDefaultUnit();
      return;
    }

    throw new Error(`Unknown constraint: ${simVar.name}`);
  }
}
