import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'sob-reactive-constraint-check-box',
  template: ` <div *ngIf="isConstraint">
    <div style="position: relative">
      <label class="control-label">
        <input
          type="checkbox"
          id="constraint"
          [disabled]="disabledConstraint"
          style="position: absolute; top: 6px; right: 0"
          [checked]="isActive"
          (change)="isConstraintChanged($event.target.checked)" />
      </label>
    </div>
  </div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveConstraintCheckBoxComponent),
      multi: true,
    },
  ],
})
export class ReactiveConstraintCheckBoxComponent implements ControlValueAccessor {
  @Input() valueMetaData: any;

  @Input() isConstraint: boolean;
  @Input() disabledConstraint: boolean;

  isActive?: boolean;

  onChange = (_: any) => {};
  onTouch = () => {};

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  isConstraintChanged(checked: boolean) {
    this.isActive = checked;
    this.onChange(this.isActive);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.isActive = value;
  }
}
