<div class="modal fade" id="streamWaterModal" tabindex="-1" role="dialog" aria-labelledby="streamModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Stream Information</h4>
      </div>

      <div class="modal-body" *ngIf="stream">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#streamGeneralTab" role="tab" data-toggle="tab" aria-controls="streamGeneralTab"> Model </a>
          </li>
        </ul>

        <div class="tab-content pw-margin tab-content-padding">
          <div role="tabpanel" class="tab-pane fade in active" id="streamGeneralTab">
            <div class="form-horizontal">
              <div class="form-group" [formGroup]="streamGroup">
                <label for="streamName" class="control-label col-sm-4"> Name </label>

                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-6">
                      <input type="text" class="form-control" id="streamName" formControlName="name" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <span
                        class="text-danger"
                        *ngIf="nameControl.invalid && (nameControl.dirty || nameControl.touched)">
                        <span *ngIf="nameControl.errors.required">Please enter a name for the stream</span>
                        <span *ngIf="nameControl.errors.unique">Entered name is already in use</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="" class="pw-label control-label col-sm-4">From</label>
                <span style="padding-top: 7px; display: inline-block" class="col-sm-4">
                  {{ stream.inletUnitOperation.name }}
                </span>
              </div>

              <div class="form-group">
                <label for="" class="pw-label control-label col-sm-4">To</label>
                <span style="padding-top: 7px; display: inline-block" class="col-sm-4">
                  {{ stream.outletUnitOperation.name }}
                </span>
              </div>

              <br />
              <div class="form-group">
                <label [attr.for]="'stream-massFlowrate'" class="pw-label control-label col-sm-4">
                  Mass Flowrate
                </label>
                <sim-var-input
                  [inputId]="'stream-massFlowrate'"
                  [simVar]="stream.massFlowrate"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'temperature'" class="pw-label control-label col-sm-4"> Temperature </label>
                <sim-var-input
                  [inputId]="'temperature'"
                  [readOnly]="true"
                  [simVar]="stream.temperature"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-pressure'" class="pw-label control-label col-sm-4"> Pressure </label>
                <sim-var-input
                  [inputId]="'stream-pressure'"
                  [simVar]="stream.pressure"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
