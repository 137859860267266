import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GasContributorUnitOperation } from '../../../_models/_unit-operations/gas-contributor-unit-operation';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';
import { CoreService } from '../../../_services/core.service';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { GasContributorCodeProvider } from '../../../_services/custom-code-providers/gas-contributor-code-provider';

@Component({
  selector: 'sob-reactive-gas-contributor',
  templateUrl: './reactive-gas-contributor.component.html',
  styleUrls: ['./reactive-gas-contributor.component.css'],
})
export class ReactiveGasContributorComponent implements OnInit {
  @Input() unitOperation: GasContributorUnitOperation;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  defaultCodeProvider: GasContributorCodeProvider;
  isOpened = false;
  currentAnalysis: GasFluidAnalysis;

  constructor(
    private fb: UntypedFormBuilder,
    private svfb: SimVarFormBuilder,
    private coreService: CoreService,
    private flowsheetService: FlowsheetService
  ) {
    this.defaultCodeProvider = new GasContributorCodeProvider();
  }

  ngOnInit(): void {
    this.addControls();
    this.updateCurrentAnalysis();
    this.formGroupWrapper.storeOriginalValue();
  }

  private addControls() {
    this.formGroup.addControl(
      'fuelGasEmissionFactor',
      this.svfb.control(this.unitOperation.fuelGasEmissionFactor, false)
    );
    this.formGroup.addControl('fuelGasHeatingValue', this.svfb.control(this.unitOperation.fuelGasHeatingValue, false));
    this.formGroup.addControl('energyFlow', this.svfb.control(this.unitOperation.energyFlow, true));
    this.formGroup.addControl('flowrate', this.svfb.control(this.unitOperation.flowrate, true));
    this.formGroup.addControl('useCustomGasModel', this.fb.control(this.unitOperation.useCustomGasModel));
    this.formGroup.addControl('gasModelScript', this.fb.control(this.unitOperation.gasModelScript));
    this.formGroup.addControl('gasAnalysisId', this.fb.control(this.unitOperation.gasAnalysisId));
  }

  saveGasCustomModel(res: any) {
    if (res.instanceId === 'gasCodeEditor') {
      this.formGroup.get('gasModelScript').setValue(res.code);
      this.unitOperation.gasModelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }

  switchOpened() {
    $('#gasDiv').collapse('toggle');
    this.isOpened = !this.isOpened;
  }

  get formGroup(): UntypedFormGroup {
    return this.formGroupWrapper.getFormGroup();
  }

  get useCustomGasModel(): boolean {
    return !!this.formGroup.get('useCustomGasModel').value;
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Gas');
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.gasAnalysisId = id;
    }
    if (this.unitOperation.gasAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.gasAnalysisId
      );
      this.formGroup.get('gasAnalysisId').setValue(this.unitOperation.gasAnalysisId);
    }
  }
}
