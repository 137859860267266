import { FuelGasMaterialStream } from '../_streams/fuel-gas-material-stream';

declare let unitConverter: any;

export class SankeyFuelGasMaterialStream {
  id: string;
  from: number;
  to: number;
  name: string;
  flowrate: number;
  flowrateUnit: string;
  width: number;

  constructor(s: FuelGasMaterialStream) {
    this.id = s.id;
    this.from = s.inletUnitOperation.key;
    this.to = s.outletUnitOperation.key;
    this.name = s.name || s.connectionName;
    this.flowrateUnit = unitConverter.units.Molarflowrate.MMSCFD;
    this.flowrate = s.flowrate.convertToAnotherUnit(this.flowrateUnit, false);
    this.width = this.flowrate > 120 ? 120 : this.flowrate;
  }
}
