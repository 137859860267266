import { AfterViewInit, Component } from '@angular/core';
import { detectBody, smoothlyMenu } from '../app.helpers';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'sob-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css'],
})
export class ChangelogComponent implements AfterViewInit {
  constructor(private _accessService: AuthService) {}

  ngAfterViewInit() {
    detectBody();
  }

  toggleNavigation(): void {
    $('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  logout() {
    this._accessService.logout();
  }
}
