import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ParametricStudy } from '../../../_models/_case-study/parametric-study';
import { CaseStudyManager } from '../../../_models/_case-study/case-study-manager';
import { BaseObject } from '../../../_models/base-object';
import { ParameterProvider } from '../../../_models/_case-study/parameter-provider';
import { SimulationVariable, UnitOperation } from '../../../_models';
import { BaseStream } from '../../../_models/_streams/base-stream';
import { WaterPipeSegment } from '../../../_models/_unit-operations/utilties/water-pipe-segment';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: 'sob-parametric-study',
  templateUrl: './parametric-study.component.html',
  styleUrls: ['./parametric-study.component.css'],
})
export class ParametricStudyComponent {
  @Input()
  parametricStudy: ParametricStudy;

  @Input()
  caseStudyManager: CaseStudyManager;

  @Input()
  currentlyFlowsheetOwnerId: string;

  paramProvidersForCategory: (BaseObject & ParameterProvider)[];
  availableParamId: string;
  availableParams: SimulationVariable[];
  constructor(private ref: ChangeDetectorRef, private coreService: CoreService) {}

  setParameter(): void {
    if (
      this.availableParamId &&
      (!this.parametricStudy.parameter ||
        this.parametricStudy.parameter.simulationVariable.id !== this.availableParamId)
    ) {
      this.parametricStudy.removeParameter();
      this.ref.detectChanges();

      this.parametricStudy.setParameterBySimulationVariableId(this.availableParamId);
      this.parametricStudy.parameter.addState();
      this.parametricStudy.clearResults();
      this.ref.detectChanges();
    }
  }

  categoryChanged(event: any) {
    this.paramProvidersForCategory = this.caseStudyManager
      .getParameterProvidersByCategoryName(event.target.value)
      .filter(parameter => {
        if (parameter instanceof UnitOperation) {
          return parameter.flowsheetId === this.currentlyFlowsheetOwnerId;
        }
        if (parameter instanceof BaseStream) {
          const inletUnitOperation = this.coreService.currentCase.getUnitOperation(parameter.inletUnitOperationId);
          return inletUnitOperation.flowsheetId === this.currentlyFlowsheetOwnerId;
        }
        if (parameter instanceof WaterPipeSegment) {
          return parameter.getWaterPipeSegmentOwner(parameter.id).flowsheetId === this.currentlyFlowsheetOwnerId;
        }
        return parameter;
      });

    this.availableParamId = '';
    this.availableParams = [];
  }

  parameterProviderChanged(event: any) {
    const selectedBaseObject = <any>this.paramProvidersForCategory.find(pp => pp.id === event.target.value);
    this.availableParams = (<ParameterProvider>selectedBaseObject).getAvailableParametricStudyParameters();
    this.availableParamId = '';
  }

  cleanInputs(): void {
    $('.parametricStudyControls').find('select').val('');
    this.caseStudyManager.parametricStudyCategories = [];
    this.availableParamId = '';
    this.paramProvidersForCategory = [];
    this.availableParams = [];
  }
}
