import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { WaterUnitOperation } from './water-unit-operation';
import { TemperatureCalculationMode } from '../../../_config/temperature-calculation-mode.enum';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

declare let unitConverter: any;

export class WaterCooler extends WaterUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.waterCooler.key;

  temperatureCalculationMode: TemperatureCalculationMode | string;
  temperatureDrop: SimulationVariable;
  outletTemperature: SimulationVariable;
  pressureDrop: SimulationVariable;
  duty: SimulationVariable;

  constructor(unitOperation: any | WaterCooler, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.temperatureCalculationMode = unitOperation.temperatureCalculationMode || '';
    this.temperatureDrop = new SimulationVariable(unitOperation.temperatureDrop, this.ownerCase, this);
    this.outletTemperature = new SimulationVariable(unitOperation.outletTemperature, this.ownerCase, this);
    this.pressureDrop = new SimulationVariable(unitOperation.pressureDrop, this.ownerCase, this);

    this.duty = new SimulationVariable(unitOperation.duty, this.ownerCase, this);
  }

  override clearResults() {
    super.clearResults();
    this.duty.value = null;
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.temperatureDrop.setName(SimulationVariableName.TEMPERATURE_DROP);
    this.outletTemperature.setName(SimulationVariableName.OUTLET_TEMPERATURE);
    this.pressureDrop.setName(SimulationVariableName.PRESSURE_DROP);
    this.duty.setName(SimulationVariableName.DUTY);
  }

  override setQuantities() {
    super.setQuantities();
    this.temperatureDrop.setQuantity(Quantity.DELTA_TEMPERATURE);
    this.outletTemperature.setQuantity(Quantity.TEMPERATURE);
    this.pressureDrop.setQuantity(Quantity.DELTA_PRESSURE);
    this.duty.setQuantity(Quantity.POWER);
  }

  override setDefaultValues() {
    super.setDefaultValues();

    if (this.temperatureCalculationMode === '') {
      this.temperatureCalculationMode = TemperatureCalculationMode.TEMPERATURE_DROP;
    }

    if (this.duty.isUndefined()) {
      this.duty.setDefaultValue(null, unitConverter.units.Power.KW);
    }
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [this.temperatureDrop, this.outletTemperature, this.duty];
    }
    return [this.temperatureDrop, this.outletTemperature, this.pressureDrop, this.duty];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.pressureDrop];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(unitOperation: any) {
    super.dePersist(unitOperation);
    this.temperatureDrop = this.getSimulationVariableSafe(unitOperation.temperatureDrop);
    this.outletTemperature = this.getSimulationVariableSafe(unitOperation.outletTemperature);
    this.pressureDrop = this.getSimulationVariableSafe(unitOperation.pressureDrop);
    this.duty = this.getSimulationVariableSafe(unitOperation.duty);
    this.setQuantities();
    this.setDefaultValues();
  }
}
