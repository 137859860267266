import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { WaterSplitter } from '../../../_models/_unit-operations/utilties/water-splitter';
import { AbstractFormGroupPropertyWindow } from '../abstract-form-group-property-window';
import { hasNumericValue, roundNumber } from '../../../_utils/utils';
import { DistributionRatioVariable } from '../../../_models/_unit-operations/distribution-ratio-variable';
import { CoreService } from '../../../_services/core.service';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';

declare let unitConverter: any;

@Component({
  selector: 'sob-water-splitter',
  templateUrl: './water-splitter.component.html',
  styleUrls: ['./water-splitter.component.css'],
})
export class WaterSplitterComponent extends AbstractFormGroupPropertyWindow implements OnInit, OnDestroy {
  @Input() override formGroupWrapper: BaseObjectFormGroupWrapper;
  @Input() unitOperation: WaterSplitter;

  private subSink = new SubSink();

  constructor(private fb: UntypedFormBuilder, private coreService: CoreService) {
    super();

    this.subSink.add(
      this.coreService.unitOperationRemovedRequest.subscribe(uo => {
        this.removeDistributionRatioVariable(uo.id);
      })
    );

    this.subSink.add(
      this.coreService.streamRemovedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.removeDistributionRatioVariable(s.outletUnitOperationId);
        }
      })
    );

    this.subSink.add(
      this.coreService.streamAddedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.addDistributionRatioVariable(s.outletUnitOperationId);
        }
      })
    );
  }

  ngOnInit(): void {
    this.init();

    this.distributionRatioVariablesFormArray.valueChanges.forEach(() => {
      this.calculateLastDistributionVariable();
    });

    // eslint-disable-next-line guard-for-in
    for (const i in this.distributionRatioVariablesFormArray.controls) {
      this.roundDistributionVariable(+i);
    }
  }

  // region form building
  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('isOptimizable', this.fb.control(this.unitOperation.isOptimizable));
    this.propertyWindowFormGroup.addControl(
      'distributionRatioVariables',
      this.fb.array(this.createDistributionRatioVariablesControls())
    );
    this.isOptimizableChanged(this.unitOperation.isOptimizable);
  }

  private createDistributionRatioVariablesControls(): UntypedFormGroup[] {
    return this.unitOperation.distributionRatioVariables.map(dv => {
      return this.createSingleDistributionRatioVariableControl(dv);
    });
  }

  protected createSingleDistributionRatioVariableControl(dv: DistributionRatioVariable) {
    const roundedValue = hasNumericValue(dv.value) ? roundNumber(+dv.value, 3) : undefined;

    const valueControl = this.fb.control(roundedValue, [Validators.required, Validators.max(1), Validators.min(0)]);

    return this.fb.group({
      value: valueControl,
    });
  }
  // endregion

  // region adding/removing distribution ratio variables when property window is open
  private addDistributionRatioVariable(unitOperationId: string) {
    this.unitOperation.addDistributionVariable(unitOperationId);

    const dv = this.unitOperation.distributionRatioVariables[this.unitOperation.distributionRatioVariables.length - 1];
    const dvControl = this.createSingleDistributionRatioVariableControl(dv);
    this.distributionRatioVariablesFormArray.push(dvControl);
  }

  /**
   * Removes a distribution ratio variable
   * Makes sense when a stream is deleted
   * @param unitOperationId
   * @private
   */
  private removeDistributionRatioVariable(unitOperationId: string) {
    const index = this.unitOperation.findDistributionRatioVariableIndex(unitOperationId);
    if (index === -1) {
      return;
    }

    this.distributionRatioVariablesFormArray.removeAt(index);
    this.unitOperation.removeDistributionRatioVariable(unitOperationId);
  }
  // endregion

  // region form getters
  get distributionRatioVariablesFormArray() {
    return this.propertyWindowFormGroup.get('distributionRatioVariables') as UntypedFormArray;
  }

  getDistributionRatioVariableValueControl(i: number): AbstractControl {
    return this.distributionRatioVariablesFormArray.get(`${i}`).get('value');
  }

  // endregion

  // region event listeners
  isOptimizableChanged(checked: boolean) {
    if (checked) {
      this.distributionRatioVariablesFormArray.disable();
    } else {
      this.distributionRatioVariablesFormArray.enable();
    }
  }
  // endregion

  findDistributionRatioName(index: number): string {
    if (this.unitOperation) {
      return this.unitOperation.findDistributionRatioVariableName(index);
    }
    return '';
  }

  calculateLastDistributionVariable() {
    const { controls } = this.distributionRatioVariablesFormArray;
    if (controls.length <= 1) {
      return;
    }

    let total = 0;

    for (let i = 0; i < controls.length - 1; i++) {
      const dv = controls[i];
      const dvParsed = unitConverter.parseFloatString(dv.get('value').value);

      if (!Number.isNaN(dvParsed)) {
        total += dvParsed;
      } else {
        return;
      }
    }

    controls[controls.length - 1].get('value').setValue(parseFloat((1 - total).toFixed(5)), {
      emitEvent: false,
      emitModelToViewChange: true,
      emitViewToModelChange: true,
    });
    controls[controls.length - 1].get('value').markAsDirty();
  }

  roundDistributionVariable(i: number) {
    const dv = this.distributionRatioVariablesFormArray.controls[i];
    const dvParsed = unitConverter.parseFloatString(dv.get('value').value);

    if (!Number.isNaN(dvParsed)) {
      dv.get('value').setValue(parseFloat(dvParsed.toFixed(3)));
    }
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
