import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';
import { DefaultMappedType } from '../_models/default-mapped-type';
import { WaterHeader } from '../_models/_unit-operations/utilties/water-header';
import { DistributionRatioVariable } from '../_models/_unit-operations/distribution-ratio-variable';

export class WaterHeaderFormChangeDetector extends DefaultBaseObjectFormChangeDetector {
  override detectChangesInValues(
    originalFormValue: DefaultMappedType<WaterHeader>,
    currentValue: DefaultMappedType<WaterHeader>
  ): boolean {
    let changes = super.detectChangesInValues(originalFormValue, currentValue);

    if (originalFormValue.distributionRatioVariables.length !== currentValue.distributionRatioVariables.length) {
      return true;
    }

    for (let i = 0; i < currentValue.distributionRatioVariables.length; i++) {
      const currentRatioVariable = currentValue.distributionRatioVariables[i] as DistributionRatioVariable;
      const originalRatioVariable = originalFormValue.distributionRatioVariables[i] as DistributionRatioVariable;

      changes = changes || currentRatioVariable.value !== originalRatioVariable.value;
    }

    return changes;
  }

  override ignoreForDefaultChangeDetection(k: string): boolean {
    return super.ignoreForDefaultChangeDetection(k) || (k as keyof WaterHeader) === 'distributionRatioVariables';
  }
}
