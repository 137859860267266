<div>
  <div class="form-horizontal" [formGroup]="commentsGroup">
    <div class="form-group">
      <div class="col-sm-8">
        <textarea rows="5" class="area" placeholder="Type here" cols="60" formControlName="commentArea"></textarea>
      </div>
    </div>
    <div class="text-right" style="margin-top: 20px; margin-bottom: 20px; margin-left: 70%">
      <a class="btn btn-success add" (click)="addComment()"><i class="fa fa-plus"></i> Add comment</a>
    </div>
    <ng-container formArrayName="commentsArray">
      <div *ngFor="let comment of commentsFormGroupArray; let i = index">
        <div formGroupName="{{ i }}">
          <div class="row">
            <div class="col-lg head">
              <label id="comment{{ i }}" class="inline left p-t-5-x" style="margin-left: 20px">
                {{ unitOpComments[i].username }}
              </label>

              <small class="text-muted"> &nbsp;On {{ formatDate(unitOpComments[i].commentDate) }}</small>

              <div *ngIf="isControlDisabled(i, 'confirm') && isSameUser(unitOpComments[i].username)" class="buttons">
                <span class="inline pull-right p-t-5-x">
                  <button class="btnR btn btn-xs buttonC" (click)="confirmDelete(i)" title="Delete comment">
                    <i class="fa fa-trash fa-lg" aria-hidden="true"></i>
                  </button>
                </span>
                <span *ngIf="isControlDisabled(i, 'text')" class="inline pull-right p-t-5-x" style="margin-right: 10px">
                  <button
                    class="btnR btn btn-xs buttonC"
                    (click)="enableTextArea(i)"
                    data-toggle="tooltip"
                    title="Edit comment">
                    <i class="fa fa-pencil fa-lg" aria-hidden="true"></i>
                  </button>
                </span>
                <span
                  *ngIf="!isControlDisabled(i, 'text')"
                  class="inline pull-right p-t-5-x"
                  style="margin-right: 10px">
                  <button
                    class="btnR btn btn-xs buttonC"
                    (click)="updateComment(i)"
                    data-toggle="tooltip"
                    title="Save comment">
                    <i
                      class="fa fa-floppy-o fa-lg"
                      [ngClass]="{ 'text-success': !isControlDisabled(i, 'text') }"
                      aria-hidden="true"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="alert alert-danger row" role="alert" *ngIf="!isControlDisabled(i, 'confirm')">
            This comment will be permanently deleted&nbsp;&nbsp;
            <a href="#" (click)="deleteComment(i)" class="alert-link">Accept&nbsp;</a>or<a
              href="#"
              (click)="cancelDelete(i)"
              class="alert-link"
              >&nbsp;Cancel</a
            >
          </div>
          <div class="row divText">
            <textarea
              *ngIf="!isControlDisabled(i, 'text')"
              [ngClass]="{ edit: !isControlDisabled(i, 'text') }"
              rows="5"
              placeholder="Type here"
              cols="60"
              formControlName="text"
              >{{ unitOpComments[i].text }}</textarea
            >
            <p class="comment" *ngIf="isControlDisabled(i, 'text')">
              {{ unitOpComments[i].text }}
            </p>
          </div>
        </div>
        <br />
      </div>
    </ng-container>
  </div>
</div>
