import { BaseObject } from '../base-object';
import { Case } from '../case';

export class Flowsheet extends BaseObject {
  category = 'flowsheet';
  private diagramJson: string;
  unitOperationId: string;
  constructor(flowsheet: any, ownerCase: Case) {
    super(flowsheet.id, ownerCase);
  }

  isSubflowsheet(): boolean {
    return !!this.unitOperationId;
  }

  getDiagramJson() {
    return this.diagramJson;
  }

  setDiagramJson(diagramJson: string) {
    this.diagramJson = diagramJson;
  }

  override dePersist(persistedObj: any) {
    this.unitOperationId = persistedObj.unitOperationId;
  }

  toJSON() {
    return {
      unitOperationId: this.unitOperationId,
    };
  }
}
