/**
 * Class for storing the constraints of a unit operation
 */
import { SuncorConstraint } from './suncor-constraint';
import { SimulationVariable } from '../simulation-variable';

export class UnitOperationConstraints {
  constraints: { [id: string]: SuncorConstraint } = {};

  addConstraint(name: string, constraint: SimulationVariable) {
    this.constraints[name] = new SuncorConstraint(constraint);
  }

  getConstraint(name: string) {
    return this.constraints[name];
  }

  allConstraintValuesDefined(): boolean {
    for (const id in this.constraints) {
      if (!this.getConstraint(id).constraintValueDefined()) {
        return false;
      }
    }

    return true;
  }

  anyConstraintValueDefined(): boolean {
    for (const id in this.constraints) {
      if (this.getConstraint(id).constraintValueDefined()) {
        return true;
      }
    }

    return false;
  }

  constraintValueViolated(id: string, referenceValue: number) {
    return this.getConstraint(id).constraintValueViolated(referenceValue);
  }
}
