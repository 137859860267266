import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { UncertaintyAnalysisComponentService } from '../../flowsheet/case-study/uncertainty-analysis/uncertainty-analysis-component.service';
import { LogManagerService } from '../../flowsheet/log-manager/log-manager.service';
import { UncertaintyAnalysis } from '../../_models/_case-study/uncertainty-analysis';
import { Project } from '../../_models';
import { SolveCaseRequest } from '../../_models/solve-case-request';
import { ApiObjectsBackupManager } from '../../_models/api-objects-backup-manager';
import { environment } from '../../../environments/environment';
import { CaseResultsBackup } from '../../_models/case-results-backup';

@Injectable({
  providedIn: 'root',
})
export class UncertaintyAnalysisApiService {
  constructor(
    private http: HttpClient,
    private uncertaintyCore: UncertaintyAnalysisComponentService,
    private logManager: LogManagerService
  ) {}

  private subSink = new SubSink();

  private static getHeaders() {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return headers;
  }

  public solveUncertaintyAnalysis(analysis: UncertaintyAnalysis, project: Project): Observable<any> {
    const { ownerCase } = analysis;

    // TODO would it be good to clone the current case and then remove the other uncertainty analyses?
    // and the results of the case being sent...

    const dataToSend: SolveCaseRequest = {
      solveOption: 'waitForResults',
      caseModel: ownerCase,
      version: project.version,
      username: '',
      userId: '',
      email: '',
    };
    const removedAnalyses = ApiObjectsBackupManager.removeOtherUncertaintyAnalyses(analysis);
    // const backupObject = ApiObjectsBackupManager.cleanAndCreateBackupObject(analysis.ownerCase);

    // remove all the references to the uncertainty analysis results.
    // should this be backed up before cleaning?
    ownerCase.caseStudyManager.uncertaintyAnalyses[0].clearResults();

    // TODO remove this heresy
    const solveSubject = new Subject<any>();
    const solveObservable = solveSubject.asObservable();

    this.subSink.add(
      this.http
        .post(environment.uncertaintyUrl, dataToSend, { headers: UncertaintyAnalysisApiService.getHeaders() })
        .subscribe(
          response => {
            // restore the removed analyses...
            ApiObjectsBackupManager.restoreRemovedAnalyses(removedAnalyses);
            // ApiObjectsBackupManager.restoreBackupData(backupObject);
            solveSubject.next(response);
          },
          error => {
            this.uncertaintyCore.notifySolveError(error);
            // return this.handleUncertaintyAnalysisError(error, analysis, removedAnalyses, backupObject);
          }
        )
    );

    return solveObservable;
  }

  handleUncertaintyAnalysisError(
    error: any,
    analysis: UncertaintyAnalysis,
    removedAnalyses: Array<UncertaintyAnalysis>,
    backupObject: CaseResultsBackup
  ) {
    ApiObjectsBackupManager.restoreRemovedAnalyses(removedAnalyses);
    ApiObjectsBackupManager.restoreBackupData(backupObject);
    this.logManager.error(`An error occurred while solving analysis: ${analysis.name}`);
    return this.handleError(error);
  }

  handleError(error: any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = (error.error ? JSON.stringify(error.error) : '') || JSON.stringify(error);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  finishSubscriptions() {
    this.subSink.unsubscribe();
  }
}
