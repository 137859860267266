export class ConstraintViolationEntry {
  ownerOperationName: string;
  constraintViolationPercent: number;
  variableName: string;

  constructor(bo: any) {
    this.ownerOperationName = bo.ownerOperationName;
    this.constraintViolationPercent = bo.constraintViolationPercent;
    this.variableName = bo.variableName;
  }
}
