import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjcSheet from '@grapecity/wijmo.grid.sheet';

import { CollectionView } from '@grapecity/wijmo';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { FlexSheetColumn } from '../../_models/wijmo/flex-sheet-column';
import { Case, UnitOperation } from '../../_models';
import { BaseUtilitySpreadsheetModel } from '../../_models/utilities-spreadsheet/base-utility-spreadsheet-model';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { CoreService } from '../core.service';
import { SpreadsheetTitle } from '../../_config/spreadsheet/spreadsheet-title.enum';

export abstract class AbstractUtilitiesHandler {
  protected items: BaseUtilitySpreadsheetModel[];
  protected grid: wjGrid.FlexGrid;
  protected readonly DEFAULT_NUMBER_OF_ROWS = 40;
  sheetName: string;
  sheetIndex: number;
  ownerUnitOperationId: string;

  protected constructor(protected coreService: CoreService) {}

  abstract isUnitOperationAllowed(unitOperation: UnitOperation): boolean;

  protected abstract toSpreadsheetItem(
    unitOperation: UnitOperation,
    alternateName?: string
  ): BaseUtilitySpreadsheetModel;

  abstract clearResults(): void;

  protected abstract getColumnsConfiguration(): FlexSheetColumn[];

  abstract addTotal(upgrader: Upgrader): void;

  init(spreadsheet: wjcSheet.FlexSheet, sheetName: string, sheetIndex: number) {
    this.grid = new wjGrid.FlexGrid(document.createElement('div'), {
      autoGenerateColumns: false,
      columns: this.getColumnsConfiguration(),
      frozenRows: 1,
      frozenColumns: 1,
    });

    this.items = [];
    this.clearSpreadsheetItems();

    const data = this.createCollectionView();

    spreadsheet.addBoundSheet(sheetName, data, sheetIndex, this.grid);
    this.sheetName = sheetName;
  }

  protected createCollectionView() {
    return new CollectionView(this.items);
  }

  caseToSpreadsheet(c: Case, upgOwner: Upgrader): void {
    this.clearSpreadsheetItems();

    // eslint-disable-next-line guard-for-in
    for (const id in c.unitOperationPool) {
      const uo = c.unitOperationPool[id] as SuncorUnitOperation;
      if (this.isUnitOperationAllowed(uo)) {
        this.addUnitOperationWithoutRefreshing(uo);
      }
    }

    if (c.isSolved) {
      this.addTotal(upgOwner);
    }

    this.grid.collectionView.refresh();
  }

  handleEditEvent(spreadsheet: wjcSheet.FlexSheet, flexSheetEvent: any) {
    if (spreadsheet.selectedSheet.name !== this.sheetName) {
      return;
    }

    const item = this.items[flexSheetEvent.row - 1];
    if (!item.id || item.unitOperationName === 'Total') {
      flexSheetEvent.cancel = true;
      return;
    }

    if (!spreadsheet.activeEditor.value) {
      flexSheetEvent.cancel = true;
      return;
    }

    this.handleEvent(spreadsheet.activeEditor.value, item, flexSheetEvent);
  }

  handlePasteEvent(spreadsheet: wjcSheet.FlexSheet, flexSheetEvent: any): void {
    if (spreadsheet.selectedSheet.name !== this.sheetName) {
      return;
    }

    const item = this.items[flexSheetEvent.row - 1];
    if (!item.id || item.unitOperationName === 'Total') {
      flexSheetEvent.cancel = true;
      return;
    }

    if (!flexSheetEvent.data) {
      flexSheetEvent.cancel = true;
      return;
    }

    this.handleEvent(flexSheetEvent.data, item, flexSheetEvent);
  }

  protected handleEvent(rawValue, item: BaseUtilitySpreadsheetModel, flexSheetEvent: any) {
    const valueAsNumber = +rawValue;

    if (Number.isNaN(valueAsNumber)) {
      flexSheetEvent.cancel = true;
      return;
    }

    const uo = this.assignValueToUnitOperation(valueAsNumber, item, flexSheetEvent);

    if (uo) {
      this.coreService.notifyUnitOperationUpdated(uo, true);
    }
  }

  abstract assignValueToUnitOperation(
    valueAsNumber: number,
    item: BaseUtilitySpreadsheetModel,
    flexSheetEvent: any
  ): SuncorUnitOperation | undefined;

  protected clearSpreadsheetItems() {
    this.items.splice(this.DEFAULT_NUMBER_OF_ROWS, this.items.length - this.DEFAULT_NUMBER_OF_ROWS);
    for (let i = 0; i < this.DEFAULT_NUMBER_OF_ROWS; i++) {
      this.items[i] = {};
    }
  }

  addUnitOperation(unitOperation: SuncorUnitOperation): void {
    if (this.isUnitOperationAllowed(unitOperation)) {
      this.addUnitOperationWithoutRefreshing(unitOperation);
      this.grid.collectionView.refresh();
    }
  }

  protected addUnitOperationWithoutRefreshing(unitOperation: UnitOperation) {
    const index = this.items.findIndex(u => !u.unitOperationName);

    const newItem = this.toSpreadsheetItem(unitOperation);

    if (index > -1 && index <= this.DEFAULT_NUMBER_OF_ROWS - 1) {
      this.items[index] = newItem;
    } else {
      this.items.push(newItem);
    }
  }

  updateUnitOperation(unitOperation: SuncorUnitOperation): void {
    if (this.isUnitOperationAllowed(unitOperation)) {
      const index = this.items.findIndex(i => i.id === unitOperation.id);
      this.items[index] = this.toSpreadsheetItem(unitOperation);
      this.grid.collectionView.refresh();
    }
  }

  removeUnitOperation(unitOperation: SuncorUnitOperation): void {
    if (this.isUnitOperationAllowed(unitOperation)) {
      const index = this.items.findIndex(i => i.id === unitOperation.id);
      this.items.splice(index, 1);

      // keep at least 40 rows/items
      if (this.items.length < this.DEFAULT_NUMBER_OF_ROWS) {
        this.items.push({});
      }

      this.grid.collectionView.refresh();
    }
  }

  belongsToUnitOperation(id: string, sheetType: SpreadsheetTitle) {
    return sheetType
      ? this.ownerUnitOperationId === id && this.sheetName.includes(sheetType)
      : this.ownerUnitOperationId === id;
  }
}
