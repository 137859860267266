import * as wjcCore from '@grapecity/wijmo';
import { FlexSheetCellConfig } from './flex-sheet-cell-config';
import { UtilitiesSheetColumns } from './utilities-cell-config.enum';

export enum H2sSheetColumns {
  NAME,
  H2S_PRODUCTION_RATE,
  H2S_PRODUCTION,
}

export const h2sCellConfig: FlexSheetCellConfig[] = [
  {
    row: H2sSheetColumns.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: H2sSheetColumns.H2S_PRODUCTION,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: H2sSheetColumns.H2S_PRODUCTION_RATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
