import { Component, Input, OnInit } from '@angular/core';
import { Extraction } from '../../../_models/_unit-operations/extraction';
import { CoreService } from '../../../_services/core.service';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { FlowsheetService } from '../../../_services/flowsheet.service';

@Component({
  selector: 'sob-extraction',
  templateUrl: './extraction.component.html',
  styleUrls: ['./extraction.component.css'],
})
export class ExtractionComponent implements OnInit {
  @Input() unitOperation: Extraction;
  currentAnalysis: SuncorFluidAnalysis;

  constructor(
    private coreService: CoreService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private flowsheetService: FlowsheetService
  ) {}

  ngOnInit() {
    this.updateCurrentAnalysis();
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Crude oil - Bitumen');
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
      this.assayChanged();
    }
  }

  assayChanged() {
    const cuts = [
      'lightEndsCut',
      'lightNaphthaCut',
      'heavyNaphthaCut',
      'distillateCut',
      'LGOCut',
      'HGOCut',
      'VGOCut',
      'HVGOCut',
      'residCut',
    ];
    const simvarNames = ['sulfur', 'anilinePoint', 'massDensity', 'nitrogen', 'ccr'];
    for (const cutId of cuts) {
      for (const simvarName of simvarNames) {
        const simVar = this.currentAnalysis[cutId][simvarName];
        const $field = `#${cutId}${simvarName}`;
        $($field).val(simVar.convertToCaptureUnit(true));
        $(`${$field}Unit`).val(simVar.unit);
        $($field).change();
      }
    }
    $(`#compositionUnit${this.unitOperation.id}`).val(this.currentAnalysis.composition[0].unit);
    let total = 0;
    for (let i = 0; i < this.currentAnalysis.composition.length; i++) {
      const simVar = this.currentAnalysis.composition[i];
      total += simVar.convertToCaptureUnit(true);
      $(`#composition${i}`).val(simVar.convertToCaptureUnit(true));
    }
    $('#compositionTotalUO').val(total);

    $('#fluidAnalysisMassDensity').val(this.currentAnalysis.massDensity.convertToCaptureUnit(true));
    $('#fluidAnalysisMassDensityUnit').val(this.currentAnalysis.massDensity.unit);
    $('#fluidAnalysisMassDensity').change();

    $('#fluidAnalysisCcr').val(this.currentAnalysis.ccr.convertToCaptureUnit(true));
    $('#fluidAnalysisCcrUnit').val(this.currentAnalysis.ccr.unit);
    $('#fluidAnalysisCcr').change();

    $('#fluidAnalysisSulfur').val(this.currentAnalysis.sulfur.convertToCaptureUnit(true));
    $('#fluidAnalysisSulfurUnit').val(this.currentAnalysis.sulfur.unit);
    $('#fluidAnalysisSulfur').change();

    $('#fluidAnalysisNitrogen').val(this.currentAnalysis.nitrogen.convertToCaptureUnit(true));
    $('#fluidAnalysisNitrogenUnit').val(this.currentAnalysis.nitrogen.unit);
    $('#fluidAnalysisNitrogen').change();
  }

  // region level 2 handling
  clearOpex(): void {
    if (!this.unitOperation.useSubFlowsheet) {
      this.unitOperation.opexTotal.value = undefined;
      this.unitOperation.opexVar.value = undefined;
      this.unitOperation.opexGasDiesel.value = undefined;
      this.unitOperation.opexPower.value = undefined;
      this.unitOperation.opexCarbonDioxide.value = undefined;
      this.unitOperation.opexFixed.value = 0.0;
    }
  }

  changeNodeTemplate(level2Enabled: boolean) {
    if (level2Enabled) {
      this.flowsheetDiagramService.changeTemplate(this.unitOperation.id, unitOperationsConfig.flexibleExtraction.key);
    } else {
      this.flowsheetDiagramService.changeTemplate(this.unitOperation.id, unitOperationsConfig.extraction.key);
    }
    this.flowsheetDiagramService.deleteInletStreams(this.unitOperation.id);
    this.flowsheetDiagramService.deleteOutletStreams(this.unitOperation.id);
  }

  openSubFlowsheet(): void {
    if ($('#closePropertyWindow').attr('disabled')) {
      return;
    }
    this.flowsheetService.prepareUnitOperationForSave();
    this.flowsheetService.closePropertyWindow();

    this.flowsheetService.loadUnitOperationFlowsheet(this.unitOperation.id);
  }
  // endregion
}
