import { SuncorUnitOperation } from './suncor-unit-operation';
import { KpiProvider } from '../_case-study/kpi-provider';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { BaseObject } from '../base-object';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';

export class ThirdPartySource
  extends SuncorUnitOperation
  implements KpiProvider, ParameterProvider, MultiPeriodParameterProvider
{
  category = unitOperationsConfig.thirdPartySource.key;

  isOptimizable: boolean;
  volumetricFlowrate: SimulationVariable;
  price: SimulationVariable;
  fluidAnalysisId: string;

  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override addSimVarsToPool() {
    super.addSimVarsToPool();
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
    const ds = another as ThirdPartySource;
    this.isOptimizable = ds.isOptimizable;
  }

  override ignoreForOverWrite(propertyName: string): boolean {
    return super.ignoreForOverWrite(propertyName);
  }

  getAvailableKpis() {
    return [this.volumetricFlowrate, this.price, this.ghgIntensity, this.ghgEmissions];
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.volumetricFlowrate];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.volumetricFlowrate, this.ghgIntensity, this.price];
  }

  override clearResults() {
    super.clearResults();
    this.ghgEmissions.value = null;
    this.opexCarbonDioxide.value = null;
    this.opexVar.value = null;
    this.opexTotal.value = null;

    if (this.isOptimizable) {
      this.volumetricFlowrate.value = null;
    }
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);

    if (typeof unitOperation.isOptimizable === 'undefined') {
      // I think this is the only place to know if isOptimizable will have an 'undefined' value
      this.isOptimizable = true;
    } else {
      this.isOptimizable = !!unitOperation.isOptimizable;
    }

    this.volumetricFlowrate = new SimulationVariable(unitOperation.volumetricFlowrate, this.ownerCase, this);
    this.price = new SimulationVariable(unitOperation.price, this.ownerCase, this);
    this.fluidAnalysisId = unitOperation.fluidAnalysisId || '';
  }

  override setSimulationVariableNames(): void {
    super.setSimulationVariableNames();
    this.volumetricFlowrate.setName('Volumetric flowrate');
    this.price.setName('Price');
    // Rename
  }

  override setSimulationVariablesOwner() {
    super.setSimulationVariablesOwner();
  }

  override setDefaultValues(): void {
    super.setDefaultValues();

    if (this.price.isUndefined()) {
      this.price.setDefaultValue(60.0);
    }
  }

  override setQuantities() {
    super.setQuantities();
    this.volumetricFlowrate.setQuantity('Volumetricflowrate');
    this.price.setQuantity('CurrencyPerVolume');
  }

  override convertToInternalUnit() {
    super.convertToInternalUnit();
  }

  getAvailableParameters() {
    return [this.ghgIntensity, this.price];
  }

  getAlternativeGhgIntensity() {
    return new SimulationVariable(this.ghgIntensity, this.ownerCase, this);
  }

  // region class-specific methods
  setOptimizable(isOptimizable: boolean) {
    this.isOptimizable = isOptimizable;

    if (this.isOptimizable) {
      this.volumetricFlowrate.value = null;
    }
  }

  override ignoreForMakeReady(simvar: SimulationVariable): boolean {
    let result = super.ignoreForMakeReady(simvar);
    if (this.isOptimizable) {
      result = result || simvar === this.volumetricFlowrate;
    }
    result = result || simvar === this.capacity || simvar === this.minimumFlow;
    return result;
  }
  // endregion

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.volumetricFlowrate = this.getSimulationVariableSafe(sup.volumetricFlowrate);
    this.price = this.getSimulationVariableSafe(sup.price);

    this.setQuantities();
    this.setDefaultValues();
  }
}
