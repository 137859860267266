import { Component } from '@angular/core';
import { CoreService } from '../../_services/core.service';

@Component({
  selector: 'sob-flowsheet-comments',
  templateUrl: './flowsheet-comments.component.html',
  styleUrls: ['./flowsheet-comments.component.css'],
})
export class FlowsheetCommentsComponent {
  constructor(private coreService: CoreService) {}

  get currentCase() {
    return this.coreService.currentCase;
  }
}
