export class DistributionRatioVariablePerCut {
  unitOperationId: string;
  lightEnds: number;
  lightNaphtha: number;
  heavyNaphtha: number;
  distillate: number;
  LGO: number;
  HGO: number;
  VGO: number;
  HVGO: number;
  resid: number;
  constructor(unitOperationId: string, obj: any) {
    this.unitOperationId = unitOperationId;
    this.lightEnds = obj.lightEnds;
    this.lightNaphtha = obj.lightNaphtha;
    this.heavyNaphtha = obj.heavyNaphtha;
    this.distillate = obj.distillate;
    this.LGO = obj.LGO;
    this.HGO = obj.HGO;
    this.VGO = obj.VGO;
    this.HVGO = obj.HVGO;
    this.resid = obj.resid;
  }

  initEmpty() {
    this.lightEnds = 0;
    this.lightNaphtha = 0;
    this.heavyNaphtha = 0;
    this.distillate = 0;
    this.LGO = 0;
    this.HGO = 0;
    this.VGO = 0;
    this.HVGO = 0;
    this.resid = 0;
  }
}
