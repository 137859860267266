import { SolveCanceller } from './solve-canceller';
import { ApiService } from '../_services/api.service';

export class SolveCaseCanceller implements SolveCanceller {
  apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  cancelSolve() {
    this.apiService.cancelSolveCase();
  }
}
