import { Component, ElementRef } from '@angular/core';
import { ProgressIndicatorService } from './progress-indicator.service';

@Component({
  selector: 'sob-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.css'],
})
export class ProgressIndicatorComponent {
  message: string;
  showStopButton: boolean;

  constructor(private elementRef: ElementRef, private progressIndicator: ProgressIndicatorService) {
    this.progressIndicator.showRequest.subscribe((data: { message: string; showStopButton: boolean }) => {
      this.message = data.message;
      this.showStopButton = data.showStopButton;
      this.show();
    });

    this.progressIndicator.hideRequest.subscribe(() => {
      this.hide();
    });

    // weirdly backwards
    this.progressIndicator.cancelRequest.subscribe(() => {
      this.stop();
    });
  }

  show() {
    this.elementRef.nativeElement.firstChild.style.display = 'block';
  }

  hide() {
    this.elementRef.nativeElement.firstChild.style.display = 'none';
  }

  stop() {
    this.progressIndicator.cancel();
  }
}
