import { DataType } from '@grapecity/wijmo';
import { CoreService } from '../core.service';
import { AbstractConstraintsHandler } from './abstract-constraints-handler';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { FlexSheetColumn } from '../../_models/wijmo/flex-sheet-column';
import { BaseConstraintsSpreadsheetModel } from '../../_models/constraints-spreadsheet/base-constraints-spreadsheet-model';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { ThirdPartyRefinery } from '../../_models/_unit-operations/third-party-refinery';
import { Pipe } from '../../_models/_unit-operations/pipe';
import { BitumenConversion } from '../../_models/_unit-operations/bitumen-conversion';
import { Refinery } from '../../_models/_unit-operations/refinery';
import { DiluentSource } from '../../_models/_unit-operations/diluent-source';
import { MineSource } from '../../_models/_unit-operations/mine-source';
import { SagdSource } from '../../_models/_unit-operations/sagd-source';
import { FlowsheetTreeService } from '../sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { NumberToUnitConverter } from '../number-to-unit-converter.service';
import { UnitOperation } from '../../_models';
import { isEmptyValue } from '../../_utils/utils';
import { Mixer } from '../../_models/_unit-operations/mixer';
import { Splitter } from '../../_models/_unit-operations/splitter';
import { BaseConstraintReportVariable } from '../../_models/_reports/_optimization-settings-table/base-constraint-report-variable';

export class ConstraintsSheetHandler extends AbstractConstraintsHandler {
  protected readonly SHEET_INDEX: number;
  constraints: Array<BaseConstraintReportVariable> = [];

  constructor(
    coreService: CoreService,
    flowsheetTreeService: FlowsheetTreeService,
    private nuc: NumberToUnitConverter
  ) {
    super(coreService, flowsheetTreeService);
  }

  isUnitOperationAllowed(
    unitOperation: UnitOperation
  ): unitOperation is
    | Upgrader
    | BitumenConversion
    | Refinery
    | ThirdPartyRefinery
    | Pipe
    | DiluentSource
    | MineSource
    | SagdSource
    | Mixer
    | Splitter {
    if (isEmptyValue(unitOperation.flowsheetId)) {
      return (
        unitOperation instanceof Upgrader ||
        unitOperation instanceof ThirdPartyRefinery ||
        unitOperation instanceof Pipe ||
        unitOperation instanceof BitumenConversion ||
        unitOperation instanceof Refinery ||
        unitOperation instanceof DiluentSource ||
        unitOperation instanceof MineSource ||
        unitOperation instanceof SagdSource ||
        unitOperation instanceof Mixer ||
        unitOperation instanceof Splitter
      );
    }

    return false;
  }

  // TODO: There should be a type function, getUnitOperation or getConstraint maybe?

  override clearResults(): void {
    this.grid.collectionView.refresh();
  }

  private getUnitOperation(item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation {
    const unitOperation = this.coreService.currentCase.getUnitOperation(item.unitOperationId) as SuncorUnitOperation;

    if (this.isUnitOperationAllowed(unitOperation)) {
      return unitOperation;
    }

    throw new Error(`A non-allowed unit operation was detected ${unitOperation.category} - ${unitOperation.name}`);
  }

  assignValueToUnitOperation(valueAsNumber: number, item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation {
    const uo = this.getUnitOperation(item);

    if (this.isMixingConstraintsUser(uo)) {
      this.assignNewValuesForRangeConstraints(uo, item, valueAsNumber);
    } else {
      const simVar = uo.getSimulationVariableSafe(item.id);
      simVar.value = this.nuc.convertDefaultToInternalUnit(valueAsNumber, simVar.quantity);
    }
    return uo;
  }

  deleteUnitOperationValue(item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation {
    const uo = this.getUnitOperation(item);

    if (this.isMixingConstraintsUser(uo)) {
      this.assignNewValuesForRangeConstraints(uo, item, null);
    } else {
      const simVar = uo.getSimulationVariableSafe(item.id);
      simVar.value = null;
    }
    return uo;
  }

  protected getColumnsConfiguration(): FlexSheetColumn[] {
    return [
      {
        header: 'Active',
        binding: 'isActive',
        dataType: DataType.Boolean,
        width: 120,
        isReadOnly: false,
      },
      {
        header: 'Unit Name',
        binding: 'unitOperationName',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: 'Constraint',
        binding: 'constraint',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: 'Constraint Value',
        binding: 'constraintValue',
        dataType: DataType.Number,
        width: 120,
      },
      {
        header: 'Current Value',
        binding: 'currentValue',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: 'Unit',
        binding: 'unit',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: 'Flowsheet Owner',
        binding: 'flowsheetOwner',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
    ];
  }
}
