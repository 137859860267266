import { SimulationVariable } from '../simulation-variable';

export interface MultiPeriodTank {
  id: string;
  name: string;
  minVolume: SimulationVariable;
  maxVolume: SimulationVariable;
  initialVolume: SimulationVariable;
}

export function isMultiPeriodTank(tank: any): tank is MultiPeriodTank {
  return tank.hasOwnProperty('minVolume') && tank.hasOwnProperty('maxVolume') && tank.hasOwnProperty('initialVolume');
}
