<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#fuelGasAmineContactorComments" aria-controls="fuelGasAmineContactorComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Inputs</h4>
        <hr />
        <br />
        <div class="form-group" formGroupName="h2SRemovalFraction">
          <label class="control-label col-sm-4"> H2S Removal </label>
          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.h2SRemovalFraction.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.h2SRemovalFraction">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="fuelGasAmineContactorComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
