import { Component, Input } from '@angular/core';
import { WaterSink } from '../../../_models/_unit-operations/utilties/water-sink';

@Component({
  selector: 'sob-water-sink',
  templateUrl: './water-sink.component.html',
  styleUrls: ['./water-sink.component.css'],
})
export class WaterSinkComponent {
  @Input()
  unitOperation: WaterSink;
}
