import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { WaterSourceImport } from '../../../_models/_unit-operations/utilties/water-source-import';
import { CoreService } from '../../../_services/core.service';
import { SteamUseUnitOperation } from '../../../_models/_unit-operations/steam-use-unit-operation';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SimulationVariable } from '../../../_models/simulation-variable';
import { WaterSourceInformationStreamFormComponent } from '../water-source-information-stream-form/water-source-information-stream-form.component';
import { SteamType } from '../../../_config/steam-type.enum';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { AbstractWaterInformationStreamPropertyWindow } from '../abstract-water-information-stream-property-window';
import { UnitOperation } from '../../../_models';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';

@Component({
  selector: 'sob-water-source-import',
  templateUrl: './water-source-import.component.html',
  styleUrls: ['./water-source-import.component.css'],
})
export class WaterSourceImportComponent extends AbstractWaterInformationStreamPropertyWindow implements OnInit {
  @Input() override unitOperation: WaterSourceImport;
  @Input() override formGroupWrapper: BaseObjectFormGroupWrapper;
  @Input() waterSourceIn: WaterSourceInformationStreamFormComponent;
  PressureCalculationMode = PressureCalculationMode;
  SteamType = SteamType;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(coreService: CoreService, fb: UntypedFormBuilder, svfb: SimVarFormBuilder) {
    super(coreService, fb, svfb);
  }

  ngOnInit(): void {
    this.activeInformationStreams.push(...this.unitOperation.inletSourceInformationStreams);
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
    this.filterStreams();
  }

  protected getOtherInformationStreamUsers(): UnitOperation[] {
    return this.currentCase.filterUnitOperations(
      uo => uo.category === unitOperationsConfig.waterSourceImport.key && uo.id !== this.unitOperation.id
    );
  }

  protected usesInformationStream(informationStreamUser: UnitOperation, simulationVariableId: string): boolean {
    return !!(informationStreamUser as WaterSourceImport).inletSourceInformationStreams.find(
      i => i.simulationVariableId === simulationVariableId
    );
  }

  protected getAllowedAvailableSteamVariableByType(
    uo: SteamUseUnitOperation,
    steamType: SteamType
  ): SimulationVariable {
    return uo.getSteamMakeVariableByType(steamType);
  }

  // region form group getters
  get informationStreamsFormArray() {
    return this.propertyWindowFormGroup.get('inletSourceInformationStreams') as UntypedFormArray;
  }
  // endregion
}
