import { Component } from '@angular/core';
import { CoreService } from '../../_services/core.service';
import { FlowsheetDiagramService } from '../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { Case } from '../../_models';
import { MultiPeriodService } from '../../_services/multi-period/multi-period.service';

@Component({
  selector: 'sob-diagram-controls',
  templateUrl: './diagram-controls.component.html',
  styleUrls: ['./diagram-controls.component.css'],
})
export class DiagramControlsComponent {
  hiddenDashedLines = false;
  hiddenInactiveItems = false;
  hiddenLabels = false;

  constructor(
    private flowsheetDiagramService: FlowsheetDiagramService,
    private core: CoreService,
    private multiPeriodService: MultiPeriodService
  ) {}

  toggleDottedLines() {
    if (this.hiddenDashedLines) {
      this.flowsheetDiagramService.showInactiveItems();
      this.hiddenDashedLines = false;
    } else {
      this.flowsheetDiagramService.hideDashedLineLinks();
      this.hiddenDashedLines = true;
    }
  }

  toggleInactiveItems() {
    if (this.hiddenInactiveItems) {
      this.flowsheetDiagramService.showInactiveItems();
      this.hiddenInactiveItems = false;
    } else {
      this.flowsheetDiagramService.hideInactiveItems();
      this.hiddenInactiveItems = true;
    }
  }

  toggleLabels() {
    if (this.hiddenLabels) {
      this.flowsheetDiagramService.showNodeLabels();
      this.hiddenLabels = false;
    } else {
      this.flowsheetDiagramService.hideNodeLabels();
      this.hiddenLabels = true;
    }
  }

  toggleMultiPeriodMode(): void {
    if (!this.isMultiPeriodEnabled) {
      this.multiPeriodService.enableMultiPeriod({
        showSettingsModal: true,
        showNotification: true,
      });
    } else {
      const disableMultiPeriodMode = confirm('Disable multi period mode? All your results will be deleted.');
      if (disableMultiPeriodMode) {
        this.multiPeriodService.disableMultiPeriod({
          showNotification: true,
        });
      }
    }
  }

  zoomToFit(): void {
    this.flowsheetDiagramService.zoomToFit();
  }

  get currentCase(): Case {
    return this.core.currentCase;
  }

  get isMultiPeriodEnabled(): boolean {
    return !!this.currentCase.multiPeriodEnabled;
  }
}
