import { SteamUseUnitOperation } from './steam-use-unit-operation';
import { SimulationVariable } from '../simulation-variable';
import { Quantity } from '../../_config/quantity.enum';
import { VariableStatus } from '../../_config/variable-status.enum';

declare let unitConverter: any;
export abstract class GasContributorUnitOperation extends SteamUseUnitOperation {
  energyFlow: SimulationVariable;
  flowrate: SimulationVariable;
  fuelGasEmissionFactor: SimulationVariable;
  fuelGasHeatingValue: SimulationVariable;
  useCustomGasModel: boolean;
  gasModelScript: string;
  gasAnalysisId: string;

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);

    this.energyFlow = new SimulationVariable(unitOperation.energyFlow || {}, this.ownerCase, this);
    this.flowrate = new SimulationVariable(unitOperation.flowrate || {}, this.ownerCase, this);
    this.fuelGasEmissionFactor = new SimulationVariable(
      unitOperation.fuelGasEmissionFactor || {},
      this.ownerCase,
      this
    );
    this.fuelGasHeatingValue = new SimulationVariable(unitOperation.fuelGasHeatingValue || {}, this.ownerCase, this);
    this.useCustomGasModel = !!unitOperation.useCustomGasModel;
    this.gasModelScript = unitOperation.gasModelScript || '';
    this.gasAnalysisId = unitOperation.gasAnalysisId || '';
  }

  override clearResults() {
    super.clearResults();
    this.energyFlow.value = undefined;
    this.flowrate.value = undefined;

    if (this.fuelGasHeatingValue.isSolverCalculated()) {
      this.fuelGasHeatingValue.variableStatus = VariableStatus.UNDEFINED;
      this.setDefaultValues();
    }

    if (this.fuelGasEmissionFactor.isSolverCalculated()) {
      this.fuelGasEmissionFactor.variableStatus = VariableStatus.UNDEFINED;
      this.setDefaultValues();
    }
  }

  override setQuantities() {
    super.setQuantities();

    this.energyFlow.setQuantity(Quantity.ENERGYRATE);
    this.flowrate.setQuantity(Quantity.MOLARFLOWRATE);
    this.fuelGasEmissionFactor.setQuantity(Quantity.MASS_EMISSIONS_PER_VOLUME);
    this.fuelGasHeatingValue.setQuantity(Quantity.ENERGY_PER_VOLUME);
  }

  override setDefaultValues(): void {
    super.setDefaultValues();
    if (this.fuelGasEmissionFactor.isUndefined()) {
      this.fuelGasEmissionFactor.setDefaultValue(0.0702, unitConverter.units.MassEmissionsPerVolume.KG_SCF);
    }
    if (this.fuelGasHeatingValue.isUndefined()) {
      this.fuelGasHeatingValue.setDefaultValue(1088.3, unitConverter.units.EnergyPerVolume.BTU_SCF);
    }
  }

  override dePersist(uo: any) {
    super.dePersist(uo);

    this.energyFlow = this.getSimulationVariableSafe(uo.energyFlow);
    this.flowrate = this.getSimulationVariableSafe(uo.flowrate);
    this.fuelGasEmissionFactor = this.getSimulationVariableSafe(uo.fuelGasEmissionFactor);
    this.fuelGasHeatingValue = this.getSimulationVariableSafe(uo.fuelGasHeatingValue);
  }

  override detectChanges(another: any): boolean {
    const changes = super.detectChanges(another);
    return changes || another.gasModelScript !== this.gasModelScript;
  }
}
