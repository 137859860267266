export class BaseObjectComment {
  id: string;
  ownerId: string;
  commentDate: Date;
  text: string;
  username: string;
  userId: string;

  static createCommentAndSetValues(plainObject: PlainObject<BaseObjectComment>) {
    const comment = new BaseObjectComment();
    comment.id = plainObject.id;
    comment.ownerId = plainObject.ownerId;
    comment.commentDate = plainObject.commentDate;
    comment.text = plainObject.text;
    comment.username = plainObject.username;
    comment.userId = plainObject.userId;
    return comment;
  }

  static create(ownerObjectId: string): BaseObjectComment {
    const comment = new BaseObjectComment();
    comment.id = (<any>window).uuid();

    comment.ownerId = ownerObjectId;
    return comment;
  }

  dePersist(plainObject: PlainObject<BaseObjectComment>) {
    this.id = plainObject.id;
    this.ownerId = plainObject.ownerId;
    this.commentDate = new Date(plainObject.commentDate);
    this.text = plainObject.text;
    this.username = plainObject.username;
    this.userId = plainObject.userId;
  }
}

type PlainObject<Type> = {
  [Property in keyof Type]: any;
};
