import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { AbstractSheetHandler } from './abstract-sheet-handler';
import { FlexSheetColumnTracker } from '../flex-sheet-column-tracker';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';

declare let unitConverter: any;
export class AssaySheetHandler extends AbstractSheetHandler {
  readonly SHEET_INDEX = 0;

  constructor(spreadsheet: wjcSheet.FlexSheet, columnTracker: FlexSheetColumnTracker) {
    super();
    this.spreadsheet = spreadsheet;
    this.columnTracker = columnTracker;
  }

  protected getLabels(): string[] {
    return [
      '',
      'Name',
      'Type',
      '',
      'Composition (wt%)',
      'Light ends',
      'Light naphtha',
      'Heavy naphtha',
      'Distillate',
      'LGO',
      'HGO',
      'VGO',
      'HVGO',
      'Resid',
      '',
      'Light Ends',
      'Sulfur (wt%)',
      'Aniline point (C)',
      'Mass density (API)',
      'Nitrogen (ppmw)',
      'CCR (wt%)',
      '',
      'Light Naphtha',
      'Sulfur (wt%)',
      'Aniline point (C)',
      'Mass density (API)',
      'Nitrogen (ppmw)',
      'CCR (wt%)',
      '',
      'Heavy naphtha',
      'Sulfur (wt%)',
      'Aniline point (C)',
      'Mass density (API)',
      'Nitrogen (ppmw)',
      'CCR (wt%)',
      '',
      'Distillate',
      'Sulfur (wt%)',
      'Aniline point (C)',
      'Mass density (API)',
      'Nitrogen (ppmw)',
      'CCR (wt%)',
      '',
      'LGO',
      'Sulfur (wt%)',
      'Aniline point (C)',
      'Mass density (API)',
      'Nitrogen (ppmw)',
      'CCR (wt%)',
      '',
      'HGO',
      'Sulfur (wt%)',
      'Aniline point (C)',
      'Mass density (API)',
      'Nitrogen (ppmw)',
      'CCR (wt%)',
      '',
      'VGO',
      'Sulfur (wt%)',
      'Aniline point (C)',
      'Mass density (API)',
      'Nitrogen (ppmw)',
      'CCR (wt%)',
      '',
      'HVGO',
      'Sulfur (wt%)',
      'Aniline point (C)',
      'Mass density (API)',
      'Nitrogen (ppmw)',
      'CCR (wt%)',
      '',
      'Resid',
      'Sulfur (wt%)',
      'Aniline point (C)',
      'Mass density (API)',
      'Nitrogen (ppmw)',
      'CCR (wt%)',
    ];
  }

  protected setData(assay: SuncorFluidAnalysis) {
    const dataArray = [
      '',
      assay.assayName,
      assay.assayType,
      '',
      '',
      this.formatSimulationVariable(assay.composition[0]),
      this.formatSimulationVariable(assay.composition[1]),
      this.formatSimulationVariable(assay.composition[2]),
      this.formatSimulationVariable(assay.composition[3]),
      this.formatSimulationVariable(assay.composition[4]),
      this.formatSimulationVariable(assay.composition[5]),
      this.formatSimulationVariable(assay.composition[6]),
      this.formatSimulationVariable(assay.composition[7]),
      this.formatSimulationVariable(assay.composition[8]),
      '',
      '',
      this.formatSimulationVariable(assay.lightEndsCut.sulfur),
      this.formatSimulationVariable(assay.lightEndsCut.anilinePoint),
      this.formatSimulationVariable(assay.lightEndsCut.massDensity),
      this.formatSimulationVariable(assay.lightEndsCut.nitrogen, unitConverter.units.MassFraction.PPMW),
      this.formatSimulationVariable(assay.lightEndsCut.ccr),
      '',
      '',
      this.formatSimulationVariable(assay.lightNaphthaCut.sulfur),
      this.formatSimulationVariable(assay.lightNaphthaCut.anilinePoint),
      this.formatSimulationVariable(assay.lightNaphthaCut.massDensity),
      this.formatSimulationVariable(assay.lightNaphthaCut.nitrogen, unitConverter.units.MassFraction.PPMW),
      this.formatSimulationVariable(assay.lightNaphthaCut.ccr),
      '',
      '',
      this.formatSimulationVariable(assay.heavyNaphthaCut.sulfur),
      this.formatSimulationVariable(assay.heavyNaphthaCut.anilinePoint),
      this.formatSimulationVariable(assay.heavyNaphthaCut.massDensity),
      this.formatSimulationVariable(assay.heavyNaphthaCut.nitrogen, unitConverter.units.MassFraction.PPMW),
      this.formatSimulationVariable(assay.heavyNaphthaCut.ccr),
      '',
      '',
      this.formatSimulationVariable(assay.distillateCut.sulfur),
      this.formatSimulationVariable(assay.distillateCut.anilinePoint),
      this.formatSimulationVariable(assay.distillateCut.massDensity),
      this.formatSimulationVariable(assay.distillateCut.nitrogen, unitConverter.units.MassFraction.PPMW),
      this.formatSimulationVariable(assay.distillateCut.ccr),
      '',
      '',
      this.formatSimulationVariable(assay.LGOCut.sulfur),
      this.formatSimulationVariable(assay.LGOCut.anilinePoint),
      this.formatSimulationVariable(assay.LGOCut.massDensity),
      this.formatSimulationVariable(assay.LGOCut.nitrogen, unitConverter.units.MassFraction.PPMW),
      this.formatSimulationVariable(assay.LGOCut.ccr),
      '',
      '',
      this.formatSimulationVariable(assay.HGOCut.sulfur),
      this.formatSimulationVariable(assay.HGOCut.anilinePoint),
      this.formatSimulationVariable(assay.HGOCut.massDensity),
      this.formatSimulationVariable(assay.HGOCut.nitrogen, unitConverter.units.MassFraction.PPMW),
      this.formatSimulationVariable(assay.HGOCut.ccr),
      '',
      '',
      this.formatSimulationVariable(assay.VGOCut.sulfur),
      this.formatSimulationVariable(assay.VGOCut.anilinePoint),
      this.formatSimulationVariable(assay.VGOCut.massDensity),
      this.formatSimulationVariable(assay.VGOCut.nitrogen, unitConverter.units.MassFraction.PPMW),
      this.formatSimulationVariable(assay.VGOCut.ccr),
      '',
      '',
      this.formatSimulationVariable(assay.HVGOCut.sulfur),
      this.formatSimulationVariable(assay.HVGOCut.anilinePoint),
      this.formatSimulationVariable(assay.HVGOCut.massDensity),
      this.formatSimulationVariable(assay.HVGOCut.nitrogen, unitConverter.units.MassFraction.PPMW),
      this.formatSimulationVariable(assay.HVGOCut.ccr),
      '',
      '',
      this.formatSimulationVariable(assay.residCut.sulfur),
      this.formatSimulationVariable(assay.residCut.anilinePoint),
      this.formatSimulationVariable(assay.residCut.massDensity),
      this.formatSimulationVariable(assay.residCut.nitrogen, unitConverter.units.MassFraction.PPMW),
      this.formatSimulationVariable(assay.residCut.ccr),
    ];
    const column = this.columnTracker.findAssayColumnIndex(assay);
    this.arrayToSpreadsheetColumn(dataArray, column);
  }

  isUnitOperationAllowed(assay: SuncorFluidAnalysis): boolean {
    return assay instanceof SuncorFluidAnalysis;
  }
}
