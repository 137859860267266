import { DefaultBaseObjectFormGroupMapper } from './default-base-object-form-group.mapper';
import { FuelGasSinkImport } from '../_models/_unit-operations/fuel-gas/fuel-gas-sink-import';
import { ArrayDiff } from '../_utils/array-diff';
import { EnergyStream } from '../_models/_unit-operations/fuel-gas/energy-stream';
import { BaseObject } from '../_models/base-object';

export class FuelGasSinkImportFormGroupMapper extends DefaultBaseObjectFormGroupMapper {
  override map(source: BaseObject, destination: BaseObject) {
    super.map(source, destination);

    const sourcePlainObject = source as FuelGasSinkImport;
    const destinationInstance = destination as FuelGasSinkImport;

    const arrayDiff = new ArrayDiff(sourcePlainObject.energyStreams);

    // diff with the source information streams as its base array
    const diff = arrayDiff.diff(destinationInstance.energyStreams, (l: EnergyStream, r: EnergyStream) => {
      return l.providerUnitOperationId === r.providerUnitOperationId;
    });

    // map matching energy streams
    for (const sourceEnergyStream of diff.intersection) {
      const destinationStream = destinationInstance.energyStreams.find(
        energyStream => energyStream.providerUnitOperationId === sourceEnergyStream.providerUnitOperationId
      );

      super.map(sourceEnergyStream, destinationStream);
    }

    // add new energy streams
    const energyStreamsToAdd = diff.left;
    for (const energyStream of energyStreamsToAdd) {
      const destinationEnergyStream = new EnergyStream(energyStream, destinationInstance.ownerCase);
      destinationInstance.addEnergyStream(destinationEnergyStream);
    }

    // delete energy streams
    const energyStreamsToDelete = diff.right;
    for (const energyStreamToDelete of energyStreamsToDelete) {
      destinationInstance.removeEnergyStream(energyStreamToDelete.providerUnitOperationId);
    }
  }

  override ignoreForAutoMapping(key: string, source: BaseObject, destination: BaseObject): boolean {
    const result = super.ignoreForAutoMapping(key, source, destination);
    return key === 'energyStreams' || result;
  }
}
