import * as wjcCore from '@grapecity/wijmo';
import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum AssaySheetRows {
  NAME = 1,
  TYPE = 2,
  COMP_LIGHT_ENDS = 5,
  COMP_LIGHT_NAPHTHA = 6,
  COMP_HEAVY_NAPHTHA = 7,
  COMP_DISTILLATE = 8,
  COMP_LGO = 9,
  COMP_HGO = 10,
  COMP_VGO = 11,
  COMP_HVGO = 12,
  COMP_RESID = 13,
  LE_SULFUR = 16,
  LE_ANILINE_POINT = 17,
  LE_MASS_DENSITY = 18,
  LE_NITROGEN = 19,
  LE_CCR = 20,
  LN_SULFUR = 23,
  LN_ANILINE_POINT = 24,
  LN_MASS_DENSITY = 25,
  LN_NITROGEN = 26,
  LN_CCR = 27,
  HN_SULFUR = 30,
  HN_ANILINE_POINT = 31,
  HN_MASS_DENSITY = 32,
  HN_NITROGEN = 33,
  HN_CCR = 34,
  DIS_SULFUR = 37,
  DIS_ANILINE_POINT = 38,
  DIS_MASS_DENSITY = 39,
  DIS_NITROGEN = 40,
  DIS_CCR = 41,
  LGO_SULFUR = 44,
  LGO_ANILINE_POINT = 45,
  LGO_MASS_DENSITY = 46,
  LGO_NITROGEN = 47,
  LGO_CCR = 48,
  HGO_SULFUR = 51,
  HGO_ANILINE_POINT = 52,
  HGO_MASS_DENSITY = 53,
  HGO_NITROGEN = 54,
  HGO_CCR = 55,
  VGO_SULFUR = 58,
  VGO_ANILINE_POINT = 59,
  VGO_MASS_DENSITY = 60,
  VGO_NITROGEN = 61,
  VGO_CCR = 62,
  HVGO_SULFUR = 65,
  HVGO_ANILINE_POINT = 66,
  HVGO_MASS_DENSITY = 67,
  HVGO_NITROGEN = 68,
  HVGO_CCR = 69,
  RES_SULFUR = 72,
  RES_ANILINE_POINT = 73,
  RES_MASS_DENSITY = 74,
  RES_NITROGEN = 75,
  RES_CCR = 76,
}

export const AssayCellConfig: FlexSheetCellConfig[] = [
  {
    row: AssaySheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: AssaySheetRows.TYPE,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: AssaySheetRows.COMP_LIGHT_ENDS,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.COMP_LIGHT_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.COMP_HEAVY_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.COMP_DISTILLATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.COMP_LGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.COMP_HGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.COMP_VGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.COMP_HVGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.COMP_RESID,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LE_SULFUR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LE_ANILINE_POINT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LE_MASS_DENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LE_NITROGEN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LE_CCR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LN_SULFUR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LN_ANILINE_POINT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LN_MASS_DENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LN_NITROGEN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LN_CCR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HN_SULFUR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HN_ANILINE_POINT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HN_MASS_DENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HN_NITROGEN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HN_CCR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.DIS_SULFUR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.DIS_ANILINE_POINT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.DIS_MASS_DENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.DIS_NITROGEN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.DIS_CCR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LGO_SULFUR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LGO_ANILINE_POINT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LGO_MASS_DENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LGO_NITROGEN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.LGO_CCR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HGO_SULFUR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HGO_ANILINE_POINT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HGO_MASS_DENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HGO_NITROGEN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HGO_CCR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.VGO_SULFUR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.VGO_ANILINE_POINT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.VGO_MASS_DENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.VGO_NITROGEN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.VGO_CCR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HVGO_SULFUR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HVGO_ANILINE_POINT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HVGO_MASS_DENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HVGO_NITROGEN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.HVGO_CCR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.RES_SULFUR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.RES_ANILINE_POINT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.RES_MASS_DENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.RES_NITROGEN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssaySheetRows.RES_CCR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
