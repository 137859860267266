import { DefaultBaseObjectFormGroupMapper } from './default-base-object-form-group.mapper';
import { BaseObject } from '../_models/base-object';
import { Hydrotreater } from '../_models/_unit-operations/hydrotreater';
import { DefaultMappedType } from '../_models/default-mapped-type';

export class HydrotreaterFormGroupMapper extends DefaultBaseObjectFormGroupMapper {
  override map(source: BaseObject, destination: BaseObject) {
    super.map(source, destination);

    const destinationHydrotreater = destination as Hydrotreater;
    const sourceHydrotreater = source as any as DefaultMappedType<Hydrotreater>;

    if (
      sourceHydrotreater.hydrogenUseRateVariables.length !== destinationHydrotreater.hydrogenUseRateVariables.length
    ) {
      throw new Error('Source and destination should have the same amount of hydrogen use rate variables!');
    }

    for (let i = 0; i < sourceHydrotreater.hydrogenUseRateVariables.length; i++) {
      destinationHydrotreater.hydrogenUseRateVariables[i].value = sourceHydrotreater.hydrogenUseRateVariables[i].value;
    }
  }

  override ignoreForAutoMapping(key: string, source: BaseObject, destination: BaseObject): boolean {
    return (
      super.ignoreForAutoMapping(key, source, destination) || (key as keyof Hydrotreater) === 'hydrogenUseRateVariables'
    );
  }
}
