import { Component, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { CoreService } from '../../_services/core.service';
import { FlowsheetTreeService } from '../../_services/sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { SubFlowsheetService } from '../../_services/sub-flowsheet/sub-flowsheet.service';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { FlowsheetTreeNode } from '../../_models/flowsheet-manager/flowsheet-tree-node';
import { UtilitiesSummaryReportComponent } from '../summary-report/utilities-summary-report/utilities-summary-report.component';
import { GhgUpgraderReportComponent } from './ghg-upgrader-report/ghg-upgrader-report.component';
import { FuelGasSummaryReportComponent } from '../summary-report/fuel-gas-summary-report/fuel-gas-summary-report.component';
import { MarginalValueEntry } from '../../_models/marginal-value-entry';
import { ConstraintRankingComponent } from './constraint-ranking/constraint-ranking.component';
import { isTypeUndefined } from '../../_utils/utils';
import { Case } from '../../_models';

export enum TabHref {
  STEAM_TAB = '#urSteam',
  FUEL_GAS_TAB = '#urFuelGas',
  CONSTRAINTS_TAB = '#urRanking',
}

export enum TabTooltipText {
  STEAM_TAB = 'Steam Make/Use',
  FUEL_GAS_TAB = 'Fuel Gas Make/Use',
  CONSTRAINTS_TAB = 'Optimization Variable',
}

@Component({
  selector: 'sob-upgrader-report',
  templateUrl: './upgrader-report.component.html',
  styleUrls: ['./upgrader-report.component.css'],
})
export class UpgraderReportComponent implements OnDestroy {
  @ViewChild(GhgUpgraderReportComponent) ghgReport: GhgUpgraderReportComponent;
  @ViewChild(UtilitiesSummaryReportComponent) utilitiesSummaryReport: UtilitiesSummaryReportComponent;
  @ViewChild(FuelGasSummaryReportComponent) fuelGasSummaryReport: FuelGasSummaryReportComponent;
  @ViewChild(ConstraintRankingComponent) upgraderConstraintRanking: ConstraintRankingComponent;

  topOwners: FlowsheetTreeNode[];
  selectedUpgrader: Upgrader;
  currentFlowsheetCategory: string;
  constraintRanking: MarginalValueEntry[] = [];
  private subSink = new SubSink();

  constructor(
    private flowsheet: FlowsheetService,
    private coreService: CoreService,
    private subFlowsheetService: SubFlowsheetService,
    private tree: FlowsheetTreeService,
    private ref: ChangeDetectorRef
  ) {
    this.subSink.add(
      this.coreService.caseChangedRequest.subscribe((data: any) => {
        if (data && data.solveSuccess) {
          this.buildReportsForCurrentSubFlowsheet();
        }
        this.constraintRanking = this.coreService.currentCase.marginalValueEntries;
      })
    );

    this.subSink.add(
      this.coreService.currentCaseReplacedRequest.subscribe(() => {
        if (this.coreService.currentCase.isSolved) {
          this.buildReportsForCurrentSubFlowsheet();
          this.constraintRanking = this.coreService.currentCase.marginalValueEntries;
        }
      })
    );

    this.subSink.add(
      this.flowsheet.subFlowsheetOpenedRequest.subscribe(() => {
        this.removeUpgrader();
        if (this.coreService.currentCase.isSolved) {
          this.buildReportsForCurrentSubFlowsheet();
        }
      })
    );

    this.subSink.add(
      this.flowsheet.subFlowsheetClosedRequest.subscribe(() => {
        this.removeUpgrader();

        if (this.coreService.currentCase.isSolved) {
          this.buildReportsForCurrentSubFlowsheet();
        }
      })
    );
  }

  buildReportsForCurrentSubFlowsheet() {
    this.ref.detectChanges();
    this.constraintRanking = this.coreService.currentCase.marginalValueEntries;
    this.currentFlowsheetCategory = this.subFlowsheetService.currentSubFlowsheetCategory;
    if (
      this.currentFlowsheetCategory === undefined ||
      this.currentFlowsheetCategory === unitOperationsConfig.extraction.key
    ) {
      this.getTopFlowsheetOwners();
      this.selectedUpgrader = null;
    }

    if (this.currentFlowsheetCategory === unitOperationsConfig.upgrader.key) {
      const id = this.coreService.currentCase.getActiveFlowsheet().unitOperationId;
      const upgrader = this.coreService.currentCase.getUnitOperation(id) as Upgrader;
      this.openUpgrader(upgrader);
    }
    if (
      this.currentFlowsheetCategory === unitOperationsConfig.waterUtilityUnitOperation.key ||
      this.currentFlowsheetCategory === unitOperationsConfig.fuelGasUtilityUnitOperation.key
    ) {
      const currentOwnerId = this.coreService.currentCase.getActiveFlowsheet().unitOperationId;
      const unitsOwners = this.getAllFlowsheetOwnersIncludeNormalUpgraders(this.coreService.currentCase);
      const owner = unitsOwners.filter(uw => {
        return uw.id === currentOwnerId;
      });
      this.selectedUpgrader = this.coreService.currentCase.getUnitOperation(owner[0].flowsheetId) as Upgrader;
      this.buildReports();
    }
  }

  getTopFlowsheetOwners() {
    const tree = this.tree.getFlowsheetTreeIncludeNormalUpgraders(this.coreService.currentCase);
    this.topOwners = tree.root.children;
  }

  openUpgrader(up?) {
    if (up) {
      this.selectedUpgrader = up;
      this.buildReports();
    } else {
      this.buildReports();
    }
  }

  removeUpgrader() {
    this.selectedUpgrader = null;
    this.constraintRanking = this.coreService.currentCase.marginalValueEntries;
  }

  buildReports() {
    this.ref.detectChanges();
    this.ghgReport.buildReport(this.selectedUpgrader);

    this.utilitiesSummaryReport.buildReport(this.selectedUpgrader.id);
    this.disableEmptyReportTab(
      this.detectReportWithData(this.utilitiesSummaryReport),
      TabHref.STEAM_TAB,
      TabTooltipText.STEAM_TAB
    );

    this.fuelGasSummaryReport.buildReport(this.selectedUpgrader.id);
    this.disableEmptyReportTab(
      this.detectReportWithData(this.fuelGasSummaryReport),
      TabHref.FUEL_GAS_TAB,
      TabTooltipText.FUEL_GAS_TAB
    );

    this.buildConstraintRanking();
    $('#urGhgReport').tab('show');
  }

  buildConstraintRanking() {
    const currentOwner = this.coreService.currentCase.getActiveFlowsheet().unitOperationId;
    if (isTypeUndefined(currentOwner)) {
      this.constraintRanking = this.constraintRanking.filter((cr: MarginalValueEntry) => {
        return cr.ownerOperationId === this.selectedUpgrader.id;
      });
      this.upgraderConstraintRanking.buildConstraintRankingGraphics(this.constraintRanking);
    } else {
      this.constraintRanking = this.constraintRanking.filter((cr: MarginalValueEntry) => {
        return cr.ownerOperationId === currentOwner;
      });
      this.upgraderConstraintRanking.buildConstraintRankingGraphics(this.constraintRanking);
    }

    this.disableEmptyReportTab(this.constraintRanking, TabHref.CONSTRAINTS_TAB, TabTooltipText.CONSTRAINTS_TAB);
  }

  getAllFlowsheetOwnersIncludeNormalUpgraders(c: Case) {
    return c.filterUnitOperations(uo => uo.isFlowsheetOwner || uo instanceof Upgrader);
  }

  disableEmptyReportTab(reportData: any, tabHref: string, tooltipText: string) {
    const element = $(`a[href*="${tabHref}"]`);
    if (reportData.length > 0 || reportData > 0) {
      element.attr('data-toggle', 'tab');
      element.removeAttr('title');
    } else {
      element.attr('data-toggle', 'tooltip');
      element.attr('title', `Upgrader "${this.selectedUpgrader.name}" does not report any ${tooltipText}`);
      element.removeAttr('href');
    }
  }

  detectReportWithData(report: UtilitiesSummaryReportComponent | FuelGasSummaryReportComponent) {
    let reportsWithData = 0;
    for (const property in report) {
      if (property.includes('ReportData') && report[property].reportItems.length !== 0) {
        reportsWithData++;
      }
    }
    return reportsWithData;
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
