import { Mapper } from '../../../../_models/_interfaces/mapper';
import { Refinery } from '../../../../_models/_unit-operations/refinery';
import { SimulationVariable } from '../../../../_models';
import { StringToSimulationVariableMapper } from '../string-to-simulation-variable-mapper';

export enum RefineryExcelIndexes {
  LIGHT_ENDS_MARGIN = 5,
  NAPHTHA_MARGIN = 6,
  DISTILLATE_MARGIN = 7,
  GAS_OIL_MARGIN = 8,
  VACUUM_RESIDUE_MARGIN = 9,
  CAPACITY = 10,
  CAPEX_AMORTIZED = 13,
  OPEX_FIXED = 14,
  OPEX_VAR = 15,
  OPEX_GAS_DIESEL = 16,
  OPEX_POWER = 17,
}

export class ArrayToRefineryMapper implements Mapper<string[], Refinery> {
  private readonly simulationVariableMapper: Mapper<string, SimulationVariable>;

  constructor() {
    this.simulationVariableMapper = new StringToSimulationVariableMapper();
  }

  map(source: string[], destination: Refinery) {
    this.simulationVariableMapper.map(source[RefineryExcelIndexes.LIGHT_ENDS_MARGIN], destination.lightEndsMargin);
    this.simulationVariableMapper.map(source[RefineryExcelIndexes.NAPHTHA_MARGIN], destination.naphthaMargin);
    this.simulationVariableMapper.map(source[RefineryExcelIndexes.DISTILLATE_MARGIN], destination.distillateMargin);
    this.simulationVariableMapper.map(source[RefineryExcelIndexes.GAS_OIL_MARGIN], destination.gasOilMargin);
    this.simulationVariableMapper.map(
      source[RefineryExcelIndexes.VACUUM_RESIDUE_MARGIN],
      destination.vacuumResidueMargin
    );
    this.simulationVariableMapper.map(source[RefineryExcelIndexes.CAPACITY], destination.capacity);

    this.simulationVariableMapper.map(source[RefineryExcelIndexes.CAPEX_AMORTIZED], destination.capexAmortized);
    this.simulationVariableMapper.map(source[RefineryExcelIndexes.OPEX_FIXED], destination.opexFixed);
    this.simulationVariableMapper.map(source[RefineryExcelIndexes.OPEX_VAR], destination.opexVar);
    this.simulationVariableMapper.map(source[RefineryExcelIndexes.OPEX_GAS_DIESEL], destination.opexGasDiesel);
    this.simulationVariableMapper.map(source[RefineryExcelIndexes.OPEX_POWER], destination.opexPower);
  }
}
