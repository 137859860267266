import { SimulationVariable } from '../simulation-variable';
import { ConstraintValueChecker } from '../_interfaces/constraint-value-checker';

export class SuncorConstraint implements ConstraintValueChecker {
  constraint: SimulationVariable;
  tolerance = 0.01;
  constructor(constraint: SimulationVariable) {
    this.constraint = constraint;
    this.constraint.isActive = this.constraint.isActive || false;
  }

  constraintValueDefined(): boolean {
    return (
      typeof this.constraint.value !== 'undefined' &&
      `${this.constraint.value}` !== '' &&
      this.constraint.value !== null &&
      !isNaN(this.constraint.value)
    );
  }

  constraintValueViolated(referenceValue: number): boolean {
    return this.constraintValueDefined()
      ? (referenceValue - this.constraint.value) / this.constraint.value > this.tolerance
      : false;
  }
}
