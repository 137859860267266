import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

import { CoreService } from '../../_services/core.service';
import { GlobalKpiService } from '../../_services/global-kpi.service';
import { SimulationVariable } from '../../_models';

@Component({
  selector: 'sob-global-kpi',
  templateUrl: './global-kpi.component.html',
  styleUrls: ['./global-kpi.component.css'],
})
export class GlobalKpiComponent implements OnInit {
  openingKpiManager: boolean;
  kpiId: string;
  globalKpis: { id: string; name: string }[] = [];
  chartType = 'column';

  constructor(private coreService: CoreService, private globalKpiService: GlobalKpiService) {}

  ngOnInit() {
    const $modal = $('#globalKpiModal');

    $modal.on('shown.bs.modal', () => {
      this.initData();
    });

    $modal.on('hidden.bs.modal', () => {
      if (this.openingKpiManager) {
        this.openingKpiManager = false;
        // need to open the new modal when old modal hide animation is completed
        $('#kpiManagerModal').modal('show');
      }
    });
  }

  initData() {
    this.destroyChart();

    this.globalKpis = this.globalKpiService.getGlobalKpiNames(this.coreService.project);

    if (this.globalKpis.length) {
      this.kpiId = this.globalKpis[0].id;
      this.displayKpi(this.kpiId);
    }
  }

  switchChartType() {
    this.chartType = this.chartType === 'column' ? 'line' : 'column';
    this.displayKpi(this.kpiId);
  }

  private destroyChart() {
    const $chart = $('#globalKpiChart');

    if ($chart.highcharts()) {
      $chart.highcharts().destroy();
    }
  }

  displayKpi(kpiId: string) {
    const kpis = this.globalKpiService.getGlobalKpi(kpiId, this.coreService.project);

    if (!kpis.length) {
      return;
    }

    const xLabels = [];
    const chartData = [];

    for (const kpi of kpis) {
      xLabels.push(kpi.ownerCase.name);
      chartData.push(kpi.convertToDefaultUnit(true, false));
    }

    this.buildChart(kpis, chartData, xLabels);
  }

  buildChart(kpis: SimulationVariable[], chartData: number[], xLabels: string[]) {
    Highcharts.chart({
      chart: {
        renderTo: 'globalKpiChart',
        type: this.chartType,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: xLabels,
      },
      yAxis: {
        title: {
          text: `${kpis[0].getFullName()} (${kpis[0].getDefaultUnit()})`,
        },
      },
      tooltip: {
        headerFormat: '<table>',
        pointFormatter() {
          return (
            `<tr><td style="color:${this.color};padding:0">${kpis[this.index].getFullName()}: </td>` +
            `<td style="padding-left:2px"><b>${Highcharts.numberFormat(this.y, 2, '.', ',')}</b></td></tr>`
          );
        },
        footerFormat: '<tr></table>',
        shared: true,
        useHTML: true,
        followPointer: true,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
            color: 'white',
          },
        },
      },
      series: [
        {
          data: chartData,
          color: '#1034a6',
        },
      ],
    });
  }

  kpiChanged($event) {
    this.kpiId = $event.target.value;
    this.displayKpi(this.kpiId);
  }

  openKpiManager() {
    this.openingKpiManager = true;
    $('#globalKpiModal').modal('hide');
  }
}
