import { BaseObject } from '../base-object';
import { Case } from '../case';

export const objectiveFunctionType = {
  Cost: {
    displayName: 'Maximize value',
    id: 'Cost',
  },
  GHG: {
    displayName: 'Minimize GHG',
    id: 'GHG',
  },
};

export class ObjectiveFunction extends BaseObject {
  category = 'objectiveFunction';

  objectiveFunctionType: string;

  constructor(objectiveFunction: any, ownerCase: Case) {
    super(objectiveFunction.id, ownerCase);

    this.initValues(objectiveFunction);
  }

  initValues(objectiveFunction: any) {
    this.objectiveFunctionType = objectiveFunction.objectiveFunctionType || objectiveFunctionType.Cost.id;
  }

  get objectiveFunctionName() {
    return objectiveFunctionType[this.objectiveFunctionType].displayName;
  }

  // no dePersist needed so far, no child simulationVariables...

  public toJSON() {
    return {
      id: this.id,
      category: this.category,
      objectiveFunctionType: this.objectiveFunctionType,
    };
  }
}
