import { BaseObject } from '../base-object';
import { ParametricStudyKpiResult } from './parametric-study-kpi-result';
import { Case } from '../case';
import { SimulationVariable } from '../simulation-variable';
import { ParametricStudyParameter } from './parametric-study-parameter';

export class ParametricStudy extends BaseObject {
  category = 'parametricStudy';

  parameter: ParametricStudyParameter;
  kpiResults: ParametricStudyKpiResult[] = [];

  constructor(parametricStudy: any, ownerCase: Case) {
    super(parametricStudy.id, ownerCase);
    this.name = parametricStudy.name || '';
  }

  clearResults() {
    this.kpiResults = [];
  }

  private removeParameterBySimulationVariable(sv: SimulationVariable) {
    if (this.parameter && sv === this.parameter.simulationVariable) {
      this.parameter = undefined;
      this.clearResults();
    }
  }

  private removeResultByKpi(kpi: SimulationVariable) {
    const i = this.kpiResults.findIndex(res => res.kpi.id === kpi.id);
    if (i > -1) {
      this.kpiResults.splice(i, 1);
    }
  }

  setParameterBySimulationVariableId(id: string): ParametricStudyParameter {
    const sv = this.ownerCase.getSimulationVariable(id);
    if (sv) {
      const p = new ParametricStudyParameter({ simulationVariable: sv }, this.ownerCase);
      this.setParameter(p);
      return p;
    }

    return null;
  }

  setParameter(parameter: ParametricStudyParameter) {
    if (this.parameter) {
      this.ownerCase.removeFromPools(this.parameter);
    }

    this.parameter = parameter;
    this.ownerCase.addToPools(parameter);
  }

  removeParameter() {
    if (this.parameter) {
      this.ownerCase.removeFromPools(this.parameter);
      this.parameter = undefined;
    }
  }

  override convertToInternalUnit() {
    super.convertToInternalUnit();

    if (this.parameter) {
      this.parameter.convertToInternalUnit();
    }
  }

  convertToCaptureUnit() {
    if (this.parameter) {
      this.parameter.convertToCaptureUnit();
    }
  }

  subscribeToOwnerCaseEvents() {
    this.ownerCase.simulationVariableDeletedRequest.subscribe(sv => {
      this.removeParameterBySimulationVariable(sv);
    });

    this.ownerCase.kpiDeletedRequest.subscribe(kpi => this.removeResultByKpi(kpi));
  }

  override dePersist(persistedObj: any) {
    this.parameter = this.ownerCase.getOtherBaseObject(persistedObj.parameter) as ParametricStudyParameter;
    this.dePersistResults(persistedObj);
  }

  dePersistResults(persistedObj: any) {
    if (persistedObj.kpiResults instanceof Array) {
      for (const kpiResult of persistedObj.kpiResults) {
        kpiResult.kpi = this.ownerCase.getSimulationVariable(kpiResult.kpi);
        this.kpiResults.push(new ParametricStudyKpiResult(kpiResult));
      }
    }
  }

  public toJSON() {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      parameter: this.parameter ? this.parameter.id : '',
      kpiResults: this.kpiResults.map(res => res),
    };
  }
}
