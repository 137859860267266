import { IUnitOperation } from '../i-unit-operation';
import { SimulationVariable } from '../simulation-variable';
import { UnitOperation } from '../unit-operation';
import { Quantity } from '../../_config/quantity.enum';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';

declare let unitConverter: any;

export abstract class SuncorUnitOperation extends UnitOperation implements IUnitOperation {
  capacity: SimulationVariable;
  // yield: SimulationVariable; No longer seen
  ghgEmissions: SimulationVariable;
  ghgIntensity: SimulationVariable;
  minimumFlow: SimulationVariable;
  unitValue: SimulationVariable;
  revenue: SimulationVariable;
  electricityPrice: SimulationVariable;

  // OPEX/CAPEX
  opexFixed: SimulationVariable;
  opexVar: SimulationVariable;
  opexGasDiesel: SimulationVariable;
  opexPower: SimulationVariable;
  opexCarbonDioxide: SimulationVariable;
  opexTotal: SimulationVariable;

  capexAmortized: SimulationVariable;
  capexTotal: SimulationVariable;

  inletCutMassFlows: number[] = [];
  outletCutMassFlows: number[] = [];

  override initValues(unitOperation: any): void {
    this.unitValue = new SimulationVariable(unitOperation.unitValue, this.ownerCase, this);
    this.revenue = new SimulationVariable(unitOperation.revenue, this.ownerCase, this);
    this.electricityPrice = new SimulationVariable(unitOperation.electricityPrice, this.ownerCase, this);

    this.opexFixed = new SimulationVariable(unitOperation.opexFixed, this.ownerCase, this);
    this.opexVar = new SimulationVariable(unitOperation.opexVar, this.ownerCase, this);
    this.opexGasDiesel = new SimulationVariable(unitOperation.opexGasDiesel, this.ownerCase, this);
    this.opexPower = new SimulationVariable(unitOperation.opexPower, this.ownerCase, this);
    this.opexCarbonDioxide = new SimulationVariable(unitOperation.opexCarbonDioxide, this.ownerCase, this);
    this.opexTotal = new SimulationVariable(unitOperation.opexTotal, this.ownerCase, this);
    this.capexAmortized = new SimulationVariable(unitOperation.capexAmortized, this.ownerCase, this);
    this.capexTotal = new SimulationVariable(unitOperation.capexTotal, this.ownerCase, this);
    this.capacity = new SimulationVariable(unitOperation.capacity, this.ownerCase, this);
    this.minimumFlow = new SimulationVariable(unitOperation.minimumFlow, this.ownerCase, this);
    // this.yield = new SimulationVariable(unitOperation.yield, this.ownerCase, this);
    this.ghgEmissions = new SimulationVariable(unitOperation.ghgEmissions, this.ownerCase, this);
    this.ghgIntensity = new SimulationVariable(unitOperation.ghgIntensity, this.ownerCase, this);
    this.inletCutMassFlows = unitOperation.inletCutMassFlows || [];
    this.outletCutMassFlows = unitOperation.outletCutMassFlows || [];
  }

  override setDefaultValues(): void {
    if (this.opexFixed.isUndefined()) {
      this.opexFixed.setDefaultValue(0.0);
    }
    if (this.opexVar.isUndefined()) {
      this.opexVar.setDefaultValue(0.0);
    }
    if (this.opexGasDiesel.isUndefined()) {
      this.opexGasDiesel.setDefaultValue(0.0);
    }
    if (this.opexPower.isUndefined()) {
      this.opexPower.setDefaultValue(0.0);
    }
    if (this.opexCarbonDioxide.isUndefined()) {
      this.opexCarbonDioxide.setDefaultValue(0.0);
    }
    if (this.opexTotal.isUndefined()) {
      this.opexTotal.setDefaultValue(0.0);
    }

    if (this.capexAmortized.isUndefined()) {
      this.capexAmortized.setDefaultValue(0.0);
    }

    if (this.capexTotal.isUndefined()) {
      this.capexTotal.setDefaultValue(0.0);
    }

    if (this.unitValue.isUndefined()) {
      this.unitValue.setDefaultValue(0.0);
    }
    if (this.revenue.isUndefined()) {
      this.revenue.setDefaultValue(0.0);
    }
    if (this.electricityPrice.isUndefined()) {
      this.electricityPrice.setDefaultValue(45.0);
    }

    // if (this.yield.isUndefined()) {
    //   this.yield.setDefaultValue(0.0);
    // }

    if (this.ghgEmissions.isUndefined()) {
      this.ghgEmissions.setDefaultValue(0.0, unitConverter.units.Massflowrate.MMTPA);
    }

    if (this.ghgIntensity.isUndefined()) {
      this.ghgIntensity.setDefaultValue(0.0, unitConverter.units.MassPerVolume.KG_BBL);
    }
  }

  override setQuantities() {
    this.unitValue.setQuantity(Quantity.CURRENCY_PER_TIME);
    this.revenue.setQuantity(Quantity.CURRENCY_PER_TIME);
    this.electricityPrice.setQuantity(Quantity.CURRENCY_PER_ELECTRICITY);
    this.opexFixed.setQuantity(Quantity.CURRENCY_PER_TIME);
    this.opexVar.setQuantity(Quantity.CURRENCY_PER_VOLUME);
    this.opexGasDiesel.setQuantity(Quantity.CURRENCY_PER_VOLUME);
    this.opexPower.setQuantity(Quantity.CURRENCY_PER_VOLUME);
    this.opexCarbonDioxide.setQuantity(Quantity.CURRENCY_PER_VOLUME);
    this.opexTotal.setQuantity(Quantity.CURRENCY_PER_TIME);
    this.capexAmortized.setQuantity(Quantity.CURRENCY_PER_TIME);
    this.capexTotal.setQuantity(Quantity.CURRENCY_PER_TIME);
    this.capacity.setQuantity(Quantity.VOLUMETRIC_FLOWRATE);
    this.minimumFlow.setQuantity(Quantity.VOLUMETRIC_FLOWRATE);
    // this.yield.setQuantity('Percent');
    this.ghgEmissions.setQuantity(Quantity.MASSFLOWRATE);
    this.ghgIntensity.setQuantity(Quantity.MASS_PER_VOLUME);
  }

  override setSimulationVariableNames() {
    this.unitValue.setName('Unit Value');
    this.revenue.setName('Revenue');
    this.electricityPrice.setName('Electricity Price');
    this.opexFixed.setName('OPEX Fixed');
    this.opexVar.setName('OPEX Var');
    this.opexGasDiesel.setName('OPEX Gas Diesel');
    this.opexPower.setName('OPEX Power');
    this.opexCarbonDioxide.setName('OPEX CO2');
    this.opexTotal.setName('OPEX Total');
    this.capexTotal.setName('CAPEX Total');
    this.capacity.setName(SimulationVariableName.MAXIMUM_FEED_CAPACITY);
    this.minimumFlow.setName(SimulationVariableName.MINIMUM_FLOW);
    this.capexAmortized.setName('CAPEX Amortized');
    // this.yield.setName('Yield');
    this.ghgEmissions.setName('GHG Emissions');
    this.ghgIntensity.setName('GHG Intensity');
  }

  override dePersist(serializedUnitOperation: any) {
    this.location = { x: +serializedUnitOperation.location.x, y: +serializedUnitOperation.location.y };
    this.unitValue = this.getSimulationVariableSafe(serializedUnitOperation.unitValue);
    this.revenue = this.getSimulationVariableSafe(serializedUnitOperation.revenue);
    this.electricityPrice = this.getSimulationVariableSafe(serializedUnitOperation.electricityPrice);
    this.opexFixed = this.getSimulationVariableSafe(serializedUnitOperation.opexFixed);
    this.opexVar = this.getSimulationVariableSafe(serializedUnitOperation.opexVar);
    this.opexGasDiesel = this.getSimulationVariableSafe(serializedUnitOperation.opexGasDiesel);
    this.opexPower = this.getSimulationVariableSafe(serializedUnitOperation.opexPower);
    this.opexCarbonDioxide = this.getSimulationVariableSafe(serializedUnitOperation.opexCarbonDioxide);
    this.opexTotal = this.getSimulationVariableSafe(serializedUnitOperation.opexTotal);
    this.capexAmortized = this.getSimulationVariableSafe(serializedUnitOperation.capexAmortized);
    this.capexTotal = this.getSimulationVariableSafe(serializedUnitOperation.capexTotal);
    this.capacity = this.getSimulationVariableSafe(serializedUnitOperation.capacity);
    this.minimumFlow = this.getSimulationVariableSafe(serializedUnitOperation.minimumFlow);
    this.ghgEmissions = this.getSimulationVariableSafe(serializedUnitOperation.ghgEmissions);
    this.ghgIntensity = this.getSimulationVariableSafe(serializedUnitOperation.ghgIntensity);
    this.setQuantities();
  }

  /**
   * For getting the GHG intensity to be displayed in the summary report charts
   * @returns {number}
   */
  abstract getAlternativeGhgIntensity(): SimulationVariable;

  override clearResults() {
    super.clearResults();
    this.ghgEmissions.value = undefined;
    this.opexCarbonDioxide.value = undefined;
    this.opexTotal.value = undefined;
    this.capexTotal.value = undefined;
  }
}
