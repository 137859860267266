<div class="modal fade" id="fuelGasStreamModal" tabindex="-1" role="dialog" aria-labelledby="fuelGasStreamModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="fuelGasStreamModalLabel">Stream Information</h4>
      </div>

      <div class="modal-body" *ngIf="stream">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#fuelGasStreamGeneralTab" role="tab" data-toggle="tab" aria-controls="fuelGasStreamGeneralTab">
              Model
            </a>
          </li>

          <li role="presentation">
            <a
              href="#fuelGasStreamCompositionTab"
              role="tab"
              data-toggle="tab"
              aria-controls="fuelGasStreamCompositionTab">
              Composition
            </a>
          </li>
        </ul>

        <div class="tab-content pw-margin tab-content-padding">
          <div role="tabpanel" class="tab-pane fade in active" id="fuelGasStreamGeneralTab">
            <div class="form-horizontal">
              <div class="form-group" [formGroup]="streamGroup">
                <label for="fuelGasStreamName" class="control-label col-sm-4"> Name </label>

                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-6">
                      <input type="text" class="form-control" id="fuelGasStreamName" formControlName="name" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <span
                        class="text-danger"
                        *ngIf="nameControl.invalid && (nameControl.dirty || nameControl.touched)">
                        <span *ngIf="nameControl.errors.required">Please enter a name for the stream</span>
                        <span *ngIf="nameControl.errors.unique">Entered name is already in use</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="pw-label control-label col-sm-4">From</label>
                <span style="padding-top: 7px; display: inline-block" class="col-sm-4">
                  {{ stream.inletUnitOperation.name }}
                </span>
              </div>

              <div class="form-group">
                <label class="pw-label control-label col-sm-4">To</label>
                <span style="padding-top: 7px; display: inline-block" class="col-sm-4">
                  {{ stream.outletUnitOperation.name }}
                </span>
              </div>

              <br />

              <ng-container [formGroup]="streamGroup">
                <div class="form-group" formGroupName="flowrate">
                  <label class="control-label col-sm-4"> Fuel Gas Flowrate </label>

                  <div class="col-sm-8" sobReactiveSimVarInput>
                    <div class="row">
                      <div class="col-xs-6">
                        <sob-reactive-unit-select formControlName="unit" [quantity]="stream.flowrate.quantity">
                        </sob-reactive-unit-select>
                      </div>
                      <div class="col-xs-6">
                        <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="stream.flowrate">
                        </sob-reactive-variable-value-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group" formGroupName="heatFlow">
                  <label class="control-label col-sm-4"> Heat Flow (LHV * Flow) </label>

                  <div class="col-sm-8" sobReactiveSimVarInput>
                    <div class="row">
                      <div class="col-xs-6">
                        <sob-reactive-unit-select formControlName="unit" [quantity]="stream.heatFlow.quantity">
                        </sob-reactive-unit-select>
                      </div>
                      <div class="col-xs-6">
                        <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="stream.heatFlow">
                        </sob-reactive-variable-value-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group" formGroupName="lhv">
                  <label class="control-label col-sm-4"> Lower Heating Value </label>

                  <div class="col-sm-8" sobReactiveSimVarInput>
                    <div class="row">
                      <div class="col-xs-6">
                        <sob-reactive-unit-select formControlName="unit" [quantity]="stream.lhv.quantity">
                        </sob-reactive-unit-select>
                      </div>
                      <div class="col-xs-6">
                        <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="stream.lhv">
                        </sob-reactive-variable-value-input>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="fuelGasStreamCompositionTab">
            <div class="form-horizontal" [formGroup]="streamGroup">
              <ng-container sobReactiveMultipleVariableInput>
                <div class="form-group">
                  <div class="row">
                    <label class="control-label col-sm-4"> Unit </label>
                    <div class="col-sm-4">
                      <sob-reactive-unit-select
                        formControlName="compositionUnit"
                        [quantity]="stream.composition[0].quantity">
                      </sob-reactive-unit-select>
                    </div>
                  </div>
                </div>

                <ng-container formArrayName="composition">
                  <div class="form-group" *ngFor="let sv of stream.composition; let i = index">
                    <div class="row">
                      <label class="control-label col-sm-4">
                        {{ stream.composition[i].name }}
                      </label>
                      <div class="col-sm-4">
                        <sob-reactive-variable-value-input [formControlName]="i" [valueMetaData]="sv">
                        </sob-reactive-variable-value-input>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
