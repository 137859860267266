import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { FlexSheetValueConverter } from '../flex-sheet-value-converter';
import { SuncorUnitOperation } from '../../../_models/_unit-operations/suncor-unit-operation';
import { enumValuesToArray } from '../../../_utils/enum-values-to-array';
import { h2sCellConfig, H2sSheetColumns } from '../cell-config/h2s-production-cell-config';
import { NumberToSimulationVariableMapper } from '../mappers/number-to-simulation-variable-mapper';
import { H2sFlexSheetMapper } from '../mappers/h2s-flex-sheet-mapper';
import { Hydrotreater } from '../../../_models/_unit-operations/hydrotreater';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { VAC } from '../../../_models/_unit-operations/vac';
import { DCU } from '../../../_models/_unit-operations/dcu';

export class H2sProductionSheetEventHandler {
  constructor(private valueConverter: FlexSheetValueConverter) {}

  handle(
    unitOperation: Hydrotreater | Hydrocracker | VAC | DCU,
    spreadsheet: wjcSheet.FlexSheet,
    flexsheetEvent,
    rawValue
  ) {
    const column = flexsheetEvent.col;
    if (enumValuesToArray(H2sSheetColumns).indexOf(column) === -1 || column === H2sSheetColumns.H2S_PRODUCTION) {
      flexsheetEvent.cancel = true;
      return;
    }
    const cellConfig = h2sCellConfig.find(rowConfig => rowConfig.row === column);
    const convertedValue = this.valueConverter.convert(spreadsheet, rawValue, cellConfig);
    const simulationVariableMapper = new NumberToSimulationVariableMapper();
    const mapper = new H2sFlexSheetMapper(simulationVariableMapper);
    mapper.map(unitOperation, column, convertedValue);
  }
}
