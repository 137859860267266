import { DefaultBaseObjectFormGroupMapper } from './default-base-object-form-group.mapper';
import { BaseObject } from '../_models/base-object';
import { WaterSource } from '../_models/_unit-operations/utilties/water-source';
import { hasNumericValue } from '../_utils/utils';
import { VariableStatus } from '../_config/variable-status.enum';

export class WaterSourceFormGroupMapper extends DefaultBaseObjectFormGroupMapper {
  override map(source: BaseObject, destination: BaseObject) {
    super.map(source, destination);

    const waterSource = destination as WaterSource;
    const simVars = [waterSource.temperature, waterSource.pressure, waterSource.vaporFraction];
    const solverCalculatedVariables = simVars.filter(sv => !hasNumericValue(sv.value));
    const userSpecifiedVariables = simVars.filter(sv => hasNumericValue(sv.value));

    if (userSpecifiedVariables.length / userSpecifiedVariables.length === solverCalculatedVariables.length) {
      solverCalculatedVariables.forEach(sv => {
        sv.variableStatus = VariableStatus.SOLVER_CALCULATED;
      });

      userSpecifiedVariables.forEach(sv => {
        sv.variableStatus = VariableStatus.USER_SPECIFIED;
      });
    }
  }
}
