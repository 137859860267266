import { Pipe, PipeTransform } from '@angular/core';
import { UnitOperation } from '../_models';

@Pipe({
  name: 'unitOperationFilter',
})
export class UnitOperationFilterPipePipe implements PipeTransform {
  transform(unitOperations: UnitOperation[], searchTerm: string): any[] {
    if (!unitOperations) {
      return null;
    }
    if (!searchTerm) {
      return unitOperations;
    }
    searchTerm = searchTerm.toLowerCase();
    return unitOperations.filter(uo => {
      return uo.name.toLowerCase().includes(searchTerm);
    });
  }
}
