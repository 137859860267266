import { Component, Input } from '@angular/core';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { UnitOperation } from '../../_models';
import { SubFlowsheetService } from '../../_services/sub-flowsheet/sub-flowsheet.service';

@Component({
  selector: 'sob-sub-flowsheet-info',
  templateUrl: './sub-flowsheet-info.component.html',
  styleUrls: ['./sub-flowsheet-info.component.css'],
})
export class SubFlowsheetInfoComponent {
  @Input() activeFlowsheetOwner: UnitOperation;
  constructor(private flowsheetService: FlowsheetService, private subFlowsheetService: SubFlowsheetService) {}

  closeSubFlowsheet() {
    this.flowsheetService.closeSubFlowsheet();
  }

  get subFlowsheetOwnerNames(): string[] {
    return this.subFlowsheetService.getSubFlowsheetOwnerNames();
  }
}
