import { AfterViewInit, Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConstraintsReport } from '../../_models/_reports/_optimization-settings-table/constraints-report';
import { OptimizationVariablesReport } from '../../_models/_reports/_optimization-settings-table/optimization-variables-report';
import { ReportService } from '../../_services/report.service';
import { CoreService } from '../../_services/core.service';
import { Splitter } from '../../_models/_unit-operations/splitter';
import { DiluentSource } from '../../_models/_unit-operations/diluent-source';
import { ThirdPartySource } from '../../_models/_unit-operations/third-party-source';
import { ProductTank } from '../../_models/product-tank';
import { SubFlowsheetService } from '../../_services/sub-flowsheet/sub-flowsheet.service';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { MixingConstraintReportVariable } from '../../_models/_reports/_optimization-settings-table/mixing-constraint-report-variable';
import { Mixer } from '../../_models/_unit-operations/mixer';
import { UnitOperation } from '../../_models';

declare let unitConverter: any;

@Component({
  selector: 'sob-optimization-settings-table',
  templateUrl: './optimization-settings-table.component.html',
  styleUrls: ['./optimization-settings-table.component.css'],
})
export class OptimizationSettingsTableComponent implements AfterViewInit {
  optimizationVariablesReport: OptimizationVariablesReport;
  constraintsReport: ConstraintsReport;
  selectedFlowsheet: string;

  constructor(
    private toastr: ToastrService,
    private reportService: ReportService,
    private coreService: CoreService,
    private subFlowsheetService: SubFlowsheetService
  ) {
    this.coreService.caseChangedRequest.subscribe((data: any) => {
      console.log(data);
      if (data && !data.solveSuccess && data.isCaseReady) {
        this.toastr.error('Constraint violation detected');
        this.init();
        $('#optimizationSettingsTableModal').modal('show');
      }
    });
  }

  ngAfterViewInit() {
    this.init();
  }

  init(flowsheetOwnerId?: string) {
    if (flowsheetOwnerId || flowsheetOwnerId === '') {
      this.buildReports(flowsheetOwnerId);
    } else {
      this.buildReports();
    }

    const $modal = $('#optimizationSettingsTableModal');

    $modal.on('show.bs.modal', () => {
      flowsheetOwnerId = null;
      this.selectedFlowsheet = null;
      this.buildReports();
    });

    $modal.on('change', '.optimization-checkbox, .isActive-checkbox', e => {
      const $target = $(e.currentTarget);
      this.optimizableChanged($target.data('id'), $target.is(':checked'));
      this.isActiveChanged($target.data('id'), $target.is(':checked'));
    });

    $('.optimization-checkbox').on('checked', e => {
      const $target = $(e.currentTarget);
      this.optimizableChanged($target.data('id'), $target.is(':checked'));
    });
    $('.isActive-checkbox').on('checked', e => {
      const $target = $(e.currentTarget);
      this.isActiveChanged($target.data('id'), $target.is(':checked'));
    });
    $modal.on('hidden.bs.modal', () => {
      this.saveOptimizationVariables();
      // this.saveIsActive();
    });
  }

  get getActiveFlowsheetTitle() {
    switch (this.subFlowsheetService.currentSubFlowsheetCategory) {
      case unitOperationsConfig.upgrader.key:
        return 'Upgrader Optimization Settings Table';
      case unitOperationsConfig.extraction.key:
        return 'Extraction Optimization Settings Table';
      case unitOperationsConfig.waterUtilityUnitOperation.key:
        return 'Steam & Power Optimization Settings Table';
      case unitOperationsConfig.fuelGasUtilityUnitOperation.key:
        return 'Fuel Gas Optimization Settings Table';
      default:
        return 'Optimization Settings Table';
    }
  }

  getFlowsheetOwnerId(fOwnerId: string) {
    this.init(fOwnerId);
  }

  optimizableChanged(id, checked) {
    $('#ostOptimizationVariables')
      .find(`input[type=checkbox][data-id="${id}"]`)
      .each((i, el) => {
        $(el).prop('checked', checked);
        this.saveOptimizationVariable(id, checked);
      });
  }

  isActiveChanged(id, checked) {
    $('#ostConstraintsTable')
      .find(`input[type=checkbox][data-id="${id}"]`)
      .each((i, el) => {
        $(el).prop('checked', checked);
        const simvar = this.coreService.currentCase.getSimulationVariable(id);
        if (simvar) {
          simvar.setIsActive(checked);
        } else {
          const constraintReportVar = this.constraintsReport.constraints.find(
            (cstr: MixingConstraintReportVariable) => cstr.id === id
          );

          const unitOperationOwner = this.coreService.currentCase.getUnitOperation(constraintReportVar.unitOperationId);
          if (unitOperationOwner) {
            if (this.isRangeConstraintsUser(unitOperationOwner)) {
              unitOperationOwner.constraints.constraints[id].isActive = checked;
            }
          }
        }
      });
  }

  buildReports(flowsheetOwnerId?: string) {
    if (flowsheetOwnerId || flowsheetOwnerId === '') {
      this.optimizationVariablesReport = this.reportService.buildOptimizationVariablesReport(flowsheetOwnerId);
      this.constraintsReport = this.reportService.buildConstraintsReport(flowsheetOwnerId);
    } else {
      this.optimizationVariablesReport = this.reportService.buildOptimizationVariablesReport(
        this.subFlowsheetService.currentSubFlowsheetId
      );
      this.constraintsReport = this.reportService.buildConstraintsReport(
        this.subFlowsheetService.currentSubFlowsheetId
      );
    }

    this.buildOptimizationVariablesTable();
    this.buildConstraintsTable();
  }

  buildOptimizationVariablesTable() {
    const $table = $('#ostOptimizationVariablesTable');

    if ((<any>$.fn).dataTable.isDataTable('#ostOptimizationVariablesTable')) {
      (<any>$table).DataTable().destroy();
    }

    const opts = {
      data: this.optimizationVariablesReport.optimizationTableVariables,
      paging: true,
      searching: true,
      info: false,
      order: [[0, 'desc']],
      columns: [
        {
          data: (data, type) => {
            if (type === 'display') {
              return `<input type="checkbox" class="optimization-checkbox"
                ${this.isReadOnly ? 'disabled="disabled"' : ''}
                ${data.isOptimizable ? 'checked="true"' : ''}
                data-id="${data.ownerId}">`;
            }

            return data.isOptimizable;
          },

          orderable: true,
          width: 50,
        },
        {
          data: 'name',
        },
        {
          data: 'ownerName',
        },
        {
          data: 'unit',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              if (data.value === null || data.value === '') {
                return 'N/D';
              }

              return unitConverter.formatNumber(data.value);
            }

            return data.value;
          },
        },
      ],
    };

    (<any>$table).DataTable(<any>opts);
  }

  buildConstraintsTable() {
    const $table = $('#ostConstraintsTable');

    if ((<any>$.fn).dataTable.isDataTable('#ostConstraintsTable')) {
      (<any>$table).DataTable().destroy();
    }

    const opts = {
      data: this.constraintsReport.constraints,
      paging: true,
      searching: true,
      info: false,
      order: [
        [0, 'desc'],
        [6, 'desc'],
      ],
      columns: [
        {
          data: (data, type) => {
            if (type === 'display') {
              if (data.constraintDisabled) {
                return `<input type="checkbox" class="isActive-checkbox" disabled ${
                  data.isActive ? 'checked="true"' : ''
                }" +  data-id="${data.simVarId}">`;
              }
              return `<input type="checkbox" class="isActive-checkbox" ${this.isReadOnly ? 'disabled="disabled"' : ''}${
                data.isActive ? 'checked="true"' : ''
              }" +  data-id="${data.simVarId ? data.simVarId : data.id}">`;
            }

            return data.isActive;
          },

          orderable: true,
          width: 50,
        },
        {
          data: 'unitOperationName',
        },
        {
          data: 'name',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              return unitConverter.formatNumber(data.constraintValue);
            }

            return data.constraintValue;
          },
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              if (data.currentValue === null || data.currentValue === '') {
                return 'N/D';
              }

              return unitConverter.formatNumber(data.currentValue);
            }

            return data.currentValue;
          },
        },
        {
          data: data => {
            if (data.constraintValue === null) {
              return 'N/D';
            }
            return data.constraintUnit;
          },
        },
        {
          data: 'isConstraintViolated',
          visible: false,
        },
      ],
      // Todo: Improve the coloring for constraints in the constraint table
      rowCallback(row, data) {
        if (data.isConstraintViolated) {
          $(row).addClass('text-danger');
        }
      },
    };

    (<any>$table).DataTable(<any>opts);
  }

  // region save changes
  saveOptimizationVariable(id, isOptimizable) {
    const usedSplitterIds = [];
    const uo = this.coreService.currentCase.getUnitOperation(id);

    if ((uo instanceof Splitter || uo instanceof ProductTank) && usedSplitterIds.indexOf(id) === -1) {
      uo.setOptimizable(isOptimizable);
      usedSplitterIds.push(id);
    } else if (uo instanceof DiluentSource || uo instanceof ThirdPartySource) {
      uo.setOptimizable(isOptimizable);
    }
  }

  saveOptimizationVariables() {
    const usedSplitterIds = [];

    $('#ostOptimizationVariables')
      .find('input[type=checkbox]')
      .each((i, el) => {
        const $el = $(el);
        const id = $el.data('id');
        const isOptimizable = $el.is(':checked');
        const uo = this.coreService.currentCase.getUnitOperation(id);

        if ((uo instanceof Splitter || uo instanceof ProductTank) && usedSplitterIds.indexOf(id) === -1) {
          uo.setOptimizable(isOptimizable);
          usedSplitterIds.push(id);
        } else if (uo instanceof DiluentSource || uo instanceof ThirdPartySource) {
          uo.setOptimizable(isOptimizable);
        }
      });
  }

  // saveIsActive() {
  //   const usedIds = [];
  //
  //   $('#ostConstraintsTable').find('input[type=checkbox]').each((i, el) => {
  //     const $el = $(el);
  //     const id = $el.data('id');
  //     const isActive = $el.is(':checked');
  //     const simvar = this.coreService.currentCase.getSimulationVariable(id);
  //     simvar.setIsActive(isActive);
  //   });
  // }

  // endregion

  isRangeConstraintsUser(unitOperation: UnitOperation): unitOperation is Mixer | Splitter {
    return unitOperation instanceof Mixer || unitOperation instanceof Splitter;
  }

  get isReadOnly() {
    return this.coreService.currentCase.multiPeriodEnabled;
  }

  get currentCase() {
    return this.coreService.currentCase;
  }
}
