import { FlexSheetMapper } from './flex-sheet-mapper';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';
import { DiluentSource } from '../../../_models/_unit-operations/diluent-source';
import { DiluentSourceSheetRows } from '../cell-config/diluent-source-cell-config';

export class DiluentSourceFlexSheetMapper implements FlexSheetMapper {
  private simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: DiluentSource, row: number, value: string | number) {
    if (typeof value === 'string' && row === DiluentSourceSheetRows.NAME) {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (row) {
        case DiluentSourceSheetRows.VOLUMETRIC_FLOW_RATE:
          this.simulationVariableMapper.map(value, unitOperation.volumetricFlowrate);
          break;

        case DiluentSourceSheetRows.GHG_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.ghgIntensity);
          break;
      }
    }
  }
}
