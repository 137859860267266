import { Case } from '../case';
import { ReportValue } from './report-value';
import { UnitOperation } from '../unit-operation';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { DiluentSource } from '../_unit-operations/diluent-source';
import { SimulationVariable } from '../simulation-variable';

declare let unitConverter: any;

export class DiluentFlowRatesReport {
  total: number;
  unit: string;
  data: Array<ReportValue> = [];

  constructor(c: Case) {
    this.initReport(c);
  }

  initReport(c: Case) {
    this.total = 0;

    const diluentSources = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.category === unitOperationsConfig.diluentSource.key;
    }) as Array<DiluentSource>;

    for (const uo of diluentSources) {
      const s = uo.volumetricFlowrate;

      this.total += s.value;
      this.data.push(new ReportValue(unitConverter.parseFloatString(s.convertToDefaultUnit(true, false)), uo.name));
    }

    const sv = diluentSources.length ? diluentSources[0].volumetricFlowrate : null;

    if (sv) {
      this.unit = sv.getDefaultUnit();
      const totalSv = new SimulationVariable(sv, sv.ownerCase, sv.ownerBaseObject);
      totalSv.value = this.total;
      this.total = unitConverter.parseFloatString(totalSv.convertToDefaultUnit(true, false));
    }
  }
}
