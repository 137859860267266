import { SuncorUnitOperation } from './_unit-operations/suncor-unit-operation';
import { SimulationVariable } from './simulation-variable';
import { Case } from './case';
import { DistributionRatioVariable } from './_unit-operations/distribution-ratio-variable';
import { BaseObject } from './base-object';
import { MultiPeriodTank } from './_multi-period/multi-period-tank';
import { KpiProvider } from './_case-study/kpi-provider';

export class ProductTank extends SuncorUnitOperation implements MultiPeriodTank, KpiProvider {
  category = 'product-tank';
  distributionRatioVariables: DistributionRatioVariable[] = [];
  productType: string;
  productVolume: SimulationVariable;
  isOptimizable: boolean;
  inventory: SimulationVariable;

  minVolume: SimulationVariable;
  maxVolume: SimulationVariable;
  initialVolume: SimulationVariable;

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [this.inventory];
    }
    return [this.productVolume];
  }

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.productVolume = new SimulationVariable(unitOperation.productVolume, this.ownerCase, this);
    this.maxVolume = new SimulationVariable(unitOperation.maxVolume, this.ownerCase, this);
    this.minVolume = new SimulationVariable(unitOperation.minVolume, this.ownerCase, this);
    this.initialVolume = new SimulationVariable(unitOperation.initialVolume, this.ownerCase, this);
    this.inventory = new SimulationVariable(unitOperation.inventory, this.ownerCase, this);

    if (unitOperation.distributionRatioVariables instanceof Array) {
      for (const dv of unitOperation.distributionRatioVariables) {
        this.distributionRatioVariables.push(new DistributionRatioVariable(dv.unitOperationId, dv.value));
      }
    }
    this.productType = unitOperation.productType || '';
    if (typeof unitOperation.isOptimizable === 'undefined') {
      this.isOptimizable = true;
    } else {
      this.isOptimizable = !!unitOperation.isOptimizable;
    }
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
    const s = another as ProductTank;
    this.name = another.name;
    this.comments = s.comments;
    this.productType = s.productType;
    this.inventory = s.inventory;
    this.isOptimizable = s.isOptimizable;
    for (let i = 0; i < s.distributionRatioVariables.length; i++) {
      this.distributionRatioVariables[i].value = s.distributionRatioVariables[i].value;
    }

    // TODO call to super.overwriteValues at the beginning of this function instead of this
    this.minVolume.overwriteValues(s.minVolume);
    this.maxVolume.overwriteValues(s.maxVolume);
    this.initialVolume.overwriteValues(s.initialVolume);
  }

  override setQuantities() {
    super.setQuantities();
    this.productVolume.setQuantity('Volumetricflowrate');
    this.minVolume.setQuantity('Volume');
    this.maxVolume.setQuantity('Volume');
    this.initialVolume.setQuantity('Volume');
    this.inventory.setQuantity('Volume');
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.productVolume.setName('Product Volume');
    this.inventory.setName('Inventory');
  }

  addDistributionVariable(unitOperationId: string) {
    this.emptyDistributionValues();
    this.distributionRatioVariables.push(new DistributionRatioVariable(unitOperationId, null));

    if (this.distributionRatioVariables.length === 1) {
      this.distributionRatioVariables[0].value = 1;
    }
  }

  private emptyDistributionValues() {
    for (const dv of this.distributionRatioVariables) {
      dv.value = null;
    }
  }

  findDistributionRatioVariable(unitOperationId: string) {
    return this.distributionRatioVariables.find(drv => {
      return drv.unitOperationId === unitOperationId;
    });
  }

  findDistributionRatioVariableName(i: number) {
    const dv = this.distributionRatioVariables[i];

    if (dv) {
      const uo = this.ownerCase.getUnitOperation(dv.unitOperationId);
      return uo ? uo.name : null;
    }

    return null;
  }

  removeDistributionRatioVariable(unitOperationId: string) {
    const index = this.distributionRatioVariables.indexOf(this.findDistributionRatioVariable(unitOperationId));

    if (index >= 0) {
      this.distributionRatioVariables.splice(index, 1);
    }
  }

  setOptimizable(isOptimizable: boolean) {
    this.isOptimizable = isOptimizable;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.productVolume = this.getSimulationVariableSafe(sup.productVolume);
    this.inventory = this.getSimulationVariableSafe(sup.inventory);

    this.maxVolume = this.getSimulationVariableSafe(sup.maxVolume);
    this.minVolume = this.getSimulationVariableSafe(sup.minVolume);
    this.initialVolume = this.getSimulationVariableSafe(sup.initialVolume);

    this.setQuantities();
    this.setDefaultValues();
  }
}
