import { DataType } from '@grapecity/wijmo';

export class H2sSheetColumnsConfiguration {
  static get() {
    return [
      {
        header: 'Unit Operation Name',
        binding: 'unitOperationName',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: 'Acid Gas & Sulphur (scf/bbl)',
        binding: 'h2sProductionRate',
        dataType: DataType.Number,
        width: 220,
        isReadOnly: true,
      },
      {
        header: 'H2S Production (MMSCFD)',
        binding: 'h2sProduction',
        dataType: DataType.Number,
        width: 200,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: 'Sulfur Plant Name',
        binding: 'sulfurPlantName',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: 'Sulfur Plant Capacity (MMSCFD)',
        binding: 'sulfurCapacity',
        dataType: DataType.Number,
        width: 220,
        isReadOnly: true,
      },
      {
        header: 'Sulfur Processing Rate (MMSCFD)',
        binding: 'sulfurFlowrate',
        dataType: DataType.Number,
        width: 220,
        isReadOnly: true,
      },
      // empty columns to fill the screen...
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
    ];
  }
}
