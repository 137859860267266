<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>

  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>
  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#pipeComments" aria-controls="pipeComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <ng-container>
          <div class="form-group" formGroupName="pipeLength">
            <label class="control-label col-sm-4"> Length </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.pipeLength.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.pipeLength">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="pressureDrop">
            <label class="control-label col-sm-4"> Pressure Drop </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.pressureDrop.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.pressureDrop">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr />
          <h4 class="subtitle">GHG</h4>

          <div class="form-group" formGroupName="ghgIntensity">
            <label class="control-label col-sm-4"> GHG Intensity </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgIntensity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgIntensity">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="ghgEmissions">
            <label class="control-label col-sm-4"> GHG Emissions </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgEmissions.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgEmissions">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="economics">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">OPEX</h4>

        <ng-container>
          <div class="form-group" formGroupName="opexFixed">
            <label class="control-label col-sm-4"> Fixed </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexFixed.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexFixed">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexVar">
            <label class="control-label col-sm-4"> Variable </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexVar.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexVar">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexCarbonDioxide">
            <label class="control-label col-sm-4"> CO2 </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.opexCarbonDioxide.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexCarbonDioxide">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexTotal">
            <label class="control-label col-sm-4"> Total </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexTotal.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexTotal">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <ng-container>
          <div class="form-group" formGroupName="capacity">
            <label class="control-label col-sm-4"> Maximum Feed Capacity </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capacity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.capacity">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.capacity"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="minimumFlow">
            <label class="control-label col-sm-4"> Minimum Flow </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.minimumFlow.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.minimumFlow">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.minimumFlow"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="pipeDensitySpec">
            <label class="control-label col-sm-4"> Pipe Density Spec </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-5">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.pipeDensitySpec.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.pipeDensitySpec">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-2">
                  <sob-reactive-constraint-check-box
                    formControlName="isActive"
                    [isConstraint]="true"
                    [valueMetaData]="unitOperation.pipeDensitySpec"></sob-reactive-constraint-check-box>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="pipeComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
