import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';

@Component({
  selector: 'sob-bulk-properties',
  templateUrl: './bulk-properties.component.html',
  styleUrls: ['./bulk-properties.component.css'],
})
export class BulkPropertiesComponent implements OnInit {
  @Input() currentAnalysis: SuncorFluidAnalysis;
  bulkPropsFormGroup: UntypedFormGroup;

  constructor(private svfb: SimVarFormBuilder, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.bulkPropsFormGroup = this.fb.group({});
    this.addControls();
  }

  addControls() {
    this.bulkPropsFormGroup.addControl('massDensity', this.svfb.control(this.currentAnalysis.massDensity, true));
    this.bulkPropsFormGroup.addControl('ccr', this.svfb.control(this.currentAnalysis.ccr, true));
    this.bulkPropsFormGroup.addControl('sulfur', this.svfb.control(this.currentAnalysis.sulfur, true));
    this.bulkPropsFormGroup.addControl('nitrogen', this.svfb.control(this.currentAnalysis.nitrogen, true));
  }

  updateControls() {
    this.bulkPropsFormGroup.get('massDensity').get('unit').setValue(this.currentAnalysis.massDensity.unit);
    this.bulkPropsFormGroup.get('massDensity').get('value').setValue(this.currentAnalysis.massDensity.value);

    this.bulkPropsFormGroup.get('ccr').get('unit').setValue(this.currentAnalysis.ccr.unit);
    this.bulkPropsFormGroup.get('ccr').get('value').setValue(this.currentAnalysis.ccr.value);

    this.bulkPropsFormGroup.get('sulfur').get('unit').setValue(this.currentAnalysis.sulfur.unit);
    this.bulkPropsFormGroup.get('sulfur').get('value').setValue(this.currentAnalysis.sulfur.value);

    this.bulkPropsFormGroup.get('nitrogen').get('unit').setValue(this.currentAnalysis.nitrogen.unit);
    this.bulkPropsFormGroup.get('nitrogen').get('value').setValue(this.currentAnalysis.nitrogen.value);
  }
}
