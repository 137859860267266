import { Case } from '../../case';
import { ConstraintReportVariable } from './constraint-report-variable';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { UnitOperationConstraints } from '../../_unit-operations/unit-operation-constraints';
import { UnitOperation } from '../../unit-operation';
import { Upgrader } from '../../_unit-operations/upgrader';
import { isEmptyValue } from '../../../_utils/utils';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { SuncorConstraint } from '../../_unit-operations/suncor-constraint';
import { SulfurPlant } from '../../_unit-operations/sulfur-plant';
import { HydrogenPlant } from '../../_unit-operations/hydrogen-plant';
import { Mixer } from '../../_unit-operations/mixer';
import { MixingConstraintReportVariable } from './mixing-constraint-report-variable';
import { RangeDistributionConstraint } from '../../_unit-operations/range-distribution-constraint';
import { MixingConstraints } from '../../_unit-operations/mixing-constraints';
import { Splitter } from '../../_unit-operations/splitter';
import { BaseConstraintReportVariable } from './base-constraint-report-variable';

export class ConstraintsReport {
  constraints: Array<BaseConstraintReportVariable> = [];

  constructor(c: Case, activeFlowhseetId: string) {
    this.initReport(c, activeFlowhseetId);
  }

  initReport(c: Case, activeFlowsheetId: string) {
    const unitOperations = c.filterUnitOperations(uo => {
      return (
        uo.category === unitOperationsConfig.upgrader.key ||
        uo.category === unitOperationsConfig.thirdPartyRefinery.key ||
        uo.category === unitOperationsConfig.dcu.key ||
        uo.category === unitOperationsConfig.dru.key ||
        uo.category === unitOperationsConfig.vac.key ||
        uo.category === unitOperationsConfig.hydrotreater.key ||
        uo.category === unitOperationsConfig.commodityTank.key ||
        uo.category === unitOperationsConfig.pipe.key ||
        uo.category === unitOperationsConfig.bitumenConversion.key ||
        uo.category === unitOperationsConfig.refinery.key ||
        uo.category === unitOperationsConfig.hydrocracker.key ||
        uo.category === unitOperationsConfig.diluentSource.key ||
        uo.category === unitOperationsConfig.mine.key ||
        uo.category === unitOperationsConfig.sagd.key ||
        uo.category === unitOperationsConfig.fluidCoker.key ||
        uo.category === unitOperationsConfig.hgoHydrotreater.key ||
        uo.category === unitOperationsConfig.lgoHydrotreater.key ||
        uo.category === unitOperationsConfig.waterTurbine.key ||
        uo.category === unitOperationsConfig.waterSink.key ||
        uo.category === unitOperationsConfig.waterBoiler.key ||
        uo.category === unitOperationsConfig.waterCogeneration.key ||
        uo.category === unitOperationsConfig.waterValve.key ||
        uo.category === unitOperationsConfig.waterSinkImport.key ||
        uo.category === unitOperationsConfig.fuelGasSinkImport.key ||
        uo.category === unitOperationsConfig.mixer.key ||
        uo.category === unitOperationsConfig.splitter.key
      );
    });

    for (const uo of unitOperations) {
      if ((uo as any).constraints) {
        const { constraints } = (uo as any).constraints as UnitOperationConstraints | MixingConstraints;
        // eslint-disable-next-line guard-for-in
        for (const id in constraints) {
          const constraint = constraints[id];
          if (constraint.constraintValueDefined()) {
            const suncorConstraint = constraint as SuncorConstraint;
            const mixingConstraint = constraint as RangeDistributionConstraint;

            if (!isEmptyValue(activeFlowsheetId)) {
              if (uo.flowsheetId === activeFlowsheetId || uo.id === activeFlowsheetId) {
                if (uo instanceof Upgrader) {
                  this.filterConstraintsForUpgradersOnSecondLevel(c, uo, suncorConstraint);
                } else if (this.isRangeConstraintsUser(uo)) {
                  this.createMixingConstraintReportVariable(uo, mixingConstraint);
                } else {
                  this.createConstraintReportVariable(uo, suncorConstraint);
                }
              }
            } else if (uo.flowsheetId === '') {
              if (uo instanceof Upgrader) {
                this.filterConstraintsForUpgradersOnFirstLevel(c, uo, suncorConstraint);
              } else if (this.isRangeConstraintsUser(uo)) {
                this.createMixingConstraintReportVariable(uo, mixingConstraint);
              } else {
                this.createConstraintReportVariable(uo, suncorConstraint);
              }
            }
          }
        }
      }
    }
  }

  filterConstraintsForUpgradersOnSecondLevel(c: Case, uo: UnitOperation, constraint: SuncorConstraint) {
    if (
      this.detectHydrogenPlants(c, uo.id).length > 0 &&
      constraint.constraint.name === SimulationVariableName.MAXIMUM_HYDROGEN_PRODUCTION
    ) {
      this.createConstraintReportVariable(uo, constraint);
    }

    if (
      this.detectSulfurPlants(c, uo.id).length > 0 &&
      constraint.constraint.name === SimulationVariableName.SULFUR_CAPACITY
    ) {
      this.createConstraintReportVariable(uo, constraint);
    }
  }

  filterConstraintsForUpgradersOnFirstLevel(c: Case, uo: UnitOperation, constraint: SuncorConstraint) {
    if (this.detectSulfurAndHydrogenPlants(c, uo.id).length === 0) {
      this.createConstraintReportVariable(uo, constraint);
    } else if (
      this.detectHydrogenPlants(c, uo.id).length > 0 &&
      this.detectSulfurPlants(c, uo.id).length === 0 &&
      constraint.constraint.name !== SimulationVariableName.MAXIMUM_HYDROGEN_PRODUCTION
    ) {
      this.createConstraintReportVariable(uo, constraint);
    } else if (
      this.detectHydrogenPlants(c, uo.id).length === 0 &&
      this.detectSulfurPlants(c, uo.id).length > 0 &&
      constraint.constraint.name !== SimulationVariableName.SULFUR_CAPACITY
    ) {
      this.createConstraintReportVariable(uo, constraint);
    } else if (
      constraint.constraint.name !== SimulationVariableName.MAXIMUM_HYDROGEN_PRODUCTION &&
      constraint.constraint.name !== SimulationVariableName.SULFUR_CAPACITY
    ) {
      this.createConstraintReportVariable(uo, constraint);
    }
  }

  createConstraintReportVariable(uo: UnitOperation, constraint: SuncorConstraint) {
    this.constraints.push(ConstraintReportVariable.create(uo as UnitOperation, constraint.constraint));
  }

  createMixingConstraintReportVariable(uo: UnitOperation, constraint: RangeDistributionConstraint) {
    this.constraints.push(MixingConstraintReportVariable.create(uo as UnitOperation, constraint));
  }

  detectSulfurAndHydrogenPlants(c: Case, upgId: string) {
    return c.filterUnitOperations(uo => {
      return (uo instanceof SulfurPlant || uo instanceof HydrogenPlant) && uo.flowsheetId === upgId;
    });
  }

  detectSulfurPlants(c: Case, upgId: string) {
    return c.filterUnitOperations(uo => {
      return uo instanceof SulfurPlant && uo.flowsheetId === upgId;
    });
  }

  detectHydrogenPlants(c: Case, upgId: string) {
    return c.filterUnitOperations(uo => {
      return uo instanceof HydrogenPlant && uo.flowsheetId === upgId;
    });
  }

  isRangeConstraintsUser(unitOperation: UnitOperation): unitOperation is Mixer | Splitter {
    return unitOperation instanceof Mixer || unitOperation instanceof Splitter;
  }
}
