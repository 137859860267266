import { Component, AfterViewInit } from '@angular/core';
import { LogManagerService } from '../log-manager/log-manager.service';
import { FlowsheetDiagramService } from '../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from '../../_services/core.service';

declare let jQuery: any;
@Component({
  selector: 'sob-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.css'],
})
export class BottomBarComponent implements AfterViewInit {
  constructor(
    private logManager: LogManagerService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private coreService: CoreService
  ) {}

  ngAfterViewInit() {
    jQuery('#zoomSlider').ionRangeSlider({
      min: 0.5,
      max: 2.5,
      from: 1,
      hide_min_max: true,
      step: 0.1,
      force_edges: true,
      onChange: data => {
        this.flowsheetDiagramService.gojsDiagram.scale = data.from;
      },
    });
    const $invertedTabs = $('.inverted-tab');

    $invertedTabs.on('click', e => {
      const $clickedTab = $(e.delegateTarget);
      const $targetPanel = $($clickedTab.data('controls'));
      const targetPanelVisible = $targetPanel.is(':visible');
      $targetPanel.hide();

      if (!targetPanelVisible) {
        $targetPanel.show();
        this.flowsheetDiagramService.overview.requestUpdate();
      }
      $invertedTabs.removeClass('active');
      $clickedTab.addClass('active');
    });

    $('.close-bottom-tabpanel').on('click', () => {
      $('.bottom-tabpanel').hide();
      $invertedTabs.removeClass('active');
    });
  }

  clearLogCounter() {
    this.logManager.clearLogCounter();
  }

  clearLogManager() {
    this.logManager.clearLogManager();
  }

  get currentProject() {
    return this.coreService.project;
  }
}
