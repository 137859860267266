import { SimulationVariable } from './simulation-variable';
import { BaseObject } from './base-object';
import { Case } from './case';
import { KpiProvider } from './_case-study/kpi-provider';

export class SuncorCalculator extends BaseObject implements KpiProvider {
  category = 'suncorCalculator';
  override name = 'Insights';
  integration: SimulationVariable;
  totalGhg: SimulationVariable;
  totalSuncorGhg: SimulationVariable;
  totalRevenue: SimulationVariable;
  totalOpex: SimulationVariable;
  totalCapex: SimulationVariable;
  totalValue: SimulationVariable;
  totalGhgIntensity: SimulationVariable;
  totalSuncorGhgIntensity: SimulationVariable;

  constructor(calculator: any, ownerCase: Case) {
    super(calculator.id, ownerCase);
    this.initValues(calculator);
    this.setSimulationVariableNames();
    this.setSimulationVariablesOwner();
  }

  initValues(calculator: any) {
    this.integration = new SimulationVariable(calculator.integration, this.ownerCase, this);
    this.totalGhg = new SimulationVariable(calculator.totalGhg, this.ownerCase, this);
    this.totalSuncorGhg = new SimulationVariable(calculator.totalSuncorGhg, this.ownerCase, this);
    this.totalRevenue = new SimulationVariable(calculator.totalRevenue, this.ownerCase, this);
    this.totalOpex = new SimulationVariable(calculator.totalOpex, this.ownerCase, this);
    this.totalCapex = new SimulationVariable(calculator.totalCapex, this.ownerCase, this);
    this.totalValue = new SimulationVariable(calculator.totalValue, this.ownerCase, this);
    this.totalGhgIntensity = new SimulationVariable(calculator.totalGhgIntensity, this.ownerCase, this);
    this.totalSuncorGhgIntensity = new SimulationVariable(calculator.totalSuncorGhgIntensity, this.ownerCase, this);
    this.setQuantities();
    this.setSimulationVariablesOwner();
  }

  setQuantities() {
    this.integration.setQuantity('Percent');
    this.totalGhg.setQuantity('Massflowrate');
    this.totalSuncorGhg.setQuantity('Massflowrate');
    this.totalOpex.setQuantity('CurrencyPerTime');
    this.totalRevenue.setQuantity('CurrencyPerTime');
    this.totalCapex.setQuantity('CurrencyPerTime');
    this.totalValue.setQuantity('CurrencyPerTime');
    this.totalGhgIntensity.setQuantity('MassPerVolume');
    this.totalSuncorGhgIntensity.setQuantity('MassPerVolume');
  }

  override dePersist(so: any) {
    this.integration = this.getSimulationVariableSafe(so.integration);
    this.totalGhg = this.getSimulationVariableSafe(so.totalGhg);
    this.totalSuncorGhg = this.getSimulationVariableSafe(so.totalSuncorGhg);
    this.totalRevenue = this.getSimulationVariableSafe(so.totalRevenue);
    this.totalOpex = this.getSimulationVariableSafe(so.totalOpex);
    this.totalCapex = this.getSimulationVariableSafe(so.totalCapex);
    this.totalValue = this.getSimulationVariableSafe(so.totalValue);
    this.totalGhgIntensity = this.getSimulationVariableSafe(so.totalGhgIntensity);
    this.totalSuncorGhgIntensity = this.getSimulationVariableSafe(so.totalSuncorGhgIntensity);
    this.setSimulationVariableNames();
    this.setSimulationVariablesOwner();
  }

  getAvailableKpis() {
    if (!this.ownerCase.multiPeriodEnabled) {
      return [
        this.integration,
        this.totalGhg,
        this.totalRevenue,
        this.totalOpex,
        this.totalCapex,
        this.totalValue,
        this.totalGhgIntensity,
        this.totalSuncorGhgIntensity,
      ];
    }
    return [];
  }

  setSimulationVariableNames() {
    this.integration.setName('Integration');
    this.totalGhg.setName('Total GHG');
    this.totalRevenue.setName('Total revenue');
    this.totalOpex.setName('Total OPEX');
    this.totalCapex.setName('Total CAPEX');
    this.totalValue.setName('Total value');
    this.totalGhgIntensity.setName('Total GHG intensity');
    this.totalSuncorGhgIntensity.setName('Total Suncor GHG intensity');
  }

  public toJSON() {
    return {
      id: this.id,
      category: this.category,
      integration: this.integration.id,
      totalGhg: this.totalGhg.id,
      totalSuncorGhg: this.totalSuncorGhg.id,
      totalRevenue: this.totalRevenue.id,
      totalOpex: this.totalOpex.id,
      totalCapex: this.totalCapex.id,
      totalValue: this.totalValue.id,
      totalGhgIntensity: this.totalGhgIntensity.id,
      totalSuncorGhgIntensity: this.totalSuncorGhgIntensity.id,
    };
  }
}
