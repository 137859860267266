import { UnitOperation } from '../../unit-operation';
import { Mixer } from '../../_unit-operations/mixer';
import { isNullOrUndefined, roundNumber } from '../../../_utils/utils';
import { RangeDistributionConstraint } from '../../_unit-operations/range-distribution-constraint';
import { Splitter } from '../../_unit-operations/splitter';
import { RangeDistributionRatioVariable } from '../../_unit-operations/range-distribution-ratio-variable';
import { BaseConstraintReportVariable } from './base-constraint-report-variable';

declare let unitConverter: any;

export class MixingConstraintReportVariable extends BaseConstraintReportVariable {
  id: string;

  constructor(name: string, constraintValue: number, currentValue?: number) {
    super(name, constraintValue);
    this.currentValue = currentValue;
    this.constraintUnit = unitConverter.units.Dimensionless.FRACTION;
  }

  static create(uo: UnitOperation, constraint: RangeDistributionConstraint): MixingConstraintReportVariable {
    const reportVariable = new MixingConstraintReportVariable(constraint.constraintName, constraint.constraintValue);

    reportVariable.id = constraint.id;
    reportVariable.isActive = constraint.isActive;
    reportVariable.unitOperationName = uo.name;
    reportVariable.unitOperationId = uo.id;

    let mixingRatioVariable: RangeDistributionRatioVariable;
    if (uo instanceof Mixer) {
      mixingRatioVariable = uo.findMixingRatioVariable(constraint.sourceUnitOperationId);
    } else if (uo instanceof Splitter) {
      mixingRatioVariable = uo.findDistributionRatioVariable(constraint.sourceUnitOperationId);
    }

    reportVariable.currentValue = isNullOrUndefined(mixingRatioVariable.value)
      ? null
      : roundNumber(mixingRatioVariable.value, 4);

    reportVariable.isConstraintViolated = this.verifyIfConstraintIsViolated(reportVariable);

    return reportVariable;
  }
}
