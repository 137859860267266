import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { CoreService } from '../core.service';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { XlsxUtils } from './xlsx.utils';
import { Workbook } from './workbook';
import { InletOutletExcelReport } from './inlet-outlet-excel-report';
import { KpiManagerExcelReport } from './kpi-manager-excel-report';
import { ReportService } from '../report.service';
import { ConstraintsExcelReport } from './constraints-excel-report';
import { MassBalanceExcelReport } from './mass-balance-excel-report';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { UnitOperation } from '../../_models';
import { CommodityTankExcelReport } from './commodity-tank-excel-report';
import { Extraction } from '../../_models/_unit-operations/extraction';
import { WaterUtilityUnitOperation } from '../../_models/_unit-operations/utilties/water-utility-unit-operation';
import { FuelGasUtilityUnitOperation } from '../../_models/_unit-operations/fuel-gas/fuel-gas-utility-unit-operation';
import { GhgExcelReport } from './ghg-excel-report';
import { UtilitiesSummaryReportComponent } from '../../flowsheet/summary-report/utilities-summary-report/utilities-summary-report.component';
import { NumberToUnitConverter } from '../number-to-unit-converter.service';
import { SteamExcelReport } from './steam-excel-report';
import { FuelGasSummaryReportComponent } from '../../flowsheet/summary-report/fuel-gas-summary-report/fuel-gas-summary-report.component';
import { FuelGasExcelReport } from './fuel-gas-excel-report';
import { FlowsheetTreeService } from '../sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { MultiperiodResultsExcelReport } from './multiperiod-results-excel-report';
import { ServerUtcDateService } from '../helpers/server-utc-date.service';
import { CaseStudyExcelReport } from './case-study-excel-report';
import { ParametricStudyKpiResult } from '../../_models/_case-study/parametric-study-kpi-result';
import { hasNumericValue } from '../../_utils/utils';
import { ParametricStudyParameter } from '../../_models/_case-study/parametric-study-parameter';

@Injectable()
export class ExcelReportService {
  private readonly SHEET_NAME_MAX_LENGTH = 31;
  constructor(
    private coreService: CoreService,
    private reportService: ReportService,
    private nuc: NumberToUnitConverter,
    private flowsheetTree: FlowsheetTreeService,
    private utcDateService: ServerUtcDateService
  ) {}

  generateExcelReport(): any {
    const wb = new Workbook();
    const flowsheetOwners = this.flowsheetTree.getAllFlowsheetOwners(this.coreService.currentCase);

    const extractionsWithSubflowsheet = this.getUnitOperationsWithSubFlowsheet(
      flowsheetOwners,
      unitOperationsConfig.extraction.key
    ) as Extraction[];

    const upgradersWithSubflowsheet = this.getUnitOperationsWithSubFlowsheet(
      flowsheetOwners,
      unitOperationsConfig.upgrader.key
    ) as Upgrader[];

    wb.SheetNames.push('KPI Manager');
    wb.Sheets['KPI Manager'] = XlsxUtils.sheetFromArrayOfArrays(
      KpiManagerExcelReport.generateDataArray(this.coreService.currentCase)
    );

    // Constraints Region

    wb.SheetNames.push('Constraints Main Flowsheet');
    wb.Sheets['Constraints Main Flowsheet'] = XlsxUtils.sheetFromArrayOfArrays(
      ConstraintsExcelReport.generateDataArray(
        this.reportService.buildConstraintsReport(undefined),
        this.coreService.currentCase
      )
    );

    this.generateExtractionConstraintsSheets(wb, extractionsWithSubflowsheet);
    this.generateUpgraderConstraintsSheets(wb, upgradersWithSubflowsheet, flowsheetOwners);

    // End Constraints Region

    // Streams Region

    wb.SheetNames.push('Main Flowsheet Streams');
    wb.Sheets['Main Flowsheet Streams'] = XlsxUtils.sheetFromArrayOfArrays(
      InletOutletExcelReport.generateDataArray(this.coreService.currentCase)
    );

    this.generateExtractionsStreamsSheets(wb, extractionsWithSubflowsheet);
    this.generateUpgradersStreamsSheets(wb, upgradersWithSubflowsheet, flowsheetOwners);

    // End Streams Region

    // Mass Balance Region

    wb.SheetNames.push('Main Flowsheet Mass Balance');
    wb.Sheets['Main Flowsheet Mass Balance'] = XlsxUtils.sheetFromArrayOfArrays(
      MassBalanceExcelReport.generateDataArray(this.coreService.currentCase)
    );

    this.generateExtractionMassBalanceSheets(wb, extractionsWithSubflowsheet);
    this.generateUpgraderMassBalanceSheets(wb, upgradersWithSubflowsheet);

    // End Mass Balance Region

    // Commodity Tanks Region
    this.generateUpgradersCommodityTanksSheets(wb, upgradersWithSubflowsheet);
    // End Commodity Tanks Region

    // GHG Region

    wb.SheetNames.push('Main Flowsheet GHG Emissions');
    wb.Sheets['Main Flowsheet GHG Emissions'] = XlsxUtils.sheetFromArrayOfArrays(
      GhgExcelReport.generateDataArray(this.reportService.buildGhgEmissionsReport(), this.coreService.currentCase)
    );

    this.generateUpgradersGhgEmissionsSheets(wb, upgradersWithSubflowsheet);
    // End GHG Region

    // Steam Region
    this.generateUpgradersSteamSheets(wb, upgradersWithSubflowsheet);
    // End Steam Region

    // Fuel Gas Region
    this.generateUpgradersFuelGasSheets(wb, upgradersWithSubflowsheet);
    // End Fuel Gas Region

    return XlsxUtils.s2ab(XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' }));
  }

  generateMultiPeriodResultsExcelReport(): any {
    const wb = new Workbook();
    wb.SheetNames.push('Multi Period Results');
    wb.Sheets['Multi Period Results'] = XlsxUtils.sheetFromArrayOfArrays(
      MultiperiodResultsExcelReport.generateResultsArray(this.coreService.currentCase)
    );

    wb.SheetNames.push('Case');
    wb.Sheets.Case = XlsxUtils.sheetFromArrayOfArrays(
      MultiperiodResultsExcelReport.generateCaseDataArray(this.coreService.currentCase, this.utcDateService)
    );

    return XlsxUtils.s2ab(XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' }));
  }

  generateCaseStudyExcelReport() {
    const wb = new Workbook();
    const currentCaseStudy = this.currentCaseStudy();

    wb.SheetNames.push('Report Info');
    wb.Sheets['Report Info'] = XlsxUtils.sheetFromArrayOfArrays(
      CaseStudyExcelReport.generateCaseInfoDataArray(
        this.coreService.currentCase,
        this.utcDateService,
        currentCaseStudy
      )
    );

    this.generateCaseStudyKpiResultsSheets(wb, currentCaseStudy.kpiResults, currentCaseStudy.parameter);

    return XlsxUtils.s2ab(XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' }));
  }

  generateCaseStudyKpiResultsSheets(
    wb: Workbook,
    kpiResults: ParametricStudyKpiResult[],
    parameter: ParametricStudyParameter
  ) {
    kpiResults.forEach(result => {
      if (result.kpi.value !== 0 && hasNumericValue(result.kpi.value)) {
        let sheetName = result.kpi.getFullName();
        if (sheetName.length > this.SHEET_NAME_MAX_LENGTH) {
          const excessCharacters = sheetName.length - this.SHEET_NAME_MAX_LENGTH;
          sheetName = sheetName.substring(0, sheetName.length - excessCharacters - 3);
        }

        wb.SheetNames.push(sheetName);
        wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
          CaseStudyExcelReport.generateKpiResultsDataArray(result, parameter)
        );
      }
    });
  }

  generateExtractionConstraintsSheets(wb: Workbook, extractions: Extraction[]) {
    extractions.forEach((extraction: Extraction) => {
      let sheetName = `Constraints ${extraction.name}`;
      sheetName = this.adjustSheetName(sheetName, extraction.name, 'Constraints');

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        ConstraintsExcelReport.generateDataArray(
          this.reportService.buildConstraintsReport(extraction.id),
          this.coreService.currentCase
        )
      );
    });
  }

  generateUpgraderConstraintsSheets(wb: Workbook, upgraders: Upgrader[], flowsheetOwners: UnitOperation[]) {
    upgraders.forEach((upg: Upgrader) => {
      let sheetName = `Constraints ${upg.name}`;
      sheetName = this.adjustSheetName(sheetName, upg.name, 'Constraints');

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        ConstraintsExcelReport.generateDataArray(
          this.reportService.buildConstraintsReport(upg.id),
          this.coreService.currentCase
        )
      );

      this.generateWaterUtilityConstraintsSheet(wb, upg, flowsheetOwners);
      this.generateFuelGasUtilityConstraintsSheet(wb, upg, flowsheetOwners);
    });
  }

  generateWaterUtilityConstraintsSheet(wb: Workbook, upgrader: Upgrader, flowsheetOwners: UnitOperation[]) {
    const waterUtility = this.getUnitOperationsWithSubFlowsheet(
      flowsheetOwners,
      unitOperationsConfig.waterUtilityUnitOperation.key,
      upgrader.id
    ) as WaterUtilityUnitOperation[];

    waterUtility.forEach((wU: WaterUtilityUnitOperation) => {
      let sheetName = `Constraints ${upgrader.name} ${wU.name}`;
      sheetName = this.adjustSheetName(sheetName, upgrader.name, 'Constraints', wU.name);

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        ConstraintsExcelReport.generateDataArray(
          this.reportService.buildConstraintsReport(wU.id),
          this.coreService.currentCase
        )
      );
    });
  }

  generateFuelGasUtilityConstraintsSheet(wb: Workbook, upgrader: Upgrader, flowsheetOwners: UnitOperation[]) {
    const fuelGasUtility = this.getUnitOperationsWithSubFlowsheet(
      flowsheetOwners,
      unitOperationsConfig.fuelGasUtilityUnitOperation.key,
      upgrader.id
    ) as FuelGasUtilityUnitOperation[];

    fuelGasUtility.forEach((fGU: FuelGasUtilityUnitOperation) => {
      let sheetName = `Constraints ${upgrader.name} ${fGU.name}`;
      sheetName = this.adjustSheetName(sheetName, upgrader.name, 'Constraints', fGU.name);

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        ConstraintsExcelReport.generateDataArray(
          this.reportService.buildConstraintsReport(fGU.id),
          this.coreService.currentCase
        )
      );
    });
  }

  generateExtractionsStreamsSheets(wb: Workbook, extractions: Extraction[]) {
    extractions.forEach((ext: Extraction) => {
      const sheetName = `${ext.name} Streams`;
      this.adjustSheetName(sheetName, ext.name, 'Streams');

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        InletOutletExcelReport.generateUnitOperationFlowsheetDataArray(this.coreService.currentCase, ext.id, false)
      );
    });
  }

  generateUpgradersStreamsSheets(wb: Workbook, upgraders: Upgrader[], flowsheetOwners: UnitOperation[]) {
    upgraders.forEach((upg: Upgrader) => {
      let sheetName = `${upg.name} Streams`;
      sheetName = this.adjustSheetName(sheetName, upg.name, 'Streams');

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        InletOutletExcelReport.generateUnitOperationFlowsheetDataArray(this.coreService.currentCase, upg.id, true)
      );

      this.generateWaterUtilityStreamsSheet(wb, upg, flowsheetOwners);
      this.generateFuelGasUtilityStreamsSheet(wb, upg, flowsheetOwners);
    });
  }

  generateWaterUtilityStreamsSheet(wb: Workbook, upg: Upgrader, flowsheetOwners: UnitOperation[]) {
    const waterUtilitiesWithSubFlowsheet = this.getUnitOperationsWithSubFlowsheet(
      flowsheetOwners,
      unitOperationsConfig.waterUtilityUnitOperation.key,
      upg.id
    ) as WaterUtilityUnitOperation[];

    waterUtilitiesWithSubFlowsheet.forEach((waterUtility: WaterUtilityUnitOperation) => {
      let sheetName = `${upg.name} ${waterUtility.name} Streams`;
      sheetName = this.adjustSheetName(sheetName, upg.name, 'Streams', waterUtility.name);

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        InletOutletExcelReport.generateWaterUtilityFlowsheetDataArray(this.coreService.currentCase, waterUtility.id)
      );
    });
  }

  generateFuelGasUtilityStreamsSheet(wb: Workbook, upg: Upgrader, flowsheetOwners: UnitOperation[]) {
    const fuelGasUtilitiesWithSubFlowsheet = this.getUnitOperationsWithSubFlowsheet(
      flowsheetOwners,
      unitOperationsConfig.fuelGasUtilityUnitOperation.key,
      upg.id
    ) as FuelGasUtilityUnitOperation[];

    fuelGasUtilitiesWithSubFlowsheet.forEach((fuelGasUtility: FuelGasUtilityUnitOperation) => {
      let sheetName = `${upg.name} ${fuelGasUtility.name} Streams`;
      sheetName = this.adjustSheetName(sheetName, upg.name, 'Streams', fuelGasUtility.name);

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        InletOutletExcelReport.generateFuelGasUtilityFlowsheetDataArray(this.coreService.currentCase, fuelGasUtility.id)
      );
    });
  }

  generateExtractionMassBalanceSheets(wb: Workbook, extractions: Extraction[]) {
    extractions.forEach((extraction: Extraction) => {
      let sheetName = `${extraction.name} Mass Balance`;
      sheetName = this.adjustSheetName(sheetName, extraction.name, 'Mass Balance');

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        MassBalanceExcelReport.generateDataArray(this.coreService.currentCase, extraction.id)
      );
    });
  }

  generateUpgraderMassBalanceSheets(wb: Workbook, upgraders: Upgrader[]) {
    upgraders.forEach((upgrader: Upgrader) => {
      let sheetName = `${upgrader.name} Mass Balance`;
      sheetName = this.adjustSheetName(sheetName, upgrader.name, 'Mass Balance');

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        MassBalanceExcelReport.generateDataArray(this.coreService.currentCase, upgrader.id)
      );
    });
  }

  generateUpgradersCommodityTanksSheets(wb: Workbook, upgraders: Upgrader[]) {
    upgraders.forEach((upg: Upgrader) => {
      let sheetName = `Commodities ${upg.name}`;
      sheetName = this.adjustSheetName(sheetName, upg.name, 'Commodities');

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        CommodityTankExcelReport.generateDataArray(this.coreService.currentCase, upg.id)
      );
    });
  }

  generateUpgradersGhgEmissionsSheets(wb: Workbook, upgraders: Upgrader[]) {
    upgraders.forEach((upg: Upgrader) => {
      let sheetName = `${upg.name} GHG Emissions`;
      sheetName = this.adjustSheetName(sheetName, upg.name, 'GHG Emissions');

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
        GhgExcelReport.generateDataArray(
          this.reportService.buildGhgEmissionsReport(),
          this.coreService.currentCase,
          upg.id
        )
      );
    });
  }

  generateUpgradersSteamSheets(wb: Workbook, upgraders: Upgrader[]) {
    upgraders.forEach((upg: Upgrader) => {
      const steamReport = new UtilitiesSummaryReportComponent(this.coreService, this.nuc);
      steamReport.buildReport(upg.id);

      if (this.detectReportWithData(steamReport) !== 0) {
        let sheetName = `${upg.name} Steam`;
        sheetName = this.adjustSheetName(sheetName, upg.name, 'Steam');

        wb.SheetNames.push(sheetName);
        wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
          SteamExcelReport.generateDataArray(steamReport, this.coreService.currentCase, upg.id)
        );
      }
    });
  }

  generateUpgradersFuelGasSheets(wb: Workbook, upgraders: Upgrader[]) {
    upgraders.forEach((upg: Upgrader) => {
      const fuelGasReport = new FuelGasSummaryReportComponent(this.coreService, this.nuc);
      fuelGasReport.buildReport(upg.id);

      if (this.detectReportWithData(fuelGasReport) !== 0) {
        let sheetName = `${upg.name} Fuel Gas`;
        sheetName = this.adjustSheetName(sheetName, upg.name, 'Fuel Gas');

        wb.SheetNames.push(sheetName);
        wb.Sheets[sheetName] = XlsxUtils.sheetFromArrayOfArrays(
          FuelGasExcelReport.generateDataArray(fuelGasReport, this.coreService.currentCase, upg.id)
        );
      }
    });
  }

  adjustSheetName(sheetName: string, mainUnitOpName: string, sheetTypeName: string, subUnitOpName?: string) {
    if (sheetName.length > this.SHEET_NAME_MAX_LENGTH) {
      const excessCharacters = sheetName.length - this.SHEET_NAME_MAX_LENGTH;
      const media = excessCharacters / 2;
      if (!subUnitOpName) {
        if (sheetTypeName === 'Commodities' || sheetTypeName === 'Constraints') {
          sheetName = `${sheetTypeName} ${mainUnitOpName.substring(0, mainUnitOpName.length - excessCharacters)}`;
        } else {
          sheetName = `${mainUnitOpName.substring(0, mainUnitOpName.length - excessCharacters)} ${sheetTypeName}`;
        }
      } else if (sheetTypeName === 'Constraints') {
        sheetName = `Cstrs ${mainUnitOpName.substring(
          0,
          mainUnitOpName.length - media - 3
        )}... ${subUnitOpName.substring(0, subUnitOpName.length - media)}`;
      } else {
        sheetName = `${mainUnitOpName.substring(0, mainUnitOpName.length - media - 3)}... ${subUnitOpName.substring(
          0,
          subUnitOpName.length - media
        )} ${sheetTypeName}`;
      }
    }

    return sheetName;
  }

  getUnitOperationsWithSubFlowsheet(
    flowsheetOwners: UnitOperation[],
    category?: string,
    flowsheetOwnerId?: string
  ): UnitOperation[] {
    return flowsheetOwners.filter(uO => {
      if (flowsheetOwnerId) {
        return uO.flowsheetId === flowsheetOwnerId && uO.category === category;
      }
      return uO.category === category;
    });
  }

  detectReportWithData(report: UtilitiesSummaryReportComponent | FuelGasSummaryReportComponent) {
    let reportsWithData = 0;
    for (const property in report) {
      if (property.includes('ReportData') && report[property].reportItems.length !== 0) {
        reportsWithData++;
      }
    }
    return reportsWithData;
  }

  currentCaseStudy() {
    const { caseStudyManager } = this.coreService.currentCase;
    return caseStudyManager.findParametricStudy(caseStudyManager.currentStudyId);
  }
}
