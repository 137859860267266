import { Component, Input } from '@angular/core';
import { WaterTwoPhaseSeparator } from '../../../_models/_unit-operations/utilties/water-two-phase-separator';
import { UnitSubset } from '../../../_config/unitSubset.enum';

@Component({
  selector: 'sob-water-two-phase-separator',
  templateUrl: './water-two-phase-separator.component.html',
  styleUrls: ['./water-two-phase-separator.component.css'],
})
export class WaterTwoPhaseSeparatorComponent {
  @Input()
  unitOperation: WaterTwoPhaseSeparator;

  UnitSubset = UnitSubset;
}
