import { SimulationVariable } from '../../simulation-variable';
import { CommodityTank } from '../../_unit-operations/commodity-tank';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Refinery } from '../../_unit-operations/refinery';
import { Hydrotreater } from '../../_unit-operations/hydrotreater';
import { Upgrader } from '../../_unit-operations/upgrader';
import { Hydrocracker } from '../../_unit-operations/hydrocracker';
import { DCU } from '../../_unit-operations/dcu';
import { DiluentSource } from '../../_unit-operations/diluent-source';
import { SagdSource } from '../../_unit-operations/sagd-source';
import { MineSource } from '../../_unit-operations/mine-source';
import { FluidCoker } from '../../_unit-operations/fluid-coker';
import { HgoHydrotreater } from '../../_unit-operations/hgo-hydrotreater';
import { LgoHydrotreater } from '../../_unit-operations/lgo-hydrotreater';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { WaterTurbine } from '../../_unit-operations/utilties/water-turbine';
import { UnitOperation } from '../../unit-operation';
import { WaterSink } from '../../_unit-operations/utilties/water-sink';
import { WaterBoiler } from '../../_unit-operations/utilties/water-boiler';
import { WaterValve } from '../../_unit-operations/utilties/water-valve';
import { WaterSinkImport } from '../../_unit-operations/utilties/water-sink-import';
import { FuelGasSinkImport } from '../../_unit-operations/fuel-gas/fuel-gas-sink-import';
import { WaterCogeneration } from '../../_unit-operations/utilties/water-cogeneration';
import { BaseConstraintReportVariable } from './base-constraint-report-variable';
import { WaterBoilerConstraintReportVariableResolver } from './variable-resolvers/water-boiler-constraint-report-variable-resolver';
import { WaterTurbineConstraintReportVariableResolver } from './variable-resolvers/water-turbine-constraint-report-variable-resolver';
import { WaterCogenerationConstraintReportVariableResolver } from './variable-resolvers/water-cogeneration-constraint-report-variable-resolver';
import { WaterValveConstraintReportVariableResolver } from './variable-resolvers/water-valve-constraint-report-variable-resolver';
import { WaterSinkImportConstraintReportVariableResolver } from './variable-resolvers/water-sink-import-constraint-report-variable-resolver';
import { FuelGasSinkImportConstraintReportVariableResolver } from './variable-resolvers/fuel-gas-sink-import-constraint-report-variable-resolver';
import { HgoHydrotreaterConstraintReportVariableResolver } from './variable-resolvers/hgo-hydrotreater-constraint-report-variable-resolver';
import { LgoHydrotreaterConstraintReportVariableResolver } from './variable-resolvers/lgo-hydrotreater-constraint-report-variable-resolver';
import { WaterSinkConstraintReportVariableResolver } from './variable-resolvers/water-sink-constraint-report-variable-resolver';
import { FluidCokerConstraintReportVariableResolver } from './variable-resolvers/fluid-coker-constraint-report-variable-resolver';
import { UpgraderConstraintReportVariableResolver } from './variable-resolvers/upgrader-constraint-report-variable-resolver';
import { CommodityTankReportVariableResolver } from './variable-resolvers/commodity-tank-report-variable-resolver';

declare let unitConverter: any;

export class ConstraintReportVariable extends BaseConstraintReportVariable {
  currentUnit: string;
  constraintDisabled: boolean;
  simVarId: string;

  constructor(name, constraintValue, constraintUnit, currentValue, currentUnit) {
    super(name, constraintValue);
    this.constraintUnit = constraintUnit;
    this.currentValue = currentValue;
    this.currentUnit = currentUnit;
  }

  static create(uo: UnitOperation, simVar: SimulationVariable): ConstraintReportVariable | undefined {
    // TODO: STOP HERE!!!! yes, you Programmer
    // before adding a lot of if's here, please follow the pattern used to get the values
    // for upgrader's constraint report variable - move the code to a separate class and just call it
    const inletStreamList = uo.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === uo.id;
    });

    const crv = new ConstraintReportVariable(
      simVar.name,
      simVar.convertToDefaultUnit(true, false),
      simVar.getDefaultUnit(),
      null,
      null
    );

    const simVarLowerCaseName = simVar.name.toLowerCase();

    crv.isActive = simVar.isActive;
    crv.unitOperationName = uo.name;
    crv.unitOperationId = uo.id;
    crv.simVarId = simVar.id;

    if (uo instanceof Upgrader) {
      UpgraderConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });
      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }

    if (uo instanceof WaterTurbine) {
      WaterTurbineConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });
      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }

    if (uo instanceof WaterSink) {
      WaterSinkConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });
      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }

    if (uo instanceof WaterBoiler) {
      WaterBoilerConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });
      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }

    if (uo instanceof WaterCogeneration) {
      WaterCogenerationConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });
      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);

      return crv;
    }

    if (uo instanceof WaterValve) {
      WaterValveConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });

      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }

    if (uo instanceof WaterSinkImport) {
      WaterSinkImportConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });

      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }

    if (uo instanceof FluidCoker) {
      FluidCokerConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });
      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }

    if (uo instanceof FuelGasSinkImport) {
      FuelGasSinkImportConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });
      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }

    if (uo instanceof CommodityTank) {
      CommodityTankReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });

      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }
    if (uo instanceof HgoHydrotreater) {
      HgoHydrotreaterConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });
      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }
    if (uo instanceof LgoHydrotreater) {
      LgoHydrotreaterConstraintReportVariableResolver.create().addCurrentValueAndUnitToConstraintReportVariable(crv, {
        uo,
        simVar,
      });
      crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
      return crv;
    }
    if (uo instanceof Hydrotreater) {
      if (simVar.name === SimulationVariableName.MAXIMUM_NITROGEN) {
        crv.currentValue = uo.totalInletNitrogen.convertToDefaultUnit(true, false);
        crv.currentUnit = uo.totalInletNitrogen.getDefaultUnit();
        crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
        return crv;
      }
    } else if (uo instanceof Hydrocracker || uo instanceof DCU) {
      if (inletStreamList.length) {
        if (simVar.name === 'Minimum API') {
          crv.currentValue = inletStreamList[0].massDensity.convertToDefaultUnit(true, false);
          crv.currentUnit = inletStreamList[0].massDensity.getDefaultUnit();
          crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
          return crv;
        }
        if (simVar.name === SimulationVariableName.MAXIMUM_CCR) {
          crv.currentValue = inletStreamList[0].ccr.convertToDefaultUnit(true, false);
          crv.currentUnit = inletStreamList[0].ccr.getDefaultUnit();
          crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
          return crv;
        }
      }
    } else if (uo instanceof DiluentSource || uo instanceof SagdSource || uo instanceof MineSource) {
      const outletStream = uo.ownerCase.filterSuncorMaterialStreams(s => {
        return s.inletUnitOperationId === uo.id;
      });
      if (outletStream.length) {
        // These unit operations only have 1 constraint
        crv.currentValue = outletStream[0].volumetricFlowrate.convertToDefaultUnit(true, false);
        crv.currentUnit = outletStream[0].volumetricFlowrate.getDefaultUnit();
        crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
        return crv;
      }
    }
    if (simVarLowerCaseName.includes('mass density') || simVarLowerCaseName.includes('density spec')) {
      crv.constraintValue = simVar.roundCurrentValue();
      crv.constraintUnit = simVar.getInternalUnit();
    }

    if (inletStreamList.length) {
      if (simVarLowerCaseName.includes('nitrogen')) {
        crv.currentValue = inletStreamList[0].nitrogen.convertToAnotherUnit(
          unitConverter.units.MassFraction.PPMW,
          false
        );
        crv.currentUnit = unitConverter.units.MassFraction.PPMW;
        crv.constraintValue = simVar.convertToAnotherUnit(unitConverter.units.MassFraction.PPMW, false);
        crv.constraintUnit = unitConverter.units.MassFraction.PPMW;
      } else if (simVarLowerCaseName.includes('sulfur')) {
        if (uo instanceof Refinery) {
          const ref = uo as Refinery;
          if (ref.hasTwoInlets) {
            if (simVarLowerCaseName.includes('train 2')) {
              const stream = inletStreamList.filter(s => {
                return s.toPort === 'train2';
              })[0];
              crv.currentValue = stream.sulfur.convertToDefaultUnit(true, false);
              crv.currentUnit = stream.sulfur.getDefaultUnit();
            } else {
              const stream = inletStreamList.filter(s => {
                return s.toPort !== 'train2';
              })[0];
              crv.currentValue = stream.sulfur.convertToDefaultUnit(true, false);
              crv.currentUnit = stream.sulfur.getDefaultUnit();
            }
          } else {
            crv.currentValue = inletStreamList[0].sulfur.convertToDefaultUnit(true, false);
            crv.currentUnit = inletStreamList[0].sulfur.getDefaultUnit();
          }
        } else if (uo instanceof Hydrotreater) {
          crv.currentValue = uo.totalInletSulfur.convertToDefaultUnit(true, false);
          crv.currentUnit = uo.totalInletSulfur.getDefaultUnit();
        } else {
          crv.currentValue = inletStreamList[0].sulfur.convertToDefaultUnit(true, false);
          crv.currentUnit = inletStreamList[0].sulfur.getDefaultUnit();
        }
      } else if (simVarLowerCaseName.includes('mass density') || simVarLowerCaseName.includes('density spec')) {
        if (uo instanceof Refinery) {
          const ref = uo as Refinery;
          if (ref.hasTwoInlets) {
            if (simVarLowerCaseName.includes('train 2')) {
              const stream = inletStreamList.filter(s => {
                return s.toPort === 'train2';
              })[0];
              crv.currentValue = stream.massDensity.roundCurrentValue();
              crv.currentUnit = stream.massDensity.getInternalUnit();
            } else {
              const stream = inletStreamList.filter(s => {
                return s.toPort !== 'train2';
              })[0];
              crv.currentValue = stream.massDensity.roundCurrentValue();
              crv.currentUnit = stream.massDensity.getInternalUnit();
            }
          } else {
            crv.currentValue = inletStreamList[0].massDensity.roundCurrentValue();
            crv.currentUnit = inletStreamList[0].massDensity.getInternalUnit();
          }
        } else {
          crv.currentValue = inletStreamList[0].massDensity.roundCurrentValue();
          crv.currentUnit = inletStreamList[0].massDensity.getInternalUnit();
        }
      } else if (simVarLowerCaseName.includes('hydrogen')) {
        crv.currentValue = null;
        crv.currentUnit = null;
      } else if (uo instanceof Refinery) {
        const ref = uo as Refinery;
        if (ref.hasTwoInlets) {
          if (simVarLowerCaseName.includes('train 2')) {
            const stream = inletStreamList.filter(s => {
              return s.toPort === 'train2';
            })[0];
            crv.currentValue = stream.volumetricFlowrate.convertToDefaultUnit(true, false);
            crv.currentUnit = stream.volumetricFlowrate.getDefaultUnit();
          } else {
            const stream = inletStreamList.filter(s => {
              return s.toPort !== 'train2';
            })[0];
            crv.currentValue = stream.volumetricFlowrate.convertToDefaultUnit(true, false);
            crv.currentUnit = stream.volumetricFlowrate.getDefaultUnit();
          }
        } else {
          crv.currentValue = inletStreamList[0].volumetricFlowrate.convertToDefaultUnit(true, false);
          crv.currentUnit = inletStreamList[0].volumetricFlowrate.getDefaultUnit();
        }
      } else if (uo instanceof Hydrotreater) {
        crv.currentValue = uo.totalInletVolume.convertToDefaultUnit(true, false);
        crv.currentUnit = uo.totalInletVolume.getDefaultUnit();
      } else {
        crv.currentValue = inletStreamList[0].volumetricFlowrate.convertToDefaultUnit(true, false);
        crv.currentUnit = inletStreamList[0].volumetricFlowrate.getDefaultUnit();
      }
    }

    if (uo instanceof Hydrotreater) {
      const disabledNames = [
        SimulationVariableName.MAXIMUM_SULFUR_CONTENT,
        SimulationVariableName.MAXIMUM_NITROGEN,
      ] as string[];
      if (disabledNames.indexOf(simVar.name) >= 0) {
        crv.constraintDisabled = uo.constraintOption === 'blendRatio';
      }
    }

    crv.isConstraintViolated = this.verifyIfConstraintIsViolated(crv);
    return crv;
  }
}
