<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#fuelGasSourceImportComments" aria-controls="fuelGasSourceImportComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4>Information Streams</h4>

        <div class="form-group">
          <div class="col-xs-12">
            <select
              name="selectedProviderUnitOperationId"
              id="selectedProviderUnitOperationId"
              formControlName="selectedProviderUnitOperationId"
              size="2"
              style="height: 200px"
              class="form-control">
              <option
                (click)="filterStreams()"
                *ngFor="let uo of availableProviderUnitOperations"
                value="{{ uo.id }}"
                (dblclick)="addInformationStream()">
                {{ uo.name }}
              </option>
            </select>

            <button class="btn btn-xs btn-success m-t-xs" (click)="addInformationStream()">
              <i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>

        <ng-container formArrayName="inletSourceInformationStreams">
          <ng-container *ngFor="let informationStreamGroup of getInformationStreamsFormGroupsArray(); let i = index">
            <div class="row information-stream-row">
              <h4>
                <a
                  data-toggle="collapse"
                  href="#informationStreamCollapse{{ i }}"
                  aria-expanded="false"
                  sfeCollapsePanelIcon>
                  <i class="fa fa-plus" aria-hidden="true"> </i>
                  {{ activeInformationStreams[i].getFullName() }}
                </a>
              </h4>
              <div>
                <span class="inline pull-right p-t-5-x" style="margin-top: -30px">
                  <button
                    class="btn-remove btn btn-xs btn-danger"
                    (click)="removeInformationStream(i)"
                    data-toggle="tooltip"
                    title="Remove Information Stream">
                    <i class="fa fa-minus-circle"></i>
                  </button>
                </span>
              </div>
              <div id="informationStreamCollapse{{ i }}" class="collapse">
                <sob-fuel-gas-source-information-stream-form
                  [informationStream]="activeInformationStreams[i]"
                  [informationStreamFormGroup]="informationStreamGroup">
                </sob-fuel-gas-source-information-stream-form>
              </div>
            </div>
            <hr />
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="fuelGasSourceImportComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
