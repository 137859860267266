import { Case } from '../case';
import { SuncorUnitOperation } from './suncor-unit-operation';
import { DistributionRatioVariable } from './distribution-ratio-variable';
import { isTypeUndefined } from '../../_utils/utils';
import { RangeDistributionRatioVariable } from './range-distribution-ratio-variable';
import { OutletDistributionRatioVariablesOwner } from './outlet-distribution-ratio-variables-owner';
import { MixingConstraints } from './mixing-constraints';
import { DefaultMappedType } from '../default-mapped-type';
import { RangeDistributionConstraint } from './range-distribution-constraint';

export class Splitter extends SuncorUnitOperation implements OutletDistributionRatioVariablesOwner {
  category = 'splitter';

  distributionRatioVariables: RangeDistributionRatioVariable[] = [];
  isOptimizable: boolean; // default value... otherwise will be initialized by false...
  constraints: MixingConstraints;

  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);

    if (isTypeUndefined(unitOperation.isOptimizable)) {
      this.isOptimizable = true;
    } else {
      this.isOptimizable = !!unitOperation.isOptimizable;
    }

    if (unitOperation.distributionRatioVariables instanceof Array) {
      this.createConstraintsObjInstance(unitOperation);
      for (const dv of unitOperation.distributionRatioVariables) {
        const rangeDistributionRatioVar = new RangeDistributionRatioVariable(dv);
        this.distributionRatioVariables.push(rangeDistributionRatioVar);
        this.initConstraintsBasedInDistributionRatios(rangeDistributionRatioVar);
      }
    }
    this.setQuantities();
  }

  // region class-specific methods
  addOutletDistributionRatioVariables(unitOperationId: string) {
    this.clearAllRatioVariables();
    const distributionRatioVar = new DistributionRatioVariable(unitOperationId, null);
    this.distributionRatioVariables.push(distributionRatioVar);

    if (this.distributionRatioVariables.length === 1) {
      this.distributionRatioVariables[0].value = 1;
    }

    this.createConstraintsObjInstance();
    this.initConstraintsBasedInDistributionRatios(distributionRatioVar);
  }

  private clearAllRatioVariables() {
    for (const dv of this.distributionRatioVariables) {
      dv.value = null;
    }
  }

  findDistributionRatioVariable(unitOperationId: string) {
    return this.distributionRatioVariables.find(drv => {
      return drv.unitOperationId === unitOperationId;
    });
  }

  findDistributionRatioVariableName(i: number) {
    const dv = this.distributionRatioVariables[i];

    if (dv) {
      const uo = this.ownerCase.getUnitOperation(dv.unitOperationId);
      return uo ? uo.name : null;
    }

    return null;
  }

  removeOutletDistributionRatioVariables(unitOperationId: string) {
    const index = this.distributionRatioVariables.indexOf(this.findDistributionRatioVariable(unitOperationId));

    if (index >= 0) {
      this.distributionRatioVariables.splice(index, 1);
      this.constraints.removeConstraint(unitOperationId);
    }
  }

  setOptimizable(isOptimizable: boolean) {
    this.isOptimizable = isOptimizable;
  }
  // endregion

  getAlternativeGhgIntensity() {
    return undefined;
  }

  // Constraints region
  initConstraintsBasedInDistributionRatios(rangeDistributionRatioVar: RangeDistributionRatioVariable) {
    const unitOpId = rangeDistributionRatioVar.unitOperationId;
    const unitOperation = this.ownerCase.getUnitOperation(unitOpId);

    if (this.isOptimizable) {
      if (this.constraints.minAndMaxConstraintsExists(unitOpId)) return;

      this.constraints.removeExactConstraintIfOptimizable(unitOpId);

      this.constraints.addNewConstraint(
        `minimumRange${unitOpId}`,
        unitOperation ? `Minimum ${unitOperation.name} Ratio` : 'Minimum',
        rangeDistributionRatioVar.minimumValue,
        unitOpId
      );
      this.constraints.addNewConstraint(
        `maximumRange${unitOpId}`,
        unitOperation ? `Maximum ${unitOperation.name} Ratio` : 'Maximum',
        rangeDistributionRatioVar.maximumValue,
        unitOpId
      );
    } else {
      if (this.constraints.exactValueConstraintExists(unitOpId)) return;

      this.constraints.removeMinMaxConIfNoOptimizable(unitOpId);

      this.constraints.addNewConstraint(
        `exactValue${unitOpId}`,
        unitOperation ? `Exact ${unitOperation.name} Ratio` : 'Exact',
        rangeDistributionRatioVar.value,
        unitOpId
      );
    }
  }

  initConstraintsBasedInExistingConstraints(unitOperation: DefaultMappedType<Splitter>) {
    Object.entries(unitOperation.constraints.constraints).forEach((entry: [string, RangeDistributionConstraint]) => {
      this.constraints.addNewConstraint(
        entry[0],
        entry[1].constraintName,
        entry[1].constraintValue,
        entry[1].sourceUnitOperationId,
        entry[1].isActive
      );
    });
  }

  createConstraintsObjInstance(unitOperation?: DefaultMappedType<Splitter>) {
    if (isTypeUndefined(this.constraints)) {
      this.constraints = new MixingConstraints();
    }
    if (isTypeUndefined(unitOperation)) return;
    if (!isTypeUndefined(unitOperation.constraints)) {
      if (Object.keys(unitOperation.constraints.constraints).length > 0)
        this.initConstraintsBasedInExistingConstraints(unitOperation);
    }
  }
  // end region
}
