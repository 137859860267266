import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RangeDistributionRatioVariable } from '../../../_models/_unit-operations/range-distribution-ratio-variable';
import { CoreService } from '../../../_services/core.service';

declare let unitConverter: any;
@Component({
  selector: 'sob-range-distribution-ratio-variable',
  templateUrl: './range-distribution-ratio-variable.component.html',
  styleUrls: ['./range-distribution-ratio-variable.component.css'],
})
export class RangeDistributionRatioVariableComponent implements OnInit {
  @Input()
  ratioVariables: Array<RangeDistributionRatioVariable>;

  @Input()
  blendRecipeOption: string;

  @Input()
  readonly = false;

  table: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private coreService: CoreService) {}

  ngOnInit() {
    this.table = this.fb.group({
      tableRows: this.fb.array(this.initRows()),
    });
    this.rows.valueChanges.forEach(() => {
      this.calculateLastDistributionVariable();
    });

    // eslint-disable-next-line guard-for-in
    for (const i in this.rows.controls) {
      this.roundValues(+i);
    }
  }

  validateRange(i: number, target) {
    const row = this.rows.controls[i];
    const parsedMin = unitConverter.parseFloatString(row.get('minimumValue').value);
    const parsedMax = unitConverter.parseFloatString(row.get('maximumValue').value);
    if (parsedMin && parsedMax) {
      if (parsedMin >= parsedMax) {
        row.get(target).setValue('');
      }
    }
    this.roundValues(i);
  }

  roundValues(i: number) {
    const row = this.rows.controls[i];
    const parsedValue = unitConverter.parseFloatString(row.get('value').value);
    const parsedMin = unitConverter.parseFloatString(row.get('minimumValue').value);
    const parsedMax = unitConverter.parseFloatString(row.get('maximumValue').value);

    if (!isNaN(parsedValue)) {
      row.get('value').setValue(parseFloat(parsedValue.toFixed(3)));
    }
    if (!isNaN(parsedMax)) {
      row.get('maximumValue').setValue(parseFloat(parsedMax.toFixed(3)));
    }
    if (!isNaN(parsedMin)) {
      row.get('minimumValue').setValue(parseFloat(parsedMin.toFixed(3)));
    }
  }

  private initRows() {
    return this.ratioVariables.map((dv, i) => {
      return this.fb.group({
        minimumValue: [dv.minimumValue],
        maximumValue: [dv.maximumValue],
        value: [dv.value, [Validators.min(0), Validators.max(1)]],
      });
    });
  }

  findDistributionRatioName(i): string {
    const id = this.ratioVariables[i].unitOperationId;
    return this.coreService.currentCase.getUnitOperation(id).name;
  }

  get rows() {
    const rows = this.table.get('tableRows') as UntypedFormArray;
    return rows;
  }

  calculateLastDistributionVariable() {
    const { controls } = this.rows;
    if (controls.length <= 1) {
      return;
    }
    let total = 0;

    for (let i = 0; i < controls.length - 1; i++) {
      const dv = controls[i];
      const valueParsed = unitConverter.parseFloatString(dv.get('value').value);

      if (!isNaN(valueParsed)) {
        total += valueParsed;
      } else {
        return;
      }
    }
    controls[controls.length - 1].get('value').setValue(parseFloat((1 - total).toFixed(5)), {
      emitEvent: false,
      emitModelToViewChange: true,
      emitViewToModelChange: true,
    });
    controls[controls.length - 1].get('value').markAsDirty();
  }
}
